import PropTypes from "prop-types";
import { useFormBlocks } from "../Providers";
import { BannerEdit } from "./BannerEdit";
import { CallToActionEdit } from "./CallToActionEdit";
import { DescriptionEdit } from "./DescriptionEdit";
import { LegacyLockScreenEdit } from "./LegacyLockScreenEdit";
import { PaywallsEdit } from "./PaywallsEdit";

const blockTypeToComponentMap = {
  banner: BannerEdit,
  description: DescriptionEdit,
  call_to_action: CallToActionEdit,
  legacy_lock_screen: LegacyLockScreenEdit,
  paywalls: PaywallsEdit,
};

export const BlockEditSelector = ({ type, id }) => {
  const { getBlockDataPath } = useFormBlocks();

  const blockDataPath = getBlockDataPath(id);
  if (!blockDataPath) return null;

  const BlockEditComponent = blockTypeToComponentMap[type];
  if (!BlockEditComponent) return null;

  return <BlockEditComponent blockDataPath={blockDataPath} />;
};

BlockEditSelector.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};
