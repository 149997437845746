import PropTypes from "prop-types";
import classNames from "classnames";

export const Body = ({ className, children }) => (
  <div className="main">
    <div className={classNames("main__wrapper", className)}>{children}</div>
  </div>
);

Body.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};
