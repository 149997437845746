import { formattedDateTime } from "@circle-react/helpers/dateTimeHelpers/formattedDateTime";
import { TableCell } from "@circle-react-uikit/Table";
import type { CellVariant } from "@circle-react-uikit/Table/TableCell";
import { Typography } from "@circle-react-uikit/Typography";
import type { Member } from "../types";

interface JoinedAtColumnProps {
  member: Member;
  cellVariant: CellVariant;
  hideColumn: boolean;
}

export const JoinedAtColumn = ({
  member,
  cellVariant,
  hideColumn,
}: JoinedAtColumnProps) => {
  if (hideColumn) {
    return null;
  }

  const joinedAt = member?.currentSpaceMember?.created_at
    ? formattedDateTime({
        dateTime: new Date(member.currentSpaceMember.created_at),
        format: "long_date",
      })
    : "";

  return (
    <TableCell variant={cellVariant} className="">
      <Typography.LabelSm color="text-selector-active">
        {joinedAt}
      </Typography.LabelSm>
    </TableCell>
  );
};
