import classNames from "classnames";
import { t } from "i18n-js";
import { TrialOffer } from "@/react/components/Layout/TrialBanner/TrialOffer";
import { useTrialBanner } from "@/react/components/Layout/TrialBanner/useTrialBanner";
import { planSettingsPath } from "@/react/helpers/urlHelpers";
import { usePunditUserContext } from "@circle-react/contexts";
import { HelloBar } from "@circle-react-uikit/HelloBar";

export const TrialBannerV2 = () => {
  const { displayCommunitySwitcher } = usePunditUserContext();
  const { shouldShowTrialBanner, isSubscriptionCancelled } = useTrialBanner();

  if (!shouldShowTrialBanner) {
    return null;
  }

  return (
    <HelloBar
      className={classNames({ "left-[60px]": displayCommunitySwitcher })}
    >
      <TrialOffer />

      {isSubscriptionCancelled && (
        <div>
          {t("trial_banner.renew_subscription")}{" "}
          <a href={planSettingsPath()}>
            {t("trial_banner.renew_subscription")}
          </a>
          {t("trial_banner.to_access_community")}
        </div>
      )}
    </HelloBar>
  );
};
