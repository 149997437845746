import { useState } from "react";
import { useRerunWorkflow } from "@circle-workflows/hooks/useRerunWorkflow";
import type { RerunFormData } from "@circle-workflows/hooks/useRerunWorkflow";
import { noop } from "lodash";
import { t } from "@circle-react/custom_i18n";
import { useBoolean } from "@circle-react/hooks/utils/useBoolean";
import { BigLoader } from "@circle-react-shared/BigLoader";
import { Form } from "@circle-react-uikit/Form";
import { Modal } from "@circle-react-uikit/ModalV2";
import { ConfirmRerun } from "./ConfirmRerun";
import { RerunForm } from "./RerunForm";

interface RerunContentProps {
  hasRuns?: boolean;
  onCancel: () => void;
  onPublish: any;
  additionalRerunParams?: Record<string, unknown>;
}

export const RerunContent = ({
  hasRuns,
  onPublish,
  onCancel,
  additionalRerunParams,
}: RerunContentProps) => {
  const [rerunParams, setRerunParams] = useState<RerunFormData | null>(null);
  const [isConfirm, toggleIsConfirm] = useBoolean(
    /* if workflow hasn't run before, go directly to confirmation step. */
    hasRuns === false ? true : false,
  );

  const { mutateAsync: rerunWorkflow, isLoading } = useRerunWorkflow(
    { onError: noop },
    { ...rerunParams, ...additionalRerunParams },
  );

  const handleSubmit = (data: RerunFormData) => {
    setRerunParams(data);
    toggleIsConfirm();
  };

  if (isLoading) {
    return (
      <Modal.Content>
        <Modal.Body className="w-full !px-10 !py-16">
          <BigLoader
            title={t("settings.workflows.rerunning_your_workflow")}
            subtitle={t("settings.workflows.we_are_preparing")}
          />
        </Modal.Body>
      </Modal.Content>
    );
  }

  if (isConfirm) {
    return (
      <ConfirmRerun
        rerunParams={rerunParams}
        onBack={toggleIsConfirm}
        onCancel={onCancel}
        onPublish={() => onPublish(rerunWorkflow)}
      />
    );
  }

  return (
    <Modal.Content size="md">
      <Form
        defaultValues={{
          do_not_skip_tasks: rerunParams?.do_not_skip_tasks || "false",
        }}
        onSubmit={handleSubmit}
      >
        <RerunForm onCancel={onCancel} />
      </Form>
    </Modal.Content>
  );
};
