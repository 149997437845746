import { t } from "i18n-js";

export const Description = ({ children }: any) => (
  <div className="flex flex-col gap-y-2">
    {children}
    <p
      className="text-default text-sm"
      dangerouslySetInnerHTML={{
        __html: t("settings.paywalls.processor_status_v2.generic.call_support"),
      }}
    />
  </div>
);
