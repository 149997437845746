import { t } from "i18n-js";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import type { CountryCode } from "@circle-react/types/Countries";
import { Form } from "@circle-react-uikit/Form";
import { Modal } from "@circle-react-uikit/ModalV2";
import { useToast } from "@circle-react-uikit/ToastV2";
import { formId } from "../constants";
import { useCommunityPaymentProcessor } from "../hooks/useCommunityPaymentProcessor";
import { BusinessForm } from "./BusinessForm";

const localeRoot = "stripe_onboarding_modal";

export const BusinessFormModal = () => {
  const modal = useModal();
  const { error } = useToast();
  const { onSubmit, isLoading, isSuccess } = useCommunityPaymentProcessor({
    onError: (response: Record<string, string>) => {
      error(response?.message);
      onClose();
    },
  });

  const getDefaultCountry = () => {
    const countryFromCloudFlare = window?.CLOUDFLARE_COUNTRY_CODE;
    const countryBasedOnLocale: CountryCode | null =
      window?.locale === "pt" ? "BR" : null;
    return (
      countryFromCloudFlare ||
      countryBasedOnLocale ||
      "US"
    ).toLowerCase();
  };
  const isDisabled = isLoading || isSuccess;
  const onClose = () => {
    !isDisabled && modal.remove();
  };

  const defaultValues: any = {
    country: getDefaultCountry(),
  };

  return (
    <Form id={formId} onSubmit={onSubmit} defaultValues={defaultValues}>
      <Modal
        isOpen={modal.visible}
        onClose={onClose}
        contentPortalId="stripe-onboarding-business-modal"
      >
        <Modal.Overlay />
        <Modal.Content size="lg" wrapperClassName="!h-full">
          <Modal.Header>
            <Modal.Title size="md">{t([localeRoot, "title"])}</Modal.Title>
            <Modal.HeaderActions>
              <Modal.CloseButton onClick={onClose} />
            </Modal.HeaderActions>
          </Modal.Header>
          <BusinessForm
            isLoading={isLoading}
            onClose={onClose}
            defaultCountry={getDefaultCountry()}
          />
        </Modal.Content>
      </Modal>
    </Form>
  );
};

export const useBusinessFormModal = () =>
  useModal(ModalManager.create(BusinessFormModal));
