import PropTypes from "prop-types";
import { useModalData } from "@circle-react-uikit/ModalV2";
import { SortableList } from "@circle-react-uikit/SortableList";
import { useListItemDataProvider } from "@circle-react-uikit/SortableList";
import { BannerItem } from "./Items/BannerItem";
import { CallToActionItem } from "./Items/CallToActionItem";
import { DescriptionItem } from "./Items/DescriptionItem";
import { LegacyLockscreenItem } from "./Items/LegacyLockscreenItem";
import { LessonsItem } from "./Items/LessonsItem";
import { PaywallsItem } from "./Items/PaywallsItem";

const blockTypeToComponentMap = {
  banner: BannerItem,
  description: DescriptionItem,
  call_to_action: CallToActionItem,
  lessons: LessonsItem,
  legacy_lock_screen: LegacyLockscreenItem,
  paywalls: PaywallsItem,
};

const BlockItem = () => {
  const { item: block } = useListItemDataProvider();
  const { type } = block;
  const BlockComponent = blockTypeToComponentMap[type];
  return <BlockComponent block={block} />;
};

export const BlockList = ({ blocks, onSort, ...rest }) => {
  const { contentPortalElement } = useModalData();

  return (
    <SortableList items={blocks} onSort={onSort} {...rest}>
      <SortableList.List>
        {blocks.map(block => (
          <SortableList.Item key={block.id} item={block}>
            <BlockItem />
          </SortableList.Item>
        ))}
      </SortableList.List>
      <SortableList.DragOverlay portalTo={contentPortalElement}>
        <BlockItem />
      </SortableList.DragOverlay>
    </SortableList>
  );
};

BlockList.propTypes = {
  blocks: PropTypes.array,
  onSort: PropTypes.func,
};
