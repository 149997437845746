import {
  reactQueryDelete,
  reactQueryGet,
  reactQueryPost,
  reactQueryPut,
} from "@/react/helpers/backendRequestHelpers";
import type { Params } from "@/react/helpers/urlHelpers";
import { internalApi } from "@/react/helpers/urlHelpers";

const index = (params: Record<string, string>) => () => {
  Object.keys(params).forEach(key => {
    if (params[key] === null) {
      delete params[key];
    }
  });

  return reactQueryGet(
    internalApi.paywalls.index({
      params,
    }),
  );
};

const getPaywall = (paywallId: string, params: Params = {}) =>
  reactQueryGet(internalApi.paywalls.show({ paywallId, params }));

const updatePaywall = (paywallId: string, params: Record<string, string>) =>
  reactQueryPut(
    internalApi.paywalls.update({
      paywallId: paywallId,
    }),
    params,
  );

const deletePaywall = (paywallId: any) =>
  reactQueryDelete(
    internalApi.paywalls.delete({
      paywallId,
    }),
  );

const createPaywall = (params: Record<string, string>) =>
  reactQueryPost(internalApi.paywalls.create(), params);

export interface UpdateMemberAccessResponse {
  id: number;
  fingerprint: string;
  record_id: number;
  record_type: string;
  created_at: string;
  params: {
    paywall_id: string;
    paywall_spaces_fingerprint: string;
  };
}
const updateMemberAccess = (
  paywallId: string,
): Promise<UpdateMemberAccessResponse> =>
  reactQueryPost(internalApi.paywalls.updateMemberAccess({ paywallId }));

export const paywallApi = {
  index,
  getPaywall,
  createPaywall,
  updatePaywall,
  deletePaywall,
  updateMemberAccess,
};
