import { t } from "i18n-js";
import { Form } from "@circle-react-shared/uikit/Form";

export const SubscriptionGroupSelect = ({
  isSelectDisabled,
  paywallGroupSelectOptions,
  className = "",
}: any) => (
  <Form.Item
    className={`!py-0 ${className}`}
    labelClassName="!font-normal"
    name="paywall_group_id"
    hideDescription
    hidePlaceholder
    hideBorder
    hideLabel
    isDisabled={isSelectDisabled}
  >
    <Form.SelectNative
      prompt={t("settings.paywalls.modal.tabs.pricing.paywall_group_prompt")}
      name="paywall_group_id"
      options={paywallGroupSelectOptions}
      disabled={isSelectDisabled}
      rules={{
        shouldUnregister: true,
      }}
    />
  </Form.Item>
);
