import type { ReactNode } from "react";
import isFunction from "lodash/isFunction";
import { Link, useHistory } from "react-router-dom";
import { dateStringToTimeAgo } from "@/react/helpers/dateTimeHelpers/timeAgo";
import { notificationPath } from "@/react/helpers/urlHelpers";
import { Icon } from "@circle-react-shared/Icon";
import { UserImage } from "@circle-react-shared/uikit/UserImage";
import { NotificationActions } from "./Actions";
import {
  notificationActionIcon,
  notificationActionType,
} from "./Actions/ActionContent";
import { Text } from "./Text";

export interface ListProps {
  notification: any;
  markAsRead: (notification: any) => void;
  unfollowNotification: (notification: any) => void;
  followNotification: (notification: any) => void;
  archiveNotification: (notification: any) => void;
  onClickNotification?: (event: any) => void;
}

export const List = ({
  notification,
  markAsRead,
  unfollowNotification,
  followNotification,
  archiveNotification,
  onClickNotification,
}: ListProps) => {
  const history = useHistory();
  const notificationIconType: any = notificationActionIcon(notification);

  const renderLinkContainer = (content: ReactNode) => {
    if (history) {
      return (
        <Link
          to={notification.action_web_path}
          className="notifications__link"
          onClick={e => {
            markAsRead(notification);
            isFunction(onClickNotification) && onClickNotification(e);
          }}
        >
          {content}
        </Link>
      );
    }
    return (
      <a className="notifications__link" href={notificationPath(notification)}>
        {content}
      </a>
    );
  };

  const notificationLinkContent = (
    <>
      <div className="mr-2">
        <UserImage
          src={notification.actor_image}
          name={notification.actor_name}
          size="10"
        />
        <div className="notifications__icon">
          <Icon type={notificationIconType} className="icon--sm" />
        </div>
      </div>
      <div className="notifications__details">
        <Text notification={notification} />
        <div className="notifications__time">
          {dateStringToTimeAgo(notification.created_at)}
        </div>
      </div>
    </>
  );

  return (
    <li key={notification.id}>
      <div
        className={`notifications__row ${notificationActionType(notification)} ${
          notification.read_at
            ? "notifications__row--read"
            : "notifications__row--unread"
        }`}
      >
        {renderLinkContainer(notificationLinkContent)}
        <NotificationActions
          notification={notification}
          markAsRead={markAsRead}
          archiveNotification={archiveNotification}
          unfollowNotification={unfollowNotification}
          followNotification={followNotification}
        />
      </div>
    </li>
  );
};
