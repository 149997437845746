import { PropTypes } from "prop-types";
import { formattedDateTime } from "@circle-react/helpers/dateTimeHelpers/formattedDateTime";
import { Typography } from "@circle-react-uikit/Typography";
import { Result } from "../Table/Result";

export const HistoryError = ({ log }) => {
  const { action_to_s = "", created_at: createdAt = "", message = {} } = log;
  const date = formattedDateTime({
    dateTime: new Date(createdAt),
    format: "short_date_at_short_time",
  });

  return (
    <div className="border-dark rounded-lg border">
      <div className="bg-secondary border-dark flex flex-col gap-3.5 rounded-t-lg border-b px-6 py-5">
        <div className="flex flex-col gap-1">
          <div>
            <Typography.LabelMd weight="semibold">
              {action_to_s}
            </Typography.LabelMd>
          </div>
          <div className="flex flex-col gap-2 md:flex-row">
            <Result result="failed" isBadge={false} />
            <Typography.LabelSm
              color="text-default"
              as="time"
              dateTime={createdAt}
            >
              {date}
            </Typography.LabelSm>
          </div>
        </div>
      </div>
      <div className="px-6 py-5">
        <Typography.LabelSm>{message?.error}</Typography.LabelSm>
      </div>
    </div>
  );
};

HistoryError.propTypes = {
  log: PropTypes.shape({
    action_to_s: PropTypes.string,
    created_at: PropTypes.string,
    message: PropTypes.shape({
      error: PropTypes.string,
    }),
  }),
};
