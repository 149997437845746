import { t } from "i18n-js";
import {
  SPACE_POST_TYPES,
  TRIGGER_RESOURCE_TYPE,
} from "@circle-react/components/SettingsApp/Workflows/constants";
import { EntitySelector } from "../../EntitySelector";
import { entityTypeOptions as eventOptions } from "./AnyEventSelector";

const i18nRoot = "settings.workflows.form.entity_type.event";

const entityTypeOptions = [
  ...eventOptions,
  {
    value: TRIGGER_RESOURCE_TYPE.EVENT,
    label: t([i18nRoot, "event.title"]),
    description: t([i18nRoot, "event.description"]),
  },
];

export const AnyEventSpecificSelector = () => (
  <EntitySelector
    entityTypeOptions={entityTypeOptions}
    resourceType={SPACE_POST_TYPES.EVENT}
  />
);

AnyEventSpecificSelector.propTypes = {};
