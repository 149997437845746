import { t } from "i18n-js";
import { Button } from "@circle-react-uikit/Button";
import { Typography } from "@circle-react-uikit/Typography";

interface NoMembersProps {
  onAddMember: () => void;
}

export const NoMembers = ({ onAddMember }: NoMembersProps) => (
  <div className="flex min-h-[600px] flex-col items-center pt-44">
    <div className="mb-3">
      <Typography.TitleSm weight="semibold">
        {t("spaces.form.edit.members.no_members")}
      </Typography.TitleSm>
    </div>
    <div className="mb-8">
      <Typography.BodySm>
        {t("spaces.form.edit.members.there_are_no_members")}
      </Typography.BodySm>
    </div>
    <Button type="button" variant="secondary" large onClick={onAddMember}>
      {t("spaces.form.edit.members.add_members")}
    </Button>
  </div>
);
