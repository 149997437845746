import classnames from "classnames";
import { t } from "i18n-js";
import { MAX_TOPICS_PER_SPACE } from "@circle-react/components/QuickPostV2/Content/TopicsPicker";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { Typography } from "@circle-react-uikit/Typography";
import type { SpaceOption } from "../../SpaceAccessManagementContext";
import { i18nRoot } from "../../constants";
import { Item } from "./Item";
import { ItemButton } from "./ItemButton";
import { ItemTitleWithIcon } from "./ItemTitleWithIcon";
import { useChildItem } from "./hooks/useChildItem";

interface ChildAddableItemProp extends SpaceOption {
  onAddChild: (action: () => void, object: SpaceOption) => void;
  spaceGroupId: number;
  topicsCountEnabled?: boolean;
}

export const ChildAddableItem = ({
  topicsCountEnabled = false,
  onAddChild = action => {
    action();
  },
  spaceGroupId,
  ...space
}: ChildAddableItemProp) => {
  const { name, icon, topicsCount, tooltipText } = space;
  const { isChecked, isSpaceFound, onAddClick } = useChildItem({
    onAddChild,
    space,
    spaceGroupId,
  });

  return (
    <>
      {topicsCountEnabled &&
      topicsCount &&
      topicsCount >= MAX_TOPICS_PER_SPACE ? (
        <TippyV2
          content={t("settings.topics.space_management.topics_limit_reached")}
          interactive={false}
        >
          <Item
            isDisabled
            className={classnames({
              hidden: isChecked || !isSpaceFound,
            })}
          >
            <ItemTitleWithIcon icon={icon} tooltipText={tooltipText}>
              <Typography.LabelSm>{name}</Typography.LabelSm>
            </ItemTitleWithIcon>
          </Item>
        </TippyV2>
      ) : (
        <Item
          isDisabled={isChecked}
          onClick={onAddClick}
          className={classnames({ hidden: isChecked || !isSpaceFound })}
        >
          <ItemTitleWithIcon icon={icon} tooltipText={tooltipText}>
            <Typography.LabelSm>{name}</Typography.LabelSm>
          </ItemTitleWithIcon>
          {!isChecked && <ItemButton actionText={t([i18nRoot, "add"])} />}
        </Item>
      )}
    </>
  );
};
