import {
  CommunityFeatureFlagsContextProvider,
  CurrentCommunityContextProvider,
  SpaceGroupsContextProvider,
} from "@circle-react/contexts";
import { ToastProvider } from "@circle-react-uikit/ToastV2";
import { EditForm } from "./EditForm";

export const Edit = props => (
  <ToastProvider>
    <CurrentCommunityContextProvider>
      <SpaceGroupsContextProvider>
        <CommunityFeatureFlagsContextProvider>
          <EditForm {...props} />
        </CommunityFeatureFlagsContextProvider>
      </SpaceGroupsContextProvider>
    </CurrentCommunityContextProvider>
  </ToastProvider>
);
