import { Suspense } from "react";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { PageLoader } from "@circle-react/pages/PageLoader";

const MemberInvitationLinksComponent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "MemberInvitationLinksPage" */ "@circle-react/components/SettingsApp/InvitationLinks"
    ),
);

export const MemberInvitationLinksPage = () => (
  <Suspense fallback={<PageLoader />}>
    <MemberInvitationLinksComponent />
  </Suspense>
);
