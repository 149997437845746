import { useEffect, useState } from "react";
import { t } from "i18n-js";
import { useFormContext } from "react-hook-form";
import { usePaywallCouponList } from "@/react/hooks/paywalls/usePaywallCouponList";
import { Form } from "@circle-react-uikit/Form";

export const CheckoutUrlWithCouponSelector = () => {
  const { setValue, watch } = useFormContext();
  const paywallId = watch("id");
  const { paywallCouponsListQuery } = usePaywallCouponList({
    paywallId: paywallId || "",
    enabled: true,
  });
  const { data: couponsData } = paywallCouponsListQuery;
  const [couponsOptions, setCouponsOptions] = useState([]);
  const [isAutoApplyCouponVisible, setIsAutoApplyCouponVisible] =
    useState(false);

  useEffect(() => {
    if (couponsData) {
      const options = couponsData.map(coupon => ({
        label: coupon.code,
        value: coupon.code,
      }));
      setCouponsOptions(options);
    }
  }, [couponsData]);

  const handleAutoApplyCouponToggle = checked => {
    setIsAutoApplyCouponVisible(checked);
    if (!checked) {
      setValue("coupon_selector", "");
    }
  };

  return (
    <div className="flex flex-col gap-y-2.5">
      <Form.Item
        labelClassName="!font-normal"
        name="auto_apply_coupon"
        translationRoot="settings.paywalls.modal.tabs.details"
        fullWidth
        tooltipText={t(
          "settings.paywalls.modal.tabs.details.auto_apply_coupon_tooltip",
        )}
        hideBorder
        hideDescription
        hidePlaceholder
        inlineReverse
      >
        <Form.ToggleSwitch
          data-testid="auto_apply_coupon"
          name="auto_apply_coupon_toggle"
          variant="small"
          onToggleChange={handleAutoApplyCouponToggle}
        />
      </Form.Item>
      {isAutoApplyCouponVisible && (
        <Form.Item
          className="!pt-2"
          fullWidth
          hideBorder
          hideDescription
          hidePlaceholder
          hideLabel
          name="coupon_selector"
          translationRoot="settings.paywalls.modal.tabs.details"
        >
          <Form.SelectNative
            name="coupon_selector"
            options={[
              {
                label: t(
                  "settings.paywalls.modal.tabs.details.auto_apply_coupon_placeholder",
                ),
                value: "",
              },
              ...couponsOptions,
            ]}
          />
        </Form.Item>
      )}
    </div>
  );
};
