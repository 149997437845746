import PropTypes from "prop-types";
import { t } from "i18n-js";
import { Icon } from "@circle-react/components/shared/Icon";
import { Modal } from "@circle-react-uikit/ModalV2";
import { Typography } from "@circle-react-uikit/Typography";
import { ActionList } from "./ActionList";

export const ConfirmRerunBody = ({ rerunParams }) => {
  const rerunText =
    rerunParams?.do_not_skip_tasks == "true"
      ? t("settings.workflows.rerun_modal.all_members")
      : t("settings.workflows.rerun_modal.only_new_members");

  return (
    <Modal.Body>
      <Typography.LabelMd weight="semibold">
        {t("settings.workflows.confirm_modal.workflow_summary")}
      </Typography.LabelMd>
      <div className="pt-5">
        <div className="bg-secondary rounded-lg border p-5">
          <div className="text-default flex flex-col gap-5">
            <div className="flex flex-row items-start gap-3">
              <div>
                <Icon
                  type="24-workflow-audience"
                  size={24}
                  className="!h-6 !w-6"
                />
              </div>
              <Typography.BodySm>{rerunText}</Typography.BodySm>
            </div>
            <ActionList />
          </div>
        </div>
      </div>
    </Modal.Body>
  );
};

ConfirmRerunBody.propTypes = {
  rerunParams: PropTypes.object,
};
