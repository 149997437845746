import { receivedSubmission } from "@circle-react/components/SettingsApp/Workflows/components/Events/Forms/receivedSubmission";
import { joinedCommunity } from "./Community";
import { emailChanged } from "./CommunityMember";
import { communityMemberProfileFieldUpdated } from "./CommunityMemberProfileField";
import {
  completedCourse,
  completedLesson,
  completedSection,
  failedQuiz,
  passedQuiz,
  submittedQuiz,
} from "./Course";
import {
  eventEndedForMember,
  eventStartsInAnHour,
  memberAttendedLiveEvent,
  rsvpToEvent,
  rsvpToRecurringEvent,
} from "./Event";
import { leveledDown } from "./Gamification/leveledDown";
import { leveledUp } from "./Gamification/leveledUp";
import { memberTagged, memberUntagged } from "./MemberTag";
import {
  endedPaywallTrialWithoutSubscription,
  memberPurchasedPaywall,
  memberSubscribedPaywall,
  memberUnsubscribedPaywall,
  startedPaywallTrial,
} from "./Paywall";
import { commentCreatedOnPost, postLiked, postPublished } from "./PostBasic";
import {
  eventPublishedInSpace,
  memberJoinedSpace,
  memberLeftSpace,
} from "./Space";
import { memberJoinedSpaceGroup, memberLeftSpaceGroup } from "./SpaceGroup";
import type { EventOption } from "./types";

export const availableEvents: ReadonlyArray<EventOption> = [
  joinedCommunity,
  rsvpToEvent,
  rsvpToRecurringEvent,
  memberJoinedSpace,
  memberLeftSpace,
  memberJoinedSpaceGroup,
  memberLeftSpaceGroup,
  memberPurchasedPaywall,
  memberTagged,
  memberUntagged,
  eventEndedForMember,
  postPublished,
  commentCreatedOnPost,
  postLiked,
  completedCourse,
  completedLesson,
  completedSection,
  memberSubscribedPaywall,
  memberUnsubscribedPaywall,
  startedPaywallTrial,
  endedPaywallTrialWithoutSubscription,
  eventStartsInAnHour,
  eventPublishedInSpace,
  memberAttendedLiveEvent,
  communityMemberProfileFieldUpdated,
  emailChanged,
  leveledUp,
  leveledDown,
  receivedSubmission,
  passedQuiz,
  failedQuiz,
  submittedQuiz,
];
