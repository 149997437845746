import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useWatch } from "react-hook-form";
import { tabs } from "../../../constants";

export const usePaywallFormContentCreateFlow = (activeTab, setActiveTab) => {
  const [shouldBlockSubmitButton, setShouldBlockSubmitButton] = useState(false);

  const { control } = useFormContext();
  const displayName = useWatch({ control, name: "display_name" });
  const checkoutPath = useWatch({ control, name: "checkout_path" });
  const webPricesAttributes = useWatch({
    control,
    name: "stripe_prices_attributes",
  });
  const allSpacesAllowed = useWatch({ control, name: "all_spaces" });
  const spaceGroupIds = useWatch({ control, name: "space_group_ids" });
  const spaceIds = useWatch({ control, name: "space_ids" });

  const blockButtonByTab = (currentTab, isValid) => {
    const hasSameValueShouldBlockSubmitButton =
      shouldBlockSubmitButton === !isValid;
    if (currentTab === activeTab && !hasSameValueShouldBlockSubmitButton) {
      setShouldBlockSubmitButton(!isValid);
    }
  };

  const isDetailsTabValid = () => {
    const isValid =
      displayName && displayName !== "" && checkoutPath && checkoutPath !== "";
    blockButtonByTab(tabs.DETAILS_TAB, isValid);
    return isValid;
  };

  const isPricingTabValid = () => {
    const isValid = webPricesAttributes.some(priceAttribute => {
      if (priceAttribute.id && !priceAttribute._destroy) return true;
      if (priceAttribute.tmp_id) return true;
    });
    blockButtonByTab(tabs.PRICING_TAB, isValid);
    return isValid;
  };

  const isAccessTabValid = () => {
    const isValid =
      allSpacesAllowed || spaceGroupIds.length > 0 || spaceIds.length > 0;
    blockButtonByTab(tabs.ACCESS_TAB, isValid);
    return isValid;
  };

  // All tabs should be navigated through `Next` button, which enforces
  // an active tab even if it is disabled. That's why we are always disabling
  // the last step.
  const tabOptions = [
    {
      label: tabs.DETAILS_TAB,
      disabled: !isDetailsTabValid(),
    },
    {
      label: tabs.PRICING_TAB,
      disabled:
        activeTab === tabs.DETAILS_TAB ||
        !isDetailsTabValid() ||
        !isPricingTabValid(),
    },
    {
      label: tabs.ACCESS_TAB,
      disabled:
        [tabs.DETAILS_TAB, tabs.PRICING_TAB].includes(activeTab) ||
        !isDetailsTabValid() ||
        !isPricingTabValid() ||
        !isAccessTabValid(),
    },
    {
      label: tabs.POST_PURCHASE_TAB,
      disabled: true,
    },
    {
      label: tabs.TRACKING_TAB,
      disabled: true,
    },
  ];

  const handleTabClick = async tab => {
    setActiveTab(tab);
  };

  return {
    handleTabClick,
    tabOptions,
    shouldBlockSubmitButton,
  };
};
