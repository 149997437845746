import PropTypes from "prop-types";
import classNames from "classnames";
import { useSidebarBorderStyles } from "../hooks";

export const ProfileSectionWrapper = ({ children, className }) => {
  const styles = useSidebarBorderStyles();

  return (
    <div
      className={classNames(
        "border-c-sidebar bg-c-sidebar bottom-0 w-[inherit] border-t px-4 py-3",
        className,
      )}
      data-testid="profile-section"
      style={styles}
    >
      <div className="flex items-center justify-between">{children}</div>
    </div>
  );
};

ProfileSectionWrapper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
