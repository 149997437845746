import PropTypes from "prop-types";
import { shouldDisplayWorkflowsTab } from "@circle-react/helpers/paywallHelpers";
import { Tabs } from "@circle-react-uikit/Tabs";
import { tabs } from "../../constants";
import { AccessTab } from "../AccessTab/";
import { DetailsTab } from "../DetailsTab/";
import { PostPurchaseTab } from "../PostPurchaseTab/";
import { PricingTab } from "../PricingTab/";
import { TrackingTab } from "../TrackingTab/";
import { WorkflowsTab } from "../WorkflowsTab/";
import { ModalFooter } from "./ModalFooter";
import "./style.scss";

export const PaywallFormContent = ({
  activeTab,
  setActiveTab,
  usePaywallFormContentHook,
  mutation,
  isSubmitting,
  paywallGroupsEnabled,
  requiresCurrency,
  canChangeCurrency,
  currentPaywallStatus,
  workflowsEnabled,
}) => {
  const { handleTabClick, tabOptions, shouldBlockSubmitButton } =
    usePaywallFormContentHook(activeTab, setActiveTab);

  const renderTab = tab => {
    const tabsToRender = {
      [tabs.DETAILS_TAB]: (
        <DetailsTab
          requiresCurrency={requiresCurrency}
          canChangeCurrency={canChangeCurrency}
        />
      ),
      [tabs.PRICING_TAB]: (
        <PricingTab
          paywallGroupsEnabled={paywallGroupsEnabled}
          requiresCurrency={requiresCurrency}
        />
      ),
      [tabs.ACCESS_TAB]: <AccessTab />,
      [tabs.POST_PURCHASE_TAB]: (
        <PostPurchaseTab
          shouldDisplayWorkflowsTab={shouldDisplayWorkflowsTab(
            currentPaywallStatus,
          )}
          setActiveTab={setActiveTab}
        />
      ),
      [tabs.TRACKING_TAB]: <TrackingTab />,
      [tabs.WORKFLOWS_TAB]: <WorkflowsTab />,
    };

    return tabsToRender[tab];
  };

  const containerClassName = `mx-auto w-full ${
    activeTab === tabs.WORKFLOWS_TAB ? "max-w-screen-full" : "max-w-[720px]"
  }`;

  const filteredTabOptions =
    !workflowsEnabled || !shouldDisplayWorkflowsTab(currentPaywallStatus)
      ? tabOptions.filter(option => option.label !== tabs.WORKFLOWS_TAB)
      : tabOptions;

  const formFieldsClassName = `paywall-admin-modal__form__fields ${
    activeTab === tabs.WORKFLOWS_TAB ? "workflows-tab" : ""
  }`;

  return (
    <>
      <div className="paywall-admin-modal__tabs">
        <Tabs
          controlled
          activeTab={activeTab}
          onClick={handleTabClick}
          options={filteredTabOptions}
          variant="underline"
        />
      </div>
      <div className={formFieldsClassName}>
        <div className={containerClassName}>{renderTab(activeTab)}</div>
      </div>
      <ModalFooter
        isSubmitting={isSubmitting}
        mutation={mutation}
        activeTab={activeTab}
        shouldBlockSubmitButton={shouldBlockSubmitButton}
        currentPaywallStatus={currentPaywallStatus}
      />
    </>
  );
};

PaywallFormContent.propTypes = {
  activeTab: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  usePaywallFormContentHook: PropTypes.func.isRequired,
  mutation: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  currentPaywallStatus: PropTypes.string,
  paywallGroupsEnabled: PropTypes.bool.isRequired,
  requiresCurrency: PropTypes.bool.isRequired,
  canChangeCurrency: PropTypes.bool.isRequired,
  workflowsEnabled: PropTypes.bool,
};
