import { useState } from "react";
import { t } from "i18n-js";
import { isCommunityAdmin } from "@/react/helpers/communityMemberHelpers";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { NewV2 } from "@circle-react/components/Spaces/NewV2";
import { FORM_ID } from "@circle-react/components/Spaces/NewV2/NewForm";
import { usePunditUserContext } from "@circle-react/contexts";
import type { SpaceType } from "@circle-react/types/Space";
import { Button } from "@circle-react-uikit/Button";
import { Modal } from "@circle-react-uikit/ModalV2";
import { SpaceTypePickerModalComponent } from "../SpaceTypePickerModal";

export interface AddSpaceModalComponentProps {
  spaceGroupId?: number;
  postType?: SpaceType;
}

export const AddSpaceModalComponent = ({
  spaceGroupId,
  postType,
}: AddSpaceModalComponentProps) => {
  const drawer = useModal();
  const [isLoading, setIsLoading] = useState(false);
  return (
    <Modal
      isOpen={drawer.visible}
      onClose={drawer.hide}
      contentPortalId="create-space-content-portal"
      title={t("create_space")}
    >
      <Modal.Overlay />
      <Modal.Content size="md">
        <Modal.Header className="!px-6 md:!px-8">
          <Modal.Title size="sm">{t("create_space")}</Modal.Title>
          <Modal.HeaderActions>
            <Modal.CloseButton onClick={drawer.hide} />
          </Modal.HeaderActions>
        </Modal.Header>
        <Modal.Body className="!px-6 !py-1 md:!px-8">
          <NewV2
            onClose={drawer.hide}
            setExternalLoadingState={setIsLoading}
            currentSpaceGroupId={spaceGroupId}
            post_type={postType}
            renderHeader={() => <>{}</>}
            renderFooter={() => <>{}</>}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={isLoading} type="submit" form={FORM_ID} full large>
            {t(isLoading ? "creating" : "create_space")}
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

const AddSpaceModal = ModalManager.create(
  ({ spaceGroupId, postType }: AddSpaceModalComponentProps) => {
    const { currentCommunityMember } = usePunditUserContext();
    if (isCommunityAdmin(currentCommunityMember)) {
      return (
        <SpaceTypePickerModalComponent
          spaceGroupId={spaceGroupId}
          selectedPostType={postType}
        />
      );
    }
    return (
      <AddSpaceModalComponent spaceGroupId={spaceGroupId} postType={postType} />
    );
  },
);

export const useAddSpaceModal = () => useModal(AddSpaceModal);
