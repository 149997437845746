import { useModerationSettingsInfiniteQuery } from "./ModerationTable/useModerationSettingsInfiniteQuery";
import { STATUS_OPTIONS_MAP } from "./constants";

export const useReportsAmounts = () => {
  const { totalRecords: inboxCount } = useModerationSettingsInfiniteQuery({
    status: STATUS_OPTIONS_MAP.INBOX,
  });

  const { totalRecords: approvedCount } = useModerationSettingsInfiniteQuery({
    status: STATUS_OPTIONS_MAP.APPROVED,
  });

  const { totalRecords: rejectedCount } = useModerationSettingsInfiniteQuery({
    status: STATUS_OPTIONS_MAP.REJECTED,
  });

  const totalReports = inboxCount + approvedCount + rejectedCount;

  return {
    inboxCount,
    approvedCount,
    rejectedCount,
    totalReports,
  };
};
