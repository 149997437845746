export const isAssignedToSpace = (paywall, space) =>
  paywall.space_ids.includes(space.id);

const isAssignedToSpaceGroup = (paywall, space) =>
  paywall.space_group_ids.includes(space.space_group_id);

export const gatedBy = (paywall, space) => {
  const gatedByMethods = [];
  if (isAssignedToSpace(paywall, space)) gatedByMethods.push("Space");
  if (isAssignedToSpaceGroup(paywall, space))
    gatedByMethods.push("Space Group");

  return gatedByMethods.join(", ");
};
