import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { UpdateMemberAccessModal } from "./UpdateMemberAccessModal";
import type { UpdateMemberAccessModalProps } from "./UpdateMemberAccessModal";

const UpdateMemberAccessModalComponent = ModalManager.create(
  (props: UpdateMemberAccessModalProps) => (
    <UpdateMemberAccessModal {...props} />
  ),
);

export const useUpdateMemberAccessModal = () =>
  useModal(UpdateMemberAccessModalComponent);
