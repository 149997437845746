import classNames from "classnames";
import { PoweredByCircle } from "@circle-react/components/shared/PoweredByCircle";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { useSidebarBorderStyles } from "./hooks";

export interface SidebarPoweredByCircleProps {
  communitySlug: string;
}

export const SidebarPoweredByCircle = ({
  communitySlug,
}: SidebarPoweredByCircleProps) => {
  const styles = useSidebarBorderStyles();
  const { isV3Enabled } = useIsV3();

  return (
    <PoweredByCircle
      className={classNames("bg-c-sidebar", {
        "border-c-sidebar border-t": !isV3Enabled,
        "justify-center !p-0": isV3Enabled,
      })}
      communitySlug={communitySlug}
      styles={styles}
    />
  );
};
