import { truncate } from "lodash";
import { Link } from "react-router-dom";
import { BodySm } from "@circle-react-shared/uikit/Typography/components/Body/BodySm";
import { LabelMd } from "@circle-react-shared/uikit/Typography/components/Label/LabelMd";
import { SkeletonLoader } from "../SkeletonLoader";
import { useShowPinnedPost } from "../queries";
import "./styles.css";

export const PinnedPost = () => {
  const { isError, isLoading, data: pinnedPost } = useShowPinnedPost();

  if (isLoading) {
    return (
      <>
        <SkeletonLoader rows={1} height="52px" wrapperClassName="px-0 mb-4" />
        <SkeletonLoader rows={1} height="200px" wrapperClassName="px-4 mb-4" />
      </>
    );
  }

  if (isError) {
    return null;
  }

  if (Object.keys(pinnedPost).length === 0) {
    return null;
  }

  return (
    <Link
      className="border-primary bg-primary block cursor-pointer rounded-lg border p-4"
      to={`/news/${pinnedPost.id}`}
    >
      {pinnedPost.cover_image_url && (
        <div>
          <img
            loading="lazy"
            alt=""
            className="mb-4 rounded"
            src={pinnedPost.cover_image_url}
          />
        </div>
      )}
      <div className="text-dark mb-1">
        <div className="news-title pb-3">
          <LabelMd weight="semibold">{pinnedPost.name}</LabelMd>
        </div>
        <div className="news-excerpt">
          <BodySm>
            {truncate(pinnedPost.body_plain_text, { length: 200 })}
          </BodySm>
        </div>
      </div>
    </Link>
  );
};
