import type { ReactNode } from "react";
import classNames from "classnames";

export interface SidebarBadgeProps {
  children: ReactNode;
  isGray?: boolean;
  ariaLabel?: string;
}

export const SidebarBadge = ({
  children,
  isGray = false,
  ariaLabel,
}: SidebarBadgeProps) => (
  <div
    className={classNames(
      "ml-auto min-w-[1.75rem] rounded px-1.5 py-1 text-center text-[11px] font-semibold leading-[11px]",
      {
        "bg-c-sidebar-badge text-c-sidebar-badge-text": !isGray,
        "border-primary bg-primary group-hover:bg-primary text-dark my-[-1px] border":
          isGray,
      },
    )}
    data-testid="unread-count"
    aria-label={ariaLabel}
  >
    {children}
  </div>
);
