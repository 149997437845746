export const getOnClickFunctionForResultType = result => {
  // TODO change this when React router is connected to layout
  const resultTypeFunctionMap = {
    post: () =>
      (window.location.href = `/c/${result.space_slug}/${result.slug}`),
    community_member: () => (window.location.href = `/u/${result.public_uid}`),
    comment: () =>
      (window.location.href = `/c/${result.space_slug}/${result.post_slug}#comment_wrapper_${result.id}`),
    space: () => (window.location.href = `/c/${result.space_slug}`),
  };
  return resultTypeFunctionMap[result.type];
};

export const getRouteForResultType = result => {
  const { type } = result;
  const resultsMap = {
    post: `/c/${result.space_slug}/${result.slug}`,
    community_member: `/u/${result.public_uid}`,
    comment: `/c/${result.space_slug}/${result.post_slug}#comment_wrapper_${result.id}`,
    space: `/c/${result.slug}`,
  };
  return resultsMap[type];
};

export const trimString = text => (text ? text.trim() : "");
