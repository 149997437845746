import { useCallback, useRef, useState } from "react";
import PropTypes from "prop-types";
import { t } from "i18n-js";
import isFunction from "lodash/isFunction";
import { NavLink } from "react-router-dom";
import { CustomizeLayoutForm } from "@/react/components/Spaces/CustomizeSpaceFullPage/CustomizeLayoutForm";
import { CustomizeEventLayoutForm } from "@/react/components/Spaces/CustomizeSpaceFullPage/CustomizeLayoutForm/CustomizeEventLayoutForm";
import { GeneralForm } from "@/react/components/Spaces/CustomizeSpaceFullPage/GeneralForm";
import { Section } from "@/react/components/Spaces/CustomizeSpaceFullPage/Section";
import { SpaceImagesForm } from "@/react/components/Spaces/CustomizeSpaceFullPage/SpaceImagesForm";
import { EditSpaceForm } from "@/react/components/Spaces/EditV2/EditForm/EditSpaceForm";
import {
  isBasicPostsSpace,
  isChatSpace,
  isCourseSpace,
  isDraftSpace,
  isEventsSpace,
  isImagesSpace,
  isMembersSpace,
} from "@/react/helpers/spaceHelpers";
import { CustomizeTopicsForm } from "@circle-react/components/Spaces/CustomizeSpaceFullPage/CustomizeTopicsForm";
import { usePunditUserContext } from "@circle-react/contexts";
import {
  DIRTY_FORM_INITIAL_STATES,
  useCustomizeSpaceContext,
} from "@circle-react/contexts/customizeSpaceContext";
import { useCustomizeUIData } from "@circle-react-shared/CustomizeUI";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { SectionCard } from "@circle-react-uikit/SectionCard";
import { useToast } from "@circle-react-uikit/ToastV2";
import { WelcomeBanner } from "../../WelcomeBanner/Form";
import { CustomizeTopicsOptionsForm } from "../CustomizeTopicsOptionsForm";
import { AccessSection } from "./AccessSection";
import { DraftVisibilityConfirmModal } from "./DraftVisibilityConfirmModal";
import { useDraftVisibilityConfirmModal } from "./hooks/useDraftVisibilityConfirmModal";

export const CustomizeSpaceForm = ({ space }) => {
  const triggerFormSubmitRef = useRef();
  const toast = useToast();
  const [dirtyWelcomeBanner, setDirtyWelcomeBanner] = useState({
    loggedIn: false,
    loggedOut: false,
  });
  const { dirtyForms, setDirtyForms } = useCustomizeSpaceContext();
  const { onSave: onSubmit, onExit } = useCustomizeUIData();
  const isSpaceChatSpace = isChatSpace(space);
  const isSpaceMembersSpace = isMembersSpace(space);
  const { currentCommunitySettings } = usePunditUserContext();
  const {
    space_directory_enabled: spaceDirectoryEnabled,
    space_description_enabled: spaceDescriptionEnabled,
    topics_enabled: topicsEnabled,
    course_topics_enabled: courseTopicsEnabled,
    event_space_calendar_view_enabled: isEventSpaceCalendarViewEnabled,
  } = currentCommunitySettings || {};

  const isPostOrImageSpaceWithTopics =
    (isBasicPostsSpace(space) || isImagesSpace(space)) && topicsEnabled;
  const isCourseSpaceWithTopics = isCourseSpace(space) && courseTopicsEnabled;
  const shouldDisplaySpaceTopics =
    isPostOrImageSpaceWithTopics ||
    isEventsSpace(space) ||
    isCourseSpaceWithTopics;

  const onDraftVisibilityConfirmModalSubmit = useCallback(() => {
    triggerFormSubmitRef?.current();
  }, [triggerFormSubmitRef]);

  const {
    shouldShowDraftAccessConfirmaModal,
    toggleDraftAccessConfirmaModal,
    isDraftAccessConfirmed,
    toggleIsDraftAccessConfirmed,
  } = useDraftVisibilityConfirmModal(onDraftVisibilityConfirmModalSubmit);

  const onChangeFormField = key => {
    setDirtyForms({
      ...dirtyForms,
      customize: {
        ...dirtyForms.customize,
        [key]: true,
      },
    });
  };

  const onConfirmDraftAccess = () => {
    toggleDraftAccessConfirmaModal();
    toggleIsDraftAccessConfirmed();
  };

  const setWelcomeBannerDirtyState = (key, value) => {
    setDirtyWelcomeBanner({
      ...dirtyWelcomeBanner,
      [key]: value,
    });
  };

  const handleSubmit = async (data, reset) => {
    const { visibility } = data;
    if (dirtyWelcomeBanner.loggedIn || dirtyWelcomeBanner.loggedOut) {
      toast.error(
        t("landing_page.settings.welcome_banner.errors.blank_welcome_banner"),
      );
      return;
    }
    const shouldConfirmDraftVisibility =
      isDraftSpace(space) && visibility !== "draft" && !isDraftAccessConfirmed;

    if (shouldConfirmDraftVisibility) {
      toggleDraftAccessConfirmaModal();
      return false;
    }

    await onSubmit({ data });
    isFunction(reset) && reset(data, { keepValues: true });
    setDirtyForms(DIRTY_FORM_INITIAL_STATES);
    onExit();
  };

  const hideImagesSection =
    (isSpaceChatSpace || isSpaceMembersSpace) && !spaceDirectoryEnabled;

  return (
    <Section>
      <EditSpaceForm
        onSubmit={(data, { reset }) => handleSubmit(data, reset)}
        space={space}
        className="mx-auto flex h-full flex-col"
        formClassName="flex flex-col pb-8 gap-8 md:gap-12"
        triggerFormSubmitRef={triggerFormSubmitRef}
      >
        <SectionCard
          title={t("customize_space.general")}
          dataTestId="customize-space-general"
          variant="fullScreen"
          isFirstChild
        >
          <GeneralForm
            onChange={() => onChangeFormField("general")}
            space={space}
          />
        </SectionCard>
        <AccessSection
          space={space}
          onChange={() => onChangeFormField("access")}
        />
        {(isBasicPostsSpace(space) ||
          isSpaceMembersSpace ||
          isImagesSpace(space)) && (
          <SectionCard
            title={t("customize_space.customize_layout")}
            dataTestId="customize-space-layout"
            variant="fullScreen"
          >
            <div className="h-full">
              <CustomizeLayoutForm
                space={space}
                onChange={() => onChangeFormField("customizeLayout")}
              />
            </div>
          </SectionCard>
        )}
        {shouldDisplaySpaceTopics && (
          <SectionCard
            title={
              isCourseSpaceWithTopics
                ? t("customize_space.course_topics.title")
                : t("customize_space.topics.title")
            }
            dataTestId="customize-space-topics"
            variant="fullScreen"
            isFirstChild
          >
            <div className="my-1 md:mb-4">
              <Typography.BodyMd color="text-light">
                {isCourseSpaceWithTopics
                  ? t("customize_space.course_topics.description")
                  : t("customize_space.topics.description")}
                <NavLink
                  onClick={onExit}
                  to="/settings/topics"
                  className="text-light hover:text-dark ml-0.5"
                >
                  <u>
                    {isCourseSpaceWithTopics
                      ? t("customize_space.course_topics.manage_topics")
                      : t("customize_space.topics.manage_topics")}
                  </u>
                </NavLink>
              </Typography.BodyMd>
            </div>
            <CustomizeTopicsForm
              spaceId={space.id}
              onChange={() => onChangeFormField("topics")}
              sortable={!isCourseSpaceWithTopics}
            />
            {!isCourseSpaceWithTopics && (
              <CustomizeTopicsOptionsForm
                onChange={() => onChangeFormField("topics")}
              />
            )}
          </SectionCard>
        )}

        {isEventsSpace(space) && (
          <SectionCard
            title={t("customize_space.customize_layout")}
            dataTestId="customize-space-layout"
            variant="fullScreen"
          >
            <div className="h-full">
              <CustomizeEventLayoutForm
                space={space}
                isEventSpaceCalendarViewEnabled={
                  isEventSpaceCalendarViewEnabled
                }
                onChange={() => onChangeFormField("customizeLayout")}
              />
            </div>
          </SectionCard>
        )}
        {!hideImagesSection && (
          <SectionCard
            title={t("customize_space.space_images")}
            dataTestId="customize-space-images"
            variant="fullScreen"
          >
            <SpaceImagesForm
              space={space}
              onChange={() => onChangeFormField("images")}
            />
          </SectionCard>
        )}
        {spaceDescriptionEnabled && isEventsSpace(space) && (
          <div id="space-welcome-banner">
            <SectionCard
              title={t("customize_space.welcome_banner.title")}
              dataTestId="customize-space-welcome-banner"
              variant="fullScreen"
            >
              <WelcomeBanner
                setDirty={setWelcomeBannerDirtyState}
                onChange={() => onChangeFormField("general")}
                space={space}
              />
            </SectionCard>
          </div>
        )}

        <DraftVisibilityConfirmModal
          isOpen={shouldShowDraftAccessConfirmaModal}
          onClose={toggleDraftAccessConfirmaModal}
          onConfirm={onConfirmDraftAccess}
          onCancel={() => {
            onChangeFormField("access");
          }}
          spaceType={isCourseSpace(space) ? "Course" : "Space"}
        />
      </EditSpaceForm>
    </Section>
  );
};

CustomizeSpaceForm.propTypes = {
  space: PropTypes.object,
};
