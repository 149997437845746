import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import invariant from "tiny-invariant";
import { t } from "@circle-react/custom_i18n";
import { workflowsSettingsPaths } from "@circle-react/helpers/urlHelpers";
import { useIsWorkflowsV2Enabled } from "@circle-react/hooks/useIsWorkflowsV2Enabled";
import { BroadcastTipTapReadonlyView } from "@circle-react-shared/Emails/EmailPreview/BroadcastTipTapReadonlyView";
import { Button } from "@circle-react-shared/uikit/Button";
import { SkeletonLoader } from "@circle-react-shared/uikit/SkeletonLoader";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { useActions, useCurrentAction } from "../../../ActionRule";
import { useResourceStatus } from "../../../WorkflowForm/useResourceStatus";
import { useUpsertBroadcast } from "./useUpsertBroadcast";

const localeNamespace = "settings.workflows.form";

export function EmailContentField() {
  const isWorkflowsV2Enabled = useIsWorkflowsV2Enabled();
  const { watch, setValue } = useFormContext();
  const { paramsPath } = useCurrentAction();
  const history = useHistory();
  const { id: workflowId } = useParams<{ id: string }>();

  const emailContent = watch(`${paramsPath}.email_content`);
  const existingBroadcastId = watch(`${paramsPath}.broadcast_id`);

  const { onUpsert } = useUpsertBroadcast(
    existingBroadcastId ? Number(existingBroadcastId) : null,
  );

  const { doesActionhasServerId, saveAction } = useActions();
  const { action, path } = useCurrentAction();
  const { value } = useResourceStatus(path);

  useEffect(() => {
    const createBroadcast = async () => {
      const isDuplicatedWorkflowSendEmailAction =
        doesActionhasServerId(action) && emailContent && !existingBroadcastId;

      if (isDuplicatedWorkflowSendEmailAction) {
        const data = await onUpsert();
        const broadcastId = data?.id;
        invariant(
          broadcastId,
          "Broadcast ID is required to start writing email",
        );
        const currentAction = isWorkflowsV2Enabled ? value : action;
        setValue(`${paramsPath}.broadcast_id`, broadcastId);
        await saveAction({ path, action: currentAction, skipOpenNext: true });
      }
    };

    void createBroadcast();
  }, []);

  const handleStartWriting = async () => {
    const data = await onUpsert();
    const broadcastId = data?.id;
    invariant(broadcastId, "Broadcast ID is required to start writing email");
    setValue(`${paramsPath}.broadcast_id`, broadcastId);
    history.push(
      workflowsSettingsPaths.edit_send_email({ workflowId, broadcastId }),
    );
  };

  return (
    <div className="flex flex-col">
      <div className="border-primary flex justify-between rounded-b-none rounded-t-lg border px-5 py-4">
        <div className="flex flex-col gap-y-1">
          <Typography.LabelSm weight="semibold" color="text-very-dark">
            {t([localeNamespace, "email_content"])}
          </Typography.LabelSm>
          <Typography.LabelXs color="text-default">
            {t([localeNamespace, "email_content_description"])}
          </Typography.LabelXs>
        </div>
        <div>
          <Button
            variant="secondary"
            type="button"
            onClick={handleStartWriting}
          >
            {emailContent ? t("edit") : t([localeNamespace, "start_writing"])}
          </Button>
        </div>
      </div>
      <div className="bg-secondary border-primary h-64 overflow-y-auto rounded-b-lg border-x border-b py-6">
        <div className="bg-primary mx-auto max-w-[500px] rounded-lg p-8 shadow-sm">
          {emailContent ? (
            <BroadcastTipTapReadonlyView content={emailContent} />
          ) : (
            <SkeletonLoader variant="email-preview" />
          )}
        </div>
      </div>
    </div>
  );
}
