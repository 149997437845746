import { useEffect, useLayoutEffect } from "react";
import { useLiveUI } from "@circle-react/components/LiveStreams/Room/store";
import { useLiveStreamContextData } from "@circle-react/contexts/LiveStreams";
import {
  isHLSSpectatorRole,
  isSpectatorRole,
} from "@circle-react/helpers/liveStreamRoomHelpers";

export const useSidebarBehavior = () => {
  const { currentParticipant } = useLiveStreamContextData();
  const { displayChat, isSidebarOpen, isChatSelected } = useLiveUI();

  useEffect(() => {
    if (
      isSpectatorRole(currentParticipant) ||
      isHLSSpectatorRole(currentParticipant)
    ) {
      displayChat();
    }
  }, [currentParticipant, displayChat]);

  useLayoutEffect(() => {
    if (isSidebarOpen && isChatSelected) {
      // This is hacky and ugly, using until we start refactoring
      // chat as a solution to scroll down once chat becomes visible.
      // TODO: We should use a ref or listen to a callback from chat
      // Notion: https://www.notion.so/circleco/Live-Video-Add-message-scroll-view-ref-prop-to-embedded-chat-component-8ebb9d733e674dcf83a36692920904ec?pvs=4
      const element = document.getElementById("message-scroll-view");
      if (element) {
        element.scrollTop = element.scrollHeight;
      }
    }
  }, [isChatSelected, isSidebarOpen]);
};
