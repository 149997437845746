import PropTypes from "prop-types";
import classNames from "classnames";

export const Section = ({ className, children }) => (
  <div className={classNames("flex flex-col gap-2", className)}>{children}</div>
);

Section.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};
