import { t } from "i18n-js";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  reactQueryDelete,
  reactQueryGet,
  reactQueryPost,
  reactQueryPut,
} from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { useToast } from "@circle-react-shared/uikit/ToastV2";

const INDEX_QUERY_KEY = ["internal_api", "community_bot", "snippets"];

interface DeleteSnippetRequest {
  id: number;
}

interface UseModalProps {
  onSuccess: () => void;
}

interface EditSnippetRequest {
  id: number;
  title: string;
}

interface ApiEditSnippetRequest {
  title: string;
}

interface ApiEditSnippetResponse {
  id: number;
  title: string;
}

interface Snippet {
  title: string;
  body: string;
}

interface SnippetItem {
  id: number;
  title: string;
  body: string;
}

interface SnippetProp {
  name: string;
  body: object;
}

interface SnippetResponse {
  records: Array<SnippetProp>;
  count: number;
}

export const useIndexSnippets = ({ currentPage }: { currentPage: number }) =>
  useQuery<SnippetResponse>({
    queryKey: INDEX_QUERY_KEY,
    queryFn: () =>
      reactQueryGet(
        internalApi.communityBot.snippets.index({
          params: {
            page: currentPage,
            per_page: 10,
          },
        }),
      ),
  });

export const useShowSnippet = ({ id }: { id: number }) =>
  useQuery<SnippetItem, Error>(["snippets", id], () =>
    reactQueryGet(internalApi.communityBot.snippets.show({ id })),
  );

export const useDeleteSnippet = ({ onSuccess }: UseModalProps) => {
  const queryClient = useQueryClient();
  const { success, error } = useToast();

  return useMutation<void, Error, DeleteSnippetRequest>(
    (data: DeleteSnippetRequest) =>
      reactQueryDelete(
        internalApi.communityBot.snippets.destroy({ id: data.id }),
      ),
    {
      onSuccess: (_, variables) => {
        void queryClient.invalidateQueries(INDEX_QUERY_KEY);
        void queryClient.invalidateQueries(["snippet", variables.id]);
        onSuccess();
        success(
          t(
            "settings.community_bot.knowledge.custom.delete_snippet_modal.success_message",
          ),
        );
      },
      onError: () => {
        error(t("request_failure_message"));
      },
    },
  );
};

export const useEditSnippet = ({ onSuccess }: UseModalProps) => {
  const queryClient = useQueryClient();
  const { success, error } = useToast();

  return useMutation<ApiEditSnippetResponse, Error, EditSnippetRequest>(
    (data: EditSnippetRequest) => {
      const { id, ...rest } = data;
      const apiData: ApiEditSnippetRequest = {
        ...rest,
      };
      return reactQueryPut(
        internalApi.communityBot.snippets.update({ id }),
        apiData,
      );
    },
    {
      onSuccess: data => {
        void queryClient.invalidateQueries(INDEX_QUERY_KEY);
        void queryClient.invalidateQueries(["snippets", data.id]);
        onSuccess();
        success(t("changes_saved"));
      },
      onError: () => {
        error(t("request_failure_message"));
      },
    },
  );
};

export const useCreateSnippet = ({ onSuccess }: { onSuccess: () => void }) => {
  const { success, error } = useToast();
  const queryClient = useQueryClient();

  return useMutation(
    (formData: Snippet) =>
      reactQueryPost(internalApi.communityBot.snippets.create(), {
        snippet: formData,
      }),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(INDEX_QUERY_KEY);
        success(t("settings.community_bot.knowledge.custom.snippet_created"));
        onSuccess();
      },
      onError: () => error(t("quick_post.toasts.failed_to_create")),
    },
  );
};
