import { isEmpty } from "lodash";
import { mapCurrencyAmountStrToInt } from "@circle-react/components/Paywalls/Admin/utils";
import type { NoticeScenarioStateValidator } from "../NoticeScenario.types";

export const onetimeStateValidator: NoticeScenarioStateValidator = ({
  watch,
}) => mapCurrencyAmountStrToInt(watch("amount")) > 0;

export const recurringStateValidator: NoticeScenarioStateValidator = ({
  watch,
}) =>
  mapCurrencyAmountStrToInt(watch("amount")) > 0 &&
  !isEmpty(watch("billing_frequency"));

export const installmentsStateValidator: NoticeScenarioStateValidator = ({
  watch,
}) =>
  mapCurrencyAmountStrToInt(watch("amount")) > 0 &&
  !isEmpty(watch("billing_frequency")) &&
  parseInt(watch("installments_count")) >= 2;
