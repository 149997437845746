import { t } from "i18n-js";
import { Header } from "@/react/components/Chat/MessagingV2/RailBar/Header";
import { Profile } from "@/react/components/Profile/Profile";
import { RailBarPortal } from "@circle-react/components/Layout/StandardLayoutV2/RailBarPortal";
import { PROFILE_PAGES } from "@circle-react/helpers/memberHelper";
import { useProfileInfo } from "@circle-react/hooks/profile/useProfileInfo";
import { useElementHeight } from "@circle-react/hooks/utils/useElementHeight";
import { Loader } from "@circle-react-shared/uikit/Loader";
import { Rail } from "@circle-react-uikit/Rail";

const params = {
  page_name: PROFILE_PAGES.PROFILE_VIEW,
};

export const CommunityMemberRailbar = ({
  communityMember = {},
}: {
  communityMember: any;
}) => {
  const {
    getProfileInfoQuery: { data: member = {}, isLoading: isProfileLoading },
  } = useProfileInfo({
    publicId: communityMember?.public_uid,
    params,
  });

  const { ref: headerRef, height: headerHeight } = useElementHeight();

  return (
    <RailBarPortal>
      <div ref={headerRef}>
        <Rail.Header className="!min-h-[4rem]">
          <Header title={t("profile")} isDirect />
        </Rail.Header>
      </div>
      <Rail.Content noPadding heightGap={headerHeight}>
        {isProfileLoading ? (
          <Loader center />
        ) : (
          <Profile
            memberPublicId={communityMember?.public_uid}
            member={member}
            isFullPageProfile={false}
            isRenderedOnRailBar
            shouldHideMessageButton
            shouldHideBadge
          />
        )}
      </Rail.Content>
    </RailBarPortal>
  );
};
