import I18n from "i18n-js";
import { useFormContext } from "react-hook-form";
import slugify from "slugify";
import { ASPECT_RATIO } from "@circle-react/components/constants";
import { Collapsible } from "@circle-react/components/shared/uikit/Collapsible";
import { ImageInputBanner } from "@circle-react/components/shared/uikit/CoverImageInput/ImageInputBanner";
import { CoverImageInput } from "@circle-react-uikit/CoverImageInput";
import { Form } from "@circle-react-uikit/Form";

const SEO = ({ setImageToDelete }) => {
  const fields = [
    {
      name: "slug",
      label: I18n.t("spaces.form.edit.options.seo.slug"),
      description: I18n.t("spaces.form.edit.options.seo.slug_description", {
        host: window.location.host,
      }),
      transformValue: value =>
        slugify(value, {
          replacement: "-",
          remove: /[*+~.;()'"!:@#]/g,
          lower: true,
        }),
    },
    {
      name: "meta_tag_attributes[meta_title]",
      label: I18n.t("spaces.form.edit.options.seo.meta_title"),
    },
    {
      name: "meta_tag_attributes[meta_description]",
      label: I18n.t("spaces.form.edit.options.seo.meta_description"),
    },
    {
      name: "meta_tag_attributes[opengraph_title]",
      label: I18n.t("spaces.form.edit.options.seo.opengraph_title"),
    },
    {
      name: "meta_tag_attributes[opengraph_description]",
      label: I18n.t("spaces.form.edit.options.seo.opengraph_description"),
    },
  ];
  const { getValues } = useFormContext();
  return (
    <Collapsible title={I18n.t("spaces.form.edit.options.seo.label")}>
      <div className="edit-space__container">
        {fields.map(({ name, label, description, transformValue }) => (
          <Form.Item
            key={name}
            hideBorder
            hideDescription={!description}
            label={label}
            name={name}
            transformValue={transformValue}
            description={description}
            variant="extra-bottom-margin"
          >
            <Form.Input />
          </Form.Item>
        ))}
        <div className="edit-space__image-upload">
          <Form.Label
            hideBorder
            label={I18n.t("spaces.form.edit.options.seo.opengraph_image.label")}
          />
          <CoverImageInput
            name="meta_tag_attributes[opengraph_image]"
            alt=""
            className="relative"
            showAddCoverBtn
            aspectRatio={ASPECT_RATIO.OPEN_GRAPH}
            changeImageText={I18n.t(
              "spaces.form.edit.options.seo.opengraph_image.change",
            )}
            onDelete={() =>
              setImageToDelete(
                getValues("meta_tag_attributes[opengraph_image_id]"),
              )
            }
            customAddCoverBtn={
              <ImageInputBanner
                title={I18n.t(
                  "spaces.form.edit.options.seo.opengraph_image.add",
                )}
                description={I18n.t(
                  "spaces.form.edit.options.seo.opengraph_image.description",
                )}
              />
            }
          />
        </div>
      </div>
    </Collapsible>
  );
};

export default SEO;
