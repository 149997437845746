import type { ComponentPropsWithoutRef } from "react";
import classNames from "classnames";
import { useIsV3 } from "@circle-react/hooks/useIsV3";

export type SpaceListViewProps = ComponentPropsWithoutRef<"div">;

export const SpaceListView = ({ children, ...rest }: SpaceListViewProps) => {
  const { isV3Enabled } = useIsV3();

  return (
    <div
      className={classNames("flex flex-col", {
        "gap-y-1": isV3Enabled,
      })}
      {...rest}
    >
      {children}
    </div>
  );
};
