import { Suspense } from "react";
import { StandardLayoutV2 } from "@circle-react/components/Layout/StandardLayoutV2";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { PageLoader } from "@circle-react/pages/PageLoader";

const ProfileComponent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "ProfilePage" */ "@circle-react/components/Profile/FullPage"
    ),
);

export const ProfilePage = () => (
  <StandardLayoutV2.Body>
    <Suspense fallback={<PageLoader />}>
      <ProfileComponent />
    </Suspense>
  </StandardLayoutV2.Body>
);
