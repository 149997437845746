import { useMemo } from "react";
import { t } from "i18n-js";
import difference from "lodash/difference";
import { toQueryString } from "@/react/helpers/urlHelpers";
import { useRouterQueryParams } from "@/react/hooks/useRouterQueryParams";

export const SORT_TYPES = [
  "latest",
  "new_activity",
  "oldest",
  "popular",
  "likes",
  "alphabetical",
];

export const useSortBy = ({
  defaultSort = "latest",
  include = SORT_TYPES,
  exclude = [],
}) => {
  const defaultValue = SORT_TYPES.includes(defaultSort)
    ? defaultSort
    : include[0];
  const { sort = defaultValue, ...rest } = useRouterQueryParams();

  const sortingOptions = useMemo(() => {
    const availableSortTypes = difference(include, exclude);
    defaultSort = defaultSort || availableSortTypes[0];
    return availableSortTypes.map(sortType => ({
      value: sortType,
      label: t(`sorting_options.${sortType}`),
      pathTo: `?${toQueryString({ ...rest, sort: sortType })}`,
    }));
  }, [defaultSort, include, exclude, rest, sort]);

  return {
    sort,
    sortingOptions,
  };
};
