import PropTypes from "prop-types";
import { Form } from "@circle-react-uikit/Form";
import { Advanced } from "./Advanced";
import { BasicInfo } from "./BasicInfo";

const defaultFormValues = {};

export const NewSpaceGroupForm = ({ onSubmit, formId }) => (
  <Form
    id={formId}
    onSubmit={onSubmit}
    defaultValues={defaultFormValues}
    className="h-full"
  >
    <div className="flex-1">
      <BasicInfo />
      <Advanced />
    </div>
  </Form>
);

NewSpaceGroupForm.propTypes = {
  onSubmit: PropTypes.func,
  formId: PropTypes.string,
};
