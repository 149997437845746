import I18n from "i18n-js";
import { types } from "@circle-react/helpers/paywallCouponHelpers";

export const getTypeOptions = () => [
  {
    label: I18n.t("settings.paywall_coupons.form.type_options.fixed"),
    value: types.fixed,
  },
  {
    label: I18n.t("settings.paywall_coupons.form.type_options.percent"),
    value: types.percent,
  },
];

export const getDurationOptions = () => [
  {
    label: I18n.t("settings.paywall_coupons.form.duration_options.forever"),
    value: "forever",
  },
  {
    label: I18n.t("settings.paywall_coupons.form.duration_options.once"),
    value: "once",
  },
  {
    label: I18n.t("settings.paywall_coupons.form.duration_options.repeating"),
    value: "repeating",
  },
];

export const getEndDateOptions = () => [
  {
    label: I18n.t("settings.paywall_coupons.form.date_options.disable"),
    value: false,
  },
  {
    label: I18n.t("settings.paywall_coupons.form.date_options.until"),
    value: true,
  },
];

export const getStartDateOptions = () => [
  {
    label: I18n.t("settings.paywall_coupons.form.date_options.immediately"),
    value: false,
  },
  {
    label: I18n.t("settings.paywall_coupons.form.date_options.set_date"),
    value: true,
  },
];

export const getMaxRedemptionOptions = () => [
  {
    label: I18n.t("settings.paywall_coupons.form.limit_options.disable"),
    value: false,
  },
  {
    label: I18n.t("settings.paywall_coupons.form.limit_options.enable"),
    value: true,
  },
];

export const getPaywallOptions = () => [
  {
    label: I18n.t("settings.paywall_coupons.form.paywall_options.all"),
    value: true,
  },
  {
    label: I18n.t("settings.paywall_coupons.form.paywall_options.specific"),
    value: false,
  },
];

export const AMOUNT_REGEX =
  /^((0?0?\.([1-9]\d*|0[1-9]\d*))|(([1-9]|0[1-9])\d*(\.\d+)?))$/;
