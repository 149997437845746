import { CourseTypePickerOptions } from "@/react/components/Spaces/CourseSpace/CourseTypePicker/CourseTypePickerOptions";
import type { CourseSettingFormValues } from "@/react/components/Spaces/CourseSpace/hooks";
import { Form } from "@circle-react-uikit/Form";

export const FORM_ID = "edit-course-type-form";

interface CourseTypePickerFormProps {
  defaultValues: CourseSettingFormValues;
  onSubmit: (payload: CourseSettingFormValues) => void;
}

export const CourseTypePickerForm = ({
  defaultValues,
  onSubmit,
}: CourseTypePickerFormProps) => (
  <Form
    id={FORM_ID}
    onSubmit={onSubmit}
    defaultValues={defaultValues}
    validationSchema={undefined}
    className={undefined}
    formClassName={undefined}
  >
    <CourseTypePickerOptions hideLabel />
  </Form>
);
