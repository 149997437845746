import { t } from "i18n-js";
import { useSpaceGroupsContext } from "@circle-react/contexts";
import { FormTextSelect } from "@circle-react-uikit/Form/FormTextSelect";
import { Loader } from "@circle-react-uikit/Loader";
import { Typography } from "@circle-react-uikit/Typography";
import { useCurrentAction } from "../../../ActionRule";

const i18nRoot = "settings.workflows.form";

export const FormSelectSpaceGroup = () => {
  const { paramsPath } = useCurrentAction();

  const { records: spaceGroups, isLoading, isError } = useSpaceGroupsContext();

  const options = spaceGroups.map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  if (isLoading) {
    return <Loader center />;
  }

  if (isError) {
    return (
      <Typography.LabelXs>
        <span className="text-v2-danger">
          {t([i18nRoot, "space_groups.fetch_failed"])}
        </span>
      </Typography.LabelXs>
    );
  }

  return (
    <FormTextSelect
      name={`${paramsPath}.space_group_id`}
      label={
        <Typography.LabelSm weight="semibold">
          {t([i18nRoot, "select_space_group"])}
        </Typography.LabelSm>
      }
      options={options}
      rules={{
        required: t([i18nRoot, "errors.required"]),
      }}
      emptyMessage={t([i18nRoot, "errors.space_groups.not_found"])}
    />
  );
};

FormSelectSpaceGroup.propTypes = {};
