import { isFinite } from "lodash";
import { useQuery } from "react-query";
import { paywallApi } from "@/react/api/paywallApi";
import type { Params } from "@circle-react/helpers/urlHelpers";
import { toQueryString } from "@circle-react/helpers/urlHelpers";

export interface UseGetPaywallPresenterOptions {
  trial_period_days?: number;
}
export const useGetPaywall = (
  paywallId: string,
  presenterOptions: UseGetPaywallPresenterOptions = {},
) => {
  let paramsStr: string = "";
  const params: Params = {};
  if (presenterOptions) {
    if (isFinite(presenterOptions.trial_period_days)) {
      params["presenter_options[trial_period_days]"] =
        presenterOptions.trial_period_days;
    }
    paramsStr = toQueryString(params);
  }
  return useQuery({
    queryKey: ["paywall-show", paywallId, paramsStr],
    queryFn: () => paywallApi.getPaywall(paywallId, params),
  });
};
