import React from "react";
import classNames from "classnames";
import isFunction from "lodash/isFunction";
import { useIsWorkflowsV2Enabled } from "@circle-react/hooks/useIsWorkflowsV2Enabled";

const TemplateItemWrapper = ({ children, onClick, centerContent = false }) => {
  const hasOnClick = isFunction(onClick);
  const isWorkflowsV2Enabled = useIsWorkflowsV2Enabled();

  const clickProps = hasOnClick
    ? {
        role: "button",
        onClick,
      }
    : {};

  return (
    <div
      className={classNames(
        "bg-primary border-primary hover:shadow-xs hover:border-secondary flex w-full max-w-xs flex-col gap-5 border p-5 transition-colors duration-75",
        {
          "cursor-pointer": hasOnClick,
          "items-center justify-center text-center": centerContent,
          "h-[205px] rounded-lg": !isWorkflowsV2Enabled,
          "h-auto w-[314px] rounded-xl": isWorkflowsV2Enabled,
        },
      )}
      {...clickProps}
    >
      {children}
    </div>
  );
};

export default TemplateItemWrapper;
