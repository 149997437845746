import { useState } from "react";
import PropTypes from "prop-types";
import { isNull, isObject, isUndefined } from "lodash";
import { isEmpty } from "lodash";
import { useFormContext } from "react-hook-form";
import { attachmentFilesPath } from "@/react/helpers/urlHelpers";
import { CircularProgress } from "@circle-react/components/shared/uikit/TipTap/AttachmentPreviews/CircularProgress";
import { reactQueryDelete } from "@circle-react/helpers/backendRequestHelpers";
import { blobPath } from "@circle-react/helpers/urlHelpers";
import { Uploader } from "@circle-react-shared/Uploader";
import { BANNER_IMAGE_KEYS } from "../helpers";
import { DropZone } from "./DropZone";
import { FieldWrapper } from "./FieldWrapper";
import { Preview } from "./Preview";

export const CoverImage = ({
  name = "cover_image",
  title,
  description,
  disabled = false,
  removeAttachmentsPath = "",
  removeAttachmentsParams = {},
  isVideo = false,
  isSpaceWelcomeBanner = false,
}) => {
  const { getValues, setValue } = useFormContext();
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const image = getValues(name);

  let initialState = {};
  if (isObject(image)) {
    initialState = image;
  } else if (!(isObject(image) || isNull(image) || isUndefined(image))) {
    const imageUrl = blobPath({ filename: name, signed_id: image });
    initialState = { url: imageUrl, type: isVideo ? "video" : "image" };
  }

  const [previewMedia, setPreviewMedia] = useState(initialState);
  const { url, type } = previewMedia;

  const uploadProgress = progress => {
    setIsLoading(true);
    setProgress(progress);
  };

  const handleUpload = files => {
    try {
      const uploader = new Uploader(
        files[0],
        afterUpload,
        "",
        "",
        uploadProgress,
      );
      uploader.start();
    } catch (e) {
      console.error(e);
    }
  };

  const afterUpload = (itemUrl, signedId, contentType) => {
    setPreviewMedia({ url: itemUrl, type: contentType?.split("/")[0] });
    setValue(name, signedId);
    setIsLoading(false);
  };

  const onRemove = async () => {
    setIsLoading(true);

    const path = removeAttachmentsPath || attachmentFilesPath();
    const options = isEmpty(removeAttachmentsParams)
      ? {
          field_name: BANNER_IMAGE_KEYS[name],
        }
      : removeAttachmentsParams;
    const response = await reactQueryDelete(path, options);

    if (response.success) {
      setValue(name, { url: null, type: "image" });
      setPreviewMedia({});
    }
    setIsLoading(false);
  };

  if (isLoading) {
    return (
      <FieldWrapper title={title}>
        <div className="text-default bg-secondary border-primary flex h-64 w-full items-center justify-center rounded-md border border-dashed">
          <CircularProgress percentage={progress} size={32} strokeWidth={3} />
        </div>
      </FieldWrapper>
    );
  }
  return (
    <FieldWrapper title={title}>
      {url ? (
        <Preview
          url={url}
          type={type}
          onRemove={onRemove}
          disabled={disabled}
          isSpaceWelcomeBanner={isSpaceWelcomeBanner}
        />
      ) : (
        <DropZone
          isLoading={isLoading}
          handleUpload={handleUpload}
          name={name}
          description={description}
          disabled={disabled}
        />
      )}
    </FieldWrapper>
  );
};

CoverImage.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string,
  aspectRatio: PropTypes.number,
  disabled: PropTypes.bool,
};
