import { t } from "i18n-js";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { ConfirmationModal } from "@circle-react-shared/ModalsV2/ConfirmationModal";

interface ConfirmDeleteActionModalContentProps {
  disabled?: boolean;
  onConfirm: () => void | Promise<void>;
  onCancel: () => void;
}

const i18nBase = "settings.workflows.edit.confirm_delete_action_modal";

export const ConfirmDeleteActionModalContent = ({
  onConfirm,
  onCancel,
  disabled = false,
}: ConfirmDeleteActionModalContentProps) => {
  const modal = useModal();
  return (
    <ConfirmationModal
      isOpen={modal.visible}
      onClose={modal.hide}
      confirmVariant="danger"
      confirmText={t(`${i18nBase}.confirm_text`)}
      title={t(`${i18nBase}.title`)}
      cancelText={t(`${i18nBase}.cancel_text`)}
      onConfirm={onConfirm}
      onCancel={onCancel}
      disabled={disabled}
    >
      {t(`${i18nBase}.body`)}
    </ConfirmationModal>
  );
};

interface ConfirmDeleteActionModalProps {
  onConfirm: () => void | Promise<void>;
  disabled?: boolean;
  onCancel: () => void;
}
export const ConfirmDeleteActionModal =
  ModalManager.create<ConfirmDeleteActionModalProps>(
    ({ onConfirm, onCancel, disabled = false }) => (
      <ConfirmDeleteActionModalContent
        onConfirm={onConfirm}
        onCancel={onCancel}
        disabled={disabled}
      />
    ),
  );

export const useConfirmDeleteActionModal = () =>
  useModal(ConfirmDeleteActionModal);
