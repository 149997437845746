import { Suspense } from "react";
import { StandardLayoutV2 } from "@circle-react/components/Layout/StandardLayoutV2";
import { VersionedContent } from "@circle-react/components/Layout/VersionedContent";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { PageLoader } from "@circle-react/pages/PageLoader";

const NotificationsComponent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "NotificationsPage" */ "@circle-react/components/NotificationsPage"
    ),
);

export const NotificationsPage = () => (
  <StandardLayoutV2 disableRightSidebar>
    <VersionedContent fullWidth fullHeight={false}>
      <StandardLayoutV2.Body>
        <Suspense fallback={<PageLoader />}>
          <NotificationsComponent />
        </Suspense>
      </StandardLayoutV2.Body>
    </VersionedContent>
  </StandardLayoutV2>
);
