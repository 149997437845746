import PropTypes from "prop-types";
import InnerHTML from "dangerously-set-html-content";
import { Typography } from "@circle-react-uikit/Typography";

const truncationStyles = {
  display: "-webkit-box",
  webkitBoxOrient: "vertical",
  webkitLineClamp: "3",
  overflow: "hidden",
};

export const Description = ({ text, truncate }) => (
  <Typography.LabelXs color="text-default">
    <InnerHTML
      {...(truncate && {
        style: truncationStyles,
      })}
      html={text}
    />
  </Typography.LabelXs>
);

Description.propTypes = {
  text: PropTypes.string.isRequired,
  truncate: PropTypes.bool,
};
