import { t } from "i18n-js";
import { Icon } from "@circle-react-shared/Icon";
import type { ActionOption } from "../../types";
import { FormSelectEvent } from "./FormSelectEvent";
import { HistoryDetailViewEvent } from "./HistoryDetailViewEvent";
import type { HistoryDetailViewEventProps } from "./HistoryDetailViewEvent/HistoryDetailViewEvent";
import { ViewEvent } from "./ViewEvent";

export const rsvpMember: Readonly<ActionOption<HistoryDetailViewEventProps>> = {
  label: t("settings.workflows.form.actions.rsvp_member_to_event"),
  value: "rsvp_member_to_event",
  group: t("settings.workflows.form.action_groups.events"),
  leftAddon: (
    <Icon type="16-calendar-join-date" size={16} className="!h-4 !w-4" />
  ),
  formComponent: FormSelectEvent,
  viewComponent: ViewEvent,
  historyDetailViewComponent: HistoryDetailViewEvent,
  icon: "event",
  isApplicableToMembersOnly: true,
} as const;
