import { Suspense } from "react";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { PageLoader } from "@circle-react/pages/PageLoader";

const LivePublicComponent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "LivePublicPage" */ "@circle-react/components/LiveStreams/PublicStream"
    ),
);

export const LivePublicPage = () => (
  <Suspense fallback={<PageLoader />}>
    <LivePublicComponent />
  </Suspense>
);
