import classNames from "classnames";
import { noop } from "lodash";
import { Icon } from "@circle-react-shared/Icon";

interface RightNavigationButtonProps {
  onClick: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
}

export const RightNavigationButton = ({
  onClick = noop,
  isDisabled = false,
  isLoading = false,
}: RightNavigationButtonProps) => {
  const iconType = isLoading ? "loader" : "20-chevron-right";

  return (
    <button
      type="button"
      className={classNames(
        "fixed top-1/2 -translate-y-1/2",
        "h-24 w-24",
        "hidden",
        "isolate z-10 items-center justify-center",
        "transition-all duration-300 ease-in-out",
        "md:flex",
        "md:right-[calc(50%-464px-32px)]",
        "lg:right-[calc(50%-464px-64px)]",
      )}
      onClick={isDisabled ? undefined : onClick}
      disabled={isDisabled}
    >
      <div
        className={classNames(
          "bg-primary hover:bg-tertiary text-default hover:text-dark",
          "relative h-10 w-10",
          "flex items-center justify-center rounded-full",
          { "cursor-not-allowed opacity-50": isDisabled },
        )}
      >
        <Icon size={20} type={iconType} />
      </div>
    </button>
  );
};
