import { useEffect } from "react";
import { usePostList as useHomePagePostList } from "@circle-react/components/HomePageV2/usePostList";
import { usePostList } from "@circle-react/hooks/posts/usePostList";
import { useProfilePostsList } from "@circle-react/hooks/profile/useProfilePostsList";
import type { Post } from "@circle-react/types";
import type { FetchedData } from "./useNavigationLogic";

export const usePostFetching = (
  isPostQueryEnabled: boolean,
  wasInFeed: boolean,
  onSuccess: (fetchedData: FetchedData) => void,
  params?: string | Record<string, unknown>,
  isWithinProfileContext?: boolean,
  profileContextMember?: {
    id: number;
    public_uid: string;
  },
): {
  posts: Post[];
  isLoading: boolean;
} => {
  const homePageSort = typeof params === "string" ? params : "";
  const isHomePageQueryEnabled = isPostQueryEnabled && wasInFeed;
  const isSpacePostsQueryEnabled =
    !isPostQueryEnabled && !wasInFeed && !isWithinProfileContext;
  const isProfilePostsQueryEnabled =
    isPostQueryEnabled && isWithinProfileContext;

  const {
    posts: profilePosts,
    isLoading: isLoadingProfilePosts,
    fetchNextPage: fetchNextPageInProfile,
  } = useProfilePostsList({
    memberPublicId: profileContextMember?.public_uid,
    enabled: isProfilePostsQueryEnabled,
    onSuccess,
  });

  const {
    posts: spacePosts,
    isLoading: isLoadingSpace,
    fetchNextPage: fetchNextPageInSpace,
  } = usePostList(params, isSpacePostsQueryEnabled, onSuccess);

  const {
    posts: homePagePosts,
    isLoading: isLoadingHome,
    fetchNextPage,
  } = useHomePagePostList(homePageSort, isHomePageQueryEnabled, onSuccess);

  useEffect(() => {
    if (isPostQueryEnabled) {
      if (wasInFeed) {
        void fetchNextPage();
      } else if (isWithinProfileContext) {
        void fetchNextPageInProfile();
      } else {
        void fetchNextPageInSpace();
      }
    }
  }, [
    isPostQueryEnabled,
    wasInFeed,
    fetchNextPage,
    fetchNextPageInSpace,
    fetchNextPageInProfile,
    isWithinProfileContext,
  ]);

  let posts = [];
  let isLoading;

  if (wasInFeed) {
    posts = homePagePosts;
    isLoading = isLoadingHome;
  } else if (isWithinProfileContext) {
    posts = profilePosts;
    isLoading = isLoadingProfilePosts;
  } else {
    posts = spacePosts;
    isLoading = isLoadingSpace;
  }

  return {
    posts,
    isLoading,
  };
};
