import { noop } from "lodash";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { DeleteSpaceConfirm } from "@circle-react/components/Spaces/Header/DeleteSpaceConfirm";
import type { Space } from "@circle-react/types/Space";

export interface DeleteSpaceModalProps {
  space?: Space;
  onSuccess?: () => Promise<void> | void;
  onClose?: () => Promise<void> | void;
}

const DeleteSpaceModalComponent = ({
  space,
  onSuccess,
  onClose = noop,
}: DeleteSpaceModalProps) => {
  const drawer = useModal();
  const onCloseDeleteSpaceModal = () => {
    onClose && void onClose();
    void drawer.hide();
  };

  return (
    <DeleteSpaceConfirm
      show={drawer.visible}
      space={space}
      onClose={onCloseDeleteSpaceModal}
      onSuccess={onSuccess}
    />
  );
};

const DeleteSpaceModal = ModalManager.create(
  ({ space, onSuccess, onClose }: DeleteSpaceModalProps) => (
    <DeleteSpaceModalComponent
      space={space}
      onSuccess={onSuccess}
      onClose={onClose}
    />
  ),
);

export const useDeleteSpaceModal = () => useModal(DeleteSpaceModal);
