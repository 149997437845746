import { Suspense } from "react";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { PageLoader } from "@circle-react/pages/PageLoader";

const ModerationSettingsComponent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "ModerationSettingsPage" */ "@circle-react/components/SettingsApp/Moderation/ModerationList"
    ),
);

export const ModerationSettingsPage = () => (
  <Suspense fallback={<PageLoader />}>
    <ModerationSettingsComponent />
  </Suspense>
);
