import { useEffect, useState } from "react";
import { uniqBy } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import { internalApi } from "../../../helpers/urlHelpers/internalApi";
import { LoaderRow } from "../../shared/LoaderRow";
import { Show } from "../Show";

export const PostViewList = props => {
  const [posts, setPosts] = useState(props.posts || []);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [page, setPage] = useState(1);
  const { space, current_user } = props;

  const fetchPosts = async () => {
    const response = await fetch(
      internalApi.posts.index({
        spaceId: space.id,
        params: { page, sort: props.sort, iframe: true },
      }),
    );
    if (response.ok) {
      const data = await response.json();
      setPosts(uniqBy([...posts, ...data.records], "id"));
      setHasNextPage(data.has_next_page);
      setPage(data.page + 1);
      window.loadOembed();
    }
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  return (
    <InfiniteScroll
      style={{ overflow: "initial" }}
      scrollThreshold={0.75}
      next={fetchPosts}
      hasMore={hasNextPage}
      loader={<LoaderRow />}
      dataLength={posts.length}
    >
      {posts.map(post => (
        <Show
          key={post.id}
          post={post}
          space={space}
          current_user={current_user}
        />
      ))}
    </InfiniteScroll>
  );
};
