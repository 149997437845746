import { PropTypes } from "prop-types";
import { t } from "i18n-js";
import { HistoryAction } from "./HistoryAction";
import { HistoryError } from "./HistoryError";
import { HistoryLog } from "./HistoryLog";
import { HistorySkipped } from "./HistorySkipped";
import { HistoryTrigger } from "./HistoryTrigger";

export const LogMessage = ({ log, status }) => {
  const { action } = log;

  switch (action) {
    case "event_triggered":
      return (
        <HistoryTrigger
          log={log}
          text={t("settings.workflows.trigger.event")}
        />
      );
    case "run_triggered":
      return (
        <HistoryTrigger log={log} text={t("settings.workflows.trigger.run")} />
      );
    case "task_execution_completed":
    case "run_completed":
    case "trigger_execution_check_failed":
      return <HistoryLog log={log} noMessage />;
    case "task_action_completed":
    case "task_action_failed":
      return <HistoryAction log={log} status={status} />;
    case "fetching_audience_members_failed":
    case "run_failed":
    case "task_execution_failed":
      return <HistoryError log={log} />;
    case "task_skipped":
      return <HistorySkipped log={log} />;
    default:
      return <HistoryLog log={log} />;
  }
};

LogMessage.propTypes = {
  log: PropTypes.shape({
    action: PropTypes.string.isRequired,
  }).isRequired,
  status: PropTypes.string,
};
