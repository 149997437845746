import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useCurrentSpaceContext } from "@circle-react/contexts";
import { reactQueryPut } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { getCourseSectionsKey } from "@circle-react/hooks/courses/useCoursesApi";
import { useToast } from "@circle-react-uikit/ToastV2";
import { NameEditor } from "./NameEditor";

export interface SectionNameEditorProps {
  section: any;
  onClose: () => void;
}

export const SectionNameEditor = ({
  section,
  onClose,
}: SectionNameEditorProps) => {
  const { data: space } = useCurrentSpaceContext();
  const [name, setName] = useState(section.name);
  const queryClient = useQueryClient();
  const toast = useToast();

  const renameMutation = useMutation<void, any, any>(
    data =>
      reactQueryPut(
        internalApi.courses.updateSection({
          courseId: space?.id,
          sectionId: section.id,
        }),
        data,
      ),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(getCourseSectionsKey(space?.id));
        onClose();
      },
      onError: error => {
        toast.error(error.errorDetails);
      },
    },
  );
  const onSubmit = () =>
    renameMutation.mutate({
      course_section: { name },
    });

  return (
    <NameEditor
      name={name}
      setName={setName}
      isLoading={renameMutation.isLoading}
      onSubmit={onSubmit}
      onClose={onClose}
      useSemiboldFont
      dataTestId="section-title"
    />
  );
};
