import PropTypes from "prop-types";
import { t } from "i18n-js";
import { useFormContext, useWatch } from "react-hook-form";
import { ConfirmationModal } from "@circle-react-shared/ModalsV2/ConfirmationModal";

export const DraftVisibilityConfirmModal = ({
  isOpen,
  onClose,
  onConfirm,
  spaceType,
}) => {
  const currentVisibility = useWatch({ name: "visibility" });
  const { resetField } = useFormContext();

  return (
    <ConfirmationModal
      isOpen={isOpen}
      title={t("spaces.form.create.change_draft_confirmation.title")}
      onClose={onClose}
      onCancel={() => {
        resetField("visibility");
        onClose();
      }}
      onConfirm={onConfirm}
      confirmVariant="primary"
      confirmText={t(
        "spaces.form.create.change_draft_confirmation.confirm_button",
        { spaceType },
      )}
    >
      {t(`spaces.form.create.change_draft_confirmation.${currentVisibility}`)}{" "}
      {t("spaces.form.create.change_draft_confirmation.wont_be_able_to_change")}
    </ConfirmationModal>
  );
};

DraftVisibilityConfirmModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  spaceType: PropTypes.string,
};
