import { useEffect, useState } from "react";

type SearchType =
  | "general"
  | "spaces"
  | "posts"
  | "comments"
  | "members"
  | "lessons"
  | "events";

export interface RecentSearch {
  type: SearchType;
  query: string;
}

interface RecentSearchesHook {
  recentSearches: RecentSearch[];
  addRecentSearch: (search: RecentSearch) => void;
  removeRecentSearch: (query: string) => void;
}

const MaxSearchItems = 10;

export const useRecentSearches = (): RecentSearchesHook => {
  const [recentSearches, setRecentSearches] = useState<RecentSearch[]>([]);

  useEffect(() => {
    try {
      const storedSearches = localStorage.getItem("recentSearches");
      if (storedSearches) {
        setRecentSearches(JSON.parse(storedSearches));
      }
    } catch (error) {
      console.error("Error parsing recent searches from local storage", error);
    }
  }, []);

  const addRecentSearch = (newSearch: RecentSearch) => {
    if (!newSearch.query) return;

    setRecentSearches(prevSearches => {
      const filteredSearches = prevSearches.filter(
        search => search.query !== newSearch.query,
      );
      const newRecentSearches = [newSearch, ...filteredSearches].slice(
        0,
        MaxSearchItems,
      );

      try {
        localStorage.setItem(
          "recentSearches",
          JSON.stringify(newRecentSearches),
        );
      } catch (error) {
        console.error("Error saving recent searches to local storage", error);
      }

      return newRecentSearches;
    });
  };

  const removeRecentSearch = (query: string) => {
    setRecentSearches(prevSearches => {
      const newRecentSearches = prevSearches.filter(
        search => search.query !== query,
      );

      try {
        localStorage.setItem(
          "recentSearches",
          JSON.stringify(newRecentSearches),
        );
      } catch (error) {
        console.error("Error saving recent searches to local storage", error);
      }

      return newRecentSearches;
    });
  };

  return { recentSearches, addRecentSearch, removeRecentSearch };
};
