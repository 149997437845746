import { t } from "i18n-js";
import { EntityResource } from "../EntityResource";
import type { EventOption } from "../types";
import { AnyEventSpecificSelector } from "./shared/AnyEventSpecificSelector";

export const eventEndedForMember: Readonly<EventOption> = {
  label: t("settings.workflows.form.events.event_ended_for_member.title"),
  value: "event_ended_for_member",
  group: t("settings.workflows.form.event_groups.events"),
  description: t(
    "settings.workflows.form.events.event_ended_for_member.description",
  ),
  formComponent: AnyEventSpecificSelector,
  viewComponent: EntityResource,
  icon: "event",
  released: true,
};
