import PropTypes from "prop-types";
import { Typography } from "@circle-react-uikit/Typography";

export const CustomFormItem = ({ label = "", children }) => (
  <div className="flex w-full flex-col pt-6">
    <div className="pb-2">
      <Typography.LabelSm weight="semibold">{label}</Typography.LabelSm>
    </div>
    {children}
  </div>
);

CustomFormItem.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
};
