import { useEffect } from "react";
import { useHomePageApi } from "../../../hooks/home_page/useHomePageApi";
import { Carousel } from "../../shared/Carousel";
import { SpaceCard } from "../../shared/SpaceCard/index";

export const SuggestedSpacesCarousel = () => {
  const { spaces, fetchSpaceSuggestions, spacesHasNextPage } = useHomePageApi();

  useEffect(() => {
    fetchSpaceSuggestions({ page: 1, perPage: 3 });
  }, []);

  const spacesList = indexProps => {
    const { fromIndex = 0, toIndex = 2 } = indexProps;
    return spaces
      .slice(fromIndex, toIndex)
      .map(space => (
        <SpaceCard
          key={space.id}
          id={space.id}
          url={space.url}
          emoji={space.emoji}
          customEmojiUrl={space.custom_emoji_url}
          customEmojiDarkUrl={space.custom_emoji_dark_url}
          name={space.name}
          totalMembers={space.total_members}
        />
      ));
  };

  return spaces.length > 2 ? (
    <div className="carousel carousel--suggested-spaces">
      <Carousel
        cards={spaces}
        renderCardsOnpage={spacesList}
        cardsPerPage={3}
        cardsHaveNextPage={spacesHasNextPage}
        fetchCards={fetchSpaceSuggestions}
      />
    </div>
  ) : (
    <div />
  );
};
