import type { Space } from "@/react/types/Space";
import { usePunditUserContext } from "@circle-react/contexts";
import { isCommunityAdmin } from "@circle-react/helpers/communityMemberHelpers";
import {
  isBasicPostsSpace,
  isChatSpace,
  isCourseSpace,
  isEventsSpace,
  isImagesSpace,
  isMembersSpace,
} from "@circle-react/helpers/spaceHelpers";
import { ChatSpaceOptions } from "./ChatSpaceOptions";
import { CourseSpaceOptions } from "./CourseSpaceOptions";
import { EventSpaceOptions } from "./EventSpaceOptions";
import { MembersSpaceOptions } from "./MembersSpaceOptions";
import { PostSpaceOptions } from "./PostSpaceOptions";

export interface CustomizationOptionsProps {
  space?: Space;
  onClickAddCoverImage: () => void;
  setIsAddMemberOpen: (isOpen: boolean) => void;
}

export const CustomizationOptions = ({
  space,
  onClickAddCoverImage,
  setIsAddMemberOpen,
}: CustomizationOptionsProps) => {
  const { currentCommunityMember } = usePunditUserContext();
  const isAdmin = isCommunityAdmin(currentCommunityMember);

  if (!space) return null;

  if (isBasicPostsSpace(space) || isImagesSpace(space)) {
    return (
      <PostSpaceOptions
        space={space}
        setIsAddMemberOpen={setIsAddMemberOpen}
        onClickAddCoverImage={onClickAddCoverImage}
        isAdmin={isAdmin}
      />
    );
  }

  if (isChatSpace(space)) {
    return (
      <ChatSpaceOptions
        space={space}
        setIsAddMemberOpen={setIsAddMemberOpen}
        isAdmin={isAdmin}
      />
    );
  }

  if (isEventsSpace(space)) {
    return (
      <EventSpaceOptions
        space={space}
        setIsAddMemberOpen={setIsAddMemberOpen}
        onClickAddCoverImage={onClickAddCoverImage}
        isAdmin={isAdmin}
      />
    );
  }

  if (isMembersSpace(space)) {
    return (
      <MembersSpaceOptions
        space={space}
        setIsAddMemberOpen={setIsAddMemberOpen}
        isAdmin={isAdmin}
      />
    );
  }

  if (isCourseSpace(space)) {
    return (
      <CourseSpaceOptions
        space={space}
        isAdmin={isAdmin}
        setIsAddMemberOpen={setIsAddMemberOpen}
      />
    );
  }

  return null;
};
