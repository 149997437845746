import PropTypes from "prop-types";
import { t } from "i18n-js";
import { delay } from "lodash";
import { CustomizeSpaceForm } from "@/react/components/Spaces/CustomizeSpaceFullPage/CustomizeSpaceForm";
import { OptionsForm } from "@/react/components/Spaces/CustomizeSpaceFullPage/OptionsForm";
import { Paywalls } from "@/react/components/Spaces/CustomizeSpaceFullPage/Paywalls";
import { SpaceMembersForm } from "@/react/components/Spaces/CustomizeSpaceFullPage/SpaceMembersForm";
import { Workflows } from "@/react/components/Spaces/CustomizeSpaceFullPage/Workflows";
import { useCustomizeUIData } from "@circle-react-shared/CustomizeUI";
import { LessonList } from "../../CourseSpace/Lessons/List";
import { Lockscreen } from "../../CourseSpace/Lockscreen";

export const CustomizeSpaceSectionSelector = ({ space, scrollToId }) => {
  const { currentSection } = useCustomizeUIData();

  if (scrollToId) {
    delay(() => document.getElementById(scrollToId)?.scrollIntoView());
  }

  if (currentSection === t("customize_space.paywalls.title")) {
    return <Paywalls space={space} />;
  }

  if (currentSection === t("customize_space.customize")) {
    return <CustomizeSpaceForm space={space} />;
  }

  if (currentSection === t("customize_space.members")) {
    return <SpaceMembersForm space={space} />;
  }

  if (currentSection === t("customize_space.options")) {
    return <OptionsForm space={space} />;
  }

  if (currentSection === t("customize_space.lessons")) {
    return <LessonList space={space} />;
  }

  if (currentSection === t("customize_space.lock_screen")) {
    return <Lockscreen space={space} />;
  }

  if (currentSection === t("customize_space.workflows")) {
    return <Workflows />;
  }

  return null;
};

CustomizeSpaceSectionSelector.propTypes = {
  space: PropTypes.object,
};
