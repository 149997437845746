import { useNavigationLogic } from "../hooks/useNavigationLogic";
import type { NavigationProps } from "../hooks/useNavigationLogic";
import { LeftNavigationButton } from "./LeftNavigationButton";
import { RightNavigationButton } from "./RightNavigationButton";

export const Navigation = (props: NavigationProps) => {
  const {
    handleNextPost,
    handlePreviousPost,
    shouldDisableLeftNavigation,
    shouldDisableRightNavigation,
    isNavigatingToNextPost,
    isNavigatingToPreviousPost,
  } = useNavigationLogic(props);
  const { isLoading = false } = props;

  return (
    <>
      <LeftNavigationButton
        onClick={handlePreviousPost}
        isDisabled={shouldDisableLeftNavigation}
        isLoading={isLoading && isNavigatingToPreviousPost}
      />
      <RightNavigationButton
        onClick={handleNextPost}
        isDisabled={shouldDisableRightNavigation}
        isLoading={isLoading && isNavigatingToNextPost}
      />
    </>
  );
};
