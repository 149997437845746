import { useMemo } from "react";
import { compact, flatten } from "lodash";

export const usePaywallPriceOptions = (paywallsListQuery: any) => {
  const getPaywallPriceAsOption = (price: any) => ({
    label: price.short_description,
    value: price.id,
    paywall_id: price.paywall_id,
  });

  const getPaywallPriceProperty = (price: any, paywall: any) => ({
    paywall_id: paywall.id,
    currency_code: paywall.currency.code,
    trial_days: price.is_web_price ? paywall.trial_days : 0,
    ...price,
  });

  const getMobilePaywallPriceProperty = (pricePackage: any, paywall: any) => ({
    ...getPaywallPriceProperty(pricePackage.price_properties, paywall),
    processor_id: pricePackage.processor_id,
    price_package_id: pricePackage.id,
    id: pricePackage.id,
  });

  const allStripePrices = useMemo(
    () =>
      paywallsListQuery?.data
        ? flatten(
            paywallsListQuery.data.map((paywall: any) =>
              paywall.stripe_prices_attributes.map((price: any) =>
                getPaywallPriceProperty(price, paywall),
              ),
            ),
          )
        : [],
    [paywallsListQuery?.data],
  );

  const allMobilePrices = useMemo(
    () =>
      paywallsListQuery?.data
        ? flatten(
            paywallsListQuery.data.map((paywall: any) =>
              paywall.mobile_price_packages_attributes.map(
                (pricePackage: any) =>
                  getMobilePaywallPriceProperty(pricePackage, paywall),
              ),
            ),
          )
        : [],
    [paywallsListQuery?.data],
  );

  const allInAppPaywallPriceOptions = useMemo(
    () => allMobilePrices.map(getPaywallPriceAsOption),
    [allMobilePrices],
  );

  const allWebPaywallPriceOptions = useMemo(
    () => compact(allStripePrices.map(getPaywallPriceAsOption)),
    [allStripePrices],
  );

  const hasWebPaywallPrices = useMemo(
    () => allWebPaywallPriceOptions?.length > 0,
    [allWebPaywallPriceOptions],
  );

  return {
    allStripePrices,
    allMobilePrices,

    allInAppPaywallPriceOptions,
    allWebPaywallPriceOptions,
    hasWebPaywallPrices,
  };
};
