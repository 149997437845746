import { setDefaultOptions } from "date-fns";

export const setDateFnsOptions = async () => {
  if (window?.locale === "pt") {
    const locale = await import(
      /* webpackChunkName: "date_fns_pt_br" */ "date-fns/locale/pt-BR"
    );

    setDefaultOptions({ locale: locale.default, weekStartsOn: 1 });
  } else {
    const locale = await import(
      /* webpackChunkName: "date_fns_en_us" */ "date-fns/locale/en-US"
    );
    setDefaultOptions({ locale: locale.default });
  }
};
