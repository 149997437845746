import { Suspense } from "react";
import { VersionedContent } from "@circle-react/components/Layout/VersionedContent";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { PageLoader } from "@circle-react/pages/PageLoader";

const EventsComponent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "EventsPage" */ "@circle-react/components/AllEvents"
    ),
);

export const EventsPage = () => (
  <VersionedContent fullWidth noPadding>
    <Suspense fallback={<PageLoader />}>
      <EventsComponent />
    </Suspense>
  </VersionedContent>
);
