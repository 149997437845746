import { RadioGroup } from "@headlessui/react";
import classNames from "classnames";
import { Controller, useFormContext } from "react-hook-form";
import { Form } from "@circle-react-uikit/Form";
import { ChangePlanFormLabelGroup } from "../ChangePlanFormLabelGroup";

interface Option {
  key: string;
  value: number;
  label: string;
  details: string;
  description: string;
  enableCursor: boolean;
}

export interface ChangePlanFormChooserProps {
  className?: string;
  name: string;
  warningMessage: string;
  options: Option[];
}

export const ChangePlanFormChooser = ({
  className,
  name,
  warningMessage,
  options,
}: ChangePlanFormChooserProps) => {
  const { control } = useFormContext();

  return (
    <Form.Item
      className={className}
      name={name}
      fullWidth
      hideBorder
      hideDescription
      hideLabel
      isFirstChild
      rules={{ required: warningMessage }}
    >
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange } }) => (
          <RadioGroup className="space-y-3" value={value} onChange={onChange}>
            {options.map(option => (
              <RadioGroup.Option key={option.key} value={option.value}>
                {({ checked }) => (
                  <ChangePlanFormLabelGroup
                    className={classNames(
                      "border-primary bg-primary hover:bg-secondary hover:border-secondary rounded border border-solid p-5",
                      {
                        "bg-secondary border-secondary": checked,
                      },
                    )}
                    label={option.label}
                    labelSize="md"
                    labelWeight="semibold"
                    details={option.details}
                    detailsSize="sm"
                    detailsWeight="medium"
                    description={option.description}
                    descriptionSize="sm"
                    enableCursor={option?.enableCursor}
                  />
                )}
              </RadioGroup.Option>
            ))}
          </RadioGroup>
        )}
      />
    </Form.Item>
  );
};
