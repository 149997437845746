import { useMemo, useRef } from "react";

const DEFAULT_SPEAKER_COUNT = 8;

// TODO: Implement livekit logic and enable linter
export const useLivekitSpeakerRotation = (
  peers,
  { pageSize = DEFAULT_SPEAKER_COUNT } = {},
) => {
  const firstPageRotationRef = useRef([]);
  const speakersArray = peers?.filter(peer => peer.isSpeaking) || [];
  // Create helper object for easily accessing peers by id
  const peersObj = useMemo(
    () =>
      peers?.reduce((obj, peer) => {
        obj[peer.sid] = peer;
        return obj;
      }, {}) || {},
    [peers],
  );

  // Update firstPageRotation from peers since tracks may have changed
  const firstPageRotation = firstPageRotationRef.current
    .map(speaker => peersObj[speaker.sid])
    .filter(speaker => !!speaker);
  // Make sure speakers are part of the rotation
  speakersArray?.forEach(peer => {
    if (firstPageRotation.every(speaker => speaker.sid !== peer.sid)) {
      // Insert in first position if not part of speakers
      const newSpeaker = peersObj[peer.sid];
      newSpeaker && firstPageRotation.unshift(newSpeaker);
    }
    if (firstPageRotation.length > pageSize) {
      // Remove last speaker
      firstPageRotation.pop();
    }
  });
  // Fill any empty spaces with non speakers
  if (
    firstPageRotation.length < peers?.length &&
    firstPageRotation.length < pageSize
  ) {
    // Look for peers that are not already on speaker rotation
    const freePeers = peers?.filter(
      peer => !firstPageRotation.some(speaker => speaker.sid === peer.sid),
    );
    if (freePeers?.length) {
      const missingSpeakerCount = pageSize - firstPageRotation.length;
      firstPageRotation.push(...freePeers.slice(0, missingSpeakerCount));
    }
  }
  // Save for next render
  firstPageRotationRef.current = firstPageRotation;

  // Fill the rest of the list with non speakers
  const freePeers = peers?.filter(
    peer => !firstPageRotation.some(speaker => speaker.sid === peer.sid),
  );
  const orderedPeers = [...firstPageRotation, ...freePeers];

  return {
    orderedPeers,
  };
};
