import classNames from "classnames";
import { t } from "i18n-js";
import { useIsV3 } from "@circle-react/hooks/useIsV3";

export interface CommunityLogoProps {
  name: string;
  src: string;
  className?: string;
}

export const CommunityLogo = ({ name, src, className }: CommunityLogoProps) => {
  const { isV3Enabled } = useIsV3();

  return (
    <img
      loading="eager"
      id="community-logo"
      alt={t("community_logo_alt", { community_name: name })}
      className={classNames(
        {
          "max-w-[14.375rem]": isV3Enabled, // 230px
          "max-h-6": !isV3Enabled, // 24px
          "max-h-9": isV3Enabled, // 36px
        },
        className,
      )}
      src={src}
    />
  );
};
