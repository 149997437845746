import { SpaceTypePicker } from "@circle-react/components/Spaces/NewV2/NewForm/SpaceTypePicker";
import { usePunditUserContext } from "@circle-react/contexts";
import type { SpaceType } from "@circle-react/types/Space";
import { Form } from "@circle-react-uikit/Form";

export const SPACE_TYPE_FORM_ID = "space_type_form";

export interface FormValues {
  post_type: SpaceType;
}

export interface SpaceTypePickerFormProps {
  onSubmit: (values: FormValues) => void;
}

export const SpaceTypePickerForm = ({ onSubmit }: SpaceTypePickerFormProps) => {
  const { currentCommunity, currentCommunitySettings } = usePunditUserContext();

  const isCoursesEnabled =
    !!currentCommunitySettings?.courses_enabled &&
    currentCommunity?.courses_feature_flag_enabled;

  return (
    <Form<FormValues>
      id={SPACE_TYPE_FORM_ID}
      onSubmit={onSubmit}
      defaultValues={{
        post_type: "basic",
      }}
    >
      <SpaceTypePicker hideLabel isCoursesEnabled={isCoursesEnabled} />
    </Form>
  );
};
