import classNames from "classnames";
import { isNull } from "lodash";
import { NavLink } from "react-router-dom";
import { Avatar } from "@/react/components/Modals/MessagingModal/LeftSidebar/List/Item/Avatar";
import { dateStringToTimeAgo } from "@circle-react/helpers/dateTimeHelpers/timeAgo";
import { Icon } from "@circle-react-shared/Icon";
import { Body } from "./Body";

interface ChatItemProps {
  chat: any;
  path: string;
  isBotType?: boolean;
  shouldHightlightChat?: boolean;
}
export const ChatItem = ({
  chat,
  path,
  isBotType = false,
  shouldHightlightChat = false,
}: ChatItemProps) => {
  const {
    community_bot_profile: botProfile,
    community_member: communityMember,
    last_message: lastMessage = {},
    id,
  } = chat;

  const author = isBotType ? botProfile : communityMember;
  const shouldShowDefaultBotAvatar = isBotType && !author.avatar_url;

  if (isNull(lastMessage)) {
    return null;
  }
  return (
    <NavLink
      className={classNames(
        "hover:bg-tertiary block cursor-pointer rounded-lg px-4 py-3",
        { "bg-[#674FD90A]/[.04]": shouldHightlightChat },
      )}
      activeClassName="bg-tertiary"
      key={id}
      to={path}
      data-testid="chat-room-dm-list"
    >
      <div className="flex w-full items-start justify-between">
        <div className="flex items-center space-x-3">
          {shouldShowDefaultBotAvatar ? (
            <Icon
              type="36-ai-bot"
              size={36}
              className="!h-[36px] !w-[36px] shrink-0"
            />
          ) : (
            <Avatar isDirect otherParticipants={[author]} />
          )}

          <Body
            isDirect
            chatRoomName={author.name}
            timestamp={dateStringToTimeAgo(lastMessage.created_at, {
              format: "short",
            })}
            lastMessage={lastMessage}
          />
        </div>
      </div>
    </NavLink>
  );
};
