import PropTypes from "prop-types";
import { t } from "i18n-js";
import {
  isEventsSpace,
  isImagesSpace,
  isMembersSpace,
} from "@circle-react/helpers/spaceHelpers";
import { Form } from "@circle-react-uikit/Form";
import { Typography } from "@circle-react-uikit/Typography";

const sortDropdownItems = space => {
  const sortOrders = {
    LATEST: { label: t("latest"), value: "latest" },
    OLDEST: { label: t("oldest"), value: "oldest" },
    POPULAR: { label: t("popular"), value: "popular" },
    LIKES: { label: t("likes"), value: "likes" },
    NEW_ACTIVITY: { label: t("new_activity"), value: "new_activity" },
    ALPHABETICAL: { label: t("alphabetical"), value: "alphabetical" },
  };

  const defaultCommentSort = {
    name: "default_comment_sort",
    options: [sortOrders.LATEST, sortOrders.OLDEST],
  };

  const defaultSort = {
    name: "default_sort",
    options: Object.values(sortOrders),
  };

  const defaultMemberSort = {
    name: "default_member_sort",
    options: [sortOrders.LATEST, sortOrders.OLDEST, sortOrders.ALPHABETICAL],
  };

  if (isEventsSpace(space)) {
    return [defaultCommentSort];
  }
  if (isMembersSpace(space)) {
    return [defaultMemberSort];
  }
  if (isImagesSpace(space)) {
    const defaultSortWithoutAlphabetical = {
      ...defaultSort,
      options: defaultSort.options.filter(
        option => option.value !== "alphabetical",
      ),
    };
    return [defaultCommentSort, defaultSortWithoutAlphabetical];
  }
  return [defaultCommentSort, defaultSort, defaultMemberSort];
};

export const Sort = ({ space }) => {
  const fields = sortDropdownItems(space);
  const shouldShowSortPosts = !isMembersSpace(space) && !isEventsSpace(space); // MembersSpace and EventsSpace don't have posts
  return (
    <div className="mt-8 flex-1">
      <Typography.TitleSm weight="semibold">
        {t("spaces.form.edit.options.sort.label")}
      </Typography.TitleSm>
      {shouldShowSortPosts && (
        <Form.Item
          hiddenField
          hideDescription
          label={t("spaces.form.edit.options.sort.show_sorting")}
          name="show_sorting"
          className="my-5 flex flex-row-reverse items-center justify-end py-0"
          labelClassName="ml-4 mb-0"
          shouldToggleValueOnLabelClick
        >
          <Form.ToggleSwitch variant="small" />
        </Form.Item>
      )}
      <div className="grid grid-cols-1 md:grid-cols-2 md:gap-4">
        {fields.map(({ name, options }) => (
          <Form.Item
            key={name}
            hideBorder
            label={t(`spaces.form.edit.options.sort.${name}.label`)}
            tooltipText={t(`spaces.form.edit.options.sort.${name}.description`)}
            hideDescription
            name={name}
            variant="extra-bottom-margin"
          >
            <Form.SelectNative options={options} />
          </Form.Item>
        ))}
      </div>
    </div>
  );
};

Sort.propTypes = {
  space: PropTypes.object.isRequired,
};
