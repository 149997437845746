import { t } from "i18n-js";
import { Button } from "@circle-react-uikit/Button";
import { Form } from "@circle-react-uikit/Form";
import { useBusinessFormModal } from "./BusinessFormModal/";
import { paywallKnowledgeBase } from "./constants";
import { useCommunityPaymentProcessor } from "./hooks/useCommunityPaymentProcessor";

export const StripeOnboarding = () => {
  const businessFormModal = useBusinessFormModal();
  const subtitles = t(
    "settings.paywalls.processor_status_v2.onboarding.subtitles",
  );
  const goToPaywallKnowledgeBase = () => {
    window.open(paywallKnowledgeBase, "_blank");
  };
  const { onSubmit } = useCommunityPaymentProcessor({});

  const defaultValues: any = { forceForm: true };

  return (
    <Form
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      formClassName="flex flex-col gap-y-4"
    >
      <div className="flex flex-col gap-y-2">
        <p
          className="text-light"
          dangerouslySetInnerHTML={{ __html: subtitles[0] }}
        />
        <p
          className="text-light"
          dangerouslySetInnerHTML={{ __html: subtitles[1] }}
        />
        <p
          className="text-light"
          dangerouslySetInnerHTML={{ __html: subtitles[2] }}
        />
      </div>

      <div className="flex flex-row gap-x-2">
        <Button
          type="button"
          variant="circle"
          data-testid="stripe-connect"
          onClick={() => {
            void businessFormModal.show();
          }}
        >
          <span>{t("settings.paywalls.buttons.connect")}</span>
        </Button>
        <Button
          type="button"
          variant="secondary"
          onClick={goToPaywallKnowledgeBase}
        >
          {t("learn_more")}
        </Button>
      </div>
    </Form>
  );
};
