import { t } from "i18n-js";

export type Frequencies = "daily" | "weekly" | "monthly" | "yearly";

interface FrequencySelectOption {
  value: Frequencies;
  label: string;
}

export const frequencySelectOptions: FrequencySelectOption[] = [
  {
    value: "daily",
    label: t("settings.workflows.recurring_schedule_modal.every_day"),
  },
  {
    value: "weekly",
    label: t("settings.workflows.recurring_schedule_modal.every_week"),
  },
  {
    value: "monthly",
    label: t("settings.workflows.recurring_schedule_modal.every_month"),
  },
  {
    value: "yearly",
    label: t("settings.workflows.recurring_schedule_modal.every_year"),
  },
];
