import { t } from "i18n-js";
import { noop } from "lodash";
import { isFunction } from "lodash";
import { useQueryClient } from "react-query";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { useAddMembersToSpace } from "@circle-react/hooks/useAddMembersToSpace";
import { Form } from "@circle-react-uikit/Form";
import { Modal } from "@circle-react-uikit/ModalV2";
import { useToast } from "@circle-react-uikit/ToastV2";
import { AddMemberContent } from "./AddMemberContent";

const formId = "add-space-members-form";

const defaultValues = {
  notify_members: false,
  community_member_ids: null,
  member_type: "space_group",
  should_add_all_members: false,
};

export interface AddSpaceMemberModalProps {
  onAdd?: () => void;
  spaceId?: number;
}

export const AddSpaceMemberModalComponent = ({
  onAdd,
  spaceId,
}: AddSpaceMemberModalProps) => {
  const toastr = useToast();
  const queryClient = useQueryClient();
  const modal = useModal();

  const { mutate: addMembers, isLoading } = useAddMembersToSpace({
    onError: (error: any) => {
      toastr.error(error.message);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries("community-members");
      toastr.success(t("spaces.form.edit.members.members_added"));
      isFunction(onAdd) ? onAdd() : modal.hide();
    },
  });

  const onSubmit = (formContent: any) =>
    addMembers({
      spaceId,
      ...(formContent.community_member_ids && {
        communityMembersId: formContent.community_member_ids,
      }),
      notifyMembers: !!formContent.notify_members,
      ...(formContent.should_add_all_members && {
        memberType: formContent.member_type,
      }),
      shouldAddAllMembers: formContent.should_add_all_members,
    });

  return (
    <Modal
      isOpen={modal.visible}
      onClose={modal.visible ? modal.hide : noop}
      contentPortalId="add-space-member-content-portal"
      title={t("spaces.form.edit.members.add_member")}
    >
      <Modal.Overlay />
      <Modal.Content size="xl" className="max-w-md">
        <Form
          id={formId}
          mode="onChange"
          onSubmit={onSubmit}
          defaultValues={defaultValues}
          className="flex h-full flex-col overflow-y-auto"
          formClassName="flex overflow-y-auto flex-col h-full"
        >
          <AddMemberContent
            onClose={modal.visible ? modal.hide : noop}
            isLoading={isLoading}
            formId={formId}
          />
        </Form>
      </Modal.Content>
    </Modal>
  );
};

const AddSpaceMemberModal = ModalManager.create(
  ({ spaceId, onAdd }: AddSpaceMemberModalProps) => (
    <AddSpaceMemberModalComponent spaceId={spaceId} onAdd={onAdd} />
  ),
);

export const useAddMemberModal = () => useModal(AddSpaceMemberModal);
