import useMousetrap from "react-hook-mousetrap";
import { QueryClient, QueryClientProvider } from "react-query";
import { useSpotlightSearch } from "@circle-react/components/Modals/SpotlightSearch";
import { MOUSETRAP_ACTIONS } from "@circle-react/components/constants";
import { reactQueryConfig } from "@circle-react/providers/QueryClient/reactQueryConfig";

const queryClient = new QueryClient(reactQueryConfig);

export const SearchWrapper = () => {
  const spotlightSearch = useSpotlightSearch();

  useMousetrap(MOUSETRAP_ACTIONS.OPEN_SEARCH_MODAL, e => {
    e.preventDefault();
    spotlightSearch.show();
  });

  return <QueryClientProvider client={queryClient} />;
};
