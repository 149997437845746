import { reactQueryGet } from "../helpers/backendRequestHelpers";
import { internalApi } from "../helpers/urlHelpers";

const show = memberId => () =>
  reactQueryGet(
    internalApi.communityMember.show({
      memberId,
    }),
  );

export const communityMemberApi = {
  show,
};
