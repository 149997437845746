import { useEffect, useState } from "react";
import { useUpdateMemberAccessModal } from "../../UpdateMemberAccessModal";
import { tabs } from "../../constants";
import { usePaywallApi } from "../../hooks/usePaywallApi";
import { serializePaywallJson } from "../helpers/serializePaywallJson";
import { usePaywallFormDefaultValues } from "./usePaywallFormDefaultValues";

export const usePaywallAdminFormModal = ({
  paywall,
  refetchPaywalls,
  onClose,
  isModalVisible,
  initialTab = tabs.DETAILS_TAB,
}) => {
  const [activeTab, setActiveTab] = useState(initialTab);
  const [currentPaywallStatus, setPaywallStatus] = useState(paywall?.status);
  const { show: showUpdateMemberAccessModal } = useUpdateMemberAccessModal();
  const { getDefaultValues } = usePaywallFormDefaultValues();

  useEffect(() => {
    if (!isModalVisible) {
      setActiveTab(initialTab);
    }
    setPaywallStatus(paywall?.status);
  }, [isModalVisible]);

  const createCallback = paywallUpdated => {
    onClose();
    refetchPaywalls();
    setPaywallStatus(paywallUpdated.status);
  };
  const updateCallback = paywallUpdated => {
    refetchPaywalls();
    setPaywallStatus(paywallUpdated.status);

    if (
      paywallUpdated.policies.can_update_all_members_access &&
      paywallUpdated.access_to_content_changed
    ) {
      showUpdateMemberAccessModal({ paywallId: paywallUpdated.id });
    }
  };

  const { createMutation, updateMutation } = usePaywallApi({
    updateCallback,
    createCallback,
    paywall,
  });
  const mutation = paywall ? updateMutation : createMutation;

  const handleSubmit = (data, { reset }) => {
    const isEditingMode = !!data.id;
    if (isEditingMode) {
      return mutation
        .mutateAsync(serializePaywallJson(data))
        .then(paywallResponse => {
          reset(getDefaultValues(paywallResponse));
        })
        .catch(err => {
          console.error(err);
        });
    }

    if (activeTab === tabs.DETAILS_TAB) {
      setActiveTab(tabs.PRICING_TAB);
    } else if (activeTab === tabs.PRICING_TAB) {
      setActiveTab(tabs.ACCESS_TAB);
    } else if (activeTab === tabs.ACCESS_TAB) {
      setActiveTab(tabs.POST_PURCHASE_TAB);
    } else if (activeTab === tabs.POST_PURCHASE_TAB) {
      setActiveTab(tabs.TRACKING_TAB);
    } else if (activeTab === tabs.TRACKING_TAB) {
      return mutation.mutate(serializePaywallJson(data));
    }
  };

  return {
    getDefaultValues,
    activeTab,
    setActiveTab,
    handleSubmit,
    mutation,
    currentPaywallStatus,
  };
};
