import I18n from "i18n-js";
import { useMutation, useQuery } from "react-query";
import { reactQueryGet } from "@/react/helpers/backendRequestHelpers";
import { internalApi } from "@/react/helpers/urlHelpers";
import { paywallCouponApi } from "@circle-react/api/paywallCouponApi";
import { useToast } from "@circle-react-uikit/ToastV2";

export const usePaywallCouponApi = ({
  refetchCoupons = null,
  onClose = null,
  paywallCoupon = null,
}) => {
  const { success, error } = useToast();
  const REQUEST_KEY = "paywall-coupons";

  const useListPaywallCoupons = () => {
    const queryFn = () => reactQueryGet(internalApi.paywallCoupons.index());

    return useQuery(REQUEST_KEY, queryFn);
  };

  const updateMutation = useMutation(
    formData =>
      paywallCouponApi.updatePaywallCoupon(paywallCoupon.id, formData),
    {
      onSuccess: () => {
        success(I18n.t("settings.paywall_coupons.alerts.success.updated"));
        onClose();
        refetchCoupons();
      },
      onError: updateError => {
        console.error(updateError.errorDetails);
        error(updateError.errorDetails, {
          duration: "long",
        });
      },
    },
  );

  const createMutation = useMutation(
    formData => paywallCouponApi.createPaywallCoupon(formData),
    {
      onSuccess: () => {
        success(I18n.t("settings.paywall_coupons.alerts.success.created"));
        onClose();
        refetchCoupons();
      },
      onError: createError => {
        console.error(createError.errorDetails);
        error(createError.errorDetails, {
          duration: "long",
        });
      },
    },
  );

  const destroyMutation = useMutation(
    () => paywallCouponApi.destroyPaywallCoupon(paywallCoupon.id),
    {
      onSuccess: () => {
        success(I18n.t("settings.paywall_coupons.alerts.success.deleted"));
        refetchCoupons();
      },
      onError: destroyError => {
        console.error(destroyError.message);
        error(destroyError.message, {
          duration: "long",
        });
      },
    },
  );

  return {
    useListPaywallCoupons,
    updateMutation,
    createMutation,
    destroyMutation,
  };
};
