import { useUsageAndConfigLimits } from "@circle-react/components/LiveStreams/hooks";
import { usePunditUserContext } from "@circle-react/contexts";
import { Attendees } from "./Attendees";
import { RecordingStorage } from "./RecordingStorage";
import { StreamHours } from "./StreamHours";

export const Limits = () => {
  const { currentCommunity } = usePunditUserContext();
  const {
    monthlyHourLimit,
    monthlyHourUsage,
    storageLimit,
    storageUsage,
    hostLimit,
    participantLimit,
    isLoading,
    refetchAddons,
  } = useUsageAndConfigLimits();

  if (!currentCommunity.supports_live_streams) {
    return null;
  }

  return (
    <div className="mb-4 flex w-full flex-col gap-4 md:flex-row">
      <StreamHours
        isLoading={isLoading}
        limit={monthlyHourLimit}
        usage={monthlyHourUsage}
        onPurchase={refetchAddons}
      />
      <RecordingStorage
        isLoading={isLoading}
        limit={storageLimit}
        usage={storageUsage}
        onPurchase={refetchAddons}
      />
      <Attendees
        isLoading={isLoading}
        hosts={hostLimit}
        participants={participantLimit}
        onPurchase={refetchAddons}
      />
    </div>
  );
};
