import { Suspense, useEffect } from "react";
import { useSpaceSlugFromUrl } from "@/react/hooks/useSpaceSlugFromUrl";
import { useDynamicLayoutContext } from "@circle-react/components/Layout/DynamicLayout";
import {
  CurrentSpaceContextProvider,
  useSpacesContext,
} from "@circle-react/contexts";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { PageLoader } from "@circle-react/pages/PageLoader";

const PostComponent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "PostPage" */ "@circle-react/pages/Post/VersionedPost"
    ),
);

export const PostPage = () => {
  const spaceSlug = useSpaceSlugFromUrl();
  const { isLoading, helpers } = useSpacesContext();
  const space = helpers.findBy({ slug: spaceSlug });
  const { setDynamicLayoutProps } = useDynamicLayoutContext();

  useEffect(() => {
    setDynamicLayoutProps({
      disableRightSidebar: false,
    });
  }, [setDynamicLayoutProps]);

  if (isLoading) {
    return null;
  }

  return (
    <CurrentSpaceContextProvider spaceId={space?.id}>
      <Suspense fallback={<PageLoader />}>
        <PostComponent />
      </Suspense>
    </CurrentSpaceContextProvider>
  );
};
