import PropTypes from "prop-types";
import { QueryClient, QueryClientProvider } from "react-query";
import { reactQueryConfig } from "@circle-react/providers/QueryClient/reactQueryConfig";
import { ToastProvider } from "@circle-react-uikit/ToastV2";
import { PaywallChargesAdmin } from "./PaywallChargesAdmin";

const queryClient = new QueryClient(reactQueryConfig);
export const PaywallChargesAdminIndex = () => (
  <ToastProvider>
    <QueryClientProvider client={queryClient}>
      <PaywallChargesAdmin />
    </QueryClientProvider>
  </ToastProvider>
);

PaywallChargesAdminIndex.propTypes = {
  queryParams: PropTypes.object,
};
