import {
  CurrentCommunityContextProvider,
  SpaceGroupsContextProvider,
} from "@circle-react/contexts";
import { NewForm } from "./NewForm";
import type { NewFormProps } from "./NewForm";
import "./styles.scss";

export const NewV2 = (props: NewFormProps) => (
  <CurrentCommunityContextProvider>
    <SpaceGroupsContextProvider>
      <NewForm {...props} />
    </SpaceGroupsContextProvider>
  </CurrentCommunityContextProvider>
);
