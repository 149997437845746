import { Typography } from "@circle-react-uikit/Typography";

interface TabTitleProps {
  title: string;
}

export const TabTitle = ({ title }: TabTitleProps) => (
  <div className="pt-7">
    <Typography.TitleSm weight="semibold">{title}</Typography.TitleSm>
  </div>
);
