import PropTypes from "prop-types";
import { t } from "i18n-js";
import { TippyV2 } from "@circle-react/components/shared/TippyV2";
import { Icon } from "@circle-react-shared/Icon";

const i18nRoot = "settings.workflows.form";

const Content = ({ hasError }) => (
  <span className="flex items-center">
    {hasError ? (
      <Icon
        type="20-alert"
        size={20}
        className="!text-v2-danger"
        aria-label={t([i18nRoot, "step_incomplete"])}
      />
    ) : (
      <Icon
        type="20-checkmark-circle-fill-thin"
        size={20}
        aria-label={t([i18nRoot, "step_complete"])}
      />
    )}
  </span>
);

Content.propTypes = {
  hasError: PropTypes.bool,
};

export const StatusIcon = ({
  hasError = false,
  hasSuccess = false,
  statusMessage,
}) => {
  if (!hasError && !hasSuccess) {
    return null;
  }

  if (statusMessage) {
    return (
      <TippyV2 content={statusMessage} className="cursor-pointer">
        <Content hasError={hasError} />
      </TippyV2>
    );
  }

  return <Content hasError={hasError} />;
};

StatusIcon.propTypes = {
  hasError: PropTypes.bool,
  hasSuccess: PropTypes.bool,
  statusMessage: PropTypes.string,
};
