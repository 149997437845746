import PropTypes from "prop-types";
import { t } from "i18n-js";
import isEmpty from "lodash/isEmpty";
import { Badge } from "@circle-react/components/SettingsApp/Workflows/components/Badge";
import { useFetchPaywallPrice } from "@circle-react/components/SettingsApp/Workflows/hooks/useFetchPaywallPrice";
import { Typography } from "@circle-react-uikit/Typography";

export const PaywallPriceFromDetails = ({ paywallId, paywallPriceId }) => {
  const { paywallPrice = {}, isLoading } = useFetchPaywallPrice({
    paywallId: paywallId,
    priceId: paywallPriceId,
  });

  if (isLoading) {
    return <Badge isLoading />;
  }

  if (isEmpty(paywallPrice)) {
    return (
      <Typography.LabelSm>
        {t("settings.workflows.view.deleted_paywall_price")}
      </Typography.LabelSm>
    );
  }

  return (
    <Typography.LabelSm>
      {paywallPrice.paywall_name} - {paywallPrice.display_name}
    </Typography.LabelSm>
  );
};

PaywallPriceFromDetails.propTypes = {
  paywallPriceId: PropTypes.number.isRequired,
  paywallId: PropTypes.number.isRequired,
};
