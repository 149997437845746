import { t } from "i18n-js";
import { filter } from "lodash";
import { formatDisplayAmountString } from "@/react/components/Paywalls/Admin/utils";
import { mapCurrencyAmountStrToInt } from "@circle-react/components/Paywalls/Admin/utils";
import type {
  PaywallCurrency,
  PaywallPriceBuildOptionsEntry,
  PaywallPriceBuildOptionsFrequency,
  PaywallPriceInterval,
} from "@circle-react/types";
import { paywallPriceTypes } from "@circle-react/types";
import { formatPaywallCurrencyAmount } from "./paywalls/currencies";

export const PRICE_TRIAL_ELIGIBILITY_CONTEXTS = {
  addingPrice: "adding_price",
  addingSubscription: "adding_subscription",
};

export const isSubscriptionPrice = (paywallPrice: any) =>
  paywallPrice?.price_type === paywallPriceTypes.subscription;
export const isInstallmentsPrice = (paywallPrice: any) =>
  paywallPrice?.price_type === paywallPriceTypes.installments;
export const isOneTimePrice = (paywallPrice: any) =>
  paywallPrice?.price_type === paywallPriceTypes.onetime;

export const isRecurringPrice = (paywallPrice: any) =>
  isSubscriptionPrice(paywallPrice) || isInstallmentsPrice(paywallPrice);

export const recurringPrices = (paywallPrices: any) =>
  filter(paywallPrices, paywallPrice => isRecurringPrice(paywallPrice));

export const isPriceEligibleForTrial = (
  paywallPrice: any,
  context: any = PRICE_TRIAL_ELIGIBILITY_CONTEXTS.addingSubscription,
) =>
  isRecurringPrice(paywallPrice) &&
  (context == PRICE_TRIAL_ELIGIBILITY_CONTEXTS.addingPrice ||
    !paywallPrice.upfront_payment_amount);

export const subscriptionPrices = (paywallPrices: any) =>
  filter(paywallPrices, paywallPrice => isSubscriptionPrice(paywallPrice));

export const pricesEligibleForTrial = (
  paywallPrices: any,
  context: any = PRICE_TRIAL_ELIGIBILITY_CONTEXTS.addingSubscription,
) =>
  filter(paywallPrices, paywallPrice =>
    isPriceEligibleForTrial(paywallPrice, context),
  );

export const findFrequencyByIntervalAndCount = (
  buildOptions: PaywallPriceBuildOptionsEntry,
  interval?: PaywallPriceInterval,
  interval_count?: number,
) =>
  Object.values(buildOptions.frequencies).find(
    (frequency: PaywallPriceBuildOptionsFrequency) =>
      frequency.interval === interval &&
      frequency.interval_count === interval_count,
  );

export const displayPaywallPrice = (paywall: any) => {
  const preferredPrice =
    paywall.stripe_prices_attributes.find(
      (price_attribute: any) => price_attribute.preferred,
    ) ?? paywall.stripe_prices_attributes[0];

  return preferredPrice
    ? formatDisplayAmountString(
        paywall.currency,
        preferredPrice.interval,
        preferredPrice.interval_count,
        preferredPrice.amount,
      )
    : t("settings.paywalls.list.form.no_preferred_price_selected");
};

export type UpfrontPaymentVariant = "no_upfront_payment" | "upfront_payment";
export const upfrontPaymentVariants = {
  noUpfrontPayment: "no_upfront_payment",
  upfrontPayment: "upfront_payment",
} as const;

export type UpfrontPaymentDetails =
  | {
      variant: "upfront_payment";
      amountInCents: number;
      amountFormatted: string;
    }
  | {
      variant: "no_upfront_payment";
      amountInCents?: undefined;
      amountFormatted?: undefined;
    };
export const resolveUpfrontPaymentDetails = (
  currency: PaywallCurrency,
  upfrontAmount: string | number | undefined,
): UpfrontPaymentDetails => {
  if (!upfrontAmount) {
    return { variant: upfrontPaymentVariants.noUpfrontPayment };
  }
  const amountInCents = mapCurrencyAmountStrToInt(upfrontAmount);
  if (!amountInCents) {
    return { variant: upfrontPaymentVariants.noUpfrontPayment };
  }
  const amountFormatted = formatPaywallCurrencyAmount(currency, amountInCents, {
    amountInCents: true,
    includeCurrencyCode: true,
  });
  return {
    variant: upfrontPaymentVariants.upfrontPayment,
    amountInCents,
    amountFormatted,
  };
};

export const calculateInstallmentAmount = (
  upfrontPaymentDetails: UpfrontPaymentDetails,
  amount: number,
  count: number,
) => {
  if (
    upfrontPaymentDetails.variant === upfrontPaymentVariants.noUpfrontPayment
  ) {
    return Math.round(amount / count);
  }
  return Math.round((amount - upfrontPaymentDetails.amountInCents) / count);
};
