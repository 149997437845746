import { useEffect, useRef, useState } from "react";
import classnames from "classnames";
import InnerHTML from "dangerously-set-html-content";
import { t } from "i18n-js";
import { postsPath } from "../../../helpers/urlHelpers";
import Comments from "../../Comments";
import { commentIdFromLocationHash } from "../../Comments/helpers";
import { Icon } from "../../shared/Icon";

export const Show = ({
  space,
  post,
  current_user,
  show_comments_on_load = false,
  is_shared_post = false,
}) => {
  const currentCommunityMember = current_user;
  const postRef = useRef(null);

  const [showComment, setShowComment] = useState(
    show_comments_on_load && !is_shared_post,
  );
  const [autofocusReplyForm, setAutoFocusOnReplyForm] = useState(
    !show_comments_on_load,
  );

  const commentIdFromHash = commentIdFromLocationHash();
  const [showCommentHierarchy, setShowCommentHierarchy] = useState(
    commentIdFromHash && post.comments_count > 10,
  );

  useEffect(() => {
    if (!postRef.current) {
      return;
    }
    const handleClick = event => {
      const toggleCommentWithFocus = postRef.current.querySelector(
        '[data-action="toggle-comment-with-focus"]',
      );

      const toggleCommentWithoutFocus = postRef.current.querySelector(
        '[data-action="toggle-comment"]',
      );
      if (!toggleCommentWithFocus) {
        return;
      }
      if (!toggleCommentWithoutFocus) {
        return;
      }

      const focusCommentBox = toggleCommentWithFocus.contains(event.target);
      const toggleComments =
        focusCommentBox || toggleCommentWithoutFocus.contains(event.target);
      if (toggleComments) {
        event.preventDefault();
        if (is_shared_post) {
          const path = postsPath({
            slug: post.slug,
            spaceSlug: space.slug,
            params: { notice: t("comments.log_in_to_add_a_comment") },
          });
          window.location.href = path;
          return;
        }
        setShowComment(!showComment);
        setAutoFocusOnReplyForm(!!focusCommentBox);
      }
    };

    postRef.current.addEventListener("click", handleClick);
    return () => {
      postRef.current.removeEventListener("click", handleClick);
    };
  }, [postRef, setShowComment, showComment]);

  const postContainerClassnames = classnames("post post--parent", {
    "post--shared": is_shared_post,
    "post--shared__truncated": is_shared_post && !currentCommunityMember,
    "is-truncation-disabled": post.is_truncation_disabled,
    "is-pinned": post.pinned_at_top_of_space,
    "flagged-for-approval": post.flagged_for_approval_at,
    "post-name--empty": !post.name,
    "post-meta--hidden": post.hide_meta_info,
  });

  const gatedCommentCondition =
    (!currentCommunityMember && post?.is_comments_enabled) ||
    (is_shared_post && post?.show_post_teaser);

  return (
    <div className={postContainerClassnames}>
      <div ref={postRef}>
        <InnerHTML html={post.html} />
      </div>
      {Boolean(!!showComment && !is_shared_post) && (
        <>
          <div
            className="post__comments trix-v2"
            data-controller="comments"
            id="all_comments"
            data-post-id={post.id}
          >
            {Boolean(gatedCommentCondition && post?.comments_count > 5) && (
              <InnerHTML html={post.gated_html} />
            )}
            {Boolean(showCommentHierarchy) && (
              <>
                {Boolean(post?.comments_count > 1) && (
                  <div className="show-all-comments__wrapper">
                    <a
                      className="show-all-comments__link btn btn-secondary"
                      href="#all_comments"
                      onClick={event => {
                        event.preventDefault();
                        setShowCommentHierarchy(false);
                        window.location.hash = "#all_comments";
                      }}
                    >
                      <Icon type="arrow-solid-top-left" />
                      {t("post.comments_section.show_all_count_comments", {
                        count: post.comments_count,
                      })}
                    </a>
                  </div>
                )}
                <Comments.CommentHierarchy
                  id={commentIdFromHash}
                  post={post}
                  space={space}
                  communityMember={currentCommunityMember}
                  showReplyForm
                  autofocusReplyForm={autofocusReplyForm}
                />
              </>
            )}
            {Boolean(!showCommentHierarchy) && (
              <Comments.CommentsList
                post={post}
                space={space}
                communityMember={currentCommunityMember}
                showReplyForm
                autofocusReplyForm={autofocusReplyForm}
              />
            )}
          </div>
          {Boolean(gatedCommentCondition && post?.comments_count <= 5) && (
            <InnerHTML html={post.gated_html} />
          )}
        </>
      )}
    </div>
  );
};
