import { useQuery } from "react-query";
import type { ApiError } from "@circle-react/config/CustomErrors";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import type { PaywallSubscriptionStats } from "@circle-react/types";

export const useSubscriptionStatsQuery = () => {
  const {
    isLoading: isSubscriptionStatsLoading,
    data: subscriptionStats,
    error: subscriptionStatsError,
  } = useQuery<PaywallSubscriptionStats, ApiError>(
    internalApi.admin.paywalls.subscriptions.stats.index(),
  );

  return {
    isSubscriptionStatsLoading,
    subscriptionStats,
    subscriptionStatsError,
  };
};
