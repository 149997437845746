import { t } from "i18n-js";
import { StripeOnboarding } from "../StripeOnboarding";
import { PaymentProcessorStatusAlert } from "./PaymentProcessorStatusAlert";

export const PaymentProcessorNotExist = () => {
  const title = t("settings.paywalls.processor_status_v2.onboarding.title");

  return (
    <div className="flex h-full w-full flex-col py-0 md:py-8">
      <div className="w-full self-center md:w-3/4">
        <PaymentProcessorStatusAlert
          sentiment="default"
          title={title}
          description={<StripeOnboarding />}
        />
        <p
          className="text-default px-7 text-xs"
          dangerouslySetInnerHTML={{
            __html: t(
              "settings.paywalls.processor_status_v2.onboarding.stripe_regulations_description",
            ),
          }}
        />
      </div>
    </div>
  );
};

PaymentProcessorNotExist.propTypes = {};
