import { createContext, useContext } from "react";
import type { UseQueryResult } from "react-query";
import invariant from "tiny-invariant";
import type { ApiError } from "@circle-react/config/CustomErrors";
import type { AudienceType } from "@circle-react/types/AudienceList";

interface AdminAudienceEditModalContextValues {
  publicId?: string;
  /** @todo We know the type of response here is ContactDetailResponse. But switching to it causes lots of TS errors down the tree */
  profileInfoQuery: Partial<UseQueryResult<any, ApiError>>;
  type: AudienceType;
}

export const AdminAudienceEditModalContext =
  createContext<AdminAudienceEditModalContextValues | null>(null);
AdminAudienceEditModalContext.displayName = "AdminAudienceEditModalContext";

export const useAdminAudienceEditModalContext = () => {
  const context = useContext(AdminAudienceEditModalContext);
  invariant(
    context,
    "useAdminAudienceEditModalContext must be used within a Provider",
  );
  return context;
};
