import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import type { SearchResultModalComponentProps } from "./SearchResultModalComponent";
import { SearchResultModalComponent } from "./SearchResultModalComponent";

const SearchResultModalDrawer = ModalManager.create(
  ({ searchValue }: SearchResultModalComponentProps) => (
    <SearchResultModalComponent searchValue={searchValue} />
  ),
);

export const useSearchResultsModal = () => useModal(SearchResultModalDrawer);
