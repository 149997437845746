import { DefaultOptionsContainer } from "./DefaultOptionsContainer";
import { useGetMessage } from "./useGetMessage";

export function EveryDayOptions() {
  const message = useGetMessage("every_day_at_until", "every_day_at");

  return (
    <DefaultOptionsContainer
      i18n={["settings.workflows.recurring_schedule_modal", message]}
    />
  );
}
