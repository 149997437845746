import { useEffect, useRef } from "react";
import { noop } from "lodash";
import { useHistory } from "react-router-dom";
import { getRouteForResultType } from "@/react/components/HeaderV2/Search/utils";
import type { SearchType } from "@/react/components/search/types";
import { useSearchAutocompleteV2 } from "@/react/components/search/useSearchAutocompleteV2";
import { isIntendedOnNewTab } from "@circle-react/helpers/browserHelpers";
import { scrollToElementIfNeeded } from "@circle-react/helpers/scrollToElementIfNeeded";

interface UseSearchV2Props {
  onClose: () => void;
  type: SearchType;
  onSearchInCommunity?: () => void;
}

export const useSearchV2 = ({
  onClose,
  type = "general",
  onSearchInCommunity = noop,
}: UseSearchV2Props) => {
  const history = useHistory();
  const searchInputRef = useRef(null);
  const resultsContainerRef = useRef(null);

  const onPressEnter = (event: any) => {
    // if selected result is the first, search for the community
    if (selectedResultIndex === 0) {
      onSearchInCommunity();
      // TODO uncomment when we have search in context
      // if selected result is the second, search based on the current context
      // } else if (selectedResultIndex === 1) {
      //   onSearchInContext();
    } else {
      const result = results[selectedResultIndex - 1];
      const route = getRouteForResultType(result);
      if (isIntendedOnNewTab(event)) {
        return window.open(route);
      }
      history.push(route);
      onNavigateToResult(result);
    }
    return undefined;
  };

  const scrollToElement = (selectedIndex: number) => {
    if (selectedIndex === -1) {
      return;
    }

    scrollToElementIfNeeded({
      containerEl: resultsContainerRef.current,
      itemSelector: `[aria-posinset="${String(selectedIndex)}"]`,
    });
  };

  const {
    searchValue,
    setSearchValue,
    results,
    isLoading: isLoadingSearch,
    hasNoResults,
    onKeydown,
    selectedResultIndex,
    resetSearch,
    onMouseOver,
    onFocus,
    fetchNextPage,
    hasNextPage,
    onMouseOut,
    countData,
    isLoadingCount,
  } = useSearchAutocompleteV2({
    onPressEnter,
    scrollToElement,
    type,
  });

  const onNavigateToResult = (event: any) => {
    if (isIntendedOnNewTab(event)) {
      return;
    }
    resetSearch();
    onClose();
  };

  useEffect(() => {
    if (!searchValue && searchInputRef.current) {
      resetSearch();
    }
  }, [searchValue, searchInputRef, resetSearch]);

  const isLoading = isLoadingSearch || isLoadingCount;

  return {
    searchValue,
    results,
    isLoading,
    isLoadingSearch,
    isLoadingCount,
    hasNoResults,
    selectedResultIndex,
    searchInputRef,
    resultsContainerRef,
    setSearchValue,
    onKeydown,
    fetchNextPage,
    hasNextPage,
    resetSearch,
    onNavigateToResult,
    onMouseOver,
    onFocus,
    onMouseOut,
    countData,
  };
};
