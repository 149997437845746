import { t } from "i18n-js";
import { TRIGGER_RESOURCE_TYPE } from "../../../constants";
import type { EventOption } from "../types";
import { ProfileFieldResource } from "./shared/ProfileFieldResource";
import { ProfileFieldSelector } from "./shared/ProfileFieldSelector";

export const communityMemberProfileFieldUpdated: Readonly<EventOption> = {
  label: t(
    "settings.workflows.form.events.community_member_profile_field_updated.title",
  ),
  value: "community_member_profile_field_updated",
  group: t("settings.workflows.form.event_groups.member"),
  description: t(
    "settings.workflows.form.events.community_member_profile_field_updated.description",
  ),
  formComponent: ProfileFieldSelector,
  viewComponent: ProfileFieldResource,
  resource: TRIGGER_RESOURCE_TYPE.PROFILE_FIELD,
  icon: "user",
  released: true,
};
