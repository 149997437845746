import type { PropsWithChildren } from "react";
import { Typography } from "@circle-react/components/shared/uikit/Typography";

interface SpaceHeaderTitleTextProps {
  truncate?: boolean;
}

export const SpaceHeaderTitleText = ({
  children,
  truncate = false,
}: PropsWithChildren<SpaceHeaderTitleTextProps>) => (
  <Typography.TitleSm
    as="h1"
    data-testid="space-title-name"
    weight="semibold"
    truncate={truncate}
  >
    {children}
  </Typography.TitleSm>
);
