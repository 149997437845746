import { useSupportUnreleasedWorkflowsFeaturesEnabled } from "@circle-react/components/shared/Workflows/hooks/useSupportUnreleasedWorkflowsFeaturesEnabled";
import { useWorkflow } from "../WorkflowForm/useWorkflow";
import { availableActions } from "./availableActions";

export const determineReleaseStatus = (
  released: boolean | (() => boolean) | undefined,
): boolean => {
  if (released === undefined) return true;
  return typeof released === "function" ? released() : released;
};

export const useEligibleActions = () => {
  const shouldSupportUnreleasedWorkflowsFeaturesEnabled =
    useSupportUnreleasedWorkflowsFeaturesEnabled();

  const { type: workflowType } = useWorkflow();

  // Filter out actions that are not released yet
  let eligibleActions = shouldSupportUnreleasedWorkflowsFeaturesEnabled
    ? availableActions
    : availableActions.filter(availableAction =>
        determineReleaseStatus(availableAction.released),
      );

  // Filter out actions that are not available for the current workflow type
  eligibleActions = eligibleActions.filter(
    action =>
      !action.availability || action.availability.includes(workflowType),
  );

  return { eligibleActions };
};
