import { useMemo } from "react";
import { useInfiniteQuery } from "react-query";
import { invitationLinksApi } from "@circle-react/api";
import type {
  PageProps,
  UseInvitationLinksInfiniteQueryProps,
} from "../interfaces";

export const DEFAULT_PAGE_SIZE = 20;

export const useInvitationLinksInfiniteQuery = (
  args?: UseInvitationLinksInfiniteQueryProps,
) => {
  const {
    query = "",
    perPage = DEFAULT_PAGE_SIZE,
    initialPage = 1,
    options = {},
  } = args ?? {};

  const fetchPage = ({ pageParam = initialPage }): Promise<PageProps> => {
    let page = 1;

    if (pageParam > 0) {
      page = pageParam;
    }

    const params = {
      page,
      per_page: perPage,
      ...(query && { query }),
    };

    return invitationLinksApi.list({ params });
  };

  const queryKey = [
    "settings",
    "invitation-links",
    query,
    perPage,
    initialPage,
  ];

  const queryOptions = {
    getNextPageParam: ({ has_next_page, page }: PageProps) =>
      has_next_page ? Number(page) + 1 : undefined,
    getPreviousPageParam: ({ page }: any) => (page > 1 ? page - 1 : undefined),
    keepPreviousData: true,
    ...options,
  };

  const result = useInfiniteQuery(queryKey, fetchPage, queryOptions);
  const pages: PageProps[] = useMemo(
    () => result.data?.pages ?? [],
    [result.data?.pages],
  );
  const totalInvitationLinks = pages[0]?.count ?? 0;
  const pageSize = pages[0]?.per_page ?? perPage;

  return {
    ...result,
    queryKey,
    totalInvitationLinks,
    pageSize,
    pages,
  };
};
