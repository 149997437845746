import { t } from "i18n-js";
import { DateFilterComponent } from "./components/DateFilterComponent";

const localeRoot = "settings.paywalls_admin_dashboard.filters.start_date";

export const defaultQueryParams = {
  default: "start_date",
  lte: "start_date_lte",
  gte: "start_date_gte",
};
export const StartDateFilter = (props: any) => (
  <DateFilterComponent
    {...props}
    queryParamsKey={defaultQueryParams}
    localeRoot={localeRoot}
  />
);

export const filterProperties = {
  name: "start_date",
  filterKeys: Object.values(defaultQueryParams),
  component: StartDateFilter,
  label: t([localeRoot, "chip"]),
};
