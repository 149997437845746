import { useEffect } from "react";
import {
  selectIsConnectedToRoom,
  selectIsInPreview,
  selectIsSomeoneScreenSharing,
  selectLocalPeer,
  useHMSActions,
  useHMSStore,
} from "@100mslive/react-sdk";
import { t } from "i18n-js";
import { PARTICIPANT_ROLES } from "@circle-react/helpers/liveStreamRoomHelpers";
import { useToast } from "@circle-react-uikit/ToastV2";
import { useHMSConfig } from "./useHMSConfig";
import { useHMSErrorHandler } from "./useHMSErrorHandler";

export const useHMSRoom = (
  token,
  { onJoinError, participantData },
  streamEnded,
) => {
  const joinConfig = useHMSConfig(participantData);

  const { error } = useToast();
  const hmsActions = useHMSActions();
  const localPeer = useHMSStore(selectLocalPeer);
  const isConnected = useHMSStore(selectIsConnectedToRoom);
  const isInPreview = useHMSStore(selectIsInPreview);
  const isJoining = !!token && !isConnected;
  const isHost = localPeer?.roleName === PARTICIPANT_ROLES.HOST;
  const isHlsSpectator =
    localPeer?.roleName === PARTICIPANT_ROLES.HLS_SPECTATOR;
  const isSomeoneScreenSharing = useHMSStore(selectIsSomeoneScreenSharing);
  const role = localPeer?.roleName;
  const joinRoom = async () => {
    try {
      if (isInPreview) {
        // It's required to leave the preview room before joining to accept a different role
        await hmsActions.leave();
      }
      await hmsActions.join({ ...joinConfig, authToken: token });
    } catch (err) {
      console.error("joinRoom:", err.message);
      error(t("live_streams.room.join_error"));
      onJoinError && onJoinError();
    }
  };
  useHMSErrorHandler();

  useEffect(() => {
    (async () => {
      if (!!token && !isConnected && !streamEnded) {
        await joinRoom();
      }
    })();
  }, [token, isConnected, streamEnded]);

  return {
    isConnected,
    isJoining,
    isHost,
    isHlsSpectator,
    isSomeoneScreenSharing,
    role,
  };
};
