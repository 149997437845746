import PropTypes from "prop-types";
import classNames from "classnames";
import { t } from "i18n-js";
import { useSmScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { useMobileWebFlags } from "@circle-react/hooks/useMobileWebFlags";
import { useCustomizeUIData } from "@circle-react-shared/CustomizeUI";
import { FullScreenModal } from "@circle-react-shared/ModalsV2/FullScreenModal";
import { Footer } from "./Footer";
import { Header } from "./Header";

export const Content = ({ children }) => {
  const { currentSection } = useCustomizeUIData();
  const { isMobileWebNewSpaceCustomizationUIEnabled } = useMobileWebFlags();
  const isLockscreenSection =
    currentSection === t("customize_space.lock_screen");
  const isMembersSection = currentSection === t("customize_space.members");
  const isLessonsSection = currentSection === t("customize_space.lessons");
  const isSmScreen = useSmScreenMediaQuery();

  const sectionsWithFooter = [
    t("customize_space.customize"),
    t("customize_space.options"),
  ];

  const shouldShowFooter = sectionsWithFooter.includes(currentSection);
  const shouldBeFullWidth =
    isLockscreenSection || isMembersSection || isLessonsSection;
  const shouldRenderMobileFooter =
    shouldShowFooter && isMobileWebNewSpaceCustomizationUIEnabled && isSmScreen;

  return (
    <>
      <FullScreenModal.Header
        className={classNames({
          "!px-5 !py-4": isMobileWebNewSpaceCustomizationUIEnabled,
        })}
      >
        <Header />
      </FullScreenModal.Header>
      <FullScreenModal.Body
        className={classNames({
          "px-5 py-8": isMobileWebNewSpaceCustomizationUIEnabled,
        })}
        wrapperClassName={classNames({
          "max-w-full h-full": shouldBeFullWidth,
        })}
      >
        {children}
        {shouldRenderMobileFooter && <Footer />}
      </FullScreenModal.Body>
      {shouldShowFooter && !isMobileWebNewSpaceCustomizationUIEnabled && (
        <FullScreenModal.Footer>
          <Footer />
        </FullScreenModal.Footer>
      )}
    </>
  );
};

Content.propTypes = {
  children: PropTypes.node,
};
