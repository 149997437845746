import ReactConfetti from "react-confetti";
import { useWindowSize } from "react-use";
import { AvatarWithProgress } from "@circle-react/components/Leaderboard/Dashboard/MyProfile/AvatarWithProgress";
import { LevelBadge } from "@circle-react/components/Leaderboard/Dashboard/MyProfile/LevelBadge";
import { LevelsList } from "@circle-react/components/Leaderboard/Dashboard/MyProfile/LevelsList";
import { usePointsInfoModal } from "@circle-react/components/Leaderboard/Dashboard/MyProfile/PointsInfoModal";
import { useGamificationMember } from "@circle-react/components/Profile/HoverCard/useGamificationMember";
import { usePunditUserContext } from "@circle-react/contexts";
import { t } from "@circle-react/custom_i18n";
import { useLocalStorage } from "@circle-react/hooks/utils/useLocalStorage";
import { Icon } from "@circle-react-shared/Icon";
import { Button } from "@circle-react-shared/uikit/Button";
import { Typography } from "@circle-react-shared/uikit/Typography";

const LAST_CELEBRATED_LEVEL = "last-celebrated-level";

export const MyProfile = () => {
  const { currentCommunityMember } = usePunditUserContext();
  const { data, isLoading } = useGamificationMember({
    member_ids: [currentCommunityMember?.id],
  });
  const gamificationMember = data?.[0];
  const pointsInfoModal = usePointsInfoModal();
  const { width, height } = useWindowSize();
  const { setValue: setLastCelebratedLevel, getValue: getLastCelebratedLevel } =
    useLocalStorage(LAST_CELEBRATED_LEVEL);
  const shouldShowCelebration =
    !isLoading &&
    Number(gamificationMember?.current_level) > 1 &&
    Number(getLastCelebratedLevel()) <
      Number(gamificationMember?.current_level);

  return (
    <div className="border-primary bg-primary rounded-2xl border p-6">
      {shouldShowCelebration && (
        <ReactConfetti
          width={width}
          height={height}
          style={{
            zIndex: 999999,
          }}
          recycle={false}
          tweenDuration={5000}
          numberOfPieces={300}
          onConfettiComplete={() =>
            setLastCelebratedLevel(gamificationMember?.current_level)
          }
        />
      )}
      <div className="flex flex-col items-center justify-between md:flex-row">
        <div className="grid w-full grid-flow-row-dense grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-3">
          <div className="col-span-2 flex flex-col items-center space-x-3 space-y-4 md:flex-row md:space-y-0">
            <AvatarWithProgress
              avatarUrl={currentCommunityMember?.avatar_url}
              name={currentCommunityMember?.name}
              progress={gamificationMember?.level_progress}
              level={gamificationMember?.current_level}
            />
            <div>
              <div className="text-dark text-center md:text-left">
                <Typography.LabelLg weight="semibold">
                  {currentCommunityMember?.name}
                </Typography.LabelLg>
              </div>
              {gamificationMember &&
                Boolean(gamificationMember?.total_points) && (
                  <Typography.LabelSm>
                    <div className="text-light text-center md:text-left">
                      {t("leaderboard.n_points", {
                        count: gamificationMember.total_points,
                      })}
                    </div>
                  </Typography.LabelSm>
                )}
            </div>
          </div>
          <div className="col-span-3 flex flex-col justify-center sm:col-span-2 md:col-span-1">
            <div className="mt-4 min-w-[200px] pt-1 text-center md:mt-0 md:pl-4 md:pt-0 md:text-left">
              {isLoading || !gamificationMember ? (
                <div className="bg-tertiary h-6 w-32 animate-pulse rounded-full text-center text-transparent md:text-left" />
              ) : (
                <LevelBadge
                  level={gamificationMember?.current_level}
                  levelName={gamificationMember?.current_level_name}
                />
              )}
              {gamificationMember && (
                <div>
                  <Button
                    type="button"
                    variant="text"
                    className="md:flex"
                    onClick={() => pointsInfoModal.show()}
                  >
                    <Typography.LabelXs weight="regular">
                      <div className="text-light">
                        {gamificationMember.points_to_next_level === 0
                          ? t("leaderboard.maxed_out")
                          : t("leaderboard.n_points_to_level_up", {
                              count: gamificationMember?.points_to_next_level,
                            })}
                        <Icon
                          type="question-mark"
                          className="text-light ml-1 !w-3"
                        />
                      </div>
                    </Typography.LabelXs>
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <LevelsList
        isLoadingGamificationMember={isLoading}
        currentLevel={gamificationMember?.current_level}
      />
    </div>
  );
};
