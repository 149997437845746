import { useMutation } from "react-query";
import { reactQueryDelete } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";

export const useRemoveMemberFromSpace = options => {
  const mutation = ({ spaceId, memberId }) =>
    reactQueryDelete(
      internalApi.spaceMembers.leaveSpace({
        spaceId,
        memberId,
      }),
    );

  return useMutation(mutation, options);
};
