import { useState } from "react";
import {
  fetchRequest,
  reactQueryPatch,
} from "../helpers/backendRequestHelpers";
import {
  internalApi,
  joinSpacePath,
  spacePath,
  spaceUpdatePath,
} from "../helpers/urlHelpers";

export const useSpaceApi = () => {
  const [isJoined, setIsJoined] = useState(false);
  const [space, setSpace] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const joinSpace = spaceId => {
    const data = { space_id: spaceId };

    fetch(joinSpacePath(), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      credentials: "same-origin",
      body: JSON.stringify(data),
    })
      .then(r => r.json())
      .then(() => {
        setIsJoined(true);
      })
      .catch(error => {
        console.error(error);
      });
  };

  const fetchSpace = id =>
    fetchRequest(spacePath(id))
      .then(r => r.json())
      .then(data => {
        setSpace(data);
      });

  const updateSpace = (updates, spaceId, options = {}) => {
    setIsSubmitting(true);
    const body =
      updates instanceof FormData
        ? updates
        : JSON.stringify({
            space: updates,
          });
    return fetchRequest(spaceUpdatePath(spaceId || space.id), {
      method: "PATCH",
      body,
      ...options,
    })
      .then(r => r.json())
      .then(data => {
        setSpace(data);
        setIsSubmitting(false);
        return data;
      });
  };

  const maxSpaceLimit = spaceGroupId =>
    fetchRequest(internalApi.spaces.maxSpaceLimit(spaceGroupId), {
      method: "GET",
      forReactQuery: true,
    });

  const createSpace = attributes => {
    const body = JSON.stringify({
      space: attributes,
    });
    return fetchRequest(internalApi.spaces.create(), {
      method: "POST",
      body,
      forReactQuery: true,
    });
  };

  const reSetWelcomeBannerPreference = spaceId =>
    reactQueryPatch(
      internalApi.spaces.resetSpaceWelcomeBannerPreferencePath(spaceId),
    );
  return {
    isJoined,
    joinSpace,
    space,
    fetchSpace,
    updateSpace,
    createSpace,
    maxSpaceLimit,
    isSubmitting,
    reSetWelcomeBannerPreference,
  };
};
