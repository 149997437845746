import { useFormContext } from "react-hook-form";
import { useFetchPreviewAttributes } from "@circle-react/components/SettingsApp/Workflows/hooks/useFetchPreviewAttributes";
import { useSupportUnreleasedWorkflowsFeaturesEnabled } from "@circle-react-shared/Workflows/hooks/useSupportUnreleasedWorkflowsFeaturesEnabled";
import { useCurrentAction } from "../../../ActionRule";
import { usePreviewDmModal } from "./PreviewDmModal";
import { useDynamicVariables } from "./useDynamicVariables";

const doesObjectContainString = (obj, pattern) =>
  Object.values(obj || {}).some(value => {
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        return value.some(item => doesObjectContainString(item, pattern));
      }
      return doesObjectContainString(value, pattern);
    }
    return typeof value === "string" && pattern.test(value);
  });

export const useMessageBodyInput = () => {
  const { watch, getValues } = useFormContext();
  const { paramsPath } = useCurrentAction();
  const previewDmModal = usePreviewDmModal();
  const shouldSupportTriggerVariables =
    useSupportUnreleasedWorkflowsFeaturesEnabled();

  const triggerType = getValues("trigger.trigger_type");

  const {
    data: { supported_variables: dynamicVariables = [] } = {},
    isLoading: isLoadingDynamicVariables,
  } = useDynamicVariables({
    triggerType,
    shouldSupportTriggerVariables,
  });

  const richTextBody = watch(`${paramsPath}.rich_text_body`);

  const { data: resourceAttributes, isSuccess: arePreviewAttributesFetched } =
    useFetchPreviewAttributes({ triggerType });

  const handlePreviewDmClick = () => {
    previewDmModal.show({
      body: getValues(`${paramsPath}.rich_text_body`).body,
      communityMemberId: getValues(`${paramsPath}.from`),
      localSgidToObjectMap: getValues(`${paramsPath}.rich_text_body`)
        .localSgidToObjectMap,
      resourceAttributes,
    });
  };

  const shouldShowPreviewDmButton =
    doesObjectContainString(richTextBody || {}, /{{[^{}]+}}/g) &&
    arePreviewAttributesFetched;

  return {
    paramsPath,
    triggerType,
    dynamicVariables,
    isLoadingDynamicVariables,
    shouldShowPreviewDmButton,
    handlePreviewDmClick,
  };
};
