import type { ReactNode } from "react";
import classNames from "classnames";

export interface FrameProps {
  children: ReactNode;
  isDms: boolean;
}

export const Frame = ({ isDms, children }: FrameProps) => (
  <div
    className={classNames("bg-primary flex h-full w-full flex-row", {
      "max-h-[calc(100vh-64px)]": !isDms,
    })}
  >
    {children}
  </div>
);
