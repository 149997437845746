import { useEffect } from "react";
import PropTypes from "prop-types";
import I18n, { t } from "i18n-js";
import { useFormContext } from "react-hook-form";
import { isChatSpace, isEventsSpace } from "@/react/helpers/spaceHelpers";
import { SpaceGroupSelect } from "@circle-react/components/shared/SpaceGroupSelect";
import { useSpaceGroupsContext } from "@circle-react/contexts";
import { Form } from "@circle-react-uikit/Form";
import { useModalData } from "@circle-react-uikit/ModalV2";

export const GeneralForm = ({ onChange, space }) => {
  const { records: spaceGroups } = useSpaceGroupsContext();
  const { contentPortalElement } = useModalData();
  const {
    formState: { dirtyFields },
  } = useFormContext();

  useEffect(() => {
    if (
      "space_group_id" in dirtyFields ||
      "name" in dirtyFields ||
      "emoji" in dirtyFields ||
      "custom_emoji" in dirtyFields ||
      "event_auto_rsvp_enabled" in dirtyFields ||
      "chat_room_description" in dirtyFields ||
      "show_next_event" in dirtyFields
    ) {
      onChange(true);
    }
  }, [
    dirtyFields.space_group_id,
    dirtyFields.name,
    dirtyFields.emoji,
    dirtyFields.custom_emoji,
    dirtyFields.chat_room_description,
    dirtyFields.event_auto_rsvp_enabled,
    dirtyFields.show_next_event,
  ]);

  return (
    <div className="grid grid-cols-1 gap-0 md:mt-4 md:gap-4">
      <Form.Item
        name="name"
        hideDescription
        translationRoot="spaces.form.create.basic_info"
        rules={{
          required: t("spaces.form.edit.name_required"),
          maxLength: 255,
        }}
        hideBorder
        fullWidth
        variant="extra-bottom-margin"
      >
        <Form.Input prefix={<Form.EmojiPickerV3Wrapper record={space} />} />
      </Form.Item>
      <Form.Item
        name="space_group_id"
        translationRoot="spaces.form.create.basic_info"
        hideDescription
        rules={{
          required: t("spaces.form.edit.choose_space_group"),
          maxLength: 255,
        }}
        hideBorder
        fullWidth
        variant="extra-bottom-margin"
      >
        <SpaceGroupSelect
          variant="form-input"
          spaceGroups={spaceGroups}
          name="space_group_id"
          appendTo={contentPortalElement}
        />
      </Form.Item>
      {isChatSpace(space) && (
        <Form.Item
          name="chat_room_description"
          hideBorder
          hideDescription
          label={t("spaces.form.edit.customize.add_description")}
          placeholder={t("spaces.form.edit.customize.description_placeholder")}
          variant="extra-bottom-margin"
        >
          <Form.TextArea />
        </Form.Item>
      )}
      {isEventsSpace(space) && (
        <>
          <Form.Item
            name="event_auto_rsvp_enabled"
            label={I18n.t("spaces.form.event_auto_rsvp_enabled")}
            hideBorder
            hideDescription
            inline
            shouldToggleValueOnLabelClick
          >
            <Form.ToggleSwitch variant="small" />
          </Form.Item>
          <Form.Item
            name="show_next_event"
            label={I18n.t("spaces.form.show_next_event")}
            hideBorder
            hideDescription
            inline
            shouldToggleValueOnLabelClick
          >
            <Form.ToggleSwitch variant="small" />
          </Form.Item>
        </>
      )}
    </div>
  );
};

GeneralForm.propTypes = {
  space: PropTypes.object.isRequired,
  onChange: PropTypes.object.isRequired,
};
