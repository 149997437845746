/* eslint-disable no-restricted-syntax -- Not translatable strings */
export const DEFAULT_INTERVAL_OPTION = "monthly";
export const ONETIME = "onetime";
export const SUBSCRIPTION = "subscription";

// These values below limits the Paywall Price amount
// to be registered.
// The Minimum Price Value is a constraint set by Stripe, which
// limits one-time payments; also we propagate this
// constraint to others payments (subscriptions).
export const MINIMUM_PAYWALL_PRICE_VALUE_IN_CENTS = 100;
export const MAXIMUM_PAYWALL_PRICE_VALUE_IN_CENTS = 99999999;

export const MINIMUM_FREE_TRIAL_DAYS_WHEN_ENABLED = 1;
export const MINIMUM_FREE_TRIAL_DAYS_WHEN_DISABLED = 0;
export const MAXIMUM_FREE_TRIAL_DAYS = 730;

export const MAXIMUM_PAYWALL_PRICES = 6;
export const INTERVAL_OPTIONS = [
  "monthly",
  "annual",
  "quarter",
  "biannual",
  "biweekly",
  "weekly",
  "daily",
  "onetime",
];

export const INTERVAL_OPTION_MAPPER = {
  daily: {
    interval: "daily",
    interval_count: 1,
    interval_label: "daily",
  },
  weekly: {
    interval: "weekly",
    interval_count: 1,
    interval_label: "weekly",
  },
  biweekly: {
    interval: "weekly",
    interval_count: 2,
    interval_label: "biweekly",
  },
  monthly: {
    interval: "monthly",
    interval_count: 1,
    interval_label: "monthly",
  },
  quarter: {
    interval: "monthly",
    interval_count: 3,
    interval_label: "quarter",
  },
  biannual: {
    interval: "monthly",
    interval_count: 6,
    interval_label: "biannual",
  },
  annual: {
    interval: "yearly",
    interval_count: 1,
    interval_label: "annual",
  },
  yearly: {
    interval: "yearly",
    interval_count: 1,
    interval_label: "annual",
  },
  onetime: {
    interval: undefined,
    interval_count: undefined,
    interval_label: "onetime",
  },
};
