import PropTypes from "prop-types";
import { Typography } from "@circle-react-uikit/Typography";
import { AddSection } from "./AddSection";

export const EmptyState = ({ sections, dispatchSectionsNameEditor }) => (
  <div className="border-primary flex h-72 flex-col items-center justify-center rounded-lg border">
    <div>
      <Typography.TitleSm weight="semibold">
        {I18n.t("courses.edit.sections.empty_state.title")}
      </Typography.TitleSm>
    </div>
    <div className="mt-1">
      <Typography.BodyMd>
        {I18n.t("courses.edit.sections.empty_state.description")}
      </Typography.BodyMd>
    </div>
    <div className="mt-3">
      <AddSection
        sections={sections}
        variant="secondary"
        setIsNameEditable={id =>
          dispatchSectionsNameEditor({
            id,
            value: true,
          })
        }
      />
    </div>
  </div>
);

EmptyState.propTypes = {
  sections: PropTypes.array.isRequired,
};
