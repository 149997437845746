import InnerHTML from "dangerously-set-html-content";
import { BodyMd } from "@circle-react/components/shared/uikit/Typography/components/Body/BodyMd";
import { LabelMd } from "@circle-react/components/shared/uikit/Typography/components/Label/LabelMd";
import { formattedMessageSentAt } from "@circle-react/helpers/dateTimeHelpers/formattedMessageSentAt";
import { UserImage } from "@circle-react-shared/uikit/UserImage";
import { Typography } from "@circle-react-uikit/Typography";
import { AttachmentsList } from "../AttachmentsList";
import { sanitizeHtmlForWidget } from "./sanitizeHtmlForWidget";
import "./styles.css";

export interface CommentProps {
  author: string;
  avatar: string;
  content: string;
  attachments: any[];
  createdAt: string;
}

export const Comment = ({
  author,
  avatar,
  content,
  attachments,
  createdAt,
}: CommentProps) => {
  const formattedBody = sanitizeHtmlForWidget(content);
  const { time } = formattedMessageSentAt(createdAt);

  return (
    <div className="flex gap-3">
      <div className="pt-1">
        <UserImage src={avatar} size="10" name={author} />
      </div>
      <div className="w-full">
        <div className="flex items-baseline gap-2">
          <LabelMd weight="bold">{author}</LabelMd>
          <Typography.LabelSm>{time}</Typography.LabelSm>
        </div>
        <BodyMd>
          <InnerHTML html={formattedBody} className="comment-body" />
        </BodyMd>
        <AttachmentsList attachments={attachments} />
      </div>
    </div>
  );
};
