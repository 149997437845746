import { usePunditUserContext } from "@circle-react/contexts";

export const useWhiteLabel = () => {
  const { currentCommunity } = usePunditUserContext();

  const whiteLabel = currentCommunity?.white_label;
  const shouldShowBranding = !whiteLabel;

  return {
    whiteLabel,
    shouldShowBranding,
  };
};
