import { Suspense } from "react";
import { VersionedContent } from "@circle-react/components/Layout/VersionedContent";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { PageLoader } from "@circle-react/pages/PageLoader";

const MemberComponent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "MembersPage" */ "@circle-react/components/MemberDirectory"
    ),
);

export const MembersPage = () => (
  <VersionedContent fullWidth>
    <Suspense fallback={<PageLoader />}>
      <MemberComponent />
    </Suspense>
  </VersionedContent>
);
