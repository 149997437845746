import { t } from "i18n-js";
import { LevelSelector } from "@circle-react/components/SettingsApp/Workflows/components/Events/Gamification/LevelSelector";
import { TRIGGER_RESOURCE_TYPE } from "../../../constants";
import type { EventOption } from "../types";
import { LevelResource } from "./LevelResource";

export const leveledUp: Readonly<EventOption> = {
  label: t(
    "settings.workflows.form.events.gamification_member_leveled_up.title",
  ),
  value: "gamification_member_leveled_up",
  group: t("settings.workflows.form.event_groups.member"),
  description: t(
    "settings.workflows.form.events.gamification_member_leveled_up.description",
  ),
  formComponent: LevelSelector,
  resource: TRIGGER_RESOURCE_TYPE.GAMIFICATION_LEVEL,
  viewComponent: LevelResource,
  icon: "email",
};
