import type { ReactNode } from "react";
import { t } from "i18n-js";
import { useHistory } from "react-router-dom";
import { workflowsSettingsPaths } from "@circle-react/helpers/urlHelpers";
import { Button } from "@circle-react-uikit/Button";
import { SettingsHeader } from "../../SettingsHeader";

const i18nRoot = "settings.workflows";

export interface HeaderProps {
  title: string;
  showAddNewButton?: boolean;
  showBottomBorder?: boolean;
  children?: ReactNode;
}

export const Header = ({
  title,
  showAddNewButton = true,
  showBottomBorder = true,
  children,
}: HeaderProps) => {
  const history = useHistory();

  const navigateToNewWorkflows = () => {
    history.push(workflowsSettingsPaths.new());
  };

  return (
    <SettingsHeader
      pageTitle={title}
      layoutConfigProps={{
        disableRightSidebar: true,
      }}
      hasBorder={showBottomBorder}
      rightContent={
        showAddNewButton && (
          <Button onClick={navigateToNewWorkflows} variant="circle">
            {t([i18nRoot, "new_workflow"])}
          </Button>
        )
      }
    >
      {children}
    </SettingsHeader>
  );
};
