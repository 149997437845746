import PropTypes from "prop-types";
import classNames from "classnames";
import { useSidebarBackgroundStyles } from "@/react/components/Spaces/SidebarV2/hooks";
import { Typography } from "@circle-react-uikit/Typography";

export const CommunityListItem = ({
  logoUrl,
  iconUrl,
  bgColor,
  name = "",
  rootUrl,
  isActive = false,
  notificationCount = 0,
}) => {
  const { backgroundColor: notificationCountBorderColor } =
    useSidebarBackgroundStyles();

  const hasLogo = Boolean(logoUrl);
  const hasIcon = Boolean(iconUrl);

  const renderCommunityLogo = () => {
    const firstLetter = name.charAt(0);
    if (hasLogo) {
      return <img loading="lazy" src={logoUrl} alt="" />;
    }
    if (hasIcon) {
      return (
        <img
          loading="lazy"
          src={iconUrl}
          style={{ width: "40", height: "40" }}
          alt=""
        />
      );
    }
    return firstLetter;
  };

  return (
    <a href={rootUrl}>
      <div
        className={classNames("relative flex items-center justify-center p-3", {
          "bg-tertiary rounded": isActive,
        })}
      >
        <div className="flex w-full items-center gap-6">
          <div
            className="text-badge flex h-[40px] w-[40px] min-w-[40px] cursor-pointer items-center justify-center rounded uppercase"
            style={!hasLogo && !hasIcon ? { backgroundColor: bgColor } : {}}
          >
            {renderCommunityLogo()}
          </div>
          <div>
            <div>
              <Typography.LabelMd weight="semibold">{name}</Typography.LabelMd>
            </div>
            <Typography.LabelSm color="text-light">
              {rootUrl}
            </Typography.LabelSm>
          </div>
        </div>
        {notificationCount > 0 && (
          <span
            className="text-badge flex h-[20px] min-w-[20px] items-center justify-center rounded-full bg-red-500 font-bold"
            style={{
              borderColor: notificationCountBorderColor,
            }}
          >
            <Typography.LabelSm color="#fff">
              {notificationCount}
            </Typography.LabelSm>
          </span>
        )}
      </div>
    </a>
  );
};

CommunityListItem.propTypes = {
  logoUrl: PropTypes.string,
  iconUrl: PropTypes.string,
  bgColor: PropTypes.string,
  name: PropTypes.string,
  rootUrl: PropTypes.string,
  notificationCount: PropTypes.number,
  isActive: PropTypes.bool,
};
