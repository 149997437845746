import PropTypes from "prop-types";

export const ProfileSectionName = ({ headline = "", name = "" }) => (
  <div className="flex max-w-[128px] flex-col text-left">
    <span className="text-c-sidebar truncate text-sm font-medium">{name}</span>
    <span className="text-c-sidebar truncate text-sm font-normal">
      {headline}
    </span>
  </div>
);

ProfileSectionName.propTypes = {
  headline: PropTypes.string,
  name: PropTypes.string,
};
