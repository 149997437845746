import { useLinkToRoot } from "@circle-react/hooks/useLinkToRoot";
import { MobileBottomToolbarV2View } from "./V2View";
import { useBottomBar } from "./useBottomBar";

export const MobileBottomToolbar = () => {
  const {
    newPostUrl,
    newPostUseDataRemote,
    shouldNewPostOpenModal,
    unreadChatCount,
    unreadNotificationCount,
    onNotificationsClick,
    onSearchClick,
    shouldShowMessagingRoutes,
  } = useBottomBar();

  const { goToCommunityRootPath } = useLinkToRoot();

  return (
    <MobileBottomToolbarV2View
      onSearchClick={onSearchClick}
      onNotificationsClick={onNotificationsClick}
      onHomeClick={goToCommunityRootPath}
      unreadNotificationCount={unreadNotificationCount}
      unreadChatCount={unreadChatCount}
      newPostUseDataRemote={newPostUseDataRemote}
      newPostUrl={newPostUrl}
      shouldNewPostOpenModal={shouldNewPostOpenModal}
      shouldShowMessagingRoutes={shouldShowMessagingRoutes}
    />
  );
};
