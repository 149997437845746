import { t } from "i18n-js";
import { useMutation } from "react-query";
import { paywallApi } from "@/react/api/paywallApi";
import { PAYWALL_STATUS } from "@circle-react/helpers/paywallHelpers";
import { useToast } from "@circle-react-uikit/ToastV2";

export const usePaywallApi = ({ createCallback, updateCallback, paywall }) => {
  const { success, error } = useToast();
  const defaultErrorMessage = t("settings.paywalls.alerts.error.action_failed");

  const onSuccess = (response, message, callback) => {
    success(message);
    if (callback) {
      callback(response);
    }
  };

  const onError = errorObject => {
    const messageError =
      typeof errorObject?.errorDetails === "string"
        ? errorObject?.errorDetails
        : defaultErrorMessage;
    error(messageError, {
      duration: "long",
    });
  };

  const publishOrArchiveParams = publish => ({
    publish: publish,
    paywall: {
      status: publish ? paywall.status : PAYWALL_STATUS.INACTIVE,
    },
  });

  const updateMutation = useMutation(
    formData => paywallApi.updatePaywall(paywall.id, formData),
    {
      onSuccess: response =>
        onSuccess(
          response,
          t("settings.paywalls.alerts.success.updated"),
          updateCallback,
        ),
      onError: onError,
    },
  );

  const createMutation = useMutation(
    formData => paywallApi.createPaywall(formData),
    {
      onSuccess: response =>
        onSuccess(
          response,
          t("settings.paywalls.alerts.success.created"),
          createCallback,
        ),
      onError: onError,
    },
  );

  const publishMutation = useMutation(
    () => paywallApi.updatePaywall(paywall.id, publishOrArchiveParams(true)),
    {
      onSuccess: response =>
        onSuccess(
          response,
          t("settings.paywalls.alerts.success.published"),
          updateCallback,
        ),
      onError: onError,
    },
  );

  const archiveMutation = useMutation(
    () => paywallApi.updatePaywall(paywall.id, publishOrArchiveParams(false)),
    {
      onSuccess: response =>
        onSuccess(
          response,
          t("settings.paywalls.alerts.success.archived"),
          updateCallback,
        ),
      onError: onError,
    },
  );

  const deleteMutation = useMutation(
    () => paywallApi.deletePaywall(paywall.id),
    {
      onSuccess: response =>
        onSuccess(
          response,
          t("settings.paywalls.alerts.success.deleted"),
          updateCallback,
        ),
      onError: onError,
    },
  );

  return {
    updateMutation,
    createMutation,
    publishMutation,
    archiveMutation,
    deleteMutation,
  };
};
