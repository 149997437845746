import { useState } from "react";
import { useQuery } from "react-query";
import { internalApi } from "@circle-react/helpers/urlHelpers/internalApi";

const PER_PAGE = 10;

export const useLiveStreamRoomsApi = () => {
  const [page, setPage] = useState(1);
  const fetchNextPage = () => {
    setPage(page + 1);
  };
  const fetchPrevPage = () => {
    setPage(Math.max(page - 1, 1));
  };
  const { isLoading, isFetching, data, refetch } = useQuery(
    internalApi.liveStreams.index({
      params: {
        page: page,
        per_page: PER_PAGE,
      },
    }),
    {
      keepPreviousData: true,
    },
  );

  const records = data?.records || [];
  const totalRecordCount = data?.count;
  const pageRecordCount = data?.page_count;
  const pageCount = totalRecordCount
    ? Math.ceil(totalRecordCount / PER_PAGE)
    : 0;
  const hasNextPage = !!data?.has_next_page;
  const startRecordNumber = (page - 1) * PER_PAGE + 1;
  const endRecordNumber = startRecordNumber + pageRecordCount - 1;

  return {
    page,
    records,
    totalRecordCount,
    pageRecordCount,
    pageCount,
    hasNextPage,
    startRecordNumber,
    endRecordNumber,
    isLoading,
    isFetching,
    refetch,
    fetchNextPage,
    fetchPrevPage,
  };
};
