import { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";
import { useFieldArray, useFormContext } from "react-hook-form";

const FormBlocksContext = createContext(null);
FormBlocksContext.displayName = "FormBlocksContext";

export const useFormBlocks = () => {
  const context = useContext(FormBlocksContext);

  if (!context) {
    throw new Error("useFormBlocks must be used within a FormBlocksProvider");
  }

  return context;
};

export const FormBlocksProvider = ({ children, name = "blocks" }) => {
  const [blockBeingEdited, setBlockBeingEdited] = useState(null);
  const { watch } = useFormContext();
  const { fields, update, replace } = useFieldArray({ name });

  // "fields" is not updated on every onChange event by default, the following
  // code gets the latest value of "fields" from the form state, see
  // "Controlled Field Array" on https://www.react-hook-form.com/api/usefieldarray
  const watchFieldArray = watch(name);
  const blocks = fields.map((field, index) => ({
    ...field,
    ...watchFieldArray[index],
  }));

  const isEditingBlock = Boolean(blockBeingEdited);
  const removeBlockBeingEdited = () => setBlockBeingEdited(null);

  const toggleBlockHidden = (blockId = "") => {
    if (!blockId) return;

    const block = blocks.find(block => block.id === blockId);
    if (!block) return;

    const index = blocks.indexOf(block);
    if (index === -1) return;

    const updatedBlock = { ...block, hidden: !block.hidden };
    update(index, updatedBlock);
  };

  const getBlockIndex = (blockId = "") => {
    if (!blockId) return -1;

    const block = blocks.find(block => block.id === blockId);
    if (!block) return -1;

    return blocks.indexOf(block);
  };

  const getBlockDataPath = (blockId = "") => {
    const index = getBlockIndex(blockId);
    if (index === -1) return "";
    return `${name}.${index}.data`;
  };

  const replaceBlocks = newBlocks => replace(newBlocks);

  return (
    <FormBlocksContext.Provider
      value={{
        blocks,
        isEditingBlock,
        blockBeingEdited,
        getBlockIndex,
        removeBlockBeingEdited,
        setBlockBeingEdited,
        toggleBlockHidden,
        getBlockDataPath,
        replaceBlocks,
      }}
    >
      {children}
    </FormBlocksContext.Provider>
  );
};

FormBlocksProvider.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string,
};
