import { t } from "i18n-js";
import { useMutation } from "react-query";
import { useQueryClient } from "react-query";
import { workflowsApi } from "@/react/api";
import {
  isMarketingHubRequired,
  isPlanCheckFailed,
} from "@/react/components/SettingsApp/Workflows/workflowApiErrorHelper";
import { nextPlanTierForWorkflowUpgrade } from "@/react/helpers/planHelpers";
import { getWorkflowSource } from "@circle-react/components/SettingsApp/Workflows/hooks/useOpenedFromPage";
import { usePunditUserContext } from "@circle-react/contexts";
import { minimumDelay } from "@circle-react/helpers/dateTimeHelpers";
import { useUpgradePlanModal } from "@circle-react-shared/UpgradePlan";
import { useToast } from "@circle-react-uikit/ToastV2";

const i18nRoot = "settings.workflows.view.header";

interface ActivateWorkflowVariables {
  onSuccessCallback?: () => void;
}

interface UseHeaderActionsProps {
  workflowId: string;
  isActive: boolean;
}

export const useHeaderActions = ({
  workflowId,
  isActive,
}: UseHeaderActionsProps) => {
  const queryClient = useQueryClient();
  const { success, error: toastError } = useToast();

  const invalidateQueries = async () => {
    await queryClient.invalidateQueries(["workflows", workflowId]);
    await queryClient.invalidateQueries(["all-workflows"]);
  };
  const { currentCommunity } = usePunditUserContext();
  const { current_plan_tier: currentPlanTier } = currentCommunity;

  const upgradePlanModal = useUpgradePlanModal();
  const showUpgradePlanModal = (error: any) => {
    void upgradePlanModal.show({
      ...(isPlanCheckFailed(error) && {
        planTier: nextPlanTierForWorkflowUpgrade(currentPlanTier),
      }),
      subheading: error.body.message,
      isMarketingHubRequired: isMarketingHubRequired(error),
      source: getWorkflowSource(),
      onSuccess: () => {
        void upgradePlanModal.hide();
        onActivateWorkflow({});
      },
      successButtonLabel: t(
        "settings.workflows.upgrade_modal.activate_this_workflow",
      ),
      usedForWorkflows: true,
      showPlanDetails: true,
    });
  };

  const { mutate: onActivateWorkflow, isLoading: isLoadingActivate } =
    useMutation<void, Error, ActivateWorkflowVariables>(
      () => minimumDelay(workflowsApi.activate({ id: workflowId })),
      {
        onSuccess: async (_data, variables) => {
          await invalidateQueries();
          if (variables.onSuccessCallback) {
            variables.onSuccessCallback();
          } else {
            success(t([i18nRoot, "workflow_activated"]));
          }
        },
        onError: error => {
          if (isPlanCheckFailed(error) || isMarketingHubRequired(error)) {
            return showUpgradePlanModal(error);
          }
          return toastError(t([i18nRoot, "workflow_activation_error"]));
        },
      },
    );

  const { mutate: onDeactivateWorkflow, isLoading: isLoadingDeactivate } =
    useMutation(() => workflowsApi.inactivate({ id: workflowId }), {
      onSuccess: async () => {
        await invalidateQueries();
        success(t([i18nRoot, "workflow_deactivated"]));
      },
      onError: () => toastError(t([i18nRoot, "workflow_deactivation_error"])),
    });

  const onChangeActiveStatus = () => {
    if (isActive) {
      onDeactivateWorkflow();
    } else {
      onActivateWorkflow({});
    }
  };

  return {
    onActivateWorkflow,
    onDeactivateWorkflow,
    onChangeActiveStatus,
    isLoading: isLoadingActivate || isLoadingDeactivate,
  };
};
