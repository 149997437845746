import PropTypes from "prop-types";
import { ProfileSectionDropdown } from "./ProfileSectionDropdown";

export const ProfileSectionUserMenu = ({
  headline = "",
  imageUrl = "",
  name = "",
  onEditProfileClick,
  onSignOutClick,
  publicUid = "",
  showMemberBilling = false,
  canAccessAuthenticationPage = false,
  canShowPaywallsAffiliate = false,
}) => (
  <ProfileSectionDropdown
    imageUrl={imageUrl}
    headline={headline}
    name={name}
    onEditProfileClick={onEditProfileClick}
    onSignOutClick={onSignOutClick}
    publicUid={publicUid}
    showMemberBilling={showMemberBilling}
    canAccessAuthenticationPage={canAccessAuthenticationPage}
    canShowPaywallsAffiliate={canShowPaywallsAffiliate}
  />
);

ProfileSectionUserMenu.propTypes = {
  headline: PropTypes.string,
  imageUrl: PropTypes.string,
  name: PropTypes.string,
  onEditProfileClick: PropTypes.func,
  onSignOutClick: PropTypes.func,
  publicUid: PropTypes.string,
  showMemberBilling: PropTypes.bool,
  canAccessAuthenticationPage: PropTypes.bool,
};
