import type { PropsWithChildren } from "react";
import classNames from "classnames";
import { t } from "i18n-js";
import { Button } from "@circle-react-uikit/Button";

export interface ItemButtonProp {
  isDisabled?: boolean;
  actionText?: string;
}

export const ItemButton = ({
  actionText = t("add"),
  isDisabled = false,
}: PropsWithChildren<ItemButtonProp>) => (
  <Button
    type="button"
    small
    variant="text"
    className={classNames("hidden opacity-0 transition-opacity", {
      "focus:opacity-100 group-hover:opacity-100 md:block": !isDisabled,
    })}
  >
    {actionText}
  </Button>
);
