import { useEffect, useMemo, useState } from "react";
import { t } from "i18n-js";
import { useHistory } from "react-router-dom";
import {
  ORDERED_WORKFLOW_TABS,
  WORKFLOW_STATUS,
  WORKFLOW_TABS,
} from "@circle-react/components/SettingsApp/Workflows/constants";
import { serializeObjectToQueryParam } from "@circle-react/helpers/urlHelpers";
import { useRouterQueryParams } from "@circle-react/hooks/useRouterQueryParams";
import { useIsWorkflowExecutionEnabled } from "@circle-react-shared/Workflows/hooks/useIsWorkflowExecutionEnabled";
import { useTotalWorkflowsCount } from "../../hooks/useTotalWorkflowsCount";

const TAB_QUERY_PARAMS = {
  WORKFLOW_TYPE: "workflow_type",
  STATUS: "status",
};

export const useWorkflowTabs = () => {
  const isWorkflowExecutionEnabled = useIsWorkflowExecutionEnabled();
  const queryParams = useRouterQueryParams();
  const history = useHistory();
  const [hasArchivedWorkflows, setHasArchivedWorkflows] = useState();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const {
    data: {
      total_count: totalWorkflows,
      meta: {
        counts_by_workflow_type: {
          dynamic: totalDynamicWorkflows,
          bulk_action: totalBulkActionWorkflows,
          scheduled: totalScheduledWorkflows,
          archived: totalArchivedWorkflows,
        } = {},
        has_active_marketing_hub_workflows: hasActiveMarketingHubWorkflows,
      } = {},
    } = { meta: {} },
    isLoading: isLoadingTotalCount,
  } = useTotalWorkflowsCount();

  const tabsCountersMap = {
    [WORKFLOW_TABS.DYNAMIC]: totalDynamicWorkflows,
    [WORKFLOW_TABS.BULK_ACTION]: totalBulkActionWorkflows,
    [WORKFLOW_TABS.SCHEDULED]: totalScheduledWorkflows,
    [WORKFLOW_TABS.ARCHIVED]: totalArchivedWorkflows,
  };

  const tabLabels = useMemo(
    () => ({
      [WORKFLOW_TABS.DYNAMIC]: t("settings.workflows.table.type.automations"),
      [WORKFLOW_TABS.BULK_ACTION]: t(
        "settings.workflows.table.type.bulk_actions",
      ),
      [WORKFLOW_TABS.SCHEDULED]: t("settings.workflows.table.type.scheduled"),
      [WORKFLOW_TABS.ARCHIVED]: t("settings.workflows.table.type.archived"),
    }),
    [],
  );

  const { status, workflow_type: workflowType } = queryParams;

  const handleTabChange = index => {
    setSelectedTabIndex(index);
    const isArchivedTab = index === ORDERED_WORKFLOW_TABS.length - 1;
    const queryParams = {
      ...(isArchivedTab && {
        [TAB_QUERY_PARAMS.STATUS]: WORKFLOW_STATUS.ARCHIVED,
      }),
      ...(!isArchivedTab && {
        [TAB_QUERY_PARAMS.WORKFLOW_TYPE]: ORDERED_WORKFLOW_TABS[index],
      }),
    };
    history.push({
      search: serializeObjectToQueryParam(queryParams),
    });
  };

  useEffect(() => {
    if (isLoadingTotalCount) {
      return;
    }
    const hasArchived = totalArchivedWorkflows > 0;
    const defaultSelectedTab =
      status === WORKFLOW_STATUS.ARCHIVED && hasArchived
        ? WORKFLOW_TABS.ARCHIVED
        : workflowType || WORKFLOW_TABS.DYNAMIC;

    const defaultSelectedIndex = ORDERED_WORKFLOW_TABS.findIndex(
      tab => tab === defaultSelectedTab,
    );

    setHasArchivedWorkflows(hasArchived);
    setSelectedTabIndex(defaultSelectedIndex);
  }, [
    isLoadingTotalCount,
    hasArchivedWorkflows,
    status,
    workflowType,
    totalArchivedWorkflows,
    tabLabels,
  ]);

  return {
    isWorkflowExecutionEnabled,
    hasArchivedWorkflows,
    isLoading: isLoadingTotalCount,
    totalWorkflows,
    hasActiveMarketingHubWorkflows,
    tabsCountersMap,
    selectedTabIndex,
    handleTabChange,
    tabNames: ORDERED_WORKFLOW_TABS,
    tabLabels,
  };
};
