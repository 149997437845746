import { t } from "i18n-js";
import { PlanDetails } from "./PlanDetails";
import { PlanDetailsContainer } from "./PlanDetailsContainer";
import { useCurrentAndSelectedPlans } from "./hooks/useCurrentAndSelectedPlans";

export const CurrentPlanDetails = () => {
  const { currentPaywall, currentPaywallPrice } = useCurrentAndSelectedPlans();

  if (!currentPaywall || !currentPaywallPrice) {
    return null;
  }

  const planDetailsLabel = t(
    "member_profile_modal.billing.subscriptions.subscription_summary.current_plan",
  );
  const planName = currentPaywall.display_name;
  const planPricing = currentPaywallPrice.short_description;

  return (
    <PlanDetailsContainer label={planDetailsLabel}>
      <PlanDetails planName={planName} planPricing={planPricing} />
    </PlanDetailsContainer>
  );
};
