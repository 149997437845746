import type { ComponentPropsWithoutRef } from "react";
import classNames from "classnames";
import type { Space } from "@circle-react/types/Space";

export interface PostListWrapperProps extends ComponentPropsWithoutRef<"div"> {
  displayView?: Space["display_view"];
}

/**
 * @deprecated
 * Please use the new PostListWrapper component from app/javascript/react/components/PostsV3 or duplicate the changes to PostsV3 as well.
 * This component will be removed in the future once posts and comments are migrated to use tailwindCSS.
 */
export const PostListWrapper = ({
  children,
  className,
  displayView,
  ...rest
}: PostListWrapperProps) => (
  <div
    className={classNames(
      "space__posts !pb-16 lg:!pb-6",
      {
        [`space__posts--${displayView}`]: displayView,
      },
      className,
    )}
    data-testid="post_section"
    {...rest}
  >
    {children}
  </div>
);
