import PropTypes from "prop-types";
import { formattedDateTime } from "@circle-react/helpers/dateTimeHelpers/formattedDateTime";

export const DateTime = ({ dateTime }) => {
  if (!dateTime) {
    return null;
  }

  const date = formattedDateTime({
    dateTime: new Date(dateTime),
    format: "short_date_at_short_time",
  });

  return (
    <time dateTime={dateTime} className="break-normal">
      {date}
    </time>
  );
};

DateTime.propTypes = {
  dateTime: PropTypes.string,
};
