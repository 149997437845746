import { t } from "i18n-js";
import { Avatar } from "@circle-react/components/SettingsApp/Workflows/components/Avatar";
import { Typography } from "@circle-react-uikit/Typography";
import { EventFromId } from "./EventFromId";

export interface HistoryDetailViewEventProps {
  body: {
    event_id: number;
    community_member_id: number;
  };
}

export const HistoryDetailViewEvent = ({
  body,
}: HistoryDetailViewEventProps) => {
  const { event_id: eventId, community_member_id: communityMemberId } = body;

  return (
    <div className="flex flex-col gap-2">
      <div className="md:flex">
        <div className="w-1/4">
          <Typography.LabelSm weight="semibold">
            {t("settings.workflows.action_messages.event")}
          </Typography.LabelSm>
        </div>
        <EventFromId id={eventId} />
      </div>
      <div className="md:flex">
        <div className="w-1/4">
          <Typography.LabelSm weight="semibold">
            {t("settings.workflows.action_messages.member")}
          </Typography.LabelSm>
        </div>
        <div className="w-3/4">
          <Avatar communityMemberId={communityMemberId} />
        </div>
      </div>
    </div>
  );
};
