import classNames from "classnames";
import { Typography } from "@circle-react-shared/uikit/Typography";

interface SettingsSectionProps {
  children: React.ReactNode;
  title: string;
  description: React.ReactNode;
  childrenClassName?: string;
  shouldHideMarginBottom?: boolean;
}

export const SettingsSection = ({
  children,
  title,
  description,
  childrenClassName,
  shouldHideMarginBottom = false,
}: SettingsSectionProps) => (
  <div className={classNames({ "mb-12": !shouldHideMarginBottom })}>
    <Typography.TitleSm weight="bold">{title}</Typography.TitleSm>
    <div className="mt-2">
      <Typography.BodyMd color="text-default">{description}</Typography.BodyMd>
    </div>
    <div className={classNames("mt-2", childrenClassName)}>{children}</div>
  </div>
);
