import { useQuery } from "react-query";
import { workflowsApi } from "@circle-react/api";
import {
  completedCourse,
  completedLesson,
  completedSection,
} from "../components/Events/Course";
import {
  eventEndedForMember,
  eventStartsInAnHour,
  memberAttendedLiveEvent,
  rsvpToEvent,
  rsvpToRecurringEvent,
} from "../components/Events/Event";
import { memberTagged, memberUntagged } from "../components/Events/MemberTag";
import { endedTrialWithoutSubscription } from "../components/Events/Paywall/endedTrialWithoutSubscription";
import { memberPurchased } from "../components/Events/Paywall/memberPurchased";
import { memberSubscribed } from "../components/Events/Paywall/memberSubscribed";
import { memberUnsubscribed } from "../components/Events/Paywall/memberUnsubscribed";
import { startedTrial } from "../components/Events/Paywall/startedTrial";
import {
  commentCreatedOnPost,
  postLiked,
  postPublished,
} from "../components/Events/PostBasic";
import { memberJoinedSpace, memberLeftSpace } from "../components/Events/Space";
import { eventPublished } from "../components/Events/Space/eventPublished";
import {
  memberJoinedSpaceGroup,
  memberLeftSpaceGroup,
} from "../components/Events/SpaceGroup";

interface FetchPreviewAttributesOptions {
  triggerType: string;
}

interface FetchPreviewAttributesResponse {
  [key: string]: object;
}

const EVENT_RESOURCE = "event";
const SPACE_RESOURCE = "space";
const SPACE_GROUP_RESOURCE = "space_group";
const MEMBER_TAG_RESOURCE = "member_tag";
const POST_RESOURCE = "post";
const COMMENT_RESOURCE = "comment";
const PAYWALL_RESOURCES = ["paywall", "paywall_price", "currency"];

const TRIGGER_TYPE_RESOURCE_MAP = {
  // Event based dynamic vars
  [rsvpToEvent.value]: [EVENT_RESOURCE],
  [rsvpToRecurringEvent.value]: [EVENT_RESOURCE],
  [eventStartsInAnHour.value]: [EVENT_RESOURCE],
  [eventPublished.value]: [EVENT_RESOURCE],
  [eventEndedForMember.value]: [EVENT_RESOURCE],
  [memberAttendedLiveEvent.value]: [EVENT_RESOURCE],

  // Space based dynamic vars
  [memberJoinedSpace.value]: [SPACE_RESOURCE],
  [memberLeftSpace.value]: [SPACE_RESOURCE],
  [completedCourse.value]: [SPACE_RESOURCE],
  [completedLesson.value]: [SPACE_RESOURCE],
  [completedSection.value]: [SPACE_RESOURCE],

  // Space Group based dynamic vars
  [memberJoinedSpaceGroup.value]: [SPACE_GROUP_RESOURCE],
  [memberLeftSpaceGroup.value]: [SPACE_GROUP_RESOURCE],

  // Member based dynamic vars
  [memberTagged.value]: [MEMBER_TAG_RESOURCE],
  [memberUntagged.value]: [MEMBER_TAG_RESOURCE],

  // Post based dynamic vars
  [postPublished.value]: [POST_RESOURCE],
  [postLiked.value]: [POST_RESOURCE],

  // Comment based dynamic vars
  [commentCreatedOnPost.value]: [COMMENT_RESOURCE],

  // Paywall based dynamic vars
  [memberPurchased.value]: PAYWALL_RESOURCES,
  [memberSubscribed.value]: PAYWALL_RESOURCES,
  [memberUnsubscribed.value]: PAYWALL_RESOURCES,
  [startedTrial.value]: PAYWALL_RESOURCES,
  [endedTrialWithoutSubscription.value]: PAYWALL_RESOURCES,
};

export const useFetchPreviewAttributes = ({
  triggerType,
}: FetchPreviewAttributesOptions) => {
  const defaultResourceNames = ["community_member", "community"];
  const triggerResourceName = TRIGGER_TYPE_RESOURCE_MAP[triggerType] || "";
  const resourceNames = [
    ...defaultResourceNames,
    ...triggerResourceName,
  ].filter(Boolean);

  return useQuery<FetchPreviewAttributesResponse>(
    ["workflows-fetch-preview-attributes", triggerType],
    () =>
      workflowsApi.fetchPreviewAttributes({
        params: { resource_names: resourceNames },
      }),
  );
};
