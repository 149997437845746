import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useThemeContext } from "@circle-react/providers";

const highlightAll = async () => {
  try {
    const { default: hljs } = await import(
      /* webpackChunkName: "HighlightJs_Core" */ "highlight.js/lib/core"
    );
    const { default: json } = await import(
      /* webpackChunkName: "HighlightJs_Languages_Json" */ "highlight.js/lib/languages/json"
    );
    hljs.registerLanguage("json", json);
    hljs.configure({ languages: ["json"] });
    hljs.highlightAll();
  } catch (error) {
    console.error(error);
  }
};

export const ThemeStyles = () => {
  const { isDarkAppearance } = useThemeContext();

  useEffect(() => {
    void highlightAll();
  }, [isDarkAppearance]);

  if (isDarkAppearance) {
    return (
      <Helmet>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/highlight.js/11.9.0/styles/github-dark.min.css"
          integrity="sha512-rO+olRTkcf304DQBxSWxln8JXCzTHlKnIdnMUwYvQa9/Jd4cQaNkItIUj6Z4nvW1dqK0SKXLbn9h4KwZTNtAyw=="
          crossOrigin="anonymous"
          referrerPolicy="no-referrer"
        />
      </Helmet>
    );
  }

  return (
    <Helmet>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/highlight.js/11.9.0/styles/github.min.css"
        integrity="sha512-0aPQyyeZrWj9sCA46UlmWgKOP0mUipLQ6OZXu8l4IcAmD2u31EPEy9VcIMvl7SoAaKe8bLXZhYoMaE/in+gcgA=="
        crossOrigin="anonymous"
        referrerPolicy="no-referrer"
      />
    </Helmet>
  );
};
