import PropTypes from "prop-types";
import { useFetchMemberTag } from "@circle-workflows/hooks/useFetchMemberTag";
import { t } from "i18n-js";
import isEmpty from "lodash/isEmpty";
import { Badge } from "@circle-react/components/SettingsApp/Workflows/components/Badge";
import { Typography } from "@circle-react-uikit/Typography";

export const MemberTagFromId = ({ memberTagId }) => {
  const { data: memberTag, isLoading } = useFetchMemberTag({ id: memberTagId });

  if (isLoading) {
    return <Badge isLoading />;
  }

  if (isEmpty(memberTag)) {
    return (
      <Typography.LabelSm>
        {t("settings.workflows.view.deleted_member_tag")}
      </Typography.LabelSm>
    );
  }

  return <Typography.LabelSm>{memberTag.name}</Typography.LabelSm>;
};

MemberTagFromId.propTypes = {
  memberTagId: PropTypes.number.isRequired,
};
