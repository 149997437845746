import { useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { usePricingTabContext } from "./usePricingTabContext";

export const useFreeTrial = () => {
  const { hasPricesEligibleForTrial } = usePricingTabContext();

  const { setValue, setFocus, watch } = useFormContext();
  const hasTrial = Boolean(watch("enable_trial"));
  const hasTrialToggleBeenRendered = useRef(false);

  useEffect(() => {
    if (!hasPricesEligibleForTrial()) {
      setValue("enable_trial", false);
    }
  }, [hasPricesEligibleForTrial(), setValue]);

  useEffect(() => {
    if (!hasTrial) {
      setValue("trial_days", 0);
      setValue("trial_requires_card", false);
      setValue("self_trial_cancelable", false);
    } else {
      if (hasTrialToggleBeenRendered.current)
        setValue("trial_requires_card", true);
      setFocus("trial_days");
    }
    hasTrialToggleBeenRendered.current = true;
  }, [hasTrial, setFocus, setValue]);

  return { hasPricesEligibleForTrial, hasTrial };
};
