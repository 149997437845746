import { createContext, useContext } from "react";
import { noop } from "lodash";
import type {
  CommunityMember,
  CommunityMemberBillingInfo,
} from "@circle-react/types";

export interface BillingInfoContextState {
  communityMember: CommunityMember;
  billingInfo: CommunityMemberBillingInfo;
  onSuccess: typeof noop;
  asPrerequisite?: boolean;
}

export const initialBillingInfo = {
  address_line1: "",
  address_line2: "",
  address_city: "",
  address_state: "",
  address_postal_code: "",
  address_country: "",
};

export const BillingInfoContext = createContext<BillingInfoContextState>({
  communityMember: { id: 0, name: "", email: "" },
  billingInfo: initialBillingInfo,
  onSuccess: noop,
  asPrerequisite: false,
});

BillingInfoContext.displayName = "BillingInfoContext";

export const useBillingInfoContext = () => useContext(BillingInfoContext);
