import { CookiesDisabled } from "./CookiesDisabled";

export const CookieStatus = () => {
  if (document.cookie) {
    return null;
  }
  if (window.location !== window.parent.location) {
    return <CookiesDisabled isThirdParty />;
  }
  return <CookiesDisabled />;
};
