import {
  HMSNotificationTypes,
  useHMSNotifications,
} from "@100mslive/react-sdk";

export const HMS_ERRORS = Object.freeze({
  WEBSOCKET_DISCONNECTED: 1003,
  ICE_CONNECTION_FAILED: 4005,
  AUDIO_BLOCKED: 3008,
});

export const useHMSErrorCode = () => {
  const hmsNotification = useHMSNotifications();
  return hmsNotification?.type === HMSNotificationTypes.ERROR
    ? hmsNotification.data?.code
    : null;
};
