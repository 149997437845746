import { t } from "i18n-js";
import { Icon } from "@circle-react-shared/Icon";
import type { ActionOption } from "../types";
import { FormSelectSpace } from "./shared/FormSelectSpace";
import { HistoryDetailViewSpace } from "./shared/HistoryDetailViewSpace";
import type { HistoryDetailViewSpaceProps } from "./shared/HistoryDetailViewSpace/HistoryDetailViewSpace";
import { ViewSpace } from "./shared/ViewSpace";

export const removeMember: Readonly<ActionOption<HistoryDetailViewSpaceProps>> =
  {
    label: t(
      "settings.workflows.form.actions.remove_community_member_from_space",
    ),
    value: "remove_community_member_from_space",
    group: t("settings.workflows.form.action_groups.space_and_space_group"),
    leftAddon: (
      <Icon type="16-space-directory-remove" size={16} className="!h-4 !w-4" />
    ),
    formComponent: FormSelectSpace,
    viewComponent: ViewSpace,
    historyDetailViewComponent: HistoryDetailViewSpace,
    icon: "user-remove",
    isApplicableToMembersOnly: true,
  } as const;
