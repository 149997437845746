import { useMutation, useQuery } from "react-query";
import { communityApi } from "@/react/api";

export const useAnalyticsInfo = () => {
  const { isLoading, data } = useQuery(
    "community-onboarding",
    communityApi.getOnboarding,
  );
  const { mutate: mutateCommunityOnboarding } = useMutation(() =>
    communityApi.completeOnboardingStep({
      onboarding: { ["paywall_analytics_info"]: true },
    }),
  );
  return {
    mutateCommunityOnboarding,
    onboardingData: data,
    isOnboardingDataLoading: isLoading,
  };
};
