import { Tab } from "@circle-react-uikit/TabV2";
import { useTabWithFilters } from "./hooks/useTabWithFilters";

export interface TabWithFiltersProps {
  setActiveFilterTab: (tab: string) => void;
  activeFilterTab: string;
  setSearchKey: (key: string) => void;
  resetAllSubscriptionToFirstPage: () => void;
}

export const TabWithFilters = ({
  setActiveFilterTab,
  activeFilterTab,
  setSearchKey,
  resetAllSubscriptionToFirstPage,
}: TabWithFiltersProps) => {
  const { tabOptions, changeTabAndResetSearchForm } = useTabWithFilters(
    setActiveFilterTab,
    setSearchKey,
    resetAllSubscriptionToFirstPage,
  );

  const selectedTabIndex = tabOptions.findIndex(
    tab => tab.value === activeFilterTab,
  );

  return (
    <Tab.Group selectedIndex={selectedTabIndex}>
      <Tab.ListWrapper>
        <Tab.List>
          {tabOptions.map(tab => (
            <Tab
              key={String(tab.value)}
              onClick={() => changeTabAndResetSearchForm(tab.value)}
            >
              {tab.label}
              <Tab.Counter>{tab.badge.value}</Tab.Counter>
            </Tab>
          ))}
        </Tab.List>
      </Tab.ListWrapper>
    </Tab.Group>
  );
};
