import { reactQueryPost } from "@/react/helpers/backendRequestHelpers";
import { internalApi } from "@/react/helpers/urlHelpers";

const previewPriceChange = (subscriptionId, data) =>
  reactQueryPost(
    internalApi.communityMemberSubscriptionPriceChanges.preview({
      subscriptionId,
    }),
    data,
  );

const createPriceChange = (subscriptionId, data) =>
  reactQueryPost(
    internalApi.communityMemberSubscriptionPriceChanges.create({
      subscriptionId,
    }),
    data,
  );

export const communityMemberSubscriptionPriceChangesApi = {
  previewPriceChange,
  createPriceChange,
};
