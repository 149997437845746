import { Icon } from "@circle-react-shared/Icon";

export const AddLink = ({ onClick }) => (
  <a
    href="#create-space"
    className="block-header__action action--add-space"
    data-testid="create-space"
    onClick={onClick}
  >
    <Icon type="circle-add" />
  </a>
);
