import { useQuery } from "react-query";
import { paywallApi } from "@circle-react/api/paywallApi";

export const paywallListQueryKey = "paywalls-list";
const paywallStatusDefault = [];

export const usePaywallList = ({
  communityMemberId,
  status = paywallStatusDefault,
  queryParams = {},
  spaceIds = [],
}) => {
  const paywallsListQuery = useQuery(
    [paywallListQueryKey, communityMemberId, { status, spaceIds }],
    paywallApi.index({
      community_member_id: communityMemberId,
      status,
      space_ids: spaceIds || [],
    }),
    queryParams,
  );

  return {
    paywallsListQuery,
  };
};
