import { t } from "i18n-js";
import {
  EMAIL_REGEX,
  PASSWORD_REGEX,
} from "@circle-react/components/OnboardingPage/helpers/constants";
import { usePaywallCheckoutContext } from "@circle-react/contexts/Paywalls/paywallCheckoutContext";
import { Typography } from "@circle-react-uikit/Typography";
import { CheckoutFormField } from "../CheckoutFormField";
import { CheckoutFormFieldWrapper } from "../CheckoutFormFieldWrapper";

export const CheckoutFormAccountFields = () => {
  const { currentUser, loginUrl } = usePaywallCheckoutContext();

  if (currentUser) {
    return null;
  }

  return (
    <>
      <Typography.BodyXs weight="regular">
        {t("paywall_checkout.have_an_account")}
        <a href={loginUrl} data-testid="checkout-form-sign-in-link">
          {t("paywall_checkout.form.buttons.sign_in")}
        </a>
      </Typography.BodyXs>
      <CheckoutFormFieldWrapper name="email">
        <CheckoutFormField
          type="text"
          name="email"
          options={{
            required: true,
            pattern: EMAIL_REGEX,
          }}
        />
      </CheckoutFormFieldWrapper>
      <CheckoutFormFieldWrapper name="password">
        <CheckoutFormField
          type="password"
          name="password"
          options={{
            required: true,
            pattern: PASSWORD_REGEX,
          }}
        />
      </CheckoutFormFieldWrapper>
    </>
  );
};
