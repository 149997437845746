import { t } from "i18n-js";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { ConfirmationModal } from "@circle-react-shared/ModalsV2/ConfirmationModal";
import { Typography } from "@circle-react-uikit/Typography";
import type { SpaceGroupProperties } from "./hooks/usePaywallSpaceAccessManagement";

const i18nRoot = "settings.paywalls.modal_add_space_group_confirmation";

interface AddSpaceGroupConfirmationModalProp {
  action: () => void;
  spaceGroups: SpaceGroupProperties[];
}

const AddSpaceGroupConfirmationModalComponent = ModalManager.create(
  ({ action, spaceGroups }: AddSpaceGroupConfirmationModalProp) => {
    const modal = useModal();
    const onClose = () => {
      void modal.hide();
      modal.remove();
    };

    const onConfirm = () => {
      action();
      onClose();
    };

    return (
      <ConfirmationModal
        isOpen={modal.visible}
        title={t([i18nRoot, "title"])}
        onClose={onClose}
        cancelText={t([i18nRoot, "cancel"])}
        onConfirm={onConfirm}
        confirmVariant="primary"
        confirmText={t([i18nRoot, "confirm"])}
      >
        <Typography.BodyMd>
          {t([i18nRoot, "description"], {
            count: spaceGroups.length,
            space_groups: spaceGroups
              .map(spaceGroup => spaceGroup.name)
              .join(", "),
          })}
        </Typography.BodyMd>
      </ConfirmationModal>
    );
  },
);

export const useAddSpaceGroupConfirmationModal = () =>
  useModal(AddSpaceGroupConfirmationModalComponent);
