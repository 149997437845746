import { Suspense } from "react";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { PageLoader } from "@circle-react/pages/PageLoader";

const EmbedSettingsComponent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "EmbedSettingsPage" */ "@circle-react/components/SettingsApp/Embed"
    ),
);

export const EmbedSettingsPage = () => (
  <Suspense fallback={<PageLoader />}>
    <EmbedSettingsComponent />
  </Suspense>
);
