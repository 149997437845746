import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { EditSnippetModalComponent } from "./EditSnippetModalComponent";

export interface Snippet {
  title: string;
  body: string;
}

const EditSnippetModal = ModalManager.create(
  ({ snippetId }: { snippetId: number }) => (
    <EditSnippetModalComponent id={snippetId} />
  ),
);

export const useEditSnippetModal = () => useModal(EditSnippetModal);
