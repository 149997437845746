import { Suspense } from "react";
import { StandardLayout } from "@circle-react/components/Layout/StandardLayout";
import { VersionedContent } from "@circle-react/components/Layout/VersionedContent";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { PageLoader } from "@circle-react/pages/PageLoader";

const SpaceGroupComponent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "SpaceGroupPage" */ "@circle-react/components/SpaceGroups"
    ),
);

export const SpaceGroupPage = () => (
  <VersionedContent fullWidth>
    <StandardLayout.Body hasPaddingTopMobile={false}>
      <Suspense fallback={<PageLoader />}>
        <SpaceGroupComponent />
      </Suspense>
    </StandardLayout.Body>
  </VersionedContent>
);
