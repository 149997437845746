import PropTypes from "prop-types";
import { t } from "i18n-js";
import { isFunction } from "lodash";
import { Filter, InputFilter, useFilterUrl } from "@circle-react-shared/Filter";

const localeNamespace = "settings.workflows.history.filters";

export const NameFilter = ({
  isInitialOpen,
  onApply,
  changeUrl = true,
  value,
}) => {
  const { queryParams, updateUrl } = useFilterUrl();

  return (
    <Filter
      chip={t([localeNamespace, "name"])}
      title={t([localeNamespace, "name_title"])}
      selectedText={value || queryParams.workflow_name}
      isInitialOpen={isInitialOpen}
      onApply={value => {
        changeUrl && updateUrl("workflow_name", value);
        if (isFunction(onApply)) {
          onApply(value);
        }
      }}
      shouldUsePortal
    >
      <InputFilter
        name="name-filter"
        placeholder={t([localeNamespace, "name_placeholder"])}
      />
    </Filter>
  );
};

NameFilter.propTypes = {
  isInitialOpen: PropTypes.bool,
  onApply: PropTypes.func,
  changeUrl: PropTypes.bool,
  value: PropTypes.string,
};
