import { paywallPriceTypes } from "@circle-react/types";
import type {
  PricingTabPriceListCell,
  PricingTabPriceListCellProps,
} from "../types";
import { PricingTabPriceListCellPricingInstallments } from "./Installments";
import { PricingTabPriceListCellPricingOneTime } from "./OneTime";
import { PricingTabPriceListCellPricingSubscription } from "./Subscription";

const componentsByType = {
  [paywallPriceTypes.onetime]: PricingTabPriceListCellPricingOneTime,
  [paywallPriceTypes.subscription]: PricingTabPriceListCellPricingSubscription,
  [paywallPriceTypes.installments]: PricingTabPriceListCellPricingInstallments,
} as const;

export const PricingTabPriceListCellPricing = (
  props: PricingTabPriceListCellProps,
) => {
  const {
    row: { original: price },
  } = props;

  const { price_type } = price;
  const Component: PricingTabPriceListCell = componentsByType[price_type];
  if (!Component) {
    return null;
  }
  return <Component {...props} />;
};
