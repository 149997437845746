import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { JoinSpacesButtonView } from "./JoinSpacesButtonView";

export const JoinSpacesButton = ({ spaceGroupSlug }) => {
  const history = useHistory();

  const goToSpaceGroup = () => {
    history.push(`/s/${spaceGroupSlug}`);
  };

  return <JoinSpacesButtonView onClick={goToSpaceGroup} />;
};

JoinSpacesButton.propTypes = {
  spaceGroupSlug: PropTypes.string,
};
