import PropTypes from "prop-types";
import { StandardLayout } from "@circle-react/components/Layout/StandardLayout";
import { LockedSpaceDefault } from "@circle-react/components/Spaces/Locked/LockedSpaceDefault";
import { BlockSelector } from "../Blocks";

export const BlocksPreviewViewer = ({ blocks = [] }) => {
  const hasNonHiddenBlocks =
    Array.isArray(blocks) && blocks.some(block => !block?.hidden);

  if (hasNonHiddenBlocks) {
    return (
      <div className="bg-primary text-dark flex h-full flex-col gap-y-10">
        {blocks
          .filter(block => !block.hidden)
          .map(block => (
            <div key={block.id} className="pointer-events-none select-none">
              <BlockSelector block={block} />
            </div>
          ))}
      </div>
    );
  }

  return (
    <StandardLayout.Body hasPaddingTopMobile={false}>
      <LockedSpaceDefault />
    </StandardLayout.Body>
  );
};

BlocksPreviewViewer.propTypes = {
  blocks: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      hidden: PropTypes.bool,
      id: PropTypes.string,
      data: PropTypes.object,
    }),
  ),
};
