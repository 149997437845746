import classNames from "classnames";
import { useProfileDrawer } from "@circle-react/components/Drawers/ProfileDrawer";
import { Rank } from "@circle-react/components/Leaderboard/Dashboard/Table/Rank";
import { ProfileHoverCard } from "@circle-react/components/Profile/HoverCard";
import { t } from "@circle-react/custom_i18n";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { UserImage } from "@circle-react-shared/uikit/UserImage";

export const DataTable = ({
  data,
  shouldShowPlusIcon = false,
}: {
  shouldShowPlusIcon?: boolean;
  data: {
    community_member_id: number;
    name: string;
    headline?: string;
    avatar_url: string;
    messaging_enabled: boolean;
    total_points: number;
    public_uid: string;
  }[];
}) => {
  const memberProfileDrawer = useProfileDrawer();
  const openProfileDrawer = (memberPublicId: string) =>
    memberProfileDrawer.show({
      memberPublicId,
    });
  if (data.length === 0) {
    return (
      <div className="mt-32 flex flex-col items-center justify-center">
        <Typography.TitleLg color="text-dark" weight="font-bold">
          {t("leaderboard.not_enough_activity.title")}
        </Typography.TitleLg>
      </div>
    );
  }
  return (
    <div className="border-primary bg-primary mt-4 flex flex-col rounded-2xl border">
      {data.map((gamificationMember, index) => (
        <div
          className={classNames("flex items-center p-4 md:px-6", {
            "border-primary border-t": index !== 0,
          })}
          key={gamificationMember.community_member_id}
        >
          <div className="mr-4 w-8 shrink-0">
            <Rank rank={index + 1} />
          </div>
          <button
            type="button"
            onClick={() => openProfileDrawer(gamificationMember.public_uid)}
            className="flex min-w-0 flex-1 items-center"
          >
            <ProfileHoverCard
              publicUid={gamificationMember.public_uid}
              communityMemberId={gamificationMember.community_member_id}
              avatarURL={gamificationMember.avatar_url}
              name={gamificationMember.name}
              headline={gamificationMember.headline}
              messagingEnabled={gamificationMember.messaging_enabled}
            >
              <div className="mr-3 shrink-0">
                <UserImage
                  src={gamificationMember.avatar_url}
                  name={gamificationMember.name ?? ""}
                  size="10"
                  isFullRounded
                />
              </div>
            </ProfileHoverCard>
            <div className="flex min-w-0 flex-1 flex-col items-start">
              <div className="inline-block w-full truncate text-left leading-4">
                <Typography.LabelMd weight="semibold" truncate>
                  {gamificationMember.name}
                </Typography.LabelMd>
              </div>
              {gamificationMember.headline && (
                <div className="text-light inline-block w-full truncate text-left leading-4">
                  <Typography.LabelSm
                    color="text-light"
                    weight="regular"
                    truncate
                  >
                    {gamificationMember.headline}
                  </Typography.LabelSm>
                </div>
              )}
            </div>
          </button>
          <div className="ml-4 mr-1 shrink-0">
            <Typography.LabelSm weight="semibold">
              <span className="text-circle whitespace-nowrap">
                {shouldShowPlusIcon && "+"}
                {gamificationMember.total_points}
              </span>
            </Typography.LabelSm>
          </div>
        </div>
      ))}
    </div>
  );
};
