import { Suspense } from "react";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { PageLoader } from "@circle-react/pages/PageLoader";

const GamificationSettingsComponent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "GamificationSettingsPage" */ "@circle-react/components/SettingsApp/Gamification"
    ),
);

export const GamificationSettingsPage = () => (
  <Suspense fallback={<PageLoader />}>
    <GamificationSettingsComponent />
  </Suspense>
);
