import { t } from "i18n-js";
import avatars from "@circle-assets/images/inbox-avatars.png";
import { Typography } from "@circle-react-uikit/Typography";

export const EmptyState = () => (
  <div className="bg-secondary flex h-full w-full items-center justify-center text-center">
    <div className="flex w-[30.375rem] flex-col items-center space-y-2">
      <img alt="" loading="lazy" src={avatars} className="mb-2 object-cover" />
      <Typography.LabelLg weight="semibold">
        {t("community_inbox.title")}
      </Typography.LabelLg>
      <Typography.BodyMd color="text-default">
        {t("community_inbox.description")}
      </Typography.BodyMd>
    </div>
  </div>
);
