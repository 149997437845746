/* eslint-disable no-undef
 -- Disabled to set CI to fail on this issue on new files, PR #5217 */
import { useEffect } from "react";
import { useNotificationWebSocket } from "../../hooks/notifications/useNotificationWebSocket";
import { useNotificationsApi } from "../../hooks/notifications/useNotificationsApi";

export const NotificationCount = ({ communityId, communityMemberId }) => {
  const { newNotificationCount, fetchNewNotificationsCount } =
    useNotificationsApi();

  useEffect(() => {
    fetchNewNotificationsCount(communityId);
  }, []);

  useNotificationWebSocket(communityMemberId, onNotificationEventReceive);

  if (newNotificationCount) {
    return <span className="notifications__count">{newNotificationCount}</span>;
  }
  return null;
};
