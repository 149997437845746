import type { PropsWithChildren } from "react";
import classnames from "classnames";
import { SpaceAccessManagementContextProvider } from "../SpaceAccessManagementContext";
import type { SpaceAccessManagementContextProviderProp } from "../SpaceAccessManagementContext";
import { Container } from "./Container";

interface RootProp
  extends PropsWithChildren<SpaceAccessManagementContextProviderProp> {
  className?: string;
}

export const Root = ({ children, className, ...rest }: RootProp) => (
  <SpaceAccessManagementContextProvider {...rest}>
    <Container
      className={classnames(
        "border-primary flex flex-col overflow-hidden md:rounded-lg md:border",
        className,
      )}
    >
      {children}
    </Container>
  </SpaceAccessManagementContextProvider>
);
