import { useFormContext } from "react-hook-form";
import { useMutation } from "react-query";
import { workflowsApi } from "@/react/api";
import { useToast } from "@circle-react-uikit/ToastV2";

export const useUpdateWorkflow = (options = {}) => {
  const { watch } = useFormContext();
  const toast = useToast();
  const id = watch("id");

  return useMutation(body => workflowsApi.update({ id, body }), {
    onError: error => {
      console.error(error);
      toast.error(error.message);
    },
    ...options,
  });
};
