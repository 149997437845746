import PropTypes from "prop-types";
import { ParticipantAvatar } from "@circle-react/components/LiveStreams/Room/ParticipantAvatar";
import { Typography } from "@circle-react-uikit/Typography";

export const Participant = ({ record }) => (
  <div className="flex items-center">
    <div className="flex w-1/2 items-center py-2">
      <div className="relative mr-3 h-10 w-10">
        <ParticipantAvatar name={record.name} src={record.avatar_url} />
      </div>
      <Typography.LabelSm weight="semibold">{record.name}</Typography.LabelSm>
    </div>
    <div className="w-1/2">{record.email}</div>
  </div>
);

Participant.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
    avatar_url: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
};
