import { forwardRef } from "react";
import type { ComponentPropsWithRef } from "react";
import classNames from "classnames";

export interface CheckoutFormInputProps extends ComponentPropsWithRef<"input"> {
  error?: boolean;
}

export const CheckoutFormInput = forwardRef<any, CheckoutFormInputProps>(
  ({ className, error = false, ...rest }, ref) => (
    <input
      className={classNames(
        className,
        { "border-red-600 focus:border-red-600": error },
        "placeholder:text-light h-11 grow rounded-lg border border-solid border-gray-200 bg-white px-4 py-3 shadow-none outline-0 ring-0 placeholder:opacity-100 focus:border focus:border-gray-500 focus:shadow-none focus:outline-0 focus:ring-0",
      )}
      {...rest}
      ref={ref}
    />
  ),
);
CheckoutFormInput.displayName = "CheckoutFormInput";
