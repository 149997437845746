import { t } from "i18n-js";
import { ConfirmationModal } from "@circle-react-shared/ModalsV2/ConfirmationModal";

export interface ConfirmRemoveMemberProps {
  isOpen?: boolean;
  onClose: () => void;
  onConfirm: () => void;
  confirmText: string;
  disabled?: boolean;
}

export const ConfirmRemoveMember = ({
  isOpen = false,
  onClose,
  onConfirm,
  confirmText,
  disabled = false,
}: ConfirmRemoveMemberProps) => (
  <ConfirmationModal
    isOpen={isOpen}
    onClose={onClose}
    onConfirm={onConfirm}
    confirmText={confirmText}
    confirmVariant="danger"
    title={t("remove")}
    disabled={disabled}
    cancelText={t("cancel")}
  >
    <p>{t("remove_member_confirm")}</p>
  </ConfirmationModal>
);
