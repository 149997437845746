import { SpaceAccessManagement as Panel } from "@circle-react/components/Spaces/SpaceAccessManagement";
import { usePaywallSpaceAccessManagement } from "./hooks/usePaywallSpaceAccessManagement";

export const PaywallSpaceAccessManagement = () => {
  const {
    options,
    addSpaceGroupCallback,
    addAllSpaceGroupCallback,
    removeSpaceCallback,
  } = usePaywallSpaceAccessManagement();

  return (
    <Panel options={options} className="flex-auto">
      <Panel.Header>
        <Panel.SearchBar />
      </Panel.Header>
      <Panel.Body className="overflow-y-auto">
        <Panel.ListHeader className="min-h-[3rem]">
          <Panel.ListHeaderColumnNoAccess onAddAll={addAllSpaceGroupCallback} />
          <Panel.ListHeaderColumnAccessGranted />
        </Panel.ListHeader>
        <Panel.ListContainer>
          <Panel.ListMobile onAddParent={addSpaceGroupCallback} />
          <Panel.ListNoAccess onAddParent={addSpaceGroupCallback} />
          <Panel.ListAccessGranted onRemoveChild={removeSpaceCallback} />
        </Panel.ListContainer>
      </Panel.Body>
    </Panel>
  );
};
