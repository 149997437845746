import type { ReactNode } from "react";
import { Icon } from "@circle-react/components/shared/Icon";
import { InfoBox } from "@circle-react/components/shared/uikit/InfoBox";
import { Typography } from "@circle-react/components/shared/uikit/Typography";

export interface PricingTabAddPriceModalNoticeBoxProps {
  children: ReactNode;
}

export const PricingTabAddPriceModalNoticeBox = ({
  children,
}: PricingTabAddPriceModalNoticeBoxProps) => (
  <InfoBox className="flex flex-row gap-3">
    <div>
      <Icon type="information-outline" size={24} />
    </div>
    <Typography.LabelSm weight="regular" as="div">
      {children}
    </Typography.LabelSm>
  </InfoBox>
);
