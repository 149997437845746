import { forwardRef, useState } from "react";
import type { ComponentPropsWithoutRef } from "react";
import classNames from "classnames";
import { t } from "i18n-js";
import { useHistory } from "react-router-dom";
import { isDrippedCourseSpace } from "@/react/helpers/courseHelpers";
import { coursePaths } from "@circle-react/helpers/urlHelpers";
import { useSmScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { Icon } from "@circle-react-shared/Icon";
import { Button } from "@circle-react-uikit/Button";
import { DROPDOWN_SIZE, Dropdown } from "@circle-react-uikit/Dropdown";
import { TableCell, TableRow } from "@circle-react-uikit/Table";
import { Typography } from "@circle-react-uikit/Typography";
import { ConfirmLessonDelete } from "./ConfirmLessonDelete";
import { LessonNameEditor } from "./LessonNameEditor";
import { useMobileCourseRenameModal } from "./MobileRenameModal";
import { StatusPicker } from "./StatusPicker";

export interface LessonItemProps {
  dragHandleProps?: any;
  isDragging?: boolean;
  isNameEditable?: boolean;
  isOverlay?: boolean;
  lesson: any;
  section?: any;
  setIsNameEditable?: (isNameEditable: boolean) => void;
  space?: any;
  tableRowProps?: ComponentPropsWithoutRef<"tr">;
}

export const LessonItem = forwardRef<HTMLTableRowElement, LessonItemProps>(
  (
    {
      dragHandleProps,
      isDragging,
      isNameEditable,
      isOverlay,
      lesson,
      section,
      setIsNameEditable,
      space,
      tableRowProps,
    },
    ref,
  ) => {
    const history = useHistory();
    const [hasDeleteConfirmation, setHasDeleteConfirmation] = useState(false);
    const isSmScreen = useSmScreenMediaQuery();
    const modal = useMobileCourseRenameModal();

    const handleViewLesson = () => {
      history.push(
        coursePaths.showLesson({
          spaceSlug: space.slug,
          sectionId: section.id,
          lessonId: lesson.id,
        }),
      );
    };

    const handleEditLesson = () => {
      history.push(
        coursePaths.editLesson({
          spaceSlug: space.slug,
          sectionId: section.id,
          lessonId: lesson.id,
        }),
      );
    };

    return (
      <TableRow
        id={`lesson-item-${lesson.id}`}
        ref={ref}
        {...(tableRowProps || {})}
        className={classNames("bg-primary group cursor-auto", {
          "opacity-50": isDragging,
          "overflow-hidden rounded-md shadow-lg": isOverlay,
        })}
        dataTestId="course-lesson-row"
      >
        <TableCell
          className="pl-3 pr-0 md:pl-10"
          {...(isDrippedCourseSpace(space) && {
            colSpan: 3,
          })}
        >
          <div className="flex items-center gap-x-1">
            <div
              className="hover:bg-secondary flex h-6 w-6 cursor-move items-center justify-center rounded"
              ref={dragHandleProps?.setActivatorNodeRef}
              {...(dragHandleProps?.listeners || {})}
            >
              <Icon type="16-reorder" size={16} />
            </div>
            {isNameEditable && !isOverlay ? (
              <LessonNameEditor
                lesson={lesson}
                section={section}
                onClose={() => setIsNameEditable?.(false)}
              />
            ) : (
              <button
                type="submit"
                className="hover:border-dark mr-4 cursor-pointer truncate border-b border-dashed border-transparent"
                title={lesson.name}
                onClick={() => setIsNameEditable?.(true)}
              >
                <Typography.LabelSm>{lesson.name}</Typography.LabelSm>
              </button>
            )}
          </div>
        </TableCell>
        <TableCell colSpan={1} className="!pr-6 text-right">
          <div className="flex items-center justify-end gap-x-4">
            {!isOverlay && (
              <Button
                className="!text-dark hover:bg-tertiary invisible rounded !px-2 !py-1 !font-normal sm:group-hover:visible"
                variant="plain"
                onClick={handleEditLesson}
              >
                {t("courses.edit.sections.edit_lesson")}
              </Button>
            )}
            <StatusPicker lesson={lesson} section={section} />
            {!isOverlay && (
              <>
                <Dropdown
                  size={DROPDOWN_SIZE.SMALL}
                  button={
                    <div className="hover:bg-tertiary cursor-pointer rounded p-1 group-hover:visible sm:invisible">
                      <Icon
                        type="menu-dots"
                        className="text-light"
                        useWithFillCurrentColor
                      />
                    </div>
                  }
                  dataTestId="lesson-dropdown-menu"
                >
                  <Dropdown.ItemWithLink onClick={handleViewLesson}>
                    {t("courses.edit.lesson.view_lesson")}
                  </Dropdown.ItemWithLink>
                  <Dropdown.ItemWithLink onClick={handleEditLesson}>
                    {t("courses.edit.sections.edit_lesson")}
                  </Dropdown.ItemWithLink>
                  <Dropdown.ItemWithLink
                    onClick={() =>
                      // Delay to avoid onBlur/onFocus events firing together
                      isSmScreen
                        ? modal.show({
                            courseId: space.id,
                            sectionId: section.id,
                            lessonId: lesson.id,
                            defaultName: lesson.name,
                          })
                        : setTimeout(() => setIsNameEditable?.(true), 100)
                    }
                  >
                    {t("courses.edit.sections.rename_lesson")}
                  </Dropdown.ItemWithLink>
                  <Dropdown.ItemWithLink
                    onClick={() => setHasDeleteConfirmation(true)}
                  >
                    {t("courses.edit.sections.delete_lesson")}
                  </Dropdown.ItemWithLink>
                </Dropdown>
                <ConfirmLessonDelete
                  show={hasDeleteConfirmation}
                  onClose={() => setHasDeleteConfirmation(false)}
                  courseId={space.id}
                  lessonId={lesson.id}
                  sectionId={section.id}
                  name={lesson.name}
                />
              </>
            )}
          </div>
        </TableCell>
      </TableRow>
    );
  },
);
LessonItem.displayName = "LessonItem";
