import { getQueryParam } from "@circle-react/helpers/urlHelpers";

export const useInitialPrice = ({ prices }: any) => {
  let price = prices[0];
  const preferredPrice = prices.find((price: any) => price.preferred);
  if (preferredPrice) {
    price = preferredPrice;
  }

  const queryPriceId = getQueryParam("price_id");
  if (queryPriceId) {
    const queryPrice = prices.find(
      (price: any) => price.id === parseInt(queryPriceId, 10),
    );
    if (queryPrice) {
      price = queryPrice;
    }
  }

  return price;
};
