import flatten from "lodash/flatten";
import { useInfiniteQuery } from "react-query";
import { workflowsApi } from "@/react/api";
import { useSupportUnreleasedWorkflowsFeaturesEnabled } from "@circle-react/components/shared/Workflows/hooks/useSupportUnreleasedWorkflowsFeaturesEnabled";

const PER_PAGE = 100;

export const useTemplates = category => {
  const supportUnreleasedWorkflowsFeaturesEnabled =
    useSupportUnreleasedWorkflowsFeaturesEnabled();

  const fetchPage = page => {
    const queryParams = { page, per_page: PER_PAGE, v2_2_enabled: true };
    if (category) {
      queryParams.category = category;
    }
    return workflowsApi.templates({ params: queryParams });
  };

  const { data, isLoading, fetchNextPage } = useInfiniteQuery(
    "workflow-templates-list",
    ({ pageParam = 1 }) => fetchPage(pageParam),
    {
      getNextPageParam: lastPage =>
        lastPage.has_next_page ? lastPage.page + 1 : undefined,
    },
  );

  const pages = data?.pages || [];
  const allTemplates = flatten(pages.map(page => page.records));
  const availableTemplates = supportUnreleasedWorkflowsFeaturesEnabled
    ? allTemplates
    : allTemplates.filter(template => template.released);

  return {
    templates: availableTemplates,
    isLoading,
    fetchNextPage,
  };
};
