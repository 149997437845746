import classNames from "classnames";
import { truncate } from "lodash";
import { Link } from "react-router-dom";
import { getRouteWithPrevLocation } from "@/react/helpers/urlHelpers";
import { useThemeContext } from "@/react/providers";
import { EmojiRenderer } from "@circle-react-shared/EmojiRenderer";
import { Typography } from "@circle-react-uikit/Typography";

interface Props {
  space: {
    slug: string;
    emoji: string;
    custom_emoji_url: string;
    custom_emoji_dark_url: string;
  };
  chatRoomName: string;
  parentMessage: {
    chat_room_uuid: string;
    embedded: boolean;
    id: number;
  };
  chatRoomUrl: string;
}

export const ChatRoomTitle = ({
  space,
  chatRoomName,
  parentMessage,
  chatRoomUrl,
}: Props) => {
  const { currentAppearance } = useThemeContext();

  const { id } = parentMessage;

  const {
    emoji,
    custom_emoji_url: customEmojiUrl,
    custom_emoji_dark_url: customEmojiDarkUrl,
  } = space || {};

  return (
    <Link
      to={getRouteWithPrevLocation({
        url: chatRoomUrl,
        hash: `message_${id}`,
        search: `message_id=${id}`,
      })}
    >
      <Typography.LabelSm
        as="div"
        color="text-selector-active"
        weight="semibold"
      >
        <div className="flex items-center">
          <EmojiRenderer
            className="!h-5 !w-auto"
            emoji={emoji}
            customEmojiUrl={customEmojiUrl}
            customEmojiDarkUrl={customEmojiDarkUrl}
            appearance={currentAppearance}
          />
          <span
            className={classNames(
              "hover:text-selector-active hover:underline",
              {
                "ml-1": emoji || customEmojiUrl,
              },
            )}
          >
            {truncate(chatRoomName, { length: 80 })}
          </span>
        </div>
      </Typography.LabelSm>
    </Link>
  );
};
