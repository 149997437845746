import { useEffect, useRef } from "react";
import { useParticipant } from "@livekit/react-core";
import { useLivekitContext } from "../../VideoProvider/LivekitProvider";

export const useLivekitScreenShareVideo = () => {
  const videoRef = useRef();
  const videoElement = videoRef.current;
  const { screenSharingParticipant: peer } = useLivekitContext();
  const { screenSharePublication } = useParticipant(peer);

  const track = screenSharePublication?.track;
  const hasVideo =
    screenSharePublication?.isSubscribed &&
    screenSharePublication?.track &&
    !screenSharePublication?.isMuted;

  useEffect(() => {
    if (videoElement && track) {
      track.attach(videoElement);
    }

    return () => {
      if (videoElement && track) {
        track.detach(videoElement);
      }
    };
  }, [videoElement, track]);

  return {
    peer,
    videoRef,
    hasVideo,
    shouldWarnInfinityMirror: false,
  };
};
