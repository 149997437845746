import { useState } from "react";
import { t } from "i18n-js";
import { useQueryClient } from "react-query";
import { useAddSpaceGroupMemberModal } from "@circle-react/components/Modals/AddSpaceGroupMember";
import { ConfirmRemoveMember } from "@circle-react/components/Modals/ConfirmRemoveMember";
import { MembersInfiniteTable } from "@circle-react/components/Spaces/EditV2/EditForm/Tabs/Members/MembersInfiniteTable.jsx";
import { useAdminAudienceEditModal } from "@circle-react/hooks/useAdminAudienceEditModal";
import {
  SORT_OPTIONS_MAP,
  useCommunityMembersInfiniteQuery,
} from "@circle-react/hooks/useCommunityMembersInfiniteQuery";
import { useRemoveMemberFromSpaceGroup } from "@circle-react/hooks/useRemoveMemberFromSpaceGroup";
import { useUpdateSpaceGroupMemberModerator } from "@circle-react/hooks/useUpdateSpaceGroupMemberModerator";
import type { SpaceGroup } from "@circle-react/types/SpaceGroup";
import { DebouncedSearchBar } from "@circle-react-shared/DebouncedSearchBar";
import { DownloadMembersCSVButton } from "@circle-react-shared/DownloadMembersCSVButton";
import { ROLES } from "@circle-react-shared/RoleSelect";
import { Loader } from "@circle-react-uikit/Loader";
import { useToast } from "@circle-react-uikit/ToastV2";

const getRole = (roles: any, spaceGroupId: number, moderatingIds: number[]) => {
  if (roles.admin) {
    return "Admin";
  }

  if (roles.moderator && moderatingIds.includes(spaceGroupId)) {
    return "Moderator";
  }

  return "Member";
};

interface SpaceGroupMembersProps {
  spaceGroup: SpaceGroup;
}

export const SpaceGroupMembers = ({ spaceGroup }: SpaceGroupMembersProps) => {
  const toastr = useToast();
  const queryClient = useQueryClient();
  const [isConfirmRemoveOpen, setIsConfirmRemoveOpen] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [query, setQuery] = useState("");
  const [removeId, setRemoveId] = useState<number | null>(null);
  const addSpaceGroupMemberModal = useAddSpaceGroupMemberModal();
  const showAdminMemberEditModal = useAdminAudienceEditModal();

  const {
    isLoading,
    hasNextPage,
    totalMembers,
    fetchNextPage,
    members,
    queryKey,
  } = useCommunityMembersInfiniteQuery({
    filters: { space_groups: spaceGroup.slug },
    sort: SORT_OPTIONS_MAP.ROLE,
    query,
  });

  const { mutate: removeMember } = useRemoveMemberFromSpaceGroup({
    onError: (error: any) => {
      toastr.error(error.message);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries(queryKey);
      toastr.success(t("space_groups.form.edit.members.members_removed"));
    },
    onSettled: () => {
      setIsConfirmRemoveOpen(false);
      setIsRemoving(false);
      setRemoveId(null);
    },
  });

  const { mutate: updateSpaceGroupModeratorStatus } =
    useUpdateSpaceGroupMemberModerator({
      onError: (error: any) => {
        toastr.error(error.message);
      },
      onSuccess: () => {
        void queryClient.invalidateQueries(queryKey);
        toastr.success(t("space_groups.form.edit.members.changed_role"));
      },
    });

  const handleAddMember = () => {
    void addSpaceGroupMemberModal.show({ spaceGroupId: spaceGroup.id });
  };

  const handleRemoveMember = (id: number) => {
    setRemoveId(id);
    setIsConfirmRemoveOpen(true);
  };

  const handleConfirmRemoveMember = () => {
    if (!removeId || !isConfirmRemoveOpen) return;
    setIsRemoving(true);
    removeMember({ spaceGroupId: spaceGroup.id, memberId: removeId });
  };

  const handleChangeMemberRole = (memberId: number, role: string) => {
    const isModerator = role === ROLES.MODERATOR;

    updateSpaceGroupModeratorStatus({
      spaceGroupId: spaceGroup.id,
      memberId,
      isModerator,
    });
  };

  const handleEditMember = (member: any) => {
    const { public_uid } = member;
    showAdminMemberEditModal({ publicId: public_uid });
  };

  const confirmText = isRemoving
    ? t("space_groups.form.edit.members.removing")
    : t("space_groups.form.edit.members.remove_member");

  if (isLoading) {
    return <Loader />;
  }

  const mappedMembers = members.map(
    ({
      id,
      avatar_url,
      name,
      email,
      roles,
      moderating_space_group_ids,
      public_uid,
    }) => ({
      id: id,
      avatarUrl: avatar_url,
      name: name,
      email: email,
      public_uid: public_uid,
      role: getRole(roles, spaceGroup.id, moderating_space_group_ids),
    }),
  );

  return (
    <>
      <div className="flex justify-between border-b border-gray-200 px-8 py-3 text-gray-600">
        <DebouncedSearchBar
          onSearch={setQuery}
          placeholder={t("space_groups.search_members")}
        />
        <DownloadMembersCSVButton spaceGroupId={spaceGroup.id} />
      </div>
      <div className="h-full overflow-auto px-4 py-2">
        <MembersInfiniteTable
          searchTerm={query}
          members={mappedMembers}
          dataLength={totalMembers}
          next={fetchNextPage}
          hasMore={hasNextPage}
          onEdit={handleEditMember}
          onRemove={handleRemoveMember}
          onAdd={handleAddMember}
          onRoleChange={handleChangeMemberRole}
          variant="space-group-members"
          scrollableTarget="scrollable-content"
        />
      </div>
      <ConfirmRemoveMember
        isOpen={isConfirmRemoveOpen}
        onClose={() => setIsConfirmRemoveOpen(false)}
        onConfirm={handleConfirmRemoveMember}
        disabled={isRemoving}
        confirmText={confirmText}
      />
    </>
  );
};
