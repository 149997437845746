import type { ReactNode } from "react";
import { createContext, useContext, useState } from "react";

export const sortTypeMap = {
  Latest: "latest",
  Oldest: "oldest",
  Popular: "popular",
  Likes: "likes",
  Alphabetical: "alphabetical",
  NewActivity: "new_activity",
  Relevance: "relevance",
} as const;

export type SortType = (typeof sortTypeMap)[keyof typeof sortTypeMap];

interface SortContextType {
  sort: Record<string, SortType | undefined>;
  setSort: (type: string, sort: SortType) => void;
}

const SortContext = createContext<SortContextType>({
  sort: {
    posts: sortTypeMap.Relevance,
    comments: sortTypeMap.Relevance,
    events: sortTypeMap.Relevance,
    lessons: sortTypeMap.Relevance,
    members: sortTypeMap.Relevance,
  },
  setSort: () => {},
});

SortContext.displayName = "SortContext";

export const SortContextProvider = ({ children }: { children: ReactNode }) => {
  const [sort, setSortState] = useState<SortContextType["sort"]>({
    posts: sortTypeMap.Relevance,
    comments: sortTypeMap.Relevance,
    events: sortTypeMap.Relevance,
    lessons: sortTypeMap.Relevance,
    members: sortTypeMap.Relevance,
  });

  const setSort = (type: string, newSort: SortType) => {
    setSortState(prev => ({
      ...prev,
      [type]: newSort,
    }));
  };

  return (
    <SortContext.Provider value={{ sort, setSort }}>
      {children}
    </SortContext.Provider>
  );
};

export const useSortContext = () => useContext(SortContext);
