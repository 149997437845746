import { Form } from "@circle-react-uikit/Form";
import { Loader } from "@circle-react-uikit/Loader";
import { Advanced } from "../NewFormV2/Advanced";
import { BasicInfo } from "../NewFormV2/BasicInfo";
import { SpaceGroupModerators } from "./Moderators";

export interface EditSpaceGroupFormProps {
  onSubmit: (data: any) => void;
  defaultValues: any;
  loading?: boolean;
  formId: string;
}

export const EditSpaceGroupForm = ({
  formId,
  onSubmit,
  defaultValues,
  loading = false,
}: EditSpaceGroupFormProps) => {
  if (loading) {
    return <Loader />;
  }

  return (
    <Form
      id={formId}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      className="mx-1 h-full"
    >
      <div className="flex-1">
        <BasicInfo />
        <SpaceGroupModerators />
        <Advanced />
      </div>
    </Form>
  );
};
