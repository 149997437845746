import { useFormContext } from "react-hook-form";
import { paywallPriceTypes } from "@circle-react/types";
import { OneTimeForm } from "./OneTimeForm";
import { SubscriptionForm } from "./SubscriptionForm";

const componentsByType: any = {
  [paywallPriceTypes.onetime]: OneTimeForm,
  [paywallPriceTypes.subscription]: SubscriptionForm,
};

export const PriceForms = () => {
  const { watch } = useFormContext();
  const priceType = watch("price_type");

  const Component = componentsByType[priceType];
  if (!Component) {
    return null;
  }
  return <Component />;
};
