import PropTypes from "prop-types";
import { t } from "i18n-js";
import { Link } from "react-router-dom";
import { workflowsSettingsPaths } from "@circle-react/helpers/urlHelpers";
import { Icon } from "@circle-react-shared/Icon";
import { useToast } from "@circle-react-uikit/ToastV2";
import { TruncateText } from "@circle-react-uikit/TruncateText";
import { Typography } from "@circle-react-uikit/Typography";
import { Result } from "../Table/Result";

const localeNamespace = "settings.workflows.history";

export const SubHeader = ({ task }) => {
  const {
    status,
    id,
    workflow: { id: workflowId },
  } = task;
  const toast = useToast();

  const copyContent = async () => {
    try {
      await navigator.clipboard.writeText(id);
      toast.success(t([localeNamespace, "copy_success"]));
    } catch (err) {
      toast.error(t([localeNamespace, "copy_error"]));
    }
  };

  const idText = `${t([localeNamespace, "task_id"])}: ${id}`;

  return (
    <div className="mt-3 flex flex-col items-start gap-3 md:flex-row md:items-center">
      <Result result={status} />
      <span className="flex max-w-[15rem] cursor-pointer items-center">
        <TruncateText
          tooltipOptions={{
            content: id,
          }}
        >
          <Typography.LabelSm>{idText}</Typography.LabelSm>
        </TruncateText>
        <button
          className="hover:bg-secondary rounded-md transition"
          type="button"
          onClick={copyContent}
        >
          <span className="sr-only">{t("copy")}</span>
          <span className="flex h-6 w-6 items-center justify-center">
            <Icon type="16-copy" size={16} />
          </span>
        </button>
      </span>
      <Link
        to={workflowsSettingsPaths.view({ workflowId })}
        className="text-default hover:text-dark flex items-center"
      >
        <Typography.LabelSm>
          {t([localeNamespace, "view_workflow"])}
        </Typography.LabelSm>
        <Icon
          type="16-arrow"
          useWithFillCurrentColor
          viewBox="0 0 16 16"
          aria-hidden
        />
      </Link>
    </div>
  );
};

SubHeader.propTypes = {
  task: PropTypes.shape({
    runId: PropTypes.string,
    status: PropTypes.string,
    id: PropTypes.string,
    workflow: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};
