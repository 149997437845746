import { useEffect, useState } from "react";
import { t } from "i18n-js";
import circleLogo from "@circle-assets/images/circular-logo@2x.png";
import { usePunditUserContext } from "@circle-react/contexts";
import { APP_BANNER_LOCAL_STORAGE_KEY } from "@circle-react/hooks/appBanner/constants";
import { useAppBanner } from "@circle-react/hooks/appBanner/useAppBanner";
import { useLocalStorage } from "@circle-react/hooks/utils/useLocalStorage";
import { FullScreenModal } from "@circle-react-shared/ModalsV2/FullScreenModal";
import { Button } from "@circle-react-uikit/Button";
import { Typography } from "@circle-react-uikit/Typography";
import { CIRCLE_PLAYSTORE_URL, PLAYSTORE_BASE_URL } from "./constants";

const circleName = t("circle");

export const AppBanner = () => {
  const [isAppBannerModalOpen, setIsAppBannerModalOpen] = useState(false);
  const { currentCommunity = {} } = usePunditUserContext();

  const {
    name: communityName,
    has_branded_app: hasBrandedApp,
    android_store_id: androidStoreId,
    icon_url: iconUrl,
  } = currentCommunity;

  const hasBrandedAppAndLogo = hasBrandedApp && iconUrl;

  const { shouldRenderAppBanner } = useAppBanner();
  const { setValue: setLocalStorageValue } = useLocalStorage(
    APP_BANNER_LOCAL_STORAGE_KEY,
  );

  const handleAppBannerClose = () => {
    setIsAppBannerModalOpen(false);
    setLocalStorageValue({ dismissedAt: new Date() });
  };

  const handleDownloadAppClick = () => {
    hasBrandedApp && androidStoreId
      ? window.open(PLAYSTORE_BASE_URL + androidStoreId, "_blank")
      : window.open(CIRCLE_PLAYSTORE_URL, "_blank");
  };

  useEffect(() => {
    setIsAppBannerModalOpen(true);
  }, []);

  if (!shouldRenderAppBanner) {
    return null;
  }

  const bannerSrc = hasBrandedAppAndLogo ? iconUrl : circleLogo;

  const bannerAlt = hasBrandedAppAndLogo
    ? t("app_banner.logo_alt", { community_name: communityName })
    : t("app_banner.logo_alt", { community_name: circleName });

  const title = hasBrandedApp
    ? t("app_banner.title", { community_name: communityName })
    : t("app_banner.title", { community_name: circleName });

  const description = hasBrandedApp
    ? t("app_banner.description", { community_name: communityName })
    : t("app_banner.description", { community_name: circleName });

  return (
    <FullScreenModal
      shouldCloseOnOutsideClick
      shouldShowCloseButton={false}
      isOpen={isAppBannerModalOpen}
      contentPortalId="app-banner-portal"
      size="auto"
      position="bottom"
      onClose={handleAppBannerClose}
      className="!h-auto"
    >
      <FullScreenModal.Body className="px-8 py-10">
        <div className="flex flex-col items-center justify-center">
          <div className="border-primary mb-6 flex h-20 w-20 items-center rounded-xl border">
            <img
              loading="lazy"
              src={bannerSrc}
              alt={bannerAlt}
              className="mx-auto h-14 w-14 rounded-xl object-cover"
            />
          </div>
          <div className="mb-8 flex flex-col space-y-2 text-center">
            <Typography.TitleSm weight="semibold" color="text-dark">
              {title}
            </Typography.TitleSm>
            <Typography.LabelSm color="text-default">
              {description}
            </Typography.LabelSm>
          </div>
          <Button
            full
            extraLarge
            className="mb-4"
            onClick={handleDownloadAppClick}
          >
            {t("app_banner.download_app_cta")}
          </Button>
          <Button variant="plain" onClick={handleAppBannerClose}>
            {t("app_banner.continue_in_browser_cta")}
          </Button>
        </div>
      </FullScreenModal.Body>
    </FullScreenModal>
  );
};
