import { useFilterContext } from "@circle-react-shared/Filter/Filter";
import { Form } from "@circle-react-uikit/Form";
import { DateRangePickerContextProvider } from "./DateRangePickerContextProvider";

export const Root = ({
  children,
  className = "",
  defaultQueryParams,
  defaultValues,
}: any) => {
  const { onApply } = useFilterContext();

  return (
    <DateRangePickerContextProvider queryParamsKey={defaultQueryParams}>
      <Form onSubmit={onApply} defaultValues={defaultValues}>
        <div className={className}>{children}</div>
      </Form>
    </DateRangePickerContextProvider>
  );
};
