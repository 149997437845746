import { useMemo } from "react";
import {
  isAdminData,
  useGetMarketingHubStatus,
} from "@circle-react/hooks/useGetMarketingHubStatus";

export const useMarketingEmailLimits = () => {
  const { data } = useGetMarketingHubStatus();

  const shouldShowEmailNotices = useMemo(() => !!isAdminData(data), [data]);

  const isSendingMarketingEmailsAllowed = useMemo(() => {
    if (!isAdminData(data)) {
      return true;
    }

    return data.limits.allowance > 0;
  }, [data]);

  const marketingHubData = useMemo(() => {
    if (isAdminData(data)) {
      return data;
    }

    return null;
  }, [data]);

  return {
    shouldShowEmailNotices,
    isSendingMarketingEmailsAllowed,
    marketingHubData,
  };
};
