import {
  useGamificationSettings,
  useUpdateGamificationSettingsMutation,
} from "@circle-react/components/SettingsApp/Gamification/LevelsForm/gamificationApi";
import { useUpdateOptInSettings } from "../OptInLandingPage/hooks/useUpdateOptInSettings";

export interface ActivationConfirmationFormValues {
  activate_gamification: boolean;
}

export const useActivationConfirmationForm = () => {
  const { mutate: updateOptInMutation, isLoading: isLoadingOptInSettings } =
    useUpdateOptInSettings();

  const { data, isLoading: isLoadingDefaultValues } = useGamificationSettings();
  const defaultValues: ActivationConfirmationFormValues = {
    activate_gamification: data?.enabled ?? false,
  };

  const {
    mutate: updateGamificationSettingsMutation,
    isLoading: isSavingGamificationSettings,
  } = useUpdateGamificationSettingsMutation({
    onSuccess: () => {
      updateOptInMutation({
        operation: "migrate_community",
      });
    },
  });

  const handleSubmit = (formData: ActivationConfirmationFormValues) => {
    const { activate_gamification: isGamificationEnabled } = formData ?? {};

    updateGamificationSettingsMutation({
      enabled: isGamificationEnabled,
      include_admins_in_leaderboard: false,
      include_moderators_in_leaderboard: false,
    });
  };

  return {
    defaultValues,
    handleSubmit,
    isLoading:
      isLoadingOptInSettings ||
      isLoadingDefaultValues ||
      isSavingGamificationSettings,
  };
};
