import { useCallback, useEffect, useRef, useState } from "react";
import type { Editor as TipTapEditor } from "@tiptap/react";
import type { TrixEditorRefObject as TrixEditor } from "@circle-react-shared/TrixEditor";
import { isTrixEditorRefObject } from "@circle-react-shared/TrixEditor";

export const useCommentFocus = ({ initialFocus = true }) => {
  const [shouldShowComments, setShouldShowComments] = useState(true);
  const commentsFormRef = useRef<TrixEditor | TipTapEditor>(null);
  const [shouldAutofocusReplyForm, setShouldAutofocusReplyForm] =
    useState(initialFocus);

  const focusCommentsForm = useCallback(() => {
    const { current } = commentsFormRef;
    if (current && "focus" in current) {
      current.focus();
    }
  }, [commentsFormRef]);

  const showCommentsAndFocusOnForm = useCallback(() => {
    setShouldShowComments(true);
    setShouldAutofocusReplyForm(true);

    if (commentsFormRef.current) {
      if (isTrixEditorRefObject(commentsFormRef.current)) {
        commentsFormRef.current.focus();
      } else {
        commentsFormRef.current.commands?.focus?.("end");
      }
    }
  }, []);

  const toggleComment = () => {
    setShouldAutofocusReplyForm(false);
    setShouldShowComments(prev => !prev);
  };

  useEffect(() => {
    if (shouldAutofocusReplyForm) {
      focusCommentsForm();
    }
  }, [focusCommentsForm, shouldAutofocusReplyForm]);

  return {
    commentsFormRef,
    focusCommentsForm,
    showCommentsAndFocusOnForm,
    toggleComment,
    shouldShowComments,
    shouldAutofocusReplyForm,
  };
};
