import { useCallback, useMemo, useRef } from "react";
import { usePunditUserContext } from "@circle-react/contexts";
import { useCurrentPlanApi } from "../Plan/hooks/useCurrentPlanApi";
import { useApiAnalyticsApi } from "./useApiAnalyticsApi";

export const useApiUsage = () => {
  const usedColors = useRef<string[]>([]);
  const { apiAnalytics, isLoading: isLoadingApiUsage } = useApiAnalyticsApi();
  const { currentPlan, isCurrentPlanLoading } = useCurrentPlanApi();
  const { currentCommunitySettings, currentCommunity } = usePunditUserContext();

  const isApiAvailable = useMemo(
    () =>
      !!currentCommunitySettings?.api_usage_enabled &&
      !!currentCommunity?.api_access_feature_flag_enabled,
    [currentCommunitySettings, currentCommunity],
  );

  const apiRequestNumbersPerApi = useMemo(
    () => ({
      admin: apiAnalytics?.admin_v2 + apiAnalytics?.legacy || 0,
      headless:
        apiAnalytics?.headless_member + apiAnalytics?.headless_auth || 0,
    }),
    [apiAnalytics],
  );

  const getColorByPercentage = useCallback(
    (value: number, totalValue = 100): string => {
      const percentage = (value / totalValue) * 100;
      const colorSets = [
        ["#DC2626", "#841717", "#FF6B6B", "#994444"], // 4 tones of red
        ["#FBBF24", "#B08619", "#FFD700", "#DAA520"], // 4 tones of yellow
        ["#16A34A", "#0F7234", "#32CD32", "#228B22"], // 4 tones of green
      ];

      let selectedSet: string[];

      if (percentage >= 80) {
        selectedSet = colorSets[0];
      } else if (percentage >= 60) {
        selectedSet = colorSets[1];
      } else {
        selectedSet = colorSets[2];
      }

      // Find the first unused color in the selected set
      const unusedColor = selectedSet.find(
        color => !usedColors.current.includes(color),
      );

      if (unusedColor) {
        usedColors.current.push(unusedColor);
        return unusedColor;
      }
      // If all colors in the set have been used, cycle back to the first one
      const colorToUse = selectedSet[0];
      usedColors.current.push(colorToUse);
      return colorToUse;
    },
    [],
  );

  const limitsByTier = useMemo(
    () => currentPlan?.api_billing?.limits || { admin: 0, headless: 0 },
    [currentPlan],
  );

  const resetUsedColors = useCallback(() => {
    usedColors.current = [];
  }, []);

  return {
    getColorByPercentage,
    limitsByTier,
    currentTier: currentPlan?.tier,
    resetUsedColors,
    startOfBillingCycle: currentPlan?.api_billing?.cycle?.start_date,
    endOfBillingCycle: currentPlan?.api_billing?.cycle?.end_date,
    isApiAvailable,
    isLoadingApiUsage,
    isCurrentPlanLoading,
    apiRequestNumbersPerApi,
  };
};
