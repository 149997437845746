import { usePunditUserContext } from "@circle-react/contexts";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { useSmOrMdOrLgScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { useWhiteLabel } from "@circle-react/hooks/useWhiteLabel";
import { GoLive } from "./SidebarFooter";
import { useShouldDisplayGoLiveFooter } from "./SidebarFooter/useShouldDisplayGoLiveFooter";
import { SidebarPoweredByCircle } from "./SidebarPoweredByCircle";
import { useSidebarBorderStyles } from "./hooks";

export interface GoLivePoweredByProps {
  isPreviewMode?: boolean;
}

export const GoLivePoweredBy = ({
  isPreviewMode = false,
}: GoLivePoweredByProps) => {
  const { isV3Enabled } = useIsV3();
  const styles = useSidebarBorderStyles();
  const hasGoLiveButton = useShouldDisplayGoLiveFooter();
  const { shouldShowBranding } = useWhiteLabel();
  const isSmOrMdOrLgScreen = useSmOrMdOrLgScreenMediaQuery();
  const { currentCommunity } = usePunditUserContext();
  const { slug: communitySlug } = currentCommunity ?? {};

  if (!hasGoLiveButton && !shouldShowBranding) {
    return null;
  }

  if (isV3Enabled) {
    return (
      <div
        style={styles}
        className="border-c-sidebar bg-c-sidebar flex w-full flex-col gap-2 border-t px-6 pb-3.5 pt-4"
      >
        {!isSmOrMdOrLgScreen && <GoLive isPreviewMode={isPreviewMode} />}
        {shouldShowBranding && (
          <SidebarPoweredByCircle communitySlug={communitySlug} />
        )}
      </div>
    );
  }

  return (
    <div className="flex w-full flex-col">
      {!isSmOrMdOrLgScreen && <GoLive isPreviewMode={isPreviewMode} />}
      {shouldShowBranding && (
        <SidebarPoweredByCircle communitySlug={communitySlug} />
      )}
    </div>
  );
};
