import { useMemo } from "react";
import flatten from "lodash/flatten";
import { useInfiniteQuery } from "react-query";
import { workflowsApi } from "../api/workflowsApi";

export const SORT_OPTIONS_MAP = {
  ALPHABETICAL: "alphabetical",
  OLDEST: "oldest",
  LATEST: "latest",
};

export const DEFAULT_PAGE_SIZE = 20;

export const useAllWorkflowsInfiniteQuery = (args = {}) => {
  const {
    query = "",
    sort = SORT_OPTIONS_MAP.ALPHABETICAL,
    filters = {},
    perPage = DEFAULT_PAGE_SIZE,
    status,
    workflowType,
    initialPage = 1,
    options = {},
  } = args;

  const fetchPage = ({ pageParam = initialPage }) => {
    let page = 1;

    if (typeof pageParam === "number" && pageParam > 0) {
      page = pageParam;
    }

    const params = {
      page,
      sort,
      filters,
      status,
      workflow_type: workflowType,
      per_page: perPage,
    };

    if (query) {
      params.query = query;
    }

    return workflowsApi.list({ params });
  };

  const queryKey = [
    "all-workflows",
    query,
    sort,
    filters,
    perPage,
    status,
    workflowType,
    initialPage,
  ];

  /* TODO: Make abstract */
  const queryOptions = {
    getNextPageParam: lastPage =>
      lastPage.has_next_page ? lastPage.page + 1 : undefined,
    getPreviousPageParam: firstPage =>
      firstPage.page > 1 ? firstPage.page - 1 : undefined,
    keepPreviousData: true,
    ...options,
  };

  /* TODO: Make abstract */
  const result = useInfiniteQuery(queryKey, fetchPage, queryOptions);
  const pages = useMemo(() => result.data?.pages || [], [result.data?.pages]);
  const totalWorkflows = pages[0]?.total_count || 0;
  const pageSize = pages[0]?.per_page || perPage;
  const workflows = useMemo(
    () => flatten(pages.map(page => page.records)),
    [pages],
  );

  return {
    ...result,
    queryKey,
    totalWorkflows,
    workflows,
    pageSize,
    pages,
  };
};
