import { t } from "i18n-js";
import { settingsNavPath } from "@circle-react/helpers/urlHelpers";
import { Button } from "@circle-react-shared/uikit/Button";
import { SanitizeHTMLString } from "@circle-react-shared/uikit/SanitizeContent";
import { Typography } from "@circle-react-uikit/Typography";

export const ConnectStripeContainer = () => {
  const onClickButton = () => {
    window.open(settingsNavPath.paywalls.index, "_blank");
  };

  return (
    <div className="bg-tertiary flex flex-col gap-y-2 rounded p-4">
      <Typography.LabelMd weight="bold">
        {t("paywalls.lock_screen.sidebar.connect_stripe_container.title")}
      </Typography.LabelMd>
      <Typography.BodyMd color="text-default">
        <SanitizeHTMLString
          content={t(
            "paywalls.lock_screen.sidebar.connect_stripe_container.text",
          )}
        />
      </Typography.BodyMd>
      <div className="pt-2">
        <Button variant="circle" type="button" onClick={onClickButton}>
          {t("paywalls.lock_screen.sidebar.connect_stripe_container.cta")}
        </Button>
      </div>
    </div>
  );
};
