import { t } from "i18n-js";
import { TRIGGER_RESOURCE_TYPE } from "../../../constants";
import type { EventOption } from "../types";
import { CommunityResource } from "./shared/CommunityResource";
import { HiddenCommunityInputs } from "./shared/HiddenCommunityInputs";

export const joinedCommunity: Readonly<EventOption> = {
  label: t(
    "settings.workflows.form.events.community_member_joined_community.title",
  ),
  value: "community_member_joined_community",
  group: t("settings.workflows.form.event_groups.member"),
  description: t(
    "settings.workflows.form.events.community_member_joined_community.description",
  ),
  formComponent: HiddenCommunityInputs,
  resource: TRIGGER_RESOURCE_TYPE.COMMUNITY,
  viewComponent: CommunityResource,
  icon: "user",
  hasNoVisibleFormFields: true,
};
