import { t } from "i18n-js";
import { useLocation } from "react-router-dom";
import { usePointsInfoModal } from "@circle-react/components/Leaderboard/Dashboard/MyProfile/PointsInfoModal";
import { DataTable } from "@circle-react/components/Leaderboard/Dashboard/Table/DataTable";
import { LoadingState } from "@circle-react/components/Leaderboard/Dashboard/Table/LoadingState";
import { RangePicker } from "@circle-react/components/Leaderboard/Dashboard/Table/RangePicker";
import { useLeaderboard } from "@circle-react/components/Leaderboard/Dashboard/Table/useLeaderboard";
import { Typography } from "@circle-react-shared/uikit/Typography";

export const LeaderboardTable = () => {
  const { search } = useLocation();
  const period = new URLSearchParams(search).get("period");
  const shouldShowPlusIcon = period === "7_days" || period === "30_days";
  const pointsInfoModal = usePointsInfoModal();

  const { isLoading, data } = useLeaderboard({
    period,
  });

  return (
    <div className="py-8">
      <div className="flex items-center justify-between gap-x-2">
        <RangePicker />
        <button
          type="button"
          className="focus-visible:!outline-secondary hidden rounded-md sm:block md:mr-6"
          onClick={() => pointsInfoModal.show()}
        >
          <Typography.LabelXs weight="medium">
            <span className="text-light hover:text-default">
              {t("leaderboard.how_do_points_work")}
            </span>
          </Typography.LabelXs>
        </button>
      </div>
      {isLoading ? (
        <LoadingState />
      ) : (
        <DataTable
          shouldShowPlusIcon={shouldShowPlusIcon}
          data={data.top_members}
        />
      )}
    </div>
  );
};
