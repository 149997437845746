import classNames from "classnames";
import { isFunction } from "lodash";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { CommunityLogo } from "./CommunityLogo";

const LOGO_TYPES = {
  LOGO: "logo",
  ICON: "icon",
};

export interface LogoLinkProps {
  communityName: string;
  logoType: "logo" | "icon";
  logoUrl?: string | null;
  as?: "button" | "div";
  onLogoClick?: () => void;
}

export const LogoLink = ({
  communityName,
  logoType,
  logoUrl,
  as = "button",
  onLogoClick,
}: LogoLinkProps) => {
  const Component = as;
  const { isV3Enabled } = useIsV3();

  const buttonProps = {
    type: "button",
  };

  return (
    <Component
      className="w-full min-w-0"
      id="community-logo-wrapper"
      onClick={() => isFunction(onLogoClick) && onLogoClick()}
      {...(as === "button" && {
        buttonProps,
      })}
    >
      {logoType === LOGO_TYPES.LOGO && logoUrl ? (
        <CommunityLogo name={communityName} src={logoUrl} />
      ) : (
        <div
          className={classNames("flex items-center gap-2", {
            "text-c-sidebar": !isV3Enabled,
            "text-c-header": isV3Enabled,
          })}
        >
          {logoUrl && (
            <div>
              <CommunityLogo name={communityName} src={logoUrl} />
            </div>
          )}
          <div className="w-full min-w-0">
            <h1
              data-testid="title-name"
              className={classNames(
                "truncate text-left text-xl font-semibold normal-case leading-7 tracking-normal",
                {
                  "text-c-sidebar": !isV3Enabled,
                  "text-c-header": isV3Enabled,
                },
              )}
            >
              {communityName}
            </h1>
          </div>
        </div>
      )}
    </Component>
  );
};
