import PropTypes from "prop-types";
import I18n from "i18n-js";
import { Drawer } from "@circle-react/components/shared/uikit/Drawer";
import { Button } from "@circle-react-uikit/Button";
import { Form } from "@circle-react-uikit/Form";
import { Details } from "../FormFields/Details";
import { Paywalls } from "../FormFields/Paywalls";
import { Usage } from "../FormFields/Usage";
import Navigation from "../Navigation";
import { TABS } from "../Navigation/constants";
import { usePaywallCoupon } from "../hooks/usePaywallCoupon";
import "./style.scss";

export const FormFields = ({ refetchCoupons, onClose, paywallCoupon }) => {
  const { getFormDefaults, handleSubmit, setActiveTab, mutation, activeTab } =
    usePaywallCoupon({ paywallCoupon, refetchCoupons, onClose });

  const renderTab = () => {
    switch (activeTab) {
      case TABS.DETAILS:
        return <Details paywallCoupon={paywallCoupon} />;
      case TABS.USAGE:
        return <Usage paywallCoupon={paywallCoupon} />;
      case TABS.PAYWALLS:
        return <Paywalls />;
    }
  };

  return (
    <Form
      onSubmit={handleSubmit}
      className="paywall-coupons-form"
      defaultValues={getFormDefaults(paywallCoupon)}
    >
      {({ formState: { isSubmitting } }) => (
        <>
          <Navigation activeTab={activeTab} setActiveTab={setActiveTab} />
          <div className="paywall-coupons-form__fields h-0 overflow-y-auto">
            {renderTab()}
          </div>

          <Drawer.Footer sticky>
            <Button
              type="submit"
              variant="primary"
              full
              disabled={isSubmitting || mutation.isLoading}
            >
              {activeTab != TABS.USAGE &&
                I18n.t("settings.paywall_coupons.buttons.next")}
              {activeTab == TABS.USAGE &&
                !paywallCoupon &&
                I18n.t("settings.paywall_coupons.buttons.create")}
              {activeTab == TABS.USAGE &&
                paywallCoupon &&
                I18n.t("settings.paywall_coupons.buttons.update")}
            </Button>
          </Drawer.Footer>
        </>
      )}
    </Form>
  );
};

FormFields.propTypes = {
  refetchCoupons: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  paywallCoupon: PropTypes.object,
};
