import classnames from "classnames";

const getHeightV2Styles = (
  shouldShowTrialBanner: boolean,
  isLgScreen: boolean,
  isMasqueradingEnabledAndActive: boolean,
  isEitherOptInOrPreviewBarVisible: boolean,
) =>
  classnames({
    "lg:!min-h-[calc(100vh-48px)]":
      (shouldShowTrialBanner || isLgScreen) &&
      !isEitherOptInOrPreviewBarVisible,
    "lg:!min-h-[calc(100vh-48px-48px)]":
      (shouldShowTrialBanner || isLgScreen) &&
      isEitherOptInOrPreviewBarVisible &&
      !isMasqueradingEnabledAndActive,
    "lg:!min-h-[calc(100vh-96px)]":
      (isLgScreen &&
        isMasqueradingEnabledAndActive &&
        !isEitherOptInOrPreviewBarVisible) ||
      (isLgScreen &&
        shouldShowTrialBanner &&
        !isEitherOptInOrPreviewBarVisible),
    "lg:!min-h-[calc(100vh-144px)]":
      shouldShowTrialBanner &&
      isLgScreen &&
      isMasqueradingEnabledAndActive &&
      !isEitherOptInOrPreviewBarVisible,
    "lg:!min-h-[calc(100vh-48px-48px-48px)]":
      isLgScreen &&
      isMasqueradingEnabledAndActive &&
      (isEitherOptInOrPreviewBarVisible || shouldShowTrialBanner),
    "lg:!min-h-[calc(100vh-48px-48px-48px-48px)]":
      isLgScreen &&
      isMasqueradingEnabledAndActive &&
      isEitherOptInOrPreviewBarVisible &&
      shouldShowTrialBanner,
  });

const getHeightV3Styles = (
  shouldShowTrialBanner: boolean,
  isLgScreen: boolean,
  isMasqueradingEnabledAndActive: boolean,
  isEitherOptInOrPreviewBarVisible: boolean,
  isReactFlowWorkflowsRoute: boolean,
) => {
  if (isReactFlowWorkflowsRoute) return "";

  return classnames({
    "lg:!min-h-[calc(100vh-64px)]":
      !shouldShowTrialBanner &&
      isLgScreen &&
      !isEitherOptInOrPreviewBarVisible &&
      !isMasqueradingEnabledAndActive,
    "lg:!min-h-[calc(100vh-64px-48px)]":
      (shouldShowTrialBanner || isLgScreen) &&
      (isEitherOptInOrPreviewBarVisible || isMasqueradingEnabledAndActive),
    "lg:!min-h-[calc(100vh-112px)]":
      (isLgScreen && isMasqueradingEnabledAndActive) ||
      (isLgScreen && shouldShowTrialBanner),
    "lg:!min-h-[calc(100vh-160px)]":
      shouldShowTrialBanner && isLgScreen && isMasqueradingEnabledAndActive,
    "lg:!min-h-[calc(100vh-64px-48px-48px)]":
      isLgScreen &&
      isMasqueradingEnabledAndActive &&
      (isEitherOptInOrPreviewBarVisible || shouldShowTrialBanner),
    "lg:!min-h-[calc(100vh-64px-48px-48px-48px)]":
      isLgScreen &&
      isMasqueradingEnabledAndActive &&
      isEitherOptInOrPreviewBarVisible &&
      shouldShowTrialBanner,
  });
};

export { getHeightV2Styles, getHeightV3Styles };
