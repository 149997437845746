import { isEmpty } from "lodash";
import { useFormContext } from "react-hook-form";
import { useAudienceInfiniteQuery } from "@circle-react/hooks/useAudienceInfiniteQuery";
import { useContactsInWorkflowsEnabled } from "@circle-react/hooks/useContactsInWorkflowsEnabled";
import { STATUS_OPTIONS_MAP } from "@circle-react-shared/MembersList/constants";
import { numberFormatter } from "@circle-react-shared/uikit/TabV2/Counter";
import { removeEmptyValues } from "./removeEmptyValues";

export const useFormAudienceCounter = query => {
  const { watch } = useFormContext();
  const audienceFilters = watch("audience.filter_rules") || {};

  const filters = removeEmptyValues(audienceFilters);

  if (query) {
    filters.push({ key: "name", filter_type: "contains", value: query });
  }

  const hasFilters = !isEmpty(filters);

  const isContactsInWorkflowsEnabled = useContactsInWorkflowsEnabled();

  const { totalQueryAudience, ...audienceQuery } = useAudienceInfiniteQuery({
    filters,
    scope: {
      status: isContactsInWorkflowsEnabled
        ? STATUS_OPTIONS_MAP.ALL
        : STATUS_OPTIONS_MAP.ACTIVE,
      exclude_empty_name: false,
      exclude_empty_profiles: false,
    },
    options: { keepPreviousData: true },
  });

  const formattedCount = numberFormatter(totalQueryAudience);

  return {
    hasFilters,
    formattedCount,
    count: totalQueryAudience,
    ...audienceQuery,
  };
};
