import { useCallback, useMemo, useState } from "react";
import { t } from "i18n-js";
import { useMutation } from "react-query";
import { communityMemberSubscriptionPriceChangesApi } from "@/react/api/communityMemberSubscriptionPriceChangeApi";
import { isTrial } from "@circle-react/helpers/communityMemberSubscriptionHelpers";
import { useToast } from "@circle-react-uikit/ToastV2";
import { CHANGE_PAYWALL_STEPS } from "../constants";
import { usePaymentMethodForm } from "./usePaymentMethodForm";

export interface UseChangePlanProps {
  subscription: any;
  changePlan: boolean;
  onSuccess: (result: any) => void;
  CardElement: any;
}

export const useChangePlan = ({
  subscription,
  changePlan,
  onSuccess,
  CardElement,
}: // eslint-disable-next-line sonarjs/cognitive-complexity -- Added before disable comments where required
UseChangePlanProps) => {
  const { error: showError } = useToast();

  const [isCreatingPaymentMethod, setIsCreatingPaymentMethod] = useState(false);
  const [isCardInfoComplete, setIsCardInfoComplete] = useState(false);

  const handleOnChangeCard = (event: any) => {
    if (event.complete) {
      setIsCardInfoComplete(true);
    } else if (isCardInfoComplete) setIsCardInfoComplete(false);
  };
  const isInTrial = isTrial(subscription);

  const shouldAddPaymentMethod =
    isTrial(subscription) && !subscription.default_payment_method;

  const [shouldKeepTrial, setShouldKeepTrial] = useState(
    // this means subscription is a trial and member is changing
    // billing cycle and we keep trial for the same plan
    isInTrial && !changePlan,
  );

  const [activeStep, setActiveStep] = useState(
    changePlan ? CHANGE_PAYWALL_STEPS.PAYWALL : CHANGE_PAYWALL_STEPS.PRICES,
  );

  const [priceChangePreview, setPriceChangePreview] = useState<any>({});

  const isPlanChooserActive = useMemo(
    () => activeStep === CHANGE_PAYWALL_STEPS.PAYWALL,
    [activeStep],
  );
  const isPriceChooserStepActive = useMemo(
    () => activeStep === CHANGE_PAYWALL_STEPS.PRICES,
    [activeStep],
  );
  const isConfirmationStepActive = useMemo(
    () => activeStep === CHANGE_PAYWALL_STEPS.CONFIRMATION,
    [activeStep],
  );

  const canGoBack = useMemo(
    () =>
      (changePlan && (isPriceChooserStepActive || isConfirmationStepActive)) ||
      (!changePlan && isConfirmationStepActive),
    [changePlan, isPriceChooserStepActive, isConfirmationStepActive],
  );

  const goBack = useCallback(() => {
    if (isConfirmationStepActive) {
      setActiveStep(CHANGE_PAYWALL_STEPS.PRICES);
    } else if (isPriceChooserStepActive && changePlan) {
      setActiveStep(CHANGE_PAYWALL_STEPS.PAYWALL);
    }
  }, [changePlan, isPriceChooserStepActive, isConfirmationStepActive]);

  const getTitle = () => {
    if (isPriceChooserStepActive) {
      return t(
        "member_profile_modal.billing.subscriptions.headings.billing_cycle",
      );
    }
    if (isInTrial) {
      return t(
        "member_profile_modal.billing.subscriptions.headings.upgrade_plan",
      );
    }
    if (isPlanChooserActive) {
      return t(
        "member_profile_modal.billing.subscriptions.headings.change_plan",
      );
    }
    return t(
      "member_profile_modal.billing.subscriptions.headings.change_subscription",
    );
  };

  const getButtonLabel = () => {
    if (!isConfirmationStepActive) {
      return t("member_profile_modal.billing.subscriptions.actions.next");
    }
    return t(
      "member_profile_modal.billing.subscriptions.actions.confirm_change",
    );
  };

  const getFormDefaults = () => ({
    paywall_id: changePlan ? "" : subscription.paywall_id,
    paywall_price_id: subscription.paywall_price_id,
    new_paywall_price_id: "",
  });

  const changePriceApi = (data: any) =>
    communityMemberSubscriptionPriceChangesApi.createPriceChange(
      subscription.id,
      {
        ...data,
        new_paywall_price_id: priceChangePreview?.paywall_price_id,
        new_coupon_code: priceChangePreview?.paywall_coupon_code,
      },
    );

  const setNewCouponError = (errorMessage: string) => {
    setPriceChangePreview({
      ...priceChangePreview,
      new_coupon_code_error: errorMessage,
    });
  };
  const validateNewCouponHandler = (data: any) => {
    if (!data.new_coupon_code) {
      setNewCouponError(
        t(
          "member_profile_modal.billing.subscriptions.confirmation_step.coupon.errors.required",
        ),
      );
      return;
    }

    previewMutation.mutate(data);
  };

  const previewMutation = useMutation(
    (data: any) =>
      communityMemberSubscriptionPriceChangesApi.previewPriceChange(
        subscription.id,
        data,
      ),
    {
      onSuccess: (result: any) => {
        setPriceChangePreview({
          ...result,
          new_coupon_code_error: null,
        });

        setActiveStep(CHANGE_PAYWALL_STEPS.CONFIRMATION);
      },
      onError: (error: any) => {
        if (error.errorDetails.code == "INVALID_COUPON") {
          setNewCouponError(error.message);
        } else {
          console.error(error);
          showError(error.message);
        }
      },
    },
  );

  const changePriceMutation = useMutation(changePriceApi, {
    onSuccess: (result: any) => {
      onSuccess(result);
    },
    onError: (error: any) => {
      console.error(error);
      showError(error.message);
    },
  });

  const { createPaymentMethod } = usePaymentMethodForm({
    CardElement,
    currency: subscription.currency,
  });

  const handleOnAddPaymentAndChangePrice = async (data: any) => {
    try {
      // We cannot render ChangePlanFormLoader component when creating
      // a payment method as Stripe CardElement component is needed in that process,
      // otherwise an error will occur. So using isCreatingPaymentMethod state to
      // disable Confirm button.
      // ChangePlanFormLoader will be rendered when changing the price
      setIsCreatingPaymentMethod(true);
      await createPaymentMethod({ currencyId: subscription.currency.id });
      setIsCreatingPaymentMethod(false);

      const updatedSubscription = await changePriceMutation.mutateAsync(data);
      onSuccess(updatedSubscription);
    } catch (processError: any) {
      console.error(processError);
      showError(processError.message);
    } finally {
      setIsCreatingPaymentMethod(false);
    }
  };

  return {
    isPlanChooserActive,
    isPriceChooserStepActive,
    isConfirmationStepActive,
    canGoBack,
    goBack,
    getTitle,
    getButtonLabel,
    getFormDefaults,
    priceChangePreview,
    setActiveStep,
    setShouldKeepTrial,
    handleOnChangeCard,
    handleOnAddPaymentAndChangePrice,
    isCardInfoComplete,
    shouldKeepTrial,
    shouldAddPaymentMethod,
    validateNewCouponHandler,
    previewMutation,
    changePriceMutation,
    isCreatingPaymentMethod,
    isInTrial,
  };
};
