const removeEmptyElements = htmlString => {
  const doc = new DOMParser().parseFromString(htmlString, "text/html");
  doc
    .querySelectorAll(":empty:not(br):not(img)")
    .forEach(elem => elem.remove());
  return doc.body.innerHTML;
};

export const sanitizeHtmlForWidget = content => {
  const tempElement = document.createElement("div");

  // Replace new lines with a single <br> tag
  tempElement.innerHTML = content
    .replace(/\n/g, "<br>")
    .replace(/(<br\s*\/?>\s*)+/g, "<br>");

  // Remove all style attributes
  const elementsWithStyle = tempElement.querySelectorAll("[style]");
  elementsWithStyle.forEach(element => element.removeAttribute("style"));

  // Remove all empty elements
  const htmlString = tempElement.innerHTML;
  return removeEmptyElements(htmlString);
};
