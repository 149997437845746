import PropTypes from "prop-types";
import { CourseTypePickerLabel } from "@/react/components/Spaces/CourseSpace/CourseTypePicker/CourseTypePickerLabel";
import { Section } from "@/react/components/Spaces/CustomizeSpaceFullPage/Section";
import { useBooleanList } from "@/react/hooks/utils/useBooleanList";
import { LoaderRow } from "@circle-react/components/shared/LoaderRow";
import { Typography } from "@circle-react-uikit/Typography";
import { EmptyState } from "./EmptyState";
import { LessonTable } from "./Table";

export const CustomizeLessonsContent = ({
  sections,
  setSections,
  sectionsAreLoading,
  space,
}) => {
  const [sectionsNameEditor, dispatchSectionsNameEditor] = useBooleanList();

  return (
    <Section className="!max-w-6xl md:!px-6">
      <div className="flex flex-col">
        <div
          className="mb-4 flex justify-between"
          data-testid="customize-lessons-header"
        >
          <div className="space-y-2">
            <Typography.TitleMd>
              {I18n.t("courses.edit.sections.lessons")}
            </Typography.TitleMd>
            <CourseTypePickerLabel space={space} />
          </div>
        </div>
        {sectionsAreLoading ? (
          <LoaderRow />
        ) : sections.length ? (
          <LessonTable
            space={space}
            sections={sections}
            setSections={setSections}
            dispatchSectionsNameEditor={dispatchSectionsNameEditor}
            sectionsNameEditor={sectionsNameEditor}
          />
        ) : (
          <EmptyState
            sections={sections}
            dispatchSectionsNameEditor={dispatchSectionsNameEditor}
          />
        )}
      </div>
    </Section>
  );
};

CustomizeLessonsContent.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.object).isRequired,
  setSections: PropTypes.func.isRequired,
  sectionsAreLoading: PropTypes.bool.isRequired,
  space: PropTypes.object.isRequired,
};
