import { Suspense } from "react";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { PageLoader } from "@circle-react/pages/PageLoader";

const PaywallsSettingsComponent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "PaywallsSettingsPage" */ "@circle-react/components/SettingsApp/PaywallsApp"
    ),
);

export const PaywallsSettingsPage = () => (
  <Suspense fallback={<PageLoader />}>
    <PaywallsSettingsComponent />
  </Suspense>
);
