import { useCallback } from "react";
import type { StandardLayoutHeaderProps } from ".";
import { StandardLayoutHeader } from ".";
import { Portal } from "@circle-react-uikit/Portal";

const childId = "standard-layout-header-child";

export const useSpaceHeader = () => {
  const getIsPresent = useCallback(
    () => !!document.querySelector(`#${childId}`),
    [],
  );

  return {
    getIsPresent,
  };
};

export const StandardLayoutHeaderWithPortal = ({
  children,
  ...props
}: StandardLayoutHeaderProps) => (
  <Portal selectorQuery="#standard-layout-header">
    <StandardLayoutHeader {...props} id={childId} />
    {children}
  </Portal>
);
