import PropTypes from "prop-types";
import { t } from "i18n-js";
import { Form } from "@circle-react-uikit/Form";
import { FormItem } from "./FormItem";

const HEX_REGEX = /^#[0-9A-Fa-f]{6}$/;

export const ColorPickerField = ({ name, ...rest }) => (
  <FormItem
    name={name}
    variant="extra-bottom-margin"
    rules={{
      required: t("settings.branding_form.prefs.brand_color_required"),
      pattern: {
        value: HEX_REGEX,
        message: t("settings.branding_form.prefs.brand_color_invalid"),
      },
    }}
    {...rest}
  >
    <Form.ColorPicker name={name} />
  </FormItem>
);

ColorPickerField.propTypes = {
  name: PropTypes.string,
};
