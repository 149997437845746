import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { DeleteSnippetModalComponent } from "./DeleteSnippetModalComponent";

const DeleteSnippetModal = ModalManager.create(
  ({ snippetId, title }: { snippetId: number; title: string }) => (
    <DeleteSnippetModalComponent id={snippetId} title={title} />
  ),
);

export const useDeleteSnippetModal = () => useModal(DeleteSnippetModal);
