import { useEffect, useState } from "react";
import {
  HMSNotificationTypes,
  useHMSNotifications,
} from "@100mslive/react-sdk";
import { PARTICIPANT_ROLES } from "@circle-react/helpers/liveStreamRoomHelpers";

export const useHMSRoleChangeNotification = () => {
  const [roleChangedToModerator, setRoleChangedToModerator] = useState(false);
  const notification = useHMSNotifications(HMSNotificationTypes.ROLE_UPDATED);
  const peer = notification?.data;
  const hideModeratorNotification = () => setRoleChangedToModerator(false);
  useEffect(() => {
    if (!notification) {
      return;
    }
    if (peer && peer.isLocal && peer.roleName == PARTICIPANT_ROLES.MODERATOR) {
      setRoleChangedToModerator(true);
    }
  }, [notification]);
  return {
    roleChangedToModerator,
    hideModeratorNotification,
  };
};
