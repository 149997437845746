import { t } from "i18n-js";
import { isCourseSpace } from "@/react/helpers/spaceHelpers";
import type { Space } from "@/react/types/Space";
import { Form } from "@circle-react-uikit/Form";
import { Typography } from "@circle-react-uikit/Typography";

interface CourseAdminNotificationsProps {
  space: Space;
}
export const CourseAdminNotifications = ({
  space,
}: CourseAdminNotificationsProps) => {
  if (!isCourseSpace(space)) {
    return null;
  }

  return (
    <>
      <Typography.TitleSm weight="bold">
        {t("spaces.form.edit.options.course_notifications.title")}
      </Typography.TitleSm>
      <Form.Item
        hiddenField
        hideDescription
        label={t(
          "spaces.form.edit.options.course_notifications.new_comment_notification",
        )}
        tooltipText={t(
          "spaces.form.edit.options.course_notifications.new_comment_notification_tooltip",
        )}
        name="course_setting_attributes.new_comment_notification_enabled"
        className="my-5 flex flex-row-reverse items-center justify-end py-0"
        labelClassName="ml-4 mb-0"
        shouldToggleValueOnLabelClick
      >
        <Form.ToggleSwitch
          variant="small"
          name="course_setting_attributes.new_comment_notification_enabled"
        />
      </Form.Item>
    </>
  );
};
