import { t } from "i18n-js";
import { Typography } from "@circle-react-uikit/Typography";
import { i18nRoot } from "../constants";

export const EmptyResult = () => (
  <div className="flex w-full flex-auto flex-col justify-center gap-y-3 px-6 py-12 text-center">
    <Typography.TitleSm weight="semibold" color="text-selector-active">
      {t([i18nRoot, "empty_result.title"])}
    </Typography.TitleSm>
    <Typography.LabelSm color="text-selector-active">
      {t([i18nRoot, "empty_result.subtitle"])}
    </Typography.LabelSm>
  </div>
);
