import classnames from "classnames";
import { t } from "i18n-js";
import { useFormContext, useWatch } from "react-hook-form";
import { Form } from "@circle-react-uikit/Form";
import { Typography } from "@circle-react-uikit/Typography";
import { TabTitle } from "../TabTitle";
import { PaywallSpaceAccessManagement } from "./PaywallSpaceAccessManagement";
import "./style.scss";

export const AccessTab = () => {
  const { control } = useFormContext();
  const allSpacesToggleWatcher = useWatch({ control, name: "all_spaces" });

  const containerClass = classnames(
    "paywalls-admin-drawer-access-tab__container px-4 h-[60vh] flex flex-col pb-4",
    {
      "drawer-access-expand": !allSpacesToggleWatcher,
    },
  );

  return (
    <div className={containerClass}>
      <TabTitle title={t("settings.paywalls.modal.tabs.access.title")} />
      <Form.Item
        name="all_spaces"
        translationRoot="settings.paywalls.modal.tabs.access"
        tooltipText={t(
          "settings.paywalls.modal.tabs.access.all_spaces_tooltip",
        )}
        inline
        hideBorder
        hideDescription
        className="flex flex-row-reverse items-center gap-x-4"
      >
        <Form.ToggleSwitch variant="small" />
      </Form.Item>

      {!allSpacesToggleWatcher && <PaywallSpaceAccessManagement />}
      {allSpacesToggleWatcher && (
        <div className="flex w-full flex-auto flex-col justify-center gap-y-3 text-center">
          <Typography.TitleSm color="text-selector-active" weight="semibold">
            {t("settings.paywalls.modal.tabs.access.all_access_title")}
          </Typography.TitleSm>
          <Typography.LabelSm color="text-selector-active">
            {t("settings.paywalls.modal.tabs.access.all_access_subtitle")}
          </Typography.LabelSm>
        </div>
      )}
    </div>
  );
};
