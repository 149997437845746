import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import type { Space } from "@circle-react/types";
import { SpaceLinksModal } from "./Modal";

export interface AddSpaceLinksModalProps {
  space: Space;
}

const AddSpaceLinksModal = ModalManager.create(
  ({ space }: AddSpaceLinksModalProps) => <SpaceLinksModal space={space} />,
);

export const useAddSpaceLinksModal = () => useModal(AddSpaceLinksModal);
