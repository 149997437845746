import PropTypes from "prop-types";
import { Drawer } from "@circle-react-shared/uikit/Drawer";
import { FormFields } from "../FormFields";

export const EditForm = ({
  paywallCoupon,
  refetchCoupons,
  isDrawerVisible,
  toggleDrawer,
}) => (
  <Drawer
    open={isDrawerVisible}
    title={I18n.t("settings.paywall_coupons.edit_title")}
    onClose={toggleDrawer}
    noWrapperPadding
  >
    <FormFields
      refetchCoupons={refetchCoupons}
      onClose={toggleDrawer}
      paywallCoupon={paywallCoupon}
    />
  </Drawer>
);

EditForm.propTypes = {
  refetchCoupons: PropTypes.func.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  isDrawerVisible: PropTypes.bool.isRequired,
  paywallCoupon: PropTypes.object,
};
