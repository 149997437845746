import { useRef, useState } from "react";
import { t } from "i18n-js";
import { useNotifyStudentsModalStore } from "@/react/components/Spaces/CourseSpace/NotifyStudents/store";
import type { CoursesSection } from "@/react/types/CoursesSection";
import { Typography } from "@circle-react/components/shared/uikit/Typography";
import { Icon } from "@circle-react-shared/Icon";
import { Button } from "@circle-react-uikit/Button";
import { ButtonWithDisabled } from "@circle-react-uikit/ButtonWithDisabled";
import { Modal } from "@circle-react-uikit/ModalV2";
import { FORM_ID, NotifyStudentsEmailForm } from "./Form";

interface NotifyStudentsEmailModalProps {
  section: CoursesSection;
  isOpen: boolean;
  isOpenForEdit: boolean;
  onClose: () => void;
  shouldShowNotificationMessage: boolean;
}

export const NotifyStudentsEmailModal = ({
  section,
  isOpen,
  isOpenForEdit,
  onClose,
  shouldShowNotificationMessage,
}: NotifyStudentsEmailModalProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const initialFocusRef = useRef(null);
  const { isDirty, setIsDirty } = useNotifyStudentsModalStore();

  const onCloseWithConfirm = () => {
    /* eslint-disable-next-line no-alert --
       We use a very obtrusive confirm dialog to make sure the
       user is aware of the consequences of closing the modal
     */
    if (!isDirty || confirm(t("prevent_accidental_leave_message"))) {
      onClose();
    }
  };

  return (
    <Modal
      isOpen={isOpen || isOpenForEdit}
      onClose={onCloseWithConfirm}
      contentPortalId="course-type-picker-portal"
      initialFocus={initialFocusRef}
    >
      <Modal.Overlay />
      <Modal.Content size="lg">
        <Modal.Header>
          <Modal.Title size="sm">
            {t("courses.edit.sections.notify_students_modal.title")}
          </Modal.Title>
          <Modal.HeaderActions>
            <Modal.CloseButton onClick={onCloseWithConfirm} />
          </Modal.HeaderActions>
        </Modal.Header>
        <Modal.Body className="!px-0">
          {shouldShowNotificationMessage && !isOpenForEdit && (
            <div className="border-primary bg-secondary flex items-start border px-8 py-6">
              <div className="mr-3 pt-0.5">
                <Icon type="20-alert" className="w-4" />
              </div>
              <div>
                <p className="mb-1">
                  <Typography.LabelSm weight="semibold">
                    {t(
                      "courses.edit.sections.notify_students_modal.notify_your_members_title",
                    )}
                  </Typography.LabelSm>
                </p>
                <Typography.BodySm>
                  {t(
                    "courses.edit.sections.notify_students_modal.notify_your_members_message",
                  )}
                </Typography.BodySm>
              </div>
            </div>
          )}
          <NotifyStudentsEmailForm
            section={section}
            isEdit={isOpenForEdit}
            onSuccess={() => {
              setIsDirty(false);
              onClose();
              // delay to avoid flickering
              setTimeout(() => setIsSubmitting(false), 100);
            }}
            onSubmit={() => setIsSubmitting(true)}
            onError={() => {
              setIsSubmitting(false);
              setIsDirty(true);
            }}
            initialFocusRef={initialFocusRef}
          />
        </Modal.Body>
        <Modal.Footer className="flex justify-end border-t">
          {!isSubmitting && (
            <Button
              type="button"
              variant="secondary"
              className="mr-2"
              onClick={onCloseWithConfirm}
            >
              {t("cancel")}
            </Button>
          )}
          <ButtonWithDisabled
            type="submit"
            variant="circle"
            form={FORM_ID}
            submitting={isSubmitting}
          >
            {t("save")}
          </ButtonWithDisabled>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};
