import type { ReactNode } from "react";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { useIsV3 } from "@circle-react/hooks/useIsV3";

export interface OverlayScrollbarsProps {
  children: ReactNode;
}

export const OverlayScrollbars = ({ children }: OverlayScrollbarsProps) => {
  const { isV3Enabled } = useIsV3();

  if (!isV3Enabled) {
    return <>{children}</>;
  }

  return (
    <OverlayScrollbarsComponent
      className="h-screen"
      options={{
        scrollbars: {
          autoHide: "leave",
          autoHideDelay: 0,
        },
      }}
    >
      {children}
    </OverlayScrollbarsComponent>
  );
};
