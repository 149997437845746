import { useFormContext } from "react-hook-form";
import { usePaywallCheckoutContext } from "@circle-react/contexts/Paywalls/paywallCheckoutContext";
import { isCard } from "@circle-react/helpers/paywallCheckoutHelpers";
import { CreditCardForm } from "./CreditCardForm/CreditCardForm";
import { PaymentElementForm } from "./PaymentElementForm/PaymentElementForm";

export const PaymentMethodTypeForm = ({
  handleOnChangeCard,
  handleOnReadyCard,
}: any) => {
  const { isCardInfoRequired, isPaywallDigitalWalletEnabled } =
    usePaywallCheckoutContext();
  const { watch } = useFormContext();

  const paymentMethodType = watch("payment_method_type");

  const shouldShowForm = isCard(paymentMethodType) && isCardInfoRequired;

  const FormComponent = isPaywallDigitalWalletEnabled
    ? PaymentElementForm
    : CreditCardForm;
  return (
    <>
      {shouldShowForm && (
        <FormComponent
          handleOnChangeCard={handleOnChangeCard}
          handleOnReadyCard={handleOnReadyCard}
        />
      )}
    </>
  );
};
