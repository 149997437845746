import { noop } from "lodash";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  reactQueryGet,
  reactQueryPatch,
} from "@/react/helpers/backendRequestHelpers";
import { internalApi } from "@/react/helpers/urlHelpers";
import { usePunditUserContext } from "@circle-react/contexts";

const REQUEST_KEY = ["community-setting", "show"];

const getCommunitySettings = () => {
  const url = internalApi.communities.communitySettings.index();
  return reactQueryGet(url);
};

export const useCommunitySettingShow = () =>
  useQuery(REQUEST_KEY, getCommunitySettings);

const updateCommunitySettings = ({ data, updates }) => {
  const url = internalApi.communities.communitySettings.update({
    communitySettingId: data.community_settings.id,
  });

  const payload = { community_setting: updates };

  return reactQueryPatch(url, payload);
};

export const useUpdateCommunitySetting = ({
  onMutate = noop,
  onSuccess = noop,
  onError = noop,
  onSettled = noop,
} = {}) => {
  const { refetch } = usePunditUserContext();
  const queryClient = useQueryClient();

  return useMutation(updateCommunitySettings, {
    onMutate: async ({ data }) => {
      const previousData = queryClient.getQueryData(REQUEST_KEY);
      queryClient.setQueryData(REQUEST_KEY, data);
      onMutate && onMutate();
      return { previousData, data };
    },
    onSuccess: async data => {
      await refetch();
      queryClient.setQueryData(REQUEST_KEY, data);
      onSuccess && onSuccess();
    },
    onError: onError,
    onSettled: onSettled,
  });
};
