import { Suspense } from "react";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { PageLoader } from "@circle-react/pages/PageLoader";

const MarketingEmailsUnsubscribeComponent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "MarketingEmailsUnsubscribePage" */ "@circle-react/components/MarketingEmails/Unsubscribe"
    ),
);

export const MarketingEmailsUnsubscribePage = () => (
  <Suspense fallback={<PageLoader />}>
    <MarketingEmailsUnsubscribeComponent />
  </Suspense>
);
