import { Fragment } from "react";
import { SpaceAccessManagement } from "@circle-react/components/Spaces/SpaceAccessManagement/";
import { useSpaceAccessManagementContext } from "@circle-react/components/Spaces/SpaceAccessManagement/SpaceAccessManagementContext";
import type {
  SpaceGroupOption,
  SpaceOption,
} from "@circle-react/components/Spaces/SpaceAccessManagement/SpaceAccessManagementContext";
import { useMdScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { ChildMobileItem } from "../Item/ChildMobileItem";
import { ParentMobileItem } from "../Item/ParentMobileItem";

interface ListMobileProp {
  shouldShowAddParentToggle?: boolean;
  onAddParent?: (action: () => void, object: SpaceGroupOption) => void;
  onAddChild?: (action: () => void, object: SpaceOption) => void;
  onRemoveParent?: (action: () => void, object: SpaceGroupOption) => void;
  onRemoveChild?: (action: () => void, object: SpaceOption) => void;
}

export const ListMobile = ({
  shouldShowAddParentToggle = true,
  onAddParent,
  onAddChild,
  onRemoveParent,
  onRemoveChild,
}: ListMobileProp) => {
  const { options } = useSpaceAccessManagementContext();
  const isMdOrLargerScreen = useMdScreenMediaQuery();

  if (isMdOrLargerScreen) {
    return null;
  }

  return (
    <SpaceAccessManagement.List className="md:hidden">
      {options.map(spaceGroup => (
        <Fragment key={spaceGroup.id}>
          <ParentMobileItem
            isToggleEnabled={shouldShowAddParentToggle}
            key={`list-mobile-${spaceGroup.id}`}
            spaceGroup={spaceGroup}
            onAddParent={onAddParent}
            onRemoveParent={onRemoveParent}
          />
          {spaceGroup.spaces.map(space => (
            <ChildMobileItem
              key={`list-mobile-${spaceGroup.id}-${space.id}`}
              spaceGroupId={spaceGroup.id}
              space={space}
              onAddChild={onAddChild}
              onRemoveChild={onRemoveChild}
            />
          ))}
        </Fragment>
      ))}
    </SpaceAccessManagement.List>
  );
};
