import { useGetMarketingEmailAddressSettings } from "./useGetMarketingEmailAddressSettings";

export const useAreMarketingSettingsAvailable = () => {
  const { data: settings, isLoading } = useGetMarketingEmailAddressSettings();

  return {
    areMarketingSettingsAvailable: Boolean(settings?.id),
    isLoading,
  };
};
