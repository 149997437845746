import { useCallback, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { t } from "i18n-js";
import { useHistory } from "react-router-dom";
import {
  isUsingTiptapEditor,
  isUsingTrixEditor,
} from "@circle-react/helpers/postHelpers";
import { supportWidgetPaths } from "@circle-react/helpers/urlHelpers";
import { DisplayTrixContent } from "@circle-react-uikit/DisplayTrixContent";
import { TipTapBlockEditor } from "@circle-react-uikit/TipTapBlockEditor";
import { Typography } from "@circle-react-uikit/Typography";
import { HeaderIconButton } from "../../HeaderIconButton";
import { SkeletonLoader } from "../../SkeletonLoader";
import { CIRCLE_KB_URL } from "../../constants";
import { useShowPost } from "../../queries";
import { FeedbackSection } from "./FeedbackSection";
import { OpenInNewWindowLink } from "./OpenInNewWindowLink";

export const PostView = ({ id }) => {
  const trixRef = useRef(null);
  const history = useHistory();
  const { isError, isLoading, data: post } = useShowPost(id);
  const urls = ["help-en.circle.so", "help-pt.circle.so", "pt-help.circle.so"];
  let postKbUrl = post?.post_url || CIRCLE_KB_URL;
  urls.forEach(url => {
    if (postKbUrl.includes(url)) {
      postKbUrl = postKbUrl.replace(url, "help.circle.so");
    }
  });

  const handleClick = useCallback(
    event => {
      event.preventDefault();
      event.stopPropagation();

      const postId = event.currentTarget.getAttribute("data-post-id");
      history.push(supportWidgetPaths.showPost(postId));
    },
    [history],
  );
  const transformLinks = useCallback(
    ({ containerElement }) => {
      if (post) {
        containerElement
          .querySelectorAll(".trix-content a")
          .forEach(element => {
            if (post.urls_hash[element.href]) {
              element.setAttribute(
                "data-post-id",
                post.urls_hash[element.href],
              );
            } else {
              element.setAttribute("target", "_blank");
              element.setAttribute("rel", "noreferrer");
            }
          });

        containerElement
          .querySelectorAll(".trix-content a[data-post-id]")
          .forEach(element => {
            element.addEventListener("click", handleClick);
          });

        // Store ref to the Trix content, so we can remove event handlers later
        trixRef.current = containerElement;
      }
    },
    [post, handleClick],
  );

  // Remove click handlers
  useEffect(
    () => () => {
      if (trixRef.current) {
        trixRef.current
          .querySelectorAll(".trix-content a[data-post-id]")
          .forEach(element => {
            element.removeEventListener("click", handleClick);
          });
      }
    },
    [handleClick],
  );

  if (isLoading) {
    return (
      <>
        <SkeletonLoader rows={1} height="52px" wrapperClassName="px-0 mb-4" />
        <SkeletonLoader
          rows={1}
          height="150px"
          wrapperClassName="px-0 space-y-0"
        />
        <SkeletonLoader rows={1} height="34px" wrapperClassName="my-4" />
        <SkeletonLoader rows={6} />
      </>
    );
  }

  if (isError) {
    return (
      <div className="flex flex-col">
        <div className="support-widget-post__header border-primary bg-primary sticky top-0 z-10 flex h-[52px] shrink-0 items-center justify-between border-b px-3">
          <HeaderIconButton type="back" />
          <div className="flex items-center">
            <HeaderIconButton type="expand" />
          </div>
        </div>
        <div className="support-widget-post__body grow">
          <div className="mx-6 my-3 max-h-full">
            {t("support_widget.post_not_found")}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <div className="support-widget-post__header border-primary bg-primary sticky top-0 z-10 flex h-[52px] shrink-0 items-center justify-between border-b px-3">
        <HeaderIconButton type="back" />
        <div className="flex items-center">
          <HeaderIconButton
            href={postKbUrl}
            icon="share-support-widget"
            size={16}
          />
          <HeaderIconButton type="expand" />
        </div>
      </div>
      <div className="support-widget-post__body !mb-0 grow">
        {post.cover_image_url && (
          <div>
            <img loading="lazy" alt="" src={post.cover_image_url} />
          </div>
        )}
        <div className="mx-6 my-4">
          <Typography.TitleLg>
            <a
              className="!text-dark transition-colors duration-150 ease-linear hover:!text-[#4262F0]"
              href={postKbUrl}
              target="_blank"
              rel="noreferrer noopener"
            >
              {post.name}
            </a>
          </Typography.TitleLg>
        </div>
        <div className="mx-6 my-3 flex max-h-full flex-col gap-2">
          {isUsingTrixEditor(post) && post.body_trix_content && (
            <DisplayTrixContent
              content={post.body_trix_content}
              transformLinks={transformLinks}
            />
          )}
          {isUsingTiptapEditor(post) && post.tiptap_body && (
            <TipTapBlockEditor
              readOnly
              value={post.tiptap_body.body}
              sgidToObjectMap={post.tiptap_body.sgids_to_object_map}
              inlineAttachments={post.tiptap_body.inline_attachments}
            />
          )}
          <OpenInNewWindowLink postUrl={postKbUrl} />
          {post.space_slug !== "tutorials" && (
            <>
              <div className="border-primary mb-0 grow border-b pt-2" />
              <FeedbackSection post={post} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

PostView.propTypes = {
  id: PropTypes.string,
};
