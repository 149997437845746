import { useState } from "react";
import type { ReactNode } from "react";
import classNames from "classnames";
import { canCreateSpaces } from "@circle-react/helpers/spaceGroupHelpers";
import { spaceGroupPaths } from "@circle-react/helpers/urlHelpers";
import { useAnyHoverMediaQuery } from "@circle-react/hooks/useMediaQuery";
import type { SpaceGroup } from "@circle-react/types/SpaceGroup";
import { AddOptions } from "./AddOptions";
import { SpaceGroupName } from "./SpaceGroupName";
import { SpaceGroupOptionsDropdown } from "./SpaceGroupOptionsDropdown";
import { SpaceGroupViewWrapper } from "./SpaceGroupViewWrapper";

export interface SpaceGroupViewProps {
  canManage?: boolean;
  children: ReactNode;
  id: string;
  isAdmin?: boolean;
  isCollapsed?: boolean;
  isDragging?: boolean;
  listId: string;
  onAddMembersClick?: () => void;
  onCollapseToggle?: () => void;
  onCreateSpaceClick?: () => void;
  onCreateSpaceGroupClick?: () => void;
  onEditSpaceGroupClick?: () => void;
  position?: number;
  shouldDisplayOptions?: boolean;
  spaceGroup: SpaceGroup;
}

export const SpaceGroupView = ({
  canManage = false,
  children,
  id,
  isAdmin = false,
  isCollapsed = false,
  isDragging = false,
  listId,
  onAddMembersClick = () => {},
  onCollapseToggle = () => {},
  onCreateSpaceClick = () => {},
  onCreateSpaceGroupClick = () => {},
  onEditSpaceGroupClick = () => {},
  position,
  shouldDisplayOptions = true,
  spaceGroup,
}: SpaceGroupViewProps) => {
  const canHover = useAnyHoverMediaQuery();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <div draggable="false" id={id} className="group relative">
      <SpaceGroupViewWrapper
        onCollapseToggle={onCollapseToggle}
        isDragging={isDragging}
        isCollapsed={isCollapsed}
        listId={listId}
      >
        <SpaceGroupName
          isDropdownOpen={isDropdownOpen}
          canManage={canManage}
          spaceGroup={spaceGroup}
          isCollapsed={isCollapsed}
        />
      </SpaceGroupViewWrapper>
      {shouldDisplayOptions && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions -- onClick only used to prevent propagation
        <div
          className={classNames(
            "text-c-sidebar absolute right-[1rem] top-[0.375rem] ml-1 flex gap-1",
            {
              "invisible group-focus-within:visible group-hover:visible":
                canHover && !isDropdownOpen,
            },
          )}
          onClick={event => {
            event.preventDefault();
            event.stopPropagation();
          }}
          data-testid="space-group-actions"
        >
          <SpaceGroupOptionsDropdown
            onAddMembersClick={onAddMembersClick}
            onEditSpaceGroupClick={onEditSpaceGroupClick}
            seeMembersLink={spaceGroupPaths.members({
              spaceGroupSlug: spaceGroup.slug,
            })}
            seeSpacesLink={spaceGroupPaths.show({
              spaceGroupSlug: spaceGroup.slug,
            })}
            onToggle={setIsDropdownOpen}
            canManage={canManage}
          />
          {(canCreateSpaces({ spaceGroup }) || isAdmin) && (
            <AddOptions
              position={position}
              isAdmin={isAdmin}
              onCreateSpace={onCreateSpaceClick}
              onCreateSpaceGroup={onCreateSpaceGroupClick}
              canCreateSpaces={canCreateSpaces({ spaceGroup })}
              onToggle={setIsDropdownOpen}
            />
          )}
        </div>
      )}
      {!isCollapsed && children}
    </div>
  );
};
