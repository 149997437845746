import { t } from "i18n-js";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { Button } from "@circle-react-shared/uikit/Button";
import { Form } from "@circle-react-shared/uikit/Form";
import { SanitizeHTMLString } from "@circle-react-shared/uikit/SanitizeContent";
import { Modal } from "@circle-react-uikit/ModalV2";
import { useActivationConfirmationForm } from "./useActivationConfirmationForm";

export const ActivationConfirmationModalComponent = () => {
  const modal = useModal();
  const { defaultValues, handleSubmit, isLoading } =
    useActivationConfirmationForm();

  return (
    <Modal
      isOpen={modal.visible}
      onClose={modal.hide}
      title={t("activation_confirmation_modal.title")}
    >
      <Modal.Overlay />
      <Modal.Content size="lg">
        <Form onSubmit={handleSubmit} defaultValues={defaultValues}>
          <Modal.Header>
            <Modal.Title size="md">
              {t("activation_confirmation_modal.title")}
            </Modal.Title>
            <Modal.HeaderActions>
              <Modal.CloseButton onClick={modal.hide} />
            </Modal.HeaderActions>
          </Modal.Header>
          <Modal.Body>
            <SanitizeHTMLString
              content={t("activation_confirmation_modal.body")}
              className="text-default text-base leading-6 tracking-tighter"
            />
            <Form.Item
              id="activate-gamification"
              name="activate_gamification"
              translationRoot="activation_confirmation_modal"
              hideDescription
              hideBorder
              inlineReverse
            >
              <Form.ToggleSwitch name="activate_gamification" variant="small" />
            </Form.Item>
          </Modal.Body>
          <Modal.Footer className="flex w-full justify-end border-t">
            <Button variant="circle" disabled={isLoading}>
              {t("activation_confirmation_modal.cta_text")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

const ActivationConfirmationModal = ModalManager.create(() => (
  <ActivationConfirmationModalComponent />
));

export const useActivationConfirmationModal = () =>
  useModal(ActivationConfirmationModal);
