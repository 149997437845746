import { AllAccessToggle } from "./components/AllAccessToggle";
import { Body } from "./components/Body";
import { EmptyResult } from "./components/EmptyResult";
import { Header } from "./components/Header";
import { List } from "./components/List/List";
import { ListAccessGranted } from "./components/List/ListAccessGranted";
import { ListContainer } from "./components/List/ListContainer";
import { ListMobile } from "./components/List/ListMobile";
import { ListNoAccess } from "./components/List/ListNoAccess";
import { ListHeader } from "./components/ListHeader/ListHeader";
import { ListHeaderColumn } from "./components/ListHeader/ListHeaderColumn";
import { ListHeaderColumnAccessGranted } from "./components/ListHeader/ListHeaderColumnAccessGranted";
import { ListHeaderColumnNoAccess } from "./components/ListHeader/ListHeaderColumnNoAccess";
import { Root } from "./components/Root";
import { SearchBar } from "./components/SearchBar";

export const SpaceAccessManagement = Object.assign(Root, {
  Header,
  SearchBar,
  AllAccessToggle,
  Body,

  ListHeader,
  ListHeaderColumn,
  ListHeaderColumnNoAccess,
  ListHeaderColumnAccessGranted,

  List,
  ListContainer,
  ListNoAccess,
  ListAccessGranted,
  ListMobile,

  EmptyResult,
});
