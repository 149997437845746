import { createContext, useContext } from "react";
import type { ReactNode } from "react";

export interface ChangePlanContextProps {
  subscription?: any;
  isManagingMember?: boolean;
}

const ChangePlanContext = createContext<ChangePlanContextProps>({});
ChangePlanContext.displayName = "ChangePlanContext";

export interface ChangePlanProviderProps {
  subscription: any;
  isManagingMember?: boolean;
  children: ReactNode;
}

export const ChangePlanContextProvider = ({
  subscription,
  isManagingMember,
  children,
}: ChangePlanProviderProps) => {
  const valuesToStore = {
    subscription,
    isManagingMember: Boolean(isManagingMember),
  };

  return (
    <ChangePlanContext.Provider value={valuesToStore}>
      {children}
    </ChangePlanContext.Provider>
  );
};

export const useChangePlanContext = () => useContext(ChangePlanContext);
