import { useEffect, useState } from "react";
import { ParticipantEvent } from "livekit-client";
import { useLivekitContext } from "../../VideoProvider/LivekitProvider";
import { parseMetadata } from "./helpers";
import { useLivekitPublishMetadata } from "./useLivekitPublishMetadata";

export const useLivekitLocalMetadata = () => {
  const { localParticipant: participant } = useLivekitContext();
  const [metadata, setMetadata] = useState(
    parseMetadata(participant?.metadata),
  );
  const publishMetadata = useLivekitPublishMetadata(participant);

  useEffect(() => {
    const onMetadataChanged = prev => {
      // The isHandRaised metadata param is only streamed but this is not persisted.
      // More info here:
      //  - https://docs.livekit.io/client/data-messages/#data-messages
      //  - https://github.com/livekit/client-sdk-js/issues/148
      // This block avoids override the isHandRaised param with the persisted metadata
      const prevMetadata = JSON.parse(prev || "{}");
      const currMetadata = JSON.parse(participant.metadata || "{}");
      if (!("isHandRaised" in currMetadata)) {
        return setParticipantMetadata({
          ...currMetadata,
          isHandRaised: prevMetadata.isHandRaised,
        });
      }

      if (participant.metadata) {
        setMetadata(parseMetadata(participant.metadata));
      }
    };

    if (participant) {
      participant.on(
        ParticipantEvent.ParticipantMetadataChanged,
        onMetadataChanged,
      );
    }

    return () => {
      if (participant) {
        participant.off(
          ParticipantEvent.ParticipantMetadataChanged,
          onMetadataChanged,
        );
      }
    };
  }, [participant]);

  const setParticipantMetadata = async newMetadata => {
    participant?.setMetadata(JSON.stringify({ ...metadata, ...newMetadata }));
    await publishMetadata(newMetadata);
  };

  return {
    metadata,
    setMetadata: setParticipantMetadata,
  };
};
