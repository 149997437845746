import useMousetrap from "react-hook-mousetrap";
import { useLiveSettingsActions } from "../store";

export const useKeyboardShortcuts = () => {
  const { toggleCamera, toggleMicrophone } = useLiveSettingsActions();

  useMousetrap("shift+v", e => {
    e.preventDefault();
    toggleCamera();
  });

  useMousetrap("shift+m", e => {
    e.preventDefault();
    toggleMicrophone();
  });
};
