import { useCallback, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { selectMessages } from "@/react/hooks/chatsV2/store/selectors";
import { RailbarContextProvider } from "@circle-react/hooks/chatsV2";
import { useChatStore } from "@circle-react/hooks/chatsV2";
import { useCommunityInboxStore } from "@circle-react/hooks/chatsV2/store";
import { Loader } from "@circle-react-shared/uikit/Loader";
import { useMessageList } from "../hooks/useCommunityMessageList";
import { ACTIONS, useScroll } from "../hooks/useScroll";
import { ChatWindow } from "./ChatWindow";
import { Frame } from "./Frame";
import { Header } from "./Header";

interface BotChatRoomInterface {
  isDms?: boolean;
}

export const BotChatRoom = ({ isDms = false }: BotChatRoomInterface) => {
  const { id } = useParams<{ id: any }>();
  const { setFirstMessage, setScrollAction, adjustScroller } = useScroll();
  const { createMessage, fetchNextPage, hasNextPage, isFetching } =
    useMessageList({ id });
  const { setCurrentChatId } = useCommunityInboxStore();

  useEffect(() => {
    setCurrentChatId(id);
  }, [id, setCurrentChatId]);

  const messagesRef: any = useRef([]);
  messagesRef.current = useChatStore(selectMessages);

  const onFetchNextPage = useCallback(() => {
    setFirstMessage(messagesRef.current[0]);
    setScrollAction(ACTIONS.PAGINATION);
    void fetchNextPage();
  }, [messagesRef.current]);

  useEffect(() => {
    if (!isFetching && messagesRef.current.length > 0) adjustScroller();
  }, [messagesRef.current, isFetching, adjustScroller]);

  if (isFetching) {
    return (
      <Frame isDms={isDms}>
        <div className="flex h-full w-full items-center justify-center">
          <Loader />
        </div>
      </Frame>
    );
  }
  return (
    <RailbarContextProvider>
      <Frame isDms={isDms}>
        <div className="w-full">
          <Header chatRoomId={Number(id)} isDms={isDms} />
          <ChatWindow
            messages={messagesRef.current}
            createMessage={createMessage}
            isDms={isDms}
            fetchNextPage={onFetchNextPage}
            hasNextPage={Boolean(hasNextPage)}
            isLoading={isFetching}
          />
        </div>
        <div id="rail-bar-header" />
      </Frame>
    </RailbarContextProvider>
  );
};
