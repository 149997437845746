import { Icon } from "@circle-react-shared/Icon";
import type { IconType } from "@circle-react-shared/Icon";

interface LocationIconProps {
  icon: IconType;
}
export const LocationIcon = ({ icon }: LocationIconProps) => (
  <div className="bg-tertiary flex h-10 w-10 flex-none items-center justify-center rounded-lg">
    <Icon type={icon} size={20} className="text-dark !h-5 !w-5" />
  </div>
);
