export const calculateAddOnPrice = (quantity, price, unitSize, toggleable) => {
  let newPrice;

  if (toggleable) {
    newPrice = quantity ? price : 0;
  } else {
    newPrice = (quantity * price) / unitSize;
  }

  return newPrice;
};
