import { Typography } from "@circle-react-shared/uikit/Typography";

export interface PlanDetailsProps {
  planName: string;
  planPricing?: string;
}

export const PlanDetails = ({ planName, planPricing }: PlanDetailsProps) => (
  <div className="flex flex-row justify-between space-x-2 md:space-x-3">
    <Typography.LabelMd>{planName}</Typography.LabelMd>
    {planPricing && <Typography.LabelMd>{planPricing}</Typography.LabelMd>}
  </div>
);
