import type { ReactNode } from "react";
import { forwardRef } from "react";
import classNames from "classnames";

export interface ContainerProps {
  isExpanded?: boolean;
  isTruncationEnabled?: boolean;
  children: ReactNode;
}

export const Container = forwardRef<HTMLDivElement, ContainerProps>(
  ({ isExpanded = false, isTruncationEnabled = false, children }, ref) => (
    <div
      ref={ref}
      className={classNames("w-full", {
        "max-h-56 overflow-hidden": !isExpanded,
      })}
      style={{
        ...(isTruncationEnabled &&
          !isExpanded && {
            WebkitMaskImage: "linear-gradient(180deg,#000 80%,transparent)",
            maskImage: "linear-gradient(180deg,#000 80%,transparent)",
          }),
      }}
    >
      {children}
    </div>
  ),
);

Container.displayName = "Container";
