import { createContext, useContext, useMemo } from "react";
import PropTypes from "prop-types";
import { useRoom } from "@livekit/react-core";
import {
  AudioPresets,
  ConnectionState,
  ScreenSharePresets,
  VideoPresets,
} from "livekit-client";
import { useLiveStreamContextData } from "@circle-react/contexts/LiveStreams";
import { PARTICIPANT_ROLES } from "@circle-react/helpers/liveStreamRoomHelpers";
import { AudioPlayer } from "./AudioPlayer";

const LivekitContext = createContext({});
LivekitContext.displayName = "LivekitContext";

const addParticipantProperties = participants => {
  participants?.forEach(participant => {
    let roleName = PARTICIPANT_ROLES.HLS_SPECTATOR;
    let customerUserId = null;
    try {
      const metadata = JSON.parse(participant.metadata);
      if (metadata?.role) {
        roleName = metadata.role;
      }
      customerUserId = metadata?.user_id;
    } catch (e) {
      console.error("Could not parse participant metadata: ", e.message);
    }
    participant.roleName = roleName;
    participant.customerUserId = customerUserId;
    participant.id = participant.sid;
  });
};

export const LivekitProvider = ({ children }) => {
  const { videoInputId, audioInputId } = useLiveStreamContextData();
  const roomOptions = {
    adaptiveStream: true,
    dynacast: true,
    publishDefaults: {
      audioBitrate: AudioPresets.music,
      videoCodec: "vp8",
    },
    videoCaptureDefaults: {
      resolution: VideoPresets.h1080.resolution,
      deviceId: videoInputId,
    },
    audioCaptureDefaults: {
      deviceId: audioInputId,
    },
    screenShareEncoding: ScreenSharePresets.h1080fps30.encoding,
  };
  const {
    connect,
    participants,
    isConnecting,
    connectionState,
    room,
    audioTracks,
    metadata,
    error,
  } = useRoom(roomOptions);

  // Assign missing values for compatibility
  addParticipantProperties(participants);

  const localParticipant = room?.localParticipant;
  const localMetadata = useMemo(() => {
    if (!localParticipant?.metadata) {
      return {};
    }
    try {
      return JSON.parse(localParticipant.metadata);
    } catch (e) {
      console.error("Could not parse participant metadata: ", e.message);
      return {};
    }
  }, [localParticipant?.metadata]);

  const screenSharingParticipant = participants?.find(
    participant => participant.isScreenShareEnabled,
  );

  // Note: declaring value separately to make its contents explicit,
  // since it's easier to know what comes from this value this way.
  const value = {
    roomOptions,
    connect,
    participants,
    screenSharingParticipant,
    isSomeoneScreenSharing: !!screenSharingParticipant,
    localParticipant,
    localMetadata,
    isConnecting,
    isReconnecting: connectionState === ConnectionState.Reconnecting,
    isConnected: connectionState === ConnectionState.Connected,
    isDisconnected: connectionState === ConnectionState.Disconnected,
    room,
    audioTracks,
    metadata,
    error,
  };

  return (
    <>
      <LivekitContext.Provider value={value}>
        {children}
      </LivekitContext.Provider>
      {participants?.map(participant => (
        <AudioPlayer
          key={`participant-audio-${participant.id}`}
          participant={participant}
        />
      ))}
    </>
  );
};

LivekitProvider.propTypes = {
  children: PropTypes.any,
};

export const useLivekitContext = () => useContext(LivekitContext);
