import { t } from "i18n-js";
import { useMutation, useQuery } from "react-query";
import {
  reactQueryGet,
  reactQueryPut,
} from "@circle-react/helpers/backendRequestHelpers";
import {
  communitySwitcherPath,
  communitySwitcherReorderPath,
} from "@circle-react/helpers/urlHelpers";
import type { CommunitySwitcher } from "@circle-react/types/CommunitySwitcher";
import { useToast } from "@circle-react-shared/uikit/ToastV2";

export interface CommunitySwitcherData {
  communities: CommunitySwitcher[];
  token: string;
}

const fetchCommunitySwitcher = (): Promise<CommunitySwitcherData> =>
  reactQueryGet(communitySwitcherPath());

const reorderCommunities = (
  communities: CommunitySwitcher[],
): Promise<void> => {
  const communityOrder = communities.map(community => community.id);
  return reactQueryPut(communitySwitcherReorderPath(), {
    community_order: communityOrder,
  });
};

export const queryKey = ["COMMUNITY_SWITCHER"];

export const useCommunitySwitcher = () => {
  const {
    data,
    isLoading,
    refetch: refetchCommunities,
  } = useQuery(queryKey, fetchCommunitySwitcher);
  const toast = useToast();

  const { mutate: onSortCommunities, isLoading: isReorderingCommunities } =
    useMutation(reorderCommunities, {
      onSuccess: async () => {
        await refetchCommunities();
      },
      onError: () => {
        toast.error(t("community_switcher_reorder.reorder_error"));
      },
    });

  const token = data?.token || "";
  const communities = data?.communities || [];

  return {
    communities,
    token,
    isLoadingCommunities: isLoading,
    isReorderingCommunities,
    refetchCommunities,
    onSortCommunities,
  };
};
