import { useQuery } from "react-query";
import { eventApi } from "@circle-react/api/eventApi";

export const useFetchEvent = ({ eventId, ...options }) =>
  useQuery(
    ["event", eventId],
    () =>
      eventApi.fetchEvent({
        id: eventId,
      }),
    options,
  );
