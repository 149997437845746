import { useEffect } from "react";
import useManageMemberQueryParamsStore from "@/react/components/SettingsApp/ManageMembers/TabContent/Tables/WorkflowActionsDropdown/useManageMemberQueryParamsStore";
import { useBlockWithConnectorData } from "@circle-react-shared/BlockWithConnector/BlockWithConnectorDataProvider";
import { Action } from "./Action";
import { ActionProvider } from "./ActionContext";
import { useActions } from "./useActions";

export const ActionRule = () => {
  const {
    itemsIds,
    formActions,
    isFirstRender,
    addActionAfterPosition,
    removeActionAtPosition,
  } = useActions();

  const { manageMemberQueryParams } = useManageMemberQueryParamsStore();
  const { setActiveItemId } = useBlockWithConnectorData();

  useEffect(() => {
    if (manageMemberQueryParams?.queryParams && !isFirstRender) {
      setActiveItemId(itemsIds.at(-1).id);
    }
  }, [isFirstRender]);

  return (
    <>
      {formActions.map((action: any, index: any) => (
        <ActionProvider key={action.id} action={action} index={index}>
          <Action
            onAddClick={async index => {
              await addActionAfterPosition({ index });
            }}
            onDeleteClick={removeActionAtPosition}
            hasBottomConnection={index !== formActions.length - 1}
          />
        </ActionProvider>
      ))}
    </>
  );
};
