import { t } from "i18n-js";
import type { SortType } from "@circle-react/components/Modals/SearchResultsModal/SortContext";
import {
  sortTypeMap,
  useSortContext,
} from "@circle-react/components/Modals/SearchResultsModal/SortContext";
import { Dropdown } from "@circle-react-shared/uikit/Dropdown";

export const SORT_TYPES_MAP: any = {
  posts: [
    sortTypeMap.Relevance,
    sortTypeMap.Latest,
    sortTypeMap.Oldest,
    sortTypeMap.Popular,
    sortTypeMap.Likes,
    sortTypeMap.Alphabetical,
    sortTypeMap.NewActivity,
  ],
  comments: [
    sortTypeMap.Relevance,
    sortTypeMap.Latest,
    sortTypeMap.Oldest,
    sortTypeMap.Likes,
    sortTypeMap.Alphabetical,
  ],
  events: [
    sortTypeMap.Relevance,
    sortTypeMap.Latest,
    sortTypeMap.Oldest,
    sortTypeMap.Popular,
    sortTypeMap.Likes,
    sortTypeMap.Alphabetical,
  ],
  lessons: [
    sortTypeMap.Relevance,
    sortTypeMap.Latest,
    sortTypeMap.Oldest,
    sortTypeMap.Alphabetical,
  ],
  members: [
    sortTypeMap.Relevance,
    sortTypeMap.Latest,
    sortTypeMap.Oldest,
    sortTypeMap.Alphabetical,
  ],
};

const isSortType = (value: any): value is SortType =>
  Object.values(sortTypeMap).includes(value);

export const Sort = ({ type }: any) => {
  const { sort, setSort } = useSortContext();
  const SORT_TYPES = SORT_TYPES_MAP[type];

  if (!SORT_TYPES) {
    return null;
  }
  return (
    <div className="flex flex-1 items-center justify-end">
      <Dropdown
        options={SORT_TYPES.map((sortOption: any) => ({
          value: sortOption,
          label: t(`sorting_options.${sortOption}`),
        }))}
        activeOptionValue={sort[type]}
        onChange={newSort => isSortType(newSort) && setSort(type, newSort)}
      />
    </div>
  );
};
