import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { LessonItem } from "./LessonItem";

export interface DraggableLessonItemProps {
  lesson: any;
  section: any;
  space: any;
  [key: string]: any;
}

export const DraggableLessonItem = ({
  lesson,
  section,
  space,
  ...rest
}: DraggableLessonItemProps) => {
  const {
    attributes,
    listeners,
    setActivatorNodeRef,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: lesson.sortId,
    data: {
      dragType: "lesson",
      item: lesson,
      sectionSortId: section.sortId,
    },
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <LessonItem
      isDragging={isDragging}
      ref={setNodeRef}
      lesson={lesson}
      section={section}
      space={space}
      tableRowProps={{
        style,
        ...attributes,
      }}
      dragHandleProps={{
        listeners,
        setActivatorNodeRef,
      }}
      {...rest}
    />
  );
};
