import classnames from "classnames";
import { LabelSm } from "@circle-react-shared/uikit/Typography/components/Label/LabelSm";

export interface ChatButtonProps {
  label: string;
  sublabel: string;
  event: string;
  value: string;
  selected: string | null;
  setSelected: (label: string | null) => void;
  process: (data: { event: string; value: string }) => void;
}

export const ChatButton = ({
  label,
  sublabel,
  event,
  value,
  selected,
  setSelected,
  process,
}: ChatButtonProps) => {
  const onClick = () => {
    if (selected === null) {
      setSelected(label);
      process({ event, value });
    }
  };

  return (
    <button
      type="button"
      className={classnames(
        "border-primary rounded-full border px-4 py-2 text-sm font-medium",
        {
          "hover:border-secondary cursor-pointer": selected === null,
          "border-secondary bg-gray-100 dark:bg-gray-700": selected === label,
          "cursor-not-allowed": selected !== null,
          "w-full": sublabel,
        },
      )}
      onClick={onClick}
    >
      <LabelSm weight="medium">{label}</LabelSm>
      {sublabel && (
        <>
          {` • `}
          <LabelSm weight="medium" color="text-gray-400">
            {sublabel}
          </LabelSm>
        </>
      )}
    </button>
  );
};
