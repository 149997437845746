import { useMemo } from "react";
import { t } from "i18n-js";
import { useSmScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import type { Member } from "../types";

interface UseMembersInfiniteTableProps {
  dataLength: number;
  members: Array<Member>;
  searchTerm: string;
  shouldHideAddMemberButton: boolean;
}

export const useMembersInfiniteTable = ({
  dataLength,
  searchTerm,
  members,
  shouldHideAddMemberButton,
}: UseMembersInfiniteTableProps) => {
  const isSpaceMembersEmpty = dataLength === 0;
  const isResultEmpty = members.length === 0 && !searchTerm;
  const isResultEmptyWithSearch = members.length === 0 && searchTerm;
  const shouldShowAddMember = !searchTerm && !shouldHideAddMemberButton;
  const isSmScreen = useSmScreenMediaQuery();

  const firstMember = members[0];
  const columnsToRender = useMemo(() => {
    const shouldRenderPaywallColumn = firstMember?.currentSpaceMember;
    const shouldRenderJoinedAtColumn =
      firstMember?.currentSpaceMember && !isSmScreen;
    const shouldRenderLastActiveColumn = firstMember?.lastActive && !isSmScreen;

    return {
      member: {
        title: t("spaces.form.edit.members.columns.name"),
        key: "name",
        className: "w-80",
      },
      memberRole: {
        title: t("spaces.form.edit.members.columns.role"),
        key: "memberRole",
      },
      joinedAt: shouldRenderJoinedAtColumn
        ? {
            title: t("spaces.form.edit.members.columns.joined"),
            key: "joinedAt",
          }
        : null,
      paywall: shouldRenderPaywallColumn
        ? {
            title: t("spaces.form.edit.members.columns.paywall"),
            key: "paywall",
          }
        : null,
      lastActive: shouldRenderLastActiveColumn
        ? {
            title: t("spaces.form.edit.members.columns.last_active"),
            key: "lastActive",
          }
        : null,
      dropdown: {
        title: "",
        key: "dropdown",
        className: "w-12",
      },
    };
  }, [firstMember, isSmScreen]);

  return {
    isSpaceMembersEmpty,
    isResultEmpty,
    isResultEmptyWithSearch,
    shouldShowAddMember,
    columnsToRender,
  };
};
