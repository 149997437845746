import { t } from "i18n-js";
import invariant from "tiny-invariant";
import {
  dateFormats,
  formattedDateTime,
} from "@circle-react/helpers/dateTimeHelpers/formattedDateTime";
import { useBroadcastReport } from "@circle-react-shared/Emails/BroadcastReport/BroadcastReportContext";
import { ViewRecipientListButton } from "@circle-react-shared/Emails/BroadcastReport/LinkClicksStats/ViewRecipients/ViewRecipientListButton";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { RecipientCount } from "./RecipientCount";

const localeNamespace = "settings.workflows.view";

export const BroadcastMeta = () => {
  const { data } = useBroadcastReport();
  invariant(data, "Broadcast data must be present");
  const {
    subject = "",
    first_delivery_at: firstDeliveryAt = null,
    recipient_count: recipientCount = null,
  } = data;
  return (
    <div>
      <div className="mb-1 gap-y-1">
        <div className="flex gap-x-1">
          <Typography.LabelSm weight="semibold" color="text-default">
            {t([localeNamespace, "subject"])}
          </Typography.LabelSm>
          <Typography.LabelSm color="text-default">
            {subject}
          </Typography.LabelSm>
        </div>

        <div className="flex gap-x-1">
          <Typography.LabelSm weight="semibold" color="text-default" as="div">
            {t([localeNamespace, "first_delivery"])}
          </Typography.LabelSm>
          <Typography.LabelSm color="text-default" as="div">
            {firstDeliveryAt
              ? t([localeNamespace, "sent_on_date_time"], {
                  datetime: formattedDateTime({
                    dateTime: new Date(firstDeliveryAt),
                    format: dateFormats.short_date_at_short_time,
                  }),
                })
              : t("n/a")}
          </Typography.LabelSm>
        </div>
      </div>

      <div className="flex items-center">
        <ViewRecipientListButton
          recipient_type="all_recipients"
          className="text-dark flex items-center gap-x-1 decoration-current decoration-dashed underline-offset-2"
        >
          <RecipientCount recipientCount={recipientCount} />
        </ViewRecipientListButton>
      </div>
    </div>
  );
};
