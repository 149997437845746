import { useEffect } from "react";
import { flatten } from "lodash";
import { useInfiniteQuery, useMutation } from "react-query";
import { useCommunityInboxStore } from "@/react/hooks/chatsV2/store";
import {
  reactQueryGet,
  reactQueryPost,
} from "@circle-react/helpers/backendRequestHelpers";
import "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers/internalApi";
import type { Message } from "@circle-react/types/CommunityInbox/Message";
import { ACTIONS } from "./useScroll";

interface MessageListProps {
  id: number;
  params?: any;
}

export const useMessageList = ({
  id,
  params = { per_page: 20 },
}: MessageListProps) => {
  const { setMessages, appendMessage, setScrollAction, setCurrentChatId } =
    useCommunityInboxStore();
  const fetchMessages = (page: number) =>
    reactQueryGet(
      internalApi.communityInbox.fetchMessages({
        id,
        params: {
          page,
          ...params,
        },
      }),
    );

  useEffect(() => {
    setCurrentChatId(id);
  }, [id, setCurrentChatId]);

  const { isLoading, fetchNextPage, hasNextPage, isFetching } =
    useInfiniteQuery(
      ["community-inbox", "chat-room", "messages", id],
      ({ pageParam = 1 }) => fetchMessages(pageParam),
      {
        select: ({ pages = [] }) => ({
          pages: flatten(pages.map(page => page.records)),
          pageParams: pages.map(page => page.page),
          pagesList: pages,
        }),
        getNextPageParam: lastPage =>
          lastPage.has_next_page ? lastPage.page + 1 : undefined,
        onSuccess: data => {
          setMessages(data.pages);
        },
      },
    );

  const { mutateAsync: createMessage } = useMutation(
    (formValues: any) =>
      reactQueryPost(
        internalApi.communityInbox.createMessage({
          id,
        }),
        { ...formValues },
      ),
    {
      onSuccess: (message: Message) => {
        setScrollAction(ACTIONS.NEW_MESSAGE);
        appendMessage(message);
      },
      onError: err => {
        console.error("Error while creating message", err);
      },
    },
  );

  return {
    isMessagesLoading: isLoading,
    createMessage,
    hasNextPage,
    fetchNextPage,
    isFetching,
  };
};
