import PropTypes from "prop-types";
import { Typography } from "@circle-react/components/shared/uikit/Typography";
import { truncateNumber } from "../helpers";

export const ProgressBar = ({ usage, limit, color = "red", label }) => {
  const progressPercentage = `${
    limit ? Math.min((usage / limit) * 100, 100) : 0
  }%`;
  const style = {
    width: progressPercentage,
    backgroundColor: color,
  };

  const text = `${truncateNumber(usage)} / ${truncateNumber(limit)}`;

  return (
    <>
      <div className="relative mb-2 h-1 w-full rounded-sm bg-gray-300">
        <div className="absolute h-full rounded-sm" style={style} />
      </div>
      <div className="flex items-center justify-between">
        {label && <Typography.LabelXs>{label}</Typography.LabelXs>}
        <Typography.LabelXs>{text}</Typography.LabelXs>
      </div>
    </>
  );
};

ProgressBar.propTypes = {
  usage: PropTypes.number,
  limit: PropTypes.number,
  color: PropTypes.string,
  label: PropTypes.string,
};
