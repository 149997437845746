import { useFormContext } from "react-hook-form";

export const useGetMessage = (
  messageWithUntil: string,
  messageWithoutUntil: string,
) => {
  const { watch } = useFormContext();
  const endsOn = watch("ends_on");
  return endsOn === "on" ? messageWithUntil : messageWithoutUntil;
};
