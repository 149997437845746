import { t } from "i18n-js";
import { useFormContext } from "react-hook-form";
import { Button } from "@circle-react-shared/uikit/Button";
import { I18N_ROOT } from "./constants";

export const SaveChangesButton = () => {
  const {
    formState: { isSubmitting },
  } = useFormContext();

  return (
    <div className="text-right">
      <Button
        variant="circle"
        className="float-right"
        large
        disabled={isSubmitting}
      >
        {isSubmitting
          ? t([I18N_ROOT, "saving_changes"])
          : t([I18N_ROOT, "save_changes"])}
      </Button>
    </div>
  );
};
