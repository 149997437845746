import { useEffect, useState } from "react";
import { useRouterQueryParams } from "@circle-react/hooks/useRouterQueryParams";

export const useOnLoadInEditMode = ({
  enabled,
  key,
  onLoadInEditMode,
}: any) => {
  const { edit: editParam } = useRouterQueryParams();
  const [hasOnLoadBeenCalled, setHasOnLoadBeenCalled] = useState(false);

  useEffect(() => {
    setHasOnLoadBeenCalled(false);
  }, [key]);

  useEffect(() => {
    if (enabled && !hasOnLoadBeenCalled && editParam === "true") {
      onLoadInEditMode();

      setHasOnLoadBeenCalled(true);
    }
  }, [enabled, hasOnLoadBeenCalled, editParam, onLoadInEditMode]);
};
