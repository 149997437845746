import { useSpaceAccessManagementContext } from "@circle-react/components/Spaces/SpaceAccessManagement/SpaceAccessManagementContext";
import type { SpaceOption } from "../../../SpaceAccessManagementContext";

interface UseChildItemProp {
  space: SpaceOption;
  spaceGroupId: number;
  onAddChild?: (action: () => void, object: SpaceOption) => void;
  onRemoveChild?: (action: () => void, object: SpaceOption) => void;
}

export const useChildItem = ({
  space,
  spaceGroupId,
  onAddChild = action => {
    action();
  },
  onRemoveChild = action => {
    action();
  },
}: UseChildItemProp) => {
  const { id, name } = space;
  const {
    isSpaceSelected,
    addSpaces,
    getIsFromSearch,
    removeSpaceGroup,
    removeSpaces,
  } = useSpaceAccessManagementContext();

  const isChecked = isSpaceSelected(id);
  const addSpace = () => {
    addSpaces(id);
  };
  const isSpaceFound = getIsFromSearch(name);
  const onAddClick = () => {
    onAddChild(addSpace, space);
  };

  const removeSpaceAndSpaceGroup = () => {
    removeSpaceGroup(spaceGroupId);
    removeSpaces([id]);
  };
  const onRemoveClick = () => {
    onRemoveChild(removeSpaceAndSpaceGroup, space);
  };

  return {
    isChecked,
    isSpaceFound,
    onAddClick,
    onRemoveClick,
  };
};
