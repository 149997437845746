import { useMemo, useRef } from "react";
import type { HMSPeer, HMSPeerID } from "@100mslive/react-sdk";
import { selectSpeakers, useHMSStore } from "@100mslive/react-sdk";
import { keyBy, sortBy } from "lodash";
import { useHMSPeersList } from "./useHMSPeersList";

const DEFAULT_PAGE_SIZE = 8;

interface UseHMSSpeakerRotationOptions {
  pageSize?: number;
}

export const useHMSSpeakerRotation = (
  peers: HMSPeer[],
  { pageSize = DEFAULT_PAGE_SIZE }: UseHMSSpeakerRotationOptions = {},
) => {
  const firstPageRotationRef = useRef<HMSPeerID[]>([]);
  const { peersWithHandRaised } = useHMSPeersList();
  const speakersObj = useHMSStore(selectSpeakers);

  const orderedPeers = useMemo(() => {
    // Create helper object for easily accessing peers by id
    const peersById = keyBy(peers, "id");
    // Update firstPageRotation from peers since tracks may have changed
    const firstPageRotation = firstPageRotationRef.current.filter(
      id => peersById[id],
    );
    const speakerIds = Object.values(speakersObj).map(({ peerID }) => peerID);
    const peersWithHandRaisedIds = peersWithHandRaised
      .map(({ id }) => id)
      .slice(0, pageSize / 2);
    // Make sure speakers and peers with hand raised are part of the rotation
    peersWithHandRaisedIds.concat(speakerIds).forEach(peerID => {
      if (!firstPageRotation.includes(peerID)) {
        // Insert in first position if not part of speakers
        firstPageRotation.unshift(peerID);
      }
      if (firstPageRotation.length > pageSize) {
        // Remove last speaker
        firstPageRotation.pop();
      }
    });

    // Save for next render
    firstPageRotationRef.current = firstPageRotation;
    // Sort peers based on firstPageRotation
    const orderingMap = new Map(
      firstPageRotation.map((id, index) => [id, index + 1]),
    );
    return sortBy(peers, peer => orderingMap.get(peer.id) || Infinity);
  }, [speakersObj, peersWithHandRaised, peers, pageSize]);

  return {
    orderedPeers,
  };
};
