import { mapCurrencyAmountStrToInt } from "@circle-react/components/Paywalls/Admin/utils";
import { formatPaywallCurrencyAmount } from "@circle-react/helpers/paywalls/currencies";
import { paywallPriceTypes } from "@circle-react/types";
import { usePricingTabContext } from "../../../hooks/usePricingTabContext";
import type { PricingTabPriceListCellProps } from "../../types";

export const PricingTabPriceListCellPricingOneTime = ({
  row: { original: price },
}: PricingTabPriceListCellProps) => {
  const { price_type, amount } = price.price_properties;

  const { currency } = usePricingTabContext();

  if (price_type !== paywallPriceTypes.onetime) {
    return null;
  }

  const amountInCents = mapCurrencyAmountStrToInt(amount);
  const amountFormatted = formatPaywallCurrencyAmount(currency, amountInCents, {
    amountInCents: true,
    includeCurrencyCode: true,
  });

  return <>{amountFormatted}</>;
};
