import { EmailPreviewThumbnail } from "@circle-react-shared/EmailPreviewThumbnail";
import { BroadcastDataProvider } from "@circle-react-shared/Emails/BroadcastDataContext";
import { useCurrentAction } from "../../../ActionRule";
import { ViewEmailCollapsed } from "./ViewSendEmail/ViewEmailCollapsed";
import ViewEmailExcerpt from "./ViewSendEmail/ViewEmailExcerpt";
import type { SendEmailApiParams } from "./interfaces";

export function ViewSendEmailCollapsed() {
  const { action } = useCurrentAction();
  const { api_params: apiParams }: { api_params?: SendEmailApiParams } =
    action || {};
  const broadcastId = apiParams?.broadcast_id;

  if (!broadcastId) {
    return (
      <ViewEmailExcerpt
        internalName={apiParams?.internal_name}
        subject={apiParams?.subject}
        inlinePreviewComponent={<EmailPreviewThumbnail />}
      />
    );
  }

  return (
    <BroadcastDataProvider id={Number(broadcastId)}>
      <ViewEmailCollapsed />
    </BroadcastDataProvider>
  );
}
