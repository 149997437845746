import { t } from "i18n-js";
import { EntityResource } from "../EntityResource";
import type { EventOption } from "../types";
import { AnyPostSpecificSelector } from "./shared/AnyPostSpecificSelector";

export const postLiked: Readonly<EventOption> = {
  label: t("settings.workflows.form.events.post_liked.title"),
  value: "post_liked",
  group: t("settings.workflows.form.event_groups.posts_and_comments"),
  description: t("settings.workflows.form.events.post_liked.description"),
  formComponent: AnyPostSpecificSelector,
  viewComponent: EntityResource,
  icon: "post",
};
