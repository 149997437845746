import { useFormContext } from "react-hook-form";
import { usePunditUserContext } from "@circle-react/contexts";
import { useDebouncedValue } from "@circle-react/hooks/utils/useDebouncedValue";
import { SanitizeHTMLString } from "@circle-react-shared/uikit/SanitizeContent";
import { useGetFooterHtml } from "../../hooks/useGetFooterHtml";

export const FooterPreview = () => {
  const { currentCommunity: { root_url: rootUrl } = {} } =
    usePunditUserContext();

  const { watch } = useFormContext();
  const addressLine1 = watch("address_line_1");
  const addressCity = watch("address_city");
  const addressPostalCode = watch("address_postal_code");
  const addressCountryCode = watch("address_country");
  const addressState = watch("address_state");

  const footerHtmlParams = {
    rootUrl,
    addressLine1,
    addressCity,
    addressPostalCode,
    addressCountryCode,
    addressState,
  };

  const debouncedFooterHtmlParams = useDebouncedValue(footerHtmlParams, 500);
  const { data } = useGetFooterHtml(debouncedFooterHtmlParams);
  const { footer_html: footerHtml } = data || { footer_html: "" };

  return <SanitizeHTMLString content={footerHtml} />;
};
