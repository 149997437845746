import type { PropsWithChildren } from "react";
import { Icon } from "@circle-react-shared/Icon";
import type { IconType } from "@circle-react-shared/Icon/IconNames";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { ItemTitle } from "./ItemTitle";

interface ItemTitleWithIconProp {
  icon?: IconType;
  tooltipText?: string;
}

export const ItemTitleWithIcon = ({
  icon = undefined,
  tooltipText = undefined,
  children,
}: PropsWithChildren<ItemTitleWithIconProp>) => (
  <ItemTitle>
    <span className="flex h-4 w-4">
      {icon && tooltipText && (
        <TippyV2
          className="flex"
          placement="top"
          delay={300}
          content={tooltipText}
          interactive={false}
        >
          <Icon type={icon} size={16} className="text-default" />
        </TippyV2>
      )}
      {icon && !tooltipText && (
        <Icon type={icon} size={16} className="text-default" />
      )}
    </span>
    {children}
  </ItemTitle>
);
