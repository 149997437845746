import { t } from "i18n-js";
import { useQuery } from "react-query";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { BaseResource } from "../../BaseResource";

export const QuizResource = ({ resourceId }: { resourceId: number }) => {
  const { data: lesson, isLoading } = useQuery<{ name: string } | undefined>(
    internalApi.settings.courses.lessons.show({ id: resourceId }),
  );

  return (
    <BaseResource
      label={t("settings.workflows.view.resources.quiz")}
      value={lesson?.name}
      isLoading={isLoading}
    />
  );
};
