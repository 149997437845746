import { useQuery } from "react-query";
import { useLiveStreamContextData } from "@circle-react/contexts/LiveStreams";
import { canManageRoom } from "@circle-react/helpers/liveStreamRoomHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { useRoom } from "../hooks";

export const useCohostsList = () => {
  const room = useRoom();
  const { currentParticipant } = useLiveStreamContextData();
  const canFetchCohosts = !!currentParticipant && canManageRoom(room);
  const {
    data,
    isLoading: isLoadingCohosts,
    refetch,
  } = useQuery(
    internalApi.liveStreams.getCohosts({
      roomId: room?.id,
    }),
    {
      enabled: canFetchCohosts,
    },
  );
  const refetchCohosts = () => {
    if (canFetchCohosts) {
      refetch();
    }
  };
  const cohosts = data?.records || [];

  return {
    cohosts,
    isLoadingCohosts,
    refetchCohosts,
  };
};
