import { CurrentPostContextProvider } from "@circle-react/contexts";
import { usePostResource } from "@circle-react/hooks/posts/usePostResource";
import { Loader } from "@circle-react-uikit/Loader";

export default function CurrentPostLoader({
  currentSpaceId,
  postSlug,
  children,
  loaderVariant = "fullscreen",
  ...passThroughProps
}) {
  const { post, isLoading } = usePostResource({
    spaceId: currentSpaceId,
    postSlug,
  });
  if (isLoading) {
    return <Loader variant={loaderVariant} />;
  }
  return (
    <CurrentPostContextProvider post={post} {...passThroughProps}>
      {children}
    </CurrentPostContextProvider>
  );
}
