import { t } from "i18n-js";
import { useQuery } from "react-query";
import { marketingEmailAddressSettingsApi } from "@circle-react/api/marketingEmailAddressSettingsApi";
import { useToast } from "@circle-react-shared/uikit/ToastV2";

export const getMarketingEmailAddressFooterHtmlKey = (
  params: UseGetFooterHtmlProps,
) => ["marketing-email-address-settings", "footer-html", params];

interface UseGetFooterHtmlProps {
  rootUrl: string;
  addressLine1: string;
  addressCity: string;
  addressPostalCode: string;
  addressCountryCode: string;
  addressState: string;
}

interface FooterHtmlProps {
  footer_html: string;
}

export const useGetFooterHtml = (params: UseGetFooterHtmlProps) => {
  const toastr = useToast();

  return useQuery<FooterHtmlProps>({
    queryKey: getMarketingEmailAddressFooterHtmlKey(params),
    queryFn: () =>
      marketingEmailAddressSettingsApi.footerHtml({
        root_url: params.rootUrl,
        address_line_1: params.addressLine1,
        address_city: params.addressCity,
        address_postal_code: params.addressPostalCode,
        address_country: params.addressCountryCode,
        address_state: params.addressState || "",
      }),
    onError: () => toastr.error(t("error")),
    keepPreviousData: true,
  });
};
