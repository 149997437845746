import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { MAX_TOPICS_PER_SPACE } from "@circle-react/components/QuickPostV2/Content/TopicsPicker";
import { FormTopicsPicker } from "@circle-react/components/QuickPostV2/Content/TopicsPicker/FormTopicsPicker";

export const CustomizeTopicsForm = ({ onChange, sortable = true }: any) => {
  const {
    formState: { dirtyFields },
  } = useFormContext();

  useEffect(() => {
    if ("topics" in dirtyFields) {
      onChange(true);
    }
  }, [dirtyFields, dirtyFields.topics]);

  return (
    <FormTopicsPicker
      containerClassName="bg-primary border-primary rounded-md border"
      sortable={sortable}
      creatable
      maxTopicsAllowed={MAX_TOPICS_PER_SPACE}
      name="topics"
    />
  );
};
