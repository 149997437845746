import { useState } from "react";
import { t } from "i18n-js";
import { SettingsHeader } from "@/react/components/SettingsApp/SettingsHeader";
import { Tab } from "@circle-react-uikit/TabV2";
import { Snippets } from "./Snippets";
import { Spaces } from "./Spaces";

export const TABS = {
  TAB_COMMUNITY: "tab_community",
  TAB_CUSTOM: "tab_custom",
};
export const TABS_LABELS = {
  [TABS.TAB_COMMUNITY]: t("settings.community_bot.knowledge.tab_community"),
  [TABS.TAB_CUSTOM]: t("settings.community_bot.knowledge.tab_custom"),
};
const tabNames = Object.values(TABS);

export const CommunityBotKnowledge = () => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(
    tabNames.indexOf(TABS.TAB_COMMUNITY),
  );

  return (
    <div className="bg-primary h-full">
      <Tab.Group
        selectedIndex={selectedTabIndex}
        onChange={tabIndex => setSelectedTabIndex(tabIndex)}
        className="!h-auto overflow-visible"
      >
        <SettingsHeader
          pageTitle={t("settings.community_bot.knowledge.title")}
          hasBorder={false}
        >
          <Tab.ListWrapper>
            <Tab.List>
              {tabNames.map(tab => (
                <Tab key={tab}>{TABS_LABELS[tab]}</Tab>
              ))}
            </Tab.List>
          </Tab.ListWrapper>
        </SettingsHeader>
        <Tab.Panels>
          <Tab.Panel>
            <Spaces />
          </Tab.Panel>
          <Tab.Panel>
            <Snippets />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};
