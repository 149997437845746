import { t } from "i18n-js";
import { useQuery } from "react-query";
import { spaceApi } from "@/react/api";
import { useToast } from "@circle-react-uikit/ToastV2";

const i18nRoot = "settings.workflows.form";

export const useFetchSpaces = (
  { per_page, ...options } = {
    per_page: 100,
  },
) => {
  const { error } = useToast();

  return useQuery(
    ["workflows", "list", "spaces"],
    () =>
      spaceApi.list({
        params: { per_page },
      }),
    {
      onError: () => error(t([i18nRoot, "errors", "spaces", "fetch_failed"])),
    },
    options,
  );
};
