import PropTypes from "prop-types";
import { isFunction } from "lodash";
import { useIsWorkflowsV2Enabled } from "@circle-react/hooks/useIsWorkflowsV2Enabled";
import { Button } from "@circle-react-uikit/Button";

export const ContinueButton = ({ onClick, children, ...rest }) => {
  const isWorkflowsV2Enabled = useIsWorkflowsV2Enabled();
  return (
    <Button
      large={!isWorkflowsV2Enabled}
      variant="circle"
      type="button"
      onClick={() => {
        isFunction(onClick) && onClick();
      }}
      {...rest}
    >
      {children}
    </Button>
  );
};

ContinueButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node,
};
