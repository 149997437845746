import { t } from "i18n-js";
import { compact } from "lodash";
import { Filter, useFilterUrl } from "@circle-react-shared/Filter";
import { MultipleInputs } from "./MultipleInputs";
import {
  localeRoot as DEFAULT_LOCALE_ROOT,
  queryParamsKey as DEFAULT_QUERY_PARAMS_KEY,
} from "./constants";

export const AmountFilterComponent = ({
  isInitialOpen,
  changeUrl = true,
  queryParamsKey = DEFAULT_QUERY_PARAMS_KEY,
  localeRoot = DEFAULT_LOCALE_ROOT,
}: any) => {
  const { queryParams, updateUrl } = useFilterUrl();

  const lteValue = queryParams[queryParamsKey.lte];
  const gteValue = queryParams[queryParamsKey.gte];

  const lteValueNumber = Number(lteValue) || 0;
  const gteValueNumber = Number(gteValue) || 0;

  const isNotANumber = isNaN(lteValueNumber) || isNaN(gteValueNumber);
  const selectedText = isNotANumber
    ? ""
    : compact([gteValueNumber / 100, lteValueNumber / 100]).join("-");

  return (
    <Filter
      chip={t([localeRoot, "chip"])}
      title={t([localeRoot, "title"])}
      selectedText={selectedText}
      isInitialOpen={isInitialOpen}
      onApply={value => {
        const {
          [queryParamsKey.lte]: lteValue,
          [queryParamsKey.gte]: gteValue,
        } = value ?? {};

        if (changeUrl) {
          updateUrl("page", 1);
          updateUrl(queryParamsKey.lte, isNaN(lteValue) ? null : lteValue);
          updateUrl(queryParamsKey.gte, isNaN(gteValue) ? null : gteValue);
        }
      }}
      shouldShowRemoveButton
    >
      <MultipleInputs
        lteValue={lteValue}
        gteValue={gteValue}
        localeRoot={localeRoot}
        queryParamsKey={queryParamsKey}
      />
    </Filter>
  );
};
