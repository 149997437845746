import { useState } from "react";
import InnerHTML from "dangerously-set-html-content";
import { t } from "i18n-js";
import { Icon } from "@circle-react/components/shared/Icon";
import { LabelMd } from "@circle-react/components/shared/uikit/Typography/components/Label/LabelMd";
import { usePunditUserContext } from "@circle-react/contexts";
import { CircleBrandThemeProvider } from "@circle-react/providers";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { BodyMd } from "@circle-react-shared/uikit/Typography/components/Body/BodyMd";
import { LabelXs } from "@circle-react-shared/uikit/Typography/components/Label/LabelXs";
import { UserImage } from "@circle-react-shared/uikit/UserImage";
import { AttachmentsList } from "../AttachmentsList";
import { BotAvatar } from "../BotAvatar";
import { ChatButton } from "../ChatButton";
import "./styles.css";

export interface ChatMessageProps {
  type: "business" | "bot" | "user";
  authorAvatarUrl?: string;
  authorName?: string;
  content: string;
  buttons: any[];
  attachments?: any[];
  createdAt: string;
  showDisclaimer: boolean;
  process?: (button: any) => void;
}

export const ChatMessage = ({
  type,
  authorAvatarUrl,
  authorName,
  content,
  buttons,
  attachments = [],
  createdAt,
  showDisclaimer = false,
  process = () => {},
}: ChatMessageProps) => {
  const [selected, setSelected] = useState<any>(null);
  const { currentCommunityMember } = usePunditUserContext();
  const authorMapping = {
    business: {
      avatarUrl: authorAvatarUrl,
      name: authorName,
    },
    bot: {
      avatarUrl: null,
      name: t("support_widget.chatbot.name"),
    },
    user: {
      avatarUrl: currentCommunityMember.avatar_url,
      name: currentCommunityMember.name,
    },
  };

  const chatButtons = buttons.map(buttonProps => {
    const { label } = buttonProps;
    return (
      <ChatButton
        key={label}
        {...buttonProps}
        selected={selected}
        setSelected={setSelected}
        process={process}
      />
    );
  });

  return (
    <div className="ml-6 flex flex-row items-start gap-3 pt-6">
      {type === "bot" ? (
        <BotAvatar />
      ) : (
        <UserImage
          src={authorMapping[type]["avatarUrl"]}
          size="10"
          name={authorMapping[type]["name"]}
        />
      )}
      <div className="flex flex-col">
        <div className="flex flex-row items-center gap-2">
          <LabelMd weight="semibold" color="text-dark">
            {authorMapping[type]["name"]}
          </LabelMd>
          <LabelXs color="text-light">{createdAt}</LabelXs>
          {showDisclaimer && (
            <TippyV2
              maxWidth={250}
              className="mt-[-4px]"
              content={
                <CircleBrandThemeProvider>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: t("support_widget.chatbot.disclaimer"),
                    }}
                  />
                </CircleBrandThemeProvider>
              }
            >
              <Icon type="16-info" size={16} />
            </TippyV2>
          )}
        </div>
        <div className="message-content pr-6">
          <BodyMd>
            <InnerHTML html={content} />
          </BodyMd>
          {attachments.length > 0 ? (
            <AttachmentsList attachments={attachments} collapsed={false} />
          ) : null}
          {chatButtons.length > 0 ? (
            <div className="mt-4 flex flex-row flex-wrap gap-2">
              {chatButtons}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
