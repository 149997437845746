import { RoleSelect } from "@circle-react-shared/RoleSelect";
import { TableCell } from "@circle-react-uikit/Table";
import type { CellVariant } from "@circle-react-uikit/Table/TableCell";
import type { Member } from "../types";

interface MemberRoleColumnProps {
  cellVariant: CellVariant;
  member: Member;
  onRoleChange: () => void;
}

export const MemberRoleColumn = ({
  cellVariant,
  member,
  onRoleChange,
}: MemberRoleColumnProps) => {
  const { role, id } = member;

  return (
    <TableCell variant={cellVariant} className="w-32">
      <RoleSelect id={id} role={role} onRoleChange={onRoleChange} />
    </TableCell>
  );
};
