import { useFetchUpcomingEvents } from "@circle-workflows/hooks/useFetchUpcomingEvents";
import { t } from "i18n-js";
import { FormTextSelect } from "@circle-react-uikit/Form/FormTextSelect";
import { Loader } from "@circle-react-uikit/Loader";
import { Typography } from "@circle-react-uikit/Typography";
import { useCurrentAction } from "../../../ActionRule";

const i18nRoot = "settings.workflows.form";
const per_page = 100;

export const FormSelectEvent = () => {
  const { paramsPath } = useCurrentAction();

  const { data, isLoading, isError } = useFetchUpcomingEvents({ per_page });
  const { records: events = [] } = data || {};

  const options = events.map(event => {
    const eventName = event.recurring
      ? `${event.name} ${t([i18nRoot, "recurring_label"])}`
      : event.name;
    const eventDescription = event.paid_event
      ? `${event.space_name} • ${event.start_date} • ${event.ticket_price}`
      : `${event.space_name} • ${event.start_date}`;

    return {
      label: eventName,
      value: event.id,
      description: eventDescription,
    };
  });

  if (isLoading) {
    return <Loader center />;
  }

  if (isError) {
    return (
      <Typography.LabelXs>
        <span className="text-v2-danger">
          {t([i18nRoot, "errors.upcoming_events.fetch_failed"])}
        </span>
      </Typography.LabelXs>
    );
  }

  return (
    <FormTextSelect
      name={`${paramsPath}.event_id`}
      label={
        <Typography.LabelSm weight="semibold">
          {t([i18nRoot, "which_event"])}
        </Typography.LabelSm>
      }
      options={options}
      rules={{
        required: t([i18nRoot, "errors.required"]),
      }}
      emptyMessage={t([i18nRoot, "errors.upcoming_events.not_found"])}
    />
  );
};
