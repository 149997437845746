import { useEffect, useState } from "react";
import { t } from "i18n-js";
import { Form } from "@circle-react-uikit/Form";
import { DayCheckbox } from "./DayCheckbox";

const i18nRoot = "settings.workflows.recurring_schedule_modal";

/**
 * This is a hack to prevent an issue with Headless UI React & React Hook Form
 * https://github.com/orgs/react-hook-form/discussions/9250
 * The first click on checkbox does not work when rendered inside Transition & Dialog.
 * This is a workaround to force the component to render after the first render.
 */
export const DayCheckboxList = ({
  days,
}: {
  days: { value: string; label: string }[];
}) => {
  const [hasRendered, setHasRendered] = useState(false);

  useEffect(() => {
    setHasRendered(true);
  }, []);

  if (!hasRendered) {
    return null;
  }

  return (
    <Form.Item
      name="days_of_week"
      label={t([i18nRoot, "repeats_every"])}
      hideBorder
      hideDescription
      labelClassName="mb-2"
    >
      <div className="flex flex-wrap justify-between gap-y-2">
        {days.map(day => (
          <DayCheckbox name="days_of_week" key={day.value} {...day} />
        ))}
      </div>
    </Form.Item>
  );
};
