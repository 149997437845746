import type { ComponentPropsWithoutRef } from "react";
import classnames from "classnames";
import noop from "lodash/noop";
import { Link } from "react-router-dom";
import type { LinkProps } from "react-router-dom";

interface RouterLinkProps extends LinkProps {
  to: LinkProps["to"];
}

interface ButtonProps extends ComponentPropsWithoutRef<"button"> {
  to?: never;
}

type BottomBarLinkProps = RouterLinkProps | ButtonProps;

const isRouterlink = (props: BottomBarLinkProps): props is RouterLinkProps =>
  "to" in props;

const isButton = (props: BottomBarLinkProps): props is ButtonProps =>
  !("to" in props);

export const BottomBarLink = ({
  children,
  className,
  ...props
}: BottomBarLinkProps) => {
  if (isRouterlink(props)) {
    return (
      <Link
        className={classnames(
          "text-dark flex w-full justify-center p-5",
          className,
        )}
        onClick={props.onClick || noop}
        {...props}
      >
        {children}
      </Link>
    );
  }

  if (isButton(props)) {
    return (
      <button
        type="submit"
        className={classnames(
          "text-dark flex w-full justify-center p-5",
          className,
        )}
        onClick={props.onClick || noop}
        {...props}
      >
        {children}
      </button>
    );
  }

  return null;
};
