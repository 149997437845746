import { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";
import { PAYWALL_STATUS } from "@circle-react/helpers/paywallHelpers";
import { usePaywallList } from "@circle-react/hooks/paywalls/usePaywallList";
import { paywallStatusesDefault } from "@circle-react-shared/Paywalls/Modals/CreatePaywallSubscriptionModal/Form/hooks/usePaywallForm";

const BulkImportTrialPaywallSubscriptionFormContext = createContext({});
BulkImportTrialPaywallSubscriptionFormContext.displayName =
  "BulkImportTrialPaywallSubscriptionFormContext";

export const BulkImportTrialPaywallSubscriptionFormContextProvider = ({
  paywallId: paywallIdParam,
  paywallPriceId: paywallPriceIdParam,
  paywallTrialDays: paywallTrialDaysParam,
  paywallCouponCode: paywallCouponCodeParam,
  skipDateValidations: skipDateValidationsParam,
  hideNotifyMemberForm,
  children,
}) => {
  const [paywallId, setPaywallId] = useState(paywallIdParam ?? "");
  const [paywallPriceId, setPaywallPriceId] = useState(
    paywallPriceIdParam ?? "",
  );
  const [paywallTrialDays, setPaywallTrialDays] = useState(
    paywallTrialDaysParam ?? "",
  );
  const [paywallCouponCode, setPaywallCouponCode] = useState(
    paywallCouponCodeParam ?? "",
  );
  const { paywallsListQuery } = usePaywallList({
    communityMemberId: null,
    status: skipDateValidationsParam
      ? paywallStatusesDefault
      : [PAYWALL_STATUS.ACTIVE],
  });
  const isPaywallLoading = paywallsListQuery.isLoading;

  const valueToStore = {
    paywallId,
    setPaywallId,
    paywallPriceId,
    setPaywallPriceId,
    paywallTrialDays,
    setPaywallTrialDays,
    paywallCouponCode,
    setPaywallCouponCode,
    isPaywallLoading,
    skipDateValidations: Boolean(skipDateValidationsParam),
    hideNotifyMemberForm,
  };

  return (
    <BulkImportTrialPaywallSubscriptionFormContext.Provider
      value={valueToStore}
    >
      {children}
    </BulkImportTrialPaywallSubscriptionFormContext.Provider>
  );
};

export const useBulkImportTrialPaywallSubscriptionFormContext = () =>
  useContext(BulkImportTrialPaywallSubscriptionFormContext);

BulkImportTrialPaywallSubscriptionFormContextProvider.propTypes = {
  paywallId: PropTypes.number,
  paywallPriceId: PropTypes.number,
  paywallTrialDays: PropTypes.number,
  paywallCouponCode: PropTypes.string,
  communityMemberId: PropTypes.number,
  skipDateValidations: PropTypes.bool,
  hideNotifyMemberForm: PropTypes.bool,
  children: PropTypes.node,
};
