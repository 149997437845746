import { t } from "i18n-js";
import { FieldLabel } from "../shared/Form/FieldLabel";
import { SearchableSelect_DEPRECATED } from "../shared/SearchableSelect_DEPRECATED";

const localizedData = t("landing_page.settings");

export const SpaceSelect = ({
  fieldName,
  options,
  community,
  onOptionSelect,
}) => {
  const idFieldName = `${fieldName}_id`;
  return (
    <div className="form-group" id={`field-${idFieldName}`}>
      <FieldLabel
        field={idFieldName}
        label={localizedData[fieldName]}
        description={localizedData[`${fieldName}_description`]}
      />
      <SearchableSelect_DEPRECATED
        options={options}
        selected={community[idFieldName]}
        customKey={idFieldName}
        onOptionSelect={value =>
          onOptionSelect(value, idFieldName, "community")
        }
      />
    </div>
  );
};
