import PropTypes from "prop-types";
import { LinearProgress } from "@material-ui/core";
import { truncate } from "lodash";
import { Icon } from "@circle-react/components/shared/Icon";
import { LabelSm } from "@circle-react/components/shared/uikit/Typography/components/Label/LabelSm";
import {
  ATTACHMENT_STATUS_COMPLETED,
  ATTACHMENT_STATUS_UPLOADED,
  ATTACHMENT_STATUS_UPLOADING,
} from "../constants";
import "./styles.css";

export const FormAttachment = ({ attachment, onCancel }) => {
  const handleCancel = () => {
    onCancel(attachment);
  };

  return (
    <div className="flex items-center justify-between gap-2 break-all rounded-md bg-gray-50 px-4 py-2 dark:bg-gray-700">
      {attachment.status === ATTACHMENT_STATUS_COMPLETED ? (
        <a
          href={attachment.blobUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="text-circle hover:text-circle focus:text-circle dark:text-dark dark:hover:text-dark text-sm font-semibold underline"
        >
          {truncate(attachment.filename, { length: 40 })}
        </a>
      ) : (
        <LabelSm weight="semibold">
          {truncate(attachment.filename, { length: 28 })}
        </LabelSm>
      )}
      <div className="flex items-center justify-between gap-2">
        {(attachment.status === ATTACHMENT_STATUS_UPLOADING ||
          attachment.status === ATTACHMENT_STATUS_UPLOADED) && (
          <LinearProgress
            variant="determinate"
            value={attachment.progress || 0}
            className="linear-progress h-1 w-14 rounded-full"
          />
        )}
        <Icon
          type="16-close"
          size={16}
          onClick={handleCancel}
          className="text-dark cursor-pointer"
        />
      </div>
    </div>
  );
};

FormAttachment.propTypes = {
  attachment: PropTypes.object,
  onCancel: PropTypes.func,
};
