import { t } from "i18n-js";
import { IdFilter } from "./";

const localeRoot = "settings.paywalls_admin_dashboard.filters.invoice_id";

export const InvoiceIdFilter = (props: any) => (
  <IdFilter
    {...props}
    localeRoot={localeRoot}
    queryParamsKey="invoice_processor_id"
  />
);

export const filterProperties = {
  name: "invoice_processor_id",
  filterKeys: ["invoice_processor_id"],
  component: InvoiceIdFilter,
  label: t([localeRoot, "chip"]),
};
