export const LOCALE_ROOT =
  "settings.paywalls_admin_dashboard.filters.start_date";

export const DEFAULT_QUERY_PARAMS_KEY = {
  default: "start_date",
  lte: "start_date_lte",
  gte: "start_date_gte",
};

export const CUSTOM_RANGE = "date_range";

export const LAST_SEVEN_DAYS = "last_seven_days";
export const LAST_FOUR_WEEKS = "last_four_weeks";
export const LAST_THREE_MONTHS = "last_three_months";
export const LAST_TWELVE_MONTHS = "last_twelve_months";
export const MONTH_TO_DATE = "month_to_date";
export const QUARTER_TO_DATE = "quarter_to_date";
export const YEAR_TO_DATE = "year_to_date";

export type AVAILABLE_RANGE_TYPE =
  | typeof LAST_SEVEN_DAYS
  | typeof LAST_FOUR_WEEKS
  | typeof LAST_THREE_MONTHS
  | typeof LAST_TWELVE_MONTHS
  | typeof MONTH_TO_DATE
  | typeof QUARTER_TO_DATE
  | typeof YEAR_TO_DATE;
