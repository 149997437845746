import flatpickr from "flatpickr";
import { getCurrentCircleLocale } from "@circle-react/helpers/localeHelpers";

export const setFlatpickrLocale = async () => {
  if (getCurrentCircleLocale() === "pt") {
    const locale = await import(
      /* webpackChunkName: "flatpickr_pt" */ "flatpickr/dist/l10n/pt"
    );

    flatpickr.setDefaults({
      locale: locale.Portuguese,
    });
  }
};
