import { t } from "i18n-js";
import { useFilterContext } from "@circle-react/components/Modals/SearchResultsModal/FilterBar/FilterContext";
import { useTagsFilter } from "@circle-react/components/SettingsApp/AnalyticsV2/FilterBarV2/EntityFilters/TagsFilter/useTagsFilter";
import {
  CheckboxFilterV2 as CheckboxFilter,
  Filter,
} from "@circle-react-shared/Filter";

export const TagFilter = () => {
  const { filters, setFilters } = useFilterContext();
  const { isLoading, memberTags } = useTagsFilter();

  if (isLoading || !memberTags.length) {
    return null;
  }
  return (
    <Filter
      chip={t("search_v2.filters.tag")}
      title={t("search_v2.filters.tag")}
      selectedText={
        filters?.member_tag_ids
          ? t("search_v2.filters.n_tags", {
              count: filters?.member_tag_ids?.length || 0,
            })
          : ""
      }
      onApply={data => {
        setFilters({
          ...filters,
          member_tag_ids: (data?.value || []).length ? data?.value : null,
        });
      }}
      shouldShowRemoveButton={!!filters?.member_tag_ids}
    >
      <CheckboxFilter
        options={memberTags}
        className="w-full"
        param={{
          id: "member_tags",
          key: "member_tags",
          value: filters?.member_tag_ids || [],
        }}
        legend={t("search_v2.filters.tag")}
        hasSearch
        searchProps={{
          placeholder: t("search_v2.filters.search_tags"),
        }}
      />
    </Filter>
  );
};
