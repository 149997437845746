import { useBroadcastSettingsAddressModal } from "@circle-react/components/SettingsApp/Emails/components/BroadcastSettings/BroadcastSettingsAddressModal";
import { useAreMarketingSettingsAvailable } from "@circle-react/components/SettingsApp/Emails/hooks/useAreMarketingSettingsAvailable";
import type { SendEmailApiParams } from "../Actions/Member/sendEmail/interfaces";
import { useUpsertBroadcast } from "../Actions/Member/sendEmail/useUpsertBroadcast";

interface EmailActionContinueProps {
  continueAction: () => Promise<void>;
}

interface UseSendEmailActionContinueProps {
  api_params?: SendEmailApiParams;
}

export const useSendEmailActionContinue = (
  action: UseSendEmailActionContinueProps,
) => {
  const { areMarketingSettingsAvailable, isLoading } =
    useAreMarketingSettingsAvailable();
  const broadcastSettingsModal = useBroadcastSettingsAddressModal();

  const { onUpsert } = useUpsertBroadcast(
    action?.api_params?.broadcast_id ?? null,
  );

  const handleEmailActionContinue = async ({
    continueAction,
  }: EmailActionContinueProps) => {
    if (areMarketingSettingsAvailable) {
      await onUpsert();
      await continueAction();
    } else {
      await broadcastSettingsModal.show({
        onSuccess: () => void continueAction(),
      });
    }
  };

  return {
    handleEmailActionContinue,
    isLoadingMarketingSettings: isLoading,
  };
};
