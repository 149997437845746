import classnames from "classnames";
import { t } from "i18n-js";
import { Typography } from "@circle-react-uikit/Typography";
import type { SpaceGroupOption } from "../../SpaceAccessManagementContext";
import { i18nRoot } from "../../constants";
import { Item } from "./Item";
import { ItemButton } from "./ItemButton";
import { ItemTitle } from "./ItemTitle";
import { useParentItem } from "./hooks/useParentItem";

interface ParentRemovableItemProp extends SpaceGroupOption {
  onRemoveParent: (action: () => void, object: SpaceGroupOption) => void;
}

export const ParentRemovableItem = ({
  onRemoveParent = action => {
    action();
  },
  ...spaceGroup
}: ParentRemovableItemProp) => {
  const { name } = spaceGroup;
  const {
    isChecked,
    isFromSearch,
    isAllSpacesSelected,
    spacesAlreadySelected,
    onRemoveClick,
  } = useParentItem({ spaceGroup, onRemoveParent });

  const shouldHide =
    (!isChecked && spacesAlreadySelected.length === 0) || !isFromSearch;

  const spacesCounterText =
    isChecked && isAllSpacesSelected
      ? t("space_access_management.list.spaces_counter.all_spaces")
      : t("space_access_management.list.spaces_counter", {
          count: spacesAlreadySelected.length,
        });

  return (
    <Item
      isDisabled={!isChecked}
      onClick={isChecked ? onRemoveClick : undefined}
      className={classnames({ hidden: shouldHide })}
    >
      <ItemTitle>
        <Typography.LabelSm weight="semibold">
          {name}
          <span className="font-normal">{spacesCounterText}</span>
        </Typography.LabelSm>
      </ItemTitle>
      {isChecked && <ItemButton actionText={t([i18nRoot, "remove"])} />}
    </Item>
  );
};
