import PropTypes from "prop-types";
import { Icon } from "@circle-react-shared/Icon";
import { Action } from "../Action";
import { useEntryRule } from "./useEntryRule";

export const EntryRule = ({ trigger, audience, workflowType }) => {
  const entryRule = useEntryRule({
    trigger,
    audience,
    workflowType,
  });

  if (!entryRule) {
    return null;
  }

  const { title, iconType, size = "lg" } = entryRule || {};

  return (
    <Action size={size} tooltipContent={title}>
      <Icon
        type={`24-workflow-${iconType}`}
        size={24}
        useWithFillCurrentColor
        className="!h-5 !w-5"
      />
    </Action>
  );
};

EntryRule.propTypes = {
  trigger: PropTypes.string,
  audience: PropTypes.string,
  workflowType: PropTypes.string,
};
