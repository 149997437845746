import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useLiveStreamContext } from "@circle-react/contexts/LiveStreams";

export const useAutoCreateRecorder = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const recordingKey = searchParams.get("recording_key");
  const {
    data: { room, recorder },
    actions: { createRecorder },
  } = useLiveStreamContext();

  useEffect(() => {
    if (room && recordingKey && createRecorder && !recorder) {
      createRecorder(recordingKey);
    }
  }, [room, recordingKey, recorder, createRecorder]);
};
