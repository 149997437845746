import classNames from "classnames";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-shared/uikit/Typography";
import type { RecentSearch } from "../useRecentSearches";

interface RecentSearchItemProps {
  searchTerm: RecentSearch;
  index: number;
  activeIndex: number;
  handleClick: (searchValue: string) => void;
  removeRecentSearch: (searchValue: string) => void;
  canHover: boolean;
  setActiveIndex: (index: number) => void;
  dataTestId: string;
}

export const RecentSearchItem = ({
  searchTerm,
  index,
  activeIndex,
  handleClick,
  removeRecentSearch,
  canHover,
  setActiveIndex,
  dataTestId,
}: RecentSearchItemProps) => {
  const isActive = index + 1 === activeIndex;

  return (
    <li
      aria-current={isActive}
      aria-posinset={index + 1}
      className={classNames(
        "group flex w-full cursor-pointer list-none items-center rounded",
        { "bg-tertiary": isActive },
      )}
      data-testid={dataTestId}
    >
      <button
        type="button"
        onClick={() => handleClick(searchTerm.query)}
        onMouseOver={() => setActiveIndex(index + 1)}
        onFocus={() => setActiveIndex(index + 1)}
        className="w-full focus-visible:!outline"
      >
        <div className="flex items-center gap-3 truncate px-4 py-2">
          <Icon
            type="16-clock"
            size={16}
            className="text-default !h-5 min-h-[16px] !w-5 min-w-[16px]"
            useWithFillCurrentColor
          />
          <div className="flex-1 truncate text-left">
            <Typography.LabelSm>{searchTerm.query}</Typography.LabelSm>
          </div>
        </div>
      </button>

      <button
        type="button"
        onClick={() => removeRecentSearch(searchTerm.query)}
        className={classNames(
          "text-default ml-auto mr-2 hidden rounded-md focus-visible:!outline",
          {
            "!block": !canHover || isActive,
          },
        )}
      >
        <Icon useWithFillCurrentColor type="close" />
      </button>
    </li>
  );
};
