import { Suspense, useEffect } from "react";
import { isEmpty } from "lodash";
import { useSpaceSlugFromUrl } from "@/react/hooks/useSpaceSlugFromUrl";
import { useDynamicLayoutContext } from "@circle-react/components/Layout/DynamicLayout";
import {
  CurrentSpaceContextProvider,
  useSpacesContext,
} from "@circle-react/contexts";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { isChatSpace } from "@circle-react/helpers/spaceHelpers";
import { PageLoader } from "@circle-react/pages/PageLoader";

const SpaceComponent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "SpacePage" */ "@circle-react/wrappers/SpaceContent"
    ),
);

export const SpacePage = () => {
  const spaceSlug = useSpaceSlugFromUrl();
  const { setDynamicLayoutProps } = useDynamicLayoutContext();

  const space = useSpacesContext().helpers.findBy({ slug: spaceSlug });

  useEffect(() => {
    if (isChatSpace(space)) {
      setDynamicLayoutProps({
        disableScroll: true,
      });
    }
  }, [space, setDynamicLayoutProps]);

  if (isEmpty(space)) {
    return null;
  }

  return (
    <CurrentSpaceContextProvider spaceId={space?.id}>
      <Suspense fallback={<PageLoader />}>
        <SpaceComponent space={space} />
      </Suspense>
    </CurrentSpaceContextProvider>
  );
};
