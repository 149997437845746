import PropTypes from "prop-types";
import { AddSpaceButton } from "@/react/components/Spaces/SidebarV2/CommunityMenu/SpaceList/AddSpaceButton";
import { JoinSpacesButton } from "@/react/components/Spaces/SidebarV2/CommunityMenu/SpaceList/JoinSpacesButton";
import { canCreateSpaces } from "@/react/helpers/spaceGroupHelpers";

export const BottomButton = ({ spaceGroup }) => {
  if (canCreateSpaces({ spaceGroup })) {
    return <AddSpaceButton spaceGroupId={spaceGroup.id} />;
  }

  if (spaceGroup.hide_non_member_spaces_from_sidebar) {
    return <JoinSpacesButton spaceGroupSlug={spaceGroup.slug} />;
  }

  return null;
};

BottomButton.propTypes = {
  spaceGroup: PropTypes.object.isRequired,
};
