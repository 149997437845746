import type { ReactNode } from "react";
import { Typography } from "@circle-react-uikit/Typography";

export interface TitleProps {
  children: ReactNode;
}

export const Title = ({ children }: TitleProps) => (
  <div>
    <Typography.LabelSm weight="semibold">{children}</Typography.LabelSm>
  </div>
);
