import { Suspense } from "react";
import { VersionedContent } from "@circle-react/components/Layout/VersionedContent";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { PageLoader } from "@circle-react/pages/PageLoader";

const SpacesComponent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "SpacesPage" */ "@circle-react/components/SpaceDirectory"
    ),
);

export const SpacesPage = () => (
  <VersionedContent fullWidth>
    <Suspense fallback={<PageLoader />}>
      <SpacesComponent />
    </Suspense>
  </VersionedContent>
);
