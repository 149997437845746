import type { Space } from "@/react/types/Space";
import {
  canInviteMembers,
  canManageSpace,
  isSortingEnabled,
  isSpaceMember,
} from "@circle-react/helpers/spaceHelpers";
import { InviteMemberSpaceOptions } from "../../SpaceHeaderTitleDropdown/spaceOptions/InviteMemberSpaceOptions";
import { LeaveAndDeleteSpaceOptions } from "../../SpaceHeaderTitleDropdown/spaceOptions/LeaveAndDeleteSpaceOptions";
import { ManageSpaceOptions } from "../../SpaceHeaderTitleDropdown/spaceOptions/ManageSpaceOptions";
import { MembersSortOptions } from "../../SpaceHeaderTitleDropdown/spaceOptions/MembersSortOptions";
import { Separator } from "./Separator";

export const MembersSpaceOptions = ({
  space,
  isAdmin,
  setIsAddMemberOpen,
}: {
  space: Space;
  isAdmin: boolean;
  setIsAddMemberOpen: (isOpen: boolean) => void;
}) => {
  const isMember = isSpaceMember(space);
  return (
    <>
      {isMember && isSortingEnabled(space) && (
        <>
          <MembersSortOptions space={space} />
          <Separator />
        </>
      )}
      {canManageSpace(space) && (
        <>
          <ManageSpaceOptions
            space={space}
            onInviteMember={() => setIsAddMemberOpen(true)}
          />
          <Separator />
        </>
      )}
      {!isAdmin && isMember && canInviteMembers(space) && (
        <>
          <InviteMemberSpaceOptions
            space={space}
            onInviteMember={() => setIsAddMemberOpen(true)}
          />
          <Separator />
        </>
      )}
      <LeaveAndDeleteSpaceOptions space={space} isAdmin={isAdmin} />
    </>
  );
};
