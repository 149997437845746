import { useMemo } from "react";
import type { HMSConfig } from "@100mslive/react-sdk";
import { selectSettings, useLiveStore } from "../../store";

export const useHMSConfig = (participant: any, authToken = "") => {
  const settings = useLiveStore(selectSettings);

  const config: HMSConfig = useMemo(
    () => ({
      userName: participant?.name,
      authToken: authToken,
      metaData: JSON.stringify(participant),
      rememberDeviceSelection: false,
      captureNetworkQualityInPreview: true,
      settings: {
        isAudioMuted: !settings.microphoneEnabled,
        isVideoMuted: !settings.cameraEnabled,
        audioInputDeviceId: settings.audioInputId,
        audioOutputDeviceId: settings.audioOutputId ?? undefined,
        videoDeviceId: settings.videoInputId,
        speakerAutoSelectionBlacklist: "all",
      },
    }),
    [participant, authToken, settings],
  );

  return config;
};
