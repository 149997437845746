import { useState } from "react";
import { Confirm } from "@circle-react/components/shared/uikit/Confirm";

export const useConfirmModal = ({
  title,
  onConfirm,
  description = "",
  bodyElement = null,
  buttonLabel = "",
  maxIndex = false,
  sentiment = "danger",
}) => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [event, setEvent] = useState();

  const onConfirmButtonClick = e => {
    setConfirmModalOpen(true);
    setEvent(e);
  };

  const onConfirmModalClose = () => {
    setConfirmModalOpen(false);
  };

  const onConfirmModal = () => {
    setConfirmModalOpen(false);
    onConfirm(event);
  };

  const confirmModal = (
    <Confirm
      show={confirmModalOpen}
      onClose={onConfirmModalClose}
      onConfirm={onConfirmModal}
      title={title}
      description={description}
      bodyElement={bodyElement}
      className="reset-banner-modal"
      maxZIndexEnabled={maxIndex}
      buttonLabel={buttonLabel}
      sentiment={sentiment}
    />
  );

  return {
    confirmModal,
    confirmModalOpen,
    onConfirmButtonClick,
    onConfirmModalClose,
    onConfirmModal,
  };
};
