import { t } from "i18n-js";
import { LabelSm } from "@/react/components/shared/uikit/Typography/components/Label/LabelSm";
import { useChangePlanFormContext } from "./ChangePlanFormContext";

export const AddPaymentMethod = () => {
  const { CardElement, handleOnChangeCard } = useChangePlanFormContext();

  return (
    <div className="mt-6">
      <LabelSm weight="semibold">
        {t(
          "member_profile_modal.billing.subscriptions.add_payment_method.card_details",
        )}
      </LabelSm>

      <div className="mt-1 rounded border border-solid border-gray-300 p-3">
        <CardElement
          options={{ hidePostalCode: true }}
          onChange={handleOnChangeCard}
        />
      </div>
    </div>
  );
};
