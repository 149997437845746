import PropTypes from "prop-types";
import { useFetchSpace } from "@circle-react/components/SettingsApp/Workflows/hooks/useFetchSpace";
import { hasEmoji } from "@circle-react/helpers/spaceHelpers";

export const SpaceFromId = ({ id }) => {
  const { data: space, isLoading } = useFetchSpace({ id });

  if (isLoading) {
    return (
      <span className="bg-tertiary block h-4 w-full animate-pulse rounded"></span>
    );
  }

  return (
    <div className="post__space">
      {hasEmoji(space) && <span>{space.emoji}</span>}
      <span>{space.name}</span>
    </div>
  );
};

SpaceFromId.propTypes = {
  id: PropTypes.string.isRequired,
};
