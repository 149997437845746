import { t } from "i18n-js";

export const DefaultTermsLabel = ({
  showCreatorTerms,
  locale,
  communityTermsUrl,
  communityPrivacyUrl,
}) => {
  const localeScope = "authentication_app.signup.form.terms_and_privacy";

  const termsUrl = showCreatorTerms
    ? locale === "pt"
      ? "https://circle.so/br/creator-terms"
      : "https://circle.so/creator-terms"
    : communityTermsUrl;
  const privacyUrl = showCreatorTerms
    ? locale === "pt"
      ? "https://circle.so/br/privacy"
      : "https://circle.so/privacy"
    : communityPrivacyUrl;

  return (
    <span className="text-light text-xs">
      {t(`${localeScope}.prefix`)}{" "}
      <a
        className="text-default hover:text-light font-bold"
        href={termsUrl}
        target="_blank"
        rel="noreferrer"
      >
        {t([
          localeScope,
          showCreatorTerms ? "creator_terms_of_service" : "terms_of_service",
        ])}
      </a>{" "}
      {t(`${localeScope}.and`)}{" "}
      <a
        className="text-default hover:text-light font-bold"
        href={privacyUrl}
        target="_blank"
        rel="noreferrer"
      >
        {t([localeScope, "privacy_policy"])}
      </a>
    </span>
  );
};
