import PropTypes from "prop-types";
import classNames from "classnames";

export const Container = ({ className, children }) => (
  <div className={classNames("flex flex-col gap-6", className)}>{children}</div>
);

Container.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};
