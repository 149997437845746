// Do not add label strings here, we won't be able to fetch the correct locale
const extractStripePaywallPrice = (paywallPrice: any) => ({
  id: paywallPrice.id,
  scenario: paywallPrice.scenario,
  amount: paywallPrice.amount,
  upfront_payment_amount: paywallPrice.upfront_payment_amount,
  installments_count: paywallPrice.installments_count,
  installment_amount: paywallPrice.installment_amount,
  interval_count: paywallPrice.interval_count,
  interval: paywallPrice.interval,
  price_type: paywallPrice.price_type,
  trial_days: paywallPrice.trial_days,
});

const extractMobilePaywallPrice = (paywallPriceWithPricePackage: any) => ({
  ...extractStripePaywallPrice(paywallPriceWithPricePackage),
  processor_id: paywallPriceWithPricePackage.processor_id,
  price_package_id: paywallPriceWithPricePackage.price_package_id,
});

export const filterPaywallPrice = (paywallPrice: any, priceType = "stripe") => {
  if (!paywallPrice) {
    return {
      id: null,
    };
  }

  if (priceType === "stripe") {
    return extractStripePaywallPrice(paywallPrice);
  }

  return extractMobilePaywallPrice(paywallPrice);
};
