import PropTypes from "prop-types";
import { t } from "i18n-js";
import { Dropdown } from "@circle-react-uikit/Dropdown";

export const Archive = ({ onClick }) => (
  <Dropdown.ItemWithLink onClick={onClick}>
    {t("settings.workflows.table.actions.archive")}
  </Dropdown.ItemWithLink>
);

Archive.propTypes = {
  onClick: PropTypes.func,
};
