import PropTypes from "prop-types";
import { t } from "i18n-js";
import { Typography } from "@circle-react-uikit/Typography";
import { BannerEditTabs } from "./BannerEditTabs";

export const BannerEdit = ({ blockDataPath }) => (
  <>
    <Typography.LabelLg weight="semibold">
      {t("courses.lockscreen.block_name.banner")}
    </Typography.LabelLg>
    <BannerEditTabs blockDataPath={blockDataPath} />
  </>
);

BannerEdit.propTypes = {
  blockDataPath: PropTypes.string.isRequired,
};
