import { useState } from "react";

export const useCollapsibleSections = ({ sections }) => {
  const [expandedSections, setExpandedSections] = useState(
    Object.fromEntries(sections.map(section => [section.id, true])),
  );

  const toggleSectionExpanded = section => {
    setExpandedSections(prevState => ({
      ...prevState,
      [section.id]: !prevState[section.id],
    }));
  };

  const isSectionExpanded = section => expandedSections[section.id];

  const expandAllSections = () => {
    setExpandedSections(
      Object.fromEntries(sections.map(section => [section.id, true])),
    );
  };

  const collapseAllSections = () => {
    setExpandedSections(
      Object.fromEntries(sections.map(section => [section.id, false])),
    );
  };

  const hasExpandedSections = () =>
    Object.values(expandedSections).some(Boolean);

  return {
    hasExpandedSections,
    isSectionExpanded,
    toggleSectionExpanded,
    expandAllSections,
    collapseAllSections,
  };
};
