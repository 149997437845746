import { t } from "i18n-js";
import isFunction from "lodash/isFunction";
import { useMutation, useQueryClient } from "react-query";
import { useEmbeddableChatApi } from "@/react/hooks/embeddable_chat/useEmbeddableChatsApi";
import {
  usePunditUserContext,
  useSpaceGroupsContext,
  useSpaceResource,
  useSpacesContext,
} from "@circle-react/contexts";
import { reactQueryPost } from "@circle-react/helpers/backendRequestHelpers";
import { isChatSpace } from "@circle-react/helpers/spaceHelpers";
import {
  communityMemberProfileSettingsPath_DEPRECATED,
  internalApi,
  newSessionPath,
} from "@circle-react/helpers/urlHelpers";
import { useChatParticipantsApi } from "@circle-react/hooks/chatsV2/useChatParticipantsApi";
import { useToast } from "@circle-react-uikit/ToastV2";

export const useJoinSpace = ({
  space,
  setOpenNotificationsDropdown = () => {},
}) => {
  const { name, id } = space;
  const queryClient = useQueryClient();
  const { currentUser, currentCommunityMember } = usePunditUserContext();
  const { refetch: refetchSpaceResource } = useSpaceResource({
    spaceId: id,
  });
  const {
    actions: { refresh: refreshSpaceGroups },
  } = useSpaceGroupsContext();
  const {
    actions: { refresh: refreshSpaces },
  } = useSpacesContext();

  //Specific to Chat Spaces
  const { refetchChatRoom } = useEmbeddableChatApi(space.chat_room_uuid);
  const { refetchChatParticipants } = useChatParticipantsApi(
    space.chat_room_uuid,
  );

  const { success, error } = useToast();
  const joinSpaceMutation = useMutation(
    () => reactQueryPost(internalApi.spaceMembers.joinSpace({ spaceId: id })),
    {
      onSuccess: async () => {
        await refetchSpaceResource();
        refreshSpaceGroups();
        refreshSpaces();
        queryClient.invalidateQueries(["spaces"]);
        if (isChatSpace(space)) {
          refetchChatRoom();
          refetchChatParticipants();
        }
        success(t("joined_space", { space_name: space.name }));
        if (!currentCommunityMember?.profile_confirmed_at) {
          window.location.href = communityMemberProfileSettingsPath_DEPRECATED({
            params: { return_to: window.location.href, new_state: true },
          });
        } else {
          isFunction(setOpenNotificationsDropdown) &&
            setOpenNotificationsDropdown(true);
        }
      },
      onError: e => {
        error(e.message || t("join_space_failed", { space_name: name }));
      },
    },
  );

  const joinSpace = async () => {
    if (!currentUser) {
      window.location.href = newSessionPath();
    } else {
      return joinSpaceMutation.mutateAsync();
    }
  };

  return {
    joinSpace,
    isSubmitting: joinSpaceMutation.isLoading,
  };
};
