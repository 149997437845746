import PropTypes from "prop-types";
import classNames from "classnames";

export const SkeletonLoader = ({
  rows = 1,
  wrapperClassName = "",
  loaderClassName = "",
  width = "100%",
  height = "24px",
}) => (
  <div
    className={classNames(
      "animate-pulse space-y-2 px-6 last:pb-4",
      wrapperClassName,
    )}
  >
    {Array(rows)
      .fill()
      .map((_, i) => (
        <div
          key={i}
          className={classNames("bg-secondary rounded-md", loaderClassName)}
          style={{ width, height }}
        />
      ))}
  </div>
);

SkeletonLoader.propTypes = {
  rows: PropTypes.number,
  wrapperClassName: PropTypes.string,
  loaderClassName: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};
