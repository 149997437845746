import { useQuery } from "react-query";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { convertTimestampToDate } from "../General/utils";
import { useCurrentPlanApi } from "../Plan/hooks/useCurrentPlanApi";

export interface ApiAnalyticsData {
  admin_v2: number;
  headless_member: number;
  headless_auth: number;
  legacy: number;
}

export const useApiAnalyticsApi = () => {
  const { currentPlan } = useCurrentPlanApi();

  const fetchApiAnalytics = (startDateTime: number, endDateTime: number) => {
    const formattedStartDate = convertTimestampToDate(startDateTime)
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    const formattedEndDate = convertTimestampToDate(endDateTime)
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    return reactQueryGet(
      internalApi.apiAnalytics.get(formattedStartDate, formattedEndDate),
    );
  };

  const {
    data = {
      admin_v2: 0,
      headless_member: 0,
      headless_auth: 0,
      legacy: 0,
    },
    isLoading,
    refetch: refetchApiAnalytics,
  } = useQuery<ApiAnalyticsData>(
    ["apiAnalytics"],
    () =>
      fetchApiAnalytics(
        currentPlan?.api_billing?.cycle?.start_date,
        currentPlan?.api_billing?.cycle?.end_date,
      ),
    {
      enabled: !!currentPlan?.api_billing?.cycle,
    },
  );

  return {
    apiAnalytics: data,
    isLoading,
    refetchApiAnalytics,
    fetchApiAnalytics,
  };
};
