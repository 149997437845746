import { DataPacket_Kind } from "livekit-client";
import { useLivekitContext } from "../../VideoProvider/LivekitProvider";
import { ACTION_TYPES } from "./helpers";

export const useLivekitSyncRoom = () => {
  const { localParticipant } = useLivekitContext();

  return () => {
    if (!localParticipant) {
      return Promise.reject("localParticipant is not present");
    }
    const encoder = new TextEncoder();
    const action = JSON.stringify({
      type: ACTION_TYPES.UPDATE_ROOM,
    });

    return localParticipant?.publishData(
      encoder.encode(action),
      DataPacket_Kind.RELIABLE,
    );
  };
};
