import { t } from "i18n-js";
import { useHistory } from "react-router-dom";
import {
  planSettingsPath,
  workflowsSettingsPaths,
} from "@circle-react/helpers/urlHelpers";
import { usePlanStatus } from "@circle-react/hooks/usePlanStatus";
import { FeatureLandingPage } from "@circle-react-shared/FeatureLandingPage";
import { Header } from "../Header";
import { FeatureCards } from "./FeatureCards";

const localeNamespace = "settings.workflows.landing_page";

export const LandingPage = () => {
  const history = useHistory();
  const { isCommunityOnTrial, isCommunityOnBasic, isCommunityOnProfessional } =
    usePlanStatus();

  const shouldShowHelloBar =
    isCommunityOnTrial || isCommunityOnBasic || isCommunityOnProfessional;

  const navigateToNewWorkflows = () => {
    history.push(workflowsSettingsPaths.new());
  };

  return (
    <>
      {shouldShowHelloBar && (
        <FeatureLandingPage.HelloBar to={planSettingsPath()}>
          {t([localeNamespace, "upgrade_your_plan_to_start_using_workflows"])}
        </FeatureLandingPage.HelloBar>
      )}
      <Header title={t("settings.workflows.workflows")} />
      <FeatureLandingPage.HeroSection
        title={t([localeNamespace, "hero_title"])}
        subtitle={t([localeNamespace, "hero_subtitle"])}
        buttonText={t([localeNamespace, "action_button"])}
        onActionButtonClick={navigateToNewWorkflows}
      >
        {t([localeNamespace, "hero_description"])}
      </FeatureLandingPage.HeroSection>
      <FeatureCards />
      <FeatureLandingPage.FooterAction
        title={t([localeNamespace, "hero_title"])}
        buttonText={t([localeNamespace, "action_button"])}
        onActionButtonClick={navigateToNewWorkflows}
      >
        {t([localeNamespace, "footer_description"])}
      </FeatureLandingPage.FooterAction>
    </>
  );
};
