import { UserImageStatus } from "@circle-react-shared/UserImageStatus";
import type { OtherParticipant } from "../ChatRoomTypes";
import { GroupUserImage } from "../GroupUserImage";

interface AvatarProps {
  isDirect: boolean;
  otherParticipants: OtherParticipant[];
}

export const Avatar = ({ isDirect, otherParticipants }: AvatarProps) => {
  if (isDirect) {
    const [user] = otherParticipants;
    const { avatar_url: src, name, status } = user || {};
    return (
      <div className="mr-1">
        <UserImageStatus src={src} name={name} status={status} size="9" />
      </div>
    );
  }

  return (
    <GroupUserImage user1={otherParticipants[0]} user2={otherParticipants[1]} />
  );
};
