import { t } from "i18n-js";
import { useWatch } from "react-hook-form";
import type { Space } from "@circle-react/types/Space";
import { Tab } from "@circle-react-uikit/TabV2";
import { Typography } from "@circle-react-uikit/Typography";
import { FormComponent } from "./FormComponent";

const TABS = {
  logged_in: t("customize_space.welcome_banner.tabs.members"),
  logged_out: t("customize_space.welcome_banner.tabs.non_members"),
};

interface WelcomeBannerProps {
  setDirty: (key: string, value: boolean) => void;
  onChange: (value: boolean) => void;
  space: Space;
}

export const WelcomeBanner = ({
  setDirty,
  onChange,
  space,
}: WelcomeBannerProps) => {
  const visibility = useWatch({ name: "visibility" });
  const displayView = useWatch({ name: "display_view" });

  if (displayView === "calendar") {
    return (
      <Typography.BodySm>
        {t("customize_space.welcome_banner.info")}
      </Typography.BodySm>
    );
  }
  if (visibility !== "open") {
    return (
      <div className="flex h-full w-full flex-col items-center gap-4 md:py-4">
        <FormComponent
          isLoggedIn
          setDirty={setDirty}
          onChange={onChange}
          spaceId={space?.id}
        />
      </div>
    );
  }
  return (
    <div className="flex h-full w-full flex-col items-center gap-4 md:py-4">
      <section className="w-full flex-1">
        <Tab.Group>
          <Tab.List>
            <Tab type="button">{TABS.logged_in}</Tab>
            <Tab type="button">{TABS.logged_out}</Tab>
          </Tab.List>
          <Tab.Panels className="-mx-1.5">
            <Tab.Panel className="px-2 py-6">
              <FormComponent
                isLoggedIn
                setDirty={setDirty}
                onChange={onChange}
                spaceId={space?.id}
              />
            </Tab.Panel>
            <Tab.Panel className="px-2 py-6">
              <FormComponent
                isLoggedIn={false}
                setDirty={setDirty}
                onChange={onChange}
                spaceId={space?.id}
              />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </section>
    </div>
  );
};
