import { useFormContext } from "react-hook-form";
import { usePricingTabContext } from "../../hooks/usePricingTabContext";
import { useSubscriptionGroup } from "../../hooks/useSubscriptionGroup";
import { SubscriptionGroupSelect } from "../Fields/SubscriptionGroupSelect";
import { SubscriptionGroupToggle } from "../Fields/SubscriptionGroupToggle";
import { ToggleableField } from "../ToggleableField";

export const SubscriptionGroupForm = () => {
  const { currency } = usePricingTabContext();
  const { watch } = useFormContext();

  const {
    canShowChooser,
    canEnableChooser,
    paywallGroupSelectOptions,
    enableSubscriptionGroup,
  } = useSubscriptionGroup(currency);

  const isToggleDisabled = !canShowChooser || !canEnableChooser;
  const isSelectDisabled = isToggleDisabled || !enableSubscriptionGroup;
  const tooltipText = isToggleDisabled
    ? "paywall_group_tooltip_disabled"
    : "paywall_group_tooltip";

  const isSubscriptionGroupEnabled = watch("enable_subscription_group");

  return (
    <ToggleableField
      toggleField={
        <SubscriptionGroupToggle
          tooltipText={tooltipText}
          isToggleDisabled={isToggleDisabled}
          enableSubscriptionGroup={enableSubscriptionGroup}
        />
      }
      fieldToggleable={
        <SubscriptionGroupSelect
          isSelectDisabled={isSelectDisabled}
          paywallGroupSelectOptions={paywallGroupSelectOptions}
          className="w-7/12"
        />
      }
      hideToggleableField={!isSubscriptionGroupEnabled}
    />
  );
};
