import { useEffect, useState } from "react";
import { t } from "i18n-js";
import { useFormContext, useWatch } from "react-hook-form";
import { Icon } from "@circle-react/components/shared/Icon";
import { usePunditUserContext } from "@circle-react/contexts";
import { Button } from "@circle-react-uikit/Button";
import { Form } from "@circle-react-uikit/Form";
import { RichTextInput } from "@circle-react-uikit/RichTextInput";
import { Typography } from "@circle-react-uikit/Typography";
import { tabs } from "../../constants";
import { TabTitle } from "../TabTitle";
import "./style.scss";

const generateTempId = () =>
  `trix-editor-${Math.floor(Math.random() * 1000000)}`;

export const PostPurchaseTab = ({
  shouldDisplayWorkflowsTab,
  setActiveTab,
}) => {
  const { currentCommunitySettings } = usePunditUserContext();
  const { formState, setValue } = useFormContext();
  const isPostPurchaseEmailEnabled = useWatch({
    name: "post_purchase_email_enabled",
  });
  const isRedirectUrlEnabled = useWatch({
    name: "redirect_url_enabled",
  });

  const { isSubmitSuccessful } = formState;
  const [forceRerender, setForceRerender] = useState(generateTempId());

  useEffect(() => {
    if (!isRedirectUrlEnabled) {
      setValue("redirect_url", "");
    }
  }, [isRedirectUrlEnabled]);

  useEffect(() => {
    setForceRerender(generateTempId());
  }, [isSubmitSuccessful]);

  const onClickCreateWorkflow = () => {
    setActiveTab(tabs.WORKFLOWS_TAB);
  };

  return (
    <div className="post-purchase__container">
      <TabTitle title={t("settings.paywalls.modal.tabs.post_purchase.title")} />
      <div className="post-purchase__container__switch">
        <Form.Item
          name="thank_you_page_enabled"
          translationRoot="settings.paywalls.modal.tabs.post_purchase"
          inline
          tooltipText={t(
            "settings.paywalls.modal.tabs.post_purchase.thank_you_page_enabled_tooltip",
          )}
          hideDescription
          hideBorder
        >
          <Form.ToggleSwitch variant="small" />
        </Form.Item>
      </div>

      {shouldDisplayWorkflowsTab &&
        currentCommunitySettings?.upgrade_flows_for_workflows_paywalls_enabled && (
          <div className="bg-tertiary flex items-start rounded-md p-4">
            <div className="shrink-0">
              <Icon type="20-lightning" size={20} />
            </div>
            <div className="ml-3">
              <Typography.BodyMd>
                {t(
                  "settings.paywalls.modal.tabs.post_purchase.workflows_banner.title",
                )}
              </Typography.BodyMd>
              <div className="mt-2">
                <Typography.BodySm color="text-light">
                  {t(
                    "settings.paywalls.modal.tabs.post_purchase.workflows_banner.description",
                  )}
                </Typography.BodySm>
              </div>
              <div className="mt-2">
                <Button
                  small
                  variant="secondary"
                  onClick={onClickCreateWorkflow}
                >
                  {t(
                    "settings.paywalls.modal.tabs.post_purchase.workflows_banner.button",
                  )}
                </Button>
              </div>
            </div>
          </div>
        )}

      <div className="post-purchase__container__redirect-url-enabled-form">
        <div className="post-purchase__container__switch">
          <Form.Item
            name="redirect_url_enabled"
            translationRoot="settings.paywalls.modal.tabs.post_purchase"
            inline
            hideBorder
            hideDescription
            tooltipText={t(
              "settings.paywalls.modal.tabs.post_purchase.redirect_url_tooltip",
            )}
          >
            <Form.ToggleSwitch variant="small" />
          </Form.Item>
        </div>
        {isRedirectUrlEnabled && (
          <div className="post-purchase__container__redirect-url-enabled-form__input">
            <Form.Item
              name="redirect_url"
              translationRoot="settings.paywalls.modal.tabs.post_purchase"
              fullWidth
              hideBorder
              hideDescription
              hidePlaceholder
              hideLabel
              rules={{
                required: t(
                  "settings.paywalls.modal.tabs.post_purchase.alerts.redirect_url_is_required",
                ),
                maxLength: 255,
              }}
            >
              <Form.Input />
            </Form.Item>
          </div>
        )}
      </div>

      <div className="post-purchase__container__switch">
        <Form.Item
          name="post_purchase_email_enabled"
          translationRoot="settings.paywalls.modal.tabs.post_purchase"
          inline
          tooltipText={t(
            "settings.paywalls.modal.tabs.post_purchase.post_purchase_email_enabled_tooltip",
          )}
          hideDescription
          hideBorder
        >
          <Form.ToggleSwitch variant="small" />
        </Form.Item>
      </div>

      {isPostPurchaseEmailEnabled && (
        <div className="post-purchase__container__post-purchase-email-message-form">
          <Form.Item
            name="post_purchase_email_meta.subject"
            translationRoot="settings.paywalls.modal.tabs.post_purchase"
            fullWidth
            hideDescription
            hideBorder
            hidePlaceholder
          >
            <Form.Input />
          </Form.Item>

          <div key={`${forceRerender}`} className="trix-v2">
            <label>
              {t("settings.paywalls.modal.tabs.post_purchase.body")}
            </label>
            <RichTextInput
              id="post_purchase_email_message_input"
              name="post_purchase_email_message"
              is_private_space={false}
              preventAccidentalLeave={false}
              type="textarea"
              featureFlags={{
                embeds: false,
                videoUpload: false,
                fileUploadLink: false,
              }}
            />
          </div>

          <Form.Item
            name="post_purchase_email_meta.button_label"
            translationRoot="settings.paywalls.modal.tabs.post_purchase"
            fullWidth
            hideBorder
            hideDescription
            hidePlaceholder
          >
            <Form.Input />
          </Form.Item>

          <Form.Item
            name="post_purchase_email_meta.button_url"
            translationRoot="settings.paywalls.modal.tabs.post_purchase"
            fullWidth
            hideBorder
            hideDescription
            hidePlaceholder
          >
            <Form.Input />
          </Form.Item>
        </div>
      )}
    </div>
  );
};
