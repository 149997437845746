import { useState } from "react";
import PropTypes from "prop-types";
import {
  WORKFLOW_RUN_STATUS,
  WORKFLOW_STATUS,
  WORKFLOW_TYPE,
} from "@circle-react/components/SettingsApp/Workflows/constants";
import { DataTable } from "@circle-react-uikit/DataTableV2";
import { Archive } from "./Archive";
import { Delete } from "./Delete";
import { Duplicate } from "./Duplicate";
import { Edit } from "./Edit";
import { ReRunWorkflowForm } from "./ReRunWorkflowForm";
import { Rerun } from "./Rerun";
import { View } from "./View";
import { useActions } from "./useActions";

export const Actions = ({ workflow, refetch }) => {
  const { id, name, workflow_type: type, status, steps = [] } = workflow;
  const {
    onDuplicate,
    handleArchiveWithConfirmation,
    handleDeleteWithConfirmation,
  } = useActions({ workflowId: id, name, type, status, refetch });

  const [showRerunModal, setShowRerunModal] = useState(false);

  const isArchived = status === WORKFLOW_STATUS.ARCHIVED;
  if (isArchived) {
    return (
      <div className="min-w-[2rem]">
        <DataTable.Actions>
          <Duplicate onClick={onDuplicate} />
        </DataTable.Actions>
      </div>
    );
  }
  const isBulkOrScheduled =
    type === WORKFLOW_TYPE.BULK_ACTION || type === WORKFLOW_TYPE.SCHEDULED;
  const isBulkAction = type === WORKFLOW_TYPE.BULK_ACTION;
  const isAutomation = type === WORKFLOW_TYPE.DYNAMIC;

  const isActive = status === WORKFLOW_STATUS.ACTIVE;
  const isDraft = status === WORKFLOW_STATUS.DRAFT;
  const isInProgress = status === WORKFLOW_RUN_STATUS.IN_PROGRESS;

  const hasSteps = steps.length > 0;
  const canRun = isBulkAction && !isInProgress && hasSteps && !isDraft;

  return (
    <div className="min-w-[2rem]">
      <DataTable.Actions>
        <View workflowId={id} />
        {canRun && (
          <Rerun
            showRerunConfirmation={() => {
              setShowRerunModal(true);
            }}
          />
        )}
        <Edit
          workflowId={id}
          isAutomation={isAutomation}
          isActive={isActive}
          isBulkOrScheduled={isBulkOrScheduled}
          isInProgress={isInProgress}
        />
        {!isDraft && <Archive onClick={handleArchiveWithConfirmation} />}
        <Duplicate onClick={onDuplicate} />
        {isDraft && <Delete onClick={handleDeleteWithConfirmation} />}
      </DataTable.Actions>

      {/* Form for rerun. Moved outside the dropdown to manage focus */}
      {showRerunModal && (
        <ReRunWorkflowForm
          workflowId={id}
          hideRerunModal={() => setShowRerunModal(false)}
        />
      )}
    </div>
  );
};

Actions.propTypes = {
  workflow: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
};
