import { t } from "i18n-js";
import { Typography } from "@circle-react/components/shared/uikit/Typography";
import type { Event } from "@circle-react/types/Event";
import { Icon } from "@circle-react-shared/Icon";

interface Props {
  event: Event;
  rsvpStatus: string;
  ticketData: any;
}

export const TicketInfo = ({ rsvpStatus, ticketData, event }: Props) => {
  const { event_setting_attributes: eventData } = event;

  const { rsvp_limit_reached: isRsvpLimitReached } = eventData || {};

  const isRsvpd = rsvpStatus == "yes";
  const ticketPurchased = rsvpStatus && !ticketData.invited;
  const shouldShowPrice = !isRsvpLimitReached || isRsvpd;
  const shouldShowSoldOutMessage = isRsvpLimitReached && !isRsvpd;

  return (
    <div className="mb-6 flex flex-row items-center space-x-4">
      <div className="bg-tertiary flex h-10 w-10 flex-none items-center justify-center rounded-lg">
        <Icon
          type="20-event-ticket"
          size={20}
          className="text-dark !h-5 !w-5"
        />
      </div>
      <div className="flex items-center justify-center space-x-1 align-middle">
        {shouldShowSoldOutMessage && (
          <Typography.LabelSm weight="medium">
            {t("events.rsvp.sold_out")}
          </Typography.LabelSm>
        )}
        {shouldShowPrice && (
          <Typography.LabelSm>{ticketData.price_with_code}</Typography.LabelSm>
        )}
        {ticketPurchased && (
          <>
            <div className="bg-dark-primary-button h-1 w-1 rounded-full sm:block" />
            <Typography.LabelSm weight="medium">
              {t("events.rsvp.purchased")}
            </Typography.LabelSm>
          </>
        )}
      </div>
    </div>
  );
};
