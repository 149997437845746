import { t } from "i18n-js";
import { Icon } from "@circle-react-shared/Icon";
import { useSpaceAccessManagementContext } from "../SpaceAccessManagementContext";
import { i18nRoot } from "../constants";

export const SearchBar = () => {
  const { setSearchText, searchText } = useSpaceAccessManagementContext();

  const onChangeHandler = (e: any) => {
    setSearchText(e.target.value);
  };

  const onReset = () => {
    setSearchText("");
  };

  return (
    <div className="text-default flex w-full items-center gap-3">
      <span className="flex h-4 w-4">
        <Icon type="16-search" size={16} className="text-default" />
      </span>
      <input
        className="md:bg-primary placeholder:text-light w-full border-0 bg-inherit p-0 text-sm leading-none outline-none placeholder:opacity-100 focus:ring-0"
        type="text"
        placeholder={t([i18nRoot, "search"])}
        onChange={onChangeHandler}
        value={searchText}
        autoFocus
      />
      {searchText.length > 0 && (
        <span className="flex h-4 w-4">
          <Icon
            type="16-close"
            size={16}
            className="text-default cursor-pointer"
            onClick={onReset}
          />
        </span>
      )}
    </div>
  );
};
