import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import {
  ACTIVE_STATUS,
  STATUS,
} from "@circle-react/helpers/communityMemberSubscriptionHelpers";
import { updateOrRemoveQueryParam } from "@circle-react/helpers/urlHelpers";
import { useAdminPaywallSubscription } from "@circle-react/hooks/paywalls/useAdminPaywallSubscription";
import { paywallSubscriptionQueryString } from "@circle-react/hooks/paywalls/useAdminPaywallSubscription";
import { useRouterQueryParams } from "@circle-react/hooks/useRouterQueryParams";
import {
  allFilterTranslations,
  normalizeFilters,
} from "../../Filters/useFilters";
import { getPaginationLimitThreshold } from "../../utils";
import { RESULTS_PER_PAGE, TABS } from "../constants";

const defaultOrder = { created_at: "desc" };

const statusByTab = {
  [TABS.ALL]: null,
  [TABS.ACTIVE]: ACTIVE_STATUS.join(","),
  [TABS.CANCELED]: STATUS.CANCELED,
};

export const usePaywallSubscriptionsAdmin = () => {
  const history = useHistory();
  const queryParams = useRouterQueryParams();
  const {
    tab = TABS.ALL,
    search_key = "",
    page: pageParam = 1,
    status,
  } = queryParams;

  const [activeFilterTab, setActiveFilterTab] = useState(
    Object.values(TABS).includes(tab) ? tab : TABS.ALL,
  );
  const [searchKey, setSearchKey] = useState(search_key);
  const [page, setPage] = useState(getPaginationLimitThreshold(pageParam));

  const updateQueryParam = (keyQueryParam, value) => {
    const newQueryParams = updateOrRemoveQueryParam(keyQueryParam, value);
    history.push(`?${newQueryParams}`);
  };

  const updatePage = value => {
    updateQueryParam("page", String(value));
    setPage(value);
  };

  const setActiveFilter = tab => {
    setActiveFilterTab(tab);
    updateQueryParam("status", statusByTab[tab]);
    updateQueryParam("tab", tab);
  };

  // This function blocks calling our API when the tab is not activated
  const getSearchKey = (currentTab, tabTarget, searchKey) =>
    currentTab === tabTarget ? searchKey : "";

  useEffect(() => {
    updateQueryParam("tab", activeFilterTab);
    if (!status) {
      updateQueryParam("status", statusByTab[activeFilterTab]);
    }
  }, []);

  const allSubscriptions = useAdminPaywallSubscription({
    pageParam: page,
    perPage: RESULTS_PER_PAGE,
    orderParam: defaultOrder,
    searchKeyParams: getSearchKey(activeFilterTab, TABS.ALL, searchKey),
    filters: normalizeFilters(queryParams, allFilterTranslations.subscriptions),
    enabled: activeFilterTab === TABS.ALL,
    page,
    setPage: updatePage,
  });
  const activeSubscriptions = useAdminPaywallSubscription({
    pageParam: page,
    perPage: RESULTS_PER_PAGE,
    orderParam: defaultOrder,
    searchKeyParams: getSearchKey(activeFilterTab, TABS.ACTIVE, searchKey),
    filters: normalizeFilters(
      {
        ...queryParams,
      },
      allFilterTranslations.subscriptions,
    ),
    enabled: activeFilterTab === TABS.ACTIVE,
    page,
    setPage: updatePage,
  });
  const canceledSubscriptions = useAdminPaywallSubscription({
    pageParam: page,
    perPage: RESULTS_PER_PAGE,
    orderParam: defaultOrder,
    searchKeyParams: getSearchKey(activeFilterTab, TABS.CANCELED, searchKey),
    filters: normalizeFilters(
      {
        ...queryParams,
      },
      allFilterTranslations.subscriptions,
    ),
    enabled: activeFilterTab === TABS.CANCELED,
    page,
    setPage: updatePage,
  });

  const queryClient = useQueryClient();
  const resetAllSubscriptionToFirstPage = () => {
    updatePage(1);
    allSubscriptions.setOrder(defaultOrder);
    activeSubscriptions.setOrder(defaultOrder);
    canceledSubscriptions.setOrder(defaultOrder);
  };
  const refetchAllSubscriptions = () => {
    void queryClient.invalidateQueries({
      queryKey: [paywallSubscriptionQueryString],
    });
  };

  const subscriptionsDataByTab = {
    [TABS.ALL]: allSubscriptions,
    [TABS.ACTIVE]: activeSubscriptions,
    [TABS.CANCELED]: canceledSubscriptions,
  };

  const onSubmit = ({ searchKey = "" }) => {
    setSearchKey(searchKey);
  };

  // We don't want to display stats when filters
  // other than the basic ones are applied.
  const showStats = () => {
    const queryParamsAux = queryParams;
    delete queryParamsAux["status"];
    delete queryParamsAux["page"];
    delete queryParamsAux["tab"];

    return Object.keys(queryParamsAux).length <= 0;
  };

  return {
    subscriptionsDataByTab,
    resetAllSubscriptionToFirstPage,
    refetchAllSubscriptions,
    activeFilterTab,
    setActiveFilterTab: setActiveFilter,
    defaultValues: { searchKey },
    setSearchKey,
    onSubmit,
    showStats,
    isLoadingSubscriptions: allSubscriptions.isLoadingSubscriptions,
  };
};
