import { useEffect } from "react";
import classNames from "classnames";
import { t } from "i18n-js";
import { useFormContext } from "react-hook-form";
import {
  getLayoutOptions,
  getMobileLayoutOptions,
} from "@/react/components/Spaces/Edit/EditForm/Customize/constants";
import type { Space } from "@/react/types";
import { useMobileWebFlags } from "@circle-react/hooks/useMobileWebFlags";
import { Form } from "@circle-react-uikit/Form";

interface CustomizeLayoutFormProps {
  space: Space;
  onChange: (value: boolean) => void;
  isEventSpaceCalendarViewEnabled: boolean;
}

export const CustomizeEventLayoutForm = ({
  onChange,
  space,
  isEventSpaceCalendarViewEnabled,
}: CustomizeLayoutFormProps) => {
  const {
    formState: { dirtyFields },
  } = useFormContext();
  const { isMobileWebNewSpaceCustomizationUIEnabled } = useMobileWebFlags();

  useEffect(() => {
    if ("display_view" in dirtyFields) {
      onChange(true);
    }
  }, [dirtyFields.display_view]);

  const excludedOptions = isEventSpaceCalendarViewEnabled ? [] : ["calendar"];
  return (
    <Form.Item
      hideBorder
      hideDescription
      label={t("spaces.form.edit.customize.layout.label")}
      name="display_view"
      variant="extra-bottom-margin"
    >
      <Form.RadioGroup
        variant="box"
        name="display_view"
        fullWidth={false}
        labelOutside={!isMobileWebNewSpaceCustomizationUIEnabled}
        options={
          isMobileWebNewSpaceCustomizationUIEnabled
            ? getMobileLayoutOptions({
                excludedOptions,
                postType: space.post_type,
              })
            : getLayoutOptions({ excludedOptions, postType: space.post_type })
        }
        optionAlignment={
          isMobileWebNewSpaceCustomizationUIEnabled ? "row" : "center"
        }
        className={classNames(
          "!grid",
          isEventSpaceCalendarViewEnabled
            ? "!grid-cols-1 !gap-x-6 sm:!grid-cols-3"
            : "!grid-cols-2 !gap-x-7",
        )}
      />
    </Form.Item>
  );
};
