import I18n from "i18n-js";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { Drawer } from "@circle-react-shared/uikit/Drawer";
import { FormFields } from "../FormFields";

export interface CouponFormDrawerProps {
  refetchCoupons: () => void;
}

export const CouponFormDrawerComponent = ({
  refetchCoupons,
}: CouponFormDrawerProps) => {
  const drawer = useModal();

  return (
    <Drawer
      open={drawer.visible}
      title={I18n.t("settings.paywall_coupons.new_title")}
      onClose={drawer.hide}
      noWrapperPadding
    >
      <FormFields refetchCoupons={refetchCoupons} onClose={drawer.hide} />
    </Drawer>
  );
};

const CouponFormDrawer = ModalManager.create(
  ({ refetchCoupons }: CouponFormDrawerProps) => (
    <CouponFormDrawerComponent refetchCoupons={refetchCoupons} />
  ),
);

export const useCouponFormDrawer = () => useModal(CouponFormDrawer);
