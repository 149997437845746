import { t } from "i18n-js";
import { useFormContext } from "react-hook-form";
import { useSectionSelector } from "@circle-react/components/SettingsApp/Workflows/hooks/useSectionSelector";
import { FormTextSelect } from "@circle-react/components/shared/uikit/Form/FormTextSelect";
import { Typography } from "@circle-react-uikit/Typography";

const i18nRoot = "settings.workflows.form";

export const SectionSelector = () => {
  const {
    formState: { touchedFields },
    register,
    getValues,
  } = useFormContext();
  const isResourceTypeTouched = touchedFields.trigger?.resource_type;

  const selectedSectionId = isResourceTypeTouched
    ? null
    : getValues("trigger.resource_id");

  const { sectionOptions, handleSectionTitleChange } = useSectionSelector({
    selectedSectionId,
  });

  return (
    <>
      <input
        type="hidden"
        className="hidden"
        {...register("trigger.resource_type")}
      />
      <FormTextSelect
        name="trigger.resource_id"
        label={
          <Typography.LabelSm weight="semibold">
            {t([i18nRoot, "choose_section"])}
          </Typography.LabelSm>
        }
        options={sectionOptions}
        rules={{
          required: t([i18nRoot, "errors.required"]),
        }}
        emptyMessage={t([i18nRoot, "errors.sections.not_found"])}
        onSearchChange={handleSectionTitleChange}
      />
    </>
  );
};
