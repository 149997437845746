import type { ReactNode } from "react";
import { StandardLayoutHeaderContainer } from "@/react/components/Layout/StandardLayoutV2/StandardLayoutHeaderContainer";
import { usePunditUserContext } from "@circle-react/contexts";
import { StandardLayoutV2 } from "./StandardLayoutV2";
import { CoverImageContainer } from "./StandardLayoutV2/CoverImageContainer";

export interface VersionedContentProps {
  children: ReactNode;
  fullHeight?: boolean;
  fullWidth?: boolean;
  noPadding?: boolean;
}

export const VersionedContent = ({
  children,
  fullHeight = true,
  fullWidth = false,
  noPadding = false,
}: VersionedContentProps) => {
  const { isLoading } = usePunditUserContext();

  if (isLoading) {
    return null;
  }

  return (
    <>
      <StandardLayoutHeaderContainer />
      <CoverImageContainer />
      <StandardLayoutV2.Content
        fullWidth={fullWidth}
        fullHeight={fullHeight}
        noPadding={noPadding}
      >
        {children}
      </StandardLayoutV2.Content>
    </>
  );
};
