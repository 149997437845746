import { t } from "i18n-js";
import { dateStringToTimeAgo } from "@circle-react/helpers/dateTimeHelpers/timeAgo";
import { Button } from "@circle-react-uikit/Button";
import { Typography } from "@circle-react-uikit/Typography";
import { LocationIcon } from "./LocationIcon";

interface EventFinishedProps {
  endsAt: string;
  dataTestid: string;
  eventData: any;
  rsvpedEvent: boolean;
}
export const EventFinished = ({
  eventData,
  endsAt,
  dataTestid,
  rsvpedEvent,
}: EventFinishedProps) => {
  const {
    location_type: locationType,
    virtual_location_url: virtualLocationUrl,
    hide_location_from_non_attendees: shouldHideLocationFromNonAttendees,
  } = eventData;

  const shouldHideLocationFromCurrentMember =
    shouldHideLocationFromNonAttendees && !rsvpedEvent;

  return (
    <>
      <div
        className="flex flex-row items-center space-x-4 pt-4"
        data-testid={dataTestid}
      >
        <LocationIcon icon="20-calendar" />
        <div className="flex flex-col truncate">
          <Typography.LabelSm>
            {t("events.details.event_finished", {
              time_string: dateStringToTimeAgo(endsAt),
            })}
          </Typography.LabelSm>
        </div>
      </div>
      {!shouldHideLocationFromCurrentMember &&
        locationType === "virtual" &&
        virtualLocationUrl && (
          <Button
            variant="circle"
            full
            onClick={() => {
              window.open(virtualLocationUrl, "_blank", "noreferrer");
            }}
            className="mt-6"
          >
            <Typography.LabelSm weight="semibold" color="text-circle-button">
              {t(`events.locations.event_link`)}
            </Typography.LabelSm>
          </Button>
        )}
    </>
  );
};
