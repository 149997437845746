import PropTypes from "prop-types";
import { defer } from "lodash";
import { useMutation, useQueryClient } from "react-query";
import { useCurrentSpaceContext } from "@circle-react/contexts";
import { reactQueryPost } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { getCourseSectionsKey } from "@circle-react/hooks/courses/useCoursesApi";
import { Button } from "@circle-react-uikit/Button";

export const AddSection = ({
  sections,
  variant = "primary",
  setIsNameEditable,
}) => {
  const { data: space } = useCurrentSpaceContext();
  const queryClient = useQueryClient();
  const addSectionMutation = useMutation(
    data =>
      reactQueryPost(
        internalApi.courses.createSection({ courseId: space.id }),
        data,
      ),
    {
      onSuccess: async data => {
        await queryClient.invalidateQueries(getCourseSectionsKey(space.id));
        defer(() => {
          const sectionElement = document.querySelector(
            `#section-item-${data.id}`,
          );
          if (sectionElement) {
            sectionElement.scrollIntoView({ behavior: "smooth" });
          }
          setIsNameEditable(data.id);
        });
      },
    },
  );
  return (
    <Button
      variant={variant}
      disabled={addSectionMutation.isLoading}
      onClick={() => {
        addSectionMutation.mutate({
          course_section: {
            name: `Untitled section - ${sections.length + 1}`,
            drip_delay: 0,
            lesson_order: [],
          },
        });
      }}
    >
      {I18n.t(
        addSectionMutation.isLoading
          ? "courses.edit.sections.adding"
          : "courses.edit.sections.add_section",
      )}
    </Button>
  );
};

AddSection.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.object),
  variant: PropTypes.oneOf(["primary", "secondary"]),
  setIsNameEditable: PropTypes.func.isRequired,
};
