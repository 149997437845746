import { useMemo } from "react";
import { useQuery } from "react-query";
import { reactQueryGet } from "@/react/helpers/backendRequestHelpers";
import { isCommunityAdmin } from "@/react/helpers/communityMemberHelpers";
import { internalApi } from "@/react/helpers/urlHelpers";
import { usePunditUserContext } from "@circle-react/contexts";

const fetchPaywalls = (params = {}) =>
  reactQueryGet(internalApi.paywalls.index({ params }));

export const usePaywalls = (fetchParams = {}) => {
  const { currentCommunityMember } = usePunditUserContext();

  const {
    data: paywalls = [],
    isSuccess,
    isLoading,
  } = useQuery("PAYWALLS", () => fetchPaywalls(fetchParams), {
    enabled: !!isCommunityAdmin(currentCommunityMember),
  });

  const activePaywalls = useMemo(() => {
    if (!paywalls) return [];
    return paywalls.filter(paywall => paywall.status === "active");
  }, [paywalls]);

  const hasActivePaywalls = useMemo(
    () => (activePaywalls ? activePaywalls?.length > 0 : false),
    [activePaywalls],
  );

  const hasPaywalls = useMemo(
    () => (paywalls ? paywalls?.length > 0 : false),
    [paywalls],
  );

  return {
    activePaywalls,
    isSuccess,
    hasActivePaywalls,
    hasPaywalls,
    isLoading,
    allPaywalls: paywalls,
  };
};
