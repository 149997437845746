import { createContext, useContext } from "react";

const ChangePlanFormContext = createContext<any>({});
ChangePlanFormContext.displayName = "ChangePlanFormContext";

export const ChangePlanFormContextProvider = (props: any) => {
  const { children } = props;

  return (
    <ChangePlanFormContext.Provider value={props}>
      {children}
    </ChangePlanFormContext.Provider>
  );
};

export const useChangePlanFormContext = () => useContext(ChangePlanFormContext);
