export const checkIfTipTapValueEmpty = (tiptapValue: any) => {
  const { body } = tiptapValue || {};
  if (!body) return true;
  const { content } = body || {};
  if (!content) return true;
  return !content.some((item: any) => {
    if (item.type === "paragraph" && item.content) {
      return true;
    }
    return undefined;
  });
};
