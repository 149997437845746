import { useEffect, useState } from "react";
import {
  HMSNotificationTypes,
  useHMSNotifications,
} from "@100mslive/react-sdk";
import { useHMSLocalSignal } from "./useHMSLocalSignal";

const MINIMUM_SIGNAL_QUALITY = 1;

export const useHMSWeakSignalIndicator = () => {
  const [isReconnecting, setIsReconnecting] = useState(false);
  const notification = useHMSNotifications();
  useEffect(() => {
    if (!notification) {
      return;
    }
    switch (notification.type) {
      case HMSNotificationTypes.RECONNECTING:
        setIsReconnecting(true);
        break;
      case HMSNotificationTypes.RECONNECTED:
        setIsReconnecting(false);
        break;
    }
  }, [notification]);

  const { localPeerDownlinkQuality } = useHMSLocalSignal();
  const isWeakSignal =
    !!localPeerDownlinkQuality &&
    localPeerDownlinkQuality >= 0 &&
    localPeerDownlinkQuality <= MINIMUM_SIGNAL_QUALITY;

  return isWeakSignal || isReconnecting;
};
