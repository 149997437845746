import { t } from "i18n-js";
import { usePaywallFilterList } from "@circle-react/hooks/paywalls/usePaywallFilterList";
import {
  CheckboxFilter,
  CheckboxSelectedText,
  Filter,
  useFilterUrl,
} from "@circle-react-shared/Filter";
import { Loader } from "@circle-react-uikit/Loader";

export const PaywallsFilter = ({
  isInitialOpen,
  changeUrl = true,
  value,
  localeRoot,
  queryParamsKey,
  filterName,
  filterParams = {},
}) => {
  const { queryParams, updateUrl } = useFilterUrl();

  const { paywalls, isPaywallsLoading } = usePaywallFilterList(
    filterName,
    filterParams,
  );

  return (
    <Filter
      chip={t([localeRoot, "chip"])}
      title={t([localeRoot, "title"])}
      selectedText={value || queryParams[queryParamsKey]}
      renderSelectedText={props => (
        <CheckboxSelectedText items={paywalls} {...props} />
      )}
      isInitialOpen={isInitialOpen}
      onApply={value => {
        updateUrl("page", 1);
        changeUrl && updateUrl(queryParamsKey, value);
      }}
      shouldShowRemoveButton
    >
      {isPaywallsLoading ? (
        <Loader />
      ) : (
        <CheckboxFilter
          options={paywalls}
          legend={t([localeRoot, "legend"])}
          name={filterName}
          activeText={value ?? queryParams[queryParamsKey]}
          hasSearch
          searchProps={{
            placeholder: t([localeRoot, "input_placeholder"]),
          }}
        />
      )}
    </Filter>
  );
};
