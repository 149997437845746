import { Suspense } from "react";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { PageLoader } from "@circle-react/pages/PageLoader";

const DefaultMembersSettingsComponent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "DefaultMembersSettingsPage" */ "@circle-react/components/SettingsApp/DefaultMembers"
    ),
);

export interface DefaultMembersSettingsPageProps {
  pageTitle: string;
}

export const DefaultMembersSettingsPage = ({
  pageTitle,
}: DefaultMembersSettingsPageProps) => (
  <Suspense fallback={<PageLoader />}>
    <DefaultMembersSettingsComponent pageTitle={pageTitle} />
  </Suspense>
);
