import { t } from "i18n-js";
import { isEventsSpace } from "@circle-react/helpers/spaceHelpers";
import type { Option } from "@circle-react-uikit/Form/RadioGroup/types";

const DEFAULT_MOBILE_ICON_CLASSNAME = "!h-6 !w-6 !text-dark";

const TRANSLATION_BASE = "spaces.form.edit.customize.layout";

const defaultLayoutOptions: Option[] = [
  {
    label: t([TRANSLATION_BASE, "feed"]),
    icon: {
      type: "feed-thumbnail",
      viewBox: "0 0 75 78",
    },
    value: "posts",
  },
  {
    label: t([TRANSLATION_BASE, "list"]),
    icon: {
      type: "list-thumbnail",
      viewBox: "0 0 75 77",
    },
    value: "list",
  },
  {
    label: t([TRANSLATION_BASE, "card"]),
    icon: {
      type: "card-thumbnail",
      viewBox: "0 0 75 77",
    },
    value: "cards",
  },
];

const DEFAULT_MOBILE_LAYOUT_OPTIONS: Option[] = [
  {
    label: t([TRANSLATION_BASE, "feed"]),
    icon: {
      type: "customize-layout-feed",
      viewBox: "0 0 25 24",
      className: DEFAULT_MOBILE_ICON_CLASSNAME,
    },
    value: "posts",
  },
  {
    label: t([TRANSLATION_BASE, "list"]),
    icon: {
      type: "customize-layout-list",
      viewBox: "0 0 25 24",
      className: DEFAULT_MOBILE_ICON_CLASSNAME,
    },
    value: "list",
  },
  {
    label: t([TRANSLATION_BASE, "card"]),
    icon: {
      type: "20-card-view-mobile",
      viewBox: "0 0 25 24",
      className: DEFAULT_MOBILE_ICON_CLASSNAME,
    },
    value: "cards",
  },
];

const DEFAULT_EVENT_MOBILE_LAYOUT_OPTIONS: Option[] = [
  {
    label: t([TRANSLATION_BASE, "thumbnail"]),
    icon: {
      type: "customize-layout-feed",
      viewBox: "0 0 25 24",
      className: DEFAULT_MOBILE_ICON_CLASSNAME,
    },
    value: "thumbnail",
  },
  {
    label: t([TRANSLATION_BASE, "list"]),
    icon: {
      type: "customize-layout-list",
      viewBox: "0 0 25 24",
      className: DEFAULT_MOBILE_ICON_CLASSNAME,
    },
    value: "posts",
  },
  {
    label: t([TRANSLATION_BASE, "calendar"]),
    icon: {
      type: "support-widget-calendar",
      viewBox: "0 0 24 24",
      className: DEFAULT_MOBILE_ICON_CLASSNAME,
    },
    value: "calendar",
  },
];

const DEFAULT_EVENT_LAYOUT_OPTIONS: Option[] = [
  {
    label: t([TRANSLATION_BASE, "thumbnail"]),
    icon: {
      type: "event-layout-thumbnail",
      viewBox: "0 0 118 84",
    },
    value: "thumbnail",
  },
  {
    label: t([TRANSLATION_BASE, "list"]),
    icon: {
      type: "event-layout-list",
      viewBox: "0 0 118 90",
    },
    value: "posts",
  },
  {
    label: t([TRANSLATION_BASE, "calendar"]),
    icon: {
      type: "event-layout-calendar",
      viewBox: "0 0 120 90",
    },
    value: "calendar",
  },
];

const IMAGE_SPACE_OPTIONS: Option[] = [
  {
    label: t([TRANSLATION_BASE, "feed"]),
    icon: {
      type: "20-feed",
      viewBox: "-2 -1 25 24",
      className: DEFAULT_MOBILE_ICON_CLASSNAME,
    },
    value: "feed",
  },
  {
    label: t([TRANSLATION_BASE, "masonry"]),
    icon: {
      type: "20-masonry",
      viewBox: "-2 -1 25 24",
      className: DEFAULT_MOBILE_ICON_CLASSNAME,
    },
    value: "masonry",
  },
  {
    label: t([TRANSLATION_BASE, "grid"]),
    icon: {
      type: "20-grid",
      viewBox: "-2 -1 25 24",
      className: DEFAULT_MOBILE_ICON_CLASSNAME,
    },
    value: "grid",
  },
];

interface GetLayoutOptionsProps {
  excludedOptions: string[];
  postType: string;
}

export const getLayoutOptions = ({
  excludedOptions = [],
  postType,
}: GetLayoutOptionsProps) => {
  const layoutOptions =
    postType === "event"
      ? DEFAULT_EVENT_LAYOUT_OPTIONS
      : postType === "image"
        ? IMAGE_SPACE_OPTIONS
        : defaultLayoutOptions;
  return layoutOptions.filter(
    option => !excludedOptions.includes(option.value),
  );
};

interface GetMobileLayoutOptionsProps {
  excludedOptions: string[];
  postType: string;
}

export const getMobileLayoutOptions = ({
  excludedOptions = [],
  postType,
}: GetMobileLayoutOptionsProps) => {
  const layoutOptions =
    postType === "event"
      ? DEFAULT_EVENT_MOBILE_LAYOUT_OPTIONS
      : postType === "image"
        ? IMAGE_SPACE_OPTIONS
        : DEFAULT_MOBILE_LAYOUT_OPTIONS;

  return layoutOptions.filter(
    option => !excludedOptions.includes(option.value),
  );
};

export const getVisibleTabOptions = ({ space }: any): Option[] => {
  if (isEventsSpace(space)) {
    return [
      {
        label: t("spaces.form.edit.customize.visible_tabs.upcoming"),
        value: "upcoming",
      },
      {
        label: t("spaces.form.edit.customize.visible_tabs.past"),
        value: "past",
      },
      {
        label: t("spaces.form.edit.customize.visible_tabs.members"),
        value: "members",
      },
    ];
  }

  return [
    {
      label: t("spaces.form.edit.customize.visible_tabs.posts"),
      value: "posts",
    },
    {
      label: t("spaces.form.edit.customize.visible_tabs.members"),
      value: "members",
    },
  ];
};
