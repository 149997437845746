import { useMutation } from "react-query";
import { reactQueryPut } from "@/react/helpers/backendRequestHelpers";
import { internalApi } from "@/react/helpers/urlHelpers";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";

export const useUpdateSpaceMemberModerator = options => {
  const mutation = async ({ spaceId, memberId, isModerator }) => {
    const communityMemberSettings = await reactQueryGet(
      internalApi.communityMember.settings({ memberId }),
    );

    const moderatingSpaceIds =
      communityMemberSettings.moderating_space_ids || [];

    const newModeratingSpaceIds = isModerator
      ? [...moderatingSpaceIds, spaceId]
      : moderatingSpaceIds.filter(id => id !== spaceId);

    return reactQueryPut(internalApi.communityMember.update({ memberId }), {
      ...communityMemberSettings,
      moderator_space_ids: newModeratingSpaceIds,
    });
  };

  return useMutation(mutation, options);
};
