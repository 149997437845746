import { Suspense } from "react";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { PageLoader } from "@circle-react/pages/PageLoader";

const LegalComponent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "LegalSettingsPage" */ "@circle-react/components/SettingsApp/LegalSettings"
    ),
);

export const LegalSettingsPage = () => (
  <Suspense fallback={<PageLoader />}>
    <LegalComponent />
  </Suspense>
);
