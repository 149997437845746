import { useFormContext } from "react-hook-form";
import { Typography } from "@circle-react-uikit/Typography";

export function DayCheckbox({ name, label, value }: any) {
  const { register } = useFormContext();

  return (
    <label>
      <input
        type="checkbox"
        value={value}
        className="peer sr-only"
        {...register(name)}
      />
      <span className="border-disabled peer-checked:border-secondary peer-checked:bg-secondary flex flex-wrap items-center justify-center rounded-md border px-3 py-2 uppercase">
        <Typography.LabelXxs weight="semibold">{label}</Typography.LabelXxs>
      </span>
    </label>
  );
}
