import { useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { usePaywalls } from "@/react/components/PaywallCoupons/Admin/FormFields/usePaywalls";
import { isPercent } from "@circle-react/helpers/paywallCouponHelpers";
import { paywallTypes } from "@circle-react/types";
import { Form } from "@circle-react-uikit/Form";
import { Loader } from "@circle-react-uikit/Loader";
import { getPaywallOptions } from "../constants";

export const Paywalls = () => {
  const { watch } = useFormContext();

  const {
    activePaywalls,
    isLoading: isLoadingPaywalls,
    isSuccess,
  } = usePaywalls({ type: [paywallTypes.space, paywallTypes.event] });

  const type = watch("type");
  const currencyId = watch("currency_id");
  const all_paywalls = watch("all_paywalls");

  const [searchText, setSearchText] = useState("");

  // Cleaning searchText in the first render
  useEffect(() => {
    setSearchText("");
  }, []);

  const queryableActivePaywalls = useMemo(
    () =>
      activePaywalls
        .filter(paywall => isPercent(type) || paywall.currency.id == currencyId)
        .filter(paywall => {
          const paywallName = paywall.display_name;
          const isSearchTextEmpty = !searchText;
          return (
            isSearchTextEmpty ||
            paywallName.toLowerCase().search(searchText.toLowerCase()) !== -1
          );
        })
        .map(paywall => ({ value: paywall.id, text: paywall.display_name })),
    [activePaywalls, currencyId, type, searchText],
  );

  // Form.Select always expect to receive the data ready, we can't render it without this
  if (isLoadingPaywalls || !isSuccess) {
    return <Loader />;
  }

  const onSearch = searchText => {
    setSearchText(searchText);
  };

  return (
    <>
      <Form.Item
        name="all_paywalls"
        translationRoot="settings.paywall_coupons.form"
        hideBorder
        hideDescription
        fullWidth
      >
        <Form.SelectNative
          name="all_paywalls"
          options={getPaywallOptions()}
          data-testid="coupon_applies_to"
        />
      </Form.Item>

      {all_paywalls == "false" && (
        <Form.Item
          name="paywall_ids"
          translationRoot="settings.paywall_coupons.form"
          hideBorder
          hideDescription
          fullWidth
        >
          <Form.Select
            name="paywall_ids"
            variant="tags"
            data={queryableActivePaywalls}
            onSearch={onSearch}
            minSearchValueLength={0}
            showArrow={false}
          />
        </Form.Item>
      )}
    </>
  );
};
