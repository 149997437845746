import { filter, some } from "lodash";
import { isPriceEligibleForTrial } from "@circle-react/helpers/paywallPriceHelpers";

export const PAYWALL_STATUS = Object.freeze({
  DRAFT: "draft",
  ACTIVE: "active",
  INACTIVE: "inactive",
});

export const PAYWALL_NON_DRAFT_STATUSES = Object.freeze(["active", "inactive"]);

export const isActive = paywall => paywall.status === PAYWALL_STATUS.ACTIVE;

export const isInactive = paywall => paywall.status === PAYWALL_STATUS.INACTIVE;

export const isPaywallEligibleForTrial = paywall =>
  some(paywall.stripe_prices_attributes, paywallPrice =>
    isPriceEligibleForTrial(paywallPrice),
  );

export const paywallsEligibleForTrial = paywalls =>
  filter(paywalls, paywall => isPaywallEligibleForTrial(paywall));

export const isEditingMode = currentPaywallStatus =>
  currentPaywallStatus !== undefined;

export const shouldDisplayWorkflowsTab = currentPaywallStatus =>
  currentPaywallStatus === PAYWALL_STATUS.ACTIVE &&
  isEditingMode(currentPaywallStatus);
