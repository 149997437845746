import classNames from "classnames";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import type { CommunityLogoProps } from "@circle-react-shared/CommunityLogo";
import { CommunityLogo } from "@circle-react-shared/CommunityLogo";

export interface CommunitySectionPresentationProps extends CommunityLogoProps {
  isActive?: boolean;
  name?: string;
  href?: string;
}

export const CommunitySectionPresentation = ({
  isActive = false,
  href = "#",
  name,
  ...rest
}: CommunitySectionPresentationProps) => {
  const { isV3Enabled } = useIsV3();
  return (
    <a
      data-testid="community-switcher-link"
      className={classNames("switcher__icon", {
        active: isActive,
        "hover:bg-c-sidebar-hover focus:bg-c-sidebar-hover !m-0 flex items-center justify-center rounded-xl border-2 border-transparent transition-colors duration-150":
          isV3Enabled,
        "border-secondary": isActive && isV3Enabled,
      })}
      href={href}
      aria-label={name}
    >
      <CommunityLogo includeName={false} name={name} {...rest} />
    </a>
  );
};
