import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useLocalStorage } from "../utils/useLocalStorage";
import {
  ANDROID_OS_REGEX,
  APP_BANNER_LOCAL_STORAGE_KEY,
  DAYS_APP_BANNER_SHOULD_BE_HIDDEN,
  MOBILE_REGEX,
  ONE_DAY_DURATION,
} from "./constants";

export const useAppBanner = () => {
  const { getValue: getLocalStorageValue } = useLocalStorage(
    APP_BANNER_LOCAL_STORAGE_KEY,
  );

  const [mobileProperties, setMobileProperties] = useState({
    isMobile: false,
    isMobileOsAndroid: false,
  });
  const [isAppIdPresentInMeta, setIsAppIdPresentInMeta] = useState(false);
  const [
    isAppBannerDismissedWithinTimeframe,
    setIsAppBannerDismissedWithinTimeframe,
  ] = useState(false);

  const location = useLocation();

  const isGettingStartedPage = location.pathname === "/getting-started";
  const isCurrentDeviceAndroidMobile =
    mobileProperties.isMobile && mobileProperties.isMobileOsAndroid;

  const shouldRenderAppBanner =
    isAppIdPresentInMeta &&
    isAppBannerDismissedWithinTimeframe &&
    isCurrentDeviceAndroidMobile &&
    !isGettingStartedPage;

  const checkIfAppIdIsPresent = () => {
    const appId = document.querySelector('meta[name="google-play-app"]');
    appId ? setIsAppIdPresentInMeta(true) : setIsAppIdPresentInMeta(false);
  };

  const checkIfMobileOsIsAndroid = userAgent => {
    const isAndroid = ANDROID_OS_REGEX.test(userAgent);
    setMobileProperties(mobileProperties => ({
      ...mobileProperties,
      isMobileOsAndroid: isAndroid,
    }));
  };

  const checkIfDeviceIsMobile = userAgent => {
    const isMobile = MOBILE_REGEX.test(userAgent);
    setMobileProperties(mobileProperties => ({
      ...mobileProperties,
      isMobile,
    }));
  };

  const checkIfAppBannerHasBeenDismissedBefore = () => {
    const appBannerLocalStorageValue = getLocalStorageValue();

    if (appBannerLocalStorageValue) {
      const appBannerDismissedAt = new Date(
        appBannerLocalStorageValue.dismissedAt,
      );
      const now = new Date();
      const diffTime = Math.abs(now - appBannerDismissedAt);
      const diffDays = Math.ceil(diffTime / ONE_DAY_DURATION);

      if (diffDays > DAYS_APP_BANNER_SHOULD_BE_HIDDEN) {
        setIsAppBannerDismissedWithinTimeframe(true);
      }
    } else {
      setIsAppBannerDismissedWithinTimeframe(true);
    }
  };

  useEffect(() => {
    const userAgent = navigator.userAgent;

    checkIfAppIdIsPresent();
    checkIfDeviceIsMobile(userAgent);
    checkIfMobileOsIsAndroid(userAgent);
    checkIfAppBannerHasBeenDismissedBefore();
  }, []);

  return { shouldRenderAppBanner };
};
