import PropTypes from "prop-types";
import { ProfileSectionUserMenu } from "./ProfileSectionUserMenu";
import { ProfileSectionWrapper } from "./ProfileSectionWrapper";

export const ProfileSectionView = ({
  headline = "",
  imageUrl = "",
  name = "",
  onEditProfileClick,
  onSignOutClick,
  publicUid = "",
  showMemberBilling = false,
  canAccessAuthenticationPage = false,
  canShowPaywallsAffiliate = false,
  children,
  className,
}) => (
  <ProfileSectionWrapper className={className}>
    <ProfileSectionUserMenu
      headline={headline}
      imageUrl={imageUrl}
      name={name}
      onEditProfileClick={onEditProfileClick}
      onSignOutClick={onSignOutClick}
      publicUid={publicUid}
      showMemberBilling={showMemberBilling}
      canAccessAuthenticationPage={canAccessAuthenticationPage}
      canShowPaywallsAffiliate={canShowPaywallsAffiliate}
    />
    {children}
  </ProfileSectionWrapper>
);

ProfileSectionView.propTypes = {
  children: PropTypes.node,
  headline: PropTypes.string,
  imageUrl: PropTypes.string,
  name: PropTypes.string,
  onEditProfileClick: PropTypes.func,
  onSignOutClick: PropTypes.func,
  publicUid: PropTypes.string,
  showLiveButton: PropTypes.bool,
  showMemberBilling: PropTypes.bool,
  canAccessAuthenticationPage: PropTypes.bool,
  className: PropTypes.string,
};
