import { t } from "i18n-js";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { ConfirmationModal } from "@circle-react-shared/ModalsV2/ConfirmationModal";

interface ContinueEditConfirmationModalContentProps {
  disabled?: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  title: string;
  body: string;
}

export const ContinueEditConfirmationModalContent = ({
  onConfirm,
  onCancel,
  disabled = false,
  title,
  body,
}: ContinueEditConfirmationModalContentProps) => {
  const modal = useModal();
  return (
    <ConfirmationModal
      isOpen={modal.visible}
      onClose={modal.hide}
      confirmVariant="circle"
      confirmText={t("settings.workflows.continue_to_edit")}
      title={title}
      cancelText={t("settings.workflows.cancel")}
      onConfirm={onConfirm}
      onCancel={onCancel}
      disabled={disabled}
    >
      {body}
    </ConfirmationModal>
  );
};

interface ContinueEditConfirmationModalProps {
  onConfirm: () => void;
  disabled?: boolean;
  onCancel: () => void;
  title: string;
  body: string;
}

export const ContinueEditConfirmationModal =
  ModalManager.create<ContinueEditConfirmationModalProps>(
    ({ onConfirm, onCancel, disabled = false, title, body }) => (
      <ContinueEditConfirmationModalContent
        onConfirm={onConfirm}
        onCancel={onCancel}
        disabled={disabled}
        title={title}
        body={body}
      />
    ),
  );

export const useContinueEditConfirmationModal = () =>
  useModal(ContinueEditConfirmationModal);
