import get from "lodash/get";
import {
  DEFAULT_PAGE_SIZE,
  SORT_OPTIONS_MAP,
  useAllWorkflowsInfiniteQuery,
} from "@circle-react/hooks/useAllWorkflowsInfiniteQuery";
import { useFilterUrl } from "@circle-react-shared/Filter";
import { useWorkflowsData } from "../../../context/WorkflowsDataProvider";
import { usePageQuery } from "./usePageQuery";

export const useTable = ({ workflowType, status }) => {
  const { queryParams } = useFilterUrl();
  const { pageSize = DEFAULT_PAGE_SIZE, category } = useWorkflowsData();
  const { setPage, initialPage } = usePageQuery(1, !category);

  const query = get(queryParams, "query", "");

  const infiniteQuery = useAllWorkflowsInfiniteQuery({
    perPage: pageSize,
    sort: SORT_OPTIONS_MAP.LATEST,
    workflowType,
    status,
    initialPage,
    query,
    options: {
      keepPreviousData: false,
    },
  });

  const totalWorkflows = get(infiniteQuery?.pages[0], "total_count", 0);
  const isLoading = infiniteQuery?.isLoading;

  return {
    infiniteQuery,
    totalWorkflows,
    isLoading,
    setPage,
    initialPage,
    defaultPageSize: pageSize,
  };
};
