import PropTypes from "prop-types";
import classNames from "classnames";
import { useAnyHoverMediaQuery } from "@circle-react/hooks/useMediaQuery";

export const SpaceGroupNameWrapper = ({
  isDropdownOpen,
  canManage,
  children,
}) => {
  const canHover = useAnyHoverMediaQuery();
  // Here width is set to be 100% - width of both icon buttons and also the margin between them and the space group name
  const spaceGroupClass = canManage
    ? "max-w-[calc(100%-56px)]"
    : "max-w-[calc(100%-32px)]";
  const groupHoverClass = canManage
    ? "group-hover:max-w-[calc(100%-56px)]"
    : "group-hover:max-w-[calc(100%-32px)]";

  return (
    <div
      className={classNames("flex w-full space-x-px", {
        [groupHoverClass]: !isDropdownOpen,
        [spaceGroupClass]: isDropdownOpen || !canHover,
      })}
    >
      {children}
    </div>
  );
};

SpaceGroupNameWrapper.propTypes = {
  isDropdownOpen: PropTypes.bool,
  children: PropTypes.node.isRequired,
  isV2_1: PropTypes.bool,
};
