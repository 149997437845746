import {
  CurrentCommunityContextProvider,
  SpaceGroupsContextProvider,
} from "../../../contexts";
import { NewForm } from "./NewForm";

export const New = props => (
  <CurrentCommunityContextProvider>
    <SpaceGroupsContextProvider>
      <NewForm {...props} />
    </SpaceGroupsContextProvider>
  </CurrentCommunityContextProvider>
);
