import { RadioGroup } from "@headlessui/react";
import classNames from "classnames";

export const PaymentMethodOption = ({ children, value, className }: any) => (
  <RadioGroup.Option
    value={value}
    className={({ checked }) =>
      classNames(
        "bg-primary hover:border-secondary relative flex cursor-pointer justify-center rounded-lg border py-3 transition-colors focus:outline-none",
        {
          "border-secondary bg-secondary border-1": checked,
          "border-primary": !checked,
        },
        className,
      )
    }
  >
    <span className="flex flex-row items-center space-x-2">{children}</span>
  </RadioGroup.Option>
);
