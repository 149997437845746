import {
  reactQueryDelete,
  reactQueryGet,
  reactQueryPatch,
  reactQueryPost,
  reactQueryPut,
} from "../helpers/backendRequestHelpers";
import { internalApi } from "../helpers/urlHelpers";

interface ListBroadcastsParams {
  params: {
    status?: string;
    page?: number;
    per_page?: number;
    query?: string;
  };
}

export interface ShowBroadcastParams {
  id: number;
}

const list = ({ params = {} }: ListBroadcastsParams) =>
  reactQueryGet(internalApi.broadcasts.list({ params }));

const create = (params: any) =>
  reactQueryPost(internalApi.broadcasts.create(), params);

const show = ({ id }: ShowBroadcastParams) =>
  reactQueryGet(internalApi.broadcasts.show({ id }));

const update = ({ id, body }: any) =>
  reactQueryPut(internalApi.broadcasts.show({ id }), body);

const sendNow = ({ id }: { id: number }) =>
  reactQueryPatch(internalApi.broadcasts.sendNow({ id }));

const duplicate = ({ id }: { id: number }) =>
  reactQueryPut(internalApi.broadcasts.duplicate({ id }));

const recipientList = ({ id, params = {} }: { id: number; params: unknown }) =>
  reactQueryGet(internalApi.broadcasts.recipientList({ id, params }));

const report = ({ id }: { id: number }) =>
  reactQueryGet(internalApi.broadcasts.report({ id }));

const recipients = ({ id, params = {} }: { id: number; params: object }) =>
  reactQueryGet(internalApi.broadcasts.recipients({ id, params }));

const cancelSchedule = ({ id }: { id: number }) =>
  reactQueryPatch(internalApi.broadcasts.cancelSchedule({ id }));

const rename = ({
  id,
  body,
}: {
  id: number;
  body: { internal_name: string };
}) => reactQueryPatch(internalApi.broadcasts.rename({ id }), body);

const sendTestEmail = ({ id }: { id: number }) =>
  reactQueryPatch(internalApi.broadcasts.sendTestEmail({ id }));

const deleteBroadcast = ({ id }: { id: number }) =>
  reactQueryDelete(internalApi.broadcasts.deleteBroadcast({ id }));

export const broadcastsApi = {
  list,
  create,
  show,
  update,
  sendNow,
  duplicate,
  recipientList,
  report,
  recipients,
  cancelSchedule,
  rename,
  sendTestEmail,
  deleteBroadcast,
};
