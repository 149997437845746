import { useEffect, useMemo } from "react";
import { flatten } from "lodash";
import { useInfiniteQuery } from "react-query";
import { usePunditUserContext } from "@circle-react/contexts";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";
import { isCommunityAdmin } from "@circle-react/helpers/communityMemberHelpers";
import { MemberTagsVisibleInMemberList } from "@circle-react/helpers/memberTagsHelper";
import { internalApi } from "@circle-react/helpers/urlHelpers";

/**
 * @todo
 * This is extracted from MemberFilters/TagsFilter
 * Check if this can be loaded on scroll or call unpaginate query?
 */
export function useTagsFilter() {
  const { currentCommunityMember } = usePunditUserContext();
  const isAdmin = isCommunityAdmin(currentCommunityMember);

  const fetchPage = ({ pageParam = 1 }) =>
    reactQueryGet(
      internalApi.memberTags.index({
        params: {
          page: pageParam,
        },
      }),
    );

  const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteQuery(
    ["member-tags"],
    fetchPage,
    {
      getNextPageParam: lastPage =>
        lastPage.has_next_page ? lastPage.page + 1 : undefined,
    },
  );

  useEffect(() => {
    if (hasNextPage) {
      void fetchNextPage();
    }
  }, [data]);

  const memberTags = useMemo(() => {
    const flatMemberRecords = flatten(data?.pages.map(page => page.records));
    const formatTags = (tag: any) => ({
      label: tag.name,
      richLabel: (
        <span className="flex gap-1.5">
          {tag.custom_emoji_url ? (
            <img
              loading="lazy"
              className="mt-[3px] block h-4"
              src={tag.custom_emoji_url}
              alt={tag.name}
            />
          ) : (
            <span>{tag.emoji}</span>
          )}
          <span>{tag.name}</span>
        </span>
      ),
      value: tag.id.toString(),
    });

    if (isAdmin) {
      return flatMemberRecords.map(formatTags);
    }

    return MemberTagsVisibleInMemberList({
      memberTags: flatMemberRecords,
    }).map(formatTags);
  }, [data, isAdmin]);

  return {
    isLoading,
    memberTags,
  };
}
