import { useMemo } from "react";
import { t } from "i18n-js";
import {
  BULK_ACTION_ENTRY_RULE_TYPE_ICON,
  WORKFLOW_TYPE,
} from "@circle-react/components/SettingsApp/Workflows/constants";
import { getEventIcon } from "../../Events";

const i18nRoot = "settings.workflows.form";

export const useEntryRule = ({ workflowType, trigger, audience } = {}) => {
  const isDynamic = workflowType === WORKFLOW_TYPE.DYNAMIC;
  const isBulkAction = workflowType === WORKFLOW_TYPE.BULK_ACTION;

  const { trigger_type: triggerType } = trigger || {};
  const audienceTypeParam = useMemo(() => {
    const filters = audience?.filter_rules?.filters ?? [];
    return filters.find(filterParam => filterParam.key === "role");
  }, [audience]);

  if ((isDynamic && !triggerType) || (isBulkAction && !audienceTypeParam)) {
    return;
  }

  const dynamicEntryRule = {
    iconType: getEventIcon(triggerType),
    title: t([i18nRoot, "events", triggerType, "title"]),
    description: t([i18nRoot, "events", triggerType, "description"]),
  };

  if (isDynamic) {
    return {
      ...dynamicEntryRule,
      title: `${t([i18nRoot, "event"])}: ${dynamicEntryRule.title}`,
    };
  }

  const allFilters = audience?.filter_rules?.filters || [];
  const filtersBasedLabel = allFilters
    .map(filterParam =>
      t(["settings.workflows.view", "audience_filters", filterParam.key]),
    )
    .join(", ");

  const bulkActionEntryRule = {
    iconType:
      BULK_ACTION_ENTRY_RULE_TYPE_ICON[audienceTypeParam.value] ?? "user",
    title: filtersBasedLabel,
    description: t([i18nRoot, "audience_types", "default"]),
  };

  if (isBulkAction) {
    return {
      ...bulkActionEntryRule,
      title: `${t([i18nRoot, "audience_filters"])}: ${
        bulkActionEntryRule.title
      }`,
    };
  }
};
