import PropTypes from "prop-types";
import { useFetchSpaceGroup } from "@circle-react/components/SettingsApp/Workflows/hooks/useFetchSpaceGroup";

export const SpaceGroupFromId = ({ id }) => {
  const { data: spaceGroup, isLoading } = useFetchSpaceGroup({ id });

  if (isLoading) {
    return (
      <span className="bg-tertiary block h-4 w-full animate-pulse rounded"></span>
    );
  }
  return <span>{spaceGroup.name}</span>;
};

SpaceGroupFromId.propTypes = {
  id: PropTypes.string.isRequired,
};
