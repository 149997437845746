import { useMemo } from "react";
import flatten from "lodash/flatten";
import { useInfiniteQuery } from "react-query";
import { workflowsApi } from "@circle-react/api";

export const DEFAULT_PAGE_SIZE = 20;

export const useWorkflowHistoryInfiniteQuery = (args = {}) => {
  const {
    perPage = DEFAULT_PAGE_SIZE,
    initialPage = 1,
    options = {},
    workflowType,
    ...filters
  } = args;

  const fetchPage = ({ pageParam = initialPage }) => {
    let page = 1;

    if (typeof pageParam === "number" && pageParam > 0) {
      page = pageParam;
    }

    const params = {
      ...filters,
      page,
      workflow_type: workflowType,
      per_page: perPage,
    };

    return workflowsApi.listHistory({ params });
  };

  const queryKey = ["workflows-history", workflowType, perPage, filters];

  const queryOptions = {
    getNextPageParam: lastPage =>
      lastPage.has_next_page ? lastPage.page + 1 : undefined,
    getPreviousPageParam: firstPage =>
      firstPage.page > 1 ? firstPage.page - 1 : undefined,
    keepPreviousData: true,
    ...options,
  };

  const result = useInfiniteQuery(queryKey, fetchPage, queryOptions);
  const pages = useMemo(() => result.data?.pages || [], [result.data?.pages]);
  const totalTasks = pages[0]?.total_count || 0;
  const pageSize = pages[0]?.per_page || perPage;
  const tasks = useMemo(
    () => flatten(pages.map(page => page.records)),
    [pages],
  );

  return {
    ...result,
    queryKey,
    totalTasks,
    tasks,
    pageSize,
    pages,
  };
};
