import type { ReactNode } from "react";
import classNames from "classnames";

export interface ProfileWrapperProps {
  children: ReactNode;
  className?: string;
}

export const ProfileWrapper = ({
  children,
  className,
}: ProfileWrapperProps) => (
  <div
    className={classNames(
      "fixed bottom-0 flex w-[inherit] flex-col items-center bg-transparent sm:max-w-[21.25rem] md:max-w-[18.5rem]",
      className,
    )}
  >
    {children}
  </div>
);
