import { t } from "i18n-js";
import { isFunction } from "lodash";
import { useMutation } from "react-query";
import { spaceApi } from "@circle-react/api";
import { useSpacesContext } from "@circle-react/contexts";
import { SecureConfirmationModal } from "@circle-react-shared/ModalsV2/SecureConfirmationModal";
import { useToast } from "@circle-react-uikit/ToastV2";
import { Details } from "./Details";

interface DeleteSpaceConfirmProps {
  show: boolean;
  space?: any;
  onClose: () => void;
  onSuccess?: () => void;
  onCancel?: () => void;
}

export const DeleteSpaceConfirm = ({
  show,
  space = {},
  onClose,
  onSuccess,
}: DeleteSpaceConfirmProps) => {
  const toast = useToast();
  const {
    actions: { refresh },
  } = useSpacesContext();

  const { mutate: deleteSpaceMutation, isLoading: isDeleting } = useMutation(
    () => spaceApi.destroy({ id: space.id }),
    {
      onSuccess: async () => {
        await refresh();
        isFunction(onSuccess) && onSuccess();
        onClose();
      },
      onError: () => {
        toast.error(t("delete_space_failed"));
      },
    },
  );

  return (
    <SecureConfirmationModal
      title={t("spaces.delete.title")}
      isOpen={show}
      confirmUserPrompt={t("spaces.delete.confirm_prompt")}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={deleteSpaceMutation}
      confirmVariant="danger"
      isDisabled={isDeleting}
      confirmText={isDeleting ? t("deleting") : t("confirm")}
    >
      <Details space={space} />
    </SecureConfirmationModal>
  );
};
