import { useState } from "react";
import { useFocusWithin as useFocusWithinHook } from "@react-aria/interactions";
import noop from "lodash/noop";

export const useFocusWithin = ({
  onFocusWithin = noop,
  onBlurWithin = noop,
  onFocusWithinChange = noop,
}) => {
  const [isFocusWithin, setFocusWithin] = useState(false);

  const { focusWithinProps } = useFocusWithinHook({
    onFocusWithin,
    onBlurWithin,
    onFocusWithinChange: isFocusWithin => {
      setFocusWithin(isFocusWithin);
      onFocusWithinChange(isFocusWithin);
    },
  });

  return {
    isFocusWithin,
    focusWithinProps,
  };
};
