import { t } from "i18n-js";
import { SanitizeHTMLString } from "@circle-react/components/shared/uikit/SanitizeContent";
import { Typography } from "@circle-react-uikit/Typography";

interface DetailsProps {
  space: {
    name: string;
    post_type: string;
  };
}
const i18nRoot = "spaces.delete.details";

export const Details = ({ space }: DetailsProps) => {
  const items: any = {
    default: ["space_members", "space_settings"],
    basic: ["posts", "engagement"],
    event: ["events", "engagement"],
    course: ["course_info", "course_progress", "videos_and_files", "comments"],
    image: ["posts", "engagement"],
    chat: ["messages"],
  };

  const itemsToDelete = [...(items[space.post_type] || []), ...items.default];

  return (
    <>
      <SanitizeHTMLString
        content={t("spaces.delete.description", {
          space_name: space.name,
        })}
      />
      <div className="border-primary mt-4 space-y-2 rounded-md border p-4">
        <div className="flex items-center">
          <Typography.LabelMd weight="semibold">
            {space.name}
          </Typography.LabelMd>
        </div>
        {itemsToDelete && (
          <ul className="list-inside list-none">
            {itemsToDelete.map(item => (
              <li key={item}>
                <Typography.LabelXs>{t([i18nRoot, item])}</Typography.LabelXs>
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
};
