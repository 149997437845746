import { t } from "i18n-js";
import { STATUS } from "@circle-react/helpers/paymentProcessorHelpers";
import { StatusBadge } from "@circle-react-uikit/StatusBadge";
import { Description } from "./Description";
import { PaymentProcessorStatusAlert } from "./PaymentProcessorStatusAlert";

export const PaymentProcessorWithStatus = ({ status }) => {
  const sentiment = status == STATUS.REJECTED ? "danger" : "warning";
  const badgeComponent = (
    <StatusBadge variant={sentiment}>
      {t(`settings.paywalls.processor_status_v2.${status}.status`)}
    </StatusBadge>
  );

  const descriptionComponent = (
    <Description>
      <p
        className="text-default text-lg"
        dangerouslySetInnerHTML={{
          __html: t(
            `settings.paywalls.processor_status_v2.${status}.description`,
          ),
        }}
      />
    </Description>
  );
  return (
    <PaymentProcessorStatusAlert
      sentiment={sentiment}
      badge={badgeComponent}
      title={t(`settings.paywalls.processor_status_v2.${status}.title`)}
      description={descriptionComponent}
    />
  );
};
