import { useEffect, useState } from "react";
import type { WebhookTest } from "../generated/WorkflowsApi.types";
import { useTestWebhook } from "./useTestWebhook";

interface Action {
  api_params: {
    url: string;
  };
}

interface RequestBody {
  webhook_test: {
    url: string;
    trigger_type: string;
  };
}

interface UseWebhookTestManagement {
  isTestingWebhook: boolean;
  isWebhookTestSuccessful: boolean;
  isWebhookTested: boolean;
  isWebhookUrlChanged: boolean;
  testWebhookResult: WebhookTest | null;
  handleTestWebhook: (requestBody: RequestBody) => Promise<void>;
}

export const useWebhookTestManagement = (
  action: Action | undefined,
  revalidate: () => Promise<boolean>,
): UseWebhookTestManagement => {
  const [isTestingWebhook, setIsTestingWebhook] = useState(false);
  const [isWebhookTestSuccessful, setIsWebhookTestSuccessful] = useState(false);
  const [isWebhookTested, setIsWebhookTested] = useState(false);
  const [isWebhookUrlChanged, setIsWebhookUrlChanged] = useState(false);
  const [testWebhookResult, setTestWebhookResult] =
    useState<WebhookTest | null>(null);
  const { mutate } = useTestWebhook({
    setIsTestingWebhook,
    setIsWebhookTestSuccessful,
    setIsWebhookTested,
    setTestWebhookResult,
    setIsWebhookUrlChanged,
  });

  useEffect(() => {
    setIsWebhookUrlChanged(
      isWebhookTested && action?.api_params?.url !== testWebhookResult?.url,
    );
  }, [action?.api_params?.url]);

  const handleTestWebhook = async (requestBody: RequestBody): Promise<void> => {
    const isValid = await revalidate();
    if (!isValid) return;
    setIsTestingWebhook(true);
    mutate(requestBody);
  };

  return {
    isTestingWebhook,
    isWebhookTestSuccessful,
    isWebhookTested,
    isWebhookUrlChanged,
    testWebhookResult,
    handleTestWebhook,
  };
};
