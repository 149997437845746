import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { updateOrRemoveQueryParam } from "@circle-react/helpers/urlHelpers";
import { useRouterQueryParams } from "@circle-react/hooks/useRouterQueryParams";

export const pageQueryName = "page";

export const usePageQuery = (initial = 1, useQueryParam = true) => {
  const queryParams = useRouterQueryParams();
  const initialPage = Number(queryParams[pageQueryName] || initial);
  const [page, setPage] = useState(initialPage);
  const history = useHistory();

  useEffect(() => {
    if (useQueryParam) {
      history.push({
        search: updateOrRemoveQueryParam(pageQueryName, String(page)),
      });
    }
  }, [page, history]);

  return { page, setPage, initialPage };
};
