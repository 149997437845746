import { t } from "i18n-js";
import {
  DropdownMenu_DEPRECATED,
  useDropdown,
} from "@circle-react-shared/DropdownMenu";
import { Icon } from "@circle-react-shared/Icon";

export interface NotificationActionsProps {
  notification: any;
  markAsRead: (notification: any) => void;
  unfollowNotification: (notification: any) => void;
  archiveNotification: (notification: any) => void;
  followNotification: (notification: any) => void;
}

export const NotificationActions = ({
  notification,
  markAsRead,
  unfollowNotification,
  followNotification,
  archiveNotification,
}: NotificationActionsProps) => {
  const { open: isOpen, toggleDropdown, handleClose } = useDropdown();

  const enableUnfollowPost = notification.post_follower_id;

  const canEnableFollowPost =
    ["Comment", "Post"].includes(notification.notifiable_type) &&
    !notification.post_follower_id;

  const ARCHIVED = "archived";
  return (
    <div className="notifications__actions">
      <div className="actions__menu">
        <div className="actions__icon">
          <a href="#action-menu" onClick={toggleDropdown}>
            <Icon type="menu-dots" />
          </a>
        </div>
        <DropdownMenu_DEPRECATED
          open={isOpen}
          handleClose={handleClose}
          className="dropdown-menu dropdown-menu--right dropdown-menu__actions"
        >
          <div className="dropdown-menu__inside">
            {isOpen && (
              <ul>
                {!notification.read_at && (
                  <li>
                    <button
                      type="submit"
                      onClick={() => markAsRead(notification)}
                    >
                      {t("notifications.actions.mark_as_read")}
                    </button>
                  </li>
                )}
                {enableUnfollowPost && (
                  <li>
                    <button
                      type="submit"
                      onClick={() => unfollowNotification(notification)}
                    >
                      {t("notifications.actions.unfollow_post")}
                    </button>
                  </li>
                )}
                {canEnableFollowPost && (
                  <li>
                    <button
                      type="submit"
                      onClick={() => followNotification(notification)}
                    >
                      {t("notifications.actions.follow_post")}
                    </button>
                  </li>
                )}
                {notification.notification_group != ARCHIVED && (
                  <li>
                    <button
                      type="submit"
                      onClick={() => archiveNotification(notification)}
                    >
                      {t("notifications.actions.archive")}
                    </button>
                  </li>
                )}
              </ul>
            )}
          </div>
        </DropdownMenu_DEPRECATED>
      </div>
    </div>
  );
};
