import type { IconType } from "@circle-react-shared/Icon";
import type { DropdownLink } from "./types";

interface GetPostProps {
  isVisible: boolean;
  link: string;
  icon: IconType;
  iconSize: number;
  title: string;
}
export const getPost = ({
  isVisible,
  link,
  icon,
  iconSize,
  title,
}: GetPostProps): DropdownLink[] => {
  if (!isVisible) return [];

  return [{ value: title, label: title, pathTo: link, icon, iconSize }];
};
