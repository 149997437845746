import type { UseQueryOptions } from "react-query";
import { useQuery } from "react-query";
import { workflowsApi } from "@/react/api";
import type { ApiError } from "@circle-react/config/CustomErrors";
import { minimumDelay } from "@circle-react/helpers/dateTimeHelpers";
import type { WorkflowWithDefinedId } from "../types";

export const useFetchWorkflow = ({
  workflowId,
  ...options
}: { workflowId: string } & UseQueryOptions<WorkflowWithDefinedId, ApiError>) =>
  useQuery<WorkflowWithDefinedId, ApiError>(
    ["workflows", workflowId],
    () =>
      minimumDelay(
        workflowsApi.fetch({
          id: workflowId,
          params: {
            v2_2_enabled: true,
          },
        }),
      ),
    options,
  );
