import { DataTable } from "@circle-react-uikit/DataTableV2";

export const SnippetsList = ({ columns, snippetsQuery, onChangePage }: any) => (
  <DataTable
    columns={columns}
    data={snippetsQuery?.data?.records ?? []}
    isLoading={snippetsQuery?.isLoading}
    initialPage={snippetsQuery?.data?.page}
    pageSize={snippetsQuery?.data?.per_page}
    totalRecordsCount={snippetsQuery?.data?.count}
    onChangePage={onChangePage}
  >
    <DataTable.Table>
      <DataTable.Header />
      <DataTable.Loader />
      <DataTable.Body />
    </DataTable.Table>
    <DataTable.Pagination align="left" direction="reverse" />
  </DataTable>
);
