import { Suspense } from "react";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { PageLoader } from "@circle-react/pages/PageLoader";

const MessagesComponent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "MessagesPage" */ "@circle-react/components/Messaging"
    ),
);

export const MessagesPage = () => (
  <Suspense fallback={<PageLoader />}>
    <MessagesComponent />
  </Suspense>
);
