import { useFormContext } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { workflowsApi } from "@/react/api";

const deleteAction = (workflowId, id) =>
  workflowsApi.deleteAction({
    workflowId,
    id,
  });

export const useDeleteAction = (options = {}) => {
  const { watch } = useFormContext();
  const workflowId = watch("id");
  const queryClient = useQueryClient();

  return useMutation(id => deleteAction(workflowId, id), {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["workflows", workflowId]);
      options.onSuccess?.(data, variables, context);
    },
  });
};
