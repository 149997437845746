import { useEffect, useMemo } from "react";
import { PARTICIPANT_ROLES } from "@circle-react/helpers/liveStreamRoomHelpers";
import { useLivekitContext } from "../../VideoProvider/LivekitProvider";

export const useLivekitRoom = (token, { onJoinError }, streamEnded) => {
  // TODO: We need to return this one from the backend or set as a FE env variable
  const url = "wss://live-staging.circle.so";
  const {
    roomOptions,
    connect,
    isConnected,
    isConnecting,
    isDisconnected,
    error,
    localMetadata,
    participants,
  } = useLivekitContext();

  useEffect(() => {
    (async () => {
      if (!!token && isDisconnected && !error && !streamEnded) {
        try {
          await connect(url, token, roomOptions);
        } catch (e) {
          console.error("Could not connect to room: ", e.message);
          onJoinError && onJoinError();
        }
      }
    })();
  }, [
    token,
    isDisconnected,
    streamEnded,
    connect,
    roomOptions,
    error,
    onJoinError,
  ]);

  const isHost = localMetadata?.role === PARTICIPANT_ROLES.HOST;
  const isHlsSpectator =
    localMetadata?.role === PARTICIPANT_ROLES.HLS_SPECTATOR;
  const role = localMetadata?.role;
  const isSomeoneScreenSharing = useMemo(
    () => participants?.some(participant => participant.isScreenShareEnabled),
    [participants],
  );

  return {
    isConnected,
    isJoining: isConnecting,
    isHost,
    isHlsSpectator,
    isSomeoneScreenSharing,
    role,
  };
};
