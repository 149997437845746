import { PricingTabAddPriceModalAmountField } from "../AmountField";
import { PricingTabAddPriceModalBillingFrequencyChooser } from "../BillingFrequencyChooser";
import { PricingTabAddPriceModalFieldsUpfrontPaymentFields } from "../UpfrontPaymentFields";

export const PricingTabAddPriceModalFieldsSubscription = () => (
  <>
    <div className="flex flex-row gap-4">
      <PricingTabAddPriceModalBillingFrequencyChooser />
      <PricingTabAddPriceModalAmountField
        name="amount"
        labelKey="recurring_price"
      />
    </div>
    <PricingTabAddPriceModalFieldsUpfrontPaymentFields />
  </>
);
