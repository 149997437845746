import { useMemo } from "react";
import { t } from "i18n-js";
import { useCreateWorkflowData } from "../../provider/WorkflowFormDataProvider";

const i18nRoot = "settings.workflows.templates";

export const useTemplateList = () => {
  const { templates } = useCreateWorkflowData();

  const templateGroups = useMemo(() => {
    const recommendedTemplates = templates.filter(
      template => template.recommended,
    );

    const recommendedCategory = {
      id: "category-recommended",
      title: t([i18nRoot, "categories", "recommended"]),
      templates: recommendedTemplates,
    };

    return templates.reduce(
      (acc, template) => {
        const { category } = template;
        const group = acc.find(group => group.id === `category-${category}`);

        if (group) {
          group.templates.push(template);
        } else {
          acc.push({
            id: `category-${category}`,
            title: t([i18nRoot, "categories", category], {
              defaultValue: category,
            }),
            templates: [template],
          });
        }
        return acc;
      },
      [recommendedCategory],
    );
  }, [templates]);

  return {
    templateGroups,
  };
};
