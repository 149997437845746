import type { NiceModalHandler } from "@ebay/nice-modal-react";
import classNames from "classnames";
import { Form } from "@circle-react-shared/uikit/Form/Form";
import { Loader } from "@circle-react-shared/uikit/Loader";
import { useGetMarketingEmailAddressSettings } from "../../hooks/useGetMarketingEmailAddressSettings";
import { useUpsertMarketingEmailAddressSettings } from "../../hooks/useUpsertMarketingEmailAddressSettings";
import { BroadcastFooter } from "./BroadcastFooter";
import { FromAddress } from "./FromAddress";
import { PostalAddress } from "./PostalAddress";
import { SaveChangesButton } from "./SaveChangesButton";
import { prefixFromEmail } from "./helpers";
import type { MarketingEmailAddressSettingsAttributes } from "./interfaces";
import { formValidationSchema } from "./validationSchema";

interface BroadcastSettingsFormProps {
  formId?: string;
  shouldShowSaveButton?: boolean;
  modal?: NiceModalHandler;
  onSuccess?: () => void;
  className?: string;
}

export const BroadcastSettingsForm = ({
  shouldShowSaveButton = true,
  formId,
  modal,
  onSuccess,
  className = "",
}: BroadcastSettingsFormProps) => {
  const { mutateAsync: handleSubmit } = useUpsertMarketingEmailAddressSettings({
    modal,
    onSuccess,
  });

  const { data: defaultValues, isLoading } =
    useGetMarketingEmailAddressSettings();

  if (isLoading) {
    return <Loader />;
  }
  return (
    <Form
      id={formId}
      className={classNames("flex flex-col", className)}
      defaultValues={{
        ...defaultValues,
        sender_email: prefixFromEmail(defaultValues?.sender_email),
      }}
      validationSchema={formValidationSchema}
      onSubmit={async (data: MarketingEmailAddressSettingsAttributes) => {
        await handleSubmit(data);
      }}
    >
      <FromAddress />
      <PostalAddress />
      <BroadcastFooter shouldHideBottomMargin={!shouldShowSaveButton} />
      {shouldShowSaveButton && <SaveChangesButton />}
    </Form>
  );
};
