import { useCallback, useMemo } from "react";
import { flatten, isFunction, uniqBy } from "lodash";
import { noop } from "lodash";
import { useInfiniteQuery, useQueryClient } from "react-query";
import { reactQueryGet } from "@/react/helpers/backendRequestHelpers";
import { internalApi } from "@/react/helpers/urlHelpers";
import { useInfiniteQueryHelpers } from "@/react/hooks/reactQuery/useInfiniteQueryHelpers";
import { useIsLoadingAppData } from "@/react/hooks/useIsLoadingAppData";
import { useRouterQueryParams } from "@/react/hooks/useRouterQueryParams";
import { usePunditUserContext, useSpacesContext } from "@circle-react/contexts";

export const HOME_PAGE_POST_REQUEST_KEY = "home-page-posts";

export const usePostList = (
  defaultSort?: string,
  isEnabled = true,
  onSuccess?: any,
) => {
  const spacesContext = useSpacesContext();
  const getIsDataLoading = spacesContext?.helpers?.isDataLoading || noop;

  const punditUserContext = usePunditUserContext();
  const isLoadingPundit = Boolean(punditUserContext?.isLoading);
  const { sort = defaultSort } = useRouterQueryParams();
  const { removeRecord, findAndReplaceRecord } = useInfiniteQueryHelpers();
  const queryClient = useQueryClient();

  const shouldShowLoader = useMemo(
    () => Boolean(getIsDataLoading() || isLoadingPundit),
    [getIsDataLoading, isLoadingPundit],
  );

  const fetchPage = (page: any) =>
    reactQueryGet(internalApi.homePagePosts.index({ params: { page, sort } }));

  const queryKey = useMemo(
    () => [HOME_PAGE_POST_REQUEST_KEY, { sort }],
    [sort],
  );

  const {
    fetchNextPage,
    data,
    hasNextPage,
    isLoading,
    refetch: refetchPosts,
  } = useInfiniteQuery(queryKey, ({ pageParam = 1 }) => fetchPage(pageParam), {
    keepPreviousData: true,
    enabled: isEnabled,
    getNextPageParam: lastPage =>
      lastPage.has_next_page ? lastPage.page + 1 : undefined,
    onSuccess: data => {
      if (isFunction(onSuccess)) {
        onSuccess(data);
      }
    },
  });

  const onPostChange = useCallback(
    async (post: any, { refetch = true } = {}) => {
      let fetchedPost = post;
      if (refetch) {
        fetchedPost = await queryClient.fetchQuery(
          internalApi.posts.show({
            spaceId: post.space_id,
            postSlug: post.slug,
          }),
        );
      }
      return findAndReplaceRecord(fetchedPost, queryKey);
    },
    [queryClient, findAndReplaceRecord, queryKey],
  );

  const onPostDestroy = useCallback(
    (postId: any) => {
      removeRecord(postId, queryKey);
    },
    [removeRecord, queryKey],
  );

  const isLoadingAppData = useIsLoadingAppData();

  const posts = useMemo(() => {
    if (!data) return [];
    return uniqBy(flatten(data.pages.map(page => page.records)), "id");
  }, [data]);

  const perPagePostIds = useMemo(() => {
    if (!data) return [];
    return data.pages.map(page => page.records.map((post: any) => post.id));
  }, [data]);

  return {
    fetchNextPage,
    hasNextPage: Boolean(hasNextPage),
    isLoading,
    isLoadingAppData,
    onPostChange,
    onPostDestroy,
    perPagePostIds,
    posts,
    refetchPosts,
    shouldShowLoader,
  };
};
