import { t } from "i18n-js";
import { defer } from "lodash";
import { useMutation, useQueryClient } from "react-query";
import { reactQueryPost } from "@/react/helpers/backendRequestHelpers";
import { internalApi } from "@/react/helpers/urlHelpers";
import { getCourseSectionsKey } from "@/react/hooks/courses/useCoursesApi";
import type { Space } from "@/react/types";
import type { CoursesSection } from "@/react/types/CoursesSection";
import { Button } from "@circle-react-uikit/Button";
import { DROPDOWN_SIZE, Dropdown } from "@circle-react-uikit/Dropdown";
import { Typography } from "@circle-react-uikit/Typography";

const TRANSLATION_ROOT = "courses.edit.sections";

interface AddLessonDropdownProps {
  space: Space;
  section: CoursesSection;
  setIsNameEditable: (lessonId: number) => void;
}

interface AddLessonMutationProps {
  course_lesson: {
    name: string;
    status: string;
    featured_media: null;
    rich_text_body: Record<string, never>;
    quizzes_attributes?: [Record<string, never>] | undefined;
  };
}

export const AddLessonDropdown = ({
  space,
  section,
  setIsNameEditable,
}: AddLessonDropdownProps) => {
  const queryClient = useQueryClient();
  const addLessonMutation = useMutation(
    (data: AddLessonMutationProps) =>
      reactQueryPost(
        internalApi.courses.createLesson({
          courseId: space.id,
          sectionId: section.id,
        }),
        data,
      ),
    {
      onSuccess: async data => {
        await queryClient.invalidateQueries(getCourseSectionsKey(space.id));
        defer(() => {
          const lessonElement = document.querySelector(
            `#lesson-item-${data.id}`,
          );
          if (lessonElement) {
            lessonElement.scrollIntoView({ behavior: "smooth" });
          }
          setIsNameEditable(data.id);
        });
      },
    },
  );

  const handleCreate = (lessonType: "lesson" | "quiz") => {
    const untitledLessonTranslation = t([
      TRANSLATION_ROOT,
      `untitled_${lessonType}`,
    ]);
    addLessonMutation.mutate({
      course_lesson: {
        name: `${untitledLessonTranslation} - ${section.lessons.length + 1}`,
        status: "draft",
        featured_media: null,
        rich_text_body: {},
        ...(lessonType === "quiz" && {
          quizzes_attributes: [{}], // this will create an empty quiz
        }),
      },
    });
  };

  return (
    <Dropdown
      size={DROPDOWN_SIZE.SMALL}
      button={
        <Button small type="button" variant="secondary" onClick={() => {}}>
          <Typography.LabelSm>
            {t([TRANSLATION_ROOT, "add_new"])}
          </Typography.LabelSm>
        </Button>
      }
      dataTestId="new-lesson-dropdown-menu"
    >
      <Dropdown.ItemWithLink
        onClick={() => {
          handleCreate("lesson");
        }}
      >
        {t([TRANSLATION_ROOT, "lesson"])}
      </Dropdown.ItemWithLink>

      <Dropdown.ItemWithLink
        onClick={() => {
          handleCreate("quiz");
        }}
      >
        {t([TRANSLATION_ROOT, "quiz"])}
      </Dropdown.ItemWithLink>
    </Dropdown>
  );
};
