import {
  selectConnectionQualityByPeerID,
  useHMSStore,
} from "@100mslive/react-sdk";

export const useHMSPeerSignal = peer => {
  const peerDownlinkQuality = useHMSStore(
    selectConnectionQualityByPeerID(peer?.id),
  )?.downlinkQuality;

  return {
    peerDownlinkQuality,
  };
};
