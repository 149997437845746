import { useEffect } from "react";
import { t } from "i18n-js";
import { useFormContext } from "react-hook-form";
import slugify from "slugify";
import { Form } from "@circle-react-uikit/Form";

const slugOptions = {
  replacement: "-",
  remove: /[*+~.;()'"!:@#]/g,
  lower: true,
};

const itemProps = {
  translationRoot: "space_groups.form.create",
  hideBorder: true,
  hideDescription: true,
  fullWidth: false,
};

export const BasicInfo = () => {
  const { watch, setValue, formState } = useFormContext();
  const name: string = watch("name");
  const isNameTouched = Boolean(formState.dirtyFields["name"]);
  const isSlugTouched = Boolean(formState.dirtyFields["slug"]);

  useEffect(() => {
    if (name && !isSlugTouched && isNameTouched) {
      setValue("slug", slugify(name, slugOptions));
    }
  }, [setValue, name, isSlugTouched, isNameTouched]);

  return (
    <>
      <div className="w-full">
        <Form.Item
          {...itemProps}
          isFirstChild
          name="name"
          rules={{
            required: t("space_groups.form.create.name_is_required"),
            maxLength: 255,
          }}
          variant="extra-bottom-margin"
        >
          <Form.Input />
        </Form.Item>
      </div>
      <Form.Item
        {...itemProps}
        name="slug"
        rules={{
          maxLength: 255,
        }}
        variant="extra-bottom-margin"
        transformValue={value => slugify(value, slugOptions)}
        tooltipText={t("space_groups.form.create.slug_tooltip", {
          host: window.location.origin,
        })}
      >
        <Form.Input />
      </Form.Item>
    </>
  );
};
