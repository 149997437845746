import type { JSXElementConstructor, ReactNode } from "react";
import classNames from "classnames";
import { uniqueId } from "lodash";
import { Form } from "@circle-react-uikit/Form";
import type { ChildrenWrapperProps } from "@circle-react-uikit/Form/Item";
import { ChildrenWrapper } from "./ChildrenWrapper";

export const FormItem = ({
  name,
  translationRoot,
  id,
  label,
  description,
  childrenWrapper = ChildrenWrapper,
  isHorizontal = false,
  hideLabel = false,
  placeholder,
  hidePlaceholder = false,
  hideDescription = false,
  rules,
  className,
  fullWidth,
  children,
  isDisabled = false,
  tooltipText,
  inlineReverse = false,
}: {
  name: string;
  translationRoot: string;
  id?: string;
  label?: string;
  description?: ReactNode;
  childrenWrapper?: JSXElementConstructor<ChildrenWrapperProps>;
  isHorizontal?: boolean;
  hideLabel?: boolean;
  placeholder?: string;
  hidePlaceholder?: boolean;
  hideDescription?: boolean;
  rules?: object;
  className?: string;
  fullWidth?: boolean;
  children: ReactNode;
  isDisabled?: boolean;
  tooltipText?: string;
  inlineReverse?: boolean;
}) => (
  <Form.Item
    id={id ?? uniqueId(`${name}-`)}
    name={name}
    translationRoot={translationRoot}
    label={label}
    description={description}
    className={classNames(
      "flex gap-4",
      {
        "flex-col": !isHorizontal,
      },
      className,
    )}
    labelWrapperClassName="!w-full !order-none !p-0"
    placeholder={placeholder}
    hidePlaceholder={hidePlaceholder}
    hideBorder
    hideLabel={hideLabel}
    hideDescription={hideDescription}
    childrenWrapper={childrenWrapper}
    rules={rules}
    fullWidth={fullWidth}
    isDisabled={isDisabled}
    tooltipText={tooltipText}
    inlineReverse={inlineReverse}
  >
    {children}
  </Form.Item>
);
