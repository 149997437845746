import {
  findFrequencyByIntervalAndCount,
  isSubscriptionPrice,
} from "@circle-react/helpers/paywallPriceHelpers";
import { usePricingTabContext } from "../../hooks/usePricingTabContext";
import type { PricingTabPriceListCellProps } from "../types";

export const PaymentTypeColumn = ({
  row: { original: pricePackage },
}: PricingTabPriceListCellProps) => {
  const price = pricePackage.price_properties;
  const { price_type, interval_count, interval } =
    pricePackage.price_properties;
  const { priceBuildOptions } = usePricingTabContext();

  const buildOptions = priceBuildOptions[price_type];

  let paymentType: string;
  if (isSubscriptionPrice(price)) {
    const buildOptions = priceBuildOptions[price_type];
    const frequency = findFrequencyByIntervalAndCount(
      buildOptions,
      interval,
      interval_count,
    );
    paymentType = frequency?.as_adjective || buildOptions.name;
  } else {
    paymentType = buildOptions.name;
  }

  return <div className="flex flex-row items-center gap-2">{paymentType}</div>;
};
