import { dateComponents } from "@circle-react/helpers/dateTimeHelpers/dateComponents";

interface DateAvatarProps {
  dateString: string;
  timeZone: string;
}

export const DateAvatar = ({ dateString, timeZone }: DateAvatarProps) => {
  const { dayOfMonth, shortMonthString } = dateComponents(dateString, timeZone);

  return (
    <div className="bg-tertiary flex h-[70px] w-[56px] cursor-default flex-col items-center justify-center rounded-md py-1.5">
      <div className="text-dark mb-0.5 text-center text-xl font-bold leading-6">
        {dayOfMonth}
      </div>
      <div
        className="text-dark text-center font-medium uppercase leading-4"
        style={{
          fontSize: "11px",
        }}
      >
        {shortMonthString}
      </div>
    </div>
  );
};
