import { BotProfileRailbar } from "./BotProfileRailbar";
import { CommunityMemberRailbar } from "./CommunityMemberRailbar";

export const Railbar = ({
  author = {},
  isDms,
}: {
  author: any;
  isDms: boolean;
}) => {
  if (isDms) {
    return <BotProfileRailbar botProfile={author} />;
  }
  return <CommunityMemberRailbar communityMember={author} />;
};
