import { useHMSNotificationsListener } from "../hms";
import { useLivekitNotificationsListener } from "../livekit";
import { useProviderHook } from "./useProviderHook";

export const useProviderNotificationsListener = ({
  onRoomUpdate,
  onEndStream,
}) =>
  useProviderHook({
    hms: useHMSNotificationsListener,
    livekit: useLivekitNotificationsListener,
  })({ onRoomUpdate, onEndStream });
