import PropTypes from "prop-types";

export const EventsDateDivider = ({ current, previous }) => {
  const currentDate = new Date(current);
  const previousDate = new Date(previous);
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.toLocaleString("en-US", { month: "long" });

  const previousYear = previousDate.getFullYear();
  const previousMonth = previousDate.toLocaleString("en-US", { month: "long" });

  if (currentYear !== previousYear || currentMonth !== previousMonth) {
    return (
      <div className="bg-[#F7F9FB] p-4 dark:bg-[#42464D]">
        <span className="text-base font-semibold leading-5 text-gray-900 dark:text-gray-100">
          {currentMonth} {currentYear}
        </span>
      </div>
    );
  }
  return null;
};

EventsDateDivider.propTypes = {
  current: PropTypes.string,
  previous: PropTypes.string,
};
