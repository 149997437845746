import { t } from "i18n-js";
import { useExportCsvModal } from "@circle-react-shared/Paywalls/Modals/ExportCsvModal";
import { Button } from "@circle-react-shared/uikit/Button";

export const HeaderRightContent = ({ totalChargesCount }: any) => {
  const { show } = useExportCsvModal();

  const showExportModal = () => {
    void show({ relation: "charges" });
  };

  const shouldDisableButton = totalChargesCount <= 0;

  return (
    <Button
      variant="circle"
      onClick={showExportModal}
      type="button"
      disabled={shouldDisableButton}
    >
      {t("export_paywall_csv.modal.export_cta")}
    </Button>
  );
};
