import { t } from "i18n-js";
import { localeRoot } from "@circle-react/types/Countries";
import { FIRST_COUNTRIES, REMAINING_COUNTRIES } from "./constants";

export const countryDropdownOptions = () => {
  const firstCountries = FIRST_COUNTRIES.map(countryCode => ({
    label: t([localeRoot, countryCode]),
    value: countryCode.toLowerCase(),
  }));
  const countriesSorted = REMAINING_COUNTRIES.map(countryCode => ({
    label: t([localeRoot, countryCode]),
    value: countryCode.toLowerCase(),
  })).sort((first, second) => (first.label >= second.label ? 1 : -1));

  return [...firstCountries, ...countriesSorted];
};

export const prefixFromEmail = (email?: string) =>
  email?.substring(0, email.indexOf("@") ?? email.length);
