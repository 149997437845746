import { t } from "i18n-js";
import { Form } from "@circle-react-uikit/Form";

export const RepurchaseToggle = ({ tooltipText, isToggleDisabled }: any) => (
  <Form.Item
    className="!p-0"
    labelClassName="!font-normal"
    name="can_be_repurchased"
    translationRoot="settings.paywalls.modal.tabs.pricing"
    tooltipText={t(`settings.paywalls.modal.tabs.pricing.${tooltipText}`)}
    fullWidth
    hideDescription
    hidePlaceholder
    hideBorder
    inlineReverse
    isDisabled={isToggleDisabled}
  >
    <Form.ToggleSwitch
      name="can_be_repurchased"
      variant="small"
      disabled={isToggleDisabled}
    />
  </Form.Item>
);
