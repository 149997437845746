import { useCallback } from "react";
import {
  selectHasPeerHandRaised,
  selectLocalPeerID,
  useHMSActions,
  useHMSStore,
} from "@100mslive/react-sdk";

export const useHMSRaiseHand = peerId => {
  const localPeerId = useHMSStore(selectLocalPeerID);
  const selectedPeerId = peerId || localPeerId;
  const hmsActions = useHMSActions();
  const isHandRaised = useHMSStore(selectHasPeerHandRaised(selectedPeerId));
  const raiseHand = useCallback(async () => {
    if (!peerId) {
      await hmsActions.raiseLocalPeerHand();
    } else {
      await hmsActions.raiseRemotePeerHand(peerId);
    }
  }, [hmsActions, peerId]);
  const lowerHand = useCallback(async () => {
    if (!peerId) {
      await hmsActions.lowerLocalPeerHand();
    } else {
      await hmsActions.lowerRemotePeerHand(peerId);
    }
  }, [hmsActions, peerId]);
  const toggleHand = useCallback(async () => {
    if (isHandRaised) {
      await lowerHand();
    } else {
      await raiseHand();
    }
  }, [isHandRaised, lowerHand, raiseHand]);

  return {
    isHandRaised,
    raiseHand,
    lowerHand,
    toggleHand,
  };
};
