import { omit } from "lodash";

const getNotificationTypeArray = space => [
  ...(space.default_notification_setting == "all"
    ? ["default_notification_setting"]
    : []),
  ...(space.default_in_app_notification_setting == "all"
    ? ["default_in_app_notification_setting"]
    : []),
  ...(space.default_mobile_notification_setting == "all"
    ? ["default_mobile_notification_setting"]
    : []),
];

const getChatNotificationType = space => {
  if (space.all_notifications_enabled) {
    return "all";
  }
  if (space.mention_notifications_enabled) {
    return "mentions";
  }
  if (space.notifications_off) {
    return "never";
  }
};

const getNotificationTypePayload = notification_type => ({
  default_notification_setting: notification_type.includes(
    "default_notification_setting",
  )
    ? "all"
    : "never",
  default_in_app_notification_setting: notification_type.includes(
    "default_in_app_notification_setting",
  )
    ? "all"
    : "never",
  default_mobile_notification_setting: notification_type.includes(
    "default_mobile_notification_setting",
  )
    ? "all"
    : "never",
  default_mention_mobile_notification_setting: notification_type.includes(
    "default_mention_mobile_notification_setting",
  )
    ? "all"
    : "never",
  default_mention_in_app_notification_setting: notification_type.includes(
    "default_mention_in_app_notification_setting",
  )
    ? "all"
    : "never",
});

const mapChatNotificationPreference = value => {
  if (value.includes("all")) {
    return [
      "default_mobile_notification_setting",
      "default_mention_mobile_notification_setting",
      "default_mention_in_app_notification_setting",
    ];
  }
  if (value.includes("mentions")) {
    return [
      "default_mention_mobile_notification_setting",
      "default_mention_in_app_notification_setting",
    ];
  }
  return [];
};

export const getVisibleTabsArray = ({ visible_tabs }) =>
  Object.keys(visible_tabs).filter(key => visible_tabs[key]);

export const parseSpaceResponse = space => ({
  ...omit(space, [
    "default_notification_setting",
    "default_in_app_notification_setting",
    "default_mobile_notification_setting",
    "visible_tabs",
    "hide_right_sidebar",
    "is_post_disabled",
    "prevent_members_from_adding_others",
    "hide_post_settings",
    "disable_member_post_covers",
    "is_hidden",
    "hide_sorting",
    "moderator_ids",
  ]),
  notification_type:
    space.post_type === "chat"
      ? getChatNotificationType(space)
      : getNotificationTypeArray(space),
  show_right_sidebar: !space.hide_right_sidebar,
  allow_members_to_create_posts: !space.is_post_disabled,
  allow_members_to_add_others: !space.prevent_members_from_adding_others,
  allow_post_title: !space.hide_post_settings,
  allow_cover_images: !space.disable_member_post_covers,
  show_sorting: !space.hide_sorting,
  show_to_logged_out_visitors: !space.is_hidden,
  visible_tabs: getVisibleTabsArray(space),
  locked_page_description: space.locked_page_description?.trix_html,
});

const getVisibleTabsPayload = visible_tabs => {
  const visibleTabsPayload = {};
  visible_tabs.forEach(key => (visibleTabsPayload[key] = true));
  return visibleTabsPayload;
};

export const parseSpacePayload = payload => {
  const {
    show_right_sidebar,
    allow_members_to_create_posts,
    allow_members_to_add_others,
    allow_post_title,
    allow_cover_images,
    show_to_logged_out_visitors,
    meta_tag_attributes,
    notification_type,
    visible_tabs,
    show_sorting,
  } = payload;

  return {
    ...omit(payload, [
      "default_notification_setting",
      "default_in_app_notification_setting",
      "default_mobile_notification_setting",
      "visible_tabs",
      "show_right_sidebar",
      "allow_members_to_create_posts",
      "allow_members_to_add_others",
      "allow_post_title",
      "allow_cover_images",
      "show_to_logged_out_visitors",
      "customize_lock_screen",
      "policies",
      "is_space_owner",
      "is_space_member",
      "meta_tag_attributes",
      "show_sorting",
      "moderator_ids",
    ]),
    hide_right_sidebar: !show_right_sidebar,
    is_post_disabled: !allow_members_to_create_posts,
    prevent_members_from_adding_others: !allow_members_to_add_others,
    hide_post_settings: !allow_post_title,
    disable_member_post_covers: !allow_cover_images,
    is_hidden: !show_to_logged_out_visitors,
    hide_sorting: !show_sorting,
    visible_tabs: getVisibleTabsPayload(visible_tabs),
    ...getNotificationTypePayload(
      payload.post_type === "chat"
        ? mapChatNotificationPreference(notification_type)
        : notification_type,
    ),
    meta_tag_attributes: {
      ...omit(meta_tag_attributes, "opengraph_image_id"),
    },
  };
};
