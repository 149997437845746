import { useFormContext } from "react-hook-form";
import { useMutation } from "react-query";
import { workflowsApi } from "@/react/api";

const upsertActions = (workflowId, actions = []) => {
  const mappedActions = actions.map(action => {
    const newAction = {
      action_type: action.action_type,
      api_params: action.api_params,
    };

    if (action.id && !action.id.startsWith("action")) {
      newAction.id = action.id;
    }

    return newAction;
  });

  return workflowsApi.upsertActions({
    workflowId,
    body: { actions: mappedActions },
  });
};

export const useUpsertActionsArray = (options = {}) => {
  const { watch } = useFormContext();
  const workflowId = watch("id");

  return useMutation(actions => upsertActions(workflowId, actions), options);
};
