import { Elements } from "@stripe/react-stripe-js";
import classnames from "classnames";
import { Modal } from "@/react/components/shared/uikit/ModalV2";
import { ModalManager } from "@circle-react/components/Layout/ModalManagerProvider";
import { useWithBillingInfoModal } from "@circle-react/components/Paywalls/CommunityMembers/BillingInfoModal";
import { isTrial } from "@circle-react/helpers/communityMemberSubscriptionHelpers";
import { useStripeElement } from "@circle-react/hooks/paywalls/useStripeElement";
import { ChangePlanContextProvider } from "./ChangePlanContext";
import { ChangePlanForm } from "./ChangePlanForm";
import { SuccessPage } from "./SuccessPage";
import { useChangePlan } from "./hooks/useChangePlan";

export interface ChangePlanModalProps {
  subscription: any;
  changePlan?: boolean;
  refetchSubscriptions: () => void;
  isManagingMember?: boolean;
}

const ChangePlanModalComponent = ({
  subscription,
  refetchSubscriptions,
  changePlan = false,
  isManagingMember = false,
}: ChangePlanModalProps) => {
  const { onClose, updatedSubscription, handleSuccess, modal } = useChangePlan({
    refetchSubscriptions,
  });

  return (
    <Modal isOpen={modal.visible} onClose={onClose}>
      <Modal.Overlay />
      <Modal.Content
        size="md"
        className={classnames({
          "sm:max-h-[90vh]": isTrial(subscription),
        })}
      >
        <ChangePlanContextProvider
          subscription={subscription}
          isManagingMember={isManagingMember}
        >
          {!updatedSubscription && (
            <ChangePlanForm
              changePlan={changePlan}
              onDismiss={onClose}
              onSuccess={handleSuccess}
              subscription={subscription}
            />
          )}
          {updatedSubscription && (
            <SuccessPage
              onDone={onClose}
              updatedSubscription={updatedSubscription}
            />
          )}
        </ChangePlanContextProvider>
      </Modal.Content>
    </Modal>
  );
};

const ChangePlanModal = ModalManager.create((props: ChangePlanModalProps) => {
  const { stripePromise } = useStripeElement();

  return (
    <Elements stripe={stripePromise}>
      <ChangePlanModalComponent {...props} />
    </Elements>
  );
});

export const useChangePlanModal = () =>
  useWithBillingInfoModal(
    ChangePlanModal,
    /**
     * `useChangePlanModal` is used on both member and admin side, so we need to resolve the
     * community member ID from the subscription that comes in as a prop.
     */
    ({ subscription }) => ({
      id: subscription.community_member_id,
      policies: {
        requires_billing_info: subscription?.policies?.requires_billing_info,
      },
    }),
  );
