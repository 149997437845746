import { t } from "i18n-js";
import { useFormContext } from "react-hook-form";
import { useGamificationSettings } from "@circle-react/components/SettingsApp/Gamification/LevelsForm/gamificationApi";
import { FormTextSelect } from "@circle-react-uikit/Form/FormTextSelect";
import { Loader } from "@circle-react-uikit/Loader";
import { Typography } from "@circle-react-uikit/Typography";

const i18nRoot = "settings.workflows.form";

export const LevelSelector = () => {
  const { register } = useFormContext();
  const { data: gamificationSettings, isLoading } = useGamificationSettings();

  if (isLoading || !gamificationSettings) {
    return <Loader center />;
  }

  let options: {
    value: number;
    label: string;
  }[] = [];

  if (gamificationSettings.levels) {
    options = gamificationSettings.levels.map(({ id, name }) => ({
      value: id,
      label: name ?? "",
    }));
  }

  return (
    <>
      <input
        type="hidden"
        className="hidden"
        {...register("trigger.resource_type")}
      />
      <FormTextSelect
        name="trigger.resource_id"
        label={
          <Typography.LabelSm weight="semibold">
            {t([i18nRoot, "choose_level"])}
          </Typography.LabelSm>
        }
        options={options}
        rules={{
          required: t("settings.workflows.form.errors.required"),
        }}
        emptyMessage={t(
          "settings.workflows.form.events.gamification_member_leveled_up.error",
        )}
      />
    </>
  );
};
