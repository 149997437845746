import { useState } from "react";
import I18n from "i18n-js";
import { Edit } from "@circle-react/components/Spaces/Edit";
import { Drawer } from "@circle-react-shared/uikit/Drawer";
import "./styles.scss";

export const EditSpaceButton = props => {
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleClose = () => {
    setIsDrawerVisible(false);
    if (submitted) {
      window.location.reload();
    }
  };
  const openDrawer = () => setIsDrawerVisible(true);

  return (
    <>
      <button type="submit" onClick={openDrawer} className="edit-space-button">
        {I18n.t("spaces.form.edit.edit_button_label")}
      </button>
      <Drawer
        noWrapperPadding
        fixedHeader
        maskClosable={false}
        title={I18n.t("spaces.form.edit.edit_drawer_header")}
        open={isDrawerVisible}
        onClose={handleClose}
      >
        <Edit {...props} open={isDrawerVisible} setSubmitted={setSubmitted} />
      </Drawer>
    </>
  );
};
