import { forwardRef, useEffect } from "react";
import PropTypes from "prop-types";
import { t } from "i18n-js";
import { useFormContext } from "react-hook-form";
import { CourseAdminNotifications } from "@/react/components/Spaces/CustomizeSpaceFullPage/OptionsForm/CourseAdminNotifications";
import { CourseOptions } from "@/react/components/Spaces/CustomizeSpaceFullPage/OptionsForm/CourseOptions";
import Permissions from "@/react/components/Spaces/EditV2/EditForm/Tabs/Options/Permissions";
import { Sort } from "@/react/components/Spaces/EditV2/EditForm/Tabs/Options/Sort";
import { NotificationTypePicker } from "@/react/components/Spaces/NewV2/NewForm/NotificationTypePicker";
import {
  isBasicPostsSpace,
  isChatSpace,
  isCourseSpace,
} from "@/react/helpers/spaceHelpers";
import { Form } from "@circle-react-uikit/Form";
import { Typography } from "@circle-react-uikit/Typography";
import { NotificationsConfirmModal } from "../OptionsForm/NotificationsConfirmModal";

const fields = [
  "allow_members_to_add_others",
  "allow_members_to_create_posts",
  "allow_post_title",
  "allow_cover_images",
  "hide_from_featured_areas",
  "pinned_posts_label",
  "default_comment_sort",
  "notification_type",
  "chat_room_show_history",
];

export const SpaceOptionsForm = forwardRef(
  (
    {
      space,
      onChange,
      isOpenNotificationsConfirmModal,
      toggleNotificationsConfirmModal,
      setNotificationsChanged,
    },
    triggerFormSubmitRef,
  ) => {
    const {
      formState: { dirtyFields },
    } = useFormContext();

    const onNotificationsChange = () => setNotificationsChanged(true);

    useEffect(() => {
      const dirtyFieldsArray = Object.keys(dirtyFields);
      const hasDirtyFields = fields.filter(value =>
        dirtyFieldsArray.includes(value),
      );

      if (hasDirtyFields.length > 0) {
        onChange(true);
      }

      if (dirtyFieldsArray.includes("notification_type")) {
        onNotificationsChange();
      }
    }, [dirtyFields]);

    return (
      <div>
        <NotificationTypePicker />
        <NotificationsConfirmModal
          isOpen={isOpenNotificationsConfirmModal}
          onClose={toggleNotificationsConfirmModal}
          setNotificationsChanged={setNotificationsChanged}
          ref={triggerFormSubmitRef}
        />
        <CourseOptions space={space} />
        <CourseAdminNotifications space={space} />
        <Permissions space={space} />
        {isBasicPostsSpace(space) && (
          <div className="mt-10 flex-1">
            <Typography.TitleSm weight="semibold">
              {t("spaces.form.edit.options.labels.label")}
            </Typography.TitleSm>
            <div className="grid grid-cols-1">
              <Form.Item
                hideBorder
                label={t("spaces.form.edit.options.labels.pinned_posts.label")}
                tooltipText={t(
                  "spaces.form.edit.options.labels.pinned_posts.description",
                )}
                hideDescription
                name="pinned_posts_label"
                variant="extra-bottom-margin"
              >
                <Form.Input placeholder={t("spaces.form.edit.pinned_posts")} />
              </Form.Item>
            </div>
          </div>
        )}
        {!isChatSpace(space) && !isCourseSpace(space) && <Sort space={space} />}
      </div>
    );
  },
);
SpaceOptionsForm.displayName = "SpaceOptionsForm";

SpaceOptionsForm.propTypes = {
  space: PropTypes.object,
  onChange: PropTypes.func,
  isOpenNotificationsConfirmModal: PropTypes.bool,
  toggleNotificationsConfirmModal: PropTypes.func,
  setNotificationsChanged: PropTypes.func,
};
