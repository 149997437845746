import type { CommunitySwitcher } from "@circle-react/types/CommunitySwitcher";
import { SortableList } from "@circle-react-shared/uikit/SortableList";
import { CommunitySection } from "./CommunitySection";

export interface SortableCommunityItemProps {
  community: CommunitySwitcher;
  sortedCommunities: CommunitySwitcher[];
  isReorderingCommunities: boolean;
  token: string;
  index: number;
}

export const SortableCommunityItem = ({
  community,
  sortedCommunities = [],
  isReorderingCommunities,
  token,
  index,
}: SortableCommunityItemProps) => (
  <SortableList.Item
    className="min-w-min"
    key={community.id}
    item={community}
    index={index}
    tabIndex={-1}
    disabled={isReorderingCommunities || sortedCommunities.length === 1}
  >
    <CommunitySection community={community} token={token} index={index} />
  </SortableList.Item>
);
