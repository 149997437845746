import { useQuery } from "react-query";
import { workflows } from "@circle-react/helpers/urlHelpers/internalApi/workflows";

export const useFetchPaywallPrice = ({ paywallId, priceId }) => {
  const {
    data: paywallPrice = {},
    isLoading,
    isSuccess,
  } = useQuery(
    workflows.paywallPrices.show({
      paywallId: paywallId,
      id: priceId,
    }),
  );

  return {
    paywallPrice,
    isLoading,
    isSuccess,
  };
};
