import { t } from "i18n-js";
import { useDeleteSpaceModal } from "@/react/components/Modals/DeleteSpaceModal";
import { useActiveSpace } from "@/react/hooks/useActiveSpace";
import { Typography } from "@circle-react/components/shared/uikit/Typography";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { Icon } from "@circle-react-shared/Icon";
import { Dropdown } from "@circle-react-uikit/Dropdown";
import { useSpaceOptionsIcons } from "./useSpaceOptionsIcons";

export const DeleteSpaceOption = () => {
  const deleteSpaceModal = useDeleteSpaceModal();
  const { space } = useActiveSpace();
  const redirectToRoot = () => (window.location.href = "/");
  const { isV3Enabled } = useIsV3();
  const { deleteSpaceIcon } = useSpaceOptionsIcons();

  return (
    <Dropdown.ItemWithLink
      key={t("space_header.delete_space")}
      onClick={() =>
        deleteSpaceModal.show({ space, onSuccess: () => void redirectToRoot() })
      }
    >
      <Dropdown.IconContainer>
        {isV3Enabled ? (
          <>
            <span className="h-5 w-5">
              <Icon
                type={deleteSpaceIcon}
                className="text-v2-danger"
                size={20}
              />
            </span>
            <Typography.LabelXsPlus weight="medium" color="text-v2-danger">
              {t("space_header.delete_space")}
            </Typography.LabelXsPlus>
          </>
        ) : (
          <>
            <Icon type={deleteSpaceIcon} className="text-v2-danger" size={16} />
            <Typography.LabelSm color="text-v2-danger">
              {t("space_header.delete_space")}
            </Typography.LabelSm>
          </>
        )}
      </Dropdown.IconContainer>
    </Dropdown.ItemWithLink>
  );
};
