import { createContext, useContext, useState } from "react";
import { noop } from "lodash";
import { useIsInAppBannerEnabled } from "@circle-react/hooks/useIsInAppBannerEnabled";
import { useIsInAppEnabled } from "@circle-react/hooks/useIsInAppEnabled";
import { usePlanStatus } from "@circle-react/hooks/usePlanStatus";

type ViewModeType = "web" | "mobile";

interface PaywallBlockContextProp {
  viewPriceMode: ViewModeType;
  setViewPriceMode: (param: ViewModeType) => void;
  isWebPriceMode: boolean;
  isMobilePriceMode: boolean;
  isInAppEnabled: boolean;
  isCommunityOnPlus: boolean;
  shouldShowBrandedAppBanner: boolean;
}

const PaywallBlockContext = createContext<PaywallBlockContextProp>({
  viewPriceMode: "web",
  setViewPriceMode: noop,
  isWebPriceMode: true,
  isMobilePriceMode: false,
  isInAppEnabled: false,
  isCommunityOnPlus: false,
  shouldShowBrandedAppBanner: true,
});

PaywallBlockContext.displayName = "PaywallBlockContext";

export const usePaywallBlockContext = () => useContext(PaywallBlockContext);

export const PaywallBlockProvider = ({ children }: any) => {
  const { isCommunityOnPlus } = usePlanStatus();
  const isInAppBannerEnabled = useIsInAppBannerEnabled();
  const [viewPriceMode, setViewPriceMode] = useState<ViewModeType>("web");

  const isWebPriceMode = viewPriceMode === "web";
  const isMobilePriceMode = viewPriceMode === "mobile";
  const isInAppEnabled = useIsInAppEnabled();

  const shouldShowBrandedAppBanner =
    (isInAppBannerEnabled &&
      isMobilePriceMode &&
      isCommunityOnPlus &&
      !isInAppEnabled) ||
    (isInAppBannerEnabled && isMobilePriceMode && !isCommunityOnPlus);

  return (
    <PaywallBlockContext.Provider
      value={{
        viewPriceMode,
        setViewPriceMode,
        isWebPriceMode,
        isMobilePriceMode,

        isInAppEnabled,
        isCommunityOnPlus,
        shouldShowBrandedAppBanner,
      }}
    >
      {children}
    </PaywallBlockContext.Provider>
  );
};
