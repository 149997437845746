import { t } from "i18n-js";
import { StandardLayoutHeader } from "@/react/components/Layout/StandardLayoutV2/StandardLayoutHeader";
import { Typography } from "@circle-react-uikit/Typography";

export const AnalyticsHeader = () => (
  <StandardLayoutHeader
    // workaround to allow this to appear correctly inside analytics are withing Rails
    className="lg:-mt-16"
    leftContent={
      <Typography.LabelMd weight="semibold">
        <a className="text-dark" href="/">
          {t("settings.analytics.title")}
        </a>
      </Typography.LabelMd>
    }
  />
);
