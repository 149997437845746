import I18n from "i18n-js";
import { Icon } from "@circle-react-shared/Icon";
import "./styles.scss";

export const Info = () => {
  const SUBSCRIPTION_KNOWLEDGE_BASE_URL =
    "https://community.circle.so/c/paywalls/";
  return (
    <div className="paywall-groups-list__info table-wrapper">
      <div>
        <Icon
          type="question-mark"
          className="icon--sm paywall-groups-list__info__icon"
        />
      </div>
      <div>
        <h4>{I18n.t("settings.paywall_groups.headings.what_is")}</h4>
        <p className="paywall-groups-list__info__explanation text-dark">
          {I18n.t("settings.paywall_groups.alerts.info.what_is")}
          &nbsp;
          {/* TODO: redirect to knowledge base article once Peter creates it  */}
          <a
            href={SUBSCRIPTION_KNOWLEDGE_BASE_URL}
            target="_blank"
            rel="noreferrer"
          >
            {I18n.t("settings.paywall_groups.actions.learn_more")}
          </a>
        </p>
      </div>
    </div>
  );
};

Info.propTypes = {};
