import { BlockWithConnector as Block } from "@circle-react-shared/BlockWithConnector";
import type { ItemProps } from "@circle-react-shared/BlockWithConnector/Item";
import type { BlockContentProps } from "./BlockContent";
import { BlockContent } from "./BlockContent";

type WorkflowBlockProps = ItemProps & BlockContentProps;

export const WorkflowBlock = ({
  title,
  description,
  actions,
  isApplicableToMembersOnly,
  hasSuccess,
  hasError,
  statusMessage,
  children,
  actionComponents,
  isCompactView,
  compactViewTitle,
  collapsedViewMetaComponent,
  ...rest
}: WorkflowBlockProps) => (
  <Block.Item {...rest}>
    <BlockContent
      title={title}
      isApplicableToMembersOnly={isApplicableToMembersOnly}
      actions={actions}
      hasSuccess={hasSuccess}
      hasError={hasError}
      statusMessage={statusMessage}
      actionComponents={actionComponents}
      description={description}
      isCompactView={isCompactView}
      compactViewTitle={compactViewTitle}
      collapsedViewMetaComponent={collapsedViewMetaComponent}
    >
      {children}
    </BlockContent>

    <Block.Connector />
  </Block.Item>
);
