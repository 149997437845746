import { useCallback, useState } from "react";
import { useModal } from "@circle-react/components/Layout/ModalManagerProvider";

export interface UseChangePlanProps {
  refetchSubscriptions: () => void;
}

export const useChangePlan = ({ refetchSubscriptions }: UseChangePlanProps) => {
  const modal = useModal();
  const [updatedSubscription, setUpdatedSubscription] = useState(null);

  const handleSuccess = useCallback(
    subscription => {
      setUpdatedSubscription(subscription);
      refetchSubscriptions();
    },
    [setUpdatedSubscription, refetchSubscriptions],
  );

  const onClose = () => {
    void modal.hide();

    // Unmount modal and resets state for new modals
    modal.remove();
  };

  return {
    onClose,
    updatedSubscription,
    handleSuccess,
    modal,
  };
};
