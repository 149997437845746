import { useMemo } from "react";
import flatten from "lodash/flatten";
import { useInfiniteQuery } from "react-query";
import { reactQueryGet } from "@/react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import type { AudienceListResponse } from "@circle-react/types/AudienceList";
import type { BaseFilter } from "@circle-react-shared/MemberFilters/types";

export const DEFAULT_PAGE_SIZE = 30;

interface UseAudienceInfiniteQueryArgs {
  query?: string;
  order?: Record<string, "asc" | "desc">;
  filters?: BaseFilter[];
  perPage?: number;
  initialPage?: number;
  options?: Record<string, any>;
  scope?: {
    status: string;
    visible_in_member_directory?: boolean;
    exclude_empty_name?: boolean;
    exclude_empty_profiles?: boolean;
  };
}

export const useAudienceInfiniteQuery = (
  args: UseAudienceInfiniteQueryArgs = {},
) => {
  const {
    query = "",
    order,
    filters = {},
    perPage = DEFAULT_PAGE_SIZE,
    initialPage = 1,
    options = {},
    scope = {},
  } = args;

  const fetchPage = ({ pageParam = initialPage }) => {
    let page = 1;

    if (typeof pageParam === "number" && pageParam > 0) {
      page = pageParam;
    }

    const params: Record<string, unknown> = {
      page,
      filters,
      per_page: perPage,
      ...scope,
    };

    if (query) {
      params.query = query;
    }

    if (order) {
      params.order = order;
    }

    return reactQueryGet(internalApi.admin.search.contacts.index({ params }));
  };

  const queryKey = ["audience", query, order, filters, perPage, scope] as const;

  const result = useInfiniteQuery<AudienceListResponse>(queryKey, fetchPage, {
    getNextPageParam: lastPage =>
      lastPage.has_next_page ? lastPage.page + 1 : undefined,
    getPreviousPageParam: firstPage =>
      firstPage.page > 1 ? firstPage.page - 1 : undefined,
    keepPreviousData: true,
    ...options,
  });
  const pages = useMemo(() => result.data?.pages || [], [result.data?.pages]);
  const totalAudience: number = pages[0]?.meta?.total_count || 0;
  const totalQueryAudience: number = pages[0]?.count || 0;
  const pageSize: number = pages[0]?.per_page || perPage;
  const audience = useMemo(
    () => flatten(pages.map(page => page.records)),
    [pages],
  );

  return {
    ...result,
    queryKey,
    totalAudience,
    totalQueryAudience,
    audience,
    pageSize,
    pages,
  };
};
