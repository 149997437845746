import PropTypes from "prop-types";
import { t } from "i18n-js";
import { useSpaceGroupResource } from "@circle-react/contexts";
import { Typography } from "@circle-react-uikit/Typography";
import { Badge } from "../../../Badge";
import { BaseView } from "../../BaseView";

const i18nRoot = "settings.workflows.view";
export const ViewSpaceGroup = ({ action }) => {
  const { api_params: apiParams } = action || {};
  const { space_group_id: spaceGroupId } = apiParams || {};
  const { data: spaceGroup = {}, isLoading } =
    useSpaceGroupResource(spaceGroupId);

  if (!spaceGroup && !isLoading) {
    return null;
  }

  return (
    <BaseView>
      <BaseView.Section>
        <BaseView.Title>{t([i18nRoot, "space_group"])}</BaseView.Title>
        <div>
          {isLoading ? (
            <Badge isLoading />
          ) : (
            <Typography.LabelSm>{spaceGroup.name}</Typography.LabelSm>
          )}
        </div>
      </BaseView.Section>
    </BaseView>
  );
};

ViewSpaceGroup.propTypes = {
  action: PropTypes.object,
};
