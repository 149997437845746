import { useEffect } from "react";
import { t } from "i18n-js";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { Drawer } from "@circle-react/components/shared/uikit/Drawer";
import { notificationsPath } from "@circle-react/helpers/urlHelpers";
import { useDocumentTitle } from "@circle-react/hooks/notifications/useDocumentTitle";
import { useNotificationWebSocket } from "@circle-react/hooks/notifications/useNotificationWebSocket";
import { useNotificationsApi } from "@circle-react/hooks/notifications/useNotificationsApi";
import { useWindowResize } from "@circle-react/hooks/utils/useWindowResize";
import { NUMBER_OF_NOTIFICATION_IN_MENU } from "../../../config/Settings";
import { NotificationsList } from "../NotificationsList";
import "./styles.scss";

export interface NotificationsDrawerProps {
  containerElement?: string;
  communityMemberId: number;
  newNotificationCount: number;
}

const NotificationsDrawerComponent = ({
  containerElement,
  communityMemberId,
  newNotificationCount: newNotificationCountFromProps,
}: NotificationsDrawerProps) => {
  const drawer = useModal();
  const {
    newNotificationCount,
    setNewNotificationCount,
    onNotificationEventReceive,
    resizeNotificationWindow,
  } = useNotificationsApi(NUMBER_OF_NOTIFICATION_IN_MENU);

  const { prependTitle, resetTitleToOriginal } = useDocumentTitle();

  useEffect(() => {
    setNewNotificationCount(newNotificationCountFromProps);
  }, [newNotificationCountFromProps]);

  useNotificationWebSocket(communityMemberId, onNotificationEventReceive);

  useWindowResize(resizeNotificationWindow);

  useEffect(() => {
    if (newNotificationCount) {
      document.title = prependTitle(`(${newNotificationCount}) `);
    } else {
      document.title = resetTitleToOriginal();
    }
  }, [newNotificationCount]);

  return (
    <Drawer
      maskClosable
      hideHeader
      noWrapperPadding
      open={drawer.visible}
      onClose={drawer.hide}
    >
      <div
        className="notifications__wrapper--drawer"
        id={containerElement + "NotificationWrapperOuter"}
        data-testid="notifications-drawer"
      >
        <NotificationsList
          handleClose={drawer.hide}
          key={`notification-${drawer.visible}`}
          containerElement="drawer"
        />
        <div className="notifications__footer">
          <span>
            <a href={notificationsPath()}>
              <div>{t("view_all_notifications")}</div>
            </a>
          </span>
        </div>
      </div>
    </Drawer>
  );
};

const NotificationsDrawer = ModalManager.create(
  ({
    containerElement,
    communityMemberId,
    newNotificationCount,
  }: NotificationsDrawerProps) => (
    <NotificationsDrawerComponent
      containerElement={containerElement}
      communityMemberId={communityMemberId}
      newNotificationCount={newNotificationCount}
    />
  ),
);

export const useNotificationsDrawer = () => useModal(NotificationsDrawer);
