import { useMemo } from "react";
import { t } from "i18n-js";
import { capitalize } from "lodash";
import { useFormContext } from "react-hook-form";
import { formattedDateTime } from "@circle-react/helpers/dateTimeHelpers/formattedDateTime";
import { Typography } from "@circle-react-uikit/Typography";
import { DayCheckboxList } from "./DayCheckboxList";
import { FirstLastRunPicker } from "./FirstLastRunPicker";
import { useMessageParts } from "./useMessageParts";

const i18nRoot = "settings.workflows.recurring_schedule_modal";

export const days = [
  { value: "monday", label: t([i18nRoot, "mon"]) },
  { value: "tuesday", label: t([i18nRoot, "tue"]) },
  { value: "wednesday", label: t([i18nRoot, "wed"]) },
  { value: "thursday", label: t([i18nRoot, "thu"]) },
  { value: "friday", label: t([i18nRoot, "fri"]) },
  { value: "saturday", label: t([i18nRoot, "sat"]) },
  { value: "sunday", label: t([i18nRoot, "sun"]) },
];

export function EveryWeekOptions() {
  const { watch } = useFormContext();

  const firstRun = watch("first_run_at");
  const lastRun = watch("last_run_at");
  const nextRun = watch("next_run_at");
  const daysOfWeek = watch("days_of_week");
  const endsOn = watch("ends_on");
  const hasScheduleRunBefore = watch("has_schedule_run_before");

  const recurringHourMinutes = hasScheduleRunBefore
    ? new Date(nextRun)
    : new Date(firstRun);

  const message = endsOn === "on" ? "every_week_on_until" : "every_week_on";

  const { prefix, midSection } = useMessageParts(hasScheduleRunBefore);

  const getIsMessageVisible = () => {
    if (!firstRun) {
      return false;
    }

    if (!daysOfWeek.length) {
      return false;
    }

    return !(endsOn === "on" && !lastRun);
  };

  const daysOfWeekLabel = useMemo(() => {
    if (!daysOfWeek?.length) {
      return "";
    }

    const listFormatter = new Intl.ListFormat(undefined, {
      style: "long",
      type: "conjunction",
    });

    const labelDaysOfWeek = daysOfWeek.map((day: any) => {
      const dayLabel = days.find(d => d.value === day)?.label;

      return capitalize(dayLabel);
    });
    return listFormatter.format(labelDaysOfWeek);
  }, [daysOfWeek]);

  return (
    <div className="flex flex-col gap-y-4">
      <div className="flex flex-col">
        <DayCheckboxList days={days} />
        <FirstLastRunPicker />
      </div>
      {getIsMessageVisible() && (
        <Typography.LabelSm weight="medium">
          {t([i18nRoot, message], {
            start_time_at: formattedDateTime({
              dateTime: new Date(firstRun),
              format: "date_time_with_short_timezone",
            }),
            end_time_at: formattedDateTime({
              dateTime: new Date(lastRun),
              format: "long_date",
            }),
            repeat_days: daysOfWeekLabel,
            prefix,
            mid_section: midSection,
            recurring_hour_minutes: formattedDateTime({
              dateTime: recurringHourMinutes,
              format: "short_time",
            }),
          })}
        </Typography.LabelSm>
      )}
    </div>
  );
}
