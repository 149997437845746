import { useQuery } from "react-query";
import { workflowsApi } from "@circle-react/api";
import { useToast } from "@circle-react-uikit/ToastV2";

export const COMMUNITY_MEMBERS_LIST_REQUEST_KEY = "COMMUNITY_MEMBERS_LIST";

/**
 * Get list of community members when given an array of IDs
 * @param {Object} param0
 * @param {string[]} ids Array of CommunityMember IDs
 * @param {boolean} withDeleted Whether to include soft deleted members
 * @param {boolean} enabled provided to useQuery
 */
export const useCommunityMembersList = ({ ids, withDeleted, enabled }) => {
  const toastr = useToast();

  const fetchCommunityMembersList = () =>
    workflowsApi.fetchContacts({
      params: { ids, with_deleted: withDeleted },
    });

  return useQuery(
    [COMMUNITY_MEMBERS_LIST_REQUEST_KEY, ids, withDeleted],
    fetchCommunityMembersList,
    {
      enabled,
      onError: err => {
        toastr.error(err.message);
      },
    },
  );
};
