import type { ComponentPropsWithoutRef, ReactNode } from "react";
import classnames from "classnames";
import { useIsV3 } from "@circle-react/hooks/useIsV3";

export interface CommunityWrapperProps extends ComponentPropsWithoutRef<"div"> {
  children: ReactNode;
  className?: string;
}

export const CommunityWrapper = ({
  children,
  className = "",
  ...rest
}: CommunityWrapperProps) => {
  const { isV3Enabled } = useIsV3();

  return (
    <div
      className={classnames("bg-secondary", className, {
        community__wrapper: !isV3Enabled,
      })}
      data-testid="community-wrapper"
      {...rest}
    >
      {children}
    </div>
  );
};
