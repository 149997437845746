import { useFormContext } from "react-hook-form";
import { useMutation } from "react-query";
import type { UseMutationOptions } from "react-query";
import { workflowsApi } from "@/react/api";
import { minimumDelay } from "@circle-react/helpers/dateTimeHelpers";
import { useToast } from "@circle-react-uikit/ToastV2";

export type DoNotSkipTasks = "true" | "false";

export interface RerunFormData {
  do_not_skip_tasks: DoNotSkipTasks;
}

export const useRerunWorkflow = (
  options: UseMutationOptions = {},
  /* Original type -- RerunFormData | null */
  params: any,
) => {
  const { watch } = useFormContext();
  const id = watch("id");

  const toast = useToast();

  return useMutation<RerunFormData, { message: string }>(
    () =>
      minimumDelay(
        workflowsApi.createRun({ workflowId: id, params: params }),
        500,
      ),
    {
      onError: error => {
        toast.error(error.message);
      },
      ...options,
    },
  );
};
