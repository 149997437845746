import { t } from "i18n-js";
import { TRIGGER_RESOURCE_TYPE } from "../../../constants";
import { CourseSelector } from "../Space/shared/CourseSelector";
import { SpaceResource } from "../Space/shared/SpaceResource";
import type { EventOption } from "../types";

export const completedCourse: Readonly<EventOption> = {
  label: t(
    "settings.workflows.form.events.community_member_completed_course.title",
  ),
  value: "community_member_completed_course",
  group: t("settings.workflows.form.event_groups.courses"),
  description: t(
    "settings.workflows.form.events.community_member_completed_course.description",
  ),
  resource: TRIGGER_RESOURCE_TYPE.COURSE,
  formComponent: CourseSelector,
  viewComponent: SpaceResource,
  icon: "course",
};
