import { SpaceGroupChevron } from "./SpaceGroupChevron";
import { SpaceGroupNameWrapper } from "./SpaceGroupNameWrapper";

interface SpaceGroup {
  id: number;
  name: string;
}

interface SpaceGroupNameProps {
  isDropdownOpen: boolean;
  spaceGroup: SpaceGroup;
  isCollapsed: boolean;
  canManage: boolean;
}

export const SpaceGroupName = ({
  isDropdownOpen,
  spaceGroup,
  isCollapsed,
  canManage,
}: SpaceGroupNameProps) => (
  <SpaceGroupNameWrapper isDropdownOpen={isDropdownOpen} canManage={canManage}>
    <h3
      className="truncate text-sm font-semibold leading-5 text-current"
      data-id={spaceGroup.id}
      data-testid="spaces-left-sidebar"
      title={spaceGroup.name}
    >
      {spaceGroup.name}
    </h3>
    <SpaceGroupChevron isCollapsed={isCollapsed} />
  </SpaceGroupNameWrapper>
);
