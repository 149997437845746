import { t } from "i18n-js";
import { usePaywallCheckoutContext } from "@circle-react/contexts/Paywalls/paywallCheckoutContext";
import { Typography } from "@circle-react-uikit/Typography";

export const CheckoutFormTitle = () => {
  const { getSelectedPrice } = usePaywallCheckoutContext();

  const selectedPrice = getSelectedPrice();

  const title = selectedPrice?.trial_enabled
    ? t([
        "paywall_checkout.get_started.trial_title",
        selectedPrice.trial_requires_upfront_payment ? "paid" : "free",
      ])
    : t("paywall_checkout.get_started.no_trial_title");

  const description = selectedPrice?.trial_enabled
    ? t("paywall_checkout.get_started.trial_description")
    : t("paywall_checkout.get_started.no_trial_description");

  return (
    <div className="flex flex-col gap-y-2">
      <Typography.TitleMd>{title}</Typography.TitleMd>
      <Typography.LabelSm color="text-secondary-500">
        {description}
      </Typography.LabelSm>
    </div>
  );
};
