import { useEffect } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { useSupportWidgetContext } from "../SupportWidgetContext";
import { getLocalStorage, setLocalStorage } from "../utils";

export const RecentLocationWrapper = ({ children }) => {
  const location = useLocation();
  const { collapseWidget } = useSupportWidgetContext();

  useEffect(() => {
    setLocalStorage({
      ...getLocalStorage(),
      location: location.pathname,
    });
    if (
      location.pathname !== "/form" &&
      !location.pathname.startsWith("/posts/") &&
      !location.pathname.startsWith("/news/")
    ) {
      collapseWidget();
    }
  }, [location.pathname, collapseWidget]);

  return children;
};

RecentLocationWrapper.propTypes = {
  children: PropTypes.node,
};
