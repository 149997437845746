import PropTypes from "prop-types";
import { t } from "i18n-js";
import isFunction from "lodash/isFunction";
import { useMutation } from "react-query";
import { spaceGroupApi } from "@circle-react/api";
import { SanitizeHTMLString } from "@circle-react/components/shared/uikit/SanitizeContent";
import {
  useSpaceGroupsContext,
  useSpacesContext,
} from "@circle-react/contexts";
import { useLinkToRoot } from "@circle-react/hooks/useLinkToRoot";
import { SecureConfirmationModal } from "@circle-react-shared/ModalsV2/SecureConfirmationModal";
import { useToast } from "@circle-react-uikit/ToastV2";
import { Typography } from "@circle-react-uikit/Typography";

export const DeleteSpaceGroupModal = ({
  show,
  spaceGroupId,
  onClose,
  onSuccess,
}) => {
  const { error, success } = useToast();
  const { goToCommunityRootPath } = useLinkToRoot();

  const {
    records: spaces,
    actions: { refresh: refreshSpaces },
  } = useSpacesContext();
  const {
    actions: { refresh: refreshSpaceGroups },
    helpers: { findById: findSpaceGroupById },
  } = useSpaceGroupsContext();

  const { mutate: deleteSpaceGroupMutation, isLoading: isDeleting } =
    useMutation(() => spaceGroupApi.destroy(spaceGroupId), {
      onSuccess: async () => {
        success(t("space_groups.form.delete.success"));
        await Promise.all([refreshSpaces(), refreshSpaceGroups()]);
        goToCommunityRootPath();

        isFunction(onClose) && onClose();
        isFunction(onSuccess) && onSuccess();
      },
      onError: () => {
        error(t("space_groups.form.delete.failed"));
      },
    });

  const spacesInSpaceGroup = spaces.filter(
    space => space.space_group_id === spaceGroupId,
  );

  const spaceGroup = findSpaceGroupById(spaceGroupId);

  return (
    <SecureConfirmationModal
      title={t("space_groups.delete.title")}
      confirmUserPrompt={t("space_groups.delete.confirm_prompt")}
      isOpen={show}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={deleteSpaceGroupMutation}
      confirmVariant="danger"
      isDisabled={isDeleting}
      confirmText={isDeleting ? t("deleting") : t("confirm")}
    >
      <SanitizeHTMLString
        content={t("space_groups.delete.description", {
          space_name: spaceGroup?.name || "",
        })}
      />{" "}
      {Boolean(spacesInSpaceGroup.length) && (
        <>
          {t("space_groups.delete.this_includes")}
          <div className="border-primary mt-4 rounded-md border">
            <div className="bg-secondary border-primary rounded-t-md border-b px-4 py-1">
              <Typography.LabelXs weight="semibold">
                {t("space_groups.delete.spaces_count", {
                  count: spacesInSpaceGroup.length,
                })}
              </Typography.LabelXs>
            </div>

            <ul className="max-h-56 w-full list-inside overflow-y-scroll p-4">
              {spacesInSpaceGroup.map(space => (
                <li className="truncate" key={space.id}>
                  {space.name}
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </SecureConfirmationModal>
  );
};

DeleteSpaceGroupModal.propTypes = {
  show: PropTypes.bool.isRequired,
  spaceGroupId: PropTypes.number.isRequired,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
};
