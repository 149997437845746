import { usePaywallGroupApi } from "../hooks/usePaywallGroupApi";

export const usePaywallGroup = ({
  modal,
  refetchPaywallGroups,
  paywallGroup,
}) => {
  const getFormDefaults = () => ({
    name: paywallGroup?.name || "",
    id: paywallGroup?.id || "",
    currency_id: paywallGroup?.currency?.id || "",
  });

  const { createMutation, updateMutation } = usePaywallGroupApi({
    closeModal: modal.hide,
    refetchPaywallGroups: refetchPaywallGroups,
  });

  const editionMode = !!paywallGroup;
  const mutation = editionMode ? updateMutation : createMutation;

  return {
    getFormDefaults,
    editionMode,
    mutation,
  };
};
