import classNames from "classnames";
import { t } from "i18n-js";
import { WORKFLOW_STATUS, WORKFLOW_TYPE } from "../../constants";
import { useWorkflowsData } from "../../context/WorkflowsDataProvider";
import { CommunityMemberColumn } from "../CommunityMemberColumn";
import { ActionGroup } from "../NewWorkflowModal/ActionGroup";
import { WorkflowsTable } from "../Table";
import { Actions } from "../Table/Actions";
import { Datetime } from "../Table/Datetime";
import { ScheduleType } from "../Table/ScheduleType";
import { WorkflowName } from "../Table/WorkflowName";
import { NextRunAtColumn } from "./ScheduledColumns/NextRunAtColumn";
import { columnStyles } from "./columnStyles";

const i18nRoot = "settings.workflows.table";

const columns = ({ refetch }) => [
  {
    accessorKey: "name",
    header: t([i18nRoot, "name"]),
    thClassName: columnStyles.title,
    cellClassName: columnStyles.title,
    cell: info => (
      <WorkflowName>
        {info.getValue() || t([i18nRoot, "untitled_workflow"])}
      </WorkflowName>
    ),
  },
  {
    accessorKey: "steps",
    header: t([i18nRoot, "steps"]),
    thClassName: columnStyles.steps,
    cellClassName: classNames("!py-0", columnStyles.steps),
    cell: info => (
      <ActionGroup
        actions={info.getValue()}
        size="md"
        truncationLimit={3}
        className="flex-wrap py-4"
      />
    ),
  },
  {
    accessorKey: "schedule_type",
    header: t([i18nRoot, "schedule_type"]),
    thClassName: columnStyles.scheduleType,
    cellClassName: columnStyles.scheduleType,
    cell: info => <ScheduleType scheduleType={info.getValue()} />,
  },
  {
    accessorKey: "created_by_community_member_id",
    header: t([i18nRoot, "created_by"]),
    thClassName: columnStyles.user,
    cellClassName: columnStyles.user,
    cell: info => (
      <CommunityMemberColumn id={info.getValue()} isLastNameHidden />
    ),
  },
  {
    accessorKey: "created_at",
    header: t([i18nRoot, "created_on"]),
    thClassName: columnStyles.dateShort,
    cellClassName: columnStyles.dateShort,
    cell: info => <Datetime date={info.getValue()} />,
  },
  {
    accessorKey: "next_run_at",
    header: t([i18nRoot, "next_run"]),
    thClassName: columnStyles.dateLong,
    cellClassName: columnStyles.dateLong,
    cell: info => (
      <NextRunAtColumn date={info.getValue()} steps={info.row.original.steps} />
    ),
  },
  {
    accessorKey: "last_run_at",
    header: t([i18nRoot, "last_run"]),
    thClassName: columnStyles.dateLong,
    cellClassName: columnStyles.dateLong,
    cell: info => <Datetime date={info.getValue()} shouldIncludeTime />,
  },
  {
    accessorKey: "tasks_count",
    header: t([i18nRoot, "total_runs"]),
    thClassName: columnStyles.number,
    thInnerClassName: "justify-end",
    cellClassName: classNames("text-right", columnStyles.number),
    cell: info => <span className="break-normal">{info.getValue() || 0}</span>,
  },
  {
    accessorKey: "actions",
    header: "",
    cellClassName: "cursor-default",
    onClick: ({ event }) => event.stopPropagation(),
    cell: ({ row }) => <Actions workflow={row.original} refetch={refetch} />,
  },
];

const getColumns =
  shouldShowFewerColumns =>
  ({ refetch }) => {
    const allColumns = columns({ refetch });

    if (!shouldShowFewerColumns) {
      return allColumns;
    }

    const reducedColumnKeys = [
      "name",
      "schedule_type",
      "created_by_community_member_id",
    ];
    return allColumns.filter(column =>
      reducedColumnKeys.includes(column.accessorKey),
    );
  };

export const ScheduledTable = () => {
  const { category } = useWorkflowsData();

  return (
    <WorkflowsTable
      columns={getColumns(category)}
      workflowType={WORKFLOW_TYPE.SCHEDULED}
      status={WORKFLOW_STATUS.ALL}
    />
  );
};
