import PropTypes from "prop-types";
import { t } from "i18n-js";
import { Typography } from "@circle-react-uikit/Typography";
import { BannerEditTabs } from "./BannerEditTabs";

export const CallToActionEdit = ({ blockDataPath }) => (
  <>
    <Typography.LabelLg weight="semibold">
      {t("courses.lockscreen.block_name.call_to_action")}
    </Typography.LabelLg>
    {/* Call to action has the same fields as the Banner block */}
    <BannerEditTabs blockDataPath={blockDataPath} />
  </>
);

CallToActionEdit.propTypes = {
  blockDataPath: PropTypes.string.isRequired,
};
