import { createContext, useContext, useState } from "react";
import { usePunditUserContext } from "@circle-react/contexts";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { useBackendProps } from "@circle-react/providers";
import { EmptyLayout } from "../EmptyLayout";
import { FullScreenLayout } from "../FullScreenLayout";
import { MinimalLayout as MinimalLayourComponent } from "../MinimalLayout";
import { StandardLayoutV2 } from "../StandardLayoutV2";
import { LAYOUT_CONSTANTS } from "./constants";
import type { LayoutType } from "./constants";
import { useSidebarDisabledV3 } from "./useSidebarDisabledV3";

const DynamicLayoutContext = createContext<any>(undefined);
DynamicLayoutContext.displayName = "DynamicLayoutContext";

export const DynamicLayout = ({
  disabledSidebar: isSidebarDisabledProp,
  ...props
}: any) => {
  const { isLoading } = usePunditUserContext();
  const { minimal_layout: isMinimalLayout } = useBackendProps();
  const [dynamicLayoutProps, setDynamicLayoutProps] = useState({});
  const [layoutType, setLayoutType] = useState<LayoutType>(
    isMinimalLayout ? LAYOUT_CONSTANTS.minimal : LAYOUT_CONSTANTS.standard,
  );
  const { isV3Enabled } = useIsV3();
  const isSidebarDisabledV3 = useSidebarDisabledV3();

  const isSidebarDisabled = isV3Enabled
    ? isSidebarDisabledV3
    : isSidebarDisabledProp;

  const setStandardLayout = () => setLayoutType(LAYOUT_CONSTANTS.standard);
  const setMinimalLayout = () => setLayoutType(LAYOUT_CONSTANTS.minimal);
  const setEmptyLayout = () => setLayoutType(LAYOUT_CONSTANTS.empty);

  let LayoutComponent: any;

  switch (layoutType) {
    case LAYOUT_CONSTANTS.fullscreen:
      LayoutComponent = FullScreenLayout;
      break;
    case LAYOUT_CONSTANTS.standard:
      LayoutComponent = StandardLayoutV2;
      break;
    case LAYOUT_CONSTANTS.minimal:
      LayoutComponent = MinimalLayourComponent;
      break;
    case LAYOUT_CONSTANTS.empty:
      LayoutComponent = EmptyLayout;
      break;
    default:
      LayoutComponent = StandardLayoutV2;
  }

  if (isLoading) {
    return null;
  }

  return (
    <DynamicLayoutContext.Provider
      value={{
        layoutType,
        setLayoutType,
        setStandardLayout,
        setMinimalLayout,
        setEmptyLayout,
        setDynamicLayoutProps,
        resetDynamicLayoutProps: () => setDynamicLayoutProps({}),
      }}
    >
      <LayoutComponent
        {...props}
        {...dynamicLayoutProps}
        disableSidebar={isSidebarDisabled}
      />
    </DynamicLayoutContext.Provider>
  );
};

export const useDynamicLayoutContext = () => useContext(DynamicLayoutContext);
