import { useLiveStreamContextActions } from "@circle-react/contexts/LiveStreams";
import { useProviderSyncRoom } from "./provider";
import { useLeaveRoom } from "./useLeaveRoom";

export const useEndRoom = () => {
  const { endStreamDelayedAsync } = useLiveStreamContextActions();
  const leaveRoom = useLeaveRoom();
  const syncRoom = useProviderSyncRoom();

  return async () => {
    await endStreamDelayedAsync();
    await syncRoom();
    await leaveRoom();
  };
};
