import { useEffect, useMemo, useState } from "react";
import flatten from "lodash/flatten";
import isNumber from "lodash/isNumber";
import { useInfiniteQuery } from "react-query";
import { workflowsApi } from "@/react/api";
import { WORKFLOW_RUN_STATUS } from "@/react/components/SettingsApp/Workflows/constants";

export const DEFAULT_PAGE_SIZE = 20;
export const IN_PROGRESS_REFETCH_INTERVAL = 5000;

const getRefetchInterval = (disableRefetching, areAllRunsCompleted) => {
  if (disableRefetching) return false;
  if (areAllRunsCompleted) return false;
  return IN_PROGRESS_REFETCH_INTERVAL;
};

export const useRunsInfiniteQuery = (args = {}) => {
  const [areAllRunsCompleted, setAreAllRunsCompleted] = useState(false);

  const {
    query = "",
    workflowId,
    perPage = DEFAULT_PAGE_SIZE,
    initialPage = 1,
    disableRefetching = false,
    options = {
      refetchInterval: getRefetchInterval(
        disableRefetching,
        areAllRunsCompleted,
      ),
      enabled: !!workflowId,
    },
  } = args;

  const fetchPage = ({ pageParam = initialPage }) => {
    let page = 1;

    if (isNumber(pageParam) && pageParam > 0) {
      page = pageParam;
    }

    const params = {
      page,
      per_page: perPage,
    };

    if (query) {
      params.query = query;
    }

    return workflowsApi.fetchRuns({ workflowId, params });
  };

  const queryKey = ["workflows", workflowId, "runs", query, perPage];

  const queryOptions = {
    getNextPageParam: lastPage =>
      lastPage.has_next_page ? lastPage.page + 1 : undefined,
    getPreviousPageParam: firstPage =>
      firstPage.page > 1 ? firstPage.page - 1 : undefined,
    keepPreviousData: true,
    ...options,
  };

  const result = useInfiniteQuery(queryKey, fetchPage, queryOptions);
  const pages = useMemo(() => result.data?.pages || [], [result.data?.pages]);
  const runs = useMemo(() => flatten(pages.map(page => page.records)), [pages]);

  useEffect(() => {
    if (result.isLoading) {
      return;
    }

    const areAllRunsCompleted = runs.every(
      run => run?.status !== WORKFLOW_RUN_STATUS.IN_PROGRESS,
    );
    setAreAllRunsCompleted(areAllRunsCompleted);
  }, [runs, result.isLoading]);

  return {
    ...result,
    queryKey,
    runs,
    areAllRunsCompleted,
  };
};
