import PropTypes from "prop-types";
import { useFetchEvent } from "@circle-workflows/hooks/useFetchEvent";
import { t } from "i18n-js";
import { Typography } from "@circle-react-uikit/Typography";
import { Badge } from "../../../Badge";
import { BaseView } from "../../BaseView";

const i18nRoot = "settings.workflows.view";
export const ViewEvent = ({ action }) => {
  const { api_params: apiParams } = action || {};
  const { event_id: eventId } = apiParams || {};
  const { data: event, isLoading } = useFetchEvent({ eventId });

  if (!event && !isLoading) {
    return null;
  }

  return (
    <BaseView>
      <BaseView.Section>
        <BaseView.Title>{t([i18nRoot, "event"])}</BaseView.Title>
        <div>
          {isLoading ? (
            <Badge isLoading />
          ) : (
            <Typography.LabelSm>{event.name}</Typography.LabelSm>
          )}
        </div>
      </BaseView.Section>
    </BaseView>
  );
};

ViewEvent.propTypes = {
  action: PropTypes.object,
};
