import { Form } from "@circle-react-uikit/Form";

export const ExcludeEmptyProfiles = () => {
  const localeScope =
    "members_directory.header.settings.form.exclude_empty_profiles";
  return (
    <Form.Item
      name="member_directory_settings.exclude_empty_profiles"
      label={I18n.t(`${localeScope}.title`)}
      description={I18n.t(`${localeScope}.description`)}
      isToggle
      hideBorder
      inline
    >
      <Form.ToggleSwitch variant="small" />
    </Form.Item>
  );
};
