import { createContext, useContext } from "react";
import type { PropsWithChildren } from "react";
import { noop } from "lodash";

export interface PaywallFormProviderProp {
  onClose: () => void;
}

const PaywallFormProvider = createContext<PaywallFormProviderProp>({
  onClose: noop,
});
PaywallFormProvider.displayName = "PaywallFormProvider";

export const usePaywallFormContext = () => useContext(PaywallFormProvider);

export const PaywallFormProviderContext = ({
  children,
  onClose,
}: PropsWithChildren<PaywallFormProviderProp>) => {
  const value = {
    onClose,
  };

  return (
    <PaywallFormProvider.Provider value={value}>
      {children}
    </PaywallFormProvider.Provider>
  );
};
