import invariant from "tiny-invariant";
import type { Action } from "@circle-react/components/SettingsApp/Workflows/generated/WorkflowsApi.types";
import { BroadcastDataProvider } from "@circle-react-shared/Emails/BroadcastDataContext";
import { BroadcastReportContextProvider } from "@circle-react-shared/Emails/BroadcastReport/BroadcastReportContext";
import { ViewEmailWrapper } from "./ViewSendEmail/ViewEmailWrapper";

interface ViewSendEmailProps {
  action?: Action;
}

export function ViewSendEmail({ action }: ViewSendEmailProps) {
  const { api_params: apiParams } = action || {};

  const getBroadcastId = () => {
    if (apiParams && "broadcast_id" in apiParams) {
      return Number(apiParams.broadcast_id);
    }
    invariant(false, "Broadcast ID is required for send_email action");
  };

  return (
    <BroadcastDataProvider id={getBroadcastId()}>
      <BroadcastReportContextProvider id={getBroadcastId()}>
        <ViewEmailWrapper />
      </BroadcastReportContextProvider>
    </BroadcastDataProvider>
  );
}
