import { t } from "i18n-js";
import { Form } from "@circle-react-shared/uikit/Form";
import { TiptapEditor } from "@circle-react-shared/uikit/TipTap";
import { MenuBar } from "./MenuBar";

interface QuestionFormProps {
  onSubmit: (value: any) => void;
}

interface TiptapContent {
  body: object;
}

const DEFAULT_VALUES = {
  body: {
    type: "doc",
    content: [],
  },
};

export const MessageBox = ({ onSubmit }: QuestionFormProps) => (
  <Form onSubmit={onSubmit} defaultValues={DEFAULT_VALUES}>
    {() => {
      const handleSubmit = (content: TiptapContent) => {
        onSubmit({ body: content });
      };
      return (
        <TiptapEditor
          menuBarComponent={MenuBar}
          placeholder={t("community_inbox.message_bot")}
          className="!mb-2 !flex-row justify-between"
          onSubmit={handleSubmit}
        />
      );
    }}
  </Form>
);
