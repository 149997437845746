import type { Ref } from "react";
import { useEffect, useState } from "react";
import { t } from "i18n-js";
import { useFormContext } from "react-hook-form";
import { isValidTiptapBody } from "@/react/components/QuickPostV2/validation";
import { dynamicVariablesCorrectedTranslation } from "@/react/helpers/localeHelpers";
import type { CoursesSection } from "@/react/types/CoursesSection";
import { Form } from "@circle-react-uikit/Form";
import { FormTipTapBlockEditor } from "@circle-react-uikit/Form/FormTipTapBlockEditor/FormTipTapBlockEditor";
import { FullToolbar } from "@circle-react-uikit/TipTapBlockEditor/FullToolbar";
import { useNotifyStudentsModalStore } from "../../store";
import { InsertVariablesMenu } from "./InsertVariablesMenu";

interface FormContentProps {
  section: CoursesSection;
  initialFocusRef?: Ref<HTMLInputElement | HTMLTextAreaElement>;
}

export const FormContent = ({ section, initialFocusRef }: FormContentProps) => {
  const { setIsDirty } = useNotifyStudentsModalStore();
  const {
    formState: { isDirty },
    getValues,
  } = useFormContext();

  const [hasBodyError, setHasBodyError] = useState(
    !isValidTiptapBody(getValues("email_notification_body")),
  );

  const isEmailEmpty =
    !section.serialized_email_notification_body?.body &&
    !section.email_notification_subject;

  useEffect(() => {
    setIsDirty(isDirty);
  }, [setIsDirty, isDirty]);

  useEffect(() => {
    if (isEmailEmpty) {
      setIsDirty(true);
    }
  }, [setIsDirty, isEmailEmpty]);

  const translationRoot = "courses.edit.sections.notify_students_modal.form";

  return (
    <>
      <Form.Item
        name="email_notification_subject"
        className="!items-center !py-0 px-8"
        label={t(`${translationRoot}.email_notification_subject`)}
        labelWrapperClassName="!w-auto grow-0"
        labelClassName="!m-0"
        hideDescription
        inline
      >
        <Form.Input
          maxLength="255"
          ref={initialFocusRef}
          placeholder={dynamicVariablesCorrectedTranslation(
            t([translationRoot, "email_notification_subject_placeholder"]),
          )}
          otherClassName="!px-0 !autofill:bg-yellow-200"
          focusRequired={false}
          autoComplete="off"
          hideBorder
        />
      </Form.Item>
      <div className="relative">
        <div id="drip-email-notification-editor-toolbar" />
        <FormTipTapBlockEditor
          editorClassName="mt-4 h-80 overflow-y-scroll px-8"
          onChangeCallback={value => {
            setHasBodyError(!isValidTiptapBody({ body: value }));
          }}
          placeholder={t(
            `${translationRoot}.email_notification_body_placeholder`,
          )}
          name="email_notification_body[body]"
          sgidToObjectMap={
            section.serialized_email_notification_body?.sgids_to_object_map ||
            {}
          }
          inlineAttachments={
            section.serialized_email_notification_body?.inline_attachments || []
          }
          disabledExtensions={["mention", "poll", "cta"]}
          disabledSlashMenuCategories={["Embed"]}
          aiCopilotTitle={section.name}
          shouldPreviewFilesAsLinks
          shouldShowTextModifiersMenu={false}
          toolbars={[
            {
              portalId: "drip-email-notification-editor-toolbar",
              component: FullToolbar,
            },
            {
              portalId: "drip-email-notification-variables-toolbar",
              component: InsertVariablesMenu,
            },
          ]}
        />
        {hasBodyError && (
          <p className="absolute bottom-2 right-8 text-red-500">
            {t(`${translationRoot}.required`)}
          </p>
        )}
      </div>
      <div id="drip-email-notification-variables-toolbar" />
    </>
  );
};
