import { useState } from "react";
import { useQuery } from "react-query";
import { paywallSubscriptionsApi } from "@/react/api/paywallSubscriptionsApi";

const RESULTS_PER_PAGE = 10;

export const paywallSubscriptionQueryString = "paywall-subscriptions";

type FIELDS_AVAILABLE_TO_ORDER = "created_at";

interface UseAdminPaywallSubscriptionProp {
  orderParam?: Record<FIELDS_AVAILABLE_TO_ORDER, "desc" | "asc">;
  perPage?: number;
  searchKeyParams?: string;
  filters?: FiltersProp;
  enabled?: boolean;
  page: string | number;
  setPage: (value: any) => void;
}

interface FiltersProp {
  community_member_public_uid?: string;
  community_member_id?: string;
  paywall_ids?: string;
  processor_id?: string;
}

export const useAdminPaywallSubscription = ({
  orderParam = { created_at: "desc" },
  perPage = RESULTS_PER_PAGE,
  searchKeyParams = "",
  filters = {},
  enabled = true,
  page,
  setPage,
}: UseAdminPaywallSubscriptionProp) => {
  const [order, setOrder] = useState(orderParam);

  const httpParams = {
    filters,
    order,
    page,
    perPage,
    searchKey: searchKeyParams,
  };

  const fetchPaywalls = paywallSubscriptionsApi.index(httpParams);

  const {
    data,
    refetch: refetchSubscriptions,
    isLoading: isLoadingSubscriptions,
    remove: removeSubscriptionsFromCache,
  } = useQuery({
    queryKey: [
      paywallSubscriptionQueryString,
      {
        filters,
        order,
        page,
        perPage,
      },
      searchKeyParams,
    ],
    queryFn: fetchPaywalls,
    enabled,
  });

  const updateOrder = (value: any) => {
    setOrder(value);
    setPage(1);
  };

  return {
    hasFilters: Object.keys(filters).length > 0,
    subscriptions: data?.records ?? [],
    totalSubscriptions: data?.count ?? 0,
    refetchSubscriptions,
    removeSubscriptionsFromCache,
    perPage,
    page,
    order,
    setOrder: updateOrder,
    setPage,
    isLoadingSubscriptions,
  };
};
