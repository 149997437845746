import { t } from "i18n-js";
import "./styles.scss";

export const FullScreenTabs = ({ children }) => (
  <div className="react-layout-fullscreen-tabs">
    <div className="react-layout-fullscreen-wrapper">
      <nav aria-label={t("full_screen_layout.tabs")}>{children}</nav>
    </div>
  </div>
);
