import { useEffect, useRef } from "react";
import autosize from "autosize";
import { t } from "i18n-js";
import { useFormContext, useWatch } from "react-hook-form";
import { Icon } from "@circle-react/components/shared/Icon";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { Form } from "@circle-react-uikit/Form";
import "./styles.css";

export const ChatForm = ({ onSubmit }) => {
  const { handleSubmit, reset } = useFormContext();
  const message = useWatch({ name: "message" });
  const textareaRef = useRef(null);

  const handleTextAreaInputChange = () => {
    autosize(textareaRef.current);
  };
  const handleOnEnter = event => {
    if (!event.shiftKey && event.key == "Enter") {
      handleSubmit(onSubmit)();
      reset();
      event.preventDefault();
    }
  };
  const handleIconClick = () => {
    handleSubmit(onSubmit)();
    reset();
  };

  useEffect(() => {
    handleTextAreaInputChange();
  }, []);

  return (
    <div className="flex">
      <Form.Input
        name="message"
        variant="textarea"
        label={null}
        placeholder={t("support_widget.chatbot.message_placeholder")}
        ref={textareaRef}
        rows={1}
        onInput={handleTextAreaInputChange}
        onKeyDown={handleOnEnter}
        hideBorder
        focusRequired={false}
        className="form-control hide-scrollbar max-h-[128px] resize-none !py-4 !pl-6 focus:!ring-0 focus:!ring-offset-0"
      />
      <div className="text-circle pr-4 pt-4">
        {message && (
          <TippyV2 placement="top" content={t("support_widget.chatbot.send")}>
            <Icon
              type="tiptap-send"
              className="cursor-pointer"
              onClick={handleIconClick}
            />
          </TippyV2>
        )}
      </div>
    </div>
  );
};
