import { CommentHierarchy } from "./CommentHierarchy";
import { Form } from "./Form";
import { List } from "./List";

export const CommentsForm = Form;
export const CommentsList = List;

export default {
  Form,
  CommentsList,
  CommentHierarchy,
};
