import type { PropsWithChildren } from "react";
import classnames from "classnames";
import { useSpaceAccessManagementContext } from "../SpaceAccessManagementContext";
import { EmptyResult } from "./EmptyResult";

interface BodyProp {
  className?: string;
}

export const Body = ({ children, className }: PropsWithChildren<BodyProp>) => {
  const { getHasAnySearchResults } = useSpaceAccessManagementContext();

  if (!getHasAnySearchResults()) {
    return <EmptyResult />;
  }

  return (
    <div
      className={classnames(
        "flex w-full flex-auto flex-col overflow-hidden",
        className,
      )}
    >
      {children}
    </div>
  );
};
