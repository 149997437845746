import PropTypes from "prop-types";
import { QueryClientProvider } from "@circle-react/providers/QueryClient";
import { CreatePaywallSubscriptionFormContextProvider } from "@circle-react-shared/Paywalls/Modals/CreatePaywallSubscriptionModal/Form/CreatePaywallSubscriptionFormContextProvider";
import { Loader } from "@circle-react-uikit/Loader";
import { BulkImportTrialPaywallSubscriptionFormContextProvider } from "./BulkImportTrialPaywallSubscriptionFormProvider";
import { useBulkImportTrialPaywallSubscriptionFormContext } from "./BulkImportTrialPaywallSubscriptionFormProvider";
import { CouponForm } from "./CouponForm";
import { NotifyMemberForm } from "./NotifyMemberForm";
import { PaywallForm } from "./PaywallForm";
import { PaywallPriceForm } from "./PaywallPriceForm";
import { TrialDaysForm } from "./TrialDaysForm";

export const BulkImportTrialPaywallSubscriptionForm = () => {
  const { isPaywallLoading } =
    useBulkImportTrialPaywallSubscriptionFormContext();

  if (isPaywallLoading) {
    return <Loader />;
  }

  return (
    <div className="flex flex-col pb-6">
      <div className="flex flex-col gap-x-5 md:flex-row">
        <PaywallForm />
        <PaywallPriceForm />
      </div>
      <div className="flex flex-col gap-x-5 md:flex-row">
        <CouponForm />
        <TrialDaysForm />
      </div>
      <div className="flex flex-col gap-x-5 md:flex-row">
        <NotifyMemberForm />
      </div>
    </div>
  );
};

export const BulkImportTrialPaywallSubscriptionFormRailsView = ({
  paywall_id: paywallId,
  paywall_price_id: paywallPriceId,
  paywall_trial_days: paywallTrialDays,
  paywall_coupon_code: paywallCouponCode,
  paywall_skip_date_validations: skipDateValidations = true,
  hide_notify_member_form: hideNotifyMemberForm = false,
}) => (
  <QueryClientProvider>
    <CreatePaywallSubscriptionFormContextProvider>
      <BulkImportTrialPaywallSubscriptionFormContextProvider
        {...{
          paywallId,
          paywallPriceId,
          paywallTrialDays,
          paywallCouponCode,
          skipDateValidations,
          hideNotifyMemberForm,
        }}
      >
        <BulkImportTrialPaywallSubscriptionForm />
      </BulkImportTrialPaywallSubscriptionFormContextProvider>
    </CreatePaywallSubscriptionFormContextProvider>
  </QueryClientProvider>
);

BulkImportTrialPaywallSubscriptionForm.propTypes = {
  paywallId: PropTypes.string,
  paywallPriceId: PropTypes.string,
  paywallTrialDays: PropTypes.string,
  paywallCouponCode: PropTypes.string,
};

BulkImportTrialPaywallSubscriptionFormRailsView.propTypes = {
  paywall_id: PropTypes.string,
  paywall_price_id: PropTypes.string,
  paywall_trial_days: PropTypes.string,
  paywall_coupon_code: PropTypes.string,
  paywall_skip_date_validations: PropTypes.bool,
  hide_notify_member_form: PropTypes.bool,
};
