import { usePaywallCheckoutContext } from "@circle-react/contexts/Paywalls/paywallCheckoutContext";

export const usePaymentElementDefaultValuesOption = () => {
  const { currentUser } = usePaywallCheckoutContext();

  const defaultValuesOption =
    currentUser && currentUser.email
      ? { defaultValues: { billingDetails: { email: currentUser.email } } }
      : {};

  return {
    defaultValuesOption,
  };
};
