import { t } from "i18n-js";
import { isFunction } from "lodash";
import { useLocation } from "react-router-dom";
import { useProfileDrawer } from "@/react/components/Drawers/ProfileDrawer";
import { ProfileSectionName } from "@/react/components/Spaces/SidebarV2/ProfileSection/ProfileSectionName";
import { memberProfileModalPathsWithState } from "@circle-react/helpers/urlHelpers";
import { UserImage } from "@circle-react-shared/uikit/UserImage";
import { Dropdown } from "@circle-react-uikit/Dropdown";
import type {
  DropdownOptions,
  PopperOptions,
} from "@circle-react-uikit/Dropdown";

const popperOptions: PopperOptions = {
  strategy: "absolute",
  modifiers: [
    {
      name: "offset",
      options: {
        offset: [0, 8],
      },
    },
  ],
};

export interface ProfileSectionDropdownProps {
  imageUrl?: string;
  name?: string;
  onEditProfileClick?: () => void;
  onSignOutClick?: () => void;
  publicUid?: string;
  headline?: string;
  showMemberBilling?: boolean;
  canAccessAuthenticationPage?: boolean;
  canShowPaywallsAffiliate?: boolean;
}

export const ProfileSectionDropdown = ({
  imageUrl = "",
  name = "",
  onEditProfileClick,
  onSignOutClick,
  publicUid = "",
  headline = "",
  showMemberBilling = false,
  canAccessAuthenticationPage = false,
  canShowPaywallsAffiliate = false,
}: ProfileSectionDropdownProps) => {
  const location = useLocation();
  const { show: showMemberProfileDrawer } = useProfileDrawer();

  const dropDownOptions: DropdownOptions = [];

  dropDownOptions.push(
    {
      value: t("view_profile"),
      label: t("view_profile"),
      onClick: () => showMemberProfileDrawer({ memberPublicId: publicUid }),
      icon: "16-account",
      dataTestId: "profile-link",
    },
    {
      value: t("edit_profile"),
      label: t("edit_profile"),
      onClick: () => isFunction(onEditProfileClick) && onEditProfileClick(),
      icon: "16-edit",
      dataTestId: "edit-profile-link",
    },
    {
      value: t("notification"),
      label: t("notification"),
      pathTo: memberProfileModalPathsWithState.notifications(location),
      icon: "sidebar-bell",
      dataTestId: "notification-link",
    },
  );

  if (canAccessAuthenticationPage) {
    dropDownOptions.push({
      value: t("authentication"),
      label: t("authentication"),
      pathTo: memberProfileModalPathsWithState.authentication(location),
      icon: "16-lock-unlocked",
      dataTestId: "authentication-link",
    });
  }

  if (showMemberBilling) {
    dropDownOptions.push({
      value: t("billing"),
      label: t("billing"),
      pathTo: memberProfileModalPathsWithState.billing(location),
      icon: "credit-card",
      dataTestId: "member-billing-link",
    });
  }

  if (canShowPaywallsAffiliate) {
    dropDownOptions.push({
      value: t("member_profile_modal.affiliate_dashboard.title"),
      label: t("member_profile_modal.affiliate_dashboard.title"),
      pathTo: memberProfileModalPathsWithState.affiliate(location),
      icon: "16-paywalls-affiliates",
      dataTestId: "member-affiliate-link",
    });
  }

  dropDownOptions.push(
    {
      value: "separator",
    },
    {
      value: t("sign_out"),
      label: t("sign_out"),
      onClick: () => isFunction(onSignOutClick) && onSignOutClick(),
      icon: "16-sign-out",
    },
  );

  return (
    <Dropdown
      size="full"
      direction="top-start"
      dataTestId="user-profile-dropdown"
      className="inline-flex"
      buttonWrapperClassName="inline-flex"
      button={
        <div
          className="flex items-center gap-[14px]"
          data-testid="user-profile-button"
        >
          <UserImage name={name} src={imageUrl} size="10" />
          <ProfileSectionName headline={headline} name={name} />
        </div>
      }
      popperOptions={popperOptions}
    >
      <div data-testid="user-profile-menu">
        <Dropdown.Header>{t("sidebar_account")}</Dropdown.Header>
        <Dropdown.IconWithBadgeList options={dropDownOptions} />
      </div>
    </Dropdown>
  );
};
