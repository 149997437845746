import { isOneTimePrice } from "@circle-react/helpers/paywallPriceHelpers";
import { formatPaywallCurrencyAmount } from "@circle-react/helpers/paywalls/currencies";
import { Typography } from "@circle-react-uikit/Typography";

export const Price = ({ price, currency }: any) => {
  const displayAmount = formatPaywallCurrencyAmount(currency, price?.amount, {
    includeCurrencyCode: false,
    includeCurrencySymbol: true,
  });

  return (
    <div className="flex flex-col">
      <div className="flex flex-row items-center gap-x-1">
        <Typography.TitleXl size="text-4xl">{displayAmount}</Typography.TitleXl>
        <Typography.LabelSm color="text-default">
          {currency.code.toUpperCase()}
        </Typography.LabelSm>
      </div>
    </div>
  );
};

const PriceOneTime = Object.assign(Price, {
  validateToRender: (price: any) => isOneTimePrice(price),
});

export { PriceOneTime };
