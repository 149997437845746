import type { ReactNode } from "react";
import classNames from "classnames";
import { isUndefined } from "lodash";
import type { IconType } from "@circle-react/components/shared/Icon";
import { Icon } from "@circle-react/components/shared/Icon";
import { useGetSidebarLinkClass } from "../CommunityMenu/SpaceList/useGetSidebarLinkClass";
import { SidebarBadge } from "./SidebarBadge";

export interface PlatformMenuItemProps {
  badgeText?: string;
  dataTestId?: string;
  iconName: IconType;
  isActive?: boolean;
  label: string;
  onClick: () => void;
  rightSideContent?: ReactNode;
  isGray?: boolean;
  shouldBold?: boolean;
  ariaLabel?: string;
}

export const PlatformMenuItem = ({
  badgeText,
  dataTestId,
  iconName,
  isActive = false,
  label,
  onClick,
  rightSideContent,
  isGray = false,
  shouldBold = false,
  ariaLabel,
}: PlatformMenuItemProps) => {
  const { getActiveInactiveClasses } = useGetSidebarLinkClass();

  const shouldShowBadge =
    !isUndefined(badgeText) && Number(badgeText) > 0 && !rightSideContent;
  return (
    <button
      type="button"
      data-testid={dataTestId}
      onClick={onClick}
      className={classNames(
        "my-px flex w-full items-center px-2 py-1.5 text-sm",
        getActiveInactiveClasses(isActive),
      )}
      aria-labelledby={label}
    >
      <Icon type={iconName} size={16} aria-hidden />
      <div className="ml-2 flex-1 text-left">
        <h3
          className={classNames("max-w-full truncate text-sm text-current", {
            "font-normal": !shouldBold,
            "font-bold": shouldBold,
          })}
        >
          {label}
        </h3>
      </div>
      {shouldShowBadge && (
        <SidebarBadge ariaLabel={ariaLabel} isGray={isGray}>
          {badgeText}
        </SidebarBadge>
      )}
      {!badgeText && rightSideContent && rightSideContent}
    </button>
  );
};
