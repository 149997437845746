import { useSettingsHeader } from "@circle-react-shared/uikit/HeaderV3/useSettingsHeader";
import { useHeightStyles } from "./useHeightStyles";

export const useTopBarClassNames = (
  isPromoCodeEnabledAndHasSubscriptionExpired: boolean,
  isMasqueradingEnabledAndActive: boolean,
  isEitherOptInOrPreviewBarVisible: boolean,
): string => {
  const { isReactFlowWorkflowsRoute } = useSettingsHeader();
  const {
    topBarStyles,
    topBarStylesWithMasquerade,
    topBarStylesWithEitherOptInOrPreviewBar,
    topBarStylesWithMasqueradeAndEitherOptInOrPreviewBar,
  } = useHeightStyles();

  if (isReactFlowWorkflowsRoute) {
    return "";
  }

  if (
    (!isPromoCodeEnabledAndHasSubscriptionExpired &&
      !isMasqueradingEnabledAndActive &&
      !isEitherOptInOrPreviewBarVisible) ||
    (isPromoCodeEnabledAndHasSubscriptionExpired &&
      isMasqueradingEnabledAndActive &&
      !isEitherOptInOrPreviewBarVisible)
  ) {
    return topBarStyles;
  }

  if (
    !isPromoCodeEnabledAndHasSubscriptionExpired &&
    isMasqueradingEnabledAndActive &&
    !isEitherOptInOrPreviewBarVisible
  ) {
    return topBarStylesWithMasquerade;
  }

  if (
    (!isPromoCodeEnabledAndHasSubscriptionExpired &&
      !isMasqueradingEnabledAndActive &&
      isEitherOptInOrPreviewBarVisible) ||
    (isPromoCodeEnabledAndHasSubscriptionExpired &&
      isMasqueradingEnabledAndActive &&
      isEitherOptInOrPreviewBarVisible)
  ) {
    return topBarStylesWithEitherOptInOrPreviewBar;
  }

  if (
    !isPromoCodeEnabledAndHasSubscriptionExpired &&
    isMasqueradingEnabledAndActive &&
    isEitherOptInOrPreviewBarVisible
  ) {
    return topBarStylesWithMasqueradeAndEitherOptInOrPreviewBar;
  }

  return "";
};
