import type { Space } from "@/react/types/Space";
import {
  canInviteMembers,
  canManageSpace,
  isSortingEnabled,
  isSpaceMember,
} from "@circle-react/helpers/spaceHelpers";
import { PostSpaceActions } from "../../SpaceHeaderActions/PostSpaceActions";
import { InviteMemberSpaceOptions } from "../../SpaceHeaderTitleDropdown/spaceOptions/InviteMemberSpaceOptions";
import { LeaveAndDeleteSpaceOptions } from "../../SpaceHeaderTitleDropdown/spaceOptions/LeaveAndDeleteSpaceOptions";
import { ManageSpaceOptions } from "../../SpaceHeaderTitleDropdown/spaceOptions/ManageSpaceOptions";
import { PostSortOptions } from "../../SpaceHeaderTitleDropdown/spaceOptions/PostSortOptions";
import { Separator } from "./Separator";

export const PostSpaceOptions = ({
  space,
  setIsAddMemberOpen,
  onClickAddCoverImage,
  isAdmin,
}: {
  space: Space;
  setIsAddMemberOpen: (isOpen: boolean) => void;
  onClickAddCoverImage: () => void;
  isAdmin: boolean;
}) => {
  const isSortingEnabledForSpace = isSortingEnabled(space);
  const canMemberManageSpace = canManageSpace(space);
  const isMember = isSpaceMember(space);
  return (
    <div className="flex flex-col">
      <PostSpaceActions space={space} />
      <Separator />
      {isSortingEnabledForSpace && (
        <>
          <PostSortOptions space={space} />
          <Separator />
        </>
      )}
      {canMemberManageSpace && (
        <>
          <ManageSpaceOptions
            space={space}
            onInviteMember={() => setIsAddMemberOpen(true)}
            onAddCoverImage={onClickAddCoverImage}
          />
          <Separator />
        </>
      )}
      {!isAdmin && isMember && canInviteMembers(space) && (
        <>
          <InviteMemberSpaceOptions
            space={space}
            onInviteMember={() => setIsAddMemberOpen(true)}
          />
          <Separator />
        </>
      )}
      <LeaveAndDeleteSpaceOptions space={space} isAdmin={isAdmin} />
    </div>
  );
};
