import { Fragment } from "react";

const ActorName = ({ notification }) => (
  <span className="notifications__name">{notification.actor_name} </span>
);

const DisplayAction = ({ notification }) => (
  <span>{notification.display_action} </span>
);

const NotifiableTitle = ({ notification }) => (
  <span className="notifications__title">{notification.notifiable_title} </span>
);

const TEXT_STRUCTURE_ITEM_TO_COMPONENT_MAP = {
  actor_name: ActorName,
  display_action: DisplayAction,
  notifiable_title: NotifiableTitle,
};

export const Text = props => {
  const { notification } = props;

  return (
    <div className="notifications__text" key={notification.id}>
      {notification.notification_text_structure.map((entry, index) => {
        const Comp =
          TEXT_STRUCTURE_ITEM_TO_COMPONENT_MAP[entry] ||
          (() => <span key={index}> {entry}</span>);
        const isLastEntry =
          index + 1 === notification.notification_text_structure.length;
        const isFirstEntry = index === 0;
        return (
          <Fragment key={`notification-key-${index}`}>
            <Comp notification={notification} />
            {(isLastEntry || isFirstEntry) && " "}
          </Fragment>
        );
      })}
    </div>
  );
};
