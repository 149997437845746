import { formatPaywallCurrencyAmount } from "@/react/helpers/paywalls/currencies";
import { mapCurrencyAmountStrToInt } from "@circle-react/components/Paywalls/Admin/utils";
import { paywallPriceTypes } from "@circle-react/types";
import type {
  NoticeScenario,
  NoticeScenarioMatcher,
  NoticeScenarioMessagePropsResolver,
} from "../NoticeScenario.types";
import { installmentsStateValidator } from "./StateValidators";

const matcher: NoticeScenarioMatcher = ({
  priceType,
  hasUpfrontPayment,
  hasTrialPeriod,
}) =>
  priceType === paywallPriceTypes.installments &&
  hasUpfrontPayment &&
  !hasTrialPeriod;
const messagePropsResolver: NoticeScenarioMessagePropsResolver = ({
  buildOptions,
  currency,
  watch,
}) => {
  const totalAmount = mapCurrencyAmountStrToInt(watch("amount"));
  const upfrontPaymentAmount = mapCurrencyAmountStrToInt(
    watch("upfront_payment_amount"),
  );
  const installmentsCount = parseInt(watch("installments_count"));
  const installmentAmount = Math.round(
    (totalAmount - upfrontPaymentAmount) / installmentsCount,
  );
  return [
    "upfront_then_installments",
    {
      installments_frequency:
        buildOptions.frequencies[
          watch("billing_frequency")
        ].as_adverb.toLowerCase(),
      installments_count: installmentsCount,
      installment_amount: formatPaywallCurrencyAmount(
        currency,
        installmentAmount,
      ),
      total_amount: formatPaywallCurrencyAmount(currency, totalAmount),
      upfront_payment_amount: formatPaywallCurrencyAmount(
        currency,
        upfrontPaymentAmount,
      ),
    },
  ];
};

export const upfrontThenInstallments: NoticeScenario = {
  matcher,
  stateValidator: installmentsStateValidator,
  messagePropsResolver,
};
