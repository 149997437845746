import classnames from "classnames";
import { Switch } from "@circle-react-uikit/Switch";
import { Typography } from "@circle-react-uikit/Typography";
import type { SpaceOption } from "../../SpaceAccessManagementContext";
import { Item } from "./Item";
import { ItemTitleWithIcon } from "./ItemTitleWithIcon";
import { useChildItem } from "./hooks/useChildItem";

interface SpaceOptionWithSpaceGroupId {
  spaceGroupId: number;
  space: SpaceOption;
  onAddChild?: (action: () => void, object: SpaceOption) => void;
  onRemoveChild?: (action: () => void, object: SpaceOption) => void;
}

export const ChildMobileItem = ({
  spaceGroupId,
  space,
  onAddChild = action => {
    action();
  },
  onRemoveChild = action => {
    action();
  },
}: SpaceOptionWithSpaceGroupId) => {
  const { name, icon, tooltipText } = space;
  const { onRemoveClick, onAddClick, isChecked, isSpaceFound } = useChildItem({
    space,
    spaceGroupId,
    onAddChild,
    onRemoveChild,
  });

  const onClick = isChecked ? onRemoveClick : onAddClick;

  return (
    <Item
      className={classnames({ hidden: !isSpaceFound })}
      isDisabled={false}
      onClick={onClick}
    >
      <ItemTitleWithIcon icon={icon} tooltipText={tooltipText}>
        <Typography.LabelSm>{name}</Typography.LabelSm>
      </ItemTitleWithIcon>
      <Switch as="span" checked={isChecked} />
    </Item>
  );
};
