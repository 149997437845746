import { useFetchUpcomingEvents } from "@circle-workflows/hooks/useFetchUpcomingEvents";
import { t } from "i18n-js";
import { useFormContext } from "react-hook-form";
import { FormTextSelect } from "@circle-react-uikit/Form/FormTextSelect";
import { Loader } from "@circle-react-uikit/Loader";
import { Typography } from "@circle-react-uikit/Typography";

const per_page = 100;
export const WhichEventSelector = () => {
  const { register } = useFormContext();
  const {
    data: events,
    isLoading,
    isError,
  } = useFetchUpcomingEvents({
    per_page,
  });

  const eventsOptions = events?.records?.map((event: any) => {
    const { name, space_name: spaceName, start_date: startDate, id } = event;

    const eventName = event.recurring
      ? `${String(name)} ${t("settings.workflows.form.recurring_label")}`
      : event.name;

    const eventDescription = event.paid_event
      ? `${String(spaceName)} • ${String(startDate)} • ${event.ticket_price}`
      : `${String(spaceName)} • ${String(startDate)}`;

    return {
      label: eventName,
      value: id,
      description: eventDescription,
    };
  });

  if (isLoading) {
    return <Loader center />;
  }

  if (isError) {
    return (
      <Typography.LabelXs>
        <span className="text-v2-danger">
          {t("settings.workflows.form.errors.upcoming_events.fetch_failed")}
        </span>
      </Typography.LabelXs>
    );
  }

  return (
    <>
      <div className="hidden">
        <input type="hidden" {...register("trigger.resource_type")} />
      </div>

      <FormTextSelect
        name="trigger.resource_id"
        label={
          <Typography.LabelSm weight="semibold">
            {t("settings.workflows.form.which_event")}
          </Typography.LabelSm>
        }
        options={eventsOptions}
        rules={{
          required: t("settings.workflows.form.errors.required"),
        }}
        emptyMessage={t(
          "settings.workflows.form.errors.upcoming_events.not_found",
        )}
      />
    </>
  );
};
