import type { ReactNode } from "react";
import { useHideMainHeader } from "@/react/hooks/useHideTrialBanner";
import { LayoutComponent } from "./LayoutComponent";

interface NoHeaderLayoutComponentProps {
  children: ReactNode;
}

export const NoHeaderLayoutComponent = ({
  children,
  ...rest
}: NoHeaderLayoutComponentProps) => {
  useHideMainHeader();

  return <LayoutComponent {...rest}>{children}</LayoutComponent>;
};
