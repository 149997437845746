import { forwardRef, useState } from "react";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import classNames from "classnames";
import I18n from "i18n-js";
import { NotifyStudentsToggle } from "@/react/components/Spaces/CourseSpace/NotifyStudents/NotifyStudentsToggle";
import {
  isDrippedCourseSpace,
  isScheduledCourseSpace,
  isStructuredCourseSpace,
} from "@/react/helpers/courseHelpers";
import {
  TableBody,
  TableCell,
  TableRow,
} from "@circle-react/components/shared/uikit/Table";
import type { TableBodyProps } from "@circle-react/components/shared/uikit/Table/TableBody";
import { useSmScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { useBooleanList } from "@circle-react/hooks/utils/useBooleanList";
import { Icon } from "@circle-react-shared/Icon";
import { Dropdown } from "@circle-react-uikit/Dropdown";
import { Typography } from "@circle-react-uikit/Typography";
import { AddLessonDropdown } from "./AddLessonDropdown";
import { ConfirmSectionDelete } from "./ConfirmSectionDelete";
import { DraggableLessonItem } from "./DraggableLessonItem";
import { DripScheduleForm } from "./DripSchedule/DripScheduleForm";
import { useMobileCourseRenameModal } from "./MobileRenameModal";
import { SectionNameEditor } from "./SectionNameEditor";

type TableProps = Omit<TableBodyProps, "children">;

export interface SectionItemProps {
  dragHandleProps?: any;
  isDragging?: boolean;
  isNameEditable?: boolean;
  isOverlay?: boolean;
  section: any;
  setIsNameEditable?: (...args: any[]) => void;
  setSections?: (...args: any[]) => void;
  space: any;
  tableBodyProps?: TableProps;
}

export const SectionItem = forwardRef<
  HTMLTableSectionElement,
  SectionItemProps
>(
  (
    {
      dragHandleProps,
      isDragging = false,
      isNameEditable = false,
      isOverlay = false,
      section,
      setIsNameEditable,
      setSections,
      space,
      tableBodyProps = {},
    },
    ref,
  ) => {
    const [lessonsNameEditor, dispatchLessonsNameEditor] = useBooleanList();
    const [hasDeleteConfirmation, setHasDeleteConfirmation] = useState(false);
    const childItems = section.lessons.map((lesson: any) => lesson.sortId);
    const modal = useMobileCourseRenameModal();
    const isSmScreen = useSmScreenMediaQuery();

    return (
      <TableBody
        className={classNames(
          "bg-primary cursor-auto transition-all duration-200 ease-in-out",
          {
            "opacity-50": isDragging,
            "overflow-hidden rounded-md shadow-lg": isOverlay,
          },
        )}
        ref={ref}
        {...tableBodyProps}
      >
        <TableRow
          id={`section-item-${section.id}`}
          className="bg-secondary group"
          dataTestId="course-section-row"
        >
          <TableCell className="px-1 md:px-5">
            <div className="flex items-center gap-1">
              <div
                className="hover:bg-primary flex h-6 w-6 cursor-move items-center justify-center rounded"
                ref={dragHandleProps?.setActivatorNodeRef}
                {...(dragHandleProps?.listeners || {})}
              >
                <Icon type="16-reorder" size={16} />
              </div>
              {isNameEditable && !isOverlay ? (
                <SectionNameEditor
                  section={section}
                  onClose={() => setIsNameEditable?.(false)}
                />
              ) : (
                <button
                  type="button"
                  className="hover:border-dark truncate border-b border-dashed border-transparent"
                  onClick={() => setIsNameEditable?.(true)}
                >
                  <Typography.LabelSm weight="semibold">
                    {section.name}
                  </Typography.LabelSm>
                </button>
              )}
            </div>
          </TableCell>
          {isDrippedCourseSpace(space) && (
            <>
              <TableCell className="pr-5">
                {!isOverlay && (
                  <DripScheduleForm section={section} space={space} />
                )}
              </TableCell>
              <TableCell className="pr-5">
                {!isOverlay &&
                  ((isScheduledCourseSpace(space) && section.dripped_at) ||
                    (isStructuredCourseSpace(space) &&
                      section.drip_delay > 0)) && (
                    <NotifyStudentsToggle section={section} space={space} />
                  )}
              </TableCell>
            </>
          )}
          <TableCell className="pr-6 text-right">
            {!isOverlay && (
              <div className="flex items-center justify-end gap-x-4">
                <AddLessonDropdown
                  space={space}
                  section={section}
                  setIsNameEditable={(id: any) =>
                    dispatchLessonsNameEditor({ id, value: true })
                  }
                />
                <Dropdown
                  button={
                    <div className="hover:bg-tertiary cursor-pointer rounded p-1 group-hover:visible sm:invisible">
                      <Icon
                        type="menu-dots"
                        className="text-light"
                        useWithFillCurrentColor
                      />
                    </div>
                  }
                  dataTestId="section-dropdown-menu"
                >
                  <Dropdown.ItemWithLink
                    onClick={() =>
                      // Delay to avoid onBlur/onFocus events firing together
                      isSmScreen
                        ? modal.show({
                            type: "section",
                            sectionId: section.id,
                            courseId: space.id,
                            defaultName: section.name,
                          })
                        : setTimeout(() => setIsNameEditable?.(true), 100)
                    }
                  >
                    {I18n.t("courses.edit.sections.rename_section")}
                  </Dropdown.ItemWithLink>
                  <Dropdown.ItemWithLink
                    onClick={() => setHasDeleteConfirmation(true)}
                  >
                    {I18n.t("courses.edit.sections.delete_section")}
                  </Dropdown.ItemWithLink>
                </Dropdown>
              </div>
            )}
          </TableCell>
          <ConfirmSectionDelete
            show={hasDeleteConfirmation}
            onClose={() => setHasDeleteConfirmation(false)}
            courseId={space.id}
            sectionId={section.id}
            name={section.name}
          />
        </TableRow>
        <SortableContext
          items={childItems}
          strategy={verticalListSortingStrategy}
        >
          {section.lessons.map((lesson: any) => (
            <DraggableLessonItem
              key={lesson.id}
              lesson={lesson}
              section={section}
              space={space}
              setSections={setSections}
              isNameEditable={lessonsNameEditor.get(lesson.id)}
              setIsNameEditable={(value: any) =>
                dispatchLessonsNameEditor({
                  id: lesson.id,
                  value,
                })
              }
            />
          ))}
        </SortableContext>
      </TableBody>
    );
  },
);
SectionItem.displayName = "SectionItem";
