import { paymentProcessorValidations } from "@circle-react/helpers/paymentProcessorHelpers";
import { PaymentProcessorStatus } from "../constants";

export const usePaymentProcessorStatus = ({ paymentProcessor }) => {
  let paymentProcessorStatus = undefined;

  paymentProcessorValidations(paymentProcessor).some(validate => {
    paymentProcessorStatus ||= validate();
  });

  return {
    paymentProcessorStatus:
      paymentProcessorStatus ?? PaymentProcessorStatus.ENABLED,
  };
};
