import PropTypes from "prop-types";
import classNames from "classnames";
import { t } from "i18n-js";
import { SortableItem } from "@circle-react-shared/SortableItem";
import {
  useListItemDataProvider,
  useSortableListData,
} from "@circle-react-uikit/SortableList";
import { useFormBlocks } from "../Providers";

export const BlockListItem = ({ icon, label, noEdit = false }) => {
  const { useDragHandle } = useSortableListData();
  const { item: block, listeners } = useListItemDataProvider();
  const { toggleBlockHidden, setBlockBeingEdited } = useFormBlocks();
  const { hidden } = block;

  const onEdit = () => setBlockBeingEdited(block);
  const onHideToggle = () => toggleBlockHidden(block.id);
  const actions = [];

  if (!noEdit) {
    actions.push({
      tooltip: t("courses.lockscreen.edit_block"),
      icon: "pencil",
      onClick: onEdit,
    });
  }

  actions.push({
    tooltip: hidden
      ? t("courses.lockscreen.show_block")
      : t("courses.lockscreen.hide_block"),
    icon: hidden ? "16-eye-closed" : "16-eye-open",
    onClick: onHideToggle,
  });

  return (
    <SortableItem
      item={{ icon, label }}
      actions={actions}
      dragEventListeners={listeners}
      useDragHandle={useDragHandle}
      className={classNames({
        "bg-secondary text-light": hidden,
      })}
    />
  );
};

BlockListItem.propTypes = {
  block: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    hidden: PropTypes.bool,
    data: PropTypes.object,
  }),
  icon: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  noEdit: PropTypes.bool,
};
