export const ordinalize = (number: number) => {
  if (number === 0) return number; // No ordinal for zero
  if (number >= 11 && number <= 13) return number + "th";

  switch (number % 10) {
    case 1:
      return number + "st";
    case 2:
      return number + "nd";
    case 3:
      return number + "rd";
    default:
      return number + "th";
  }
};
