import { useEffect } from "react";
import { t } from "i18n-js";
import { useFormContext } from "react-hook-form";
import { FormTextSelect } from "@circle-react-shared/uikit/Form/FormTextSelect";
import { Form } from "@circle-react-uikit/Form";
import { usePricingTabContext } from "../../hooks/usePricingTabContext";

const localeRoot = "settings.paywalls.modal.tabs.pricing.add_price_modal";

export const TrialDaysField = () => {
  const { watch, setValue } = useFormContext();
  const { priceBuildOptions } = usePricingTabContext();

  const isTrialEnabled = watch("enable_trial");
  const trialType = watch("trial_type");

  useEffect(() => {
    if (!isTrialEnabled) {
      setValue("trial_type", null);
      setValue("trial_days", 0);
    }
  }, [isTrialEnabled, trialType]);

  const options = Object.keys(priceBuildOptions.subscription.trial_options).map(
    trialOption => ({
      label: t([localeRoot, "trial_options", trialOption]),
      value: trialOption,
    }),
  );

  return (
    <>
      <Form.Item
        className="!py-0"
        labelClassName="!font-normal"
        name="enable_trial"
        translationRoot="settings.paywalls.modal.tabs.pricing"
        tooltipText={t(
          "settings.paywalls.modal.tabs.pricing.enable_trial_tooltip",
        )}
        fullWidth
        hideDescription
        hidePlaceholder
        hideBorder
        inlineReverse
        variant="checkout-path"
      >
        <Form.ToggleSwitch name="enable_trial" variant="small" value="false" />
      </Form.Item>
      {isTrialEnabled && (
        <FormTextSelect
          name="trial_type"
          rules={{ required: true }}
          label={
            <>
              {t([localeRoot, "field_labels.trial_options"])}
              <span className="text-red-500">*</span>
            </>
          }
          options={options}
        />
      )}
    </>
  );
};
