import { Suspense } from "react";
import { StandardLayoutV2 } from "@circle-react/components/Layout/StandardLayoutV2";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { PageLoader } from "@circle-react/pages/PageLoader";

const GettingStartedComponent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "GettingStartedPage" */ "@circle-react/components/OnboardingPage/CommunityOnboardingPage"
    ),
);

export const GettingStartedPage = () => (
  <StandardLayoutV2 disableRightSidebar>
    <Suspense fallback={<PageLoader />}>
      <GettingStartedComponent />
    </Suspense>
  </StandardLayoutV2>
);
