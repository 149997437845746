import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { updateOrRemoveQueryParam } from "@circle-react/helpers/urlHelpers";
import { useAdminPaywallCharge } from "@circle-react/hooks/paywalls/useAdminPaywallCharge";
import { useRouterQueryParams } from "@circle-react/hooks/useRouterQueryParams";
import {
  allFilterTranslations,
  normalizeFilters,
} from "../../Filters/useFilters";
import { getPaginationLimitThreshold } from "../../utils";
import { TABS, statusByTab } from "../constants";

const defaultOrder = { created_at: "desc" };

export const usePaywallChargesAdmin = () => {
  const history = useHistory();
  const queryParams = useRouterQueryParams();

  const {
    tab = TABS.ALL,
    search_key = "",
    page: pageParam = 1,
    status,
  } = queryParams;

  const [page, setPage] = useState(getPaginationLimitThreshold(pageParam));
  const [activeFilterTab, setActiveFilterTab] = useState(
    Object.values(TABS).includes(tab) ? tab : TABS.ALL,
  );
  const [searchKey, setSearchKey] = useState(search_key);
  const getSearchKey = (currentTab, tabTarget, searchKey) =>
    currentTab === tabTarget ? searchKey : "";

  const updateQueryParam = (keyQueryParam, value) => {
    const newQueryParams = updateOrRemoveQueryParam(keyQueryParam, value);
    history.push(`?${newQueryParams}`);
  };

  const updatePage = value => {
    updateQueryParam("page", String(value));
    setPage(value);
  };

  useEffect(() => {
    updateQueryParam("tab", activeFilterTab);
    if (!status) {
      updateQueryParam("status", statusByTab[activeFilterTab]);
    }
  }, []);

  const setActiveFilter = tab => {
    setActiveFilterTab(tab);
    updateQueryParam("tab", tab);
    updateQueryParam("status", statusByTab[tab]);
    setSearchKey("");
  };

  const allChargesData = useAdminPaywallCharge({
    searchKeyParams: getSearchKey(activeFilterTab, TABS.ALL, searchKey),
    orderParam: defaultOrder,
    filters: normalizeFilters(
      {
        ...queryParams,
      },
      allFilterTranslations.charges,
    ),
    page,
    setPage: updatePage,
    enabled: activeFilterTab === TABS.ALL,
  });
  const paidChargesData = useAdminPaywallCharge({
    searchKeyParams: getSearchKey(activeFilterTab, TABS.PAID, searchKey),
    orderParam: defaultOrder,
    filters: normalizeFilters(
      {
        ...queryParams,
      },
      allFilterTranslations.charges,
    ),
    page,
    setPage: updatePage,
    enabled: activeFilterTab === TABS.PAID,
  });
  const refundedChargesData = useAdminPaywallCharge({
    searchKeyParams: getSearchKey(activeFilterTab, TABS.REFUNDED, searchKey),
    orderParam: defaultOrder,
    filters: normalizeFilters(
      {
        display_status: statusByTab[TABS.REFUNDED],
        ...queryParams,
      },
      allFilterTranslations.charges,
    ),
    page,
    setPage: updatePage,
    enabled: activeFilterTab === TABS.REFUNDED,
  });
  const failedChargesData = useAdminPaywallCharge({
    searchKeyParams: getSearchKey(activeFilterTab, TABS.FAILED, searchKey),
    orderParam: defaultOrder,
    filters: normalizeFilters(
      {
        display_status: statusByTab[TABS.FAILED],
        ...queryParams,
      },
      allFilterTranslations.charges,
    ),
    page,
    setPage: updatePage,
    enabled: activeFilterTab === TABS.FAILED,
  });

  const resetAllChargesToFirstPage = () => {
    updatePage(1);
    allChargesData.setOrder(defaultOrder);
    paidChargesData.setOrder(defaultOrder);
    refundedChargesData.setOrder(defaultOrder);
    failedChargesData.setOrder(defaultOrder);
  };
  const queryClient = useQueryClient();
  const refetchAllCharges = () => {
    queryClient.clear();
    allChargesData.refetchCharges();
    paidChargesData.refetchCharges();
    refundedChargesData.refetchCharges();
    failedChargesData.refetchCharges();
  };

  const chargesDataByTab = {
    [TABS.ALL]: allChargesData,
    [TABS.PAID]: paidChargesData,
    [TABS.REFUNDED]: refundedChargesData,
    [TABS.FAILED]: failedChargesData,
  };

  // We don't want to display stats when filters
  // other than the basic ones are applied.
  const showStats = () => {
    const queryParamsAux = queryParams;
    delete queryParamsAux["status"];
    delete queryParamsAux["page"];
    delete queryParamsAux["tab"];

    return Object.keys(queryParamsAux).length <= 0;
  };

  return {
    chargesDataByTab,
    activeFilterTab,
    setActiveFilterTab: setActiveFilter,
    defaultValues: { searchKey },
    setSearchKey,
    refetchAllCharges,
    resetAllChargesToFirstPage,
    showStats,
    isLoadingCharges: allChargesData.isLoadingCharges,
  };
};
