import { useEffect } from "react";
import PropTypes from "prop-types";
import { t } from "i18n-js";
import { useFormContext } from "react-hook-form";
import { TippyV2 } from "@circle-react/components/shared/TippyV2";
import { useSpaceGroupsContext } from "@circle-react/contexts";
import { isChatSpace } from "@circle-react/helpers/spaceHelpers";
import { Form } from "@circle-react-uikit/Form";

export const AutoAddMembers = ({
  spaceGroupMemberCount,
  chatSpaceMemberLimit,
}) => {
  const {
    helpers: { findBy },
  } = useSpaceGroupsContext();
  const { watch, setValue } = useFormContext();

  const spaceGroupId = watch("space_group_id");
  const post_type = watch("post_type");
  const spaceGroup = findBy({ id: spaceGroupId });

  useEffect(() => {
    if (spaceGroup) {
      setValue(
        "add_members_from_space_group",
        spaceGroup.automatically_add_members_to_new_spaces,
      );
    }
  }, [spaceGroupId]);

  const isToggleDisabled =
    isChatSpace({ post_type }) && spaceGroupMemberCount > chatSpaceMemberLimit;

  return (
    <TippyV2
      content={t("members_in_space_group_limit_reached")}
      disabled={!isToggleDisabled}
    >
      <Form.Item
        name="add_members_from_space_group"
        label={I18n.t("spaces.form.add_members_from_space_this_group")}
        hideBorder
        hideDescription
        inline
        shouldToggleValueOnLabelClick
        isDisabled={isToggleDisabled}
      >
        <Form.ToggleSwitch variant="small" disabled={isToggleDisabled} />
      </Form.Item>
    </TippyV2>
  );
};

AutoAddMembers.propTypes = {
  spaceGroupMemberCount: PropTypes.number,
  chatSpaceMemberLimit: PropTypes.number,
};
