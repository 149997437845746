import { t } from "i18n-js";
import { RSVP_STATUS } from "@circle-react/components/Events/helpers/constants";
import { isPaidEvent } from "@circle-react/components/EventsV3/Modal/utils";
import { Typography } from "@circle-react/components/shared/uikit/Typography";
import type { Event } from "@circle-react/types/Event";
import { Icon } from "@circle-react-shared/Icon";

interface Props {
  event: Event;
}

export const RsvpLimitInfo = ({ event }: Props) => {
  const {
    event_setting_attributes: eventData,
    rsvped_event: isRsvpedEvent,
    rsvp_status: rsvpStatus,
  } = event;

  const {
    rsvp_limit_reached: isRsvpLimitReached,
    show_rsvp_limit_warning: shouldShowRsvpWarning,
  } = eventData || {};
  const iconName = shouldShowRsvpWarning
    ? "20-event-clock"
    : "20-event-members";
  const isAttendeeInvited = rsvpStatus == RSVP_STATUS.INVITED;

  if (
    isRsvpedEvent ||
    isAttendeeInvited ||
    (isRsvpLimitReached && isPaidEvent(event)) ||
    (!shouldShowRsvpWarning && !isRsvpLimitReached)
  ) {
    return null;
  }

  return (
    <div className="mb-6 flex flex-row items-center space-x-4">
      <div className="bg-tertiary flex h-10 w-10 flex-none items-center justify-center rounded-lg">
        <Icon type={iconName} size={20} className="text-dark !h-5 !w-5" />
      </div>
      <div className="flex items-center justify-center space-x-1 align-middle">
        {shouldShowRsvpWarning && (
          <Typography.LabelXs weight="medium">
            <div className="text-left" style={{ color: "#F5A607" }}>
              {t("events.rsvp.rsvp_limit_warning")}
            </div>
          </Typography.LabelXs>
        )}
        {isRsvpLimitReached && (
          <Typography.LabelXs weight="medium">
            <div className="text-left">
              {t("events.rsvp.rsvp_limit_reached")}
            </div>
          </Typography.LabelXs>
        )}
      </div>
    </div>
  );
};
