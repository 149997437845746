interface UseStripeElementOptions {
  filteredPaymentMethodsSupported: string[];
  isPaywallDigitalWalletEnabled: boolean;
  paywall: any;
  price: any;
}
export const useStripeElementOptions = ({
  filteredPaymentMethodsSupported,
  isPaywallDigitalWalletEnabled,
  paywall,
  price,
}: UseStripeElementOptions) => {
  if (!isPaywallDigitalWalletEnabled) {
    return {};
  }

  return {
    payment_method_types: filteredPaymentMethodsSupported,
    mode: "subscription",
    setup_future_usage: "off_session",
    currency: paywall.currency_code,
    amount: price.amount,
  };
};
