import { useState } from "react";
import debounce from "lodash/debounce";
import { usePosts } from "../../Posts/hooks/usePosts";
import { useFetchPost } from "./useFetchPost";

const postOptionFormatter = post => ({
  label: post.display_title,
  value: post.id,
  description: `${post.space_name} • ${post.community_member.name}`,
});

export const usePostSelector = ({ selectedPostId }) => {
  const [postTitle, setPostTitle] = useState("");
  const { posts, isLoadingPosts } = usePosts({ title: postTitle });
  const { post, isLoadingPost, isPostLoaded } = useFetchPost(selectedPostId);
  const postOptions = posts.map(postOptionFormatter);

  if (post && !isLoadingPost && isPostLoaded) {
    postOptions.push(postOptionFormatter(post));
  }

  const handlePostTitleChange = debounce(title => setPostTitle(title), 500);

  return {
    isLoading: isLoadingPosts || isLoadingPost,
    postOptions,
    handlePostTitleChange,
  };
};
