import { t } from "i18n-js";
import { isDraftSpace } from "@/react/helpers/spaceHelpers";
import { ProfileHoverCard } from "@circle-react/components/Profile/HoverCard";
import { useCurrentSpaceContext } from "@circle-react/contexts";
import { isMember } from "@circle-react/helpers/communityMemberHelpers";
import { UserImage } from "@circle-react-shared/uikit/UserImage";
import { BadgeV2 } from "@circle-react-uikit/BadgeV2";
import { TableCell } from "@circle-react-uikit/Table";
import type { CellVariant } from "@circle-react-uikit/Table/TableCell";
import { Typography } from "@circle-react-uikit/Typography";
import type { Member } from "../types";

interface MemberColumProps {
  cellVariant: CellVariant;
  member: Member;
}

export const MemberColumn = ({
  member: { id, name, email, avatarUrl, role, publicUid },
  cellVariant,
}: MemberColumProps) => {
  const spaceData = useCurrentSpaceContext();

  return (
    <TableCell variant={cellVariant} className="w-80">
      <div className="inline-flex items-center">
        <ProfileHoverCard
          communityMemberId={id}
          name={name}
          publicUid={publicUid}
          avatarURL={avatarUrl}
        >
          <UserImage name={name} src={avatarUrl} size="10" />
        </ProfileHoverCard>
        <div className="w-60 pl-3">
          <div className="flex gap-4">
            <Typography.LabelSm
              truncate
              weight="semibold"
              color="text-selector-active"
            >
              {name}
            </Typography.LabelSm>
            {isDraftSpace(spaceData?.data) && isMember(role) && (
              <BadgeV2 label={t("spaces.form.edit.members.waitlist")} square />
            )}
          </div>
          <div className="w-60 truncate">
            <Typography.LabelSm color="text-default">
              {email}
            </Typography.LabelSm>
          </div>
        </div>
      </div>
    </TableCell>
  );
};
