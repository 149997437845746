import { useCallback, useEffect, useState } from "react";
import { useSpaceMember } from "@/react/hooks/useSpaceMember";
import { useUpdateSpaceMember } from "@/react/hooks/useUpdateSpaceMember";
import { usePunditUserContext } from "@circle-react/contexts";

const NOTIFICATION_OPTIONS = {
  NEVER: "never",
  ALL: "all",
};

export const useSpaceHeaderTitleDropdown = ({ space }) => {
  const { mutate } = useUpdateSpaceMember();
  const [notificationState, setNotificationState] = useState(null);
  const { currentCommunityMember } = usePunditUserContext();

  const { data: spaceMember, isLoading: isLoadingSpaceMember } = useSpaceMember(
    {
      spaceId: space.id,
      memberId: currentCommunityMember?.id,
    },
  );

  const isNotificationTypeEnabled = useCallback(
    value => value === NOTIFICATION_OPTIONS.ALL,
    [],
  );

  useEffect(() => {
    if (spaceMember) {
      setNotificationState({
        notification_type: isNotificationTypeEnabled(
          spaceMember?.notification_type,
        ),
        in_app_notification_setting: isNotificationTypeEnabled(
          spaceMember?.in_app_notification_setting,
        ),
        mobile_notification_setting: isNotificationTypeEnabled(
          spaceMember?.mobile_notification_setting,
        ),
        all: spaceMember?.all_notifications_enabled,
        mentions: spaceMember?.mention_notifications_enabled,
        never: spaceMember?.notifications_off,
      });
    }
  }, [spaceMember, isLoadingSpaceMember, isNotificationTypeEnabled]);

  const updateSpaceMemberNotificationPreferences = useCallback(
    ({ fieldKey, shouldEnable }) =>
      mutate({
        spaceMember,
        // "all" is the default to turn all notifications on
        updates: {
          [fieldKey]: shouldEnable
            ? NOTIFICATION_OPTIONS.ALL
            : NOTIFICATION_OPTIONS.NEVER,
        },
      }),
    [spaceMember, isLoadingSpaceMember],
  );

  const updateChatSpaceMemberNotificationPreferences = useCallback(
    ({ fieldKey }) =>
      mutate({
        spaceMember,
        updates: chatSpaceUpdatesParams(fieldKey),
      }),
    [spaceMember, mutate],
  );

  const chatSpaceUpdatesParams = fieldKey => {
    if (fieldKey == "all") {
      return {
        mobile_notification_setting: NOTIFICATION_OPTIONS.ALL,
        mention_mobile_notification_setting: NOTIFICATION_OPTIONS.ALL,
        mention_in_app_notification_setting: NOTIFICATION_OPTIONS.ALL,
      };
    }
    if (fieldKey == "mentions") {
      return {
        mobile_notification_setting: NOTIFICATION_OPTIONS.NEVER,
        mention_mobile_notification_setting: NOTIFICATION_OPTIONS.ALL,
        mention_in_app_notification_setting: NOTIFICATION_OPTIONS.ALL,
      };
    }
    if (fieldKey == "never") {
      return {
        mobile_notification_setting: NOTIFICATION_OPTIONS.NEVER,
        mention_mobile_notification_setting: NOTIFICATION_OPTIONS.NEVER,
        mention_in_app_notification_setting: NOTIFICATION_OPTIONS.NEVER,
      };
    }
  };
  return {
    updateSpaceMemberNotificationPreferences,
    updateChatSpaceMemberNotificationPreferences,
    isNotificationTypeEnabled,
    notificationState,
    isLoading: isLoadingSpaceMember,
  };
};
