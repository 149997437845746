import { forwardRef } from "react";
import type {
  TrixEditorProps,
  TrixEditorRefObject,
} from "../shared/TrixEditor";
import { TrixEditor } from "../shared/TrixEditor";

const RichTextInput = forwardRef<TrixEditorRefObject, TrixEditorProps>(
  (props, ref) => (
    <div>
      <TrixEditor {...props} ref={ref} />
    </div>
  ),
);
RichTextInput.displayName = "RichTextInput";
export default RichTextInput;
