import { PropTypes } from "prop-types";
import { formattedDateTime } from "@circle-react/helpers/dateTimeHelpers/formattedDateTime";
import { Typography } from "@circle-react-uikit/Typography";
import { Avatar } from "../Avatar";
import { availableEvents } from "../Events";
import { Result } from "../Table/Result";

export const HistoryTrigger = ({ log, text }) => {
  const { action_to_s = "", created_at: createdAt = "", message = {} } = log;
  const date = formattedDateTime({
    dateTime: new Date(createdAt),
    format: "short_date_at_short_time",
  });

  const getEventName = () => {
    const selectedEvent = availableEvents.find(
      ({ value }) => value === message?.trigger_type,
    );
    return selectedEvent?.label ?? action_to_s;
  };

  return (
    <div className="border-dark rounded-lg border">
      <div className="border-dark bg-secondary flex flex-col gap-3.5 rounded-t-lg border-b px-6 py-5">
        <div className="flex flex-col gap-1">
          <div>
            <Typography.LabelMd weight="semibold">
              {getEventName()}
            </Typography.LabelMd>
          </div>
          <div className="flex flex-col gap-2 md:flex-row">
            <Result result="success" isBadge={false} />
            <Typography.LabelSm
              color="text-default"
              as="time"
              dateTime={createdAt}
            >
              {date}
            </Typography.LabelSm>
          </div>
        </div>
      </div>
      <div className="px-6 py-5">
        <div className="md:flex">
          <div className="w-1/4">
            <Typography.LabelSm weight="semibold">{text}</Typography.LabelSm>
          </div>
          <div className="w-3/4">
            <Avatar communityMemberId={message?.initiator_id} />
          </div>
        </div>
      </div>
    </div>
  );
};

HistoryTrigger.propTypes = {
  log: PropTypes.shape({
    action_to_s: PropTypes.string,
    created_at: PropTypes.string,
    message: PropTypes.shape({
      initiator_id: PropTypes.number,
      trigger_type: PropTypes.string,
    }),
  }),
  text: PropTypes.string.isRequired,
};
