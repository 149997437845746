import { useFormContext } from "react-hook-form";
import { useMutation } from "react-query";
import { workflowsApi } from "@/react/api";

export const upsertAction = (workflowId, action) => {
  const { id, action_type, api_params, position = 1 } = action;

  if (!id || id.startsWith("action")) {
    return workflowsApi.createAction({
      workflowId,
      body: {
        action_type,
        api_params,
        position,
      },
    });
  }

  return workflowsApi.updateAction({
    workflowId,
    id,
    body: {
      action_type,
      api_params,
      position,
    },
  });
};

export const useUpsertAction = (options = {}) => {
  const { watch } = useFormContext();
  const workflowId = watch("id");
  return useMutation(action => upsertAction(workflowId, action), options);
};
