import classNames from "classnames";
import { Typography } from "@circle-react-uikit/Typography";

export interface PaywallPriceOptionsProps {
  id: number | string;
  title: string;
  subtitle?: string;
  value: string;
  isSelected: boolean;
  onSelect: (id: number | string) => void;
}
export const PaywallPriceOption = ({
  id,
  title,
  subtitle,
  value,
  isSelected,
  onSelect,
}: PaywallPriceOptionsProps) => (
  <button
    type="button"
    aria-pressed={isSelected}
    className={classNames(
      "border-primary bg-primary focus-visible:!outline-secondary flex min-h-[4rem] cursor-pointer flex-row items-center justify-between rounded-md border px-5 py-3 focus-visible:!outline focus-visible:!outline-2 focus-visible:!outline-offset-2",
      {
        "border-secondary bg-secondary": isSelected,
      },
    )}
    data-testid="checkout-paywall-price-option"
    onClick={() => onSelect(id)}
  >
    <div className="flex flex-col justify-around gap-y-1 py-1 text-left">
      <Typography.LabelMd weight="medium">{title}</Typography.LabelMd>
      {subtitle && (
        <Typography.LabelXs weight="regular">{subtitle}</Typography.LabelXs>
      )}
    </div>
    <div className="flex flex-col justify-center whitespace-nowrap">
      <Typography.LabelMd weight="regular">{value}</Typography.LabelMd>
    </div>
  </button>
);
