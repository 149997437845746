import { Suspense } from "react";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { PageLoader } from "@circle-react/pages/PageLoader";

const LiveRecordComponent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "LiveRecordPage" */ "@circle-react/components/LiveStreams/Room/RoomRecorder"
    ),
);

export const LiveRecordPage = () => (
  <Suspense fallback={<PageLoader />}>
    <LiveRecordComponent />
  </Suspense>
);
