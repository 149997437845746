import { t } from "i18n-js";
import { Link } from "react-router-dom";
import { supportWidgetPaths } from "@circle-react/helpers/urlHelpers";
import { Typography } from "@circle-react-uikit/Typography";
import { Button } from "../../Button";
import { ConversationItem } from "../../ConversationItem";
import { DraftMessageButton } from "../../DraftMessageButton";
import { SkeletonLoader } from "../../SkeletonLoader";
import { useListConversations } from "../../queries";

export const ConversationsListView = () => {
  const { isLoading, data } = useListConversations();

  if (isLoading) {
    return (
      <>
        <SkeletonLoader rows={1} height="52px" wrapperClassName="!px-0" />
        {Array(4)
          .fill(null)
          .map((_, index) => (
            <div className="my-2" key={index}>
              <SkeletonLoader
                rows={1}
                height="20px"
                width="80%"
                loaderClassName="mb-2"
              />
              <SkeletonLoader
                rows={1}
                height="13px"
                width="25%"
                wrapperClassName="last:pb-0"
              />
            </div>
          ))}
      </>
    );
  }

  const conversations = data?.conversations || [];

  return (
    <div className="flex flex-col">
      <div className="border-primary bg-primary sticky top-0 z-10 flex h-[52px] shrink-0 items-center justify-center border-b px-3">
        <Typography.LabelMd weight="semibold">
          {t("support_widget.messages_screen.title")}
        </Typography.LabelMd>
      </div>
      <DraftMessageButton />
      {conversations.length === 0 ? (
        <div className="flex flex-col justify-center px-6 py-8">
          <div className="mx-auto mb-5 mt-auto w-full">
            <div className="border-primary bg-primary rounded-lg border">
              <div className="mt-4 text-center">
                <Typography.LabelMd
                  weight="semibold"
                  color="dark:text-secondary"
                >
                  {t("support_widget.no_messages_found")}
                </Typography.LabelMd>
              </div>
              <div className="mx-6 my-5">
                <Link to={supportWidgetPaths.askChatBot()}>
                  <Button
                    label={t("support_widget.need_help")}
                    className="dark:bg-primary dark:text-white"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      ) : (
        conversations.map(conversation => (
          <ConversationItem key={conversation.id} conversation={conversation} />
        ))
      )}
    </div>
  );
};
