import { useHistory, useRouteMatch } from "react-router-dom";
import { Button } from "@circle-react-shared/uikit/Button";
import { useSettingsHeader } from "@circle-react-shared/uikit/HeaderV3/useSettingsHeader";
import { HelloBar } from "@circle-react-shared/uikit/HelloBar";
import { SanitizeHTMLString } from "@circle-react-shared/uikit/SanitizeContent";
import { OPT_IN_BAR_DISABLED_ROUTES } from "./constants";

export interface OptInBarProps {
  optInText: string;
  optInCTAContent: string;
  isAdmin?: boolean;
  shouldDisplayV3OptInBar?: boolean;
}

export const OptInBarComponent = ({
  optInText,
  optInCTAContent,
  isAdmin = false,
  shouldDisplayV3OptInBar = false,
}: OptInBarProps) => {
  const { isSettingsRoute } = useSettingsHeader();
  const isOptInBarDisabledOnRoute = useRouteMatch(OPT_IN_BAR_DISABLED_ROUTES);
  const history = useHistory();

  const shouldNotDisplayOptInBar =
    !shouldDisplayV3OptInBar ||
    !isAdmin ||
    isOptInBarDisabledOnRoute ||
    isSettingsRoute;

  if (shouldNotDisplayOptInBar) return null;

  return (
    <HelloBar>
      <div className="flex items-center space-x-6">
        <SanitizeHTMLString content={optInText} />
        <Button
          variant="secondary"
          isFullRounded
          onClick={() => {
            history.push("/features/visual-refresh");
          }}
        >
          {optInCTAContent}
        </Button>
      </div>
    </HelloBar>
  );
};
