import { t } from "i18n-js";
import { useMutation } from "react-query";
import { liveStreamApi } from "@circle-react/api/liveStreamApi";
import { useToast } from "@circle-react-uikit/ToastV2";

export const useDeleteRecording = (room, refetch) => {
  const { error, success } = useToast();
  const deleteRecordingMutation = useMutation(
    () =>
      liveStreamApi.deleteRoomRecording({
        roomId: room?.id,
        id: room?.recording_id,
      }),
    {
      onSuccess: () => {
        success(t("live_streams.delete_recording.success"), {
          duration: "short",
          shouldUseProgress: false,
        });
        refetch();
      },
      onError: () => {
        error(t("live_streams.delete_recording.error"));
      },
    },
  );

  return {
    deleteRecording: deleteRecordingMutation.mutate,
  };
};
