import { useGamificationSettings } from "@circle-react/components/SettingsApp/Gamification/LevelsForm/gamificationApi";
import { LevelItem } from "./LevelItem";
import { LoadingState } from "./LoadingState";

export const LevelsList = ({
  isLoadingGamificationMember,
  currentLevel,
}: {
  isLoadingGamificationMember: boolean;
  currentLevel?: number;
}) => {
  const { data: gamificationSettings, isLoading } = useGamificationSettings();
  const shouldShowSkeletonLoader =
    isLoading ||
    !gamificationSettings ||
    isLoadingGamificationMember ||
    !currentLevel;

  return (
    <div className="mt-6 grid grid-flow-col-dense grid-rows-5 gap-5 md:grid-rows-3">
      {shouldShowSkeletonLoader ? (
        <LoadingState />
      ) : (
        gamificationSettings.levels?.map(levelData => (
          <LevelItem
            key={levelData.level}
            isLocked={levelData.level > currentLevel}
            level={levelData.level}
            levelName={levelData.name}
            pointsRequired={levelData.points_required}
          />
        ))
      )}
    </div>
  );
};
