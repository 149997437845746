import { t } from "i18n-js";
import { noop } from "lodash";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { NewPostWrapper } from "@circle-react/components/QuickPostV2/NewPostWrapper";
import { useQuickPostModalStore } from "@circle-react/components/QuickPostV2/store";
import { useCloseOnEscape } from "@circle-react/components/QuickPostV2/useCloseOnEscape";
import { usePreventAccidentalModalClose } from "@circle-react/components/QuickPostV2/usePreventAccidentalModalClose";
import { Modal } from "@circle-react/components/shared/uikit/ModalV2";
import { PostsDetailsContextProvider } from "@circle-react/contexts/postsDetailsContext";
import { SpacesContextProvider } from "@circle-react/contexts/spacesContext";
import { useEmbedModal } from "@circle-react-shared/uikit/TipTapBlockEditor/EmbedExtension/EmbedModal";
import { useFileUploadModal } from "@circle-react-shared/uikit/TipTapBlockEditor/FileExtension/FileUploadModal";
import { useImagePickerModal } from "@circle-react-shared/uikit/TipTapBlockEditor/ImageExtension/ImageModal";
import "./trix-compatibility.scss";

interface QuickPostModalProps {
  spaceId?: string | number;
  shouldSkipRedirectAfterSubmit?: boolean;
  onSubmitSuccess?: () => void;
  bodyForEditor?: string;
  tiptapBodyForEditor?: object;
}

export const QuickPostModalComponent = ({
  spaceId,
  shouldSkipRedirectAfterSubmit,
  onSubmitSuccess,
  bodyForEditor,
  tiptapBodyForEditor,
}: QuickPostModalProps) => {
  const modal = useModal();
  const { isDirty } = useQuickPostModalStore();
  const conditionalClose = usePreventAccidentalModalClose({
    shouldPrevent: isDirty,
  });
  const filePickerModal = useFileUploadModal();
  const imageUploadModal = useImagePickerModal();
  const embedModal = useEmbedModal();

  const shouldBlockEscape =
    filePickerModal.visible || imageUploadModal.visible || embedModal.visible;

  useCloseOnEscape({
    disabled: shouldBlockEscape,
    onClose: conditionalClose,
  });

  return (
    <Modal
      isOpen={modal.visible}
      onClose={noop}
      className="quick-post-v2-modal w-full max-w-3xl"
      aria-label={t("quick_post_v2.title")}
    >
      <Modal.Overlay onClick={conditionalClose} />
      <SpacesContextProvider>
        <PostsDetailsContextProvider>
          <NewPostWrapper
            spaceId={spaceId}
            bodyForEditor={bodyForEditor}
            tiptapBodyForEditor={tiptapBodyForEditor}
            shouldSkipRedirectAfterSubmit={shouldSkipRedirectAfterSubmit}
            onSubmitSuccess={onSubmitSuccess}
          />
        </PostsDetailsContextProvider>
      </SpacesContextProvider>
    </Modal>
  );
};

const QuickPostModal = ModalManager.create((props: QuickPostModalProps) => (
  <QuickPostModalComponent {...props} />
));

export const useQuickPostV2Modal = () => useModal(QuickPostModal);
