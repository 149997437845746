import PropTypes from "prop-types";
import classNames from "classnames";
import { Tippy } from "@circle-react/components/shared/Tippy";

const sizes = {
  md: "w-8 h-8",
  lg: "min-w-[2.5rem] h-10",
};

export const Action = ({
  tooltipContent,
  size = "lg",
  className,
  children,
}) => {
  const Content = () => (
    <div
      className={classNames(
        "border-dark text-default bg-primary flex items-center justify-center rounded-full border",
        sizes[size],
        className,
        {
          "cursor-pointer": tooltipContent,
        },
      )}
    >
      {children}
    </div>
  );

  if (tooltipContent) {
    return (
      <Tippy placement="top" content={tooltipContent}>
        <Content />
      </Tippy>
    );
  }

  return <Content />;
};

Action.propTypes = {
  tooltipContent: PropTypes.string,
  size: PropTypes.oneOf(Object.keys(sizes)),
  className: PropTypes.string,
  children: PropTypes.node,
};
