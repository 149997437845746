import { useFormContext } from "react-hook-form";
import { paywallPriceTypes } from "@circle-react/types";
import { PricingTabAddPriceModalFieldsInstallments } from "./Installments";
import { PricingTabAddPriceModalFieldsOneTime } from "./OneTime";
import { PricingTabAddPriceModalFieldsSubscription } from "./Subscription";

const componentsByType: any = {
  [paywallPriceTypes.onetime]: PricingTabAddPriceModalFieldsOneTime,
  [paywallPriceTypes.subscription]: PricingTabAddPriceModalFieldsSubscription,
  [paywallPriceTypes.installments]: PricingTabAddPriceModalFieldsInstallments,
};

export const PricingTabAddPriceModalFields = () => {
  const { watch } = useFormContext();
  const priceType = watch("price_type");

  const Component = componentsByType[priceType];
  if (!Component) {
    return null;
  }
  return <Component />;
};
