import { useModal } from "@ebay/nice-modal-react";
import { t } from "i18n-js";
import { IconButton } from "@circle-react/components/shared/uikit/HeaderV3/IconButton";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { Typography } from "@circle-react-uikit/Typography";

export const Header = ({ title }: { title: string }) => {
  const modal = useModal();
  return (
    <header className="border-primary flex items-center justify-between border-b px-5 py-4">
      <Typography.TitleSm weight="semibold">{title}</Typography.TitleSm>
      <ul className="text-darkest flex list-none items-center space-x-2">
        <li>
          <TippyV2 placement="bottom" content={t("quick_post_v2.header.close")}>
            <IconButton
              variant="secondary"
              iconSize={20}
              onClick={modal.remove}
              iconClassName="!text-dark"
              name="20-close"
              ariaLabel={t("quick_post_v2.header.close")}
            />
          </TippyV2>
        </li>
      </ul>
    </header>
  );
};
