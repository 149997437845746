import { toQueryString } from "./urlHelpers";

export const communitiesPaths = {
  onboarding: () => "/getting-started",
  templates: (communityId: string) => `/communities/${communityId}/templates`,
  questions: (communityId: string) => `/communities/${communityId}/questions`,
  feed: () => "/feed",
};

export const memberDirectoryPath = () => "/members";
export const coursesDirectoryPath = () => "/courses";
export const spacesDirectoryPath = () => "/spaces";
export const eventsDirectoryPath = () => "/events";

export type Params = Record<string, any>;

export const onboardingItemPathQueryParam = (param: Params) =>
  `${communitiesPaths.onboarding()}?${toQueryString(param)}`;
