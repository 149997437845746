import type { ReactNode } from "react";
import classnames from "classnames";
import { BlockInViewOnlyMode } from "@circle-react/components/ViewOnlyMasquerading/BlockInViewOnlyMode";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { useSidebarBorderStyles } from "../hooks";

export interface TopSectionContainerProps {
  hasBorder?: boolean;
  hasPadding?: boolean;
  isSticky?: boolean;
  children: ReactNode;
  className?: string;
}

export const TopSectionContainer = ({
  hasBorder = false,
  hasPadding = true,
  isSticky = true,
  children,
  className,
}: TopSectionContainerProps) => {
  const styles = useSidebarBorderStyles();
  const { isV3Enabled } = useIsV3();

  return (
    <BlockInViewOnlyMode>
      <div
        className={classnames(
          className,
          "top-0 z-30 flex h-16 min-h-[4rem] items-center justify-between gap-2",
          {
            "border-c-sidebar border-b": hasBorder && !isV3Enabled,
            "px-4": hasPadding,
            sticky: isSticky,
            "bg-c-sidebar": !isV3Enabled,
          },
        )}
        data-testid="top_section_container"
        style={styles}
      >
        {children}
      </div>
    </BlockInViewOnlyMode>
  );
};
