import { useMutation } from "react-query";
import { reactQueryPut } from "@/react/helpers/backendRequestHelpers";
import { internalApi } from "@/react/helpers/urlHelpers";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";

export const useUpdateSpaceGroupMemberModerator = options => {
  const mutation = async ({ spaceGroupId, memberId, isModerator }) => {
    const communityMemberSettings = await reactQueryGet(
      internalApi.communityMember.settings({ memberId }),
    );

    const moderatingSpaceGroupIds =
      communityMemberSettings.moderating_space_group_ids || [];

    const newModeratingSpaceGroupIds = isModerator
      ? [...moderatingSpaceGroupIds, spaceGroupId]
      : moderatingSpaceGroupIds.filter(id => id !== spaceGroupId);

    return reactQueryPut(internalApi.communityMember.update({ memberId }), {
      ...communityMemberSettings,
      moderator_space_group_ids: newModeratingSpaceGroupIds,
    });
  };

  return useMutation(mutation, options);
};
