import { useEffect } from "react";
import { useLiveUI } from "@circle-react/components/LiveStreams/Room/store";

document.documentElement.requestFullscreen =
  document.documentElement.requestFullscreen ||
  document.documentElement.webkitRequestFullscreen;
document.exitFullscreen =
  document.exitFullscreen || document.webkitExitFullscreen;
const isFullscreenMode = () =>
  !!document.fullscreenElement || !!document.webkitFullscreenElement;

export const useFullscreenListener = () => {
  const { setIsFullscreen } = useLiveUI();

  useEffect(() => {
    const handleFullScreenChange = () => setIsFullscreen(isFullscreenMode());
    document.addEventListener("fullscreenchange", handleFullScreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullScreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullScreenChange,
      );
    };
  }, [setIsFullscreen]);
};
