import {
  reactQueryDelete,
  reactQueryGet,
  reactQueryPost,
} from "@/react/helpers/backendRequestHelpers";
import { paywalls } from "@circle-react/helpers/urlHelpers/internalApi/admin/paywalls";

const preview =
  ({ subscriptionProcessorId, couponCode }: Record<string, string>) =>
  () =>
    reactQueryGet(
      paywalls.subscriptions.coupons.preview({
        subscriptionProcessorId,
        couponCode,
      }),
    );

const create = ({ subscriptionProcessorId, params }: Record<string, string>) =>
  reactQueryPost(
    paywalls.subscriptions.coupons.create({ subscriptionProcessorId }),
    params,
  );

const deleteEndpoint = ({ subscriptionProcessorId }: Record<string, string>) =>
  reactQueryDelete(
    paywalls.subscriptions.coupons.delete({ subscriptionProcessorId }),
  );

export const couponsApi = {
  preview,
  create,
  delete: deleteEndpoint,
};
