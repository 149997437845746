import { useMutation, useQueryClient } from "react-query";
import { reactQueryPut } from "@/react/helpers/backendRequestHelpers";
import { internalApi } from "@/react/helpers/urlHelpers";

export const REQUEST_KEY = "space-members";

export const useUpdateSpaceMember = () => {
  const queryClient = useQueryClient();
  const mutationFn = ({ spaceMember, updates }) =>
    reactQueryPut(
      internalApi.spaceMembers.update({
        spaceId: spaceMember.space_id,
        memberId: spaceMember.community_member_id,
      }),
      { space_member: updates },
    );

  return useMutation(mutationFn, {
    onMutate: async ({ spaceMember }) => {
      const key = [REQUEST_KEY, spaceMember.community_member_id];
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(key);
      // Snapshot the previous value
      const previousData = queryClient.getQueryData(key);
      // Optimistically update to the new value
      queryClient.setQueryData(key, spaceMember);
      return { previousData, spaceMember };
    },
    onSuccess: data => {
      queryClient.setQueryData([REQUEST_KEY, data.community_member_id], data);
    },
  });
};
