import { SuggestedSpacesSection } from "./SuggestedSpacesSection";
import { WelcomeBannerV1 } from "./WelcomeBanner";

export const HomePageV1 = ({ community_member_id, display_welcome_banner }) => (
  <div>
    {display_welcome_banner && (
      <WelcomeBannerV1 communityMemberId={community_member_id} />
    )}
    <SuggestedSpacesSection />
  </div>
);
