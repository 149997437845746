import { Suspense } from "react";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { PageLoader } from "@circle-react/pages/PageLoader";

const ApiSettingsComponent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "ApiSettingsPage" */ "@circle-react/components/SettingsApp/ApiTokens"
    ),
);

export const ApiSettingsPage = () => (
  <Suspense fallback={<PageLoader />}>
    <ApiSettingsComponent />
  </Suspense>
);
