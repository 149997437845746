import type { ReactNode } from "react";
import classNames from "classnames";
import { Typography } from "@circle-react-uikit/Typography";

export const Badge = ({
  icon,
  text,
  isLoading,
}: {
  icon?: ReactNode;
  text?: string;
  isLoading?: boolean;
}) => (
  <span
    className={classNames(
      "inline-flex h-6 items-center gap-1 rounded-full border px-2",
      {
        "bg-tertiary border-tertiary w-20 animate-pulse": isLoading,
        "border-dark": !isLoading,
      },
    )}
  >
    {icon && <span className="flex">{icon}</span>}
    {text && (
      <Typography.LabelXs weight="medium" color="text-default">
        {text}
      </Typography.LabelXs>
    )}
  </span>
);
