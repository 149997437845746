import classNames from "classnames";
import { t } from "i18n-js";
import { useIsV3 } from "@circle-react/hooks/useIsV3";

export const NoSpaces = () => {
  const { isV3Enabled } = useIsV3();

  return (
    <div>
      <div className="homepage__nav">
        <h2 className="homepage__title" data-testid="space-title-name">
          {t("home_page.title")}
        </h2>
      </div>
      <div
        className={classNames("space__gated locked-pane", {
          "!rounded-2xl": isV3Enabled,
        })}
        data-testid="post_section"
      >
        <h3>{t("landing_page.settings.empty_suggested_spaces_text")}</h3>
      </div>
    </div>
  );
};
