import PropTypes from "prop-types";
import classNames from "classnames";

export const Container = ({ children, className }) => (
  <main className={classNames("bg-primary flex h-full flex-col", className)}>
    {children}
  </main>
);

Container.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
