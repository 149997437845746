import { t } from "i18n-js";
import { Loader } from "@circle-react-uikit/Loader";
import { ChangePlanFormChooser } from "./ChangePlanFormChooser";
import { useChangePlanFormContext } from "./ChangePlanFormContext";
import { CurrentPlanDetails } from "./CurrentPlanDetails";
import { useCurrentAndSelectedPlans } from "./hooks/useCurrentAndSelectedPlans";

export const PaywallChooserStep = () => {
  const { subscription, isInTrial } = useChangePlanFormContext();
  const { isLoadingCurrentPaywall } = useCurrentAndSelectedPlans();

  if (isLoadingCurrentPaywall) {
    return <Loader />;
  }

  let subscriptionPricingOptions = subscription.upgradable_paywalls?.map(
    (paywall: any) => ({
      key: `${paywall.id}`,
      value: paywall.id,
      label: paywall.display_name,
      details: paywall.min_subscription_amount,
      description: paywall.subtitle,
      enableCursor: true,
    }),
  );
  if (!isInTrial) {
    subscriptionPricingOptions = subscriptionPricingOptions?.filter(
      (paywall: any) => paywall.id !== subscription.paywall_id,
    );
  }

  return (
    <>
      <CurrentPlanDetails />
      <ChangePlanFormChooser
        name="paywall_id"
        warningMessage={t(
          "member_profile_modal.billing.subscriptions.alerts.errors.no_plan_chosen",
        )}
        options={subscriptionPricingOptions}
      />
    </>
  );
};
