import PropTypes from "prop-types";
import { t } from "i18n-js";
import { Dropdown } from "@circle-react-uikit/Dropdown";

const i18nRoot = "settings.workflows.table.actions";

export const Duplicate = ({ onClick }) => (
  <Dropdown.ItemWithLink onClick={onClick}>
    {t([i18nRoot, "duplicate"])}
  </Dropdown.ItemWithLink>
);

Duplicate.propTypes = {
  onClick: PropTypes.func.isRequired,
};
