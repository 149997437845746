import { flatten } from "lodash";
import { useInfiniteQuery } from "react-query";
import { useCommunityInboxStore } from "@/react/hooks/chatsV2/store";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers/internalApi";

export const useChatList = (params = { filter: "", per_page: 20 }) => {
  const { setChats } = useCommunityInboxStore();
  const fetchChats = (page: number) =>
    reactQueryGet(
      internalApi.communityInbox.index({
        params: {
          page,
          ...params,
        },
      }),
    );

  const { data, isLoading, hasNextPage, fetchNextPage } = useInfiniteQuery(
    ["community-inbox", params.filter, params.per_page],
    ({ pageParam = 1 }) => fetchChats(pageParam),
    {
      select: ({ pages = [] }) =>
        // RQ recommends to restructure your data that it still includes data.pages and data.pageParams properties - https://tanstack.com/query/v4/docs/react/guides/infinite-queries
        ({
          pages: flatten(pages.map(page => page.records)),
          pageParams: pages.map(page => page.page),
          pagesList: pages,
        }),
      getNextPageParam: lastPage =>
        lastPage.has_next_page ? lastPage.page + 1 : undefined,
      onSuccess: data => {
        if (params.filter != "mine") {
          setChats(data?.pages || []);
        }
      },
    },
  );

  const chats = data?.pages || [];
  return {
    chats,
    isChatsLoading: isLoading,
    hasNextPage,
    fetchNextPage,
  };
};
