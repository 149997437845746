import type { ReactNode } from "react";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { usePaywallCheckoutContext } from "@circle-react/contexts/Paywalls/paywallCheckoutContext";
import { getQueryParam } from "@circle-react/helpers/urlHelpers";
import { CheckoutFormFieldWrapper } from "../CheckoutFormFieldWrapper";
import { CheckoutFormCouponBadge } from "./CheckoutFormCouponBadge";
import { CheckoutFormCouponForm } from "./CheckoutFormCouponForm";

export const CheckoutFormCouponField = () => {
  const { watch } = useFormContext();
  const { areCouponsEnabled, applyCouponCode } = usePaywallCheckoutContext();
  const [isInitialCouponApplied, setInitialCouponApplied] = useState(false);
  const { coupon_code_applied } = watch();
  const { previewMutation } = usePaywallCheckoutContext();

  useEffect(() => {
    const couponCode = getQueryParam("coupon_code");

    if (couponCode && !isInitialCouponApplied && previewMutation.isSuccess) {
      setInitialCouponApplied(true);
      applyCouponCode(couponCode);
    }
  }, [applyCouponCode, isInitialCouponApplied, previewMutation.isSuccess]);

  if (!areCouponsEnabled) {
    return null;
  }

  let content: ReactNode;
  if (coupon_code_applied) {
    content = <CheckoutFormCouponBadge />;
  } else {
    content = <CheckoutFormCouponForm />;
  }

  return (
    <CheckoutFormFieldWrapper name="coupon_code" optional>
      {content}
    </CheckoutFormFieldWrapper>
  );
};
