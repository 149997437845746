import PropTypes from "prop-types";
import { AudioRenderer, useParticipant } from "@livekit/react-core";

export const AudioPlayer = ({ participant }) => {
  const { microphonePublication, isLocal } = useParticipant(participant);
  if (
    !microphonePublication?.track ||
    !microphonePublication?.isSubscribed ||
    microphonePublication?.isMuted ||
    !microphonePublication?.isEnabled
  ) {
    return null;
  }

  return (
    <AudioRenderer track={microphonePublication.track} isLocal={isLocal} />
  );
};

AudioPlayer.propTypes = {
  participant: PropTypes.object,
};
