import { separator } from "./separator";
import type { DropdownButton, DropdownLink, DropdownOption } from "./types";

interface ModeratorOptionsProps {
  moderation: DropdownLink;
  showToggleTheme: boolean;
  toggleTheme: DropdownButton[];
  post: DropdownLink[];
  shortcutsModalButton: DropdownButton | null;
}
export const moderatorOptions = ({
  moderation,
  showToggleTheme,
  toggleTheme,
  post,
  shortcutsModalButton,
}: ModeratorOptionsProps): DropdownOption[] => [
  ...post,
  moderation,
  ...(showToggleTheme ? [separator] : []),
  ...toggleTheme,
  ...(shortcutsModalButton ? [shortcutsModalButton] : []),
];
