import classNames from "classnames";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { FiltersList } from "@circle-react-shared/Filter/FiltersList";

export interface HistoryFiltersProps {
  filtersConfig: {
    name: string;
    component: any;
  }[];
}

export const HistoryFilters = ({ filtersConfig }: HistoryFiltersProps) => {
  const { isV3Enabled } = useIsV3();

  return (
    <FiltersList
      className={classNames(
        "border-stroke-background relative border-b px-6 py-3",
        {
          "lg:px-9": isV3Enabled,
        },
      )}
    >
      {filtersConfig.map(({ name, component: Component }) => (
        <Component key={name} />
      ))}
    </FiltersList>
  );
};
