import { t } from "i18n-js";
import { SearchResultsContainer } from "@/react/components/search/SearchResultsContainer";
import type { SearchType } from "@/react/components/search/types";
import { Typography } from "@circle-react-uikit/Typography";

export interface EmptySearchResultModalProps {
  type: SearchType;
}

export const EmptySearchResultModal = ({
  type,
}: EmptySearchResultModalProps) => (
  <SearchResultsContainer>
    <div
      className="m-auto flex max-w-md flex-col items-center justify-center gap-2 p-8 pb-14 pt-20"
      data-testid="no-results-found"
    >
      <Typography.TitleSm weight="semibold">
        {t(`search_v2.empty_search_by_type.${type}`)}
      </Typography.TitleSm>
      <Typography.BodySm color="text-default">
        {t("search_v2.empty_search_description")}
      </Typography.BodySm>
    </div>
  </SearchResultsContainer>
);
