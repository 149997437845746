import { useRef, useState } from "react";
import { DirectUpload } from "@rails/activestorage";
import { supportWidgetApi } from "@circle-react/api/supportWidgetApi";
import {
  activeStorageBlobUrl,
  directUploadPath,
} from "@circle-react/helpers/urlHelpers";
import {
  ATTACHMENT_STATUS_COMPLETED,
  ATTACHMENT_STATUS_FAILED,
  ATTACHMENT_STATUS_UPLOADED,
  ATTACHMENT_STATUS_UPLOADING,
} from "../constants";
import { getLocalStorage, setLocalStorage } from "../utils";

export const useZendeskUploads = ({ controlName, cachingEnabled }) => {
  const [attachments, setAttachments] = useState(() => {
    if (cachingEnabled) {
      return getLocalStorage()[`${controlName}-attachments`] || [];
    }
    return [];
  });
  const canceledUploads = useRef({});

  const updateAttachment = (url, props) => {
    setAttachments(prevAttachments =>
      prevAttachments.map(attachment => {
        if (attachment.url === url) {
          return { ...attachment, ...props };
        }
        return attachment;
      }),
    );
  };

  const cancelAttachment = attachment => {
    canceledUploads.current[attachment.url] = true;
    setAttachments(prevAttachments =>
      prevAttachments.filter(elem => elem.url !== attachment.url),
    );

    if (cachingEnabled) {
      setLocalStorage({
        ...getLocalStorage(),
        [`${controlName}-attachments`]: (
          getLocalStorage()[`${controlName}-attachments`] || []
        ).filter(a => a.url !== attachment.url),
      });
    }
  };

  const setAttachmentObserver = ({
    url,
    filename,
    zendeskUploadId,
    blobUrl,
  }) => {
    let intervalId = null;

    intervalId = setInterval(() => {
      supportWidgetApi
        .checkAttachment({ zendeskUploadId: zendeskUploadId.current })
        .then(json => {
          if (canceledUploads.current[url]) {
            clearInterval(intervalId);
          } else if (json.success) {
            updateAttachment(url, {
              status: ATTACHMENT_STATUS_COMPLETED,
              completed: true,
            });

            if (cachingEnabled) {
              const storedAttachments =
                getLocalStorage()[`${controlName}-attachments`] || [];

              setLocalStorage({
                ...getLocalStorage(),
                [`${controlName}-attachments`]: [
                  ...storedAttachments,
                  {
                    status: ATTACHMENT_STATUS_COMPLETED,
                    completed: true,
                    url,
                    filename,
                    zendeskUploadId,
                    blobUrl,
                  },
                ],
              });
            }

            clearInterval(intervalId);
          }
        })
        .catch(error => {
          console.error(error);
        });
    }, 1000);
  };

  const startUpload = ({ url, file, filename }) => {
    updateAttachment(url, {
      status: ATTACHMENT_STATUS_UPLOADING,
    });

    const upload = new DirectUpload(file, directUploadPath(), {
      directUploadWillStoreFileWithXHR: xhr =>
        xhr.upload.addEventListener("progress", e => {
          if (canceledUploads.current[url]) {
            xhr.abort();
          }
          updateAttachment(url, {
            progress: (e.loaded / e.total) * 100,
          });
        }),
    });

    upload.create((error, blob) => {
      if (error) {
        updateAttachment(url, {
          status: ATTACHMENT_STATUS_FAILED,
        });
        throw new Error(`Direct upload failed: ${error}`);
      } else {
        supportWidgetApi
          .createAttachment({ signedId: blob.signed_id })
          .then(response => response.json())
          .then(json => {
            const blobUrl = activeStorageBlobUrl({
              signed_id: blob.signed_id,
              filename: blob.filename,
            });
            const zendeskUploadId = json.zendesk_upload_id;

            updateAttachment(url, {
              status: ATTACHMENT_STATUS_UPLOADED,
              zendeskUploadId,
              blobUrl,
            });

            setAttachmentObserver({ url, filename, zendeskUploadId, blobUrl });
          })
          .catch(error => {
            console.error(error);
          });
      }
    });
  };

  const uploadAttachments = files => {
    const fileObjects = files.map(file => ({
      file,
      url: URL.createObjectURL(file),
      filename: file.name,
      completed: false,
    }));

    setAttachments([...attachments, ...fileObjects]);

    for (const fileObject of fileObjects) {
      startUpload({
        url: fileObject.url,
        file: fileObject.file,
        filename: fileObject.filename,
        status: ATTACHMENT_STATUS_UPLOADING,
      });
    }
  };

  return {
    uploadAttachments,
    attachments,
    setAttachments,
    cancelAttachment,
  };
};
