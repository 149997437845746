import { t } from "@circle-react/custom_i18n";
import { Icon } from "@circle-react-shared/Icon";
import { Button } from "@circle-react-shared/uikit/Button";
import { BodyMd } from "@circle-react-shared/uikit/Typography/components/Body/BodyMd";
import { BodyXs } from "@circle-react-shared/uikit/Typography/components/Body/BodyXs";
import { TitleSm } from "@circle-react-shared/uikit/Typography/components/Title/TitleSm";
import { Avatar } from "./Avatar";

export const AgentsBanner = () => (
  <div className="bg-secondary flex justify-between border-b px-12 py-8">
    <div className="flex items-start space-x-8">
      <Avatar />
      <div className="flex flex-col gap-2">
        <TitleSm weight="semibold">
          {t("settings.community_bot.agents.banner.title")}
        </TitleSm>
        <BodyMd color="secondary">
          {t("settings.community_bot.agents.banner.description")}
        </BodyMd>
        <Button
          variant="secondary"
          aria-hidden
          className="!border-primary hover:!border-secondary mt-2 flex w-fit gap-1"
        >
          <Icon type="pencil" size={16} aria-hidden stroke="secondary" />
          <BodyXs color="secondary">
            {t("settings.community_bot.agents.banner.edit_button.title")}
          </BodyXs>
        </Button>
      </div>
    </div>
    <Icon type="20-close" className="!self-start" />
  </div>
);
