import { CourseTypePickerOptions } from "@/react/components/Spaces/CourseSpace/CourseTypePicker/CourseTypePickerOptions";
import { DEFAULT_COURSE_TYPE } from "@/react/components/Spaces/CourseSpace/CourseTypePicker/constants";
import { Form } from "@circle-react-uikit/Form";

export const COURSE_TYPE_FORM_ID = "course-type-form";

export const CourseTypePickerForm = ({
  onSubmit,
  courseSetting = { course_type: DEFAULT_COURSE_TYPE },
}: any) => (
  <Form
    id={COURSE_TYPE_FORM_ID}
    onSubmit={onSubmit}
    defaultValues={{
      course_setting: courseSetting,
    }}
    validationSchema={undefined}
    className={undefined}
    formClassName={undefined}
  >
    <CourseTypePickerOptions hideLabel />
  </Form>
);
