/* eslint-disable max-lines
-- Disabled to set CI to fail on this issue on new files, PR #6757 */
import { useEffect, useState } from "react";
import { t } from "i18n-js";
import { Button } from "@circle-react-uikit/Button";
import { transformToFormData } from "../../helpers/backendRequestHelpers";
import {
  attachmentFilesPath,
  landingPageSettingsPath,
} from "../../helpers/urlHelpers";
import { useLandingPageSettingsApi } from "../../hooks/home_page/useLandingPageSettingsApi";
import { FieldLabel } from "../shared/Form/FieldLabel";
import { LoaderRow } from "../shared/LoaderRow";
import { MetaTagDetailForm } from "../shared/MetaTagDetail/Form/index";
import { SearchableSelect_DEPRECATED } from "../shared/SearchableSelect_DEPRECATED";
import { ToggleSwitch } from "../shared/ToggleSwitch";
import { BannerFormFields } from "./BannerFormFields";
import { SpaceSelect } from "./SpaceSelect";

// eslint-disable-next-line sonarjs/cognitive-complexity -- Disabled to set CI to fail on this issue on new files, PR #6718
export const LandingPageSettings = () => {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [sections, setSections] = useState({
    logged_in: true,
    logged_out: true,
    meta_details: true,
  });
  const [errors, setErrors] = useState(new Set());
  const {
    dataLoaded: isDataLoaded,
    settings,
    metaData,
    loggedInUserSpaceOptions,
    loggedOutUserSpaceOptions,
    setSettings,
    fetchLandingPageSettings,
    setLoggedInUserSpaceOptions,
    setLoggedOutUserSpaceOptions,
  } = useLandingPageSettingsApi();

  const { community, community_setting } = settings;

  const {
    home_page_enabled,
    default_existing_member_space_id,
    default_new_member_space_id,
    default_logged_out_space_id,
  } = community;

  const {
    trending_posts_window_in_days,
    include_non_member_space_posts,
    meta_tags,
  } = community_setting;

  const { postSortOptions, trendingPostWindowLimit, homePageOption } = metaData;

  const localizedData = t("landing_page.settings");

  useEffect(() => {
    fetchLandingPageSettings();
  }, []);

  const validateInputs = () => {
    const results = new Set();

    if (trending_posts_window_in_days > trendingPostWindowLimit) {
      results.add(
        t("landing_page.settings.trending_posts_window_limit_exceed", {
          limit: trendingPostWindowLimit,
        }),
      );
    }

    [
      "logged_in_user_home_page_banner",
      "logged_out_user_home_page_banner",
    ].forEach(fieldName => {
      const { enabled, title } = community_setting[fieldName];

      if (enabled) {
        const media = community[`${fieldName}_media`];
        const hasMedia =
          (media["url"] && media["url"].length > 0) || media.length > 0;
        if (
          title.length === 0 &&
          community[`${fieldName}_description`].length === 0 &&
          !hasMedia
        ) {
          results.add(localizedData.welcome_banner.errors.blank_welcome_banner);
        }
      }
    });

    if (results.size > 0) {
      setErrors(results);
      return false;
    }
    return true;
  };

  const handleSubmit = async event => {
    event.preventDefault();
    if (validateInputs()) {
      setIsSubmitDisabled(true);

      const transform = transformToFormData({
        landing_page_settings: settings,
      });

      fetch(landingPageSettingsPath(), {
        method: "POST",
        body: transform,
        credentials: "same-origin",
        mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(r => r.json())
        .then(() => {
          setIsSubmitDisabled(false);
          window.location.reload();
        })
        .catch(error => {
          console.error(error);
        });
    } else {
      window.scrollTo(0, 0);
    }
  };

  const onRemoveMedia = async (event, fieldName) => {
    setIsSubmitDisabled(true);

    const response = await fetch(attachmentFilesPath(), {
      method: "DELETE",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        field_name: fieldName,
      }),
    });

    if (response.ok) {
      const updatedSettings = {
        community: {
          ...community,
          [fieldName]: {},
        },
        community_setting: community_setting,
      };
      setSettings(updatedSettings);
      setIsSubmitDisabled(false);
    }
  };

  const setMediaSettings = (fieldName, signedId) => {
    const updatedSettings = {
      community: {
        ...community,
        [fieldName]: signedId,
      },
      community_setting: community_setting,
    };

    setSettings(updatedSettings);
  };

  const onCommunitySettingChange = (parentKey, fieldName, value) => {
    const updatedSettings = {
      community: community,
      community_setting: {
        ...community_setting,
        [parentKey]: fieldName
          ? { ...community_setting[parentKey], [fieldName]: value }
          : value,
      },
    };

    setSettings(updatedSettings);
  };

  const onBannerDescriptionChange = (event, fieldName) => {
    const updatedSettings = {
      community: { ...community, [fieldName]: event.target.innerHTML },
      community_setting: community_setting,
    };

    setSettings(updatedSettings);
  };

  const onOptionSelect = (selectedOption, fieldName, parentKey) => {
    let updatedSettings = settings;

    if (parentKey === "community") {
      updatedSettings = {
        community: { ...community, ...{ [fieldName]: selectedOption.value } },
        community_setting: community_setting,
      };
    } else {
      updatedSettings = {
        community: community,
        community_setting: {
          ...community_setting,
          ...{ [fieldName]: selectedOption.value },
        },
      };
    }

    setSettings(updatedSettings);
  };

  const onHomePageToggle = checked => {
    const homePageOptionValue = homePageOption.value;

    if (checked === true) {
      const landingSpaceUpdates = {
        home_page_enabled: checked,
        default_existing_member_space_id: homePageOptionValue,
        default_new_member_space_id: homePageOptionValue,
        default_logged_out_space_id: homePageOptionValue,
      };

      const updatedSettings = {
        community: { ...community, ...landingSpaceUpdates },
        community_setting: community_setting,
      };

      setLoggedInUserSpaceOptions([
        homePageOption,
        ...loggedInUserSpaceOptions,
      ]);
      setLoggedOutUserSpaceOptions([
        homePageOption,
        ...loggedOutUserSpaceOptions,
      ]);
      setSettings(updatedSettings);
    } else {
      const landingSpaceUpdates = { home_page_enabled: checked };
      const updatedLoggedInUserSpaceOptions = loggedInUserSpaceOptions.filter(
        option => option.value !== homePageOptionValue,
      );
      const updatedLoggedOutUserSpaceOptions = loggedOutUserSpaceOptions.filter(
        option => option.value !== homePageOptionValue,
      );

      if (default_existing_member_space_id === homePageOption.value) {
        landingSpaceUpdates.default_existing_member_space_id =
          updatedLoggedInUserSpaceOptions[0].value;
      }

      if (default_new_member_space_id === homePageOption.value) {
        landingSpaceUpdates.default_new_member_space_id =
          updatedLoggedInUserSpaceOptions[0].value;
      }

      if (default_logged_out_space_id === homePageOption.value) {
        landingSpaceUpdates.default_logged_out_space_id =
          updatedLoggedInUserSpaceOptions[0].value;
      }

      const updatedSettings = {
        community: { ...community, ...landingSpaceUpdates },
        community_setting: community_setting,
      };

      setSettings(updatedSettings);
      setLoggedInUserSpaceOptions(updatedLoggedInUserSpaceOptions);
      setLoggedOutUserSpaceOptions(updatedLoggedOutUserSpaceOptions);
    }
  };

  const homePageSpecificConfigs = () => (
    <div className="form-group__hidden">
      {trendingPostsWindowField()}
      {postSortOptionsFields}
      {nonMemberSpaceToggle()}
    </div>
  );

  const trendingPostsWindowField = () => (
    <div className="form-group">
      <FieldLabel
        field="trending_posts_window_in_days_title"
        label={localizedData.trending_posts_window_in_days_title}
        description={localizedData.trending_posts_window_in_days_description}
      />
      <div className="form-input">
        <input
          className="form-control placeholder:text-light placeholder:opacity-100"
          value={trending_posts_window_in_days}
          onChange={event =>
            onCommunitySettingChange(
              "trending_posts_window_in_days",
              null,
              event.target.value,
            )
          }
        />
      </div>
    </div>
  );

  const postSortOptionsFields = ["logged_in", "logged_out"].map(key => {
    const fieldName = `default_post_sort_${key}_user`;

    return (
      <div className="form-group" key={fieldName}>
        <FieldLabel
          field={fieldName}
          label={localizedData[fieldName]}
          description={localizedData[`${fieldName}_description`]}
        />
        <SearchableSelect_DEPRECATED
          options={postSortOptions}
          selected={community_setting[fieldName]}
          customKey={fieldName}
          onOptionSelect={value =>
            onOptionSelect(value, fieldName, "community_setting")
          }
          searchable={false}
        />
      </div>
    );
  });

  const nonMemberSpaceToggle = () => (
    <div className="form-group full-width">
      <FieldLabel
        field="include_non_member_space_posts"
        label={localizedData.include_non_member_space_posts_title}
        description={localizedData.include_non_member_space_posts_description}
      />
      <div className="form-input">
        <div className="form-meta-button">
          <ToggleSwitch
            field="include_non_member_space_posts"
            value={include_non_member_space_posts}
            onToggleChange={value =>
              onCommunitySettingChange(
                "include_non_member_space_posts",
                null,
                value,
              )
            }
          />
        </div>
      </div>
    </div>
  );

  const loggedInDefaultsSection = () => (
    <div className="form-section__content">
      <SpaceSelect
        fieldName="default_existing_member_space"
        options={loggedInUserSpaceOptions}
        community={community}
        onOptionSelect={onOptionSelect}
      />
      <SpaceSelect
        fieldName="default_new_member_space"
        options={loggedInUserSpaceOptions}
        community={community}
        onOptionSelect={onOptionSelect}
      />
      {home_page_enabled && (
        <BannerFormFields
          fieldName="logged_in_user_home_page_banner"
          community={community}
          community_setting={community_setting}
          setMediaSettings={setMediaSettings}
          onBannerDescriptionChange={onBannerDescriptionChange}
          onCommunitySettingChange={onCommunitySettingChange}
          onRemoveMedia={onRemoveMedia}
        />
      )}
    </div>
  );

  const loggedOutDefaultsSection = () => (
    <div className="form-section__content">
      <SpaceSelect
        fieldName="default_logged_out_space"
        options={loggedOutUserSpaceOptions}
        community={community}
        onOptionSelect={onOptionSelect}
      />
      {home_page_enabled && (
        <BannerFormFields
          fieldName="logged_out_user_home_page_banner"
          community={community}
          community_setting={community_setting}
          setMediaSettings={setMediaSettings}
          onBannerDescriptionChange={onBannerDescriptionChange}
          onCommunitySettingChange={onCommunitySettingChange}
          onRemoveMedia={onRemoveMedia}
        />
      )}
    </div>
  );

  const landingDefaultsSections = ["logged_in", "logged_out"].map(key => {
    const sectionVisible = sections[key];

    return (
      <div className="form-section" key={`${key}-section`}>
        <div className="form-section__header has-toggle">
          <button
            type="button"
            className={`block-toggle ${sectionVisible ? "open" : "hidden"}`}
            onClick={event => {
              event.preventDefault();
              setSections({ ...sections, [key]: !sectionVisible });
            }}
          >
            {localizedData[`${key}_defaults_title`]}
          </button>
        </div>
        {sectionVisible &&
          (key === "logged_in"
            ? loggedInDefaultsSection()
            : loggedOutDefaultsSection())}
      </div>
    );
  });

  const homePageToggleField = () => (
    <div className="form-group__toggle">
      <div className="form-group full-width" id="field-home_page_enabled">
        <FieldLabel
          field="home_page_enabled"
          label={localizedData.home_page_enabled}
          description={localizedData.home_page_enabled_description}
        />
        <div className="form-input">
          <div className="form-meta-button">
            <ToggleSwitch
              field="home_page_enabled"
              value={home_page_enabled}
              onToggleChange={onHomePageToggle}
            />
          </div>
        </div>
      </div>
    </div>
  );

  const homeMetaDataSection = () => (
    <div className="form-section" key="meta-data-section">
      <div className="form-section__header has-toggle">
        <button
          type="button"
          className={`block-toggle ${
            sections["meta_details"] ? "open" : "hidden"
          }`}
          onClick={event => {
            event.preventDefault();
            setSections({
              ...sections,
              meta_details: !sections["meta_details"],
            });
          }}
        >
          {localizedData["meta_tag"]["meta_tag_section_title"]}
        </button>
      </div>
      {sections["meta_details"] && (
        <MetaTagDetailForm
          metaTagDetails={meta_tags}
          localizedData={localizedData}
          onCommunitySettingChange={onCommunitySettingChange}
        />
      )}
    </div>
  );

  return isDataLoaded ? (
    <form onSubmit={handleSubmit}>
      {errors.size > 0 && <div className="form-errors">{errors}</div>}
      <div className="form-section">
        <div className="form-section__content border-top">
          <div className="form-section">
            {homePageToggleField()}
            {home_page_enabled && homePageSpecificConfigs()}
          </div>
          {landingDefaultsSections}
          {home_page_enabled && homeMetaDataSection()}
        </div>
      </div>
      <div className="flex justify-end">
        <Button variant="circle" type="submit" disabled={isSubmitDisabled}>
          {isSubmitDisabled ? t("submitting") : t("save_changes")}
        </Button>
      </div>
    </form>
  ) : (
    <LoaderRow />
  );
};
