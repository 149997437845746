import { useMutation } from "react-query";
import { paywallsExportCsvApi } from "@circle-react/api/paywallsExportCsvApi";
import type { PaywallsAdminExportsCreate } from "@circle-react/api/paywallsExportCsvApi";

export const usePaywallExportCsvApi = () => {
  const createCsvExportMutation = useMutation<
    unknown,
    unknown,
    PaywallsAdminExportsCreate
  >(formData => paywallsExportCsvApi.create(formData));

  return {
    createCsvExportMutation,
  };
};
