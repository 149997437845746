import { useBroadcastData } from "@circle-react-shared/Emails/BroadcastDataContext";
import { Loader } from "@circle-react-shared/uikit/Loader";
import { ViewEmailDetails } from "./ViewEmailDetails";

export function ViewEmailCollapsed() {
  const { isLoading } = useBroadcastData();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="flex flex-col gap-y-6">
      <ViewEmailDetails hideViewEmailModal />
    </div>
  );
}
