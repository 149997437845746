export const Avatar = () => (
  <div>
    <svg
      width="134"
      height="134"
      viewBox="0 0 134 134"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Avatar">
        <g clipPath="url(#clip0_4495_28245)">
          <rect
            width="134"
            height="134"
            rx="67"
            fill="url(#paint0_radial_4495_28245)"
          />
          <rect
            id="Img"
            width="134"
            height="134"
            fill="url(#paint1_linear_4495_28245)"
          />
          <rect
            id="Rectangle 1940"
            x="62"
            y="62"
            width="10"
            height="10"
            rx="5"
            fill="white"
          />
          <rect
            id="Rectangle 1942"
            x="41"
            y="57"
            width="10"
            height="20"
            rx="5"
            fill="white"
          />
          <rect
            id="Rectangle 1941"
            x="83"
            y="51"
            width="10"
            height="32"
            rx="5"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_4495_28245"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(67 67) rotate(90) scale(67)"
        >
          <stop stopColor="#469AFC" />
          <stop offset="1" stopColor="#046CF1" />
        </radialGradient>
        <linearGradient
          id="paint1_linear_4495_28245"
          x1="154"
          y1="-82"
          x2="10"
          y2="170"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F7F9FA" />
          <stop offset="1" stopColor="#E6ECEF" />
        </linearGradient>
        <clipPath id="clip0_4495_28245">
          <rect width="134" height="134" rx="67" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </div>
);
