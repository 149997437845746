import { Icon } from "@circle-react-shared/Icon/";

export interface StepIconProps {
  complete: boolean;
}

export const StepIcon = ({ complete }: StepIconProps) => (
  <Icon
    viewBox="0 0 16 16"
    size={16}
    type={complete ? "circle-checked-old" : "circle-unchecked-old"}
  />
);
