import { Suspense } from "react";
import { useParams } from "react-router-dom";
import { AdminAudienceEditModalContextProvider } from "@circle-react/components/Modals/AdminMemberEditModal/AdminAudienceEditContext/AdminAudienceEditModalContextProvider";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { PageLoader } from "@circle-react/pages/PageLoader";

const AdminLeadEditComponent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "AdminLeadEditPage" */ "@circle-react/components/Modals/AdminMemberEditModal/AdminLeadEditModal"
    ),
);

export const AdminLeadEditPage = () => {
  const { leadPublicId } = useParams<{ leadPublicId: string }>();

  return (
    <Suspense fallback={<PageLoader />}>
      <AdminAudienceEditModalContextProvider
        type="CommunityLead"
        publicId={leadPublicId}
      >
        <AdminLeadEditComponent />
      </AdminAudienceEditModalContextProvider>
    </Suspense>
  );
};
