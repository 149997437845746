import { t } from "i18n-js";
import { useFormContext } from "react-hook-form";
import { AcceptTermsLabel } from "@circle-react/components/OnboardingPage/CreateProfilePage/AcceptTermsLabel";
import { usePaywallCheckoutContext } from "@circle-react/contexts/Paywalls/paywallCheckoutContext";

const termsOfServiceKey = "termsOfService" as const;

export const CheckoutFormTOS = ({ label = t("checkbox") }) => {
  const { register } = useFormContext();
  const { currentUser } = usePaywallCheckoutContext();
  if (currentUser) {
    return null;
  }
  return (
    <div className="flex">
      <div className="inline-flex items-start gap-3">
        <input
          type="checkbox"
          id="terms"
          className="bg-primary text-circle hover:bg-tertiary disabled:border-selector-inactive disabled:bg-tertiary mt-[3px] h-4 w-4 cursor-pointer rounded border-gray-300"
          {...register(termsOfServiceKey, { required: true })}
        />
        <label
          className="text-dark mb-0 w-full cursor-pointer"
          htmlFor={termsOfServiceKey}
        >
          <span className="sr-only">{label}</span>
          <AcceptTermsLabel caller="paywallCheckout" />
        </label>
      </div>
    </div>
  );
};
