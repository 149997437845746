import { useMutation } from "react-query";
import { reactQueryPut } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";

export const useSpaceMutation = ({ space = {}, options = {} }) => {
  const spaceUrl = internalApi.spaces.update({ id: space.id });

  return useMutation(
    updatedSpace => reactQueryPut(spaceUrl, { space: updatedSpace }),
    options,
  );
};
