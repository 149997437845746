import { t } from "i18n-js";
import { EntityResource } from "../EntityResource";
import type { EventOption } from "../types";
import { AnyCommentSelector } from "./shared/AnyCommentSelector";

export const commentCreated: Readonly<EventOption> = {
  label: t("settings.workflows.form.events.post_comment_created.title"),
  value: "post_comment_created",
  group: t("settings.workflows.form.event_groups.posts_and_comments"),
  description: t(
    "settings.workflows.form.events.post_comment_created.description",
  ),
  formComponent: AnyCommentSelector,
  viewComponent: EntityResource,
  icon: "post",
};
