import { useMutation } from "react-query";
import { reactQueryPost } from "@/react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";

export const usePaymentMethodApi = () => {
  const createSetupIntentMutation = useMutation((payload: any) =>
    reactQueryPost(
      internalApi.communityMemberPaymentMethod.setupIntent.create(),
      payload,
    ),
  );

  const createPaymentMethodMutation = useMutation((payload: any) =>
    reactQueryPost(internalApi.communityMemberPaymentMethod.create(), payload),
  );

  return {
    createSetupIntentMutation,
    createPaymentMethodMutation,
  };
};
