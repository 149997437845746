import { t } from "i18n-js";
import { InfoBox } from "@circle-react-uikit/InfoBox";
import { Typography } from "@circle-react-uikit/Typography";
import { BlockList } from "../BlockListItems";
import { useFormBlocks } from "../Providers";

export const SidebarListView = () => {
  const { blocks, replaceBlocks } = useFormBlocks();

  return (
    <div className="p-6">
      <InfoBox className="mb-7">
        {t("courses.lockscreen.only_visible_on_private")}
      </InfoBox>
      <div className="mb-4">
        <Typography.LabelLg weight="semibold">
          {t("courses.lockscreen.page_blocks")}
        </Typography.LabelLg>
      </div>
      <BlockList blocks={blocks} onSort={replaceBlocks} />
    </div>
  );
};
