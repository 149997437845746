import { Suspense, useEffect } from "react";
import { useDynamicLayoutContext } from "@circle-react/components/Layout/DynamicLayout";
import { VersionedContent } from "@circle-react/components/Layout/VersionedContent";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { PageLoader } from "@circle-react/pages/PageLoader";

const HomeV2Component = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "HomePage" */ "@circle-react/components/HomePageV2"
    ),
);

export const HomePageV2 = () => {
  const { setDynamicLayoutProps } = useDynamicLayoutContext();

  useEffect(() => {
    setDynamicLayoutProps({
      fullWidth: true,
      disableRightSidebar: false,
    });
  }, [setDynamicLayoutProps]);

  return (
    <VersionedContent fullWidth fullHeight={false}>
      <Suspense fallback={<PageLoader />}>
        <HomeV2Component />
      </Suspense>
    </VersionedContent>
  );
};
