import { DataTable } from "@circle-react-uikit/DataTableV2";

export const SpacesList = ({ columns, spacesQuery, onChangePage }: any) => (
  <DataTable
    columns={columns}
    data={spacesQuery?.data?.records ?? []}
    isLoading={spacesQuery?.isLoading}
    initialPage={spacesQuery?.data?.page}
    pageSize={spacesQuery?.data?.per_page}
    totalRecordsCount={spacesQuery?.data?.count}
    onChangePage={onChangePage}
  >
    <DataTable.Table>
      <DataTable.Header />
      <DataTable.Loader />
      <DataTable.Body />
    </DataTable.Table>
    <DataTable.Pagination align="left" direction="reverse" />
  </DataTable>
);
