import { upperFirst } from "lodash";
import { getCurrentBcp47Locale } from "@circle-react/helpers/localeHelpers";

export const formatDateRangeString = (
  startDate: Date,
  endDate: Date,
  options?: Intl.DateTimeFormatOptions,
) => {
  try {
    const locale = getCurrentBcp47Locale();
    const formatter = new Intl.DateTimeFormat(locale, options);
    return upperFirst(formatter.formatRange(startDate, endDate));
  } catch (error) {
    if (error instanceof Error) {
      console.error(`Could not parse date range: ${error.message}`);
    }
    return "";
  }
};

export const getLongLocalizedDateRange = (
  startDate: Date,
  endDate: Date,
  timeZone: string,
) =>
  formatDateRangeString(startDate, endDate, {
    weekday: "long",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZone,
  });

export const getLocalizedDateRange = (
  startDate: Date,
  endDate: Date,
  timeZone: string,
) =>
  formatDateRangeString(startDate, endDate, {
    weekday: "long",
    hour: "numeric",
    minute: "numeric",
    timeZone,
  });

export const getLocalizedTimeRange = (
  startDate: Date,
  endDate: Date,
  timeZone: string,
) =>
  formatDateRangeString(startDate, endDate, {
    hour: "numeric",
    minute: "numeric",
    timeZone,
  });
