import { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { t } from "i18n-js";
import { noop } from "lodash";
import { New } from "@circle-react/components/Spaces/New";
import { NewV2 } from "@circle-react/components/Spaces/NewV2";
import { useLgScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { Tippy } from "@circle-react-shared/Tippy";
import { Drawer } from "@circle-react-shared/uikit/Drawer";
import { Modal } from "@circle-react-uikit/Modal";
import { AddLink } from "./AddLink";
import "./styles.scss";

export const CreateSpaceButton = ({
  spaceGroupId,
  type,
  size = "md",
  useDrawerFlow,
  children,
  ...rest
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const isLgScreen = useLgScreenMediaQuery();

  const handleClose = () => {
    setIsModalVisible(false);
  };

  const handleAddLInkClick = event => {
    event.preventDefault();
    setIsModalVisible(true);
  };

  return (
    <>
      {!type && (
        <a href="#create-space" onClick={handleAddLInkClick} {...rest}>
          {children}
        </a>
      )}
      {type === "icon" &&
        (isLgScreen ? (
          <Tippy
            content={t("create_a_space")}
            delay={0}
            arrow
            size="regular"
            animation="fade"
          >
            <AddLink onClick={handleAddLInkClick} />
          </Tippy>
        ) : (
          <AddLink onClick={handleAddLInkClick} />
        ))}
      {type === "link" && (
        <button
          type="submit"
          className={classnames({
            "create-space-btn--md": size === "md",
            "create-space-btn--lg": size === "lg",
          })}
          onClick={handleAddLInkClick}
          {...rest}
        >
          {t("create_a_space")}
        </button>
      )}
      {useDrawerFlow ? (
        <Drawer hideHeader open={isModalVisible} noWrapperPadding>
          <NewV2
            open={isModalVisible}
            onClose={handleClose}
            currentSpaceGroupId={spaceGroupId}
          />
        </Drawer>
      ) : (
        <Modal
          variant="fullscreen"
          title={t("create_a_space")}
          show={isModalVisible}
          onClose={noop}
          hideCloseButton
          hideTitle
        >
          <New space_group_id={spaceGroupId} onModalClose={handleClose} />
        </Modal>
      )}
    </>
  );
};

CreateSpaceButton.propTypes = {
  spaceGroupId: PropTypes.number.isRequired,
  type: PropTypes.oneOf(["link", "icon"]),
  size: PropTypes.oneOf(["md", "lg"]),
  useDrawerFlow: PropTypes.bool,
  children: PropTypes.node,
};
