import { useFormContext } from "react-hook-form";
import type {
  SpaceGroupOption,
  SpaceOption,
} from "@circle-react/components/Spaces/SpaceAccessManagement/SpaceAccessManagementContext";
import {
  serializeSpaceDefault,
  useSpaceAccessManagement,
} from "@circle-react/components/Spaces/SpaceAccessManagement/hooks/useSpaceAccessManagement";
import { useAddSpaceGroupConfirmationModal } from "../AddSpaceGroupConfirmationModal";
import { useRemoveSpaceConfirmationModal } from "../RemoveSpaceConfirmationModal";

export interface SpaceGroupProperties extends SpaceGroupOption {
  properties: {
    automatically_add_members_to_new_spaces: boolean;
  };
}

const serializeSpaceGroup = (spaceGroup: any): SpaceGroupProperties => ({
  id: spaceGroup.id,
  name: spaceGroup.name,
  properties: {
    automatically_add_members_to_new_spaces:
      spaceGroup.automatically_add_members_to_new_spaces,
  },
  spaces: [],
});

export const usePaywallSpaceAccessManagement = () => {
  const {
    options: spaceGroupOptions,
    spaceGroupIndex,
    spaceRecords,
  } = useSpaceAccessManagement<SpaceGroupProperties, SpaceOption>({
    serializeSpaceGroup,
    serializeSpace: serializeSpaceDefault,
  });
  const { watch } = useFormContext();

  const spaceGroupsSelected = watch("space_group_ids");
  const paywallId = watch("id");

  const { show: showModalConfirmation } = useAddSpaceGroupConfirmationModal();
  const { show: showRemoveSpaceConfirmation } =
    useRemoveSpaceConfirmationModal();
  const isSpaceGroupAddMembersToNewSpaces = (spaceGroupId: any) =>
    spaceGroupIndex[spaceGroupId]?.properties
      .automatically_add_members_to_new_spaces;

  const addSpaceGroupCallback = (action: () => void, object: any) => {
    if (isSpaceGroupAddMembersToNewSpaces(object.id)) {
      void showModalConfirmation({ action, spaceGroups: [object] });
    } else {
      action();
    }
  };

  const addAllSpaceGroupCallback = (action: () => void) => {
    const spaceGroupsWhichAddMembersToNewSpaces = spaceGroupOptions.filter(
      spaceGroup =>
        !spaceGroupsSelected.includes(spaceGroup.id) &&
        spaceGroup.properties.automatically_add_members_to_new_spaces,
    );

    if (spaceGroupsWhichAddMembersToNewSpaces.length > 0) {
      void showModalConfirmation({
        action,
        spaceGroups: spaceGroupsWhichAddMembersToNewSpaces,
      });
    } else {
      action();
    }
  };

  const isSpaceContainPaywallLockScreen = (space: any) => {
    const paywallLockScreenBlock = space.lock_screen_blocks?.find(
      (lockScreenBlock: any) => lockScreenBlock?.data?.paywalls,
    );
    return !!paywallLockScreenBlock?.data?.paywalls?.find(
      (paywallLockScreenBlock: any) => paywallLockScreenBlock.id === paywallId,
    );
  };

  const removeSpaceCallback = (action: () => void, object: SpaceOption) => {
    const spacesWithPaywallLockScreenToRemove = spaceRecords.filter(
      (space: any) =>
        space.id === object.id && isSpaceContainPaywallLockScreen(space),
    );

    if (spacesWithPaywallLockScreenToRemove.length > 0) {
      void showRemoveSpaceConfirmation({
        action,
        spaces: spacesWithPaywallLockScreenToRemove,
      });
    } else {
      action();
    }
  };

  return {
    options: spaceGroupOptions,
    addSpaceGroupCallback,
    addAllSpaceGroupCallback,
    removeSpaceCallback,
  };
};
