import { useQuery } from "react-query";
import type { ApiError } from "@circle-react/config/CustomErrors";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import type { PaywallChargeStats } from "@circle-react/types";

export const useChargeStatsQuery = () => {
  const {
    isLoading: isChargeStatsLoading,
    data: chargeStats,
    error: chargeStatsError,
  } = useQuery<PaywallChargeStats, ApiError>(
    internalApi.admin.paywalls.charges.stats.index(),
  );

  return {
    isChargeStatsLoading,
    chargeStats,
    chargeStatsError,
  };
};
