import classnames from "classnames";
import { t } from "i18n-js";
import { Icon } from "@circle-react/components/shared/Icon";

export const EventItem = ({ event, last }) => {
  const date = new Date(event.starts_at).toLocaleString("en-US", {
    month: "long",
    day: "numeric",
  });

  const time = new Date(event.starts_at).toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });

  return (
    <a
      href={event.post_url}
      target="_blank"
      rel="noreferrer"
      className={classnames(
        "flex items-start justify-between p-4 hover:bg-gray-100 dark:border-gray-700 hover:dark:bg-gray-900",
        { "border-b": !last },
      )}
    >
      <div className="flex flex-col gap-2">
        <span className="text-sm font-semibold text-[#1A283B] dark:font-medium dark:text-gray-300">
          {event.name}
        </span>
        <span className="text-[11px] font-medium text-gray-500">
          {date} {t("support_widget.at")} {time}
        </span>
      </div>
      <div className="text-gray-400">
        <Icon type="16-arrow-right" size={16} />
      </div>
    </a>
  );
};
