import { useCallback } from "react";
import {
  selectIsPeerAudioEnabled,
  selectIsPeerVideoEnabled,
  useHMSActions,
  useHMSStore,
} from "@100mslive/react-sdk";

export const useHMSPeerMediaStatus = peer => {
  const isMicrophoneEnabled = useHMSStore(selectIsPeerAudioEnabled(peer?.id));
  const isCameraEnabled = useHMSStore(selectIsPeerVideoEnabled(peer?.id));
  const hmsActions = useHMSActions();
  const muteMicrophone = useCallback(
    () => hmsActions.setRemoteTrackEnabled(peer?.audioTrack, false),
    [hmsActions, peer?.audioTrack],
  );
  const disableCamera = useCallback(
    () => hmsActions.setRemoteTrackEnabled(peer?.videoTrack, false),
    [hmsActions, peer?.videoTrack],
  );

  return {
    isMicrophoneEnabled,
    isCameraEnabled,
    muteMicrophone,
    disableCamera,
  };
};
