import { useEffect, useMemo, useState } from "react";
import { useCommunitySwitcher } from "@circle-react/hooks/community_switcher/useCommunitySwitcherApi";
import type { CommunitySwitcher } from "@circle-react/types/CommunitySwitcher";
import { SortableList } from "@circle-react-shared/uikit/SortableList";
import { CommunityDragOverlay } from "./CommunityDragOverlay";
import { CommunitySwitcherPresentationV3 } from "./CommunitySwitcherPresentationV3";
import { useSeeMyCommunitiesModal } from "./MyCommunitiesModal";
import { SortableCommunityItem } from "./SortableCommunityItem";

export const MAX_COMMUNITIES_TO_DISPLAY = 5;

export interface CommunitySwitcherContentV3Props {
  isLoadingAppData?: boolean;
}

export const CommunitySwitcherContentV3 = ({
  isLoadingAppData = false,
}: CommunitySwitcherContentV3Props) => {
  const [sortedCommunities, setSortedCommunities] = useState<
    CommunitySwitcher[]
  >([]);
  const {
    communities,
    token,
    isLoadingCommunities,
    isReorderingCommunities,
    onSortCommunities,
  } = useCommunitySwitcher();
  const myCommunitiesModal = useSeeMyCommunitiesModal();

  const isLoading = isLoadingAppData || isLoadingCommunities;
  const shouldShowShowMoreButton =
    communities.length > MAX_COMMUNITIES_TO_DISPLAY;

  const onClickSeeMyCommunities = async () => {
    await myCommunitiesModal.show();
  };

  const onSortHandler = (sortedCommunities: CommunitySwitcher[]) => {
    setSortedCommunities(sortedCommunities);
    onSortCommunities(sortedCommunities, {
      onError: () => {
        setSortedCommunities(communities);
      },
    });
  };

  const extraCommunity = useMemo(() => {
    const currentCommunityIndex = sortedCommunities.findIndex(
      community => community.is_current_community,
    );
    if (currentCommunityIndex >= MAX_COMMUNITIES_TO_DISPLAY) {
      return sortedCommunities[currentCommunityIndex];
    }
    return undefined;
  }, [sortedCommunities]);

  useEffect(() => {
    if (isLoadingCommunities) {
      return;
    }
    setSortedCommunities(communities);
  }, [communities, isLoadingCommunities]);

  return (
    <CommunitySwitcherPresentationV3
      id="community-switcher"
      isLoading={isLoading}
      shouldShowShowMoreButton={shouldShowShowMoreButton}
      onClickSeeMyCommunities={onClickSeeMyCommunities}
    >
      <SortableList
        items={sortedCommunities}
        lockedVerticalAxis
        onSort={onSortHandler}
        shouldUseKeyboardSensor={false}
      >
        <SortableList.List>
          {sortedCommunities
            .slice(0, MAX_COMMUNITIES_TO_DISPLAY)
            .map((community, index) => (
              <SortableCommunityItem
                key={community.id}
                community={community}
                sortedCommunities={sortedCommunities}
                isReorderingCommunities={isReorderingCommunities}
                token={token}
                index={index}
              />
            ))}
          {extraCommunity && (
            <SortableCommunityItem
              key={extraCommunity.id}
              community={extraCommunity}
              sortedCommunities={sortedCommunities}
              isReorderingCommunities={isReorderingCommunities}
              token={token}
              index={sortedCommunities.length - 1}
            />
          )}
        </SortableList.List>
        <SortableList.DragOverlay shouldRestrictToParent>
          <CommunityDragOverlay />
        </SortableList.DragOverlay>
      </SortableList>
    </CommunitySwitcherPresentationV3>
  );
};
