import { usePaywallCheckoutContext } from "@circle-react/contexts/Paywalls/paywallCheckoutContext";
import { Typography } from "@circle-react-uikit/Typography";
import { EventCover } from "./EventCover";
import { EventDetails } from "./EventDetails";
import { PaywallPriceOption } from "./PaywallPriceOption";
import "../styles.scss";

export const PaywallPrice = () => {
  const { paywall, getSelectedPrice, setSelectedPrice } =
    usePaywallCheckoutContext();

  const onPriceChangeHandler = (priceId: any) => {
    setSelectedPrice(priceId);
  };

  const selectedPrice = getSelectedPrice();

  const isSelectedPrice = (price: any) =>
    selectedPrice && selectedPrice.id == price.id;

  let title: string;
  if (paywall.event?.name) {
    title = paywall.event.name;
  } else {
    title = paywall.display_name;
  }

  let description: string | undefined;
  if (paywall.description) {
    description = paywall.description;
  } else if (paywall.event?.body) {
    description = paywall.event?.body;
  }

  // TODO:
  // In the future, our checkout system will rely on a paywall's contents association (polymorphic) to
  // customize the checkout page as we are doing for events. For now, we are using the event association
  // directly to display the event details.

  return (
    <div className="flex flex-col gap-8">
      <EventCover event={paywall.event} />
      <div
        className="flex flex-col gap-3"
        data-testid="checkout-paywall-detail"
      >
        <Typography.TitleLg>{title}</Typography.TitleLg>
        <EventDetails event={paywall.event} />
        {description && (
          <Typography.BodyMd>
            <span className="whitespace-pre-wrap">{description}</span>
          </Typography.BodyMd>
        )}
      </div>
      <div className="flex flex-col gap-4">
        {(paywall.prices || []).map((price: any) => (
          <PaywallPriceOption
            key={`paywall_price-${price.id}`}
            id={price.id}
            title={price.title}
            subtitle={price.subtitle}
            value={price.value}
            isSelected={isSelectedPrice(price)}
            onSelect={onPriceChangeHandler}
          />
        ))}
      </div>
    </div>
  );
};
