import ReactConfetti from "react-confetti";
import type { Props as ReactConfettiProps } from "react-confetti";
import { useWindowSize } from "react-use";

export interface FullScreenConfettiProps extends ReactConfettiProps {
  shouldCelebrate?: boolean;
}

export const FullScreenConfetti = ({
  shouldCelebrate = false,
  ...props
}: FullScreenConfettiProps) => {
  const { width, height } = useWindowSize();

  if (!shouldCelebrate) {
    return null;
  }

  return (
    <ReactConfetti
      width={width}
      height={height}
      style={{
        zIndex: 999999,
      }}
      recycle={false}
      tweenDuration={5000}
      numberOfPieces={300}
      {...props}
    />
  );
};
