export const STATUS_OPTIONS_MAP = {
  INBOX: "inbox",
  APPROVED: "approved",
  REJECTED: "rejected",
} as const;

export const MODERATION_TABS = ["inbox", "approved", "rejected"];

export const TAB_QUERY_NAME = "status";

export const CONTENT_TYPES_MAP = {
  POST: "post",
  COMMENT: "comment",
};
