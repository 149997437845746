import { useEffect } from "react";
import PropTypes from "prop-types";
import { isFunction } from "lodash";
import { useLayoutContext } from "@/react/components/SettingsApp/Layout/LayoutContext";

export const SetLayoutConfig = props => {
  const { mergeConfig, resetToDefault } = useLayoutContext();

  useEffect(() => {
    isFunction(mergeConfig) && mergeConfig(props);
    return () => {
      isFunction(resetToDefault) && resetToDefault();
    };
    // This hook needs to only run once. If we add deps to the hook then it goes into infinite update issue.
    // pass key to SetLayoutConfig if updating props.
  }, []);

  return null;
};

SetLayoutConfig.propTypes = {
  disableRightSidebar: PropTypes.bool.isRequired,
  renderLeftSidebar: PropTypes.func,
  disableSidebar: PropTypes.func,
};
