import { useModal } from "@circle-react/components/Layout/ModalManagerProvider";
import { FilterContextProvider } from "@circle-react/components/Modals/SearchResultsModal/FilterBar/FilterContext";
import { SortContextProvider } from "@circle-react/components/Modals/SearchResultsModal/SortContext";
import { SearchResultsModal } from "./SearchResultsModal";

export interface SearchResultModalComponentProps {
  searchValue: string;
}

export const SearchResultModalComponent = ({
  searchValue,
}: SearchResultModalComponentProps) => {
  const drawer = useModal();

  return (
    <FilterContextProvider>
      <SortContextProvider>
        <SearchResultsModal
          isOpen={drawer.visible}
          onClose={drawer.remove}
          defaultSearchValue={searchValue}
        />
      </SortContextProvider>
    </FilterContextProvider>
  );
};
