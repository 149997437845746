import PropTypes from "prop-types";
import "./styles.scss";

export const FullScreenHeader = ({ title, beforeTitle, afterTitle }) => (
  <div className="react-layout-fullscreen-header">
    <div className="react-layout-fullscreen-wrapper">
      {beforeTitle && (
        <div className="react-layout-fullscreen-header-before">
          {beforeTitle}
        </div>
      )}
      <div className="react-layout-fullscreen-header-heading">
        <h1 className="heading-h1">{title}</h1>
        {afterTitle}
      </div>
    </div>
  </div>
);

FullScreenHeader.propTypes = {
  title: PropTypes.node,
  beforeTitle: PropTypes.node,
  afterTitle: PropTypes.node,
};
