import { usePunditUserContext } from "@circle-react/contexts";
import { STATUS as PaymentProcessorStatus } from "@circle-react/helpers/paymentProcessorHelpers";

export const useCommunityPaymentProcessorStatus = () => {
  const { currentCommunity, currentCommunitySettings } = usePunditUserContext();

  const paymentProcessor = currentCommunity?.payment_processor;

  const isPaymentProcessorEnabled =
    paymentProcessor &&
    paymentProcessor.status === PaymentProcessorStatus.ENABLED &&
    (!paymentProcessor.is_restricted_for_application_fees ||
      currentCommunitySettings?.paywalls_for_restricted_country_enabled);

  return {
    isPaymentProcessorEnabled,
  };
};
