import { useFormContext } from "react-hook-form";
import { Tabs } from "@circle-react-uikit/Tabs";
import { TABS } from "./constants";

const Navigation = ({ activeTab, setActiveTab }) => {
  const {
    reset,
    formState: { dirtyFields },
  } = useFormContext();

  // isDirty method from react-hook-form behaves more like isTouched.
  // It gives false positives when an input is focused multiple times.
  // When we have an input focus on mount, isDirty is incorrectly set to true.
  const isDirty = !!Object.keys(dirtyFields).length;

  const handleTabClick = tab => {
    if (isDirty) {
      if (
        confirm(
          "Are you sure you want to navigate away from this page?\nAll your unsaved changes will be lost.",
        )
      ) {
        reset();
        setActiveTab(tab);
      }
    } else {
      setActiveTab(tab);
    }
  };
  return (
    <Tabs
      wrapperClassName="px-6"
      controlled
      activeTab={activeTab}
      onClick={handleTabClick}
      options={Object.values(TABS).map(tab => ({ label: tab }))}
      variant="underline"
    />
  );
};

export default Navigation;
