import { SortableElement } from "react-sortable-hoc";
import { usePunditUserContext } from "@circle-react/contexts";
import { useSpaceNotificationsState } from "@circle-react/contexts/sidebarNotificationsContext";
import { useSpaceLinkWithDefaultTab } from "@circle-react/helpers/spaceHelpers";
import { SpaceView } from "./SpaceView";

const Space = SortableElement(({ space }) => {
  const { currentCommunityMember } = usePunditUserContext();
  const getSpaceLinkWithDefaultTab = useSpaceLinkWithDefaultTab();
  const buttonLink = getSpaceLinkWithDefaultTab(space, currentCommunityMember);

  const { spaces: spaceNotifications } = useSpaceNotificationsState();
  const spaceNotificationDetail = spaceNotifications.find(
    spaceItem => spaceItem.id === space.id,
  );

  return (
    <SpaceView
      space={space}
      buttonLink={buttonLink}
      spaceNotificationDetail={spaceNotificationDetail}
    />
  );
});

export default Space;
