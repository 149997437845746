import { t } from "i18n-js";
import { signOutPath } from "@/react/helpers/urlHelpers";
import { usePaywallCheckoutContext } from "@circle-react/contexts/Paywalls/paywallCheckoutContext";
import { UserImage } from "@circle-react-shared/uikit/UserImage";
import { Typography } from "@circle-react-uikit/Typography";

export const CheckoutFormAccountDetails = () => {
  const { currentUser, currentCommunityMember } = usePaywallCheckoutContext();
  const user = currentCommunityMember || currentUser;

  if (!currentUser) {
    return null;
  }

  return (
    <div
      className="flex flex-row items-center gap-x-2 rounded-lg border border-gray-200 bg-gray-50 px-2.5 py-2"
      data-testid="checkout-form-account-details"
    >
      <UserImage src={user.avatar_url} name={user.name} size="10" />
      <div className="flex w-full flex-col justify-between">
        <div className="flex flex-row items-center justify-between">
          <Typography.LabelSm
            weight="medium"
            data-testid="checkout-form-account-details-name"
          >
            {user.name}
          </Typography.LabelSm>

          <Typography.LabelXs weight="medium">
            <a
              className="!text-dark cursor-pointer"
              href={`${signOutPath()}?redirect_back_url=${
                window.location.href
              }`}
            >
              {t("logout")}
            </a>
          </Typography.LabelXs>
        </div>

        <Typography.LabelXs
          weight="medium"
          color="text-default"
          data-testid="checkout-form-account-details-email"
        >
          {user.email}
        </Typography.LabelXs>
      </div>
    </div>
  );
};
