import { t } from "i18n-js";
import { head } from "lodash";
import { useCommunityMembersList } from "@circle-react/hooks/useCommunityMembersList";
import { UserImage } from "@circle-react-shared/uikit/UserImage";

export interface AvatarProps {
  communityMemberId: any;
  withDeleted?: boolean;
}

export const Avatar = ({
  communityMemberId,
  withDeleted = true,
}: AvatarProps) => {
  const { data: communityMembers, isLoading } = useCommunityMembersList({
    ids: [communityMemberId],
    withDeleted,
    enabled: !!communityMemberId,
  });

  if (isLoading) {
    <span className="bg-tertiary block h-4 w-full animate-pulse rounded" />;
  }

  const communityMember: any = head(communityMembers?.records);

  if (!communityMember) {
    return <span className="text-sm">{t("deactivated_member")}</span>;
  }

  const { name, avatar_url: src, is_deleted: isDeleted } = communityMember;

  if (isDeleted) {
    return (
      <span className="break-normal text-sm">
        {t("deactivated_member")} ({name})
      </span>
    );
  }

  return (
    <>
      <span className="pl-2">
        <div className="float-left">
          <UserImage size="6" src={src} name={name} />
        </div>
      </span>
      <span className="break-normal text-sm">{name}</span>
    </>
  );
};
