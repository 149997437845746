import type {
  CSSProperties,
  ComponentPropsWithoutRef,
  MouseEvent,
} from "react";
import classNames from "classnames";
import { t } from "i18n-js";
import { isFunction } from "lodash";
import { MOBILE_BOTTOM_TOOLBAR_ID } from "@circle-react/components/constants";
import { Icon } from "@circle-react-shared/Icon";
import { BottomBarLink } from "../BottomBarLink";
import { CountBadge } from "../CountBadge";
import { NewPostLink } from "./NewPostLink";

export interface MobileBottomToolbarV2ViewProps
  extends ComponentPropsWithoutRef<"div"> {
  className?: string;
  newPostUrl?: string;
  newPostUseDataRemote?: boolean;
  onHomeClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  onNotificationsClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  onSearchClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  shouldNewPostOpenModal?: boolean;
  shouldShowMessagingRoutes?: boolean;
  style?: CSSProperties;
  unreadChatCount?: number;
  unreadNotificationCount?: number;
}

export const MobileBottomToolbarV2View = ({
  className = "",
  newPostUrl = "",
  newPostUseDataRemote = false,
  onHomeClick,
  onNotificationsClick,
  onSearchClick,
  shouldNewPostOpenModal = false,
  shouldShowMessagingRoutes = false,
  style = {},
  unreadChatCount = 0,
  unreadNotificationCount = 0,
  ...rest
}: MobileBottomToolbarV2ViewProps) => (
  <div
    id={MOBILE_BOTTOM_TOOLBAR_ID}
    className={classNames("bg-primary fixed bottom-0 z-10 w-full", className)}
    style={{
      boxShadow: "0px 0px 10px 0px rgb(0 0 0 / 11%)",
      ...style,
    }}
    {...rest}
  >
    <div className="flex items-center">
      <button
        type="submit"
        onClick={event => isFunction(onHomeClick) && onHomeClick(event)}
        className="text-dark flex w-full cursor-pointer justify-center p-5"
      >
        <Icon
          aria-label={t("mobile_nav.home")}
          type="home"
          size={24}
          useWithStrokeCurrentColor
        />
      </button>
      <BottomBarLink
        onClick={event => isFunction(onSearchClick) && onSearchClick(event)}
      >
        <Icon
          aria-label={t("mobile_nav.search")}
          type="search"
          size={24}
          useWithFillCurrentColor
        />
      </BottomBarLink>
      <NewPostLink
        newPostUseDataRemote={newPostUseDataRemote}
        newPostUrl={newPostUrl}
        shouldNewPostOpenModal={shouldNewPostOpenModal}
      />
      <BottomBarLink
        onClick={event =>
          isFunction(onNotificationsClick) && onNotificationsClick(event)
        }
      >
        <div className="relative">
          <Icon
            aria-label={t("mobile_nav.notifications")}
            type="bell"
            size={24}
            useWithFillCurrentColor
          />
          {unreadNotificationCount > 0 && (
            <CountBadge>{unreadNotificationCount}</CountBadge>
          )}
        </div>
      </BottomBarLink>
      {shouldShowMessagingRoutes && (
        <BottomBarLink to="/messages">
          <div className="relative">
            <Icon
              aria-label={t("mobile_nav.messages")}
              type="dm"
              size={24}
              useWithFillCurrentColor
            />
            {unreadChatCount > 0 && <CountBadge>{unreadChatCount}</CountBadge>}
          </div>
        </BottomBarLink>
      )}
    </div>
  </div>
);
