import { getStatPercentage } from "@circle-react/components/SettingsApp/Emails/components/helper";
import { toLocaleString } from "@circle-react/helpers/number";
import { Typography } from "@circle-react-shared/uikit/Typography";

interface StatBoxProps {
  value: number | null;
  total: number | null;
  label: string;
}

export function StatBox({ value, total, label }: StatBoxProps) {
  return (
    <div className="flex flex-1 flex-col gap-y-1.5">
      <Typography.LabelMd weight="bold" color="text-very-dark">
        {getStatPercentage({ total, value })}
      </Typography.LabelMd>
      <div className="flex justify-center gap-x-1">
        <Typography.LabelXs weight="medium" color="text-default">
          {label}
        </Typography.LabelXs>
        <Typography.LabelXs weight="medium" color="text-default">
          {value ? toLocaleString(value) : 0}
        </Typography.LabelXs>
      </div>
    </div>
  );
}
