import { useState } from "react";
import PropTypes from "prop-types";
import { t } from "i18n-js";
import { ToggleSwitch } from "@circle-react-uikit/ToggleSwitchWithLabel";
import { Typography } from "@circle-react-uikit/Typography";
import { useBulkImportTrialPaywallSubscriptionFormContext } from "./BulkImportTrialPaywallSubscriptionFormProvider";

export const NotifyMemberForm = ({ disabled = false }) => {
  const { hideNotifyMemberForm } =
    useBulkImportTrialPaywallSubscriptionFormContext();
  const [notifyMember, setNotifyMember] = useState(false);
  const onChange = () => {
    setNotifyMember(!notifyMember);
  };

  if (hideNotifyMemberForm) {
    return null;
  }

  return (
    <div className="flex flex-col gap-y-4 pt-8">
      <Typography.LabelSm weight="semibold">
        {t("bulk_import_page.paywall.notify_member")}
      </Typography.LabelSm>
      <div className="flex flex-row gap-x-4 py-2">
        <ToggleSwitch
          field="paywall[paywall_send_notification_subscription_created_by_admin]"
          disabled={disabled}
          value={notifyMember}
          onChange={onChange}
        />
        <div className="flex flex-col">
          <span>{t("bulk_import_page.paywall.notify_member_description")}</span>
        </div>
      </div>
    </div>
  );
};

NotifyMemberForm.propTypes = {
  disabled: PropTypes.bool,
};
