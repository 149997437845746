import { useMutation } from "react-query";
import { reactQueryDelete } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";

export const useRemoveMemberFromSpaceGroup = options => {
  const mutation = ({ spaceGroupId, memberId }) =>
    reactQueryDelete(
      internalApi.spaceGroups.deleteMember({
        spaceGroupId,
        memberId,
      }),
    );

  return useMutation(mutation, options);
};
