import { AddressElement } from "@stripe/react-stripe-js";
import { usePaywallCheckoutContext } from "@circle-react/contexts/Paywalls/paywallCheckoutContext";
import { stripeAddressElementOptions } from "../../StripeElements/stripeElementsOptions";
import { CheckoutFormField } from "./CheckoutFormField";
import { CheckoutFormFieldWrapper } from "./CheckoutFormFieldWrapper";

export const CheckoutFormBillingInfoFields = () => {
  const {
    currentUser,
    isMemberAddressRequired: isMemberAddressRequired,
    setMemberAddressAttributes,
  } = usePaywallCheckoutContext();

  if (!isMemberAddressRequired && currentUser) return null;

  if (isMemberAddressRequired) {
    return (
      <AddressElement
        options={stripeAddressElementOptions}
        onChange={setMemberAddressAttributes}
      />
    );
  }
  return (
    <CheckoutFormFieldWrapper name="name">
      <CheckoutFormField type="text" name="name" options={{ required: true }} />
    </CheckoutFormFieldWrapper>
  );
};
