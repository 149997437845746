import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { uniqueId } from "lodash";
import { TipTapBlockEditor } from "@circle-react-uikit/TipTapBlockEditor";

export const DescriptionBlock = ({
  description_tiptap = {},
  sgids_to_object_map = {},
  inline_attachments = [],
}) => {
  /*
    This unique id as key forces the editor to re-render when
    description_tiptap changes, since the "value" prop from
    TipTapBlockEditor is only used on the initial mount
  */
  const [key, setKey] = useState(uniqueId("description_block_"));

  useEffect(() => {
    setKey(uniqueId("description_block_"));
  }, [description_tiptap]);

  return (
    <div key={key} className="bg-primary flex w-full flex-col items-center">
      <div className="w-full max-w-[960px] px-6">
        <TipTapBlockEditor
          readOnly
          value={description_tiptap}
          sgidToObjectMap={sgids_to_object_map}
          inlineAttachments={inline_attachments}
          editorClassName="break-words"
          disabledExtensions={["mention", "poll", "ai_copilot"]}
        />
      </div>
    </div>
  );
};

DescriptionBlock.propTypes = {
  description_tiptap: PropTypes.object,
  sgids_to_object_map: PropTypes.object,
  inline_attachments: PropTypes.array,
};
