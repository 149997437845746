import type { ReactNode } from "react";
import { createContext, useContext, useState } from "react";
import { LeftSidebar } from "@/react/components/SettingsApp/LeftSidebar";

export interface LayoutContextProps {
  config: any;
  setConfig: (config: any) => void;
}

const LayoutContext = createContext<LayoutContextProps>({
  config: {},
  setConfig: () => {},
});
LayoutContext.displayName = "LayoutContext";

const defaults = {
  disableRightSidebar: false,
  renderLeftSidebar: () => <LeftSidebar />,
  disableSidebar: false,
  hideAppBar: false,
};

export const LayoutContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [config, setConfig] = useState(defaults);

  const resetToDefault = () => {
    setConfig(defaults);
  };
  const mergeConfig = (updates = {}) => {
    setConfig({ ...config, ...updates });
  };

  const value = { config, setConfig, resetToDefault, mergeConfig };
  return (
    <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
  );
};

export const useLayoutContext = () => useContext(LayoutContext);
