import type { SearchType } from "@/react/components/search/types";
import {
  Avatar,
  AvatarContainer,
  Row,
  RowContainer,
} from "@circle-react-uikit/SkeletonLoader";

export const SearchResultsModalSkeleton = ({
  type,
  numberOfRecords = 1,
}: {
  type: SearchType;
  numberOfRecords?: number;
}) => {
  const renderSkeleton = (_: any, index: number) => {
    switch (type) {
      case "posts" || "events":
        return (
          <div key={index}>
            <AvatarContainer lg>
              <Avatar sm />
              <RowContainer>
                <Row sm />
              </RowContainer>
            </AvatarContainer>
            <div className="mt-2.5">
              <RowContainer md>
                <Row md full />
                <Row md full />
                <Row md almostFull />
              </RowContainer>
            </div>
          </div>
        );
      case "lessons":
        return (
          <div key={index}>
            <AvatarContainer sm>
              <Avatar sm />
              <RowContainer>
                <Row sm />
              </RowContainer>
            </AvatarContainer>
            <div className="mt-2.5">
              <RowContainer md>
                <Row md full />
                <Row md almostFull />
              </RowContainer>
            </div>
          </div>
        );
      case "comments":
        return (
          <div key={index} className="flex items-center gap-8">
            <AvatarContainer sm>
              <Avatar sm />
              <RowContainer>
                <Row sm full />
                <Row sm />
              </RowContainer>
            </AvatarContainer>
            <div className="mt-2.5">
              <RowContainer md>
                <Row md full />
                <Row md almostFull />
              </RowContainer>
            </div>
          </div>
        );
      case "members":
        return (
          <AvatarContainer key={index} lg>
            <Avatar />
            <RowContainer>
              <Row sm />
              <Row sm />
            </RowContainer>
          </AvatarContainer>
        );
    }

    return null;
  };

  return (
    <div className="mt-6 flex flex-col gap-8">
      {Array.from({ length: numberOfRecords }).map(renderSkeleton)}
    </div>
  );
};
