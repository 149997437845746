import PropTypes from "prop-types";
import classnames from "classnames";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-uikit/Typography";
import { Action } from "../Action";
import { useActionGroup } from "./useActionGroup";

const iconSizes = {
  md: 16,
  lg: 24,
};

export const ActionGroup = ({
  actions: actionsProp = [],
  size = "lg",
  truncationLimit = 2,
  className,
}) => {
  const { actions, remainingActionsCount, remainingItemsTooltip } =
    useActionGroup({
      actions: actionsProp,
      truncationLimit,
    });

  const iconSize = iconSizes[size] || iconSizes.lg;

  if (actionsProp.length === 0 || truncationLimit < 1) {
    return <div className="py-[25px] leading-none">-</div>;
  }

  const remainingText = `+${remainingActionsCount}`;

  return (
    <div
      className={classnames(
        "flex items-center",
        {
          "gap-1": size === "md",
          "gap-2": size === "lg",
        },
        className,
      )}
    >
      {actions.map(({ title, icon }, index) => (
        <Action tooltipContent={title} key={`${icon}-${index}`} size={size}>
          <Icon
            type={`${iconSize}-workflow-${icon}`}
            size={iconSize}
            useWithFillCurrentColor
            className={classnames({
              "!w-4": size === "md",
              "!w-6": size === "lg",
            })}
          />
        </Action>
      ))}
      {remainingActionsCount > 1 && (
        <Action tooltipContent={remainingItemsTooltip} size={size}>
          <div className="flex items-center">
            <Typography.LabelSm weight="semibold">
              <span
                className={classnames("text-default flex", {
                  "text-xxs": size === "md",
                })}
              >
                {remainingText}
              </span>
            </Typography.LabelSm>
          </div>
        </Action>
      )}
    </div>
  );
};

ActionGroup.propTypes = {
  actions: PropTypes.array,
  size: PropTypes.oneOf(["md", "lg"]),
  truncationLimit: PropTypes.number,
  shouldUseTooltip: PropTypes.bool,
  className: PropTypes.string,
};
