import { t } from "i18n-js";
import { PaywallCouponBadge } from "@circle-react/components/Paywalls/PaywallCouponBadge";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { Button } from "@circle-react-uikit/Button";
import { Input } from "@circle-react-uikit/Input";
import { useChangePlanFormContext } from "../ChangePlanFormContext";
import { ChangePlanFormLabelGroup } from "../ChangePlanFormLabelGroup";
import { useCoupon } from "../hooks/useCoupon";

export const Coupon = () => {
  const { priceChangePreview } = useChangePlanFormContext();

  const {
    enableCoupon: isCouponEnabled,
    toggleAddCoupon,
    handleOnClickCouponValidation,
    handleOnClickRemoveCoupon,
    reactHook,
  } = useCoupon();

  const { paywall_coupon_code } = priceChangePreview;
  const { register } = reactHook;

  if (!isCouponEnabled) {
    return (
      <Button
        type="button"
        onClick={toggleAddCoupon}
        variant="link"
        className="mb-3 !px-0 no-underline hover:no-underline"
        small
      >
        <Typography.LabelSm weight="medium" color="text-link">
          {t(
            "member_profile_modal.billing.subscriptions.confirmation_step.coupon.add_link",
          )}
        </Typography.LabelSm>
      </Button>
    );
  }

  if (!paywall_coupon_code) {
    return (
      <div className="mb-3 flex flex-col items-start gap-y-1">
        <ChangePlanFormLabelGroup
          label={t(
            "member_profile_modal.billing.subscriptions.confirmation_step.coupon.field_label",
          )}
          labelSize="sm"
          labelWeight="semibold"
        />
        <div className="flex w-full flex-row gap-x-1">
          <Input
            variant="text"
            {...register("new_coupon_code")}
            suffix={
              <Icon
                type="20-close"
                size={20}
                className="cursor-pointer"
                onClick={toggleAddCoupon}
              />
            }
          />
          <Button
            type="button"
            onClick={handleOnClickCouponValidation}
            variant="tertiary"
          >
            <Typography.LabelSm weight="medium">
              {t(
                "member_profile_modal.billing.subscriptions.confirmation_step.coupon.activate",
              )}
            </Typography.LabelSm>
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="mb-3">
      <PaywallCouponBadge
        couponCode={paywall_coupon_code}
        onRemoveCoupon={handleOnClickRemoveCoupon}
      />
    </div>
  );
};
