import { useQuery } from "react-query";
import { internalApi } from "@/react/helpers/urlHelpers";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";

export const useFetchSpaceGroup = ({ id, options = {} }) =>
  useQuery(
    ["space-group", id],
    () => reactQueryGet(internalApi.spaceGroups.show({ id })),
    {
      enabled: !!id,
      ...options,
    },
  );
