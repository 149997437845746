import { useItemData } from "@circle-react-shared/BlockWithConnectorItem/ItemDataProvider";
import { Icon } from "@circle-react-shared/Icon";
import { Dropdown } from "@circle-react-uikit/Dropdown";
import { StatusIcon } from "./StatusIcon";

export interface ActionsProps {
  hasSuccess?: boolean;
  hasError?: boolean;
  statusMessage?: string;
  actions?: any[];
  children?: any;
}

export const Actions = ({
  hasSuccess,
  hasError,
  statusMessage,
  actions,
  children,
}: ActionsProps) => {
  const { isOpen } = useItemData();

  return (
    <div className="flex items-center gap-3 px-8 pl-4 pr-6">
      {!isOpen && children}
      <StatusIcon
        hasSuccess={hasSuccess}
        hasError={hasError}
        statusMessage={statusMessage}
      />
      {actions && (
        <Dropdown
          buttonWrapperClassName="flex"
          linkType="button"
          button={
            <Dropdown.MenuButton className="hover:bg-tertiary rounded !p-0.5 transition-colors">
              <span className="flex items-center">
                <Icon
                  type="20-menu-dots-horizontal"
                  size={20}
                  className="text-default"
                />
              </span>
            </Dropdown.MenuButton>
          }
          options={actions}
        />
      )}
    </div>
  );
};
