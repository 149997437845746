import { useAddSpaceModal } from "@/react/components/Modals/AddSpaceModal";
import { AddSpaceButtonView } from "./AddSpaceButtonView";

export interface AddSpaceButtonProps {
  spaceGroupId: number;
}

export const AddSpaceButton = ({ spaceGroupId }: AddSpaceButtonProps) => {
  const spaceModal = useAddSpaceModal();

  const toggleSpaceModal = () => {
    void spaceModal.show({
      spaceGroupId,
    });
  };

  return <AddSpaceButtonView onClick={toggleSpaceModal} />;
};
