import PropTypes from "prop-types";
import {
  isBasicPostsSpace,
  isChatSpace,
} from "@circle-react/helpers/spaceHelpers";
import { Labels } from "./Labels";
import Moderators from "./Moderators";
import Permissions from "./Permissions";
import SEO from "./SEO";
import Sort from "./Sort";

const Options = ({ setImageToDelete, space }) => (
  <div className="edit-space__container edit-space__container--no-padding">
    {!isChatSpace(space) && <Moderators />}
    <Permissions space={space} />
    {isBasicPostsSpace(space) && <Labels />}
    <SEO setImageToDelete={setImageToDelete} />
    {!isChatSpace(space) && <Sort space={space} />}
  </div>
);

Options.propTypes = {
  space: PropTypes.object,
  setImageToDelete: PropTypes.func,
};

export default Options;
