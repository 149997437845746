import { useMemo } from "react";
import { isCommunityAdmin } from "@/react/helpers/communityMemberHelpers";
import { usePunditUserContext } from "@circle-react/contexts";
import { useCommunitySettingShow } from "@circle-react/hooks/UseCommunitySetting";
import { useMembersInfiniteQuery } from "@circle-react-shared/MembersList/CommunityMembers/hooks/useMembersInfiniteQuery";
import { SORT_OPTIONS } from "@circle-react-shared/MembersList/constants";

const defaultSort = SORT_OPTIONS[0];

export const useMembersAvatarRow = scopeResults => {
  const { currentCommunityMember, currentUser } = usePunditUserContext();
  const { data, isLoading: isLoadingCommunitySettings } =
    useCommunitySettingShow();
  const communitySetting = data?.community_settings;

  const { members, isLoading: isLoadingMembers } = useMembersInfiniteQuery({
    defaultSort,
    scopeResults,
    per_page: 3,
  });

  const isLoading = useMemo(
    () => isLoadingCommunitySettings || isLoadingMembers,
    [isLoadingCommunitySettings, isLoadingMembers],
  );

  const canSeeMembersDirectory = useMemo(() => {
    const isMemberDirectoryPublic = communitySetting?.public_member_directory;
    const isUserAdmin = isCommunityAdmin(currentCommunityMember);
    return isMemberDirectoryPublic || isUserAdmin;
  }, [communitySetting, currentCommunityMember, currentUser]);

  const canSeeMembersAvatarRow = useMemo(() => currentUser, [currentUser]);

  return {
    members,
    isLoading,
    canSeeMembersDirectory,
    canSeeMembersAvatarRow,
  };
};
