import type { RefObject } from "react";
import { useLayoutEffect, useState } from "react";

export interface UseExpandableCodeProps {
  containerRef: RefObject<HTMLDivElement>;
}

export const useExpandableCode = ({ containerRef }: UseExpandableCodeProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isTruncationEnabled, setIsTruncationEnabled] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(previous => !previous);
  };

  useLayoutEffect(() => {
    const containerEl = containerRef?.current;
    if (!containerEl) return;

    const codeEl = containerEl.querySelector("pre");
    if (!codeEl) return;

    const { clientHeight: containerHeight } = containerEl;
    const { clientHeight: codeHeight } = codeEl;

    if (codeHeight > containerHeight) {
      setIsTruncationEnabled(true);
    }
  }, [containerRef]);

  return {
    isTruncationEnabled,
    isExpanded,
    toggleExpand,
  };
};
