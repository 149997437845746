import PropTypes from "prop-types";
import { QueryClient, QueryClientProvider } from "react-query";
import { ModalManagerProvider } from "@circle-react/components/Layout/ModalManagerProvider";
import { SpaceGroupsContextProvider } from "@circle-react/contexts/spaceGroupsContext";
import { SpacesContextProvider } from "@circle-react/contexts/spacesContext";
import { reactQueryConfig } from "@circle-react/providers/QueryClient/reactQueryConfig";
import { ToastProvider } from "@circle-react-uikit/ToastV2";
import { Header } from "./Header";
import { List } from "./List";
import { PaymentProcessorEnabled } from "./PaymentProcessorStatus/PaymentProcessorEnabled";
import { PaymentProcessorNotExist } from "./PaymentProcessorStatus/PaymentProcessorNotExist";
import { PaymentProcessorRestricted } from "./PaymentProcessorStatus/PaymentProcessorRestricted";
import { PaymentProcessorRestrictedFeesToApply } from "./PaymentProcessorStatus/PaymentProcessorRestrictedFeesToApply";
import { PaymentProcessorWithStatus } from "./PaymentProcessorStatus/PaymentProcessorWithStatus";
import { PaymentProcessorStatus } from "./constants";
import { usePaywallsAdmin } from "./hooks/usePaywallsAdmin";

const queryClient = new QueryClient(reactQueryConfig);

export const PaywallsComponent = ({
  paymentProcessor,
  paywallGroupsEnabled,
  shouldHideHeader = false,
}) => {
  const {
    paymentProcessorStatus,
    paywalls,
    refetchPaywalls,
    isPaywallsLoading,
  } = usePaywallsAdmin({ paymentProcessor });

  const casesByPaymentProcessorStatus = {
    [PaymentProcessorStatus.NOT_EXIST]: () => null,
    [PaymentProcessorStatus.REJECTED]: () => (
      <PaymentProcessorWithStatus status="rejected" />
    ),
    [PaymentProcessorStatus.PENDING]: () => (
      <PaymentProcessorWithStatus status="pending" />
    ),
    [PaymentProcessorStatus.RESTRICED_FOR_APPLICATION_FEES]: () => (
      <PaymentProcessorRestrictedFeesToApply />
    ),
    [PaymentProcessorStatus.RESTRICTED]: () => <PaymentProcessorRestricted />,
    [PaymentProcessorStatus.ENABLED]: () => (
      <PaymentProcessorEnabled paymentProcessor={paymentProcessor} />
    ),
    [PaymentProcessorStatus.NOT_EXIST]: () => <PaymentProcessorNotExist />,
  };
  const renderPaymentProcessorStatus =
    casesByPaymentProcessorStatus[paymentProcessorStatus];

  return (
    <>
      {!shouldHideHeader && (
        <Header
          refetchPaywalls={refetchPaywalls}
          paymentProcessorStatus={paymentProcessorStatus}
          paywallGroupsEnabled={paywallGroupsEnabled}
        />
      )}
      {renderPaymentProcessorStatus()}
      {paymentProcessorStatus === PaymentProcessorStatus.ENABLED && (
        <List
          refetchPaywalls={refetchPaywalls}
          paywalls={paywalls ?? []}
          isPaywallsLoading={isPaywallsLoading}
          paywallGroupsEnabled={paywallGroupsEnabled}
        />
      )}
    </>
  );
};

export const PaywallsAdmin = ({ paymentProcessor, paywallGroupsEnabled }) => (
  <SpaceGroupsContextProvider>
    <SpacesContextProvider>
      <ToastProvider>
        <QueryClientProvider client={queryClient}>
          <ModalManagerProvider>
            <PaywallsComponent
              paymentProcessor={paymentProcessor}
              paywallGroupsEnabled={paywallGroupsEnabled}
            />
          </ModalManagerProvider>
        </QueryClientProvider>
      </ToastProvider>
    </SpacesContextProvider>
  </SpaceGroupsContextProvider>
);

PaywallsComponent.propTypes = {
  paymentProcessor: PropTypes.object.isRequired,
  paywallGroupsEnabled: PropTypes.bool.isRequired,
  shouldHideHeader: PropTypes.bool.isRequired,
};

PaywallsAdmin.propTypes = {
  paymentProcessor: PropTypes.object.isRequired,
  paywallGroupsEnabled: PropTypes.bool.isRequired,
};
