import { t } from "i18n-js";
import { usePaywalls } from "@circle-react/components/PaywallCoupons/Admin/FormFields/usePaywalls";
import { FormTextSelect } from "@circle-react-uikit/Form/FormTextSelect";
import { Loader } from "@circle-react-uikit/Loader";
import { Typography } from "@circle-react-uikit/Typography";
import { useCurrentAction } from "../../../ActionRule";

const i18nRoot = "settings.workflows.form";

export const FormSelectPaywall = () => {
  const { paramsPath } = useCurrentAction();
  const { activePaywalls, isLoading, isSuccess } = usePaywalls();

  const options = activePaywalls.map(({ id, name, display_name }) => ({
    value: id,
    label: name || display_name,
  }));

  if (isLoading) {
    return <Loader center />;
  }

  if (!isSuccess) {
    return (
      <Typography.LabelXs>
        <span className="text-v2-danger">
          {t([i18nRoot, "paywalls.fetch_failed"])}
        </span>
      </Typography.LabelXs>
    );
  }

  return (
    <FormTextSelect
      name={`${paramsPath}.paywall_id`}
      label={
        <Typography.LabelSm weight="semibold">
          {t([i18nRoot, "select_paywall"])}
        </Typography.LabelSm>
      }
      options={options}
      rules={{
        required: t([i18nRoot, "errors.required"]),
      }}
      emptyMessage={t([i18nRoot, "errors.paywalls.not_found"])}
    />
  );
};

FormSelectPaywall.propTypes = {};
