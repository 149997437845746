import type { ComponentPropsWithoutRef } from "react";
import classnames from "classnames";
import { t } from "i18n-js";
import { RailbarContainer } from "./RailbarContainer";

export interface ContentProps extends ComponentPropsWithoutRef<"div"> {
  fullWidth?: boolean;
  noPadding?: boolean;
  fullHeight?: boolean;
  disableSidebar?: boolean;
}

export const Content = ({
  children,
  fullWidth,
  fullHeight = true,
  noPadding,
  ...props
}: ContentProps) => (
  <main
    aria-label={t("main_content")}
    {...props}
    className={classnames("standard-layout-v2__content bg-secondary", {
      "standard-layout-v2__content--full": fullWidth,
      "standard-layout-v2__content--no-padding": noPadding,
      "h-full": fullHeight,
    })}
  >
    <div
      className={classnames("bg-secondary", {
        "h-full": fullHeight,
      })}
    >
      {children}
    </div>
    <RailbarContainer id="rail-bar-content" />
  </main>
);
