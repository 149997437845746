import type { ReactNode } from "react";
import classNames from "classnames";
import { useIsV3 } from "@circle-react/hooks/useIsV3";

export interface SpaceGroupListViewProps {
  children: ReactNode;
}

export const SpaceGroupListView = ({ children }: SpaceGroupListViewProps) => {
  const { isV3Enabled } = useIsV3();
  return (
    <div
      className={classNames("bg-c-sidebar mt-4 flex flex-col px-3", {
        "space-y-4": !isV3Enabled,
        "gap-y-6 lg:mt-6 lg:px-6": isV3Enabled,
      })}
    >
      {children}
    </div>
  );
};
