import { t } from "i18n-js";
import { useButtonVariant } from "@circle-react/helpers/useButtonVariant";
import { useFilterContext } from "@circle-react-shared/Filter/Filter";
import { Button } from "@circle-react-uikit/Button";
import { Form } from "@circle-react-uikit/Form";

export const MultipleInputs = ({
  lteValue,
  gteValue,
  localeRoot,
  queryParamsKey,
}: any) => {
  const { onApply } = useFilterContext();
  const removeAllDigits = (e: any) => e.replace(/\D/g, "");

  const handleApply = (submitData: any) => {
    const lteValue = parseInt(removeAllDigits(submitData[queryParamsKey.lte]));
    const gteValue = parseInt(removeAllDigits(submitData[queryParamsKey.gte]));

    onApply({
      [queryParamsKey.lte]: lteValue,
      [queryParamsKey.gte]: gteValue,
    });
  };

  const formatter = Intl.NumberFormat("en-US", { minimumFractionDigits: 2 });

  const primaryButtonVariant = useButtonVariant();
  const transformToNumber = (value: any) => {
    if (
      value === "0.0" ||
      value === "" ||
      value === null ||
      value === undefined
    ) {
      return "";
    }

    const amountWithoutDecimals = Number(removeAllDigits(value));

    if (!isNaN(amountWithoutDecimals)) {
      return formatter.format(amountWithoutDecimals / 100);
    }

    return formatter.format(0);
  };

  return (
    <Form
      defaultValues={{
        [queryParamsKey.lte]: transformToNumber(lteValue),
        [queryParamsKey.gte]: transformToNumber(gteValue),
      }}
      onSubmit={handleApply}
    >
      <div className="flex min-w-[18rem] flex-col gap-y-4 p-4">
        <Form.Input
          name={queryParamsKey.gte}
          placeholder={t([localeRoot, "min_amount_placeholder"])}
          autoFocus
          transformValue={transformToNumber}
        />
        <Form.Input
          name={queryParamsKey.lte}
          placeholder={t([localeRoot, "max_amount_placeholder"])}
          transformValue={transformToNumber}
        />
        <Button variant={primaryButtonVariant} type="submit" full>
          {t("filters.apply")}
        </Button>
      </div>
    </Form>
  );
};
