import { useEffect } from "react";
import { useAdminPreviewModal } from "..";
import { useIsV3 } from "@circle-react/hooks/useIsV3";

export const useRenderAdminPreviewModal = () => {
  const { isCircleV3Preview } = useIsV3();
  const modal = useAdminPreviewModal();
  let shouldShowAdminPreviewModal = false;

  try {
    shouldShowAdminPreviewModal = !!sessionStorage.getItem(
      "showAdminPreviewModal",
    );
  } catch (error) {
    console.warn("Session storage is disabled or unavailable.");
    shouldShowAdminPreviewModal = true;
  }

  useEffect(() => {
    if (!isCircleV3Preview) return;

    if (shouldShowAdminPreviewModal) {
      try {
        sessionStorage.removeItem("showAdminPreviewModal");
      } catch (error) {
        console.warn("Unable to remove item from session storage.");
      }
      void modal.show();
    }
  }, [modal, shouldShowAdminPreviewModal, isCircleV3Preview]);
};
