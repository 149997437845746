import { TRIGGER_RESOURCE_TYPE } from "@circle-react/components/SettingsApp/Workflows/constants";
import { CommunityResource } from "../Community/shared/CommunityResource";
import { LessonResource } from "../Course/shared/LessonResource";
import { SectionResource } from "../Course/shared/SectionResource";
import { PostsEventResource } from "../Event/shared/PostsEventResource";
import { PostsBasicResource } from "../PostBasic/shared/PostsBasicResource";
import { SpaceResource } from "../Space/shared/SpaceResource";
import { SpaceGroupResource } from "../SpaceGroup/shared/SpaceGroupResource";

const componentsMap = {
  [TRIGGER_RESOURCE_TYPE.COMMUNITY]: CommunityResource,
  [TRIGGER_RESOURCE_TYPE.SPACE_GROUP]: SpaceGroupResource,
  [TRIGGER_RESOURCE_TYPE.SPACE]: SpaceResource,
  [TRIGGER_RESOURCE_TYPE.POST_BASIC]: PostsBasicResource,
  [TRIGGER_RESOURCE_TYPE.EVENT]: PostsEventResource,
  [TRIGGER_RESOURCE_TYPE.LESSON]: LessonResource,
  [TRIGGER_RESOURCE_TYPE.SECTION]: SectionResource,
};

export const EntityResource = ({ resourceType, ...rest }) => {
  const Component = componentsMap[resourceType] || null;

  return (
    <>{Component && <Component resourceType={resourceType} {...rest} />}</>
  );
};
