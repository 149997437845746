import { t } from "i18n-js";
import { useFormContext } from "react-hook-form";
import { usePaywallCheckoutContext } from "@circle-react/contexts/Paywalls/paywallCheckoutContext";
import { Button } from "@circle-react-uikit/Button";
import { Typography } from "@circle-react-uikit/Typography";
import { CheckoutFormField } from "../CheckoutFormField";

export const CheckoutFormCouponForm = () => {
  const { watch } = useFormContext();
  const { applyCouponCode } = usePaywallCheckoutContext();

  const couponCode = watch("coupon_code");

  return (
    <div className="flex flex-row items-center gap-x-2">
      <CheckoutFormField className="grow" type="text" name="coupon_code" />
      {couponCode && (
        <Button
          large
          type="button"
          variant="secondary"
          onClick={() => applyCouponCode()}
          data-testid="checkout-form-apply-coupon"
        >
          <Typography.LabelSm weight="medium">
            {t("paywall_checkout.form.buttons.apply_coupon")}
          </Typography.LabelSm>
        </Button>
      )}
    </div>
  );
};
