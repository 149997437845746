import { PriceEmpty } from "./PriceEmpty";
import { PriceInstallment } from "./PriceInstallment";
import { PriceOneTime } from "./PriceOneTime";
import { PriceSubscription } from "./PriceSubscription";

const resolvers: Array<any> = [
  PriceEmpty,
  PriceOneTime,
  PriceSubscription,
  PriceInstallment,
];

export const Price = ({ price, currency }: any) => {
  const PriceComponent = resolvers.find(component =>
    component.validateToRender(price),
  );

  if (!PriceComponent) {
    return null;
  }

  return <PriceComponent price={price} currency={currency} />;
};
