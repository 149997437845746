import { useQuery } from "react-query";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";
import { workflows } from "@circle-react/helpers/urlHelpers/internalApi/workflows";

export const useDynamicVariables = ({
  triggerType,
  shouldSupportTriggerVariables,
}) => {
  const queryKey = ["dynamic_variables", triggerType];

  const fetchDynamicVariables = () =>
    reactQueryGet(
      workflows.fetchDynamicVariables({
        params: {
          trigger_type: triggerType,
          support_trigger_variables: shouldSupportTriggerVariables,
        },
      }),
    );

  return useQuery(queryKey, fetchDynamicVariables);
};
