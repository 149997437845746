import { t } from "i18n-js";
import {
  isDraft,
  isStreamBackstage,
  isStreamEnded,
  isStreamLive,
} from "@/react/components/Events/EditForm/helpers";
import { PlanLimitExceededLiveLocation } from "@/react/components/Events/Rsvp/PlanLimitExceededLiveLocation";
import { useLiveStreamIssue } from "@/react/components/Events/Rsvp/hooks/useLiveStreamIssue";
import { useOnCreateLiveStream } from "@/react/components/Events/Rsvp/useOnCreateLiveStream";
import { Typography } from "@circle-react/components/shared/uikit/Typography";
import type { Event } from "@circle-react/types/Event";
import { Button } from "@circle-react-uikit/Button";

interface LiveLocationProps {
  event: Event;
  isLiveLimitReached: boolean;
  shouldDisplayJoinButton: boolean;
  handleViewLiveStreamClick: () => void;
  hasEventFinished: boolean;
  isLoggedIn: boolean;
}

export const LiveLocation = ({
  event,
  shouldDisplayJoinButton,
  isLiveLimitReached,
  handleViewLiveStreamClick,
  hasEventFinished,
  isLoggedIn,
}: LiveLocationProps) => {
  const { event_setting_attributes: eventData, rsvped_event: isRsvpedEvent } =
    event || {};
  const {
    location_type: locationType,
    live_stream_slug: liveStreamSlug,
    live_stream_room_setting_attributes: {
      limit_url_sharing: isLimitUrlSharingEnabled,
    },
  } = eventData || {};
  const { issueCreatingLive, canCreateLive } = useLiveStreamIssue(event) || {};
  const onCreateLiveStreamMutation = useOnCreateLiveStream(event);
  const shouldShowCreateButton =
    canCreateLive && (issueCreatingLive || isStreamEnded(event));
  const shouldShowStreamLink =
    !!liveStreamSlug &&
    (!isLimitUrlSharingEnabled || isRsvpedEvent) &&
    (isStreamBackstage(event) || isStreamLive(event));

  if (isLiveLimitReached) {
    return <PlanLimitExceededLiveLocation event={event} />;
  }

  if (
    hasEventFinished ||
    shouldDisplayJoinButton ||
    !(shouldShowCreateButton || shouldShowStreamLink) ||
    !isLoggedIn
  ) {
    return (
      <Typography.LabelSm>
        {t(`events.location_types.${locationType}`)}
      </Typography.LabelSm>
    );
  }

  return (
    <>
      {shouldShowCreateButton && (
        <Button
          variant="text"
          onClick={onCreateLiveStreamMutation.mutate}
          disabled={
            isDraft(event) ||
            onCreateLiveStreamMutation.isLoading ||
            onCreateLiveStreamMutation.isSuccess
          }
        >
          <Typography.LabelSm color="text-link" weight="semibold">
            {t(`events.locations.create_${locationType}`)}
          </Typography.LabelSm>
        </Button>
      )}
      {shouldShowStreamLink && (
        <Button variant="text" onClick={handleViewLiveStreamClick}>
          <Typography.LabelSm color="text-link" weight="semibold">
            {t(`events.locations.join_${locationType}`)}
          </Typography.LabelSm>
        </Button>
      )}
    </>
  );
};
