import { compact } from "lodash";
import { filterProperties as amountFilterProperties } from "./AmountFilter/AmountFilter";
import { filterProperties as totalAmountPaidFilterProperties } from "./AmountFilter/TotalAmountPaidFilter";
import { filterProperties as chargeStatusFilterProperties } from "./ChargesStatusFilter/ChargesStatusFilter";
import { filterProperties as emailFilterProperties } from "./CommunityMemberEmailFilter";
import { filterProperties as nameFilterProperties } from "./CommunityMemberNameFilter";
import { filterProperties as currencyFilterProperties } from "./CurrencyFilter";
import { filterProperties as dateFilterProperties } from "./DateFilter/DateFilter";
import { filterProperties as startDateFilterProperties } from "./DateFilter/StartDateFilter";
import { filterProperties as invoiceIdFilterProperties } from "./IdFilter/InvoiceIdFilter";
import { filterProperties as subscriptionIdFilterProperties } from "./IdFilter/SubscriptionIdFilter";
import { filterProperties as transactionIdFilterProperties } from "./IdFilter/TransactionIdFilter";
import { filterProperties as memberNameOrEmailFilterProperties } from "./MemberNameOrEmailFilter/MemberNameOrEmailFilter";
import { filterProperties as eventPaywallsFilterProperties } from "./PaywallsFilter/EventPaywallsFilter";
import { filterProperties as spacePaywallsFilterProperties } from "./PaywallsFilter/SpacePaywallsFilter";
import { filterProperties as platformFilterProperties } from "./PlatformFilter";
import { filterProperties as subscriptionStatusFilterProperties } from "./SubscriptionsStatusFilter/SubscriptionsStatusFilter";

export const localeRoot = "settings.paywalls_admin_dashboard.filters";

export type FILTERS_AVAILABLE =
  | "amount"
  | "charge_status"
  | "community_member_id"
  | "created_at"
  | "currency"
  | "community_member_email"
  | "community_member_name"
  | "invoice_processor_id"
  | "paywalls"
  | "platform"
  | "events"
  | "start_date"
  | "subscription_id"
  | "subscription_status"
  | "total_amount_paid"
  | "transaction_id";

export const FILTERS: any = {
  // COMMON FILTERS
  community_member_id: memberNameOrEmailFilterProperties,
  currency: currencyFilterProperties,
  created_at: dateFilterProperties,
  community_member_email: emailFilterProperties,
  community_member_name: nameFilterProperties,
  paywalls: spacePaywallsFilterProperties,
  platform: platformFilterProperties,

  // CHARGE FILTERS
  amount: amountFilterProperties,
  charge_status: chargeStatusFilterProperties,
  invoice_processor_id: invoiceIdFilterProperties,
  transaction_id: transactionIdFilterProperties,
  events: eventPaywallsFilterProperties,

  // SUBSCRIPTION FILTERS
  subscription_status: subscriptionStatusFilterProperties,
  subscription_id: subscriptionIdFilterProperties,
  total_amount_paid: totalAmountPaidFilterProperties,
  start_date: startDateFilterProperties,
};

export const allFilterTranslations: any = {
  subscriptions: {
    community_member_id: "community_member_id",
    currency: "currency",
    email: "email",
    paywalls: "paywall_ids",
    platform: "platform",
    name: "query",
    start_date_gte: "created_at_gte",
    start_date_lte: "created_at_lte",
    status: "status",
    subscription_processor_id: "processor_id",
    total_amount_paid_gte: "total_amount_paid_gte",
    total_amount_paid_lte: "total_amount_paid_lte",
  },
  charges: {
    amount_gte: "amount_gte",
    amount_lte: "amount_lte",
    community_member_id: "community_member_id",
    created_at_gte: "created_at_gte",
    created_at_lte: "created_at_lte",
    currency: "currency",
    email: "email",
    invoice_processor_id: "invoice_processor_id",
    paywalls: "paywall_ids",
    platform: "platform",
    events: "paywall_ids",
    name: "query",
    status: "display_status",
    subscription_processor_id: "subscription_processor_id",
    transaction_id: "processor_id",
  },
};

export const normalizeFilters = (filters: any, filterTranslation: any) => {
  const newFilter: any = {};

  Object.keys(filterTranslation).forEach((key: any) => {
    if (filters[key]) {
      const filterKey = filterTranslation[key];
      newFilter[filterKey] = filters[key];
    }
  });

  return newFilter;
};

export const useFilters = (
  filterNames?: Array<Partial<FILTERS_AVAILABLE>>,
  customFilterNames?: Array<Partial<FILTERS_AVAILABLE>>,
) => {
  const defaultFilters = compact(
    (filterNames ?? []).map(filterKey => FILTERS[filterKey]),
  );

  const customFilters = compact(
    (customFilterNames ?? []).map(filterKey => FILTERS[filterKey]),
  );

  return {
    defaultFilters: [...defaultFilters, ...customFilters],
  };
};
