import { t } from "i18n-js";
import { useGetBroadcast } from "@circle-react/components/SettingsApp/Emails/hooks/useGetBroadcast";
import { Loader } from "@circle-react-shared/uikit/Loader";
import { Typography } from "@circle-react-uikit/Typography";
import type { SendEmailApiParams } from "./interfaces";

export interface HistoryDetailViewEmailProps {
  apiParams: SendEmailApiParams;
}

const i18nBase = "settings.workflows.action_messages.send_email";

export const HistoryDetailViewEmail = ({
  apiParams,
}: HistoryDetailViewEmailProps) => {
  const { broadcast_id: id } = apiParams;
  const { data, isLoading } = useGetBroadcast({ id: Number(id) });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="flex flex-col gap-2">
      <div className="md:flex">
        <div className="w-1/4">
          <Typography.LabelSm weight="semibold">
            {t([i18nBase, "internal_name"])}
          </Typography.LabelSm>
        </div>
        <div className="w-3/4">{data?.internal_name}</div>
      </div>
      <div className="md:flex">
        <div className="w-1/4">
          <Typography.LabelSm weight="semibold">
            {t([i18nBase, "subject"])}
          </Typography.LabelSm>
        </div>
        <div className="w-3/4">{data?.broadcast_content?.subject}</div>
      </div>
      <div className="md:flex">
        <div className="w-1/4">
          <Typography.LabelSm weight="semibold">
            {t([i18nBase, "preview_text"])}
          </Typography.LabelSm>
        </div>
        <div className="w-3/4">{data?.broadcast_content?.preview_text}</div>
      </div>
    </div>
  );
};
