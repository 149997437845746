import { Suspense } from "react";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { PageLoader } from "@circle-react/pages/PageLoader";

const LiveRoomComponent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "LiveRoomPage" */ "@circle-react/components/LiveStreams/Room"
    ),
);

export const LiveRoomPage = () => (
  <Suspense fallback={<PageLoader />}>
    <LiveRoomComponent />
  </Suspense>
);
