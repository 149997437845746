import { useLiveStreamContext } from "@circle-react/contexts/LiveStreams";
import {
  ROOM_VIEW_TYPES,
  canManageRoom,
} from "@circle-react/helpers/liveStreamRoomHelpers";
import { useProviderSyncRoom } from "./provider";
import { useRoomType } from "./useRoomType";

export const useViewType = () => {
  const {
    actions: { setViewTypeOverride, updateRoomAsync },
    data: { room, viewTypeOverride },
  } = useLiveStreamContext();
  const { isLiveStream } = useRoomType();
  const viewType = viewTypeOverride || room?.view_type;
  const syncRoom = useProviderSyncRoom();
  const setSpeakerView = async () => {
    if (viewType === ROOM_VIEW_TYPES.SPEAKER) {
      return;
    }
    if (isLiveStream || canManageRoom(room)) {
      try {
        await updateRoomAsync({ view_type: ROOM_VIEW_TYPES.SPEAKER });
        syncRoom();
      } catch (e) {
        console.error(e.message);
      }
    } else {
      setViewTypeOverride(ROOM_VIEW_TYPES.SPEAKER);
    }
  };
  const setGridView = async () => {
    if (viewType === ROOM_VIEW_TYPES.GRID) {
      return;
    }
    if (isLiveStream || canManageRoom(room)) {
      try {
        await updateRoomAsync({ view_type: ROOM_VIEW_TYPES.GRID });
        syncRoom();
      } catch (e) {
        console.error(e.message);
      }
    } else {
      setViewTypeOverride(ROOM_VIEW_TYPES.GRID);
    }
  };

  return {
    isSpeakerView: viewType === ROOM_VIEW_TYPES.SPEAKER,
    isGridView: viewType === ROOM_VIEW_TYPES.GRID,
    setSpeakerView,
    setGridView,
  };
};
