import { useMemo } from "react";
import flatten from "lodash/flatten";
import { useInfiniteQuery } from "react-query";
import { segmentsApi } from "@circle-react/api/segmentsApi";
import type { ApiError } from "@circle-react/config/CustomErrors";
import type { CommunityMember, PaginationHelper } from "@circle-react/types";
import type { CommunitySegmentRule } from "@circle-react/types/CommunitySegment";

export const DEFAULT_PAGE_SIZE = 20;
export const SEGMENTS_QUERY_KEY_PREFIX = "segments";
export const ALL_SEGMENTS_QUERY_KEY_PREFIX = "all";

type AuthorCellMember = Pick<
  CommunityMember,
  "id" | "name" | "avatar_url" | "public_uid"
>;

export interface SegmentPage {
  id: number;
  title: string;
  audience_match_count: number;
  updated_at: string;
  author: AuthorCellMember;
  rules: CommunitySegmentRule;
}

type SegmentData = PaginationHelper<SegmentPage>;

export const useSegmentsInfiniteQuery = ({
  perPage = DEFAULT_PAGE_SIZE,
  initialPage = 1,
  options = {},
}: {
  perPage?: number;
  initialPage?: number;
  options?: object;
}) => {
  const fetchPage = ({ pageParam = initialPage }) => {
    let page = 1;

    if (typeof pageParam === "number" && pageParam > 0) {
      page = pageParam;
    }

    const params = {
      page,
      per_page: perPage,
    };

    return segmentsApi.list({ params });
  };

  const queryKey = [
    SEGMENTS_QUERY_KEY_PREFIX,
    ALL_SEGMENTS_QUERY_KEY_PREFIX,
    perPage,
    initialPage,
  ];

  const result = useInfiniteQuery<SegmentData, ApiError>(queryKey, fetchPage, {
    getNextPageParam: lastPage =>
      lastPage.has_next_page ? lastPage.page + 1 : undefined,
    getPreviousPageParam: firstPage =>
      firstPage.page > 1 ? firstPage.page - 1 : undefined,
    keepPreviousData: true,
    ...options,
  });
  const pages = useMemo(() => result.data?.pages || [], [result.data?.pages]);
  const pageSize = pages[0]?.per_page || perPage;
  const count = pages[0]?.count ?? 0;
  const segments = useMemo(
    () => flatten(pages.map(page => page.records)),
    [pages],
  );

  return {
    ...result,
    queryKey,
    segments,
    pageSize,
    pages,
    count,
  };
};
