import PropTypes from "prop-types";
import classNames from "classnames";
import { Layout } from "@circle-react/components/shared/CustomizeUI";

export const Section = ({ className, children, id }) => (
  <Layout.Main id={id} className="!bg-primary">
    <div
      className={classNames(
        "mx-auto max-w-[45rem] p-0 md:px-0 lg:py-8",
        className,
      )}
    >
      {children}
    </div>
  </Layout.Main>
);

Section.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  id: PropTypes.string,
};
