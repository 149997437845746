import { useEffect, useState } from "react";
import { RoomEvent } from "livekit-client";
import { useLivekitContext } from "../../VideoProvider/LivekitProvider";

export const useLivekitHlsState = () => {
  const [isRecording, setIsRecording] = useState(false);
  const { room } = useLivekitContext();

  useEffect(() => {
    if (!room) return;

    setIsRecording(room.isRecording);

    room.on(RoomEvent.RecordingStatusChanged, recording =>
      setIsRecording(recording),
    );

    return () => {
      room.off(RoomEvent.RecordingStatusChanged, setIsRecording);
    };
  }, [room]);

  return {
    hlsUrl: null,
    isHlsRunning: isRecording,
    isRecording: isRecording,
  };
};
