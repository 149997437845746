import { Suspense } from "react";
import { useParams } from "react-router-dom";
import { AdminAudienceEditModalContextProvider } from "@circle-react/components/Modals/AdminMemberEditModal/AdminAudienceEditContext/AdminAudienceEditModalContextProvider";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { PageLoader } from "@circle-react/pages/PageLoader";

const AdminMemberEditComponent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "AdminMemberEditPage" */ "@circle-react/components/Modals/AdminMemberEditModal/AdminMemberEditModal"
    ),
);

export const AdminMemberEditPage = () => {
  const { memberPublicId } = useParams<{ memberPublicId: string }>();

  return (
    <Suspense fallback={<PageLoader />}>
      <AdminAudienceEditModalContextProvider
        type="CommunityMember"
        publicId={memberPublicId}
      >
        <AdminMemberEditComponent />
      </AdminAudienceEditModalContextProvider>
    </Suspense>
  );
};
