import { compact, omit } from "lodash";

const sanitizePaywallsBlocks = paywallBlock => {
  paywallBlock.data.paywalls = compact(
    paywallBlock.data.paywalls.map(paywall => {
      const isPaywallNotPresent = !paywall?.id;
      const isPaywallPriceNotPresent =
        !paywall?.web_paywall_price?.id && !paywall?.mobile_paywall_price?.id;

      if (isPaywallNotPresent || isPaywallPriceNotPresent) {
        return null;
      }

      return paywall;
    }),
  );

  return paywallBlock;
};

const getLegacyLockscreenData = (blocks = []) => {
  const legacyBlock = blocks.find(block => block.type === "legacy_lock_screen");

  if (!legacyBlock) {
    return null;
  }

  return {
    ...legacyBlock.data,
    locked_page_description:
      legacyBlock.data?.locked_page_description?.trix_html || "",
  };
};

const removeDataFromBlocks = (blocks = []) =>
  blocks
    .map(block => omit(block, "id"))
    .map(block => {
      if (block.type === "lessons" || block.type === "legacy_lock_screen") {
        return {
          ...block,
          data: {},
        };
      }

      if (block.type === "paywalls") {
        return sanitizePaywallsBlocks(block);
      }

      return block;
    });

export const parseSubmitData = ({ lock_screen_blocks: blocks }) => {
  const legacyLockscreenData = getLegacyLockscreenData(blocks);
  const hasLegacyData = Boolean(legacyLockscreenData);
  const sanitizedBlocks = removeDataFromBlocks(blocks);

  if (hasLegacyData) {
    return {
      lock_screen_blocks: sanitizedBlocks,
      ...legacyLockscreenData,
    };
  }

  return {
    lock_screen_blocks: sanitizedBlocks,
  };
};
