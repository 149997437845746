import type { ReactNode } from "react";
import classNames from "classnames";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { useScrollbarStyles } from "./useScrollbarStyles";

export interface LinksWrapperProps {
  children: ReactNode;
}

export const LinksWrapper = ({ children }: LinksWrapperProps) => {
  const styles = useScrollbarStyles();

  return (
    <div className={classNames("h-full")} style={styles}>
      <OverlayScrollbarsComponent
        className="h-full"
        options={{
          scrollbars: {
            autoHide: "leave",
            autoHideDelay: 0,
          },
        }}
      >
        {children}
      </OverlayScrollbarsComponent>
    </div>
  );
};
