import classNames from "classnames";
import { t } from "i18n-js";
import type { RegisterOptions } from "react-hook-form";
import { get, useFormContext } from "react-hook-form";
import { Typography } from "@circle-react-uikit/Typography";
import { CheckoutFormInput } from "./CheckoutFormInput";

export interface CheckoutFormFieldProps {
  autoFocus?: boolean;
  className?: string;
  placeholder?: string;
  name: string;
  options?: RegisterOptions;
  type: "text" | "password";
}

export const CheckoutFormField = ({
  autoFocus = false,
  className,
  name,
  placeholder,
  options = {},
  type,
}: CheckoutFormFieldProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const error = get(errors, name);

  return (
    <div className={classNames(className, "flex flex-col")}>
      <CheckoutFormInput
        autoFocus={autoFocus}
        type={type}
        error={!!error}
        placeholder={placeholder}
        {...register(name, options)}
      />
      {error && (
        <div className="mt-2">
          <Typography.LabelSm weight="semibold" color="text-red-600">
            {t(`paywall_checkout.form.errors.${name}_${String(error.type)}`)}
          </Typography.LabelSm>
        </div>
      )}
    </div>
  );
};
