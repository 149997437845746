import { useCallback, useMemo } from "react";
import { flatten } from "lodash";
import { useSet } from "@circle-react/hooks/useSet";
import { useFilterUrl } from "@circle-react-shared/Filter";

export const useDynamicFilters = ({ filters, defaultFilterItems }) => {
  const { queryParams, removeQueryParams } = useFilterUrl();
  const allFilterKeys = flatten(filters.map(filter => filter.filterKeys));

  const getInitialDisplayedFilters = () => {
    const initialDisplayedFiltersSet = new Set(defaultFilterItems);

    filters.forEach(filter => {
      const existsOnQueryString = Object.keys(queryParams).includes(
        filter.name,
      );
      if (existsOnQueryString) {
        initialDisplayedFiltersSet.add(filter.name);
      }
      const hasValue = filter.value;
      if (hasValue != undefined) {
        initialDisplayedFiltersSet.add(filter.name);
      }
    });
    return initialDisplayedFiltersSet;
  };
  const [displayedFilters, { add, remove, clear }] = useSet(
    getInitialDisplayedFilters,
  );

  const isClearFiltersShown = useMemo(() => {
    let offset = 0;
    for (const filterItemName of defaultFilterItems) {
      if (Object.prototype.hasOwnProperty.call(queryParams, filterItemName)) {
        return true;
      }
      if (displayedFilters.has(filterItemName)) {
        offset += 1;
      }
    }
    return displayedFilters.size > offset;
  }, [displayedFilters, queryParams, defaultFilterItems]);

  const handleClearFilters = () => {
    removeQueryParams([...allFilterKeys]);
    clear();
    defaultFilterItems.forEach(add);
  };

  const getSplitAndAvailableFilters = useCallback(
    filters => {
      const displayedFilterNames = [...displayedFilters];
      if (!displayedFilterNames.length) {
        return {
          availableFilters: filters,
          displayedFilters: [],
        };
      }
      return {
        availableFilters: filters.filter(
          filter => !displayedFilterNames.includes(filter.name),
        ),
        displayedFilters: filters.filter(filter =>
          displayedFilterNames.includes(filter.name),
        ),
      };
    },
    [displayedFilters],
  );

  const handleAddFilter = name => {
    add(name);
  };

  const handleRemoveFilter = (value, name) => {
    if (value) return;

    remove(name);
  };

  return {
    isClearFiltersShown,
    getSplitAndAvailableFilters,
    onClearFilters: handleClearFilters,
    onAddFilter: handleAddFilter,
    onRemoveFilter: handleRemoveFilter,
  };
};
