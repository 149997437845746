import { createContext, useContext } from "react";
import PropTypes from "prop-types";
import { useCommunityMembersList } from "@circle-react/hooks/useCommunityMembersList";

export const CommunityMembersListContext = createContext(null);
CommunityMembersListContext.displayName = "CommunityMembersListContext";
export const useCommunityMembersListContext = () => {
  const communityMembersListContext = useContext(CommunityMembersListContext);

  console.assert(
    communityMembersListContext,
    "useCommunityMembersListContext is being used outside context provider.",
  );
  return communityMembersListContext;
};

export const CommunityMembersListProvider = ({ ids, children }) => {
  const reactQueryProps = useCommunityMembersList({
    ids: [...new Set(ids)],
    withDeleted: true,
    enabled: !!ids?.length,
  });

  return (
    <CommunityMembersListContext.Provider value={reactQueryProps}>
      {children}
    </CommunityMembersListContext.Provider>
  );
};

CommunityMembersListProvider.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.number),
  children: PropTypes.node.isRequired,
};
