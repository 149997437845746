import { MODAL_RESULT, useConfirmSubmitWorkflowModal } from ".";
import { t } from "i18n-js";
import { useFormContext } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { workflowsSettingsPaths } from "@circle-react/helpers/urlHelpers";
import { useToast } from "@circle-react-uikit/ToastV2";
import { WORKFLOW_TYPE } from "../../constants";

export const useSubmitWorkflow = ({ isRerun }) => {
  const history = useHistory();
  const submitModal = useConfirmSubmitWorkflowModal({ isRerun });
  const queryClient = useQueryClient();
  const toast = useToast();
  const { handleSubmit, getValues } = useFormContext();

  const onFormSubmit = async (data = {}) => {
    const result = await submitModal.show({ workflow: getValues() });

    if (result === MODAL_RESULT.DISMISSED || result === MODAL_RESULT.ERROR) {
      return;
    }

    const { id: workflowId, workflow_type: type, schedule } = data;
    const isBulkAction = type === WORKFLOW_TYPE.BULK_ACTION;
    const isRunNowSchedule = schedule?.schedule_type === "run_now";

    queryClient.removeQueries(["workflows", workflowId]);

    if (isBulkAction || isRunNowSchedule) {
      history.push(workflowsSettingsPaths.runs({ workflowId }));
      return;
    }

    history.push(workflowsSettingsPaths.view({ workflowId }));
  };

  const onLocalValidationsError = () => {
    toast.error(t("settings.workflows.form.errors.fill_all_required_fields"));
  };

  return {
    submitWorkflow: handleSubmit(onFormSubmit, onLocalValidationsError),
  };
};
