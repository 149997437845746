import { createContext, useCallback, useContext } from "react";
import type { ReactNode } from "react";
import { noop } from "lodash";
import { useFieldArray, useFormContext } from "react-hook-form";
import { usePunditUserContext } from "@circle-react/contexts";
import {
  PRICE_TRIAL_ELIGIBILITY_CONTEXTS,
  pricesEligibleForTrial,
  recurringPrices,
  subscriptionPrices,
} from "@circle-react/helpers/paywallPriceHelpers";
import type {
  PaywallCurrency,
  PaywallPrice,
  PaywallPriceBuildOptions,
} from "@circle-react/types";
import {
  paywallCurrencyDirections,
  paywallCurrencySmallestUnits,
  paywallPriceTypes,
} from "@circle-react/types";
import type {
  PricingTabPriceItem,
  PricingTabPriceItemId,
  PricingTabSelectOption,
} from "../../types";

export const findPriceIndex = (
  prices: Array<PricingTabPriceItem>,
  price: PricingTabPriceItemId,
): number =>
  prices.findIndex((item: PricingTabPriceItem) => {
    if (price.id) {
      return item.id === price.id;
    }
    return item.tmp_id === price.tmp_id;
  });

export interface PricingTabContextProps {
  currency: PaywallCurrency;
  priceBuildOptions: PaywallPriceBuildOptions;
  priceTypeOptions: () => Array<PricingTabSelectOption>;
  defaultPriceTypeOption: () => PricingTabSelectOption;
  getPrices: () => Array<PricingTabPriceItem>;
  getActivePrices: () => Array<PricingTabPriceItem>;
  hasRecurringPrices: () => boolean;
  getTrialPeriodDays: () => number;
  getHasTrialPeriod: () => boolean;
  hasPricesEligibleForTrial: () => boolean;
  addPrice: (
    data: Omit<PaywallPrice, "id" | "preferred" | "currency_id">,
  ) => void;
  markPriceAsPreferred: (price: PricingTabPriceItemId) => void;
  removePrice: (price: PricingTabPriceItemId) => void;
  isShowingAddPriceModal: () => boolean;
  showAddPriceModal: () => void;
  hideAddPriceModal: () => void;
  hasActivePrices: boolean;
  hasSubscriptionPrices: () => boolean;
}

const PricingTabContext = createContext<PricingTabContextProps>({
  currency: {
    id: 1,
    code: "usd",
    direction: paywallCurrencyDirections.ltr,
    smallest_unit: paywallCurrencySmallestUnits.decimal,
    symbol: "$",
    min_charge_amount: 0,
    max_charge_amount: 0,
    is_default: true,
  },
  priceBuildOptions: {},
  priceTypeOptions: () => [],
  defaultPriceTypeOption: () => ({
    value: "",
    label: "",
  }),
  getPrices: () => [],
  getActivePrices: () => [],
  hasRecurringPrices: () => false,
  getTrialPeriodDays: () => 0,
  getHasTrialPeriod: () => false,
  hasPricesEligibleForTrial: () => false,
  addPrice: noop,
  markPriceAsPreferred: noop,
  removePrice: noop,
  isShowingAddPriceModal: () => false,
  showAddPriceModal: noop,
  hideAddPriceModal: noop,
  hasActivePrices: false,
  hasSubscriptionPrices: () => false,
});

PricingTabContext.displayName = "PricingTabContext";

export interface PricingTabContextProviderProps {
  currency: PaywallCurrency;
  children: ReactNode;
}

export const StripePricingTabContextProvider = ({
  currency,
  children,
}: PricingTabContextProviderProps) => {
  const {
    currentCommunitySettings: {
      price_build_options: { stripe: priceBuildOptions },
    },
  } = usePunditUserContext();

  const { setValue, watch } = useFormContext();

  const priceTypeOptions = useCallback(() => {
    const canBeRepurchased = watch("can_be_repurchased");

    return Object.keys(priceBuildOptions)
      .filter(value => !canBeRepurchased || value === paywallPriceTypes.onetime)
      .map(value => ({
        value,
        label: priceBuildOptions[value].name,
      }));
  }, [priceBuildOptions, watch]);
  const defaultPriceTypeOption = useCallback(
    () => priceTypeOptions()[0],
    [priceTypeOptions],
  );

  const getPrices = useCallback(
    (): Array<PricingTabPriceItem> => watch("stripe_prices_attributes"),
    [watch],
  );
  const getActivePrices = useCallback(
    () => getPrices().filter(price => !price._destroy),
    [getPrices],
  );
  const hasActivePrices = getActivePrices().length > 0;

  const hasRecurringPrices = useCallback(() => {
    const prices = getActivePrices();
    return recurringPrices(prices).length > 0;
  }, [getActivePrices]);

  const hasSubscriptionPrices = useCallback(() => {
    const prices = getActivePrices();
    return subscriptionPrices(prices).length > 0;
  }, [getActivePrices]);

  const getTrialPeriodDays = useCallback(
    () => parseInt(watch("trial_days")),
    [watch],
  );
  const getHasTrialPeriod = useCallback(() => {
    const isTrialPeriodEnabled = Boolean(watch("enable_trial"));
    return isTrialPeriodEnabled && getTrialPeriodDays() > 0;
  }, [watch, getTrialPeriodDays]);

  const hasPricesEligibleForTrial = useCallback(() => {
    const prices = getActivePrices();
    return (
      pricesEligibleForTrial(
        prices,
        PRICE_TRIAL_ELIGIBILITY_CONTEXTS.addingPrice,
      ).length > 0
    );
  }, [getActivePrices]);

  const { append, update, remove } = useFieldArray({
    name: "stripe_prices_attributes",
    rules: { minLength: 1 },
  });
  const addPrice = useCallback(
    (data: Omit<PaywallPrice, "id" | "preferred" | "currency_id">) => {
      append({
        ...data,
        preferred: false,
        currency_id: currency.id,
        tmp_id: `tmp_${Date.now()}`,
      });
    },
    [currency, append],
  );
  const markPriceAsPreferred = useCallback(
    (price: PricingTabPriceItemId) => {
      const prices = getPrices();
      const currentPreferredPriceIdx = prices.findIndex(item => item.preferred);
      if (currentPreferredPriceIdx > -1) {
        update(currentPreferredPriceIdx, {
          ...prices[currentPreferredPriceIdx],
          preferred: false,
          _updated: true,
        });
      }
      const priceIdx = findPriceIndex(prices, price);
      if (priceIdx > -1) {
        update(priceIdx, {
          ...prices[priceIdx],
          preferred: true,
          _updated: true,
        });
      }
    },
    [getPrices, update],
  );
  const removePrice = useCallback(
    (price: PricingTabPriceItemId) => {
      const prices = getPrices();
      const priceIdx = findPriceIndex(prices, price);
      if (priceIdx > -1) {
        const existingPrice = prices[priceIdx];
        if (existingPrice.id) {
          update(priceIdx, {
            ...existingPrice,
            preferred: false,
            _destroy: true,
          });
        } else {
          remove(priceIdx);
        }
      }
    },
    [getPrices, update, remove],
  );

  const isShowingAddPriceModal = useCallback(
    () => !!watch("isShowingAddPriceModal"),
    [watch],
  );
  const showAddPriceModal = useCallback(() => {
    setValue("isShowingAddPriceModal", true);
  }, [setValue]);
  const hideAddPriceModal = useCallback(() => {
    setValue("isShowingAddPriceModal", false);
  }, [setValue]);

  return (
    <PricingTabContext.Provider
      value={{
        currency,
        priceBuildOptions,
        priceTypeOptions,
        defaultPriceTypeOption,
        getPrices,
        getActivePrices,
        hasRecurringPrices,
        getTrialPeriodDays,
        getHasTrialPeriod,
        hasPricesEligibleForTrial,
        addPrice,
        markPriceAsPreferred,
        removePrice,
        isShowingAddPriceModal,
        showAddPriceModal,
        hideAddPriceModal,
        hasActivePrices,
        hasSubscriptionPrices,
      }}
    >
      {children}
    </PricingTabContext.Provider>
  );
};

export const usePricingTabContext = () => useContext(PricingTabContext);
