import { Suspense } from "react";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { PageLoader } from "@circle-react/pages/PageLoader";

const BrandedAppsComponent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "BrandedAppsPage" */ "@circle-react/components/SettingsApp/Plan/BrandedApps"
    ),
);

export const BrandedAppsPage = () => (
  <Suspense fallback={<PageLoader />}>
    <BrandedAppsComponent />
  </Suspense>
);
