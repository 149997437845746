import classNames from "classnames";
import { isThisYear } from "date-fns";
import { Typography } from "@circle-react/components/shared/uikit/Typography";

interface BodyProps {
  isDirect: boolean;
  chatRoomName: string;
  timestamp: string;
  lastMessage: any;
}
export const Body = ({
  isDirect,
  chatRoomName,
  timestamp,
  lastMessage,
}: BodyProps) => {
  const getMessageSenderName = () =>
    isDirect ? "" : `${lastMessage.sender?.name}: `;

  const renderMessageBody = () =>
    lastMessage.body?.circle_ios_fallback_text || "";

  const isOldMessage = !isThisYear(new Date(lastMessage.created_at));

  return (
    <div>
      <div className="flex items-center space-x-2">
        <span
          className={classNames(
            isOldMessage ? "max-w-[106px]" : "max-w-[130px]",
          )}
        >
          <Typography.BodySm truncate weight="font-medium">
            {chatRoomName}
          </Typography.BodySm>
        </span>
        <span className="mt-[-1px] min-w-fit">
          <Typography.BodyXs as="time" weight="font-medium" color="text-light">
            {timestamp}
          </Typography.BodyXs>
        </span>
      </div>
      <div className="mt-0.5">
        <p className="text-default !line-clamp-1 flex items-center overflow-hidden hyphens-auto break-all text-sm">
          {`${getMessageSenderName()}${renderMessageBody()}`}
        </p>
      </div>
    </div>
  );
};
