import {
  reactQueryDelete,
  reactQueryPost,
} from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";

const create = (params: any) =>
  reactQueryPost(internalApi.paywalls.affiliates.attributions.create(), params);

const destroy = (params: any) =>
  reactQueryDelete(
    internalApi.paywalls.affiliates.attributions.destroy(),
    params,
  );

export const attributions = {
  create,
  destroy,
};
