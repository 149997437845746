import { tabs } from "../../../constants";

export const usePaywallFormContentEditFlow = (activeTab, setActiveTab) => {
  const tabOptions = [
    {
      label: tabs.DETAILS_TAB,
    },
    {
      label: tabs.PRICING_TAB,
    },
    {
      label: tabs.ACCESS_TAB,
    },
    {
      label: tabs.POST_PURCHASE_TAB,
    },
    {
      label: tabs.TRACKING_TAB,
    },
    {
      label: tabs.WORKFLOWS_TAB,
    },
  ];

  const handleTabClick = async tab => {
    setActiveTab(tab);
  };

  return {
    handleTabClick,
    tabOptions,
    shouldBlockSubmitButton: false,
  };
};
