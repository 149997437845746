import PropTypes from "prop-types";
import { t } from "i18n-js";
import { Dropdown } from "@circle-react-uikit/Dropdown";

export const Delete = ({ onClick }) => (
  <Dropdown.ItemWithLink hasDanger onClick={onClick}>
    {t("settings.workflows.table.actions.delete")}
  </Dropdown.ItemWithLink>
);

Delete.propTypes = {
  onClick: PropTypes.func.isRequired,
};
