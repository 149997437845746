import { t } from "i18n-js";
import { PaywallsComponent } from "@/react/components/Paywalls/Admin/PaywallsAdmin";
import { Section } from "@/react/components/Spaces/CustomizeSpaceFullPage/Section";
import { usePunditUserContext } from "@circle-react/contexts";
import { Typography } from "@circle-react-uikit/Typography";
import { StripeEnabled } from "./StripeEnabled";

export const Paywalls = () => {
  const { currentCommunity, currentCommunityMember, isLoading } =
    usePunditUserContext();

  if (isLoading) {
    return null;
  }

  return (
    <Section>
      <div>
        <Typography.TitleSm weight="bold">
          {t("customize_space.paywalls.title")}
        </Typography.TitleSm>
      </div>
      <div className="mt-5 lg:mt-9">
        {currentCommunity.has_payment_processor_enabled ? (
          <StripeEnabled />
        ) : (
          <PaywallsComponent
            shouldHideHeader
            paymentProcessor={currentCommunity.payment_processor}
            paywallGroupsEnabled={
              currentCommunityMember.policies.paywall_group_enabled
            }
          />
        )}
      </div>
    </Section>
  );
};
