import { useCohostsList } from ".";
import { t } from "i18n-js";
import { useMutation } from "react-query";
import { liveStreamApi } from "@circle-react/api";
import { PARTICIPANT_ROLES } from "@circle-react/helpers/liveStreamRoomHelpers";
import { useToast } from "@circle-react-uikit/ToastV2";
import { useProviderRoleChange } from "./provider";

export const useInviteParticipantToCohost = (
  peerId,
  roomId,
  communityMemberId,
) => {
  const { success, error } = useToast();
  const { requestRoleChange } = useProviderRoleChange();
  const { refetchCohosts } = useCohostsList();
  const inviteCohostMutation = useMutation(
    () =>
      liveStreamApi.addCohosts({
        roomId: roomId,
        formData: {
          cohosts_community_member_ids: [communityMemberId],
        },
      }),
    {
      onSuccess: () => {
        requestRoleChange(peerId, PARTICIPANT_ROLES.HOST);
        refetchCohosts();
        success(t("live_streams.room.participant_invited_to_cohost_message"));
      },
      onError: () =>
        error(t("live_streams.room.participant_invited_to_cohost_error")),
    },
  );

  return inviteCohostMutation.mutate;
};
