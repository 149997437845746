import { useRouterQueryParams } from "@/react/hooks/useRouterQueryParams";
import { ALL_STATUS } from "../constants";
import { usePostsApi } from "./usePostsApi";

export const usePosts = (filters = {}) => {
  const { page: pageNumber, ...filterParams } = useRouterQueryParams();

  const page = Number(pageNumber) || 1;
  const filter = { status: ALL_STATUS, ...filterParams, ...filters };

  const params = { page, filter };
  if (filter.status === "scheduled") {
    params.sort = "scheduled_at";
  }

  const { posts, postsCount, isLoadingPosts } = usePostsApi(params);

  return {
    posts,
    postsCount,
    isLoadingPosts,
    filter,
    page,
  };
};
