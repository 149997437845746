import { t } from "i18n-js";
import { TRIGGER_RESOURCE_TYPE } from "../../../constants";
import { EntityResource } from "../EntityResource";
import type { EventOption } from "../types";
import { AnySectionSelector } from "./shared/AnySectionSelector";

const i18nRoot = "settings.workflows.form";

export const completedSection: Readonly<EventOption> = {
  label: t([i18nRoot, "events.community_member_completed_section.title"]),
  value: "community_member_completed_section",
  group: t("settings.workflows.form.event_groups.courses"),
  description: t([
    i18nRoot,
    "events.community_member_completed_section.description",
  ]),
  resource: TRIGGER_RESOURCE_TYPE.SECTION,
  formComponent: AnySectionSelector,
  viewComponent: EntityResource,
  icon: "course",
};
