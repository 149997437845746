import type { CardElementProps } from "@stripe/react-stripe-js";
import { CardElement } from "@stripe/react-stripe-js";
import I18n from "i18n-js";
import { stripeCardElementOptions } from "@circle-react/components/Paywalls/StripeElements/stripeElementsOptions";
import { usePaywallCheckoutContext } from "@circle-react/contexts/Paywalls/paywallCheckoutContext";
import { Icon } from "@circle-react-shared/Icon";
import { TippyV2 as Tippy } from "@circle-react-shared/TippyV2";
import { CheckoutFormFieldWrapper } from "../../";

export interface CheckoutFormCardFieldProps {
  handleOnChangeCard: CardElementProps["onChange"];
  handleOnReadyCard: CardElementProps["onReady"];
}
export const CreditCardForm = ({
  handleOnChangeCard,
  handleOnReadyCard,
}: CheckoutFormCardFieldProps) => {
  const { getSelectedPrice } = usePaywallCheckoutContext();

  const selectedPrice = getSelectedPrice();

  return (
    <CheckoutFormFieldWrapper
      name="payment"
      addon={
        selectedPrice.trial_enabled && (
          <span className="inline-flex">
            <Tippy
              className="inline-flex"
              content={I18n.t("paywall_checkout.payment_subtitle_tooltip")}
            >
              <Icon type="12-question-mark" size={12} color="text-primary" />
            </Tippy>
          </span>
        )
      }
    >
      <div data-testid="checkout-form-card-field-wrapper">
        <CardElement
          options={stripeCardElementOptions}
          onChange={handleOnChangeCard}
          onReady={handleOnReadyCard}
        />
      </div>
    </CheckoutFormFieldWrapper>
  );
};
