import { t } from "i18n-js";
import { Button } from "@circle-react-uikit/Button";
import { Typography } from "@circle-react-uikit/Typography";
import { usePricingTabContext } from "./hooks/usePricingTabContext";

export interface AddPriceButtonProps {
  variant: "primary" | "secondary" | "circle";
}

export const AddPriceButton = ({ variant }: AddPriceButtonProps) => {
  const { showAddPriceModal, disableAddPriceButton } = usePricingTabContext();

  const textColor = variant === "primary" ? "text-secondary" : "text-primary";
  const isDisabled = disableAddPriceButton();

  return (
    <Button
      type="button"
      variant={variant}
      onClick={showAddPriceModal}
      className="whitespace-nowrap"
      disabled={isDisabled}
      large
    >
      <Typography.LabelSm weight="semibold" color={textColor}>
        {t("settings.paywalls.modal.tabs.pricing.actions.add_price")}
      </Typography.LabelSm>
    </Button>
  );
};
