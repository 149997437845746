import {
  DIRTY_FORM_INITIAL_STATES,
  useCustomizeSpaceContext,
} from "@circle-react/contexts/customizeSpaceContext";

export const useDirtyFields = () => {
  const { dirtyForms, setDirtyForms } = useCustomizeSpaceContext();
  const { customize, options } = dirtyForms || DIRTY_FORM_INITIAL_STATES;

  const isDirty =
    Object.values(customize).some(Boolean) ||
    Object.values(options).some(Boolean);

  const resetDirtyFields = () => {
    setDirtyForms(DIRTY_FORM_INITIAL_STATES);
  };

  return {
    isDirty,
    resetDirtyFields,
  };
};
