import { t } from "i18n-js";
import { useRoom } from "@circle-react/components/LiveStreams/Room/hooks";
import { useRoomType } from "@circle-react/components/LiveStreams/Room/hooks";
import { useRecordingHoursAddon } from "@circle-react/components/LiveStreams/hooks";
import { useStreamHoursAddon } from "@circle-react/components/LiveStreams/hooks";
import { useLiveStreamHoursAvailable } from "@circle-react/components/LiveStreams/hooks";
import { usePunditUserContext } from "@circle-react/contexts";
import {
  canManageRoom,
  isRecordingEnabled,
  roomHostCapacityReached,
} from "@circle-react/helpers/liveStreamRoomHelpers";
import { roomTypeData } from "@circle-react/helpers/liveStreamRoomHelpers";
import { useToast } from "@circle-react-uikit/ToastV2";

export const useLivePrejoinAddonsPurchaseFlow = joinAsHost => {
  const { currentCommunityMember } = usePunditUserContext();
  const isEnabled = currentCommunityMember?.is_admin;
  const room = useRoom();
  const { roomType } = useRoomType();
  const {
    streamingHoursAvailable,
    recordingHoursAvailable,
    isLoading: isLoadingUsage,
    streamingTotalPlanHours,
    recordingTotalPlanHours,
  } = useLiveStreamHoursAvailable({ enabled: isEnabled });

  const shouldPurchaseStreamingAddons = isEnabled && !streamingHoursAvailable;
  const shouldPurchaseRecordingAddons =
    isEnabled && !recordingHoursAvailable && isRecordingEnabled(room);

  const showPurchaseRecordingHoursButton =
    shouldPurchaseStreamingAddons || shouldPurchaseRecordingAddons;

  const addonPurchaseNotAllowed =
    !canManageRoom(room) && showPurchaseRecordingHoursButton;
  const allowAddonPurchase =
    canManageRoom(room) && showPurchaseRecordingHoursButton;

  const { success } = useToast();

  const joinAndToast = () => {
    joinAsHost();
    success(t("settings.billing.add_ons.changes_succeeded"));
  };

  const {
    purchaseAsync: purchaseRecordingAddon,
    unitSize: recordingAddonUnitSize,
    currentQuantity: recordingHoursAddonQuantity,
  } = useRecordingHoursAddon(() => joinAndToast(), { enabled: isEnabled });

  const {
    purchaseAsync: purchaseStreamingAddon,
    unitSize: streamingAddonUnitSize,
    currentQuantity: streamHoursAddonQuantity,
  } = useStreamHoursAddon(() => joinAndToast(), { enabled: isEnabled });

  const purchaseRecordingHours = async () => {
    await purchaseRecordingAddon(recordingAddonUnitSize);
  };

  const purchaseStreamingHours = async () => {
    await purchaseStreamingAddon(streamingAddonUnitSize);
  };

  const boxMessage = () => {
    if (addonPurchaseNotAllowed)
      return t(
        `live_streams.pre_join.${roomTypeData(room)}.non_host_upgrade_message`,
      );
    if (shouldPurchaseStreamingAddons)
      return t("live_streams.pre_join.streaming_limit_exceeded", {
        hours: streamingTotalPlanHours + streamHoursAddonQuantity,
      });
    if (shouldPurchaseRecordingAddons)
      return t("live_streams.pre_join.recording_limit_exceeded", {
        hours: recordingTotalPlanHours + recordingHoursAddonQuantity,
      });
    if (roomHostCapacityReached(room))
      return t(
        `live_streams.pre_join.${roomTypeData(
          room,
        )}.host_capacity_reached_message`,
      );
  };

  const onClickHandler = () => {
    if (shouldPurchaseStreamingAddons) return purchaseStreamingHours();
    if (shouldPurchaseRecordingAddons) return purchaseRecordingHours();

    joinAsHost();
  };

  const liveButtonLabel = () => {
    if (allowAddonPurchase)
      return t(`live_streams.pre_join.${roomType}.upgrade_and_continue`);

    return t("live_streams.pre_join.conference.room_button_label");
  };

  const backstageButtonLabel = () => {
    if (allowAddonPurchase)
      return t(`live_streams.pre_join.${roomType}.upgrade_and_continue`);

    return t(`live_streams.pre_join.${roomType}.enter_backstage_button_label`);
  };

  return {
    addonPurchaseNotAllowed,
    isLoadingUsage,
    onClickHandler,
    liveButtonLabel,
    backstageButtonLabel,
    boxMessage,
  };
};
