import { useCallback, useEffect, useRef, useState } from "react";
import { useLocalStorage } from "@circle-react/hooks/utils/useLocalStorage";
import type { ViewSwitcherLayoutTypes } from "@circle-react-shared/ViewSwitcher";

const STORAGE_ACTIVE_VIEW_KEY = "members-directory-view-v2";

interface ActiveViewStorage {
  defaultCommunityView: ViewSwitcherLayoutTypes;
  defaultUserView: ViewSwitcherLayoutTypes;
}

interface UseActiveViewProps {
  defaultCommunityView: ViewSwitcherLayoutTypes;
  views: Record<string, ViewSwitcherLayoutTypes>;
  activeStorageKey?: string;
}
/**
 * Logic for storing and retrieving the active view for a user.
 *
 * | **Case**                                                                                         | **Outcome**            |
 * |--------------------------------------------------------------------------------------------------|------------------------|
 * | User has never visited the directory                                                             | default community view |
 * | User has visited the directory and has a stored view                                             | stored view            |
 * | User has visited the directory and has a stored view, but the default community view has changed | default community view |
 * | Community does not have default community view                                                   | list view              |
 *
 * @param defaultCommunityView Default for community
 * @returns useState hook for active view
 */
export const useActiveView = ({
  defaultCommunityView,
  views,
  activeStorageKey = STORAGE_ACTIVE_VIEW_KEY,
}: UseActiveViewProps): [
  ViewSwitcherLayoutTypes,
  (value: ViewSwitcherLayoutTypes) => void,
] => {
  const { getValue, setValue } =
    useLocalStorage<ActiveViewStorage>(activeStorageKey);
  const defaultCommunityViewRef = useRef(defaultCommunityView);

  const getDefaultView = useCallback(() => {
    const storedViews = getValue();
    const { current: defaultCommunityView } = defaultCommunityViewRef;
    if (!storedViews) {
      return defaultCommunityView ?? views.LIST;
    }

    const {
      defaultCommunityView: previousDefaultCommunityView,
      defaultUserView,
    } = storedViews;
    if (previousDefaultCommunityView === defaultCommunityView) {
      return defaultUserView;
    }
    return defaultCommunityView ?? views.LIST;
  }, [getValue]);

  const [activeView, setActiveView] = useState(getDefaultView);

  useEffect(() => {
    setValue({
      defaultCommunityView: defaultCommunityViewRef.current,
      defaultUserView: activeView,
    });
  }, [activeView, setValue]);

  useEffect(() => {
    defaultCommunityViewRef.current = defaultCommunityView;
    setActiveView(getDefaultView());
  }, [defaultCommunityView, getDefaultView]);

  return [activeView, setActiveView];
};
