import { useEffect } from "react";
import { isParticipantInactive } from "@circle-react/helpers/liveStreamRoomHelpers";
import { useLeaveRoom } from "./useLeaveRoom";

export const useComponentCloseListener = (isConnected, currentParticipant) => {
  const leaveRoom = useLeaveRoom();

  useEffect(() => {
    if (
      !isConnected ||
      !currentParticipant ||
      isParticipantInactive(currentParticipant) ||
      !leaveRoom
    ) {
      return;
    }

    // 100ms recommended window events
    window.addEventListener("beforeunload", leaveRoom);
    window.addEventListener("onunload", leaveRoom);

    return () => {
      window.removeEventListener("beforeunload", leaveRoom);
      window.removeEventListener("onunload", leaveRoom);
    };
  }, [isConnected, currentParticipant?.id, leaveRoom]);

  // Leave when component is unmounting
  useEffect(() => {
    if (!currentParticipant || isParticipantInactive(currentParticipant)) {
      return;
    }

    return () => {
      if (
        leaveRoom &&
        currentParticipant &&
        !isParticipantInactive(currentParticipant)
      ) {
        leaveRoom();
      }
    };
  }, [currentParticipant?.id]);
};
