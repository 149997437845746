import { Suspense } from "react";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { PageLoader } from "@circle-react/pages/PageLoader";

const WorkflowsSettingsComponent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "WorkflowsSettingsPage" */ "@circle-react/components/SettingsApp/Workflows"
    ),
);

export const WorkflowsSettingsPage = () => (
  <Suspense fallback={<PageLoader />}>
    <WorkflowsSettingsComponent />
  </Suspense>
);
