import { t } from "i18n-js";
import { Typography } from "@circle-react/components/shared/uikit/Typography";
import { Button } from "@circle-react-shared/uikit/Button";

export const WARNING_WHEN_IN_QUEUE = "in_queue";
export const WARNING_WHEN_CHATTING = "chatting";

export const LiveChatLeaveDialog = ({ onStay, onLeave, warningType }) => {
  const explanation =
    warningType == WARNING_WHEN_IN_QUEUE
      ? t(
          "support_widget.chatbot.live_chat_leave_dialog.explanation_when_in_queue",
        )
      : t(
          "support_widget.chatbot.live_chat_leave_dialog.explanation_when_chatting",
        );
  return (
    <div className="my-auto px-14">
      <div className="flex flex-col gap-9">
        <div className="flex flex-col gap-2 text-center text-sm">
          <Typography.TitleSm weight="bold">
            <span className="text-lg">
              {t("support_widget.chatbot.live_chat_leave_dialog.are_you_sure")}
            </span>
          </Typography.TitleSm>
          <div className="px-4">
            <Typography.BodySm>{explanation}</Typography.BodySm>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <Button
            large
            full
            className="!bg-[#2B2E33] dark:!bg-white dark:text-[#19283a]"
            onClick={onStay}
          >
            {t("support_widget.chatbot.live_chat_leave_dialog.stay")}
          </Button>
          <Button variant="secondary" full large onClick={onLeave}>
            {t("support_widget.chatbot.live_chat_leave_dialog.leave")}
          </Button>
        </div>
      </div>
    </div>
  );
};
