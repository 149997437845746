import { useLiveStreamContextData } from "@circle-react/contexts/LiveStreams";
import { PARTICIPANT_ROLES } from "@circle-react/helpers/liveStreamRoomHelpers";
import { useProviderLocalParticipant } from "./provider/useProviderLocalParticipant";

export const useLocalParticipant = () => {
  const { currentParticipant, room } = useLiveStreamContextData();
  const localProviderParticipant = useProviderLocalParticipant();
  const isCurrentParticipantModerator =
    localProviderParticipant?.roleName === PARTICIPANT_ROLES.MODERATOR;
  const isCurrentParticipantRoomCreator =
    room?.creator?.id === currentParticipant?.community_member_id;
  const isCurrentParticipantSpectator =
    localProviderParticipant?.roleName === PARTICIPANT_ROLES.SPECTATOR ||
    localProviderParticipant?.roleName === PARTICIPANT_ROLES.HLS_SPECTATOR;
  return {
    isCurrentParticipantModerator,
    isCurrentParticipantRoomCreator,
    isCurrentParticipantSpectator,
  };
};
