import PropTypes from "prop-types";
import { Button } from "@circle-react-uikit/Button";
import { useModalFooter } from "./hooks/useModalFooter";
import "./style.scss";

export const ModalFooter = ({
  isSubmitting,
  mutation,
  activeTab,
  shouldBlockSubmitButton,
  currentPaywallStatus,
}) => {
  const {
    isLastStep,
    isEditingMode,
    currentPrimaryButtonState,
    currentSecondaryButtonState,
  } = useModalFooter({ activeTab, currentPaywallStatus });

  return (
    <div className="paywall-admin-modal__footer__container border-t-primary border-t">
      {(isLastStep || isEditingMode) && (
        <Button
          type="submit"
          variant={currentSecondaryButtonState.variant}
          disabled={
            isSubmitting || mutation.isLoading || shouldBlockSubmitButton
          }
          onClick={currentSecondaryButtonState.onClick}
        >
          {currentSecondaryButtonState.buttonLabel}
        </Button>
      )}
      <Button
        type="submit"
        variant={currentPrimaryButtonState.variant}
        disabled={isSubmitting || mutation.isLoading || shouldBlockSubmitButton}
        onClick={currentPrimaryButtonState.onClick}
      >
        {currentPrimaryButtonState.buttonLabel}
      </Button>
    </div>
  );
};

ModalFooter.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  mutation: PropTypes.object.isRequired,
  activeTab: PropTypes.string.isRequired,
  shouldBlockSubmitButton: PropTypes.bool.isRequired,
  currentPaywallStatus: PropTypes.string,
};
