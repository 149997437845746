import { useIsV3 } from "@circle-react/hooks/useIsV3";

export const useHeightStyles = () => {
  const { isV3Enabled } = useIsV3();

  const topBarStyles = isV3Enabled
    ? "lg:h-[calc(100vh-64px)]"
    : "lg:h-[calc(100vh-48px)]";

  const topBarStylesWithMasquerade = isV3Enabled
    ? "lg:h-[calc(100vh-48px-64px)]"
    : "lg:h-[calc(100vh-48px-48px)]";

  const topBarStylesWithEitherOptInOrPreviewBar = isV3Enabled
    ? "lg:h-[calc(100vh-64px-48px)]"
    : "lg:h-[calc(100vh-96px)]";

  const topBarStylesWithMasqueradeAndEitherOptInOrPreviewBar = isV3Enabled
    ? "lg:h-[calc(100vh-48px-48px-64px)]"
    : "lg:h-[calc(100vh-48px-48px-48px)]";

  return {
    topBarStyles,
    topBarStylesWithMasquerade,
    topBarStylesWithEitherOptInOrPreviewBar,
    topBarStylesWithMasqueradeAndEitherOptInOrPreviewBar,
  };
};
