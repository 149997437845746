import { formattedMessageSentAt } from "@circle-react/helpers/dateTimeHelpers/formattedMessageSentAt";
import { TiptapEditor } from "@circle-react-shared/uikit/TipTap";
import { Typography } from "@circle-react-shared/uikit/Typography";

interface ContentBlockProps {
  authorName: string;
  timestamp: string;
  body: object;
}
export const ContentBlock = ({
  authorName,
  timestamp,
  body,
}: ContentBlockProps) => {
  const time = formattedMessageSentAt(timestamp).time;

  return (
    <div className="min-w-0 grow">
      <div className="flex items-center gap-1.5">
        <Typography.LabelSm weight="semibold" color="text-darkest" truncate>
          {authorName}
        </Typography.LabelSm>
        <div className="min-w-fit">
          <Typography.LabelSm weight="medium" color="text-lightest">
            {time}
          </Typography.LabelSm>
        </div>
      </div>
      <div className="text-dark whitespace-pre-line text-sm font-normal">
        <TiptapEditor editable={false} rich_text_body={body} />
      </div>
    </div>
  );
};
