// TODO: Implement livekit logic
import { noop } from "lodash";

export const useLivekitRoleChange = () => ({
  hasRoleChangeRequest: false,
  requestedByPeer: null,
  requestRoleChange: noop,
  acceptRoleChange: noop,
  rejectRoleChange: noop,
});
