import { t } from "i18n-js";
import { BaseResource } from "../../BaseResource";

export interface CommunityResourcePropType {
  resourceId: number;
}

export const CommunityResource = ({
  resourceId,
}: CommunityResourcePropType) => {
  const value = String(resourceId);

  return (
    <BaseResource
      label={t(
        "settings.workflows.form.events.community_member_joined_community.community_id",
      )}
      value={value}
    />
  );
};
