import { Modal } from "@circle-react-shared/uikit/ModalV2";

export const PostBodySkeleton = () => (
  <Modal.Body className="!px-0 !pt-0">
    <div className="px-5 pt-3 md:px-8">
      <div className="flex flex-col gap-5">
        <div className="flex gap-3">
          <div className="bg-tertiary h-10 w-10 animate-pulse rounded-full" />
          <div className="flex w-full flex-col gap-2">
            <div className="bg-tertiary h-4 w-3/5 animate-pulse rounded-md" />
            <div className="bg-tertiary h-4 w-2/5 animate-pulse rounded-md" />
          </div>
        </div>
        <div className="bg-tertiary h-80 w-full animate-pulse rounded-md" />
      </div>
    </div>
  </Modal.Body>
);
