import { t } from "i18n-js";
import { Avatar } from "@circle-react/components/SettingsApp/Workflows/components/Avatar";
import { Typography } from "@circle-react-uikit/Typography";
import { SpaceFromId } from "./SpaceFromId";

export interface HistoryDetailViewSpaceProps {
  body: {
    executor_member_id: number;
  };
  url: string;
}

export const HistoryDetailViewSpace = ({
  body,
  url,
}: HistoryDetailViewSpaceProps) => {
  const { executor_member_id: executorMemberId } = body;

  // get id from the url /api/actions/v1/spaces/:id/space_members.
  const spaceId = new URL(url).pathname.split("/")[5];

  return (
    <div className="flex flex-col gap-2">
      <div className="md:flex">
        <div className="w-1/4">
          <Typography.LabelSm weight="semibold">
            {t("settings.workflows.action_messages.space")}
          </Typography.LabelSm>
        </div>
        <div className="w-3/4">
          <SpaceFromId id={spaceId} />
        </div>
      </div>
      <div className="md:flex">
        <div className="w-1/4">
          <Typography.LabelSm weight="semibold">
            {t("settings.workflows.action_messages.member")}
          </Typography.LabelSm>
        </div>
        <div className="w-3/4">
          <Avatar communityMemberId={executorMemberId} />
        </div>
      </div>
    </div>
  );
};
