export const columnStyles = {
  // eslint-disable-next-line no-restricted-syntax -- Not a translatable string
  title: "md:w-1/3 xl:w-1/4",
  steps: "hidden xl:table-cell min-w-[11rem]",
  user: "hidden md:table-cell min-w-[9rem] max-w-[16rem] truncate text-ellipsis",
  status: "min-w-[9rem]",
  workflowType: "min-w-[8rem]",
  scheduleType: "min-w-[8rem]",
  dateShort: "hidden 2xl:table-cell min-w-[8rem]",
  dateLong: "hidden 2xl:table-cell min-w-[12rem]",
  number: "hidden xl:table-cell min-w-[6rem]",
  toggleInput: "2xl:max-w-auto 2xl:min-w-[6rem]",
  uuid: "hidden 2xl:table-cell max-w-[12rem]",
};
