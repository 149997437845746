import { Suspense } from "react";
import { VersionedContent } from "@circle-react/components/Layout/VersionedContent";
import { LeaderboardDashboard } from "@circle-react/components/Leaderboard/Dashboard";
import { LeaderboardLandingPage } from "@circle-react/components/Leaderboard/LandingPage";
import { useGamificationSettings } from "@circle-react/components/SettingsApp/Gamification/LevelsForm/gamificationApi";
import { PageLoader } from "@circle-react/pages/PageLoader";

export const LeaderboardPage = () => {
  const { data, isLoading } = useGamificationSettings();

  if (isLoading) {
    return <PageLoader />;
  }
  return (
    <VersionedContent fullWidth>
      <Suspense fallback={<PageLoader />}>
        {data?.enabled ? <LeaderboardDashboard /> : <LeaderboardLandingPage />}
      </Suspense>
    </VersionedContent>
  );
};
