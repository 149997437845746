import { useCommunitySwitcher } from "@circle-react/hooks/community_switcher/useCommunitySwitcherApi";
import { CommunitySection } from "./CommunitySection";
import { CommunitySwitcherPresentation } from "./CommunitySwitcherPresentation";

export interface CommunitySwitcherContentProps {
  isLoadingAppData?: boolean;
}

export const CommunitySwitcherContent = ({
  isLoadingAppData = false,
}: CommunitySwitcherContentProps) => {
  const { communities, token, isLoadingCommunities } = useCommunitySwitcher();
  const isLoading = isLoadingAppData || isLoadingCommunities;

  return (
    <CommunitySwitcherPresentation
      id="community-switcher"
      isLoading={isLoading}
    >
      {communities.map((community, index) => (
        <CommunitySection
          key={community.id}
          community={community}
          token={token}
          index={index}
        />
      ))}
    </CommunitySwitcherPresentation>
  );
};
