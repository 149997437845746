import { useEffect, useRef } from "react";
import autosize from "autosize";
import classnames from "classnames";
import { t } from "i18n-js";
import { useDropzone } from "react-dropzone";
import { useFormContext, useWatch } from "react-hook-form";
import { Icon } from "@circle-react/components/shared/Icon";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { Form } from "@circle-react-uikit/Form";
import "./styles.css";

export const LiveChatForm = ({
  onSubmit,
  uploadAttachments,
  uploadingEnabled,
}) => {
  const { handleSubmit, reset } = useFormContext();
  const message = useWatch({ name: "message" });
  const isFormDirty = message;
  const textareaRef = useRef(null);

  // const isSubmitAllowed =
  //   !isSubmitting && isFormDirty && allAttachmentsComplete;
  const isSubmitAllowed = isFormDirty;

  const { getRootProps, getInputProps, inputRef } = useDropzone({
    onDrop: uploadAttachments,
    noClick: true,
    multiple: false,
  });

  const openFilePicker = event => {
    event.preventDefault();
    inputRef.current.click();
  };

  const handleTextAreaInputChange = () => {
    autosize(textareaRef.current);
  };
  const handleOnEnter = event => {
    if (!event.shiftKey && event.key == "Enter") {
      handleSubmit(onSubmit)();
      reset();
      event.preventDefault();
    }
  };

  const handleIconClick = () => {
    if (isSubmitAllowed) {
      handleSubmit(onSubmit)();
      reset();
    }
  };

  useEffect(() => {
    handleTextAreaInputChange();
  }, []);

  return (
    <>
      <Form.Input
        name="message"
        variant="textarea"
        label={null}
        placeholder={t("support_widget.chatbot.message_placeholder")}
        ref={textareaRef}
        rows={1}
        onInput={handleTextAreaInputChange}
        onKeyDown={handleOnEnter}
        hideBorder
        focusRequired={false}
        className="form-control hide-scrollbar !mb-1 !mt-2 max-h-[128px] resize-none !py-3 !pl-6 focus:!ring-0 focus:!ring-offset-0"
      />
      <div className="flex justify-between pb-4 pl-6 pr-4" {...getRootProps()}>
        <input {...getInputProps()} />
        <TippyV2
          placement="right"
          content={
            uploadingEnabled
              ? t("tiptap.attach_file")
              : t("support_widget.chatbot.live_chat_send_a_message_first")
          }
          className={classnames({
            "hover:text-circle focus:text-circle text-dark visited:text-dark":
              uploadingEnabled,
            "text-dark visited:text-dark": !uploadingEnabled,
          })}
        >
          <button
            type="submit"
            aria-label={t("tiptap.attach_file")}
            onClick={openFilePicker}
            disabled={!uploadingEnabled}
            className={classnames({ "cursor-not-allowed": !uploadingEnabled })}
          >
            <Icon type="tiptap-paperclip" size={20} />
          </button>
        </TippyV2>
        <span
          className={classnames({
            "text-circle dark:text-circle visited:text-circle": isSubmitAllowed,
            "text-dark visited:text-dark": !isSubmitAllowed,
          })}
        >
          <Icon
            type="tiptap-send"
            className={classnames({
              "cursor-pointer": isSubmitAllowed,
              "cursor-not-allowed": !isSubmitAllowed,
            })}
            onClick={handleIconClick}
            size={20}
          />
        </span>
      </div>
    </>
  );
};
