import PropTypes from "prop-types";
import { t } from "i18n-js";
import { SortByItemLinks } from "@circle-react/components/Spaces/Header/SpaceHeaderActions/SortByDropdown";
import { Dropdown } from "@circle-react-uikit/Dropdown";

export const MembersSortOptions = ({ space }) => (
  <>
    <Dropdown.Header>{t("space_header.sort_order")}</Dropdown.Header>
    <SortByItemLinks
      defaultSort={space.default_member_sort || "latest"}
      showDescription={false}
      include={["latest", "oldest", "alphabetical"]}
    />
  </>
);

MembersSortOptions.propTypes = {
  space: PropTypes.object.isRequired,
};
