import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { arrayMove } from "react-sortable-hoc";
import { usePunditUserContext } from "@circle-react/contexts";
import { reactQueryPut } from "@circle-react/helpers/backendRequestHelpers";
import { isCommunityAdmin } from "@circle-react/helpers/communityMemberHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { useBoolean } from "@circle-react/hooks/utils/useBoolean";

export const useLinks = () => {
  const { currentCommunity, currentCommunityMember } = usePunditUserContext();
  const [shouldShowLinks, toggleLinks] = useBoolean(true);
  const [orderedList, setOrderedList] = useState(currentCommunity.links);
  const isAdmin = isCommunityAdmin(currentCommunityMember);

  const { mutate: updateLinksOrder } = useMutation(({ links_order_array }) =>
    reactQueryPut(internalApi.communities.communityLinks.reorder(), {
      links_order_array,
    }),
  );

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const reorderedData = [...arrayMove(orderedList, oldIndex, newIndex)];
    const linksOrderArray = reorderedData.map(link => link.id.toString());

    setOrderedList(reorderedData);
    updateLinksOrder({ links_order_array: linksOrderArray });
  };

  useEffect(() => {
    /*
    TODO - The query with the key "pundit-user" is causing a re-render when sorting items
    resulting in a jump, impacting in the UX. This is a temporal workaround to prevent it.
    */
    const a = currentCommunity.links;
    const b = orderedList;

    if (a.length !== b.length) {
      setOrderedList(currentCommunity.links);
      return;
    }
    if (
      a.some(link => link.name !== b.find(l => l.id === link.id).name) ||
      a.some(link => link.url !== b.find(l => l.id === link.id).url)
    ) {
      setOrderedList(currentCommunity.links);
    }
  }, [currentCommunity.links, orderedList]);

  const canSeeLinks = isAdmin || currentCommunity.links.length > 0;

  return {
    canSeeLinks,
    shouldShowLinks,
    toggleLinks,
    orderedList,
    onSortEnd,
  };
};
