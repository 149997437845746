import PropTypes from "prop-types";
import { FormProvider, useForm } from "react-hook-form";
import { useGetDefaultLockscreenBlocks } from "../hooks";
import { FormBlocksProvider } from "./FormBlocksProvider";
import { PaywallBlockProvider } from "./PaywallBlockProvider";

export const LockScreenProviders = ({ children, space = {} }) => {
  const blocks = useGetDefaultLockscreenBlocks(space);

  const formMethods = useForm({
    defaultValues: { lock_screen_blocks: blocks },
  });

  return (
    <FormProvider {...formMethods}>
      <FormBlocksProvider name="lock_screen_blocks">
        <PaywallBlockProvider>{children}</PaywallBlockProvider>
      </FormBlocksProvider>
    </FormProvider>
  );
};

LockScreenProviders.propTypes = {
  children: PropTypes.node,
  space: PropTypes.object,
};
