import type { PropsWithChildren } from "react";
import classnames from "classnames";

interface ListContainerProp {
  className?: string;
}

export const ListContainer = ({
  children,
  className,
}: PropsWithChildren<ListContainerProp>) => (
  <div
    className={classnames(
      "md:divide-primary md:grid md:flex-auto md:grid-cols-2 md:divide-x",
      className,
    )}
  >
    {children}
  </div>
);
