import { PropTypes } from "prop-types";
import { DynamicWorkflowToggle } from "@circle-workflows/components/DynamicWorkflowForm/DynamicWorkflowToggle";
import { useHeaderActions } from "@circle-workflows/components/ViewWorkflowModal/Header/HeaderActions/useHeaderActions";
import { WORKFLOW_STATUS } from "@circle-react/components/SettingsApp/Workflows/constants";

export const ActiveToggle = ({ workflow }) => {
  const { id: workflowId, status, steps } = workflow;
  const hasActions = Boolean(steps?.length);
  const isActive = status === WORKFLOW_STATUS.ACTIVE;
  const isDraft = status === WORKFLOW_STATUS.DRAFT;

  const { onChangeActiveStatus, isLoading } = useHeaderActions({
    workflowId,
    isActive,
  });

  return (
    <DynamicWorkflowToggle
      isWorkflowActive={isActive}
      isDisabled={!hasActions || isDraft}
      hasNoActions={!hasActions}
      isDraft={isDraft}
      isLoading={isLoading}
      onChange={onChangeActiveStatus}
    />
  );
};

ActiveToggle.propTypes = {
  workflow: PropTypes.object.isRequired,
};
