import { compact } from "lodash";
import { useFormContext } from "react-hook-form";

export const usePaywallsBlock = () => {
  const formContext = useFormContext();
  const paywallWithErrorsByIndex: Array<boolean> = [];

  if (formContext) {
    const { errors } = formContext.formState;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- Hard to fix type issue, disabling until we convert all files to ts
    // @ts-ignore-next-line
    const paywallBlocks = errors?.lock_screen_blocks?.find(
      (blockData: any) => blockData?.data?.paywalls,
    );
    compact(paywallBlocks?.data?.paywalls || []).forEach((paywall: any) => {
      if (paywall?.id?.ref?.name) {
        const index = parseInt(
          paywall.id.ref.name.match(/paywalls.(\d).id+/)[1],
        );
        paywallWithErrorsByIndex[index] = true;
      }

      if (paywall?.web_paywall_price?.id?.ref?.name) {
        const index = parseInt(
          paywall.web_paywall_price.id.ref.name.match(
            /paywalls.(\d).web_paywall_price+/,
          )[1],
        );
        paywallWithErrorsByIndex[index] = true;
      }
    });
  }

  return {
    paywallWithErrorsByIndex,
    isEditing: !!formContext,
    isPreviewing: !formContext,
  };
};
