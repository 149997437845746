import { Link } from "react-router-dom";

export const Breadcrumb = ({
  hidden = false,
  backLink = "",
  backLinkText = I18n.t("back"),
}) => {
  if (hidden || !backLink) {
    return null;
  }
  return (
    <div className="header__name">
      {Boolean(backLink) && (
        <div className="header__breadcrumb">
          <Link to={backLink}>{backLinkText}</Link>
        </div>
      )}
    </div>
  );
};
