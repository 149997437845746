import type { PropsWithChildren } from "react";
import classNames from "classnames";

export interface ItemProp {
  isDisabled?: boolean;
  onClick?: () => void;
  className?: string;
}

export const Item = ({
  children,
  isDisabled = false,
  onClick,
  className = "",
}: PropsWithChildren<ItemProp>) => {
  const isOnClickAvailable = Boolean(onClick);

  return (
    <li className={classNames("group", className)}>
      <button
        type="button"
        className={classNames(
          "flex w-full list-none items-center justify-between rounded-md p-2 text-sm transition-colors md:px-4",
          {
            "hover:bg-tertiary group-focus-within:bg-tertiary cursor-pointer":
              !isDisabled && isOnClickAvailable,
            "cursor-default opacity-50": isDisabled,
          },
        )}
        onClick={() => {
          !isDisabled && onClick?.();
        }}
      >
        {children}
      </button>
    </li>
  );
};
