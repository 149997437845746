import { useActiveSpaceMembersCount } from "@circle-react/contexts/useActiveSpaceMembersCount";
import { toLocaleString } from "@circle-react/helpers/number";
import type { Space } from "@circle-react/types";

export interface UseCustomizeMembersProps {
  space: Space;
}

export const useCustomizeMembers = ({ space }: UseCustomizeMembersProps) => {
  const { count: activeSpaceMembersCount, isLoading } =
    useActiveSpaceMembersCount({ spaceId: space?.id });

  const customizeMembersBadge =
    "" + (isLoading ? "" : toLocaleString(activeSpaceMembersCount));

  return {
    customizeMembersBadge,
  };
};
