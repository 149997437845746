import { useEffect } from "react";
import { t } from "i18n-js";
import { useFormContext } from "react-hook-form";
import { Dropdown } from "@circle-react-uikit/Dropdown";
import {
  CUSTOM_RANGE,
  LAST_FOUR_WEEKS,
  LAST_SEVEN_DAYS,
  LAST_THREE_MONTHS,
  LAST_TWELVE_MONTHS,
  LOCALE_ROOT,
  MONTH_TO_DATE,
  QUARTER_TO_DATE,
  YEAR_TO_DATE,
} from "../constants";
import { useDateRangePickerContext } from "./DateRangePickerContextProvider";

const dateMethodObject: any = {
  [LAST_SEVEN_DAYS]: (today = new Date()) => ({
    startDate: new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - 7,
    ),
    endDate: today,
  }),
  [LAST_FOUR_WEEKS]: (today = new Date()) => ({
    startDate: new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - 28,
    ),
    endDate: today,
  }),
  [LAST_THREE_MONTHS]: (today = new Date()) => ({
    startDate: new Date(
      today.getFullYear(),
      today.getMonth() - 3,
      today.getDate(),
    ),
    endDate: today,
  }),
  [LAST_TWELVE_MONTHS]: (today = new Date()) => ({
    startDate: new Date(
      today.getFullYear(),
      today.getMonth() - 12,
      today.getDate(),
    ),
    endDate: today,
  }),
  [MONTH_TO_DATE]: (today = new Date()) => ({
    startDate: new Date(today.getFullYear(), today.getMonth(), 1),
    endDate: today,
  }),
  [QUARTER_TO_DATE]: (today = new Date()) => {
    const currentQuarter = Math.floor(today.getMonth() / 3);
    const currentYear = today.getFullYear();

    return {
      startDate: new Date(currentYear, currentQuarter * 3, 1),
      endDate: today,
    };
  },
  [YEAR_TO_DATE]: (today = new Date()) => ({
    startDate: new Date(
      today.getFullYear() - 1,
      today.getMonth(),
      today.getDate(),
    ),
    endDate: today,
  }),
};

export const DropdownOptions = () => {
  const { queryParamsKey } = useDateRangePickerContext();
  const { watch, setValue } = useFormContext();

  const optionField = watch(queryParamsKey.default);

  useEffect(() => {
    if (!dateMethodObject[optionField]) {
      return;
    }

    const { startDate, endDate } = dateMethodObject[optionField]();

    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);

    setValue(queryParamsKey.gte, startDate.getTime());
    setValue(queryParamsKey.lte, endDate.getTime());
  }, [optionField, queryParamsKey.gte, queryParamsKey.lte, setValue]);

  const onChange = (value: any) => {
    setValue(queryParamsKey.default, value);
  };

  const options = [
    LAST_SEVEN_DAYS,
    LAST_FOUR_WEEKS,
    LAST_THREE_MONTHS,
    LAST_TWELVE_MONTHS,
    MONTH_TO_DATE,
    QUARTER_TO_DATE,
    YEAR_TO_DATE,
    CUSTOM_RANGE,
  ].map(dateRangeType => ({
    value: dateRangeType,
    label: t([LOCALE_ROOT, "options", dateRangeType]),
  }));

  return (
    <Dropdown
      onChange={onChange}
      options={options}
      size="full"
      className="w-full rounded-lg border px-4 py-2"
      buttonWrapperClassName="flex flex-col"
      linkType="button"
      popperOptions={{
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [34, 15],
            },
          },
        ],
      }}
      button={
        <Dropdown.ActiveLabelButton
          className="flex w-full justify-between"
          options={options}
          activeOptionValue={optionField || LAST_SEVEN_DAYS}
        />
      }
    />
  );
};
