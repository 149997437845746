import { t } from "i18n-js";
import { SettingsHeader } from "@/react/components/SettingsApp/SettingsHeader";
import { AgentsBanner } from "./AgentsBanner";

export const CommunityBotAgents = () => (
  <div className="bg-secondary flex h-full flex-col">
    <SettingsHeader pageTitle={t("settings.community_bot.agents.title")} />
    <AgentsBanner />
  </div>
);
