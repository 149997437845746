import { createContext, useContext } from "react";
import { DEFAULT_PAGE_SIZE } from "@circle-react/hooks/useAllWorkflowsInfiniteQuery";

interface WorkflowsContextProp {
  pageSize?: number;
  category?: "paywalls" | "onboarding" | "administrative";
}

export interface WorkflowsDataProviderProp extends WorkflowsContextProp {
  children: any;
}

const WorkflowsData = createContext<WorkflowsContextProp>({
  pageSize: DEFAULT_PAGE_SIZE,
});
WorkflowsData.displayName = "WorkflowsData";

export const useWorkflowsData = () => useContext(WorkflowsData);

export const WorkflowsDataProvider = ({
  children,
  pageSize = DEFAULT_PAGE_SIZE,
  category,
}: WorkflowsDataProviderProp) => {
  const valuesToStore = { pageSize, category };

  return (
    <WorkflowsData.Provider value={valuesToStore}>
      {children}
    </WorkflowsData.Provider>
  );
};
