import { t } from "i18n-js";
import { useModal } from "@circle-react/components/Layout/ModalManagerProvider";
import { Loader } from "@circle-react-shared/uikit/Loader";
import { Modal } from "@circle-react-uikit/ModalV2";
import { Content } from "../SnippetForm/Content";
import { Header } from "../SnippetForm/Header";
import { useEditSnippet, useShowSnippet } from "../queries";

interface ApiEditSnippetResponse {
  id: number;
  title: string;
}

export const EditSnippetModalComponent = ({ id }: { id: number }) => {
  const modal = useModal();
  const editSnippetMutation = useEditSnippet({ onSuccess: modal.remove });
  const { data: snippetData, isLoading: isSnippetDataLoading } = useShowSnippet(
    { id },
  );

  return (
    <Modal isOpen={modal.visible} onClose={modal.hide}>
      <Modal.Overlay />
      <Modal.Content
        size="auto"
        className="h-full max-h-full max-w-[720px] overflow-visible !rounded-t-none sm:h-full sm:!rounded-t-lg md:max-h-[80vh] lg:max-h-[550px]"
        animations="fadeIn"
      >
        <Header
          title={t(
            "settings.community_bot.knowledge.custom.update_modal.title",
          )}
        />
        {isSnippetDataLoading ? (
          <Loader />
        ) : (
          <Content
            defaultValues={snippetData}
            onSubmit={(data: ApiEditSnippetResponse) =>
              editSnippetMutation.mutate({ ...data, id })
            }
            actionLabel={t("settings.community_bot.knowledge.custom.save")}
          />
        )}
      </Modal.Content>
    </Modal>
  );
};
