import I18n from "i18n-js";
import { useSpacesContext } from "@circle-react/contexts";
import { DataTable } from "@circle-react-shared/uikit/DataTableV2";
import { ItemWithLink } from "@circle-react-shared/uikit/Dropdown/ItemWithLink";
import { useModalData } from "@circle-react-shared/uikit/ModalV2";
import { usePricingTabContext } from "../hooks/usePricingTabContext";
import { useRemovePriceConfirmationModal } from "./RemovePriceConfirmationModal";

export const PricingTabPriceListCellActions = ({
  row: { original: price },
}: any) => {
  const { show: showRemovePriceConfirmationModal } =
    useRemovePriceConfirmationModal();
  const { records: spaceRecords } = useSpacesContext();
  const { contentPortalElement } = useModalData();
  const { getPrices, markPriceAsPreferred, removePrice } =
    usePricingTabContext();

  const prices = getPrices();
  const isLastPrice = prices.length === 1;

  if (isLastPrice && price.preferred) {
    return null;
  }

  const isSpaceContainPaywallLockScreen = (space: any) => {
    const paywallLockScreenBlock = space.lock_screen_blocks?.find(
      (lockScreenBlock: any) => lockScreenBlock?.data?.paywalls,
    );
    return !!paywallLockScreenBlock?.data?.paywalls?.find(
      (paywallLockScreenBlock: any) =>
        price?.id &&
        (paywallLockScreenBlock?.web_paywall_price?.id === price?.id ||
          paywallLockScreenBlock?.mobile_paywall_price?.id === price?.id),
    );
  };

  const removePriceOnClick = () => {
    const spacesWithPaywallLockScreenToRemove = spaceRecords.filter(
      (space: any) => isSpaceContainPaywallLockScreen(space),
    );

    if (spacesWithPaywallLockScreenToRemove.length > 0) {
      void showRemovePriceConfirmationModal({
        action: () => removePrice(price),
        spaces: spacesWithPaywallLockScreenToRemove,
      });
    } else {
      removePrice(price);
    }
  };

  return (
    <DataTable.Actions
      contentPortalElement={contentPortalElement ?? document.body}
    >
      {!price.preferred && (
        <ItemWithLink
          key="preferred"
          hasDanger={false}
          onClick={() => markPriceAsPreferred(price)}
          linkType="button"
        >
          {I18n.t(
            "settings.paywalls.modal.tabs.pricing.table.actions.preferred",
          )}
        </ItemWithLink>
      )}
      {!isLastPrice && (
        <ItemWithLink
          key="remove"
          hasDanger
          onClick={removePriceOnClick}
          linkType="button"
        >
          {I18n.t("settings.paywalls.modal.tabs.pricing.table.actions.remove")}
        </ItemWithLink>
      )}
    </DataTable.Actions>
  );
};
