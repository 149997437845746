import { useState } from "react";
import { mapCurrencyAmountStrToDecimal } from "@circle-react/components/Paywalls/Admin/utils";
import { isFixed, types } from "@circle-react/helpers/paywallCouponHelpers";
import { TABS } from "../Navigation/constants";
import { usePaywallCouponApi } from "./usePaywallCouponApi";

export const usePaywallCoupon = ({
  paywallCoupon,
  refetchCoupons,
  onClose,
}) => {
  const [activeTab, setActiveTab] = useState(TABS.DETAILS);
  const SEARCH_INVALID_CHARACTERS = /[^\d\\.\\-]/g;
  const SEARCH_DOTS = /\./g;

  const currencyAmountStrToDecimal = currencyAmountStr => {
    // we need to include any new locale where commas are used as decimal separator

    if (window?.locale == "pt") {
      // use dots instead of commas for decimal separator
      return parseFloat(
        String(currencyAmountStr || "0")
          .replace(SEARCH_DOTS, "")
          .replace(",", ".")
          .replace(SEARCH_INVALID_CHARACTERS, ""),
      );
    }

    return mapCurrencyAmountStrToDecimal(currencyAmountStr);
  };

  const getFormDefaults = paywallCoupon => {
    if (paywallCoupon) {
      return {
        name: paywallCoupon.name,
        currency_id: paywallCoupon.currency?.id ?? "",
        active: paywallCoupon.active,
        starts_at: paywallCoupon.starts_at,
        ends_at: paywallCoupon.ends_at,
        paywall_ids: paywallCoupon.paywall_ids,
        all_paywalls: paywallCoupon.all_paywalls.toString(),
        enable_start_date: (!!paywallCoupon.starts_at).toString(),
        enable_end_date: (!!paywallCoupon.ends_at).toString(),
        type: paywallCoupon.type,
      };
    }
    return { type: types.fixed, currency_id: "" };
  };

  const handleSubmit = data => {
    if (activeTab == TABS.DETAILS) {
      setActiveTab(TABS.PAYWALLS);
    } else if (activeTab == TABS.PAYWALLS) {
      setActiveTab(TABS.USAGE);
    } else if (activeTab == TABS.USAGE) {
      let amount = data.amount;
      if (isFixed(data.type)) {
        amount = currencyAmountStrToDecimal(amount);
      }
      return mutation.mutate({ ...data, amount });
    }
  };

  const { createMutation, updateMutation } = usePaywallCouponApi({
    refetchCoupons,
    onClose,
    paywallCoupon,
  });
  const mutation = paywallCoupon ? updateMutation : createMutation;

  return {
    getFormDefaults,
    handleSubmit,
    setActiveTab,
    mutation,
    activeTab,
  };
};
