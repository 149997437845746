import { useMutation } from "react-query";
import { useToast } from "@circle-react-uikit/ToastV2";
import { useWorkflow } from "../components/WorkflowForm/useWorkflow";
import { useActivateWorkflow } from "./useActivateWorkflow";
import { useRunWorkflow } from "./useRunWorkflow";

export const useActivateAndRunWorkflow = (options = {}) => {
  const { isBulkAction } = useWorkflow();
  const toast = useToast();
  const { mutateAsync: activateWorkflow } = useActivateWorkflow();
  const { mutateAsync: runWorkflow } = useRunWorkflow();

  return useMutation(
    async () => {
      await activateWorkflow();
      if (isBulkAction) {
        await runWorkflow();
      }
    },
    {
      onError: error => {
        toast.error(error.message);
      },
      ...options,
    },
  );
};
