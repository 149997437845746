import { useCallback, useRef } from "react";
import {
  selectDominantSpeaker,
  selectSpeakers,
  useHMSStore,
} from "@100mslive/react-sdk";
import { useHMSPeersList } from "./useHMSPeersList";

const assignSelectedSpeaker = (dominantSpeaker, hosts, selectedSpeakerRef) => {
  if (dominantSpeaker) {
    // New dominant speaker, set as selected
    selectedSpeakerRef.current = dominantSpeaker;
  } else if (selectedSpeakerRef.current) {
    // No dominant speaker, update previous selected speaker or assign the first host,
    // This will take care of any video track changes that may occur.
    const selectedSpeaker = selectedSpeakerRef.current;
    selectedSpeakerRef.current =
      hosts?.find(host => host.id === selectedSpeaker.id) || hosts?.[0] || null;
  } else {
    // No selected speaker assigned, use the first host
    selectedSpeakerRef.current = hosts?.[0] || null;
  }
};

export const useHMSPeers = () => {
  const { peers, hosts, spectators } = useHMSPeersList();
  const dominantSpeaker = useHMSStore(selectDominantSpeaker);
  const selectedSpeakerRef = useRef(null);
  assignSelectedSpeaker(dominantSpeaker, hosts, selectedSpeakerRef);

  const speakersObject = useHMSStore(selectSpeakers);
  const isSpeaking = useCallback(
    peerId => {
      if (!speakersObject) {
        return false;
      }
      return Object.values(speakersObject).some(
        speakerData => speakerData.peerID === peerId,
      );
    },
    [speakersObject],
  );

  return {
    peers,
    hosts,
    spectators,
    dominantSpeaker,
    selectedSpeaker: selectedSpeakerRef.current,
    isSpeaking,
  };
};
