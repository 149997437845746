import { t } from "i18n-js";
import { useMutation } from "react-query";
import { liveStreamApi } from "@circle-react/api/liveStreamApi";
import { roomTypeData } from "@circle-react/helpers/liveStreamRoomHelpers";
import { useToast } from "@circle-react-uikit/ToastV2";

export const useEndRoom = (room, refetch) => {
  const { error, success } = useToast();
  const endRoomMutation = useMutation(
    () => liveStreamApi.endStream({ id: room?.id }),
    {
      onSuccess: () => {
        success(t(`live_streams.room.${roomTypeData(room)}.end_success`), {
          duration: "short",
          shouldUseProgress: false,
        });
        refetch();
      },
      onError: () =>
        error(t(`live_streams.room.${roomTypeData(room)}.end_error`)),
    },
  );

  return {
    endRoom: endRoomMutation.mutate,
  };
};
