import { TableHeadingCell, TableHeadingRow } from "@circle-react-uikit/Table";
import { Typography } from "@circle-react-uikit/Typography";

interface MembersHeaderTableProps {
  columnsToRender: Record<string, Record<string, string>>;
  shouldShowHeader: boolean;
}

export const MembersHeaderTable = ({
  columnsToRender,
  shouldShowHeader = false,
}: MembersHeaderTableProps) => {
  if (!shouldShowHeader) {
    return null;
  }

  return (
    <TableHeadingRow>
      {Object.keys(columnsToRender).map(columnKey => {
        const column = columnsToRender[columnKey];

        return (
          column && (
            <TableHeadingCell
              key={column.key}
              className={`!px-0 ${column.className ?? ""}`}
            >
              <Typography.LabelXs
                leading="leading-tight"
                color="text-default"
                weight="semibold"
              >
                {column.title.toUpperCase()}
              </Typography.LabelXs>
            </TableHeadingCell>
          )
        );
      })}
    </TableHeadingRow>
  );
};
