import { find } from "lodash";

export const isCollapsed = ({ spaceGroupId, currentCommunityMember }) =>
  currentCommunityMember?.hidden_space_group_ids?.includes(spaceGroupId);

export const canManageSpaceGroups = ({ spaceGroup }) =>
  spaceGroup?.policies?.can_manage_space_group;

export const canCreateSpaces = ({ spaceGroup }) =>
  spaceGroup.policies?.can_create_space;

export const addSpacesToGroup = ({
  spaceGroup,
  spaces,
  spacesWithDetails = [],
}) => {
  spaces = spaces.filter(space => space.space_group_id == spaceGroup.id);
  spaces = spaces.map(space => ({
    ...space,
    ...(find(spacesWithDetails, { id: space.id }) || {}),
  }));
  return {
    ...spaceGroup,
    spaces,
  };
};

export const addSpacesToSpaceGroups = ({ spaces, spaceGroups }) =>
  spaceGroups.map(spaceGroup => addSpacesToGroup({ spaceGroup, spaces }));

export const isSpaceGroupMemberGrantedByPaywall = accessType =>
  accessType === "paywall";
