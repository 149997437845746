import { useEffect } from "react";
import { t } from "i18n-js";
import { useFormContext } from "react-hook-form";
import { FormTipTapEditor } from "@circle-react/components/shared/uikit/Form/FormTipTapEditor";
import { usePunditUserContext } from "@circle-react/contexts";
import { PATH_PATTERN } from "@circle-react/helpers/urlHelpers/constants";
import { Form } from "@circle-react-uikit/Form";
import { Typography } from "@circle-react-uikit/Typography";
import { useCurrentAction } from "../../../ActionRule";

export const FormSendPushNotification = () => {
  const { paramsPath, isViewOnlyMode } = useCurrentAction();
  const { currentCommunity } = usePunditUserContext();
  const { root_url: rootUrl } = currentCommunity;
  const { getValues, setValue } = useFormContext();
  const path = getValues(`${paramsPath}.path`);

  useEffect(() => {
    if (!path) {
      return;
    }

    const charAtBeginning = path.match(/^\//);
    if (charAtBeginning) {
      const cleanPath = path.replace(/^\//, "");
      setValue(`${paramsPath}.path`, cleanPath);

      return;
    }

    const urlParts = path.split("://");
    if (urlParts.length <= 1) {
      return;
    }

    const pathParts = urlParts[1].split("/");
    if (pathParts.length <= 1) {
      setValue(`${paramsPath}.path`, "");

      return;
    }

    const newPath = pathParts.slice(1).join("/");
    setValue(`${paramsPath}.path`, newPath);
  }, [path]);

  const prefixText = `${rootUrl}/`;

  return (
    <div className="react-form flex flex-col gap-9">
      <Form.Item
        name={`${paramsPath}.path`}
        hideBorder
        fullWidth
        hideDescription
        label={t("settings.workflows.form.path.name")}
        labelClassName="!mb-2 !text-sm"
        className="!py-0"
        placeholder={t("settings.workflows.form.path.placeholder")}
        rules={{
          pattern: {
            value: PATH_PATTERN,
            message: t("settings.workflows.form.path.error"),
          },
        }}
      >
        <Form.Input
          prefix={
            <div className="whitespace-nowrap pl-4 pr-2">
              <Typography.LabelMd>{prefixText}</Typography.LabelMd>
            </div>
          }
        />
      </Form.Item>

      <div className="flex flex-col gap-2">
        <Typography.LabelSm weight="semibold">
          {t("settings.workflows.form.message_body")}
        </Typography.LabelSm>
        <FormTipTapEditor
          placeholder={t("settings.workflows.form.message_body_placeholder")}
          name={`${paramsPath}.message`}
          showMenuBar={false}
          submitOnEnter={false}
          className="!border-primary focus-within:!border-light !mb-0 !px-4 !text-base"
          editorClassName="min-h-[5rem]"
          maxLength={150}
          disabled={isViewOnlyMode}
          type="workflow"
        />
      </div>
    </div>
  );
};
