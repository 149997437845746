export default (progress, filename) =>
  `
  <div class="progress-card">
    <div class="editor-overlay">
      <div class="overlay-editor-actions">
        <a href="#remove-attachment" data-action="remove-attachment" class="close-btn">
          <div class="close-btn__caret"></div>
          <svg class="icon icon-close icon--lg" viewBox="0 0 24 24">
            <use xlink:href="#icon-close" />
          </svg>
        </a>
      </div>
    </div>
    <span class="progress-card__filename">${filename}</span>
    <span class="progress-card__amount">${progress} %</span>
    <div class="attachment__upload progress-bar">
      <div class="progress-bar__line" style="width:${progress}%;"></div>
    </div>
  </div>
`;
