import { useSpaceResource } from "@circle-react/contexts";
import { useMembersAvatarRow } from "@circle-react/hooks/members/useMembersAvatarRow";
import type { Space } from "@circle-react/types";

export const useShouldHideMembersCount = (space: Space) => {
  const { canSeeMembersAvatarRow } = useMembersAvatarRow({
    spaces: space.slug,
  });

  const { data: spaceResource, isLoading: isLoadingSpace } = useSpaceResource({
    spaceId: space.id,
  });

  return (
    isLoadingSpace ||
    Boolean(spaceResource?.hide_members_count) ||
    !canSeeMembersAvatarRow
  );
};
