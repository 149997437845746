import { useProviderRaiseHand } from "./provider";

export const usePeerRaiseHand = peerId => {
  const { isHandRaised, raiseHand, lowerHand, toggleHand } =
    useProviderRaiseHand(peerId);

  return {
    isHandRaised,
    raiseHand,
    lowerHand,
    toggleHand,
  };
};
