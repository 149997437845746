import { useEffect, useState } from "react";
import { DataPacket_Kind, LocalParticipant, Track } from "livekit-client";
import { noop } from "lodash";
import { useLiveStreamContext } from "@circle-react/contexts/LiveStreams";
import { useLivekitContext } from "../../VideoProvider/LivekitProvider";
import { ACTION_TYPES } from "./helpers";

// eslint-disable-next-line sonarjs/cognitive-complexity -- Already existed before maximum complexity value was reduced
export const useLivekitPeerMediaStatus = peer => {
  const { localParticipant } = useLivekitContext();
  const {
    data: { microphoneEnabled },
  } = useLiveStreamContext();
  const isLocal = peer instanceof LocalParticipant;
  const audioStatusMuted = isLocal ? !microphoneEnabled : false;
  const [isAudioMuted, setIsAudioMuted] = useState(
    peer?.getTrack(Track.Source.Microphone)?.isMuted || audioStatusMuted,
  );
  useEffect(() => {
    const onMuted = trackPublication => {
      if (trackPublication.kind === Track.Kind.Audio) {
        setIsAudioMuted(true);
      }
    };
    const onUnmuted = trackPublication => {
      if (trackPublication.kind === Track.Kind.Audio) {
        setIsAudioMuted(false);
      }
    };

    if (peer) {
      peer.on("trackMuted", onMuted);
      peer.on("trackUnmuted", onUnmuted);
    }

    return () => {
      if (peer) {
        peer.off("trackMuted", onMuted);
        peer.off("trackUnmuted", onUnmuted);
      }
    };
  }, [peer]);

  const muteMicrophone = () => {
    if (!localParticipant) {
      return Promise.reject("localParticipant is not present");
    }
    const encoder = new TextEncoder();
    const action = JSON.stringify({
      type: ACTION_TYPES.MUTE_MICROPHONE,
    });

    return localParticipant?.publishData(
      encoder.encode(action),
      DataPacket_Kind.LOSSY,
      [peer?.sid],
    );
  };

  return {
    isMicrophoneEnabled: !isAudioMuted,
    muteMicrophone,
    isCameraEnabled: true,
    disableCamera: noop,
  };
};
