import { t } from "i18n-js";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { ConfirmationModal } from "@circle-react-shared/ModalsV2/ConfirmationModal";
import { Typography } from "@circle-react-uikit/Typography";

const i18nRoot = "settings.paywalls.modal_remove_space_confirmation";

interface RemoveSpaceConfirmationModalProp {
  action: () => void;
  spaces: Array<any>;
}

const RemoveSpaceConfirmationModalComponent = ModalManager.create(
  ({ action, spaces }: RemoveSpaceConfirmationModalProp) => {
    const modal = useModal();
    const onClose = () => {
      void modal.hide();
      modal.remove();
    };

    const onConfirm = () => {
      action();
      onClose();
    };

    return (
      <ConfirmationModal
        isOpen={modal.visible}
        title={t([i18nRoot, "title"])}
        onClose={onClose}
        cancelText={t(["cancel"])}
        onConfirm={onConfirm}
        confirmVariant="primary"
        confirmText={t(["confirm"])}
      >
        <Typography.BodyMd>
          {t([i18nRoot, "description"], {
            count: spaces.length,
            space_groups: spaces.map(spaceGroup => spaceGroup.name).join(", "),
          })}
        </Typography.BodyMd>
      </ConfirmationModal>
    );
  },
);

export const useRemoveSpaceConfirmationModal = () =>
  useModal(RemoveSpaceConfirmationModalComponent);
