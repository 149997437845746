import { useFormContext } from "react-hook-form";

export const useEntitySelector = () => {
  const { watch, setValue } = useFormContext();
  const resourceType = watch("trigger.resource_type");

  const handleEntitySelectorChange = () => {
    setValue("trigger.resource_id", null);
  };

  return {
    resourceType,
    handleEntitySelectorChange,
  };
};
