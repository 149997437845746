import type { CommunityMember } from "@circle-react/types/CommunityMember";
import type { Reply } from "./Reply";

export const QUESTION_STATUSES = {
  APPROVED: "approved",
  ANSWERED: "answered",
  UNANSWERED: "unanswered",
  PENDING: "pending",
  DISMISSED: "dismissed",
} as const;

export type QuestionStatus =
  (typeof QUESTION_STATUSES)[keyof typeof QUESTION_STATUSES];

export interface Question {
  id: number;
  upvotes_count: number;
  upvoted_by_current_community_member: boolean;
  status: QuestionStatus;
  body: {
    body: object;
    attachments: any[];
  };
  created_at: string;
  approved_at?: string | null;
  community_member?: CommunityMember;
  replies_count: number;
  pinned_reply?: Reply | null;
  last_reply?: Reply | null;
  spotlighted: boolean;
}
