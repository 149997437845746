import { useEffect, useState } from "react";
import { t } from "i18n-js";
import { useMutation } from "react-query";
import { Link } from "react-router-dom";
import { supportWidgetApi } from "@circle-react/api/supportWidgetApi";
import { supportWidgetPaths } from "@circle-react/helpers/urlHelpers";
import { BodySm } from "@circle-react-shared/uikit/Typography/components/Body/BodySm";
import { LabelMd } from "@circle-react-shared/uikit/Typography/components/Label/LabelMd";
import { BooleanButton } from "../../BooleanButton";
import { Button } from "../../Button";

export const FeedbackSection = ({ post }) => {
  const [feedbackPositive, setFeedbackPositive] = useState(null);

  const { mutate: createPostFeedback } = useMutation(({ postId, value }) =>
    supportWidgetApi.createPostFeedback({
      postId: postId,
      helpful: value,
    }),
  );

  const handleFeedback = value => {
    setFeedbackPositive(value);
    createPostFeedback({ postId: post.id, value });
  };

  useEffect(() => {
    if (feedbackPositive !== null) {
      document.getElementById("feedback-response-container").scrollIntoView();
    }
  }, [feedbackPositive]);

  return (
    <div className="mx-auto w-full pt-4">
      <div className="mb-6">
        <LabelMd weight="semibold">
          {t("support_widget.was_this_article_helpful")}
        </LabelMd>
      </div>
      <div className="mb-6 flex justify-center gap-4">
        <BooleanButton
          value={feedbackPositive}
          handleFeedback={handleFeedback}
        />
      </div>
      <div id="feedback-response-container">
        {feedbackPositive && (
          <div className="mb-2">
            <BodySm>
              {t("support_widget.thank_you_for_article_feedback")}
            </BodySm>
          </div>
        )}
        {feedbackPositive === false && (
          <div className="mt-8">
            <div className="bg-primary">
              <div className="my-5">
                <Link to={supportWidgetPaths.askChatBot()}>
                  <Button
                    label={t("support_widget.still_need_help")}
                    className="dark:bg-gray-50 dark:text-gray-800"
                  />
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
