import { t } from "i18n-js";
import type { PaywallCheckoutPreview } from "@circle-react/types";
import { CheckoutFormSection } from "../CheckoutFormSection";
import { CheckoutPreviewEntry } from "../CheckoutPreviewEntry";

export interface TotalDueTodayPreviewProps {
  preview: PaywallCheckoutPreview;
}

export const TotalDueTodayPreview = ({
  preview,
}: TotalDueTodayPreviewProps) => (
  <CheckoutFormSection className="gap-y-3 border-t py-8">
    <CheckoutPreviewEntry
      label={t("paywall_checkout.amount_due")}
      labelSize="md"
      labelWeight="semibold"
      value={preview.formatted_amount_due_now}
      valueSize="lg"
      valueWeight="semibold"
      dataTestId="checkout-form-preview-total-due-today"
    />
  </CheckoutFormSection>
);
