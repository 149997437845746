import type { PropsWithChildren } from "react";

export const ItemTitle = ({ children }: PropsWithChildren<unknown>) => (
  <span
    className="flex items-center gap-2 text-left"
    data-testid="space-list-title"
  >
    {children}
  </span>
);
