import type { ComponentPropsWithoutRef } from "react";

export type SpaceListButtonProps = ComponentPropsWithoutRef<"button">;

export const SpaceListButton = ({
  children,
  ...rest
}: SpaceListButtonProps) => (
  <button
    type="button"
    className="text-c-sidebar hover:text-c-sidebar hover:bg-c-sidebar-hover focus:bg-c-sidebar-hover my-px flex cursor-pointer items-center justify-start rounded-md px-2 py-1.5 text-sm"
    {...rest}
  >
    {children}
  </button>
);
