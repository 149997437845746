import type { NoticeScenario } from "../NoticeScenario.types";
import { installments } from "./Installments";
import { onetime } from "./OneTime";
import { subscription } from "./Subscription";
import { trialThenInstallments } from "./TrialThenInstallments";
import { trialThenSubscription } from "./TrialThenSubscription";
import { trialThenUpfrontThenInstallments } from "./TrialThenUpfrontThenInstallments";
import { trialThenUpfrontThenSubscription } from "./TrialThenUpfrontThenSubscription";
import { upfrontThenInstallments } from "./UpfrontThenInstallments";
import { upfrontThenSubscription } from "./UpfrontThenSubscription";
import { upfrontThenTrialThenInstallments } from "./UpfrontThenTrialThenInstallments";
import { upfrontThenTrialThenSubscription } from "./UpfrontThenTrialThenSubscription";

// TODO: move notice bulding logic to the backend
export const noticeScenarios: NoticeScenario[] = [
  installments,
  onetime,
  subscription,
  trialThenInstallments,
  trialThenSubscription,
  trialThenUpfrontThenInstallments,
  trialThenUpfrontThenSubscription,
  upfrontThenInstallments,
  upfrontThenSubscription,
  upfrontThenTrialThenInstallments,
  upfrontThenTrialThenSubscription,
];
