import classNames from "classnames";
import { isNumber } from "lodash";
import { useLevelColors } from "@circle-react/components/Leaderboard/colorHelper";
import { t } from "@circle-react/custom_i18n";
import { toLocaleString } from "@circle-react/helpers/number";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-shared/uikit/Typography";

interface LevelItemProps {
  level: number;
  isLocked: boolean;
  levelName: string;
  membersPercentage?: number;
  pointsRequired?: number;
}

export const LevelItem = ({
  level,
  isLocked,
  membersPercentage,
  levelName,
  pointsRequired,
}: LevelItemProps) => {
  const levelColors = useLevelColors(level);
  return (
    <div
      className={classNames(
        "flex space-x-2",
        membersPercentage ? "items-start" : "items-center",
        isLocked ? "opacity-60" : "",
      )}
    >
      <div
        className={classNames(
          "flex h-8 w-8 items-center justify-center rounded-full border",
          isLocked
            ? "bg-primary text-dark border-primary"
            : ["border-transparent", levelColors.bg],
        )}
      >
        {isLocked ? (
          <Icon size={14} type="16-lock" />
        ) : (
          <Typography.LabelSm weight="semibold">
            <span
              className={classNames(isLocked ? "text-dark" : levelColors.text)}
            >
              {level}
            </span>
          </Typography.LabelSm>
        )}
      </div>
      <div>
        <Typography.LabelSm weight="semibold">
          <div className="text-dark">
            {levelName ||
              t("leaderboard.level_n", {
                level,
              })}
          </div>
        </Typography.LabelSm>
        {isNumber(pointsRequired) && (
          <Typography.LabelXs weight="regular">
            <div className="text-default">
              {t("leaderboard.n_points_required", {
                count: toLocaleString(pointsRequired),
              })}
            </div>
          </Typography.LabelXs>
        )}
      </div>
    </div>
  );
};
