import { PaywallsEmptyState } from "./PaywallsEmptyState";
import { WebPaywallPricesEmptyState } from "./WebPaywallPricesEmptyState";

interface EmptyStateProp {
  hasPaywalls: boolean;
  hasWebPaywallPrices: boolean;
}

export const EmptyState = ({
  hasPaywalls,
  hasWebPaywallPrices,
}: EmptyStateProp) => (
  <>
    {hasPaywalls && !hasWebPaywallPrices && <WebPaywallPricesEmptyState />}
    {!hasPaywalls && !hasWebPaywallPrices && <PaywallsEmptyState />}
  </>
);
