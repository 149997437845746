import { t } from "i18n-js";
import { mapCurrencyAmountStrToInt } from "@circle-react/components/Paywalls/Admin/utils";
import {
  calculateInstallmentAmount,
  resolveUpfrontPaymentDetails,
  upfrontPaymentVariants,
} from "@circle-react/helpers/paywallPriceHelpers";
import { formatPaywallCurrencyAmount } from "@circle-react/helpers/paywalls/currencies";
import { paywallPriceTypes } from "@circle-react/types";
import { usePricingTabContext } from "../../hooks/usePricingTabContext";
import type { PricingTabPriceListCellProps } from "../types";

export const PricingTabPriceListCellPricingInstallments = ({
  row: { original: price },
}: PricingTabPriceListCellProps) => {
  const {
    price_type,
    interval_count,
    interval,
    installments_count,
    amount,
    upfront_payment_amount,
    trial_requires_upfront_payment: isUpfrontPaymentRequiredForTrial,
  } = price;

  const { currency, priceBuildOptions, getHasTrialPeriod, getTrialPeriodDays } =
    usePricingTabContext();

  if (price_type !== paywallPriceTypes.installments) {
    return null;
  }

  const trialPeriodDays = getTrialPeriodDays();
  const hasTrialPeriod = getHasTrialPeriod();

  const buildOptions = priceBuildOptions.installments;

  const frequency = Object.values(buildOptions.frequencies).find(
    frequency =>
      frequency.interval === interval &&
      frequency.interval_count === interval_count,
  );

  const upfrontPaymentDetails = resolveUpfrontPaymentDetails(
    currency,
    upfront_payment_amount,
  );

  const amountInCents = mapCurrencyAmountStrToInt(amount);
  const amountFormatted = formatPaywallCurrencyAmount(
    currency,
    calculateInstallmentAmount(
      upfrontPaymentDetails,
      amountInCents,
      installments_count,
    ),
    {
      amountInCents: true,
      includeCurrencyCode: true,
    },
  );

  // TODO: move pricing description and notice building logic to the backend.
  const scenarioParts: string[] = [];
  if (hasTrialPeriod && !isUpfrontPaymentRequiredForTrial) {
    scenarioParts.push("trial");
  }
  if (upfrontPaymentDetails.variant === upfrontPaymentVariants.upfrontPayment) {
    scenarioParts.push("upfront");
  }
  if (hasTrialPeriod && isUpfrontPaymentRequiredForTrial) {
    scenarioParts.push("trial");
  }
  scenarioParts.push("value");
  const scenario = scenarioParts.join("_then_");

  return (
    <>
      {t(
        ["settings.paywall_prices", price_type, "short_description", scenario],
        {
          upfront: upfrontPaymentDetails.amountFormatted,
          count: installments_count,
          interval: frequency?.as_adverb?.toLowerCase(),
          amount: amountFormatted,
          trial: trialPeriodDays,
        },
      )}
    </>
  );
};
