import { Comment } from "@/react/components/search/SearchResultItem/Comment";
import { Event } from "@/react/components/search/SearchResultItem/Event";
import { Lesson } from "@/react/components/search/SearchResultItem/Lesson";
import { Member } from "@/react/components/search/SearchResultItem/Member";
import { Post } from "@/react/components/search/SearchResultItem/Post";

const SearchResultItem = Object.assign(
  {},
  {
    Comment,
    Event,
    Member,
    Post,
    Lesson,
  },
);

export { SearchResultItem };
