import { useQuery } from "react-query";
import { internalApi } from "@/react/helpers/urlHelpers";
import { isCourseSpace } from "@circle-react/helpers/spaceHelpers";

export const useCourseSettingsQuery = ({ space = {}, options = {} }) => {
  const courseSettingsUrl = internalApi.courses.settings({
    courseId: space.id,
  });

  return useQuery([courseSettingsUrl, space.id], {
    enabled: isCourseSpace(space) && !!space.id,
    ...options,
  });
};
