import { t } from "i18n-js";
import { Icon } from "@circle-react-shared/Icon";
import type { ActionOption } from "../types";
import { FormSelectSpaceGroup } from "./shared/FormSelectSpaceGroup";
import { HistoryDetailViewSpaceGroup } from "./shared/HistoryDetailViewSpaceGroup";
import type { HistoryDetailViewSpaceGroupProps } from "./shared/HistoryDetailViewSpaceGroup/HistoryDetailViewSpaceGroup";
import { ViewSpaceGroup } from "./shared/ViewSpaceGroup";

export const addMember: Readonly<
  ActionOption<HistoryDetailViewSpaceGroupProps>
> = {
  label: t(
    "settings.workflows.form.actions.add_community_member_to_space_group",
  ),
  value: "add_community_member_to_space_group",
  group: t("settings.workflows.form.action_groups.space_and_space_group"),
  leftAddon: <Icon type="16-folder" size={16} className="!h-4 !w-4" />,
  formComponent: FormSelectSpaceGroup,
  viewComponent: ViewSpaceGroup,
  historyDetailViewComponent: HistoryDetailViewSpaceGroup,
  icon: "user",
  isApplicableToMembersOnly: true,
} as const;
