import { formatPaywallCurrencyAmount } from "@/react/helpers/paywalls/currencies";
import { mapCurrencyAmountStrToInt } from "@circle-react/components/Paywalls/Admin/utils";
import { paywallPriceTypes } from "@circle-react/types";
import type {
  NoticeScenario,
  NoticeScenarioMatcher,
  NoticeScenarioMessagePropsResolver,
} from "../NoticeScenario.types";
import { recurringStateValidator } from "./StateValidators";

const matcher: NoticeScenarioMatcher = ({
  priceType,
  hasUpfrontPayment,
  hasTrialPeriod,
}) =>
  priceType === paywallPriceTypes.subscription &&
  hasUpfrontPayment &&
  !hasTrialPeriod;
const messagePropsResolver: NoticeScenarioMessagePropsResolver = ({
  buildOptions,
  currency,
  watch,
}) => [
  "upfront_then_subscription",
  {
    recurring_frequency:
      buildOptions.frequencies[
        watch("billing_frequency")
      ].as_phrase.toLowerCase(),
    recurring_amount: formatPaywallCurrencyAmount(
      currency,
      mapCurrencyAmountStrToInt(watch("amount")),
    ),
    upfront_payment_amount: formatPaywallCurrencyAmount(
      currency,
      mapCurrencyAmountStrToInt(watch("upfront_payment_amount")),
    ),
  },
];

export const upfrontThenSubscription: NoticeScenario = {
  matcher,
  stateValidator: recurringStateValidator,
  messagePropsResolver,
};
