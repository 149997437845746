import PropTypes from "prop-types";
import { t } from "i18n-js";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-uikit/Typography";

const Description = ({ title, children }) => (
  <Typography.BodySm color="text-default" textAlign="left">
    <span className="font-medium">{title}: </span>
    {children}
  </Typography.BodySm>
);

export const OptionDescription = ({
  accessDesc,
  visibilityDesc,
  isAccessOpen = false,
  isVisibilityOpen = true,
}) => (
  <div
    className="mt-3 grid gap-2 text-left text-sm"
    style={{
      gridTemplateColumns: "min-content auto",
    }}
  >
    <Icon
      type={isAccessOpen ? "16-lock-unlocked" : "16-lock"}
      size={16}
      className="text-default mt-px !self-start"
    />
    <Description title={t("customize_space.access")}>{accessDesc}</Description>
    <Icon
      type={isVisibilityOpen ? "16-eye-visible" : "16-eye-hidden"}
      size={16}
      className="text-default mt-px !self-start"
    />
    <Description title={t("customize_space.visibility")}>
      {visibilityDesc}
    </Description>
  </div>
);

Description.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
};

OptionDescription.propTypes = {
  accessDesc: PropTypes.string.isRequired,
  visibilityDesc: PropTypes.string.isRequired,
  /** This changes the icon for access */
  isAccessOpen: PropTypes.bool,
  /** This changes the icon for visibility */
  isVisibilityOpen: PropTypes.bool,
};
