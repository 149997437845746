import { useFormContext } from "react-hook-form";
import { useMutation } from "react-query";
import { workflowsApi } from "@/react/api";
import { minimumDelay } from "@circle-react/helpers/dateTimeHelpers";

export const useActivateWorkflow = (options = {}, params = {}) => {
  const { watch } = useFormContext();
  const id = watch("id");

  return useMutation(
    () => minimumDelay(workflowsApi.activate({ id, params }), 500),
    {
      onError: error => {
        console.error(error);
      },
      ...options,
    },
  );
};
