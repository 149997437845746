import { useBroadcastData } from "@circle-react-shared/Emails/BroadcastDataContext";
import { BroadcastTipTapReadonlyView } from "@circle-react-shared/Emails/EmailPreview/BroadcastTipTapReadonlyView";

export function SmallInlinePreview() {
  const { data, isLoading } = useBroadcastData();
  const content = data?.broadcast_content?.email_content;

  if (isLoading || !content) {
    return null;
  }

  return (
    <div className="border-primary pointer-events-none relative aspect-square h-14 w-14 cursor-default select-none overflow-hidden rounded-md border">
      <div className="absolute h-[560px] w-[560px] origin-top-left scale-[10%] px-8 py-4">
        <BroadcastTipTapReadonlyView content={content} />
      </div>
    </div>
  );
}
