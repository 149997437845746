import type { TwoUserStackProps } from "@circleco/ui-kit";
import { TwoUserStack } from "@circleco/ui-kit";
import classNames from "classnames";
import { useIsV3 } from "@circle-react/hooks/useIsV3";

type Users = TwoUserStackProps["users"];

/** @deprecated Please use TwoUserStack component from @circleco/ui-kit */
export const GroupUserImage = ({ user1, user2, className = "" }: any) => {
  const { isV3Enabled } = useIsV3();

  const users: Users = [
    { name: user1?.name || "", src: user1?.avatar_url || "" },
    { name: user2?.name || "", src: user2?.avatar_url || "" },
  ];

  return (
    <div
      className={classNames(
        "border-tertiary group-hover:border-hover mr-2.5",
        className,
      )}
    >
      <TwoUserStack users={users} isFullRounded={isV3Enabled} />
    </div>
  );
};
