import { usePunditUserContext } from "@circle-react/contexts";

export const useIsMessagingEnabled = () => {
  const { currentCommunityMember } = usePunditUserContext();
  return Boolean(currentCommunityMember?.messaging_enabled || false);
};

export const useShouldShowMessagingRoutes = () => {
  const { currentCommunityMember } = usePunditUserContext();
  const isMessagingEnabled = currentCommunityMember?.messaging_enabled || false;
  const enableDMs =
    !isMessagingEnabled && currentCommunityMember?.policies?.can_direct_message;
  return Boolean(
    enableDMs ||
      (currentCommunityMember?.messaging_enabled_by_admin &&
        isMessagingEnabled),
  );
};
