import { Suspense } from "react";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { PageLoader } from "@circle-react/pages/PageLoader";

const SingleSignOnComponent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "SingleSignOnPage" */ "@circle-react/components/SettingsApp/SingleSignOn"
    ),
);

export const SingleSignOnPage = () => (
  <Suspense fallback={<PageLoader />}>
    <SingleSignOnComponent />
  </Suspense>
);
