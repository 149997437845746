import { useRef, useState } from "react";
import { t } from "i18n-js";
import { DatePicker } from "@/react/components/QuickPost/ModalForm/SubmitButton/DatePicker";
import type { CoursesSection } from "@/react/types/CoursesSection";
import { formattedDateTime } from "@circle-react/helpers/dateTimeHelpers/formattedDateTime";
import { Icon } from "@circle-react-shared/Icon";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { Button } from "@circle-react-uikit/Button";
import type { FormValues } from "./DripScheduleForm";

interface DrippedAtDateTimePickerProps {
  onChange: (payload: FormValues) => void;
  disabled: boolean;
  section: CoursesSection;
}
const formatDrippedAt = (drippedAtValue: any) =>
  drippedAtValue &&
  formattedDateTime({
    dateTime: new Date(drippedAtValue),
    format: "date_time_with_short_timezone",
  });

export const DrippedAtDateTimePicker = ({
  onChange,
  disabled,
  section,
}: DrippedAtDateTimePickerProps) => {
  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);
  const [formattedDrippedAt, setFormattedDrippedAt] = useState(
    formatDrippedAt(section.dripped_at),
  );
  const buttonRef = useRef<HTMLButtonElement>(null);

  let tippyContent;

  if (section.is_dripped) {
    tippyContent = t(
      "courses.edit.sections.drip_schedule.form.section_already_available",
    );
  } else if (!section.dripped_at) {
    tippyContent = t(
      "courses.edit.sections.drip_schedule.form.section_immediately_available",
    );
  }

  return (
    <div className="!py-0">
      <TippyV2 disabled={!tippyContent} content={tippyContent}>
        <Button
          forwardedRef={buttonRef}
          disabled={disabled || !!section.drip_notifications_sent_at}
          variant="secondary"
          className="flex w-full items-center justify-between !pr-2"
          onClick={() => {
            setIsDatePickerVisible(!isDatePickerVisible);
          }}
        >
          <span>
            {formattedDrippedAt ||
              t("courses.edit.sections.drip_schedule.form.select_drip_date")}
          </span>
          <Icon className="text-dark" type="20-chevron-down-sm" size={20} />
        </Button>
      </TippyV2>
      <DatePicker
        datePickerVisible={isDatePickerVisible}
        onClickOutside={(_instance, event) => {
          // We ignore the click outside event if the click is on the button or any of its children
          // because it will be handled by the button's onClick listener which also toggles the visibility.
          if (
            buttonRef.current instanceof HTMLButtonElement &&
            event.target instanceof HTMLElement &&
            (event.target === buttonRef.current ||
              buttonRef.current.contains(event.target))
          ) {
            return;
          }
          setIsDatePickerVisible(false);
        }}
        onCancel={() => {
          setFormattedDrippedAt(formatDrippedAt(null));
          onChange({ dripped_at: null });
          setIsDatePickerVisible(false);
        }}
        cancelButtonText={t(
          "courses.edit.sections.drip_schedule.form.clear_date",
        )}
        value={section.dripped_at || ""}
        onSave={date => {
          setFormattedDrippedAt(formatDrippedAt(date));
          onChange({ dripped_at: date });
          setIsDatePickerVisible(false);
        }}
        saveButtonVariant="circle"
      />
    </div>
  );
};
