import { isLivekitProvider } from "@circle-react/helpers/liveStreamRoomHelpers";
import { useRoom } from "../useRoom";

export const useProviderHook = ({ hms, livekit } = {}) => {
  const room = useRoom();
  if (isLivekitProvider(room)) {
    return livekit;
  }
  // Return HMS by default
  return hms;
};
