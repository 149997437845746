import { useMutation, useQuery } from "react-query";
import { reactQueryPut } from "@/react/helpers/backendRequestHelpers";
import { internalApi } from "@/react/helpers/urlHelpers";

export const useSpacePaywalls = ({ space }) => {
  const {
    data: paywalls = [],
    isLoading: isLoadingPaywalls,
    refetch: refetchPaywalls,
  } = useQuery(internalApi.paywalls.index(), {
    enabled: !!space?.id,
    refetchOnWindowFocus: false,
  });

  const currentPaywalls = paywalls.filter(
    paywall =>
      paywall.space_ids.includes(space.id) ||
      paywall.space_group_ids.includes(space.space_group_id),
  );

  const availablePaywalls = paywalls.filter(
    paywall => !paywall.space_ids.includes(space.id),
  );

  const baseParams = paywall => ({
    space_group_ids: paywall.space_group_ids,
    all_spaces: paywall.all_spaces,
    trial_days: paywall.trial_days,
  });

  const { mutate: removePaywall, isLoading: isRemovingPaywall } = useMutation(
    paywall =>
      reactQueryPut(internalApi.paywalls.update({ paywallId: paywall.id }), {
        paywall: {
          space_ids: paywall.space_ids.filter(id => id !== space.id),
          ...baseParams(paywall),
        },
      }),
    {
      onSuccess: refetchPaywalls,
    },
  );

  const { mutate: addPaywall, isLoading: isAddingPaywall } = useMutation(
    paywall =>
      reactQueryPut(internalApi.paywalls.update({ paywallId: paywall.id }), {
        paywall: {
          space_ids: [...paywall.space_ids, space.id],
          ...baseParams(paywall),
        },
      }),
    {
      onSuccess: refetchPaywalls,
    },
  );

  const isLoading = isLoadingPaywalls || isRemovingPaywall || isAddingPaywall;

  return {
    paywalls,
    isLoading,
    removePaywall,
    addPaywall,
    refetchPaywalls,
    currentPaywalls,
    availablePaywalls,
  };
};
