import PropTypes from "prop-types";
import classnames from "classnames";
import { Icon } from "@circle-react-shared/Icon";

export const DownloadCSV = ({ onClickDownload }) => (
  <div className="grid grid-cols-2 pr-2 font-medium">
    <p className="pr-1">{I18n.t("live_streams.dashboard.participants.csv")}</p>
    <Icon
      type="download"
      className={classnames("cursor-pointer")}
      onClick={onClickDownload}
    />
  </div>
);

DownloadCSV.propTypes = {
  onClickDownload: PropTypes.func,
};
