import PropTypes from "prop-types";
import { t } from "i18n-js";
import { Button } from "@circle-react-uikit/Button";

export const LiveSettingsPagination = ({
  startRecordNumber,
  endRecordNumber,
  totalRecordCount,
  page,
  fetchPrevPage,
  hasNextPage,
  fetchNextPage,
}) => (
  <div className="flex flex-row-reverse items-center justify-end space-x-4 space-x-reverse">
    <div className="text-dark text-sm font-medium">
      {startRecordNumber}-{endRecordNumber}{" "}
      {t("live_streams.dashboard.pagination.of")} {totalRecordCount}
    </div>
    <div className="ml-2 space-x-2">
      <Button
        variant="secondary"
        disabled={page === 1}
        onClick={fetchPrevPage}
        type="button"
      >
        {t("live_streams.dashboard.pagination.prev")}
      </Button>
      <Button
        variant="secondary"
        disabled={!hasNextPage}
        onClick={fetchNextPage}
        type="button"
      >
        {t("live_streams.dashboard.pagination.next")}
      </Button>
    </div>
  </div>
);

LiveSettingsPagination.propTypes = {
  startRecordNumber: PropTypes.number,
  endRecordNumber: PropTypes.number,
  totalRecordCount: PropTypes.number,
  page: PropTypes.number,
  fetchPrevPage: PropTypes.func,
  hasNextPage: PropTypes.bool,
  fetchNextPage: PropTypes.func,
};
