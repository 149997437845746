import { useRef } from "react";
import { defer } from "lodash";
import Flatpickr from "react-flatpickr";
import type DatePicker from "react-flatpickr";
import type { DateTimePickerProps } from "react-flatpickr";
import { useFormContext } from "react-hook-form";
import invariant from "tiny-invariant";
import { CUSTOM_RANGE } from "../constants";
import { DatePickerInput } from "./DatePickerInput";
import { useDateRangePickerContext } from "./DateRangePickerContextProvider";

export const DateRangePicker = () => {
  const { queryParamsKey } = useDateRangePickerContext();
  const { watch, setValue } = useFormContext();

  const optionField = watch(queryParamsKey.default);
  const flatPickrInstanceRef = useRef<DatePicker | null>(null);

  const handleReady = () => {
    defer(() => {
      const flatPickrInstance = flatPickrInstanceRef.current?.flatpickr;
      invariant(flatPickrInstance, "flatPickrInstance is null");
      /* Add a class on calendar container so that styles are customised accordingly. */
      flatPickrInstance.calendarContainer.classList.add("settings-app");
    });
  };

  const handleRangePickerChange: DateTimePickerProps["onChange"] = ([
    startDate,
    endDate,
  ]) => {
    if (!startDate || !endDate) return;

    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);

    setValue(queryParamsKey.gte, startDate.getTime());
    setValue(queryParamsKey.lte, endDate.getTime());
  };

  if (optionField !== CUSTOM_RANGE) {
    return null;
  }

  const today = new Date();
  const yesterday = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - 1,
  );

  return (
    <Flatpickr
      ref={flatPickrInstanceRef}
      options={{
        mode: "range",
        dateFormat: "M j, Y",
        defaultDate: [yesterday, today],
        maxDate: today,
        onReady: handleReady,
        static: true,
      }}
      className="w-full"
      render={(props, ref) => (
        <button
          type="button"
          className="border-primary focus-within:bg-tertiary text-selector-active focus-visible:!outline-secondary flex flex-col items-center justify-center gap-x-2 rounded-md border px-3 py-2 focus-visible:!outline focus-visible:!outline-2 focus-visible:!outline-offset-2"
          onClick={() => {
            flatPickrInstanceRef.current?.flatpickr?.open?.();
          }}
        >
          <DatePickerInput ref={ref} {...props} />
        </button>
      )}
      onChange={handleRangePickerChange}
    />
  );
};
