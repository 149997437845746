import { useState } from "react";
import type { Cell } from "@tanstack/react-table";
import { t } from "i18n-js";
import { isEmpty } from "lodash";
import { FormWrapper } from "@/react/components/SettingsApp/Layout/FormWrapper";
import { DataTable } from "@circle-react-shared/uikit/DataTableV2";
import { Button } from "@circle-react-uikit/Button";
import { EmptyState } from "@circle-react-uikit/EmptyState";
import { Loader } from "@circle-react-uikit/Loader";
import { Typography } from "@circle-react-uikit/Typography";
import { useAddSnippetModal } from "./AddSnippetModal";
import { DropdownActions } from "./DropdownActions";
import { SnippetsList } from "./SnippetsList";
import { useIndexSnippets } from "./queries";

export interface Snippet {
  id: number;
  title: string;
}

type SnippetTableCell<TValue> = Cell<Snippet, TValue>;

const columns = [
  {
    header: t("settings.community_bot.knowledge.custom.columns.title"),
    accessorKey: "title",
    cell: ({ row }: any) => row.original?.title,
    canSort: false,
  },
  {
    header: t(
      "settings.community_bot.knowledge.custom.columns.used_in_replies",
    ),
    accessorKey: "used_in_replies",
    cell: () => 0,
    canSort: false,
  },
  {
    accessorKey: "actions",
    header: "",
    cell: ({ row }: SnippetTableCell<unknown>) => (
      <DropdownActions snippet={row.original} />
    ),
  },
];

export const Snippets = () => {
  const [currentPage, setPage] = useState(1);
  const snippetsQuery = useIndexSnippets({ currentPage });

  const addSnippetModal = useAddSnippetModal();

  const openAddSnippetModal = () => {
    void addSnippetModal.show();
  };

  const onChangePage = (page: number) => {
    // Forcing useQuery to only cache one page
    setPage(page);
    snippetsQuery.remove();
  };

  const snippetsCount = snippetsQuery?.data?.count || 0;
  const hasNoResults = isEmpty(snippetsQuery?.data?.records || []);

  if (snippetsQuery?.isLoading) {
    return <Loader />;
  }

  if (hasNoResults) {
    return (
      <div className="bg-primary flex h-full flex-col">
        <EmptyState
          header={t(
            "settings.community_bot.knowledge.custom.empty_state.header",
          )}
          description={t(
            "settings.community_bot.knowledge.custom.empty_state.description",
          )}
          primaryLabel={t(
            "settings.community_bot.knowledge.custom.empty_state.primary_label",
          )}
          onPrimaryClick={openAddSnippetModal}
        />
      </div>
    );
  }

  return (
    <>
      <div className="px-8 pt-6">
        <div className="rounded-lg border border-[#E4E4E7] bg-[#FAFAFA] p-4 pr-6">
          <Typography.BodySm>
            {t("settings.community_bot.knowledge.custom.banner")}
          </Typography.BodySm>
        </div>
      </div>
      {snippetsCount > 0 && (
        <div className="px-6 pb-1 pt-5 lg:px-9">
          <div className="flex min-h-[1.75rem] items-center justify-between gap-4">
            <Typography.LabelMd weight="semibold">
              {t("settings.community_bot.knowledge.custom.count", {
                count: snippetsCount,
              })}
            </Typography.LabelMd>
            <Button
              type="button"
              onClick={openAddSnippetModal}
              variant="circle"
            >
              {t("settings.community_bot.knowledge.custom.add_snippet")}
            </Button>
          </div>
        </div>
      )}
      <DataTable.TableWrapper>
        <FormWrapper>
          <SnippetsList
            columns={columns}
            snippetsQuery={snippetsQuery}
            onChangePage={onChangePage}
          />
        </FormWrapper>
      </DataTable.TableWrapper>
    </>
  );
};
