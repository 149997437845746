import { t } from "i18n-js";
import { Typography } from "@circle-react-uikit/Typography";

export const Price = () => {
  const dollarCurrency = "USD";

  return (
    <div className="flex flex-col">
      <div className="flex flex-row items-center gap-x-1">
        <Typography.TitleXl>
          {t("paywalls.lock_screen.preview.empty_block.price")}
        </Typography.TitleXl>
        <Typography.LabelSm color="text-default">
          {dollarCurrency}
        </Typography.LabelSm>
      </div>
    </div>
  );
};

const PriceEmpty = Object.assign(Price, {
  validateToRender: (price: any) => !price?.id,
});

export { PriceEmpty };
