import PropTypes from "prop-types";
import { Typography } from "@circle-react-uikit/Typography";

export const ResultTypeBadge = ({ label }) => (
  <span className="border-primary bg-primary flex rounded border px-1.5 py-[1px]">
    <Typography.LabelXs color="text-default">{label}</Typography.LabelXs>
  </span>
);

ResultTypeBadge.propTypes = {
  label: PropTypes.string.isRequired,
};
