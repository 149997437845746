import PropTypes from "prop-types";
import { t } from "i18n-js";
import { CommunityListItem } from "@/react/components/MobileCommunitySwitcher/CommunityListItem";
import { useCommunitySwitcher } from "@circle-react/hooks/community_switcher/useCommunitySwitcherApi";
import { Typography } from "@circle-react-uikit/Typography";

export const MobileCommunitySwitcher = ({ onClickDone }) => {
  const { communities } = useCommunitySwitcher();
  return (
    <div className="relative flex h-full w-full flex-col gap-1 py-1.5">
      <div className="mb-3.5 flex w-full cursor-pointer items-center justify-end px-3">
        <div className="w-full flex-1">
          <Typography.LabelLg weight="semibold">
            {t("mobile_community_switcher.my_communities")}
          </Typography.LabelLg>
        </div>
        <button type="submit" onClick={onClickDone}>
          <Typography.LabelSm weight="semibold">
            {t("mobile_community_switcher.done")}
          </Typography.LabelSm>
        </button>
      </div>
      {communities.map(community => (
        <CommunityListItem
          key={community.id}
          isActive={community.is_current_community}
          rootUrl={community.root_url}
          notificationCount={community.notificationCount}
          logoUrl={community.logo_attachment_url}
          iconUrl={community.icon_attachment_url}
          bgColor={community.brand_color}
          name={community.name}
        />
      ))}
    </div>
  );
};

MobileCommunitySwitcher.propTypes = {
  onClickDone: PropTypes.func,
};
