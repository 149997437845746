import PropTypes from "prop-types";
import { t } from "i18n-js";
import { ButtonWithDisabled } from "@circle-react-shared/uikit/ButtonWithDisabled/index";
import { Form } from "@circle-react-shared/uikit/Form/index";
import { Loader } from "@circle-react-uikit/Loader";
import { ServerErrors } from "../ServerErrors";
import { useAddOnsSettingsContext } from "../addOnsSettingsContext";
import { AddOnItem } from "./AddOnItem";

export const ManageAddOnsForm = ({ onAfterSubmit }) => {
  const {
    isLoading,
    handleAddonsMutation,
    addOnsData,
    defaultValues,
    onSubmit: onSubmitForm,
    total,
    serverErrors,
    getPlanInterval,
    getPlanCurrency,
    grandfatheredAddonNames,
  } = useAddOnsSettingsContext();

  const onSubmit = async (...values) => {
    onSubmitForm(...values);
    onAfterSubmit && onAfterSubmit();
  };

  return (
    <>
      {isLoading && (
        <div className="manage-add-ons__loader">
          <Loader />
        </div>
      )}

      {!isLoading && (
        <div>
          {grandfatheredAddonNames && (
            <div className="mt-6 rounded-md border border-l-8 border-amber-500 p-5">
              <p
                dangerouslySetInnerHTML={{
                  __html: t(
                    "settings.billing.add_ons.manage.grandfathered_addons",
                    {
                      addons: grandfatheredAddonNames,
                    },
                  ),
                }}
              />
            </div>
          )}

          <Form onSubmit={onSubmit} defaultValues={defaultValues}>
            <div className="manage-add-ons__fields-wrapper text-dark">
              {serverErrors && <ServerErrors serverErrors={serverErrors} />}

              {addOnsData.map(item => (
                <div className="mb-1" key={item.slug}>
                  <AddOnItem
                    name={item.slug}
                    step={item.step}
                    monthlyAmount={item.formatted_base_monthly_amount}
                    toggleable={item.toggleable}
                    interval={item.interval}
                    maxPurchasableQuantity={item.max_purchasable_quantity}
                    unitSize={item.unit_size}
                    priceCurrency={item.price_currency}
                  />
                </div>
              ))}

              <div className="manage-add-ons__total">
                <div className="manage-add-ons__total-label">
                  {t("settings.billing.add_ons.manage.total_purchase")}
                </div>
                <div className="manage-add-ons__total-value">
                  <b>{getPlanCurrency() + total}</b> {t("separator_slash")}{" "}
                  {getPlanInterval().charAt(0)}
                </div>
              </div>

              <div className="form-actions">
                <ButtonWithDisabled
                  type="submit"
                  variant="primary"
                  full
                  submitting={handleAddonsMutation.isLoading}
                >
                  {t("settings.billing.add_ons.manage.apply_changes")}
                </ButtonWithDisabled>
              </div>
            </div>
          </Form>
        </div>
      )}
    </>
  );
};

ManageAddOnsForm.propTypes = {
  onAfterSubmit: PropTypes.func,
};
