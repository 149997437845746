import { Form } from "@circle-react-uikit/Form";

export const FormItem = props => (
  <Form.Item
    hideDescription
    hideBorder
    hidePlaceholder
    fullWidth
    translationRoot="support_widget.send_message_form"
    {...props}
  />
);
