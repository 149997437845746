import PropTypes from "prop-types";
import classNames from "classnames";

export const ActionsSeparator = ({ className }) => (
  <div
    className={classNames("border-dark m-1 h-4 w-[1px] border-l", className)}
  />
);

ActionsSeparator.propTypes = {
  className: PropTypes.string,
};
