import { useQuery } from "react-query";
import { communityMemberApi } from "../api/communityMemberApi";

export const useCommunityMember = ({ memberId }) => {
  const communityMemberQuery = useQuery(
    ["community-member-show", memberId],
    communityMemberApi.show(memberId),
    {
      enabled: !!memberId,
    },
  );

  return {
    communityMemberQuery,
  };
};
