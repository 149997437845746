import { t } from "i18n-js";
import {
  isDraft,
  isStreamEnded,
} from "@/react/components/Events/EditForm/helpers";
import { useEventRsvp } from "@/react/components/Events/Rsvp/hooks/useEventRsvp";
import { useLiveStreamIssue } from "@/react/components/Events/Rsvp/hooks/useLiveStreamIssue";
import { useOnCreateLiveStream } from "@/react/components/Events/Rsvp/useOnCreateLiveStream";
import { isPublicStream } from "@circle-react/components/EventsV3/Modal/utils";
import { usePunditUserContext } from "@circle-react/contexts";
import { liveStreamPaths } from "@circle-react/helpers/urlHelpers";
import type { Event } from "@circle-react/types/Event";
import { Button } from "@circle-react-uikit/Button";
import { Typography } from "@circle-react-uikit/Typography";

interface JoinButtonProps {
  event: Event;
  onChange: (post: any) => void;
}

export const JoinButton = ({ event, onChange }: JoinButtonProps) => {
  const { currentUser } = usePunditUserContext();
  const { rsvpWhileJoiningEvent } = useEventRsvp(event, onChange);

  const isLoggedIn = !!currentUser;

  const { event_setting_attributes: eventData, rsvped_event: isRsvpedEvent } =
    event || {};

  const {
    location_type: locationType,
    live_stream_slug: liveStreamSlug,
    virtual_location_url: virtualLocationUrl,
  } = eventData || {};

  const { issueCreatingLive, canCreateLive } = useLiveStreamIssue(event) || {};
  const onCreateLiveStreamMutation = useOnCreateLiveStream(event);
  const shouldCreateLiveRoom =
    canCreateLive && (issueCreatingLive || isStreamEnded(event));

  const autoRsvp = () => {
    if (!isLoggedIn) return;
    if (!isRsvpedEvent) {
      void rsvpWhileJoiningEvent();
    }
  };

  const handleViewLiveStreamClick = () => {
    let url = liveStreamPaths.room({ roomSlug: liveStreamSlug });

    if (isPublicStream(event) && !isLoggedIn)
      url = liveStreamPaths.publicRoom({ roomSlug: liveStreamSlug });

    window.open(url, "_blank");
  };

  return (
    <Button
      variant="primary"
      full
      onClick={() => {
        autoRsvp();
        if (locationType === "virtual" && virtualLocationUrl) {
          window.open(virtualLocationUrl, "_blank");
          return;
        }
        if (shouldCreateLiveRoom) {
          onCreateLiveStreamMutation.mutate(null);
        } else {
          handleViewLiveStreamClick();
        }
      }}
      className="mt-6"
      disabled={isDraft(event) || onCreateLiveStreamMutation.isLoading}
    >
      <Typography.LabelSm weight="semibold" color="text-primary">
        {t(`events.locations.join_${locationType}`)}
      </Typography.LabelSm>
    </Button>
  );
};
