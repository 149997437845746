import { useState } from "react";
import PropTypes from "prop-types";
import { t } from "i18n-js";
import { usePunditUserContext } from "@circle-react/contexts";
import { ROOM_TYPES } from "@circle-react/helpers/liveStreamRoomHelpers";
import { CustomizeUI } from "@circle-react-shared/CustomizeUI";
import { FullScreenModal } from "@circle-react-shared/ModalsV2/FullScreenModal";
import { Form } from "@circle-react-uikit/Form";
import { useToast } from "@circle-react-uikit/ToastV2";
import { UpgradePlan } from "../../UpgradePlan";
import { useAddonsContext } from "./AddonsProvider";
import { Body, Footer, Header } from "./Common";
import {
  useCreateLiveMutation,
  useDefaultValues,
  useFormSections,
} from "./hooks";
import { useValidateFormSteps } from "./hooks/useValidateFormSteps";
import { SCROLLABLE_TARGET_ID } from "./utils";

export const LiveFormV3 = ({ isOpen, onClose }) => {
  const {
    currentCommunity: {
      supports_live_streams: communitySupportsLiveStreams,
    } = {},
  } = usePunditUserContext();
  const [roomType, setRoomType] = useState(ROOM_TYPES.STREAM);
  const { sections, sectionTitles } = useFormSections(roomType);
  const defaultValues = useDefaultValues();
  const { mutateAsync, isLoading: isSaving } = useCreateLiveMutation(
    roomType,
    onClose,
  );

  const { validateAndContinue, triggerValidationRef } = useValidateFormSteps();

  const { success } = useToast();

  const {
    shouldUpgradeAddons,
    purchaseRecordingsAddonWhenLimitReached,
    purchaseStreamingAddonWhenLimitReached,
  } = useAddonsContext();

  const handleSubmit = async (params, event) => {
    try {
      await purchaseRecordingsAddonWhenLimitReached(params);
      await purchaseStreamingAddonWhenLimitReached();
      if (shouldUpgradeAddons)
        success(t("settings.billing.add_ons.changes_succeeded"));
      await mutateAsync(params, event);
    } catch (err) {
      // Toaster error already being displayed from mutation
      console.error(err.message);
    }
  };

  return (
    <FullScreenModal
      isOpen={isOpen}
      onClose={onClose}
      shouldShowCloseButton
      contentPortalId="live"
    >
      <CustomizeUI
        formId="live-QWZ7d7ysBkm5cwhL235J9Q"
        sections={sectionTitles}
        initialSection={sections.roomType}
        title={t("live_streams.form.title_v3")}
        onExit={onClose}
        onSave={handleSubmit}
        isSaving={isSaving}
      >
        <FullScreenModal.Header>
          <Header />
        </FullScreenModal.Header>
        <FullScreenModal.Body
          id={SCROLLABLE_TARGET_ID}
          wrapperClassName="max-w-full"
        >
          {communitySupportsLiveStreams && (
            <Form
              id="live-QWZ7d7ysBkm5cwhL235J9Q"
              onSubmit={handleSubmit}
              onCancel={onClose}
              defaultValues={defaultValues}
            >
              <Body
                setRoomType={setRoomType}
                triggerValidationRef={triggerValidationRef}
              />
            </Form>
          )}
          {!communitySupportsLiveStreams && (
            <div className="mx-auto max-w-screen-md py-14">
              <UpgradePlan />
            </div>
          )}
        </FullScreenModal.Body>
        {communitySupportsLiveStreams && (
          <FullScreenModal.Footer>
            <Footer
              roomType={roomType}
              validateAndContinue={validateAndContinue}
            />
          </FullScreenModal.Footer>
        )}
      </CustomizeUI>
    </FullScreenModal>
  );
};

LiveFormV3.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};
