import { usePaginatedParticipants } from "@100mslive/react-sdk";
import { PARTICIPANT_ROLES } from "@circle-react/helpers/liveStreamRoomHelpers";

export const useHMSSpectators = () => {
  const { peers, loadPeers, loadMorePeers, hasNext, total } =
    usePaginatedParticipants({
      role: PARTICIPANT_ROLES.HLS_SPECTATOR,
      limit: 50,
    });

  return {
    peers,
    loadPeers,
    loadMorePeers,
    hasNext,
    total,
  };
};
