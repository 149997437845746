import { t } from "i18n-js";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { Button } from "@circle-react-uikit/Button";

interface AddToCalendarComponentProps {
  setShouldShowAddToCalendarModal: (value: boolean) => void;
}
export const AddToCalendarComponent = ({
  setShouldShowAddToCalendarModal,
}: AddToCalendarComponentProps) => {
  const handleOnClick = () => {
    setShouldShowAddToCalendarModal(true);
  };

  return (
    <Button
      variant="secondary-light"
      full
      onClick={handleOnClick}
      className="!border-primary flex justify-center gap-x-2 border"
      isFullRounded
    >
      <Icon
        type="16-calendar-add"
        size={16}
        className="h-4 !w-4"
        useWithFillCurrentColor
      />
      <Typography.LabelSm weight="semibold" color="text-primary">
        {t("add_to_calendar")}
      </Typography.LabelSm>
    </Button>
  );
};
