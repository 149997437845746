import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { Modal } from "@circle-react-uikit/ModalV2";
import { HistoryWorkflowDetail } from "./HistoryWorkflowDetail";

export const HistoryWorkflowDetailContainer = ({ taskId }: any) => {
  const modal = useModal();

  return (
    <Modal isOpen={modal.visible} onClose={modal.hide}>
      <Modal.Overlay />
      <Modal.Content size="lg">
        <HistoryWorkflowDetail taskId={taskId} onClose={modal.hide} />
      </Modal.Content>
    </Modal>
  );
};

const HistoryWorkflowModal = ModalManager.create(({ taskId }: any) => (
  <HistoryWorkflowDetailContainer taskId={taskId} />
));

export const useHistoryWorkflowModal = () => useModal(HistoryWorkflowModal);
