import PropTypes from "prop-types";
import { displayPaywallPrice } from "@circle-react/helpers/paywallPriceHelpers";
import { DataTable } from "@circle-react-uikit/DataTable";
import { PaywallContextualMenu } from "./PaywallContextualMenu";
import { PaywallStatusBadge } from "./PaywallStatusBadge";
import { COLUMN_NAMES } from "./constants";
import { gatedBy, isAssignedToSpace } from "./helpers";

export const PaywallList = ({ paywalls, space, onRemove, refetchPaywalls }) => {
  if (paywalls.length === 0) {
    return null;
  }

  const spaceGroupsPaywallsData = paywalls.map(paywall => ({
    key: paywall.id,
    rowClassName: "group",
    display_name: paywall.display_name,
    unlocked_by: gatedBy(paywall, space),
    pricing: displayPaywallPrice(paywall),
    status: <PaywallStatusBadge paywall={paywall} />,
    actions: (
      <PaywallContextualMenu
        paywall={paywall}
        showRemove={isAssignedToSpace(paywall, space)}
        refetchPaywalls={refetchPaywalls}
        onRemove={onRemove}
      />
    ),
  }));

  return (
    <DataTable
      variant="no-border"
      className="mt-2 lg:mt-2"
      columns={COLUMN_NAMES}
      dataSource={spaceGroupsPaywallsData}
    />
  );
};

PaywallList.propTypes = {
  space: PropTypes.object,
  paywalls: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool.isRequired,
  onRemove: PropTypes.func,
  refetchPaywalls: PropTypes.func,
};
