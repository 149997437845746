import { t } from "i18n-js";
import { noop } from "lodash";
import { usePunditUserContext } from "@circle-react/contexts";
import { isDraftSpace } from "@circle-react/helpers/spaceHelpers";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { useMobileWebFlags } from "@circle-react/hooks/useMobileWebFlags";
import type { Space } from "@circle-react/types";
import { Icon } from "@circle-react-shared/Icon";
import { Dropdown } from "@circle-react-uikit/Dropdown";
import { Typography } from "@circle-react-uikit/Typography";
import { useSpaceOptionsIcons } from "./useSpaceOptionsIcons";

export interface InviteMemberSpaceOptionsProps {
  space: Space;
  onInviteMember: () => void;
}

export const InviteMemberSpaceOptions = ({
  space,
  onInviteMember,
}: InviteMemberSpaceOptionsProps) => {
  const { isViewOnlyMasquerading } = usePunditUserContext();
  const { isMobileWebNewSpaceCustomizationUIEnabled } = useMobileWebFlags();
  const { iconSize } = useIsV3();
  const label = !isDraftSpace(space)
    ? t("space_header.invite_member")
    : t("customize_space.add_waitlist");

  const { inviteMemberIcon } = useSpaceOptionsIcons();

  if (isMobileWebNewSpaceCustomizationUIEnabled) {
    return (
      <button
        type="submit"
        className="flex w-full items-center justify-between px-5 py-2.5"
        onClick={onInviteMember}
      >
        <div className="flex items-center gap-4">
          <Icon
            type={inviteMemberIcon}
            size={iconSize}
            className="text-default"
          />
          <Typography.LabelSm color="text-light" weight="medium">
            {t("space_header.invite_member")}
          </Typography.LabelSm>
        </div>
        <Icon type="16-chevron-right" size={16} className="text-default" />
      </button>
    );
  }

  return (
    <Dropdown.IconWithBadgeList
      options={[
        {
          value: t("space_header.invite_member"),
          label,
          onClick: isViewOnlyMasquerading ? noop : onInviteMember,
          icon: inviteMemberIcon,
          iconSize,
        },
      ]}
    />
  );
};
