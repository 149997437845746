import { t } from "i18n-js";
import { EntityResource } from "../EntityResource";
import type { EventOption } from "../types";
import { AnyEventSpecificSelector } from "./shared/AnyEventSpecificSelector";

export const startsInAnHour: Readonly<EventOption> = {
  label: t("settings.workflows.form.events.event_starts_in_an_hour.title"),
  value: "event_starts_in_an_hour",
  group: t("settings.workflows.form.event_groups.events"),
  description: t(
    "settings.workflows.form.events.event_starts_in_an_hour.description",
  ),
  formComponent: AnyEventSpecificSelector,
  viewComponent: EntityResource,
  icon: "event",
};
