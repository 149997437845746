import { t } from "i18n-js";
import { useFormContext } from "react-hook-form";
import { PaywallCurrencyAmountFormInput } from "@circle-react/components/Paywalls";
import {
  buildTransformInputValueToCurrencyAmountStrFunc,
  mapCurrencyAmountStrToDecimal,
} from "@circle-react/components/Paywalls/Admin/utils";
import { SupportedCurrencyChooser } from "@circle-react/components/SupportedCurrencyChooser";
import { usePunditUserContext } from "@circle-react/contexts";
import { useGetPaywallsCurrencies } from "@circle-react/hooks/paywalls/useGetPaywallsCurrencies";
import { Form } from "@circle-react-uikit/Form";
import { Loader } from "@circle-react-uikit/Loader";

export const FixedAmountFields = () => {
  const {
    currentCommunitySettings: communitySettings,
    isLoading: isLoadingCommunitySettings,
  } = usePunditUserContext();
  const { data: paywallsCurrencies, isLoading: isLoadingPaywallsCurrencies } =
    useGetPaywallsCurrencies();

  const { watch } = useFormContext();

  if (isLoadingCommunitySettings || isLoadingPaywallsCurrencies) {
    return <Loader />;
  }

  const defaultCurrency = paywallsCurrencies.find(
    currency => currency.is_default,
  );

  const currencyId = watch("currency_id");
  const currency = paywallsCurrencies.find(
    currency => currency.id === parseInt(currencyId || defaultCurrency.id),
  );

  const inputTransformValueFunc =
    buildTransformInputValueToCurrencyAmountStrFunc(currency);

  const inputMinAmount = e => {
    const amount = mapCurrencyAmountStrToDecimal(e);
    if (amount <= 0) {
      return t("settings.paywall_coupons.alerts.error.amount_not_zero");
    }
    return true;
  };

  return (
    <>
      {communitySettings.currencies_enabled && (
        <SupportedCurrencyChooser
          formItemName="currency_id"
          formItemTranslationRoot="settings.paywall_coupons.form"
          formItemRules={{ required: true }}
        />
      )}
      <Form.Item
        fullWidth={false}
        hideBorder
        hideDescription
        hidePlaceholder
        name="amount"
        rules={{
          required: true,
          validate: { inputMinAmount },
        }}
        transformValue={inputTransformValueFunc}
        translationRoot="settings.paywall_coupons.form"
        variant="extra-bottom-margin"
      >
        <PaywallCurrencyAmountFormInput
          currency={currency}
          name="amount"
          data-testid="coupon_amount"
        />
      </Form.Item>
    </>
  );
};
