import { Form } from "@circle-react-shared/uikit/Form";

interface FormItemProps {
  name: string;
  label: string;
  tooltipText?: string;
  children?: React.ReactNode;
}

export const FormItem = ({
  children,
  name,
  label,
  tooltipText,
}: FormItemProps) => (
  <Form.Item
    className="grow basis-0 !pt-4"
    hideBorder
    hideDescription
    hidePlaceholder
    labelClassName="mb-2"
    errorClassName="mt-1 text-v2-danger text-sm"
    name={name}
    label={label}
    tooltipText={tooltipText}
  >
    {children ? children : <Form.Input />}
  </Form.Item>
);
