interface StatPercentageProps {
  value: number | null;
  total: number | null;
  precision?: number;
  fallbackValue?: string;
}

export const getStatPercentage = ({
  value,
  total,
  precision = 1,
  fallbackValue = "0.0%",
}: StatPercentageProps) => {
  if (!value || !total) {
    return fallbackValue;
  }

  if (total > 0 && value >= 0) {
    return `${((value / total) * 100).toFixed(precision)}%`;
  }

  return fallbackValue;
};
