import { t } from "i18n-js";
import { Header } from "@/react/components/Chat/MessagingV2/RailBar/Header";
import { RailBarPortal } from "@circle-react/components/Layout/StandardLayoutV2/RailBarPortal";
import { useElementHeight } from "@circle-react/hooks/utils/useElementHeight";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { Rail } from "@circle-react-uikit/Rail";

export const BotProfileRailbar = ({ botProfile = {} }: { botProfile: any }) => {
  const { ref: headerRef, height: headerHeight } = useElementHeight();

  const questions = [
    t("community_inbox.profile.default_suggested_question_1"),
    t("community_inbox.profile.default_suggested_question_2"),
    t("community_inbox.profile.default_suggested_question_3"),
  ];
  return (
    <RailBarPortal>
      <div ref={headerRef}>
        <Rail.Header className="!min-h-[4rem]">
          <Header title={t("profile")} isDirect />
        </Rail.Header>
      </div>
      <Rail.Content noPadding heightGap={headerHeight}>
        <div className="px-6 py-5">
          <div className="mb-4">
            <Icon
              type="120-ai-bot"
              size={120}
              className="mb-4 !h-[7.5rem] !w-[7.5rem]"
            />
            <Typography.TitleSm weight="semibold">
              {botProfile.name}
            </Typography.TitleSm>
          </div>
          <div className="mb-5 flex flex-col gap-y-2">
            <Typography.LabelSm weight="semibold">
              {t("community_inbox.profile.bio")}
            </Typography.LabelSm>

            <Typography.LabelSm>
              {t("community_inbox.profile.description")}
            </Typography.LabelSm>
          </div>
          <div className="flex flex-col gap-y-2">
            <div className="mb-2">
              <Typography.LabelSm weight="semibold">
                {t("community_inbox.profile.suggested_questions")}
              </Typography.LabelSm>
            </div>

            {questions.map((question, index) => (
              <div
                // eslint-disable-next-line react/no-array-index-key -- Not necessary in this case
                key={index}
                className="w-fit rounded-full border px-3.5 py-[5px]"
              >
                <Typography.LabelSm>{question}</Typography.LabelSm>
              </div>
            ))}
          </div>
        </div>
      </Rail.Content>
    </RailBarPortal>
  );
};
