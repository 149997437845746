import { PropTypes } from "prop-types";
import { Typography } from "@circle-react-uikit/Typography";

export const WorkflowName = ({ children }) => (
  <Typography.LabelSm weight="medium">
    <span className="break-normal">{children}</span>
  </Typography.LabelSm>
);

WorkflowName.propTypes = {
  children: PropTypes.node.isRequired,
};
