import { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { mapCurrencyAmountStrToInt } from "@circle-react/components/Paywalls/Admin/utils";
import type { PaywallPriceInterval } from "@circle-react/types";
import { usePricingTabContext } from "../../hooks/usePricingTabContext";

export const useAddPriceMobile = () => {
  const { priceBuildOptions, addPrice, hideAddPriceModal, currency } =
    usePricingTabContext();

  const { handleSubmit } = useFormContext();
  const onConfirm = useCallback(() => {
    const submit = handleSubmit(data => {
      const price_type = data.price_type;
      const amount: number = mapCurrencyAmountStrToInt(data.amount);

      let interval: PaywallPriceInterval | undefined;
      let interval_count: number | undefined;
      if (data.billing_frequency) {
        const frequency =
          priceBuildOptions[data.price_type].frequencies[
            data.billing_frequency
          ];
        if (frequency) {
          interval = frequency.interval;
          interval_count = frequency.interval_count;
        }
      }

      const isTrialEnabled = data?.enable_trial;
      const trialType = data?.trial_type;

      const google_processor_id =
        data?.in_app_google_processor_id ||
        data?.subscription_google_processor_id;
      const apple_processor_id =
        data?.in_app_apple_processor_id ||
        data?.subscription_apple_processor_id;

      const createPriceProperties = (platformType = "", processorId = "") => ({
        currency_id: currency.id,
        price_type,
        amount,
        interval,
        interval_count,
        installments_count: 0,
        upfront_payment_amount: 0,
        trial_requires_upfront_payment: false,
        platform_type: platformType,
        processor_id: processorId,
        ...(isTrialEnabled
          ? priceBuildOptions.subscription.trial_options[trialType]
          : {}),
      });

      addPrice({
        status: "pending",
        price_properties: createPriceProperties(),
        prices_attributes: [
          createPriceProperties("google", google_processor_id),
          createPriceProperties("apple", apple_processor_id),
        ],
      });
      hideAddPriceModal();
    });
    submit().catch(error => console.error("Error adding price", error));
  }, [priceBuildOptions, addPrice, hideAddPriceModal, handleSubmit]);

  return {
    onConfirm,
    hideAddPriceModal,
  };
};
