import { t } from "i18n-js";
import { Icon } from "@circle-react-shared/Icon";
import { SEND_TO_WEBHOOK_ELIGIBLE_PLAN_TIERS } from "../../../constants";
import type { ActionOption } from "../types";
import { FormSendToWebhook } from "./FormSendToWebhook";
import type { HistoryDetailViewSendToWebhookProps } from "./HistoryDetailViewSendToWebhook";
import { HistoryDetailViewSendToWebhook } from "./HistoryDetailViewSendToWebhook";
import { TitleSendToWebhook } from "./TitleSendToWebhook";
import { ViewSendToWebhook } from "./ViewSendToWebhook";

type ActionOptionWithStringLabel<T extends object> = Omit<
  ActionOption<T>,
  "label"
> & { label: string };

export const sendToWebhook: Readonly<
  ActionOption<HistoryDetailViewSendToWebhookProps>
> = {
  label: <TitleSendToWebhook />,
  textLabel: t("settings.workflows.form.action_groups.webhook.send_to_webhook"),
  value: "send_to_webhook",
  group: t("settings.workflows.form.action_groups.webhook"),
  leftAddon: <Icon type="16-webhook" size={16} className="!h-4 !w-4" />,
  formComponent: FormSendToWebhook,
  viewComponent: ViewSendToWebhook,
  historyDetailViewComponent: HistoryDetailViewSendToWebhook,
  availability: ["dynamic"] as const,
  eligiblePlanTiers: SEND_TO_WEBHOOK_ELIGIBLE_PLAN_TIERS, // Assumes that the eligible plans are sorted in ascending order of price
  isApplicableToMembersOnly: true,
};

export const sendToWebhookV2: Readonly<
  ActionOptionWithStringLabel<HistoryDetailViewSendToWebhookProps>
> = {
  label: t("settings.workflows.form.actions.send_to_webhook"),
  textLabel: t("settings.workflows.form.actions.send_to_webhook"),
  value: "send_to_webhook",
  group: t("settings.workflows.form.action_groups.webhook"),
  leftAddon: <Icon type="16-webhook" size={16} className="!h-4 !w-4" />,
  formComponent: FormSendToWebhook,
  viewComponent: ViewSendToWebhook,
  historyDetailViewComponent: HistoryDetailViewSendToWebhook,
  availability: ["dynamic"] as const,
  eligiblePlanTiers: SEND_TO_WEBHOOK_ELIGIBLE_PLAN_TIERS, // Assumes that the eligible plans are sorted in ascending order of price
  isApplicableToMembersOnly: true,
};
