import PropTypes from "prop-types";
import { t } from "i18n-js";
import { Typography } from "@circle-react/components/shared/uikit/Typography";
import { LimitPanel } from "../LimitPanel";
import { ProgressBar } from "../ProgressBar";
import { truncateNumber } from "../helpers";

export const StreamHours = ({ limit, usage, onPurchase, isLoading }) => {
  if (!limit) {
    return null;
  }

  const percentageUsed = (usage / limit) * 100;

  return (
    <LimitPanel
      isLoading={isLoading}
      canPurchaseMore
      onPurchase={onPurchase}
      title={t("live_streams.dashboard.limits.hours_this_month")}
    >
      <div className="mb-5">
        <Typography.TitleMd>{truncateNumber(usage)}</Typography.TitleMd>
      </div>
      <ProgressBar
        usage={usage}
        limit={limit}
        label={t("live_streams.dashboard.limits.monthly_limit")}
        color={percentageUsed > 75 ? "red" : "green"}
      />
    </LimitPanel>
  );
};

StreamHours.propTypes = {
  limit: PropTypes.number,
  usage: PropTypes.number,
  isLoading: PropTypes.bool,
  onPurchase: PropTypes.func,
};
