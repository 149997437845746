import PropTypes from "prop-types";
import { formattedMessageSentAt } from "@circle-react/helpers/dateTimeHelpers/formattedMessageSentAt";
import { Typography } from "@circle-react-uikit/Typography";

export const DateDivider = ({ current, previous }) => {
  const { day: currentDate } = formattedMessageSentAt(current);
  const { day: previousDate } = formattedMessageSentAt(previous);

  if (currentDate !== previousDate) {
    return (
      <div className="flex items-center">
        <div className="h-px grow bg-gray-200 dark:bg-gray-700"></div>
        <div className="mx-5 grow-0">
          <Typography.LabelXs color="text-light" weight="semibold">
            {currentDate.toUpperCase()}
          </Typography.LabelXs>
        </div>
        <div className="h-px grow bg-gray-200 dark:bg-gray-700"></div>
      </div>
    );
  }
  return null;
};

DateDivider.propTypes = {
  current: PropTypes.string,
  previous: PropTypes.string,
};
