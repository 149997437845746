import { useState } from "react";
import { t } from "i18n-js";
import { Switch } from "@circle-react-uikit/Switch";

const i18nRoot = "space_access_management";

export const AllAccessToggle = () => {
  const field = "all_access_enabled";
  const [isAllAccessEnabled, setIsAllAccessEnabled] = useState(false);

  const toggleAllAccess = () => {
    setIsAllAccessEnabled(prev => !prev);
  };

  return (
    <div>
      <input
        type="checkbox"
        name={field}
        id={`checkbox-${field}`}
        checked={isAllAccessEnabled}
        onChange={toggleAllAccess}
        className="hidden"
      />
      <label
        className="m-0 flex cursor-pointer select-none items-center gap-3"
        htmlFor={`checkbox-${field}`}
      >
        <span className="text-default text-sm tracking-tighter">
          {t([i18nRoot, "all_access"])}
        </span>
        <Switch
          as="span"
          checked={isAllAccessEnabled}
          onChange={toggleAllAccess}
        />
      </label>
    </div>
  );
};
