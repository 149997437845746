import PropTypes from "prop-types";
import { t } from "i18n-js";
import isEmpty from "lodash/isEmpty";
import { useGetPaywall } from "@circle-react/components/Modals/MemberProfileModal/Billing/Tabs/SubscriptionTab/ChangePlan/hooks/useGetPaywall";
import { Badge } from "@circle-react/components/SettingsApp/Workflows/components/Badge";
import { Typography } from "@circle-react-uikit/Typography";

export const PaywallName = ({ id }) => {
  const { data: paywall = {}, isLoading } = useGetPaywall(id);

  if (isLoading) {
    return <Badge isLoading />;
  }

  if (isEmpty(paywall)) {
    return (
      <Typography.LabelSm>
        {t("settings.workflows.view.deleted_paywall")}
      </Typography.LabelSm>
    );
  }

  return (
    <Typography.LabelSm>
      {paywall.name || paywall.display_name}
    </Typography.LabelSm>
  );
};

PaywallName.propTypes = {
  id: PropTypes.number.isRequired,
};
