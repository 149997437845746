export const scrollToElementIfNeeded = ({
  containerEl,
  itemSelector,
  scrollIntoViewOptions = {},
  intersectionObserverOptions = {},
}) => {
  const options = {
    root: containerEl,
    rootMargin: "0px",
    threshold: 1,
    ...intersectionObserverOptions,
  };
  const element = containerEl.querySelector(itemSelector);
  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        observer.unobserve(element);
        return;
      }
      element.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        ...scrollIntoViewOptions,
      });
      observer.unobserve(element);
    });
  }, options);
  if (element) {
    observer.observe(element);
  }
};
