import {
  selectLocalPeer,
  selectTracksMap,
  useHMSActions,
  useHMSStore,
} from "@100mslive/react-sdk";

export const useHMSMuteAllPeersAudio = () => {
  const hmsActions = useHMSActions();
  const localPeer = useHMSStore(selectLocalPeer);
  const tracksMap = useHMSStore(selectTracksMap);
  const allPeersAlreadyMuted = Object.values(tracksMap)
    .filter(track => track.peerId != localPeer?.id && track.type === "audio")
    .every(track => !track.enabled);

  const muteAllPeersMicrophone = () => {
    const options = {
      enabled: false,
      roles: ["host"],
      type: "audio",
    };

    hmsActions.setRemoteTracksEnabled(options);
  };

  return {
    muteAllPeersMicrophone,
    areAllPeersMuted: allPeersAlreadyMuted,
  };
};
