import { t } from "i18n-js";
import { useMutation } from "react-query";
import { communityPlanAddonApi } from "@/react/api/communityPlanAddonApi";
import { useToast } from "@circle-react-uikit/ToastV2";

export const useCommunityPlanAddonApi = ({
  handleOnSuccess = null,
  setServerErrors = null,
  onCloseModal = null,
  refetchCurrentPlan = null,
}) => {
  const toast = useToast();
  const handleAddonsMutation = useMutation(
    formData => communityPlanAddonApi.create(formData),
    {
      onSuccess: () => {
        if (handleOnSuccess) {
          handleOnSuccess();
        } else {
          refetchCurrentPlan && refetchCurrentPlan();
          toast.success(t("settings.billing.add_ons.changes_succeeded"));
          onCloseModal && onCloseModal();
        }
      },
      onError: responseError => {
        console.error(responseError);
        setServerErrors &&
          setServerErrors({
            title: responseError?.message,
            errors: responseError?.errorDetails,
          });
      },
    },
  );

  return {
    handleAddonsMutation,
  };
};
