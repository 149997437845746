import { t } from "i18n-js";
import { Collapsible } from "@circle-react/components/shared/uikit/Collapsible";
import { isEventsSpace } from "@circle-react/helpers/spaceHelpers";
import { Form } from "@circle-react-uikit/Form";

const Sort = ({ space }) => {
  const sortOrders = {
    LATEST: { label: t("latest"), value: "latest" },
    OLDEST: { label: t("oldest"), value: "oldest" },
    POPULAR: { label: t("popular"), value: "popular" },
    LIKES: { label: t("likes"), value: "likes" },
    NEW_ACTIVITY: { label: t("new_activity"), value: "new_activity" },
    ALPHABETICAL: { label: t("alphabetical"), value: "alphabetical" },
  };

  let fields = [
    {
      name: "default_comment_sort",
      options: [sortOrders.LATEST, sortOrders.OLDEST],
    },
  ];
  if (!isEventsSpace(space)) {
    fields = [
      ...fields,
      {
        name: "default_sort",
        options: Object.values(sortOrders),
      },
      {
        name: "default_member_sort",
        options: [sortOrders.LATEST, sortOrders.OLDEST],
      },
    ];
  }
  return (
    <Collapsible title={t("spaces.form.edit.options.sort.label")}>
      <div className="edit-space__container">
        <Form.Item
          inline
          hideBorder
          hideDescription
          label={t("spaces.form.edit.options.sort.show_sorting")}
          name="show_sorting"
        >
          <Form.ToggleSwitch variant="small" />
        </Form.Item>
        {fields.map(({ name, options }) => (
          <Form.Item
            key={name}
            hideBorder
            label={t(`spaces.form.edit.options.sort.${name}.label`)}
            description={t(`spaces.form.edit.options.sort.${name}.description`)}
            name={name}
            variant="extra-bottom-margin"
          >
            <Form.SelectNative options={options} />
          </Form.Item>
        ))}
      </div>
    </Collapsible>
  );
};

export default Sort;
