import { Suspense } from "react";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { PageLoader } from "@circle-react/pages/PageLoader";

const CustomDomainComponent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "CustomDomainPage" */ "@circle-react/components/SettingsApp/CustomDomain"
    ),
);

export const CustomDomainPage = () => (
  <Suspense fallback={<PageLoader />}>
    <CustomDomainComponent />
  </Suspense>
);
