import PropTypes from "prop-types";
import { LockedSpaceV2View } from "@circle-react/components/Spaces/Show/LockedSpaceV2";

export const LegacyLockScreenBlock = ({
  locked_page_heading = "",
  locked_page_description = {},
  locked_button_url = "",
  locked_button_label = "",
}) => (
  <div className="bg-secondary text-dark h-full">
    <LockedSpaceV2View
      locked_page_heading={locked_page_heading}
      locked_page_description={locked_page_description}
      locked_button_url={locked_button_url}
      locked_button_label={locked_button_label}
    />
  </div>
);

LegacyLockScreenBlock.propTypes = {
  locked_page_heading: PropTypes.string,
  locked_page_description: PropTypes.shape({
    plain_text: PropTypes.string,
    trix_html: PropTypes.string,
    rendered_html_with_layout: PropTypes.string,
  }),
  locked_button_url: PropTypes.string,
  locked_button_label: PropTypes.string,
};
