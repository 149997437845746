import { Suspense } from "react";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { PageLoader } from "@circle-react/pages/PageLoader";

const NotSupportedComponent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "LiveNotSupportedPage" */ "@circle-react/components/LiveStreams/NotSupported"
    ),
);

export const LiveNotSupportedPage = () => (
  <Suspense fallback={<PageLoader />}>
    <NotSupportedComponent />
  </Suspense>
);
