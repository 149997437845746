import { useMemo } from "react";
import {
  usePunditUserContext,
  useSpaceGroupsContext,
  useSpacesContext,
} from "@circle-react/contexts";

export const useIsLoadingAppData = () => {
  // We want synchronise loader, while some shared resources responsible for rendering core UI are loading
  // We want to show a loader/
  const spacesContext = useSpacesContext();
  const spaceGroupsContext = useSpaceGroupsContext();
  const punditUserContext = usePunditUserContext();

  const isSpaceLoading = Boolean(spacesContext.helpers.isDataLoading());
  const isSpaceGroupLoading = Boolean(spaceGroupsContext.isLoading);
  const isPunditUserLoading = Boolean(punditUserContext.isLoading);

  return useMemo(
    () => isSpaceLoading || isSpaceGroupLoading || isPunditUserLoading,
    [isSpaceLoading, isSpaceGroupLoading, isPunditUserLoading],
  );
};
