import type { ReactNode } from "react";
import classNames from "classnames";
import { useIsV3 } from "@circle-react/hooks/useIsV3";

export interface SidebarV2WrapperProps {
  children: ReactNode;
}

export const SidebarV2Wrapper = ({ children }: SidebarV2WrapperProps) => {
  const { isV3Enabled } = useIsV3();

  return (
    <div
      className={classNames(
        "bg-c-sidebar fixed flex h-full max-h-full flex-col",
        {
          "w-sidebar": !isV3Enabled,
          "w-sidebar-v3": isV3Enabled,
        },
      )}
    >
      {children}
    </div>
  );
};
