import { QueryClientProvider } from "react-query";
import { BrowserRouter as Router } from "react-router-dom";
import { LayoutPartialsContextProvider } from "@circle-react/contexts/layoutPartialsContext";
import { SpacesContextProvider } from "@circle-react/contexts/spacesContext";
import { ToastProvider } from "@circle-react-uikit/ToastV2";
import { AppBar } from "../components/Layout/AppBar";
import { queryClient } from "../providers/QueryClient/reactQueryConfig";

export const AppBarWrapper = () => (
  <QueryClientProvider client={queryClient}>
    <LayoutPartialsContextProvider>
      <SpacesContextProvider includeSidebar>
        <ToastProvider>
          <Router>
            <AppBar />
          </Router>
        </ToastProvider>
      </SpacesContextProvider>
    </LayoutPartialsContextProvider>
  </QueryClientProvider>
);
