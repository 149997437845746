import get from "lodash/get";
import { useFilterUrl } from "@circle-react-shared/Filter";
import { usePageQuery } from "./usePageQuery";
import {
  DEFAULT_PAGE_SIZE,
  useWorkflowHistoryInfiniteQuery,
} from "./useWorkflowHistoryInfiniteQuery";

export const useTable = ({ workflowType }) => {
  const { queryParams } = useFilterUrl();
  const { setPage, initialPage } = usePageQuery();
  const { member_id: memberId, ...filterParams } = queryParams;

  const params = {
    ...filterParams,
    perPage: DEFAULT_PAGE_SIZE,
    initialPage,
    workflowType,
    options: {
      keepPreviousData: false,
    },
  };

  if (memberId) {
    params.community_member_ids = memberId;
  }

  const infiniteQuery = useWorkflowHistoryInfiniteQuery(params);

  const totalWorkflows = get(infiniteQuery?.pages[0], "total_count", 0);
  const isLoading = infiniteQuery?.isLoading;

  return {
    infiniteQuery,
    totalWorkflows,
    isLoading,
    setPage,
    initialPage,
    defaultPageSize: DEFAULT_PAGE_SIZE,
  };
};
