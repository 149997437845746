import { Suspense } from "react";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { PageLoader } from "@circle-react/pages/PageLoader";

const PaywallSubscriptionsComponent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "PaywallSubscriptionsPage" */ "@circle-react/components/SettingsApp/PaywallSubscriptionsAdminApp"
    ),
);

export const PaywallSubscriptionsPage = () => (
  <Suspense fallback={<PageLoader />}>
    <PaywallSubscriptionsComponent />
  </Suspense>
);
