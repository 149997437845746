import { paywallPriceTypes } from "@circle-react/types";
import type {
  PricingTabPriceListCell,
  PricingTabPriceListCellProps,
} from "../../types";
import { PricingTabPriceListCellPricingOneTime } from "./OneTime";
import { PricingTabPriceListCellPricingSubscription } from "./Subscription";

const componentsByType = {
  [paywallPriceTypes.onetime]: PricingTabPriceListCellPricingOneTime,
  [paywallPriceTypes.subscription]: PricingTabPriceListCellPricingSubscription,
  [paywallPriceTypes.installments]: undefined,
} as const;

export const PricingColumn = (props: PricingTabPriceListCellProps) => {
  const {
    row: { original: price },
  } = props;

  const { price_type } = price.price_properties;
  const Component: PricingTabPriceListCell | undefined =
    componentsByType[price_type];
  if (!Component) {
    return null;
  }
  return <Component {...props} />;
};
