import { t } from "i18n-js";
import { IdFilter } from "./";

const localeRoot = "settings.paywalls_admin_dashboard.filters.subscription_id";

export const SubscriptionIdFilter = (props: any) => (
  <IdFilter
    {...props}
    localeRoot={localeRoot}
    queryParamsKey="subscription_processor_id"
  />
);

export const filterProperties = {
  name: "subscription_processor_id",
  filterKeys: ["subscription_processor_id"],
  component: SubscriptionIdFilter,
  label: t([localeRoot, "chip"]),
};
