export const ACTION_TYPES = Object.freeze({
  PUBLISH_METADATA: "PUBLISH_METADATA",
  UPDATE_ROOM: "UPDATE_ROOM",
  MUTE_MICROPHONE: "MUTE_MICROPHONE",
});

export const parseMetadata = strMetadata => {
  let metadata = {};
  if (strMetadata) {
    try {
      metadata = JSON.parse(strMetadata);
    } catch (e) {
      console.error("Could not parse metadata: ", e.message);
    }
  }
  return metadata;
};
