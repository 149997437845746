import { useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { t } from "i18n-js";
import { useFormContext } from "react-hook-form";
import { usePunditUserContext } from "@circle-react/contexts";
import {
  isBasicPostsSpace,
  isChatSpace,
} from "@circle-react/helpers/spaceHelpers";
import { Form } from "@circle-react-uikit/Form";
import { ImageInput } from "@circle-react-uikit/ImageInput";
import { RichTextInput } from "@circle-react-uikit/RichTextInput";
import { getLayoutOptions } from "./constants";

export const Customize = ({ space, isShownInRailBar = false }) => {
  const { currentCommunitySettings } = usePunditUserContext();
  const { space_directory_enabled: spaceDirectoryEnabled } =
    currentCommunitySettings || {};
  const { watch, setValue } = useFormContext();
  const isPrivateSpace = watch("visibility") !== "open";
  const visibleTabs = watch("visible_tabs");
  const defaultTab = watch("default_tab");
  const showTabBar = watch("show_tab_bar");
  const displayView = watch("display_view");
  const lockedDescription = watch("locked_page_description");
  const shouldShowHideMembersSwitch = isBasicPostsSpace(space);
  const shouldHideMembersSidebarBlock = watch("hide_member_sidebar");
  const isPostTabVisible = visibleTabs?.includes("posts");
  const isCardsView = displayView === "cards";

  if (!visibleTabs.includes(defaultTab)) {
    setValue("default_tab", visibleTabs[0]);
  }

  if (visibleTabs.length > 1 && !showTabBar) {
    setValue("show_tab_bar", true);
  }

  // small workaround to update nested locked_page_description
  if (
    isPrivateSpace &&
    space?.locked_page_description?.trix_html &&
    !lockedDescription
  ) {
    setValue(
      "locked_page_description",
      space?.locked_page_description?.trix_html,
    );
  }

  // set the default value for the switch based on the visible tabs (if members is visible or not)
  useEffect(() => {
    if (visibleTabs.includes("members")) {
      setValue("hide_member_sidebar", true);
    } else {
      setValue("hide_member_sidebar", false);
    }
  }, [space]);

  // when on v2 on a basic post space, the hide sidebar members toggle will basically
  // change the visible tabs array to only posts or posts and members (members being the condition to render the block)
  useEffect(() => {
    if (shouldShowHideMembersSwitch) {
      if (shouldHideMembersSidebarBlock) {
        setValue("visible_tabs", ["posts", "members"]);
      } else {
        setValue("visible_tabs", ["posts"]);
      }
    }
  }, [shouldHideMembersSidebarBlock, shouldShowHideMembersSwitch]);

  const showToggleSidebar = !isCardsView;
  const showThumbnailImage =
    !isChatSpace(space) || (isChatSpace(space) && spaceDirectoryEnabled);

  return (
    <div className={classNames({ "edit-space__container": !isShownInRailBar })}>
      {isBasicPostsSpace(space) && (
        <>
          {isPostTabVisible && (
            <Form.Item
              hideBorder
              hideDescription
              label={t("spaces.form.edit.customize.layout.label")}
              name="display_view"
              variant="extra-bottom-margin"
            >
              <Form.RadioGroup
                fullWidth={false}
                labelOutside
                options={getLayoutOptions({ postType: space.post_type })}
              />
            </Form.Item>
          )}
          {showToggleSidebar && (
            <Form.Item
              inline
              hideBorder
              hideDescription
              label={t("spaces.form.edit.customize.right_sidebar")}
              name="show_right_sidebar"
              shouldToggleValueOnLabelClick
            >
              <Form.ToggleSwitch variant="small" />
            </Form.Item>
          )}
        </>
      )}
      {!isChatSpace(space) && (
        <Form.Item
          inline
          hideBorder
          label={t("spaces.form.edit.customize.cover_image")}
          name="cover_image"
          description={t("spaces.form.edit.customize.cover_image_description")}
        >
          <ImageInput name="cover_image" aspectRatio={43 / 9} />
        </Form.Item>
      )}

      {isChatSpace(space) && (
        <div className="mb-4">
          <Form.Item
            name="chat_room_description"
            hideBorder
            hideDescription
            label={t("spaces.form.edit.customize.add_description")}
            placeholder={t(
              "spaces.form.edit.customize.description_placeholder",
            )}
            variant="extra-bottom-margin"
          >
            <Form.TextArea />
          </Form.Item>
        </div>
      )}

      {showThumbnailImage && (
        <Form.Item
          inline
          hideBorder
          label={t("spaces.form.edit.customize.thumbnail_image")}
          name="thumbnail_image"
          description={t(
            "spaces.form.edit.customize.thumbnail_image_description",
          )}
        >
          <ImageInput name="thumbnail_image" aspectRatio={16 / 9} />
        </Form.Item>
      )}

      {shouldShowHideMembersSwitch && (
        <Form.Item
          inline
          hideBorder
          hideDescription
          label={t("spaces.form.edit.customize.show_sidebar_members_block")}
          name="hide_member_sidebar"
          shouldToggleValueOnLabelClick
        >
          <Form.ToggleSwitch variant="small" />
        </Form.Item>
      )}

      {isPrivateSpace && (
        <>
          <Form.Item
            name="customize_lock_screen"
            inline
            hideBorder
            hideDescription
            tooltipText={t(
              "spaces.form.edit.customize.customize_lock_screen.description",
            )}
            label={t("spaces.form.edit.customize.customize_lock_screen.label")}
          >
            {/* <Form.ToggleSwitch variant="small" /> */}
          </Form.Item>

          {/* TODO: Add backend attribute customize_lock_screen and make this conditional */}
          <div className="customize-lock-screen">
            <Form.Input
              name="locked_page_heading"
              placeholder={t(
                "spaces.form.edit.customize.customize_lock_screen.placeholders.title",
              )}
            />
            <div className="trix-v2">
              <RichTextInput
                key={space?.locked_page_description?.trix_html}
                value={lockedDescription}
                className="locked-page-description"
                id="locked_page_description"
                name="locked_page_description"
                placeholder={t(
                  "spaces.form.edit.customize.customize_lock_screen.placeholders.description",
                )}
              />
            </div>
            <Form.Input
              name="locked_button_label"
              placeholder={t(
                "spaces.form.edit.customize.customize_lock_screen.placeholders.button_text",
              )}
            />
            <Form.Input
              name="locked_button_url"
              placeholder={t(
                "spaces.form.edit.customize.customize_lock_screen.placeholders.button_link",
              )}
            />
          </div>
        </>
      )}
    </div>
  );
};

Customize.propTypes = {
  space: PropTypes.object.isRequired,
  isShownInRailBar: PropTypes.bool,
};
