import { Icon } from "@circle-react-shared/Icon";
import { TableCell } from "@circle-react-uikit/Table";
import type { CellVariant } from "@circle-react-uikit/Table/TableCell";
import { Typography } from "@circle-react-uikit/Typography";
import type { Member } from "../types";

interface PaywallColumnProps {
  member: Member;
  cellVariant: CellVariant;
  hideColumn: boolean;
}

export const PaywallColumn = ({
  member,
  cellVariant,
  hideColumn,
}: PaywallColumnProps) => {
  const communityMemberPaywallContent =
    member?.currentSpaceMember?.community_member_paywall_content_granted;

  if (hideColumn) {
    return null;
  }

  const paywallName =
    communityMemberPaywallContent?.paywall?.name ||
    communityMemberPaywallContent?.paywall?.display_name;

  const shouldDisplayPaywall = Boolean(paywallName);
  const paywallComponent = (
    <div className="flex items-center gap-x-2">
      <Icon
        type="20-payments"
        className="text-selector-active !w-4"
        size={20}
      />
      <div className="w-36 truncate">
        <Typography.LabelSm color="text-selector-active">
          {paywallName}
        </Typography.LabelSm>
      </div>
    </div>
  );

  return (
    <TableCell variant={cellVariant} className="">
      {shouldDisplayPaywall ? paywallComponent : "-"}
    </TableCell>
  );
};
