import { useState } from "react";
import debounce from "lodash/debounce";
import { useFetchSection } from "./useFetchSection";
import { useFetchSections } from "./useFetchSections";

const sectionsOptionFormatter = section => ({
  label: section.name,
  value: section.id,
  description: section.course_name,
});

export const useSectionSelector = ({ selectedSectionId }) => {
  const [sectionName, setSectionName] = useState("");
  const { data, isLoading: isLoadingSections } = useFetchSections({
    name: sectionName,
  });
  const {
    data: section,
    isLoading: isLoadingSection,
    isSuccess: isSectionLoaded,
  } = useFetchSection({
    sectionId: selectedSectionId,
  });
  const sections = data?.records ?? [];

  const sectionOptions = sections.map(sectionsOptionFormatter);

  if (section && !isLoadingSection && isSectionLoaded) {
    sectionOptions.push(sectionsOptionFormatter(section));
  }

  const handleSectionTitleChange = debounce(
    title => setSectionName(title),
    500,
  );

  return {
    isLoading: isLoadingSections || isLoadingSection,
    sectionOptions,
    handleSectionTitleChange,
  };
};
