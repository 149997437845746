import { useMutation } from "react-query";
import { spaceGroupApi } from "@/react/api";

export const useAddMembersToSpaceGroup = (options = {}) => {
  const addMembers = ({
    spaceGroupId,
    communityMembersId,
    shouldAddAllMembers,
  }) =>
    spaceGroupApi.addMembers({
      spaceGroupId,
      body: {
        community_member_ids: communityMembersId,
        add_all_members: shouldAddAllMembers,
      },
    });

  return useMutation(addMembers, options);
};
