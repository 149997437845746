import classNames from "classnames";
import { SpaceAccessManagement } from "@circle-react/components/Spaces/SpaceAccessManagement/";
import { useSpaceAccessManagementContext } from "@circle-react/components/Spaces/SpaceAccessManagement/SpaceAccessManagementContext";
import type {
  SpaceGroupOption,
  SpaceOption,
} from "@circle-react/components/Spaces/SpaceAccessManagement/SpaceAccessManagementContext";
import { defaultAction } from "../../constants";
import { ChildAddableItem } from "../Item/ChildAddableItem";
import { ParentAddableItem } from "../Item/ParentAddableItem";

interface ListNoAccessProp {
  shouldShowAddParentButton?: boolean;
  shouldShowEmptySpaceGroups?: boolean;
  onAddParent?: (action: () => void, object: SpaceGroupOption) => void;
  onAddChild?: (action: () => void, object: SpaceOption) => void;
  className?: string;
}

export const ListNoAccess = ({
  shouldShowAddParentButton = true,
  shouldShowEmptySpaceGroups,
  onAddParent,
  onAddChild,
  className,
}: ListNoAccessProp) => {
  const { options, hasSpaceGroupUnselectedSpaces } =
    useSpaceAccessManagementContext();

  return (
    <SpaceAccessManagement.List
      className={classNames("hidden md:block", className)}
      dataTestId="list-space-access-denied"
    >
      {options.map(spaceGroup => {
        if (
          !hasSpaceGroupUnselectedSpaces(spaceGroup) &&
          !shouldShowEmptySpaceGroups
        ) {
          return null;
        }
        return (
          <>
            <ParentAddableItem
              key={`list-no-access-${spaceGroup.id}`}
              onAddParent={onAddParent ?? defaultAction}
              isButtonEnabled={shouldShowAddParentButton}
              {...spaceGroup}
            />
            {spaceGroup.spaces.map(space => (
              <ChildAddableItem
                key={`list-no-access-${spaceGroup.id}-${space.id}`}
                onAddChild={onAddChild ?? defaultAction}
                spaceGroupId={spaceGroup.id}
                topicsCountEnabled
                {...space}
              />
            ))}
          </>
        );
      })}
    </SpaceAccessManagement.List>
  );
};
