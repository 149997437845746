import { useEffect } from "react";
import { RoomEvent } from "livekit-client";
import { useLivekitContext } from "../../VideoProvider/LivekitProvider";

export const useLivekitRecorder = () => {
  const { room } = useLivekitContext();
  const egressToken = new URLSearchParams(window.location.search).get("token");

  useEffect(() => {
    if (room) {
      // eslint-disable-next-line no-console -- Needed for Egress
      console.log("START_RECORDING");
      room.on(
        RoomEvent.Disconnected,
        // eslint-disable-next-line no-console -- Needed for Egress
        () => console.log("END_RECORDING"),
      );
    }
  }, [room]);

  return {
    recorderToken: egressToken,
  };
};
