import { t } from "i18n-js";
import { isCourseSpace } from "@/react/helpers/spaceHelpers";
import { Form } from "@circle-react-uikit/Form";

export const CourseOptions = ({ space }: any) => {
  if (!isCourseSpace(space)) {
    return null;
  }

  return (
    <Form.Item
      hiddenField
      hideDescription
      label={t("spaces.form.edit.options.course_options.enforce_lessons_order")}
      tooltipText={t(
        "spaces.form.edit.options.course_options.enforce_lessons_order_tooltip",
      )}
      name="course_setting_attributes.enforce_lessons_order"
      className="my-5 flex flex-row-reverse items-center justify-end py-0"
      labelClassName="ml-4 mb-0"
      shouldToggleValueOnLabelClick
    >
      <Form.ToggleSwitch
        variant="small"
        name="course_setting_attributes.enforce_lessons_order"
      />
    </Form.Item>
  );
};
