import { isAfter, isBefore, parseISO, subDays } from "date-fns";
import { useMarketingHubAccessEndDate } from "../useMarketingHubAccessEndDate";

const ENDING_SOON_DAYS_THRESHOLD = 7;

export const useMarketingHubAccessForDate = () => {
  const { isLoading: isLoadingMarketingHubStatus, accessEndDate } =
    useMarketingHubAccessEndDate();

  const hasAccessToRunOnDate = (date: string): boolean => {
    if (!accessEndDate) return true;
    return new Date(accessEndDate) >= new Date(date);
  };

  const isMarketingHubAccessEndingSoon = () => {
    if (!accessEndDate) return false;

    const today = new Date();
    const parsedEndDate = parseISO(accessEndDate);
    const thresholdDate = subDays(parsedEndDate, ENDING_SOON_DAYS_THRESHOLD);

    return isAfter(today, thresholdDate) && isBefore(today, parsedEndDate);
  };

  return {
    isLoading: isLoadingMarketingHubStatus,
    hasAccessToRunOnDate,
    isMarketingHubAccessEndingSoon,
    accessEndDate,
  };
};
