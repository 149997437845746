import { DirectUpload } from "@rails/activestorage";
import Trix from "trix";
import {
  activeStorageBlobUrl,
  internalApi,
} from "../../react/helpers/urlHelpers";
import progressBarHtml from "./progressBarHtml";

// this is based on @rails/actiontext/app/javascript/actiontext/attachment_upload

// TODO :: Create a solution that does not need to
export class AttachmentUpload {
  constructor(file, editorElement, afterCompleteCallback) {
    this.file = file;
    this.editor = editorElement.editor;
    this.editorElement = editorElement;
    this.afterCompleteCallback = afterCompleteCallback;
    const attributes = Trix.Attachment.attributesForFile(file);
    this.attachment = new Trix.Attachment(attributes);
    this.tempAttachment = new Trix.Attachment({
      content: this.tempAttachmentContent(),
      sgid: `${new Date().getTime()}-${Math.random()}`,
    });
    this.directUpload = new DirectUpload(
      file,
      internalApi.actionTextDirectUpload.create(),
      this,
    );
  }

  start() {
    this.insertTempAttachment();
    this.directUpload.create(this.directUploadDidComplete.bind(this));
  }

  insertTempAttachment() {
    this.editor.insertAttachment(this.tempAttachment);
  }

  removeTempAttachment() {
    this.editor.composition.removeAttachment(this.tempAttachment);
  }

  insertAttachment(attributes) {
    this.attachment.setAttributes({
      sgid: attributes.attachable_sgid,
      url: this.createBlobUrl(attributes.signed_id, attributes.filename),
    });
    this.editor.insertAttachment(this.attachment);
  }

  directUploadWillStoreFileWithXHR(xhr) {
    xhr.upload.addEventListener("progress", event => {
      const progress = (event.loaded / event.total) * 100;
      this.tempAttachment.setAttributes({
        content: this.tempAttachmentContent(progress),
      });
    });
  }

  directUploadDidComplete(error, attributes) {
    if (error) {
      this.afterCompleteCallback();
      throw new Error(`Direct upload failed: ${error}`);
    }

    const attachmentPresent = this.editor.composition.attachments.includes(
      this.tempAttachment,
    );
    if (attachmentPresent) {
      this.removeTempAttachment();
      this.insertAttachment(attributes);
    }
    this.afterCompleteCallback();
  }

  createBlobUrl(signedId, filename) {
    return activeStorageBlobUrl({ signed_id: signedId, filename });
  }

  tempAttachmentContent(progress = 0.0) {
    return progressBarHtml(parseInt(progress), this.file.name);
  }

  get directUploadUrl() {
    return this.editorElement.dataset.directUploadUrl;
  }

  get blobUrlTemplate() {
    return this.editorElement.dataset.blobUrlTemplate;
  }
}
