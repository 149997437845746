import { Fragment } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Divider } from "@/react/components/search/Divider";
import { SearchResult } from "@/react/components/search/SearchResult";
import type { AdvancedSearchResult } from "@/react/components/search/types";
import { LoaderRow } from "@circle-react-shared/LoaderRow";

export interface SearchResultsListProps {
  searchResults: AdvancedSearchResult[];
  fetchNextPage: () => void;
  hasNextPage?: boolean;
}

export const SearchResultsList = ({
  searchResults,
  fetchNextPage,
  hasNextPage,
}: SearchResultsListProps) => (
  <InfiniteScroll
    className="flex flex-col gap-5 py-6"
    next={fetchNextPage}
    hasMore={!!hasNextPage}
    loader={<LoaderRow />}
    dataLength={searchResults.length}
    style={{ overflow: "initial" }}
    scrollableTarget="scrollable-container"
  >
    {searchResults.map((result: AdvancedSearchResult) => (
      <Fragment key={result.id}>
        <SearchResult item={result} />
        <Divider />
      </Fragment>
    ))}
  </InfiniteScroll>
);
