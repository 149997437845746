import { reactQueryGet } from "@/react/helpers/backendRequestHelpers";
import { fetchRequest } from "../helpers/backendRequestHelpers";
import { internalApi } from "../helpers/urlHelpers";

const updatePaywallCoupon = (paywallCouponId, params) => {
  const body = JSON.stringify({
    paywall_coupon: params,
    forReactQuery: true,
  });

  return fetchRequest(
    internalApi.paywallCoupons.update({
      paywallCouponId: paywallCouponId,
    }),
    {
      method: "PATCH",
      body,
      forReactQuery: true,
    },
  );
};

const createPaywallCoupon = params => {
  const body = JSON.stringify({
    paywall_coupon: params,
  });

  return fetchRequest(internalApi.paywallCoupons.create(), {
    method: "POST",
    body,
    forReactQuery: true,
  });
};

const destroyPaywallCoupon = paywallCouponId =>
  fetchRequest(
    internalApi.paywallCoupons.destroy({
      paywallCouponId: paywallCouponId,
    }),
    {
      method: "DELETE",
      forReactQuery: true,
    },
  );

const calculateDiscount = params =>
  fetchRequest(internalApi.paywallCoupons.calculateDiscount({ params }), {
    method: "GET",
    forReactQuery: true,
  });

const index =
  ({ paywallId, skipDateValidations, couponStatuses }) =>
  () =>
    reactQueryGet(
      internalApi.paywallCoupons.index({
        params: {
          paywall_id: paywallId,
          skip_date_validations: skipDateValidations,
          status: couponStatuses,
        },
      }),
    );

export const paywallCouponApi = {
  index,
  createPaywallCoupon,
  updatePaywallCoupon,
  calculateDiscount,
  destroyPaywallCoupon,
};
