import { useRef } from "react";
import { useLiveStreamContextActions } from "@circle-react/contexts/LiveStreams";
import { useProviderSyncRoom } from "./provider";

export const useGoLive = () => {
  const isLoadingRef = useRef(false);
  const { goLiveAsync } = useLiveStreamContextActions();
  const syncRoom = useProviderSyncRoom();

  return async () => {
    const isLoading = isLoadingRef.current;
    if (isLoading) return;
    try {
      isLoadingRef.current = true;
      await goLiveAsync();
      await syncRoom();
    } catch (error) {
      console.error("Error going live", error);
    } finally {
      isLoadingRef.current = false;
    }
  };
};
