import { QueryClient, QueryClientProvider } from "react-query";
import { CommunityOnboardingGettingStartedSection } from "@/react/components/Layout/CommunityOnboardingGettingStartedSection";
import { reactQueryConfig } from "@circle-react/providers/QueryClient/reactQueryConfig";
import { ToastProvider } from "@circle-react-uikit/ToastV2";

const queryClient = new QueryClient(reactQueryConfig);

export const CommunityOnboardingGettingStartedSectionWrapper = () => (
  <QueryClientProvider client={queryClient}>
    <ToastProvider>
      <CommunityOnboardingGettingStartedSection />
    </ToastProvider>
  </QueryClientProvider>
);
