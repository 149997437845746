import { useHistory, useLocation } from "react-router-dom";
import { GoLiveButtonV2 } from "@circle-react/components/LiveStreams/GoLiveButton/GoLiveButtonV2";
import { useLiveRoom } from "@circle-react/components/LiveStreams/hooks/useLiveRoom";
import { usePunditUserContext } from "@circle-react/contexts";
import {
  memberProfileModalPathsWithState,
  signOutPath,
} from "@circle-react/helpers/urlHelpers";
import { ProfileSectionView } from "./ProfileSectionView";

export const ProfileSection = () => {
  const history = useHistory();
  const location = useLocation();
  const { currentCommunityMember } = usePunditUserContext();
  const currentCommunityMemberPolicies = currentCommunityMember?.policies || {};
  const { room: ifLiveAlreadyExists } = useLiveRoom();

  if (!currentCommunityMember) {
    return null;
  }

  const handleProfileClick = () =>
    history.push(memberProfileModalPathsWithState.profile(location));

  const handleSignOutClick = () => {
    window.location.href = signOutPath();
  };

  return (
    <ProfileSectionView
      headline={currentCommunityMember.headline}
      imageUrl={currentCommunityMember.avatar_url}
      name={currentCommunityMember.name}
      onEditProfileClick={handleProfileClick}
      onSignOutClick={handleSignOutClick}
      publicUid={currentCommunityMember?.public_uid}
      showMemberBilling={currentCommunityMemberPolicies?.can_access_billing}
      canAccessAuthenticationPage={
        currentCommunityMemberPolicies?.can_access_authentication_page
      }
      canShowPaywallsAffiliate={
        currentCommunityMemberPolicies?.can_show_paywalls_affiliate
      }
    >
      {!ifLiveAlreadyExists && (
        <div className="ml-5">
          <GoLiveButtonV2 />
        </div>
      )}
    </ProfileSectionView>
  );
};
