import { useSpaceHeaderTitleDropdown } from "@/react/components/Spaces/Header/SpaceHeaderTitleDropdown/useSpaceHeaderTitleDropdown";
import {
  isChatSpace,
  isMembersSpace,
  isSpaceMember,
} from "@/react/helpers/spaceHelpers";
import { usePunditUserContext } from "@circle-react/contexts";

export const useNotificationBell = ({ space }) => {
  const { currentCommunitySettings, currentCommunity } = usePunditUserContext();
  const {
    chat_notification_preferences_enabled: chatNotificationPreferencesEnabled,
  } = currentCommunitySettings || {};
  const areMobileNotificationsEnabled =
    currentCommunity.ios_app_enabled || currentCommunity.has_branded_app;
  const shouldShowNotificationBell = isChatSpace(space)
    ? chatNotificationPreferencesEnabled && areMobileNotificationsEnabled
    : true;
  const {
    updateSpaceMemberNotificationPreferences,
    updateChatSpaceMemberNotificationPreferences,
    notificationState,
  } = useSpaceHeaderTitleDropdown({ space });
  const shouldShowNotificationsSection =
    !isMembersSpace(space) &&
    isSpaceMember(space) &&
    shouldShowNotificationBell;

  return {
    updateSpaceMemberNotificationPreferences,
    updateChatSpaceMemberNotificationPreferences,
    notificationState,
    shouldShowNotificationsSection,
  };
};
