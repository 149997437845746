import { useEffect, useState } from "react";
import classNames from "classnames";
import type { Location } from "history";
import { t } from "i18n-js";
import { useQueryClient } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import { posts } from "@/react/helpers/urlHelpers";
import { isEventPost } from "@circle-react/helpers/postHelpers";
import { usePostList } from "@circle-react/hooks/posts/usePostList";
import { useSmScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { BookmarkButton } from "@circle-react-shared/Bookmarks/BookmarkButton";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { IconButton } from "@circle-react-shared/uikit/HeaderV3/IconButton";
import { Modal, useModalData } from "@circle-react-shared/uikit/ModalV2";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { PostActionsDropdownMenu } from "../PostViewListItem/PostActionsDropdownMenu";
import { PostShareOptions } from "../PostViewListItem/PostShareOptions";
import { useCurrentPostHelpers } from "../useCurrentPostHelpers";
import { PostHeaderSkeleton } from "./SkeletonLoader/PostHeaderSkeleton";

interface LocationState {
  prevLocation?: Location;
  shouldFocusOnCommentsSection?: boolean;
}

export interface ModalHeaderProps {
  onChange: () => void;
  spaceId: any;
  isLoading?: boolean;
  invalidateQueries?: () => void;
  onPostDestroy: () => void;
}

export const ModalHeader = ({
  onChange,
  spaceId,
  isLoading = false,
  invalidateQueries,
  onPostDestroy,
}: ModalHeaderProps) => {
  const { post, refetchPostDetails } = useCurrentPostHelpers();
  const queryClient = useQueryClient();
  const { contentPortalElement } = useModalData();
  const history = useHistory();
  const location = useLocation<LocationState>();
  const prevLocation = location?.state?.prevLocation;
  const isSmScreen = useSmScreenMediaQuery();
  const { name, slug, space_slug: spaceSlug } = post;
  const { refetch: refetchPosts } = usePostList();

  const [isContentVisible, setIsContentVisible] = useState(!isLoading);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (!isLoading) {
      timer = setTimeout(() => setIsContentVisible(true), 50);
    } else {
      setIsContentVisible(false);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [isLoading]);

  const invalidateSpacePosts = () => {
    void queryClient.invalidateQueries(["posts", spaceId]);
  };

  if (isLoading) {
    return <PostHeaderSkeleton />;
  }

  return (
    <Modal.Header
      className={classNames(
        "flex w-full items-center gap-4",
        "transition-opacity duration-300 ease-in-out",
        {
          "opacity-0": !isContentVisible,
          "opacity-100": isContentVisible,
          "!justify-end": !name,
        },
      )}
    >
      {name && <Typography.TitleMd>{name}</Typography.TitleMd>}
      <Modal.HeaderActions className="mt-1">
        <div className="flex gap-2 md:gap-5">
          <div className="flex gap-2">
            <TippyV2
              interactive={false}
              content={t("post.post_modal.share_post_button_tooltip")}
            >
              <PostShareOptions
                onChange={() => {
                  onChange();
                  invalidateSpacePosts();
                }}
                appendTo={contentPortalElement ?? document.body}
                menuButtonEl="div"
                button={
                  <IconButton
                    name="20-share"
                    iconSize={20}
                    iconClassName="!text-default group-hover:!text-dark"
                    className="group"
                    buttonSize={24}
                    ariaLabel={t("post.post_modal.share_post_aria_label")}
                  />
                }
                shouldRenderCustomMenuButton
              />
            </TippyV2>
            <BookmarkButton
              bookmarkType={isEventPost(post) ? "event" : "post"}
              variant="minimal-post-modal"
              record={post}
              onSuccess={() => {
                refetchPostDetails?.();
                invalidateSpacePosts();
              }}
            />
            <PostActionsDropdownMenu
              onChange={onChange}
              onPostDestroy={onPostDestroy}
              refetchPosts={refetchPosts}
            />
          </div>
          <div className="bg-overlay-dark w-px" />
          <div className="flex gap-2">
            {!isSmScreen && (
              <TippyV2
                interactive={false}
                content={t(
                  "post.post_modal.open_post_in_full_page_button_tooltip",
                )}
              >
                <IconButton
                  name="20-expand"
                  iconSize={20}
                  iconClassName="!text-default group-hover:!text-dark"
                  className="group"
                  buttonSize={24}
                  onClick={() => {
                    history.push(
                      posts.show({
                        slug,
                        spaceSlug,
                      }),
                    );
                  }}
                  ariaLabel={t(
                    "post.post_modal.open_post_in_full_page_aria_label",
                  )}
                />
              </TippyV2>
            )}
            <TippyV2
              interactive={false}
              content={t("post.post_modal.close_post_modal_tooltip")}
            >
              <IconButton
                name="20-close"
                iconSize={20}
                iconClassName="!text-default group-hover:!text-dark"
                className="group"
                buttonSize={24}
                onClick={() => {
                  if (prevLocation) {
                    history.push(prevLocation);
                    invalidateQueries?.();
                  }
                }}
                ariaLabel={t("post.post_modal.close_button_aria_label")}
              />
            </TippyV2>
          </div>
        </div>
      </Modal.HeaderActions>
    </Modal.Header>
  );
};
