import { Suspense } from "react";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { PageLoader } from "@circle-react/pages/PageLoader";

const ActivityLogsComponent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "ActivityLogsSettingsPage" */ "@circle-react/components/SettingsApp/ActivityLogs"
    ),
);

export const ActivityLogsSettingsPage = () => (
  <Suspense fallback={<PageLoader />}>
    <ActivityLogsComponent />
  </Suspense>
);
