import type { ReactNode } from "react";
import classNames from "classnames";
import { StepIcon } from "./StepIcon";

export interface GettingStartedStepProps {
  complete: boolean;
  href: string;
  text: string;
  children?: ReactNode;
}

export const GettingStartedStep = ({
  complete,
  href,
  text,
  children,
}: GettingStartedStepProps) => (
  <li className={classNames({ "li--complete": complete })}>
    {children || (
      <a href={href} className={classNames({ "!text-dark": !complete })}>
        <StepIcon complete={complete} />
        {text}
      </a>
    )}
  </li>
);
