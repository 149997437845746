import { useEffect } from "react";
import { DisconnectReason, RoomEvent } from "livekit-client";
import { useLiveStreamContextActions } from "@circle-react/contexts/LiveStreams";
import { useLivekitContext } from "../../VideoProvider/LivekitProvider";
import { ACTION_TYPES } from "./helpers";

export const useLivekitNotificationsListener = ({
  onRoomUpdate,
  onEndStream,
}) => {
  const { room } = useLivekitContext();
  const { setMicrophoneEnabled } = useLiveStreamContextActions();

  useEffect(() => {
    const decoder = new TextDecoder();
    const onDataReceived = data => {
      try {
        const strData = decoder.decode(data);
        const action = JSON.parse(strData);
        const { type } = action || {};
        // Note: this should become a switch statement once
        // we deal with more messages.
        if (type === ACTION_TYPES.UPDATE_ROOM) {
          onRoomUpdate && onRoomUpdate();
        } else if (type === ACTION_TYPES.MUTE_MICROPHONE) {
          setMicrophoneEnabled(false);
        }
      } catch (err) {
        console.error("Could not decode received data - ", err.message);
      }
    };

    const onRoomDisconnected = reason => {
      if (
        [
          DisconnectReason.ROOM_DELETED,
          DisconnectReason.PARTICIPANT_REMOVED,
        ].includes(reason)
      ) {
        onEndStream && onEndStream();
      }
    };

    if (room) {
      room.on(RoomEvent.DataReceived, onDataReceived);
      room.on(RoomEvent.Disconnected, onRoomDisconnected);
    }

    return () => {
      if (room) {
        room.off(RoomEvent.DataReceived, onDataReceived);
        room.off(RoomEvent.Disconnected, onRoomDisconnected);
      }
    };
  }, [onEndStream, onRoomUpdate, room, setMicrophoneEnabled]);
};
