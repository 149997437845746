import { t } from "i18n-js";
import { useQuery } from "react-query";
import { workflows } from "@circle-react/helpers/urlHelpers/internalApi/workflows";
import { BaseResource } from "../../BaseResource";

export const PostsEventResource = ({ resourceId }: { resourceId: number }) => {
  const { data: event, isLoading } = useQuery<{ name: string } | undefined>(
    workflows.events.show({ id: String(resourceId) }),
  );

  return (
    <BaseResource
      label={t("settings.workflows.view.resources.event")}
      value={event?.name}
      isLoading={isLoading}
    />
  );
};
