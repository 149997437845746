import { useProviderRaiseHand } from "./provider";

export const useRaiseHand = () => {
  const { isHandRaised, raiseHand, lowerHand, toggleHand } =
    useProviderRaiseHand();

  return {
    isHandRaised,
    raiseHand,
    lowerHand,
    toggleHand,
  };
};
