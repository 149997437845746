import { Suspense } from "react";
import { StandardLayoutV2 } from "@circle-react/components/Layout/StandardLayoutV2";
import { VersionedContent } from "@circle-react/components/Layout/VersionedContent";
import { CommunityFeatureFlagsContextProvider } from "@circle-react/contexts";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { PageLoader } from "@circle-react/pages/PageLoader";

const ThreadsComponent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "ThreadsPage" */ "@circle-react/components/Messaging/ChatThreads"
    ),
);

export const ThreadsPage = () => (
  <CommunityFeatureFlagsContextProvider>
    <StandardLayoutV2 disableRightSidebar>
      <VersionedContent fullWidth fullHeight={false}>
        <Suspense fallback={<PageLoader />}>
          <ThreadsComponent />
        </Suspense>
      </VersionedContent>
    </StandardLayoutV2>
  </CommunityFeatureFlagsContextProvider>
);
