import { useRunsInfiniteQuery } from "@circle-react/components/SettingsApp/Workflows/hooks/useRunsInfiniteQuery";

export const useAreAllRunsCompleted = ({
  workflowId,
  disableRefetching,
}: {
  workflowId?: string;
  disableRefetching?: boolean;
}) => {
  const { areAllRunsCompleted, isLoading } = useRunsInfiniteQuery({
    workflowId,
    disableRefetching,
  });
  return { areAllRunsCompleted, isLoading };
};
