import { t } from "i18n-js";
import { usePaywallsAdminFormModal } from "@circle-react/components/Paywalls/Admin/PaywallsAdmin/FormModal";
import { settingsNavPath } from "@circle-react/helpers/urlHelpers";
import { IconButton } from "@circle-react-shared/uikit/HeaderV3/IconButton";
import { Dropdown } from "@circle-react-uikit/Dropdown";
import { Typography } from "@circle-react-uikit/Typography";
import { usePaywallsEditFormContext } from "./PaywallsEditFormProvider";

export const Header = () => {
  const { show: showPaywallModal } = usePaywallsAdminFormModal();
  const { paywallsListQuery } = usePaywallsEditFormContext();

  const showPaywallsAdminFormModal = () =>
    showPaywallModal({
      paywall: undefined,
      refetchPaywalls: () => {
        paywallsListQuery.refetch();
      },
      paywallGroupsEnabled: true,
    });

  const redirectToPaywalls = () => {
    window.open(settingsNavPath.paywalls.index, "_blank");
  };

  return (
    <div className="flex flex-row justify-between">
      <Typography.LabelLg weight="semibold">
        {t("courses.lockscreen.paywalls")}
      </Typography.LabelLg>
      <Dropdown
        button={
          <IconButton
            name="16-menu-dots-horizontal"
            iconClassName="!text-dark"
            ariaLabel={t("community_member.actions")}
          />
        }
      >
        <Dropdown.ItemWithLink onClick={redirectToPaywalls} linkType="button">
          {t(
            "paywalls.lock_screen.sidebar.paywall_edit_form.actions.manage_paywalls",
          )}
        </Dropdown.ItemWithLink>
        <Dropdown.ItemWithLink
          onClick={showPaywallsAdminFormModal}
          linkType="button"
        >
          {t(
            "paywalls.lock_screen.sidebar.paywall_edit_form.actions.create_paywall",
          )}
        </Dropdown.ItemWithLink>
      </Dropdown>
    </div>
  );
};
