import { useMemo } from "react";
import flatten from "lodash/flatten";
import { useInfiniteQuery } from "react-query";
import { reactQueryGet } from "@/react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";

export const SORT_OPTIONS_MAP = {
  ALPHABETICAL: "alphabetical",
  OLDEST: "oldest",
  LATEST: "latest",
  ROLE: "role",
};

export const DEFAULT_PAGE_SIZE = 30;

/**
 * @deprecated Prefer useCommunityMembersInfiniteQueryV2 for new use cases.
 */
export const useCommunityMembersInfiniteQuery = (args = {}) => {
  const {
    query = "",
    sort = SORT_OPTIONS_MAP.ALPHABETICAL,
    order,
    filters = {},
    perPage = DEFAULT_PAGE_SIZE,
    initialPage = 1,
    options = {},
    adminScope = false,
  } = args;

  const fetchPage = ({ pageParam = initialPage }) => {
    let page = 1;

    if (typeof pageParam === "number" && pageParam > 0) {
      page = pageParam;
    }

    const params = {
      page,
      sort,
      filters,
      per_page: perPage,
    };

    if (query) {
      params.query = query;
    }

    if (order) {
      params.order = order;
    }

    const route = adminScope
      ? internalApi.admin.search.communityMembers.index
      : internalApi.search.communityMembers.index;

    return reactQueryGet(route({ params }));
  };

  const queryKey = ["community-members", query, sort, order, filters, perPage];

  const queryOptions = {
    getNextPageParam: lastPage =>
      lastPage.has_next_page ? lastPage.page + 1 : undefined,
    getPreviousPageParam: firstPage =>
      firstPage.page > 1 ? firstPage.page - 1 : undefined,
    keepPreviousData: true,
    ...options,
  };

  const result = useInfiniteQuery(queryKey, fetchPage, queryOptions);
  const pages = useMemo(() => result.data?.pages || [], [result.data?.pages]);
  const totalMembers = pages[0]?.meta?.total_count || 0;
  const totalQueryMembers = pages[0]?.count || 0;
  const pageSize = pages[0]?.per_page || perPage;
  const members = useMemo(
    () => flatten(pages.map(page => page.records)),
    [pages],
  );

  return {
    ...result,
    queryKey,
    totalMembers,
    totalQueryMembers,
    members,
    pageSize,
    pages,
  };
};
