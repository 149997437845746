import { Suspense } from "react";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { PageLoader } from "@circle-react/pages/PageLoader";

const MembersProfileFieldsComponent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "MembersProfileFieldsPage" */ "@circle-react/components/SettingsApp/ProfileFields"
    ),
);

export const MembersProfileFieldsPage = () => (
  <Suspense fallback={<PageLoader />}>
    <MembersProfileFieldsComponent />
  </Suspense>
);
