import { useState } from "react";
import PropTypes from "prop-types";
import { Icon } from "@circle-react/components/shared/Icon";
import { Typography } from "@circle-react/components/shared/uikit/Typography";
import { AttachmentItem } from "../AttachmentItem";

export const AttachmentsList = ({ attachments, collapsed = true }) => {
  const [isCollapsed, setIsCollapsed] = useState(collapsed);
  const toggle = () => setIsCollapsed(isCollapsed => !isCollapsed);
  const label =
    attachments.length === 1
      ? I18n.t("support_widget.file")
      : I18n.t("support_widget.files");

  if (attachments.length === 0) {
    return null;
  }

  return (
    <>
      <Typography.LabelXs>
        <button
          type="button"
          onClick={toggle}
          className="text-dark hover:text-dark focus:text-dark cursor-pointer hover:underline"
        >
          {`${attachments.length} ${label}`}
          {isCollapsed ? (
            <Icon type="cheveron-up" useWithFillCurrentColor />
          ) : (
            <Icon type="cheveron-down" useWithFillCurrentColor />
          )}
        </button>
      </Typography.LabelXs>
      <div className="flex flex-col gap-2">
        {!isCollapsed &&
          attachments.map(attachment => (
            <AttachmentItem key={attachment.id} attachment={attachment} />
          ))}
      </div>
    </>
  );
};

AttachmentsList.propTypes = {
  attachments: PropTypes.array,
};
