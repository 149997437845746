import { useHMSRoom } from "../hms";
import { useLivekitRoom } from "../livekit";
import { useProviderHook } from "./useProviderHook";

export const useProviderRoom = (
  token,
  { onJoinError, participantData },
  streamEnded,
) =>
  useProviderHook({
    hms: useHMSRoom,
    livekit: useLivekitRoom,
  })(token, { onJoinError, participantData }, streamEnded);
