import { Suspense } from "react";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { PageLoader } from "@circle-react/pages/PageLoader";

const MarketingEmailsPreferencesComponent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "MarketingEmailsPreferencesPage" */ "@circle-react/components/MarketingEmails/EmailPreferences"
    ),
);

export const MarketingEmailsPreferencesPage = () => (
  <Suspense fallback={<PageLoader />}>
    <MarketingEmailsPreferencesComponent />
  </Suspense>
);
