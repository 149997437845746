import { useEffect, useRef } from "react";
import { defer } from "lodash";
import { useQuery } from "react-query";
import { workflowsApi } from "@circle-react/api";
import { WorkflowForm } from "../../WorkflowForm";

export const ReRunWorkflowForm = ({ workflowId, hideRerunModal }: any) => {
  const workflowFormRef = useRef<HTMLFormElement>(null);
  const { data, isLoading } = useQuery(
    ["workflow-detail", workflowId],
    () => workflowsApi.fetch({ id: workflowId }),
    {
      onSuccess: () => {
        defer(() => {
          if (workflowFormRef.current) {
            workflowFormRef.current.dispatchEvent(
              new Event("submit", { cancelable: true }),
            );
          }
        });
      },
    },
  );

  useEffect(
    () => () => {
      hideRerunModal();
    },
    [hideRerunModal],
  );

  if (isLoading) {
    return null;
  }

  return <WorkflowForm formRef={workflowFormRef} workflow={data} isRerun />;
};
