import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { useWhiteLabel } from "@circle-react/hooks/useWhiteLabel";
import { useShouldDisplayGoLiveFooter } from "../SidebarFooter/useShouldDisplayGoLiveFooter";

const poweredByHeightV2 = 37;
const goLiveButtonHeightV2 = 64;
const poweredByHeightV3 = 16;
const goLiveButtonHeightV3 = 39;

/* TODO: Remove this logic when migrating the StandardLayout to a CSS flex/grid approach */
export const useGoLivePoweredByHeight = () => {
  const { isV3Enabled } = useIsV3();
  const hasGoLiveButton = useShouldDisplayGoLiveFooter();
  const { shouldShowBranding } = useWhiteLabel();

  const goLiveButtonHeight = isV3Enabled
    ? goLiveButtonHeightV3
    : goLiveButtonHeightV2;

  const poweredByHeight = isV3Enabled ? poweredByHeightV3 : poweredByHeightV2;

  const containerPadding =
    isV3Enabled && (shouldShowBranding || hasGoLiveButton) ? 31 : 0;
  const containerGap =
    isV3Enabled && shouldShowBranding && hasGoLiveButton ? 8 : 0;

  const extraContainerSpacing = containerPadding + containerGap;

  return {
    goLiveButtonHeight,
    poweredByHeight,
    extraContainerSpacing,
  };
};
