import type { ReactNode } from "react";
import { Dropdown } from "@circle-react-uikit/Dropdown";
import { SortByItemLinks as SortByItemLinksComponent } from "./SortByItemLinks";
import { useSortBy } from "./useSortBy";

export interface SortByDropdownProps {
  defaultSort: string;
  onChange: (value: string) => void;
  include?: string[];
  exclude?: string[];
  shouldOpenToLeft?: boolean;
  button?: ReactNode;
}

export const SortByDropdown = ({
  defaultSort,
  include,
  exclude,
  shouldOpenToLeft = false,
  onChange,
  button,
}: SortByDropdownProps) => {
  const { sort, sortingOptions } = useSortBy({ defaultSort, exclude, include });
  const activeOptionValue = sort ? String(sort) : undefined;

  return (
    <Dropdown
      direction={shouldOpenToLeft ? "bottom-start" : "bottom-end"}
      options={sortingOptions}
      activeOptionValue={activeOptionValue}
      onChange={onChange}
      button={button}
    />
  );
};

export const SortByItemLinks = SortByItemLinksComponent;
