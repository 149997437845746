import { useMutation } from "react-query";
import { spaceApi } from "@/react/api";

export const useAddMembersToSpace = (options = {}) => {
  const addMember = ({
    spaceId,
    communityMembersId,
    notifyMembers,
    memberType,
    shouldAddAllMembers,
  }) =>
    spaceApi.addMemberToSpace({
      spaceId,
      body: {
        community_member_ids_or_emails: communityMembersId,
        notify_members: notifyMembers,
        select_all_members: shouldAddAllMembers,
        ...(memberType && { member_type: memberType }),
      },
    });

  return useMutation(addMember, options);
};
