import { formatPaywallCurrencyAmount } from "@/react/helpers/paywalls/currencies";
import { mapCurrencyAmountStrToInt } from "@circle-react/components/Paywalls/Admin/utils";
import { paywallPriceTypes } from "@circle-react/types";
import type {
  NoticeScenario,
  NoticeScenarioMatcher,
  NoticeScenarioMessagePropsResolver,
} from "../NoticeScenario.types";
import { installmentsStateValidator } from "./StateValidators";

const matcher: NoticeScenarioMatcher = ({
  priceType,
  hasUpfrontPayment,
  hasTrialPeriod,
}) =>
  priceType === paywallPriceTypes.installments &&
  !hasUpfrontPayment &&
  !hasTrialPeriod;
const messagePropsResolver: NoticeScenarioMessagePropsResolver = ({
  buildOptions,
  currency,
  watch,
}) => {
  const totalAmount = mapCurrencyAmountStrToInt(watch("amount"));
  const installmentsCount = parseInt(watch("installments_count"));
  const installmentAmount = Math.round(totalAmount / installmentsCount);
  return [
    "installments",
    {
      installments_frequency:
        buildOptions.frequencies[
          watch("billing_frequency")
        ].as_adverb.toLowerCase(),
      // This notice is formatted as: "... {amount} now, and then {count} {frequency} payments of {amount} ..."
      // That's why we need to subtract 1 from the installments count for presentation purposes.
      installments_count: installmentsCount - 1,
      installment_amount: formatPaywallCurrencyAmount(
        currency,
        installmentAmount,
      ),
      total_amount: formatPaywallCurrencyAmount(currency, totalAmount),
    },
  ];
};

export const installments: NoticeScenario = {
  matcher,
  stateValidator: installmentsStateValidator,
  messagePropsResolver,
};
