import { t } from "i18n-js";
import { useContactsInWorkflowsEnabled } from "@circle-react/hooks/useContactsInWorkflowsEnabled";
import { Icon } from "@circle-react-shared/Icon";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { Loader } from "@circle-react-shared/uikit/Loader";
import type { Action } from "../../../generated/WorkflowsApi.types";
import { Datetime } from "../../Table/Datetime";
import { useMarketingHubAccessForDate } from "../useMarketingHubAccessForDate";

interface NextRunAtColumnProps {
  date: string | null;
  steps: Action["action_type"][];
}

const i18nRoot = "settings.workflows.table";

export const NextRunAtColumn = ({ date, steps }: NextRunAtColumnProps) => {
  const isContactsInWorkflowsEnabled = useContactsInWorkflowsEnabled();
  const { hasAccessToRunOnDate, isLoading: isLoadingMarketingHubAccess } =
    useMarketingHubAccessForDate();

  if (isLoadingMarketingHubAccess) {
    return <Loader />;
  }

  const isMarketingHubWorkflow = steps.some(step => step === "send_email");

  if (
    isContactsInWorkflowsEnabled &&
    isMarketingHubWorkflow &&
    date &&
    !hasAccessToRunOnDate(date)
  ) {
    return (
      <TippyV2 content={t([i18nRoot, "requires_marketing_hub_tooltip"])}>
        {t([i18nRoot, "requires_marketing_hub"])}
      </TippyV2>
    );
  }

  return (
    <>
      <Datetime date={date} shouldIncludeTime />
      {date && <Icon type="16-clock" className="ml-2 pt-1" />}
    </>
  );
};
