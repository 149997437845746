import { t } from "i18n-js";
import { Icon } from "@circle-react-shared/Icon";

interface NewPostLinkButtonProps {
  newPostUrl: string;
  newPostUseDataRemote: boolean;
}
export const NewPostLinkButton = ({
  newPostUrl,
  newPostUseDataRemote,
}: NewPostLinkButtonProps) => (
  <a
    href={newPostUrl}
    data-remote={newPostUseDataRemote}
    className="text-dark flex w-full justify-center p-5"
  >
    <Icon
      aria-label={t("mobile_nav.quick_post")}
      type="bar-plus"
      size={24}
      useWithFillCurrentColor
    />
  </a>
);
