import { Row, RowContainer } from "@circle-react-uikit/SkeletonLoader";
import { Bookmarks } from "@circle-react-uikit/SkeletonLoader/skeletons/Bookmarks";

export const SearchResultModalHeaderSkeleton = () => (
  <div>
    <div className="mb-4 pt-6 md:pt-10">
      <RowContainer>
        <Row lg />
      </RowContainer>
    </div>
    <Bookmarks />
  </div>
);
