import { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { isEventsSpace } from "@/react/helpers/spaceHelpers";
import { newSpaceEventPath, posts } from "@/react/helpers/urlHelpers";
import { useNotificationDropdown } from "@circle-react/components/Spaces/SidebarV2/PlatformMenu/useNotificationDropdown";
import { usePlatformMenu } from "@circle-react/components/Spaces/SidebarV2/PlatformMenu/usePlatformMenu";
import { useSpacesContext } from "@circle-react/contexts";
import { usePunditUserContext } from "@circle-react/contexts";
import { useChatNotification } from "@circle-react/hooks/chatsV2/useChatNotification";
import { useShouldShowMessagingRoutes } from "@circle-react/hooks/useIsMessagingEnabled";

export const useBottomBar = () => {
  const isHomePage = useRouteMatch("/home")?.isExact;
  const isFeedPage = useRouteMatch("/feed")?.isExact;
  const { params: { spaceSlug } = {} } = useRouteMatch("/c/:spaceSlug") || {};
  const space = useSpacesContext().helpers.findBy({ slug: spaceSlug }) || {};
  const spaceIsEvent = isEventsSpace(space);
  const [newPostUrl, setNewPostUrl] = useState();
  const [newPostUseDataRemote, setNewPostUseDataRemote] = useState();

  const { currentCommunitySettings } = usePunditUserContext();
  const { spotlightSearch } = usePlatformMenu();
  const shouldShowMessagingRoutes = useShouldShowMessagingRoutes();
  const {
    newNotificationCount,
    toggleNotificationDrawer,
    resetNewNotificationsCount,
  } = useNotificationDropdown();

  const { unreadChatCount } = useChatNotification();

  const onSearchClick = () => {
    spotlightSearch.show();
  };

  const onNotificationsClick = () => {
    toggleNotificationDrawer();
    resetNewNotificationsCount();
  };

  const shouldNewPostOpenModal =
    !spaceIsEvent &&
    !!currentCommunitySettings?.reactified_new_post_on_mobile_enabled;

  useEffect(() => {
    let url;

    if (isHomePage || isFeedPage || !spaceSlug) {
      url = "/c/new_post_in_space";
      setNewPostUseDataRemote(true);
    } else {
      url = spaceIsEvent
        ? newSpaceEventPath({ spaceSlug: space.slug })
        : posts.new({ spaceSlug: space.slug });
      setNewPostUseDataRemote(false);
    }

    setNewPostUrl(url);
  }, [isHomePage, space.slug, spaceIsEvent, isFeedPage, spaceSlug]);

  return {
    newPostUrl,
    newPostUseDataRemote,
    shouldNewPostOpenModal,
    unreadChatCount,
    unreadNotificationCount: newNotificationCount,
    onNotificationsClick,
    onSearchClick,
    shouldShowMessagingRoutes,
  };
};
