import { t } from "i18n-js";
import { Alert } from "@circle-react-uikit/AlertV2";
import { Typography } from "@circle-react-uikit/Typography";
import { OptionDescription } from "../../AccessForm/OptionDescription";

export const DraftAlert = () => (
  <div className="flex flex-col gap-y-6">
    <Alert
      type="info"
      title={t("customize_space.draft_warning.title")}
      description={
        <div className="flex flex-col gap-y-2">
          <Typography.BodySm>
            {t("customize_space.draft_warning.description")}
          </Typography.BodySm>
          <OptionDescription
            accessDesc={t("customize_space.draft_warning.access")}
            visibilityDesc={t("customize_space.draft_warning.visibility")}
            isVisibilityOpen={false}
          />
        </div>
      }
    />
    <div className="flex flex-col gap-y-2">
      <Typography.LabelLg weight="semibold">
        {t("customize_space.draft_warning.form_title")}
      </Typography.LabelLg>
      <Typography.BodySm>
        {t("customize_space.draft_warning.form_description")}:
      </Typography.BodySm>
    </div>
  </div>
);
