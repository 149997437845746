import { FirstPaymentPreview } from "@circle-react/components/Paywalls/Checkout/CheckoutForm/CheckoutPreview/FirstPaymentPreview";
import { FuturePaymentsPreview } from "@circle-react/components/Paywalls/Checkout/CheckoutForm/CheckoutPreview/FuturePaymentsPreview";
import { ProratedCreditPreview } from "@circle-react/components/Paywalls/Checkout/CheckoutForm/CheckoutPreview/ProratedCreditPreview";
import { TotalDueTodayPreview } from "@circle-react/components/Paywalls/Checkout/CheckoutForm/CheckoutPreview/TotalDueTodayPreview";
import { Loader } from "@circle-react-uikit/Loader";
import { AddPaymentMethod } from "./AddPaymentMethod";
import { useChangePlanFormContext } from "./ChangePlanFormContext";
import { Coupon } from "./Coupon";
import { SelectedPlanDetails } from "./SelectedPlanDetails";
import { useCurrentAndSelectedPlans } from "./hooks/useCurrentAndSelectedPlans";

export const ConfirmationStep = () => {
  const {
    priceChangePreview,
    isInTrial,
    shouldAddPaymentMethod,
    previewMutation,
  } = useChangePlanFormContext();
  const { isLoadingSelectedPaywall, selectedPaywall } =
    useCurrentAndSelectedPlans();

  if (isLoadingSelectedPaywall) {
    return <Loader />;
  }

  return (
    <>
      <SelectedPlanDetails />
      {selectedPaywall.coupons_enabled && <Coupon />}
      {previewMutation.isLoading ? (
        <Loader />
      ) : (
        <>
          <ProratedCreditPreview preview={priceChangePreview} />
          <FirstPaymentPreview
            className="border-t"
            preview={priceChangePreview}
          />
          <FuturePaymentsPreview preview={priceChangePreview} />
          <TotalDueTodayPreview preview={priceChangePreview} />
          {isInTrial && shouldAddPaymentMethod && <AddPaymentMethod />}
        </>
      )}
    </>
  );
};
