import type { ReactNode } from "react";
import { t } from "i18n-js";
import { Modal } from "@/react/components/shared/uikit/ModalV2";
import { Icon } from "@circle-react-shared/Icon";
import { Button } from "@circle-react-uikit/Button";
import { Form } from "@circle-react-uikit/Form";
import { Typography } from "@circle-react-uikit/Typography";
import { useChangePlanFormContext } from "../ChangePlanFormContext";

export interface ChangePlanFormStepProps {
  title: string;
  children: ReactNode;
}

export const ChangePlanFormStep = ({
  title,
  children,
}: ChangePlanFormStepProps) => {
  const {
    dismiss,
    canGoBack,
    goBack,
    formDefaults,
    submitLabel,
    submitDisabled,
    submit,
  } = useChangePlanFormContext();

  return (
    <>
      <Modal.Header>
        <Modal.Title size="md" className="text-center">
          {title}
        </Modal.Title>
        <Modal.HeaderActions className="absolute right-0 top-0 mr-6 mt-6">
          <Modal.CloseButton onClick={dismiss} />
        </Modal.HeaderActions>
        {canGoBack && (
          <Modal.HeaderActions className="absolute left-0 top-0 ml-6 mt-6">
            <Button type="button" variant="plain" small onClick={goBack}>
              <span className="sr-only">{t("back")}</span>
              <Icon type="20-chevron-left" size={20} />
            </Button>
          </Modal.HeaderActions>
        )}
      </Modal.Header>
      <Modal.Body>
        <Form
          id="change_plan_form"
          defaultValues={formDefaults}
          onSubmit={submit}
          className="flex flex-col"
        >
          {children}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          full
          large
          variant="circle"
          form="change_plan_form"
          type="submit"
          disabled={submitDisabled}
        >
          {submitLabel}
        </Button>
        <div className="mt-2 flex flex-row items-center justify-center">
          <Icon type="lock-solid" className="mt-2 !h-6 !w-6" />
          <Typography.BodyXs weight="normal">
            {t(
              "member_profile_modal.billing.subscriptions.payment_secure_and_encrypted",
            )}
          </Typography.BodyXs>
        </div>
      </Modal.Footer>
    </>
  );
};
