import { Suspense } from "react";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { PageLoader } from "@circle-react/pages/PageLoader";

const SpacesSettingsComponent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "SpacesSettingsPage" */ "@circle-react/components/SettingsApp/Spaces"
    ),
);

export const SpacesSettingsPage = () => (
  <Suspense fallback={<PageLoader />}>
    <SpacesSettingsComponent />
  </Suspense>
);
