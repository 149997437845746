import type { PropsWithChildren } from "react";
import classNames from "classnames";

interface ListHeaderProp {
  className?: string;
}

export const ListHeader = ({
  children,
  className,
}: PropsWithChildren<ListHeaderProp>) => (
  <div
    className={classNames(
      "divide-primary border-primary bg-primary sticky top-0 z-10 hidden divide-x md:flex md:border-b",
      className,
    )}
  >
    {children}
  </div>
);
