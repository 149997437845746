import { Form } from "@circle-react-shared/uikit/Form";
import type { FieldWrapperProps } from "./FieldWrapper";
import { FieldWrapper } from "./FieldWrapper";

export type AmountFieldProps = Pick<
  FieldWrapperProps,
  "name" | "labelKey" | "placeholder" | "tooltipText"
>;

export const InputField = ({
  name,
  labelKey,
  placeholder = "",
  tooltipText,
}: AmountFieldProps) => (
  <FieldWrapper
    name={name}
    labelKey={labelKey}
    placeholder={placeholder}
    tooltipText={tooltipText}
    rules={{ required: true }}
  >
    <Form.Input />
  </FieldWrapper>
);
