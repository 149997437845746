import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useLayoutContext } from "@/react/components/SettingsApp/Layout/LayoutContext";
import { SideBar } from "@/react/components/Spaces/Sidebar";
import { useLayoutPartialsContext } from "@circle-react/contexts/layoutPartialsContext";
import { useWindowResize } from "@circle-react/hooks/utils/useWindowResize";
import { Portal } from "@circle-react-shared/uikit/Portal";

export const MobileDrawer = ({
  renderLeftSidebar,
}: {
  renderLeftSidebar?: () => void;
}) => {
  const history = useHistory();
  const { data: { mobile_drawer_html } = {} } = useLayoutPartialsContext();
  const [canRenderSidebar, setCanRenderSidebar] = useState(false);
  const { config: layoutContext } = useLayoutContext();
  let leftSidebarContent = renderLeftSidebar;

  if (!renderLeftSidebar && layoutContext?.renderLeftSidebar) {
    leftSidebarContent = layoutContext.renderLeftSidebar;
  }

  const ref = useCallback(
    node => {
      if (node !== null) {
        setCanRenderSidebar(true);
      }
    },
    [setCanRenderSidebar],
  );

  const handleScroll = useCallback(() => {
    const scrollLeft =
      document.body.scrollLeft || document.documentElement.scrollLeft;
    // The sidebar width is 296px, hence we wish to close it once the user has scrolled past half of it
    const threshold = 148;

    if (scrollLeft > threshold) {
      window.mobileDrawer?.close();
    }
  }, []);

  const onResizeHandler = (e: any) => {
    const windowWidth = e?.target?.innerWidth;
    if (!windowWidth) {
      return;
    }
    // Tailwind lg screen breakpoint
    if (windowWidth > 1024) {
      window?.mobileDrawer?.close();
    }
  };

  useWindowResize(onResizeHandler);

  useEffect(() => {
    if (canRenderSidebar) {
      document.body.addEventListener("scroll", handleScroll);
    }

    return () => {
      document.body.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll, canRenderSidebar]);

  useEffect(
    () =>
      history.listen(() => {
        // Gets set in app/javascript/controllers/mobile_sidebar_controller.js
        // We want to close the sidebar when we navigate away from sidebar
        window.mobileDrawer?.close();
      }),
    [history],
  );

  if (!mobile_drawer_html) {
    return null;
  }

  return (
    <>
      <div ref={ref} dangerouslySetInnerHTML={{ __html: mobile_drawer_html }} />
      {canRenderSidebar && (
        <Portal selectorQuery="#react-mobile-community-sidebar">
          {leftSidebarContent ? leftSidebarContent() : <SideBar />}
        </Portal>
      )}
    </>
  );
};
