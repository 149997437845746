import PropTypes from "prop-types";
import { Icon } from "@circle-react-shared/Icon";

export const SearchParticipants = ({ onSearchChange }) => (
  <div className="grow">
    <Icon type="search" />
    <input
      type="text"
      name="textSearch"
      placeholder={I18n.t("live_streams.dashboard.participants.placeholder")}
      onChange={onSearchChange}
      className="bg-primary placeholder:text-light mr-2 w-10/12 border-transparent placeholder:opacity-100 focus:border-transparent focus:ring-0"
    />
  </div>
);

SearchParticipants.propTypes = {
  onSearchChange: PropTypes.func,
};
