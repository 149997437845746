import { t } from "i18n-js";
import {
  SPACE_POST_TYPES,
  TRIGGER_RESOURCE_TYPE,
} from "@circle-react/components/SettingsApp/Workflows/constants";
import { EntitySelector } from "../../EntitySelector";

const i18nRoot = "settings.workflows.form.entity_type.lesson";

export const entityTypeOptions = [
  {
    value: TRIGGER_RESOURCE_TYPE.COMMUNITY,
    label: t([i18nRoot, "community.title"]),
    description: t([i18nRoot, "community.description"]),
  },
  {
    value: TRIGGER_RESOURCE_TYPE.SPACE_GROUP,
    label: t([i18nRoot, "space_group.title"]),
    description: t([i18nRoot, "space_group.description"]),
  },
  {
    value: TRIGGER_RESOURCE_TYPE.COURSE,
    label: t([i18nRoot, "space.title"]),
    description: t([i18nRoot, "space.description"]),
  },
  {
    value: TRIGGER_RESOURCE_TYPE.LESSON,
    label: t([i18nRoot, "specific_lesson.title"]),
    description: t([i18nRoot, "specific_lesson.description"]),
  },
];

export const AnyLessonSelector = () => (
  <EntitySelector
    entityTypeOptions={entityTypeOptions}
    resourceType={SPACE_POST_TYPES.COURSE}
  />
);

AnyLessonSelector.propTypes = {};
