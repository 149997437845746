import { t } from "i18n-js";
import { StatusBadge } from "@circle-react-uikit/StatusBadge";
import { PaymentProcessorStatusAlert } from "./PaymentProcessorStatusAlert";

export const PaymentProcessorEnabled = ({ paymentProcessor }) => {
  if (!paymentProcessor.is_restricted_soon) return null;

  const sentiment = "warning";

  const badgeComponent = (
    <StatusBadge variant={sentiment}>
      {t(`settings.paywalls.processor_status_v2.restricted_soon.status`)}
    </StatusBadge>
  );

  const titleComponent = t(
    "settings.paywalls.processor_status_v2.restricted_soon.title",
  );

  const descriptionComponent = (
    <div className="flex flex-col gap-y-2">
      <p
        className="text-default text-lg"
        dangerouslySetInnerHTML={{
          __html: t(
            "settings.paywalls.processor_status_v2.restricted_soon.description",
          ),
        }}
      />
      <p
        className="text-default text-sm"
        dangerouslySetInnerHTML={{
          __html: t(
            "settings.paywalls.processor_status_v2.restricted_soon.call_support",
          ),
        }}
      />
    </div>
  );

  return (
    <PaymentProcessorStatusAlert
      sentiment={sentiment}
      badge={badgeComponent}
      title={titleComponent}
      description={descriptionComponent}
    />
  );
};
