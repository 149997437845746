import { QueryClient, QueryClientProvider } from "react-query";
import { LayoutContextProvider } from "@circle-react/components/SettingsApp/Layout/LayoutContext";
import { SomethingWentWrong } from "@circle-react/components/shared/uikit/ErrorPages";
import { SpacesContextProvider } from "@circle-react/contexts";
import { AppContextProvider } from "@circle-react/providers/AppContext";
import { reactQueryConfig } from "@circle-react/providers/QueryClient/reactQueryConfig";
import { withErrorBoundary } from "@circle-react-uikit/ErrorBoundary";
import { ToastProvider } from "@circle-react-uikit/ToastV2";

const queryClient = new QueryClient(reactQueryConfig);
const renderSomethingWentWrong = () => <SomethingWentWrong />;

export const SettingsAppV1Provider = withErrorBoundary(
  ({ notice, alert, display_community_switcher, children, ...rest }) => (
    <QueryClientProvider client={queryClient}>
      <AppContextProvider
        backendProps={{
          notice,
          alert,
          display_community_switcher,
          ...rest,
        }}
      >
        <SpacesContextProvider>
          <ToastProvider>
            <LayoutContextProvider>{children}</LayoutContextProvider>
          </ToastProvider>
        </SpacesContextProvider>
      </AppContextProvider>
    </QueryClientProvider>
  ),
  renderSomethingWentWrong,
);
