import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { reactQueryGet } from "@/react/helpers/backendRequestHelpers";
import { usePunditUserContext } from "@circle-react/contexts";
import { isPublicStream } from "@circle-react/helpers/liveStreamRoomHelpers";
import { publicLiveStreams } from "@circle-react/helpers/urlHelpers";
import { useRoom } from "./useRoom";

export const useGuests = ({ refetchInterval = null } = {}) => {
  const { currentCommunity = {}, isLoading: isCommunityLoading } =
    usePunditUserContext();
  const { roomSlug } = useParams();
  const room = useRoom();

  const { data: guests, isLoading } = useQuery(
    ["public-live-stream", "guests", roomSlug, currentCommunity?.id],
    () =>
      reactQueryGet(
        publicLiveStreams.rooms.guests.index({
          slug: roomSlug,
          params: {
            community_id: currentCommunity?.id,
          },
        }),
      ),
    {
      enabled:
        Boolean(room) &&
        isPublicStream(room) &&
        !isCommunityLoading &&
        Boolean(roomSlug),
      refetchInterval,
    },
  );
  return {
    guests,
    count: guests?.length || 0,
    isLoading,
  };
};
