import { MemberDirectoryContent } from "./MemberDirectoryContent";
import { useMemberDirectorySettings } from "./useMemberDirectorySettings";

export const MemberDirectory = () => {
  const memberDirectorySettings = useMemberDirectorySettings();

  if (memberDirectorySettings.isLoading) {
    return null;
  }

  return (
    <MemberDirectoryContent memberDirectorySettings={memberDirectorySettings} />
  );
};

export default MemberDirectory;
