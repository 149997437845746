import { t } from "i18n-js";
import { formatDisplayAmountString } from "@circle-react/components/Paywalls/Admin/utils";
import { copyPaywallCheckoutUrlWithPrefix } from "@circle-react/helpers/paywallCheckoutHelpers";
import { Icon } from "@circle-react-shared/Icon";
import { Badge } from "@circle-react-uikit/Badge";
import { IconDropdownMenu } from "@circle-react-uikit/IconDropdownMenu";
import { usePaywallsAdminFormModal } from "../FormModal/";

export const useList = ({
  paywalls,
  refetchPaywalls,
  paywallGroupsEnabled,
}) => {
  const { show } = usePaywallsAdminFormModal();

  const showPaywallAdminFormModalToEdit = paywall =>
    show({ paywall, refetchPaywalls, paywallGroupsEnabled });
  const showPaywallAdminFormModalToCreate = () =>
    show({ paywall: undefined, refetchPaywalls, paywallGroupsEnabled });

  const dataSource = paywalls?.map(paywall => {
    const statusStates = {
      active: {
        label: t("settings.paywalls.status.active"),
        variant: "success",
      },
      inactive: {
        label: t("settings.paywalls.status.inactive"),
        variant: "default",
      },
      draft: {
        label: t("settings.paywalls.status.draft"),
        variant: "default",
      },
      notIdentified: {
        label: "",
        variant: "default",
      },
    };
    const selectedStatusObject =
      statusStates[paywall.status] ?? statusStates["notIdentified"];

    const preferredPrice =
      paywall.stripe_prices_attributes.find(
        price_attribute => price_attribute.preferred,
      ) ?? paywall.stripe_prices_attributes[0];

    const preferredPriceText = preferredPrice
      ? formatDisplayAmountString(
          paywall.currency,
          preferredPrice.interval,
          preferredPrice.interval_count,
          preferredPrice.amount,
        )
      : t("settings.paywalls.list.form.no_preferred_price_selected");

    const menuItems = [
      {
        key: "edit-paywall",
        label: t("settings.paywalls.list.form.edit"),
        onClick: () => showPaywallAdminFormModalToEdit(paywall),
      },
    ];

    return {
      key: paywall.id,
      displayName: paywall.name || paywall.display_name,
      preferred_price: preferredPriceText,
      status: (
        <Badge variant={selectedStatusObject.variant}>
          {selectedStatusObject.label}
        </Badge>
      ),
      action: (
        <button
          type="button"
          className="text-[12px]"
          onClick={() =>
            copyPaywallCheckoutUrlWithPrefix(
              paywall.checkout_path,
              "#paywalls-list",
            )
          }
        >
          <Icon type="20-copy" className="mr-2" />
          <span>{t("settings.paywalls.list.form.copy_url")}</span>
        </button>
      ),
      actionMenu: (
        <IconDropdownMenu
          menuItems={menuItems}
          dropdownSide="left"
          shouldCloseOnClickItem
        />
      ),
    };
  });

  return {
    showPaywallAdminFormModalToCreate,
    dataSource,
  };
};
