import PropTypes from "prop-types";
import classnames from "classnames";

export const Content = ({ children, className }) => (
  <div
    className={classnames("main__content mx-auto h-full max-w-5xl", className)}
  >
    {children}
  </div>
);

Content.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
};
