import I18n from "i18n-js";
import { useSpacesContext } from "@circle-react/contexts";
import { DataTable } from "@circle-react-shared/uikit/DataTableV2";
import { ItemWithLink } from "@circle-react-shared/uikit/Dropdown/ItemWithLink";
import { useModalData } from "@circle-react-shared/uikit/ModalV2";
import { usePricingTabContext } from "../../hooks/usePricingTabContext";
import { useRemovePriceConfirmationModal } from "../RemovePriceConfirmationModal";

export const ActionsColumn = ({ row: { original: pricePackage } }: any) => {
  const { show: showRemovePriceConfirmationModal } =
    useRemovePriceConfirmationModal();
  const { records: spaceRecords } = useSpacesContext();
  const { contentPortalElement } = useModalData();
  const { getPrices, removePrice } = usePricingTabContext();

  const prices = getPrices();
  const isLastPrice = prices.length === 1;

  if (isLastPrice) {
    return null;
  }

  const isSpaceContainPaywallLockScreen = (space: any) => {
    const paywallLockScreenBlock = space.lock_screen_blocks?.find(
      (lockScreenBlock: any) => lockScreenBlock?.data?.paywalls,
    );
    return !!paywallLockScreenBlock?.data?.paywalls?.find(
      (paywallLockScreenBlock: any) =>
        pricePackage?.id &&
        (paywallLockScreenBlock?.web_paywall_price?.id === pricePackage?.id ||
          paywallLockScreenBlock?.mobile_paywall_price?.id ===
            pricePackage?.id),
    );
  };

  const removePriceOnClick = () => {
    const spacesWithPaywallLockScreenToRemove = spaceRecords.filter(
      (space: any) => isSpaceContainPaywallLockScreen(space),
    );

    if (spacesWithPaywallLockScreenToRemove.length > 0) {
      void showRemovePriceConfirmationModal({
        action: () => removePrice(pricePackage),
        spaces: spacesWithPaywallLockScreenToRemove,
      });
    } else {
      removePrice(pricePackage);
    }
  };

  return (
    <DataTable.Actions
      contentPortalElement={contentPortalElement ?? document.body}
    >
      {!isLastPrice && (
        <ItemWithLink
          key="remove"
          hasDanger
          onClick={removePriceOnClick}
          linkType="button"
        >
          {I18n.t("settings.paywalls.modal.tabs.pricing.table.actions.remove")}
        </ItemWithLink>
      )}
    </DataTable.Actions>
  );
};
