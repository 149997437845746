import { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Icon } from "@circle-react-shared/Icon";

export const SortParticipants = ({ onClickSort }) => {
  const [ascending, setAscending] = useState(false);

  const handleClick = () => {
    setAscending(!ascending);
    onClickSort(ascending);
  };

  return (
    <div className="grid grid-cols-2 pr-2 font-medium text-gray-800">
      <p className="pr-1">
        {I18n.t("live_streams.dashboard.participants.sort")}
      </p>
      <Icon
        type="caret-down"
        className={classnames("cursor-pointer fill-gray-800", {
          "rotate-180": ascending,
        })}
        onClick={handleClick}
      />
    </div>
  );
};

SortParticipants.propTypes = {
  onClickSort: PropTypes.func,
};
