import PropTypes from "prop-types";
import { useCurrentCommunityContext } from "@circle-react/apps/AuthenticationApp";
import { usePaywallCheckoutContext } from "@circle-react/contexts/Paywalls/paywallCheckoutContext";
import { DefaultTermsLabel } from "./DefaultTermsLabel";

export const AcceptTermsLabel = ({ caller }) => {
  const checkoutContext = usePaywallCheckoutContext();
  const currentCommunityContext = useCurrentCommunityContext();

  const { currentCommunity } =
    caller === "paywallCheckout" ? checkoutContext : currentCommunityContext;

  if (!currentCommunity) {
    return <DefaultTermsLabel showCreatorTerms />;
  }

  const { locale, terms_url, privacy_url } = currentCommunity;
  return (
    <DefaultTermsLabel
      showCreatorTerms={false}
      locale={locale}
      communityTermsUrl={terms_url}
      communityPrivacyUrl={privacy_url}
    />
  );
};

AcceptTermsLabel.propTypes = {
  caller: PropTypes.string,
};
