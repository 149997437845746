import { reactQueryPost } from "@/react/helpers/backendRequestHelpers";
import { internalApi } from "@/react/helpers/urlHelpers";

export interface PaywallsAdminExportsCreate {
  relation: "CommunityMemberCharge" | "CommunityMemberSubscription";
  fields: Array<string>;
  filters: Record<string, string>;
  timezone: string;
}

const create = (params: PaywallsAdminExportsCreate) =>
  reactQueryPost(internalApi.admin.paywalls.exports.create(), params);

export const paywallsExportCsvApi = { create };
