import { useMemo } from "react";
import { useQuery } from "react-query";
import { PARTICIPANT_STATUS } from "@circle-react/helpers/liveStreamRoomHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";

// Requesting max per page since we always want all participants
// 1000 is the maximum for enterprise plan, requesting double to
// account for addons.
const per_page = 2000;

export const useParticipants = (room, currentParticipant, recorder) => {
  const params = {
    status: PARTICIPANT_STATUS.ACTIVE,
    per_page,
  };
  if (recorder?.recording_key) {
    params.recording_key = recorder.recording_key;
  }

  // Only query participants when we have already joined
  // (!!currentParticipant)
  const {
    data,
    isLoading: isLoadingParticipants,
    refetch: refetchParticipants,
  } = useQuery(
    internalApi.liveStreams.participants({
      roomId: room?.id,
      params,
    }),
    {
      enabled: !!currentParticipant || !!recorder,
    },
  );
  const participantsArray = data?.records;
  // We want to maintain an object of participants for easy access
  // [id] => participant
  const participants = useMemo(
    () =>
      participantsArray?.reduce((prev, participant) => {
        prev[participant.id] = participant;
        return prev;
      }, {}),
    [participantsArray],
  );

  const roles = {
    host: [room?.creator.id],
    moderator: participantsArray
      ?.filter(participant => participant.role === "moderator")
      .map(participant => participant.community_member_id),
  };

  return {
    participants,
    isLoadingParticipants,
    refetchParticipants,
    roles,
  };
};
