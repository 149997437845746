import { t } from "i18n-js";
import { useGetPaywallsCurrencies } from "@circle-react/hooks/paywalls/useGetPaywallsCurrencies";
import {
  CheckboxFilter,
  CheckboxSelectedText,
  Filter,
  useFilterUrl,
} from "@circle-react-shared/Filter";

const localeRoot = "settings.paywalls_admin_dashboard.filters.currency";
const queryParamsKey = "currency";

export const CurrencyFilter = ({
  isInitialOpen,
  changeUrl = true,
  value,
}: any) => {
  const { queryParams, updateUrl } = useFilterUrl();

  const { data: paywallsCurrencies, isLoading: isLoadingPaywallsCurrencies } =
    useGetPaywallsCurrencies();
  if (isLoadingPaywallsCurrencies || !paywallsCurrencies) {
    return null;
  }

  const currencyOptions = paywallsCurrencies.map(currency => ({
    label: currency.code.toUpperCase(),
    value: currency.code,
  }));

  return (
    <Filter
      chip={t([localeRoot, "chip"])}
      title={t([localeRoot, "title"])}
      selectedText={value || queryParams[queryParamsKey]}
      renderSelectedText={props => (
        <CheckboxSelectedText items={currencyOptions} {...props} />
      )}
      isInitialOpen={isInitialOpen}
      onApply={value => {
        updateUrl("page", 1);
        changeUrl && updateUrl(queryParamsKey, value);
      }}
      shouldShowRemoveButton
    >
      <CheckboxFilter
        options={currencyOptions}
        legend={t([localeRoot, "legend"])}
        name="paywalls-filter"
        activeText={value ?? queryParams[queryParamsKey]}
      />
    </Filter>
  );
};

export const filterProperties = {
  name: "currency",
  filterKeys: ["currency"],
  component: CurrencyFilter,
  label: t([localeRoot, "chip"]),
};
