import { useEffect, useRef } from "react";
import classnames from "classnames";
import { t } from "i18n-js";
import {
  MAXIMUM_FREE_TRIAL_DAYS,
  MINIMUM_FREE_TRIAL_DAYS_WHEN_ENABLED as MINIMUM_FREE_TRIAL_DAYS,
} from "@circle-react/components/Paywalls/Admin/PaywallsAdmin/FormModal/PricingTab/constants";
import { Input } from "@circle-react-uikit/Input";
import { useBulkImportTrialPaywallSubscriptionFormContext } from "./BulkImportTrialPaywallSubscriptionFormProvider";
import { CustomFormItem } from "./CustomFormItem";

export const TrialDaysForm = () => {
  const { paywallId, paywallTrialDays, setPaywallTrialDays } =
    useBulkImportTrialPaywallSubscriptionFormContext();
  const paywallIsBlank = paywallId === "";
  const paywallIdOld = useRef(paywallId);

  const onChange = e => {
    const trialDays = e.target.value === "" ? NaN : Number(e.target.value);

    if (isNaN(trialDays)) {
      setPaywallTrialDays("");
    } else {
      if (trialDays < MINIMUM_FREE_TRIAL_DAYS) {
        setPaywallTrialDays(1);
      } else if (trialDays > MAXIMUM_FREE_TRIAL_DAYS) {
        setPaywallTrialDays(MAXIMUM_FREE_TRIAL_DAYS);
      } else {
        setPaywallTrialDays(trialDays);
      }
    }
  };

  useEffect(() => {
    if (paywallIdOld.current !== paywallId) {
      setPaywallTrialDays("");
      paywallIdOld.current = paywallId;
    }
  }, [paywallId, setPaywallTrialDays]);

  return (
    <CustomFormItem
      label={t("create_paywall_subscription_modal.form.trial_days_toggle")}
    >
      <Input
        name="paywall[paywall_trial_days]"
        suffix={t("create_paywall_subscription_modal.form.trial_days_suffix")}
        disabled={paywallIsBlank}
        otherClassName={classnames("", {
          "!opacity-100 !bg-secondary": paywallIsBlank,
        })}
        value={paywallTrialDays}
        onChange={onChange}
      />
    </CustomFormItem>
  );
};
