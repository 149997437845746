import { t } from "i18n-js";
import { Avatar } from "@circle-react/components/SettingsApp/Workflows/components/Avatar";
import { Typography } from "@circle-react-uikit/Typography";
import type { CancelSubscriptionApiParams } from "../cancelSubscription";
import { PaywallName } from "./PaywallName";

export interface HistoryDetailViewPaywallProps {
  body: {
    community_member_id: number;
  };
  url: string;
  apiParams: CancelSubscriptionApiParams;
}

export const HistoryDetailViewPaywall = ({
  body,
  apiParams,
}: HistoryDetailViewPaywallProps) => {
  const { community_member_id: communityMemberId } = body;
  const { paywall_id: paywallId } = apiParams;

  return (
    <div className="flex flex-col gap-2">
      <div className="md:flex">
        <div className="w-1/4">
          <Typography.LabelSm weight="semibold">
            {t("settings.workflows.action_messages.paywall")}
          </Typography.LabelSm>
        </div>
        <div className="w-3/4">
          <PaywallName id={paywallId} />
        </div>
      </div>
      <div className="md:flex">
        <div className="w-1/4">
          <Typography.LabelSm weight="semibold">
            {t("settings.workflows.action_messages.member")}
          </Typography.LabelSm>
        </div>
        <div className="w-3/4">
          <Avatar communityMemberId={communityMemberId} />
        </div>
      </div>
    </div>
  );
};
