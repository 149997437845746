import classNames from "classnames";
import { t } from "i18n-js";
import {
  stepKeysToOnboardingWidget,
  useOnboarding,
} from "@/react/components/CommunityOnboarding/useOnboarding";
import { Typography } from "@circle-react/components/shared/uikit/Typography";
import { onboardingItemPathQueryParam } from "@circle-react/helpers/routerPathHelpers";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { Loader } from "@circle-react-uikit/Loader";
import { GettingStartedStep } from "./GettingStartedStep";

export const CommunityOnboardingGettingStartedSection = () => {
  const { onboardingData, isLoading, isOnboardingAvailable } = useOnboarding();
  const { isV3Enabled } = useIsV3();

  if (isLoading) {
    return <Loader />;
  }

  if (!isLoading && !isOnboardingAvailable) {
    return null;
  }

  const items = Object.keys(stepKeysToOnboardingWidget);

  return (
    <div
      className={classNames(
        "sidebar__block sidebar__onboarding-steps !bg-primary !border-primary block border",
        {
          "overflow-hidden !rounded-2xl": isV3Enabled,
        },
      )}
    >
      <div className="block__inside !bg-primary !shadow-none">
        <div className="block__title">
          <Typography.TitleMd as="h3">
            {t("onboarding_title")}
          </Typography.TitleMd>
        </div>
        <ul className="ul--steps">
          {items.map(key => {
            const stepOnboardingObj = stepKeysToOnboardingWidget[key];
            return (
              <GettingStartedStep
                key={key}
                text={stepOnboardingObj.label}
                href={onboardingItemPathQueryParam({
                  item: stepOnboardingObj.queryParam,
                })}
                complete={onboardingData[key]}
              />
            );
          })}
        </ul>
      </div>
    </div>
  );
};
