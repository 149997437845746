import type { CountryCode } from "@circle-react/types/Countries";

export const I18N_ROOT = "settings.emails.settings";

export const FIELD_USE_CUSTOM_REPLY_TO_EMAIL = "use_custom_reply_to_email";

export const EMAIL_PREFIX_REGEX = /^[A-Z0-9._%+-]+$/i;

export const FIRST_COUNTRIES: Array<Partial<CountryCode>> = [
  "US",
  "BR",
  "GB",
  "AU",
  "CA",
  "DE",
  "FR",
];

export const REMAINING_COUNTRIES: Array<Partial<CountryCode>> = [
  "AE",
  "AG",
  "AL",
  "AM",
  "AR",
  "AT",
  "BA",
  "BE",
  "BG",
  "BH",
  "BO",
  "BS",
  "CH",
  "CI",
  "CL",
  "CO",
  "CR",
  "CY",
  "CZ",
  "DK",
  "DO",
  "EC",
  "EE",
  "EG",
  "ES",
  "ET",
  "FI",
  "GH",
  "GM",
  "GR",
  "GT",
  "GY",
  "HK",
  "HR",
  "HU",
  "ID",
  "IE",
  "IL",
  "IN",
  "IS",
  "IT",
  "JM",
  "JO",
  "JP",
  "KE",
  "KH",
  "KR",
  "KW",
  "LC",
  "LI",
  "LK",
  "LT",
  "LU",
  "LV",
  "MA",
  "MD",
  "MG",
  "MK",
  "MN",
  "MO",
  "MT",
  "MU",
  "MX",
  "MY",
  "NA",
  "NG",
  "NL",
  "NO",
  "NZ",
  "OM",
  "PA",
  "PE",
  "PH",
  "PL",
  "PT",
  "PY",
  "QA",
  "RO",
  "RS",
  "RW",
  "SA",
  "SE",
  "SG",
  "SK",
  "SN",
  "SV",
  "TH",
  "TN",
  "TR",
  "TT",
  "TW",
  "TZ",
  "UY",
  "UZ",
  "VN",
  "ZA",
];
