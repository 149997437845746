import { isWorkflowActiveOrInProgress } from "@circle-workflows/components/EditWorkflowInfoBanner/isWorkflowActiveOrInProgress";
import { useAreAllRunsCompleted } from "../ViewWorkflowModal/Header/HeaderActions/useAreAllRunsCompleted";
import { useWorkflow } from "../WorkflowForm/useWorkflow";

export const useIsWorkflowActiveOrInProgress = (): {
  result: boolean;
  isLoading?: boolean;
} => {
  const { id: workflowId, isDynamic, status } = useWorkflow();
  const { areAllRunsCompleted, isLoading } = useAreAllRunsCompleted({
    workflowId: isDynamic ? undefined : workflowId,
    disableRefetching: true,
  });

  if (isDynamic) {
    return { result: isWorkflowActiveOrInProgress(status) };
  }

  // For one-time & scheduled workflows we cannot rely on status of the workflow
  // Thus we need to check if all runs are completed
  return {
    result: !areAllRunsCompleted,
    isLoading,
  };
};
