import { t } from "i18n-js";
import { useQuery } from "react-query";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { useToast } from "@circle-react-uikit/ToastV2";

const i18nRoot = "settings.workflows.form";

export const useFetchSection = ({ sectionId }) => {
  const { error } = useToast();

  return useQuery(
    ["workflows", "list", "sections", sectionId],
    () =>
      reactQueryGet(
        internalApi.settings.courses.sections.show({ id: sectionId }),
      ),
    {
      onError: () => error(t([i18nRoot, "errors", "sections", "fetch_failed"])),
      enabled: !!sectionId,
    },
  );
};
