import { useChatNotification } from "@circle-react/hooks/chatsV2";
import { useNotificationsApi } from "@circle-react/hooks/notifications/useNotificationsApi";
import type { CommunitySwitcher } from "@circle-react/types/CommunitySwitcher";

export const useCommunityNotificationsCount = (
  community: CommunitySwitcher,
) => {
  const { newNotificationCount } = useNotificationsApi();
  const { unreadChatCount } = useChatNotification({
    community: community,
  });

  return {
    notificationsCount: Number(newNotificationCount + unreadChatCount),
  };
};
