import PropTypes from "prop-types";
import InnerHTML from "dangerously-set-html-content";
import { t } from "i18n-js";
import { useRouterQueryParams } from "@/react/hooks/useRouterQueryParams";
import { Typography } from "@circle-react/components/shared/uikit/Typography";
import { useSpaceResource } from "@circle-react/contexts/useSpaceResource";
import { Icon } from "@circle-react-shared/Icon";
import { LinkButton } from "@circle-react-uikit/LinkButton";

export const LockedSpaceV2 = ({ space }) => {
  const { data: spaceDetails = {}, isLoading } = useSpaceResource({
    spaceId: space.id,
  });

  if (isLoading) {
    return null;
  }

  const {
    locked_page_heading,
    locked_page_description = {},
    locked_button_url,
    locked_button_label,
  } = spaceDetails;

  return (
    <LockedSpaceV2View
      locked_page_heading={locked_page_heading}
      locked_page_description={locked_page_description}
      locked_button_url={locked_button_url}
      locked_button_label={locked_button_label}
    />
  );
};

LockedSpaceV2.propTypes = {
  space: PropTypes.object.isRequired,
};

export const LockedSpaceV2View = ({
  locked_page_heading = "",
  locked_page_description = {},
  locked_button_url = "",
  locked_button_label = "",
}) => {
  const { invited } = useRouterQueryParams();
  const isInvited = Boolean(invited);
  const hasLinkButton = Boolean(locked_button_label && locked_button_url);
  const { rendered_html_with_layout = "" } = locked_page_description;
  const hasDescription = Boolean(rendered_html_with_layout);

  return (
    <div
      className="flex h-full w-full justify-center p-0 md:h-auto md:p-6"
      data-testid="locked-space"
    >
      <div className="border-primary bg-primary h-full w-full max-w-5xl rounded-none border-0 px-10 py-20 text-center md:h-auto md:rounded-lg md:border">
        <Icon type="lock" className="mb-5 !w-14" />
        {isInvited && (
          <>
            <Typography.TitleSm weight="semibold">
              {t("please_check_your_email")}
            </Typography.TitleSm>
            <Typography.BodyMd>
              {t("please_check_your_email_description")}
            </Typography.BodyMd>
          </>
        )}
        {!isInvited && (
          <>
            <div className="mb-3">
              <Typography.TitleSm weight="semibold">
                {locked_page_heading || t("this_space_is_private")}
              </Typography.TitleSm>
            </div>
            <div className="mb-4">
              {hasDescription && (
                <div className="text-dark flex justify-center">
                  <InnerHTML html={rendered_html_with_layout} />
                </div>
              )}
              {!hasDescription && (
                <Typography.BodyMd>
                  {t("this_space_is_private_description")}
                </Typography.BodyMd>
              )}
            </div>
            {hasLinkButton && (
              <LinkButton variant="brand" href={locked_button_url}>
                {locked_button_label}
              </LinkButton>
            )}
          </>
        )}
      </div>
    </div>
  );
};

LockedSpaceV2View.propTypes = {
  locked_page_heading: PropTypes.string,
  locked_page_description: PropTypes.shape({
    plain_text: PropTypes.string,
    trix_html: PropTypes.string,
    rendered_html_with_layout: PropTypes.string,
  }),
  locked_button_url: PropTypes.string,
  locked_button_label: PropTypes.string,
  className: PropTypes.string,
};
