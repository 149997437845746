import { notifyBugsnag } from "@/react/helpers/bugsnagHelpers";
import { usePaywallCheckoutContext } from "@circle-react/contexts/Paywalls/paywallCheckoutContext";

const defaultHandler = (event: any) => {
  const defaultMessage = "Failed to Render PE";
  console.error(defaultMessage, event);
  notifyBugsnag(new Error(event?.error?.message ?? defaultMessage));
};

export const useHandleFailedPaymentElementRender = () => {
  const { paymentMethodsSupported, setPaymentMethodsSupported } =
    usePaywallCheckoutContext();

  return (failedEvent: any) => {
    if (failedEvent.error.param === "deferred_intent.payment_method_types") {
      setPaymentMethodsSupported(
        paymentMethodsSupported.filter(
          paymentMethod => paymentMethod !== "link",
        ),
      );
    } else {
      defaultHandler(failedEvent);
    }
  };
};
