import { t } from "i18n-js";
import { BaseView } from "@circle-react/components/SettingsApp/Workflows/components/Actions/BaseView";
import { Typography } from "@circle-react-uikit/Typography";

const i18nRoot = "settings.workflows.view";
export const ViewPoints = ({
  action,
}: {
  action: { api_params?: { points?: number | string; reason?: string } };
}) => {
  const { api_params: apiParams } = action || {};
  const { points, reason } = apiParams || {};

  return (
    <BaseView>
      <BaseView.Section>
        <BaseView.Title>{t([i18nRoot, "points"])}</BaseView.Title>
        <div>
          <Typography.LabelSm>{points}</Typography.LabelSm>
        </div>
      </BaseView.Section>
      {reason && (
        <BaseView.Section>
          <BaseView.Title>{t([i18nRoot, "reason"])}</BaseView.Title>
          <div>
            <Typography.LabelSm>{reason}</Typography.LabelSm>
          </div>
        </BaseView.Section>
      )}
    </BaseView>
  );
};
