import { t } from "i18n-js";
import { NameFilter } from "@circle-react/components/Modals/SearchResultsModal/FilterBar/NameFilter";
import { SpacesFilter } from "@circle-react/components/Modals/SearchResultsModal/FilterBar/SpacesFilter";
import { TagFilter } from "@circle-react/components/Modals/SearchResultsModal/FilterBar/TagFilter";
import { TopicsFilter } from "@circle-react/components/Modals/SearchResultsModal/FilterBar/TopicsFilter";
import type { SearchType } from "@circle-react/components/search/types";
import { EventStatusFilter } from "./EventStatusFilter";

const SPACE_FILTER_NAME = t("search_v2.filters.space");

const filters: any = {
  posts: [
    {
      name: SPACE_FILTER_NAME,
      component: SpacesFilter,
      spaceType: "posts",
    },
    {
      name: "author_name",
      component: NameFilter,
    },
    {
      name: "topics",
      component: TopicsFilter,
    },
  ],
  comments: [
    {
      name: SPACE_FILTER_NAME,
      component: SpacesFilter,
      spaceType: "posts",
    },
    {
      name: "author_name",
      component: NameFilter,
    },
  ],
  lessons: [
    {
      name: SPACE_FILTER_NAME,
      component: SpacesFilter,
      spaceType: "courses",
    },
  ],
  events: [
    {
      name: SPACE_FILTER_NAME,
      component: SpacesFilter,
      spaceType: "events",
    },
    {
      name: "event_status",
      component: EventStatusFilter,
    },
  ],
  members: [
    {
      name: "tags",
      component: TagFilter,
    },
  ],
};

interface FilterBarProps {
  type: SearchType;
}

export const FilterBar = ({ type }: FilterBarProps) => {
  const filterComponents = filters[type] || [];
  return filterComponents.map(
    ({ name, component: FilterComponent, ...rest }: any) => (
      <div className="py-1" key={name}>
        <FilterComponent name={name} {...rest} />
      </div>
    ),
  );
};
