import { t } from "i18n-js";
import { Icon } from "@circle-react-shared/Icon";

export const DropdownText = ({
  localeString,
  icon,
  useWithFillCurrentColor,
}: any) => (
  <div className="flex flex-row gap-x-2.5 self-center">
    <Icon
      className=""
      type={icon}
      size={16}
      useWithFillCurrentColor={useWithFillCurrentColor}
    />
    {t(localeString)}
  </div>
);
