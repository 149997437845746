import { useQuery } from "react-query";
import { internalApi } from "@circle-react/helpers/urlHelpers";

// TODO: Implement pagination and sorting, requesting all for now
const per_page = 2000;

export const useParticipants = roomId => {
  const params = {
    per_page,
    sort: "oldest",
  };
  const { data, isLoading } = useQuery(
    internalApi.liveStreams.participants({
      roomId: roomId,
      params,
    }),
  );

  const records = data?.records;
  const uniqueParticipants = records && [
    ...new Map(records.map(item => [item["email"], item])).values(),
  ];

  return {
    records: uniqueParticipants,
    isLoading,
  };
};
