import { Suspense } from "react";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { PageLoader } from "@circle-react/pages/PageLoader";

const ModerationEditSettingsComponent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "ModerationEditSettingsPage" */ "@circle-react/components/SettingsApp/Moderation/ModerationSettings"
    ),
);

export const ModerationEditSettingsPage = () => (
  <Suspense fallback={<PageLoader />}>
    <ModerationEditSettingsComponent />
  </Suspense>
);
