import { PostItem } from "../PostItem";
import { SkeletonLoader } from "../SkeletonLoader";
import { MAX_TUTORIAL_POSTS } from "../constants";
import { useListTutorialPosts } from "../queries";

export const TutorialPostsContainer = () => {
  const { isLoading, data: { records: tutorialPosts } = { records: [] } } =
    useListTutorialPosts();

  if (isLoading) {
    return (
      <>
        <SkeletonLoader rows={1} wrapperClassName="mb-1.5" />
        <SkeletonLoader rows={3} wrapperClassName="space-y-1" />
      </>
    );
  }

  return (
    <div className="mt-1.5 flex flex-col justify-center pb-4">
      {tutorialPosts.slice(0, MAX_TUTORIAL_POSTS).map(post => (
        <PostItem post={post} key={post.id} />
      ))}
    </div>
  );
};
