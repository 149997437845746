import { t } from "i18n-js";
import { FormTextSelect } from "@circle-react-shared/uikit/Form/FormTextSelect";
import type { PricingTabSelectOption } from "../../../types";
import { usePricingTabContext } from "../../hooks/usePricingTabContext";

export interface PriceTypeSelectorFieldProps {
  name: string;
  labelKey?: string;
  options?: Array<PricingTabSelectOption>;
  required?: boolean;
  tooltipText?: string;
}

export const PriceTypeSelectorField = ({
  name,
  labelKey,
  required = false,
  options,
  tooltipText,
}: PriceTypeSelectorFieldProps) => {
  const { priceTypeOptions } = usePricingTabContext();

  return (
    <div className="grow leading-5">
      <FormTextSelect
        name={name}
        rules={{ required: required }}
        label={
          <>
            {t(
              `settings.paywalls.modal.tabs.pricing.add_price_modal.field_labels.${
                labelKey || name
              }`,
            )}
            {required && <span className="text-red-500">*</span>}
          </>
        }
        options={options ?? priceTypeOptions()}
        tooltipText={tooltipText}
      />
    </div>
  );
};
