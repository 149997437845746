import {
  selectLocalPeerID,
  selectPeerMetadata,
  useHMSActions,
  useHMSStore,
} from "@100mslive/react-sdk";

export const useHMSLocalMetadata = () => {
  const localPeerId = useHMSStore(selectLocalPeerID);
  const metadata = useHMSStore(selectPeerMetadata(localPeerId));
  const hmsActions = useHMSActions();
  const setMetadata = newMetadata => {
    hmsActions.changeMetadata({ ...metadata, ...newMetadata });
  };

  return {
    metadata,
    setMetadata,
  };
};
