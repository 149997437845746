import { noop } from "lodash";
import { Button } from "@circle-react-shared/uikit/Button";
import { SanitizeHTMLString } from "@circle-react-shared/uikit/SanitizeContent";
import { Typography } from "@circle-react-uikit/Typography";

interface TemplateEmptyStateProp {
  onClickButton?: () => void;
  title?: string;
  subtitle?: string;
  buttonText?: string;
}

export const TemplateEmptyState = ({
  onClickButton = noop,
  title,
  subtitle,
  buttonText,
}: TemplateEmptyStateProp) => (
  <div className="flex h-[70%] flex-col justify-center gap-y-2 text-center">
    {title && <Typography.LabelMd weight="bold">{title}</Typography.LabelMd>}

    {subtitle && (
      <Typography.BodyMd color="text-default">
        <SanitizeHTMLString content={subtitle} />
      </Typography.BodyMd>
    )}
    {buttonText && (
      <div className="pt-2">
        <Button variant="circle" type="button" onClick={onClickButton}>
          {buttonText}
        </Button>
      </div>
    )}
  </div>
);
