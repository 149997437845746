import { t } from "i18n-js";
import { noop } from "lodash";
import { NameFilter } from "./NameFilter";

const localeRoot =
  "settings.paywalls_admin_dashboard.filters.community_member_name";

export const filterProperties = {
  name: "community_member_name",
  filterKeys: ["name"],
  component: (prop: any) => <NameFilter {...prop} onApply={noop} />,
  label: t([localeRoot, "title"]),
};
