import type { ComponentPropsWithoutRef } from "react";
import classnames from "classnames";

export interface LinkButtonProps extends ComponentPropsWithoutRef<"a"> {
  href: string;
  onClick?: never;
}

export const CustomLinkButton = ({
  href,
  children,
  className,
  rel = "noopener noreferrer",
  ...rest
}: LinkButtonProps) => (
  <a
    {...rest}
    href={href}
    rel={rel}
    className={classnames(
      "focus-visible:!outline-secondary bg-brand text-brand-button inline-block rounded-md px-[18px] py-[6px] text-sm font-bold leading-5 transition-colors duration-150 hover:opacity-90 focus-visible:!outline focus-visible:!outline-2 focus-visible:!outline-offset-2",
      className,
    )}
  >
    {children}
  </a>
);
