/* eslint-disable tailwindcss/no-custom-classname -- will refactor later */
import PropTypes from "prop-types";
import { DataTable } from "@circle-react-uikit/DataTable";
import { Loader } from "@circle-react-uikit/Loader";
import { columnsListComponent } from "./constants";
import { useList } from "./hooks/useList";
import "./style.scss";

export const List = ({
  paywalls,
  refetchPaywalls,
  isPaywallsLoading,
  paywallGroupsEnabled,
}) => {
  const { dataSource } = useList({
    paywalls,
    refetchPaywalls,
    paywallGroupsEnabled,
  });

  return isPaywallsLoading ? (
    <Loader />
  ) : (
    <div className="paywall-coupons-list" data-testid="paywall-list">
      {dataSource.length > 0 && (
        <DataTable
          columns={columnsListComponent}
          className="paywall-coupons-list__table"
          dataSource={dataSource || []}
          hasStickyHeader
          headerClassName="top-[116px]"
        />
      )}
    </div>
  );
};

List.propTypes = {
  paywalls: PropTypes.array,
  refetchPaywalls: PropTypes.func.isRequired,
  isPaywallsLoading: PropTypes.bool,
  paywallGroupsEnabled: PropTypes.bool.isRequired,
};
