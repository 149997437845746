import classNames from "classnames";
import { t } from "i18n-js";
import { Typography } from "@circle-react-uikit/Typography";

export const Block = ({
  children,
  isHighlighted,
  isError,
  isEmpty,
  hasFavorites,
}: any) => (
  <div className={classNames("flex flex-col", { "opacity-40": isEmpty })}>
    {hasFavorites && (
      <div
        className={classNames("bg-brand flex flex-col items-center py-1", {
          "rounded-t-lg border-x border-t": isHighlighted,
          "hidden md:invisible md:flex": !isHighlighted,
          "border-feedback-error": isError,
          "border-primary": !isError,
        })}
      >
        <Typography.LabelXs
          weight="bold"
          color="text-brand-button"
          textTransform="uppercase"
        >
          {t("paywalls.lock_screen.preview.featured")}
        </Typography.LabelXs>
      </div>
    )}
    <div
      className={classNames(
        "bg-primary flex h-full flex-col gap-y-4 border p-5",
        {
          "rounded-b-lg": isHighlighted,
          "rounded-lg": !isHighlighted,
          "border-feedback-error": isError,
          "border-primary": !isError,
        },
      )}
    >
      {children}
    </div>
  </div>
);
