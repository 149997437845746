import { useState } from "react";
import classNames from "classnames";
import { Switch } from "@circle-react-uikit/Switch";

export const SpaceNameCell = (name: any) => {
  const [isActive, setActive] = useState(false);

  return (
    <div className="m-0 flex w-full flex-row align-middle">
      <span className="mt-0.5">
        <Switch
          as="span"
          checked={isActive}
          onChange={() => setActive(!isActive)}
        />
      </span>
      <span
        className={classNames("ml-3", {
          "font-normal": !isActive,
          "font-semibold": isActive,
        })}
      >
        {name?.name}
      </span>
    </div>
  );
};
