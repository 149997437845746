import { reactQueryPost } from "@/react/helpers/backendRequestHelpers";
import { reactQueryGet } from "@/react/helpers/backendRequestHelpers";
import { paymentsPath } from "@/react/helpers/urlHelpers/settingsPath";

const create = (params: CreateParams) =>
  reactQueryPost(paymentsPath.create(), params);
const refresh = () => reactQueryGet(paymentsPath.refresh());

// Request
export interface CreateParams {
  country?: "br" | "us";
}

// Response
export interface ResponseParams {
  url: string;
}

export const communityPaymentProcessorApi = {
  create,
  refresh,
};
