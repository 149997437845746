import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { useBackendProps } from "@circle-react/providers";
import { useRouteHelpers } from "@circle-react/routes/Main/useRouteHelpers";
import type { CommunitySwitcherContentProps } from "./CommunitySwitcherContent";
import { CommunitySwitcherContent } from "./CommunitySwitcherContent";
import { CommunitySwitcherContentV3 } from "./CommunitySwitcherContentV3";

export const CommunitySwitcher = (props: CommunitySwitcherContentProps) => {
  const { pathname } = useLocation();
  const { isV3Enabled } = useIsV3();
  const { isSettingsPage } = useRouteHelpers();
  const is2faPage = pathname.startsWith("/two_fa");
  const { display_community_switcher: isDisplayCommunitySwitcherEnabled } =
    useBackendProps();
  const isVisualRefreshPage = pathname.startsWith("/features/visual-refresh");

  useEffect(() => {
    if (isSettingsPage || isVisualRefreshPage) {
      document.body.classList.remove("has-community-switcher");
    } else if (isDisplayCommunitySwitcherEnabled) {
      document.body.classList.add("has-community-switcher");
    }
  }, [
    pathname,
    isSettingsPage,
    isDisplayCommunitySwitcherEnabled,
    isVisualRefreshPage,
  ]);

  if (isSettingsPage || is2faPage || isVisualRefreshPage) {
    return null;
  }

  if (isV3Enabled) {
    return <CommunitySwitcherContentV3 {...props} />;
  }

  return <CommunitySwitcherContent {...props} />;
};
