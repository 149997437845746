import { t } from "i18n-js";
import { TRIGGER_RESOURCE_TYPE } from "../../../constants";
import { EntityResource } from "../EntityResource";
import type { EventOption } from "../types";
import { AnyLessonSelector } from "./shared/AnyLessonSelector";

export const completedLesson: Readonly<EventOption> = {
  label: t(
    "settings.workflows.form.events.community_member_completed_lesson.title",
  ),
  value: "community_member_completed_lesson",
  group: t("settings.workflows.form.event_groups.courses"),
  description: t(
    "settings.workflows.form.events.community_member_completed_lesson.description",
  ),
  resource: TRIGGER_RESOURCE_TYPE.LESSON,
  formComponent: AnyLessonSelector,
  viewComponent: EntityResource,
  icon: "course",
};
