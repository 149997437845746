import {
  reactQueryGet,
  reactQueryPatch,
} from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import type { UseModerationSettingsInfiniteQueryProps } from "./interfaces";

export interface ApiResource {
  id?: string;
  params?: UseModerationSettingsInfiniteQueryProps;
}

export const useModerationApi = () => {
  const list = ({ params = {} }: ApiResource) =>
    reactQueryGet(internalApi.flaggedContent.index({ params }));

  const get = (id: string) =>
    reactQueryGet(internalApi.flaggedContent.show({ id }));

  const approve = (id: string) =>
    reactQueryPatch(internalApi.flaggedContent.approve({ id }));

  const reject = (id: string) =>
    reactQueryPatch(internalApi.flaggedContent.reject({ id }));

  return {
    list,
    get,
    approve,
    reject,
  };
};
