import PropTypes from "prop-types";
import { t } from "i18n-js";
import { Icon } from "@circle-react/components/shared/Icon";

export const OpenInNewWindowLink = ({ postUrl }) => (
  <a
    href={postUrl}
    target="_blank"
    rel="noopener noreferrer"
    className="border-circle my-2 flex w-full justify-center gap-2 rounded-lg border p-1"
  >
    <Icon type="share-support-widget" useWithFillCurrentColor size={16} />
    {t("support_widget.open_post")}
  </a>
);

OpenInNewWindowLink.propTypes = {
  postUrl: PropTypes.string,
};
