import { StandardLayoutHeaderWithPortal } from "@circle-react/components/Layout/StandardLayoutV2/StandardLayoutHeader/StandardLayoutHeaderWithPortal";
import { usePunditUserContext } from "@circle-react/contexts";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import type { ViewSwitcherLayoutTypes } from "@circle-react-shared/ViewSwitcher";
import { MembersHeaderTitle } from "./HeaderTitle";
import { MembersSearch } from "./MemberSearch";
import { MembersFilters } from "./MembersFilters";
import { RightOptions } from "./RightOptions";

export interface HeaderProps {
  memberCount: number;
  headerTitle?: string;
  activeView: ViewSwitcherLayoutTypes;
  setShouldRenderMobileFiltersTransition: (value: boolean) => void;
  setActiveView: (value: ViewSwitcherLayoutTypes) => void;
}

export const Header = ({
  memberCount,
  headerTitle,
  activeView,
  setShouldRenderMobileFiltersTransition,
  setActiveView,
}: HeaderProps) => {
  const { currentCommunityMember } = usePunditUserContext();
  const { isV3Enabled } = useIsV3();

  return (
    <>
      <StandardLayoutHeaderWithPortal
        leftContent={
          <MembersHeaderTitle
            currentCommunityMember={currentCommunityMember}
            memberCount={memberCount}
            hasDropdown={!isV3Enabled}
            headerTitle={headerTitle}
          />
        }
        rightContent={
          <RightOptions activeView={activeView} setActiveView={setActiveView} />
        }
      />
      <MembersSearch
        setShouldRenderMobileFiltersTransition={
          setShouldRenderMobileFiltersTransition
        }
      />
      <MembersFilters />
    </>
  );
};
