import { useCallback, useEffect, useState } from "react";
import { t } from "i18n-js";
import { useFormContext } from "react-hook-form";
import { usePaywallCheckoutContext } from "@circle-react/contexts/Paywalls/paywallCheckoutContext";
import { Select } from "@circle-react-uikit/Select";
import { CheckoutFormField } from "../CheckoutFormField";
import { CheckoutFormFieldWrapper } from "../CheckoutFormFieldWrapper";
import { taxIdTypes } from "./taxIdTypes";

const mapTaxIdTypeToOptions = (entry: any) => ({
  value: entry.label,
  text: entry.label,
});

const taxIdTypesByLabel: any = taxIdTypes.reduce(
  (dict: any, entry: any) => ({
    ...dict,
    [entry.label]: entry,
  }),
  {},
);

export const CheckoutFormTaxIdForm = () => {
  const { setValue, watch } = useFormContext();
  const {
    community_member_billing_info_attributes: {
      address_country: memberAddressCountry,
    },
  } = watch();

  const {
    isMemberAddressRequired,
    isTaxIdMandatory,
    isBrazilianAccountAndProcessorStripeBr,
  } = usePaywallCheckoutContext();

  const [taxIdTypeValue, setTaxIdTypeValue] = useState(
    isTaxIdMandatory && isBrazilianAccountAndProcessorStripeBr
      ? t("tax_ids.brazil_cpf")
      : "",
  );
  const taxIdType = taxIdTypesByLabel[taxIdTypeValue];
  const taxIdFormOptions = { required: isTaxIdMandatory };

  const handleChange = useCallback(
    ({ value }) => {
      setTaxIdTypeValue(value);
    },
    [setTaxIdTypeValue],
  );

  useEffect(() => {
    if (taxIdType) {
      setValue(
        "community_member_billing_info_attributes[tax_id_type]",
        taxIdType.enum,
      );
      if (!isMemberAddressRequired) {
        setValue(
          "community_member_billing_info_attributes[address_country]",
          taxIdType.countryCode,
        );
      }
    }
  }, [setValue, isMemberAddressRequired, taxIdType]);
  useEffect(() => {
    if (
      isMemberAddressRequired &&
      taxIdType &&
      taxIdType.countryCode !== memberAddressCountry
    ) {
      setTaxIdTypeValue("");
    }
  }, [setValue, isMemberAddressRequired, memberAddressCountry, taxIdType]);

  const applicableTaxIdTypes = taxIdTypes
    .filter(
      taxIdType =>
        !isTaxIdMandatory ||
        !isBrazilianAccountAndProcessorStripeBr ||
        (isTaxIdMandatory &&
          isBrazilianAccountAndProcessorStripeBr &&
          taxIdType.countryCode === "BR"),
    )
    .filter(
      taxIdType =>
        !isMemberAddressRequired ||
        !memberAddressCountry ||
        taxIdType.countryCode === memberAddressCountry,
    );
  const [taxIdTypeOptions, setTaxIdTypeOptions] = useState(
    applicableTaxIdTypes.map(mapTaxIdTypeToOptions),
  );

  const handleSearch = useCallback(
    text => {
      const query = text.toLowerCase();
      const filteredOptions = applicableTaxIdTypes
        .filter(
          option =>
            option.label.toLowerCase().includes(query) ||
            option.country.toLowerCase().includes(query),
        )
        .map(mapTaxIdTypeToOptions);
      setTaxIdTypeOptions(filteredOptions);
    },
    [applicableTaxIdTypes, setTaxIdTypeOptions],
  );

  return (
    <CheckoutFormFieldWrapper name="tax_id" optional={!isTaxIdMandatory}>
      <Select
        variant="single"
        name="taxIdTypeLabel"
        data={taxIdTypeOptions}
        value={taxIdTypeValue}
        onChange={handleChange}
        onSearch={handleSearch}
        hideSelectedItems
      />
      {taxIdTypeValue && (
        <CheckoutFormField
          className="mt-2 grow"
          type="text"
          name="community_member_billing_info_attributes[tax_id_value]"
          placeholder={taxIdType.example}
          options={{ ...(taxIdType?.formOptions ?? {}), ...taxIdFormOptions }}
        />
      )}
    </CheckoutFormFieldWrapper>
  );
};
