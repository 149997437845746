import isArray from "lodash/isArray";

export const getActionsByActionType = actions => {
  if (!isArray(actions)) {
    return [];
  }

  return actions
    .filter(({ action_type }) => action_type)
    .map(({ action_type }) => action_type);
};
