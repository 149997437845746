import { useQuery } from "react-query";
import { reactQueryGet } from "@/react/helpers/backendRequestHelpers";
import { PAYWALL_NON_DRAFT_STATUSES } from "@circle-react/helpers/paywallHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";

export const usePaywallFilterList = (name: string, params = {}) => {
  const fetchPaywalls = () =>
    reactQueryGet(
      internalApi.paywalls.index({
        params: {
          ...params,
          status: PAYWALL_NON_DRAFT_STATUSES,
        },
      }),
    );

  const { data: paywallsData = [], isLoading: isPaywallsLoading } = useQuery(
    name,
    fetchPaywalls,
  );

  const paywalls = paywallsData.map((record: any) => ({
    label: record.display_name,
    value: record.id.toString(),
  }));

  return {
    paywalls,
    isPaywallsLoading,
  };
};
