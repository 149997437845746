import { PropTypes } from "prop-types";
import classNames from "classnames";
import { useDropzone } from "react-dropzone";
import { Typography } from "@circle-react-uikit/Typography";

const uploadBtnClass =
  "h-[34px] rounded-md font-bold leading-5 text-darkest border border-secondary bg-primary hover:bg-tertiary !font-medium py-[6px] px-[18px] text-sm cursor-pointer";

export const DropZone = ({
  isLoading,
  handleUpload,
  name,
  description,
  disabled,
}) => {
  const handleFileRejection = err => {
    console.error(err);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDropAccepted: handleUpload,
    onDropRejected: handleFileRejection,
    noClick: true,
    disabled: isLoading,
    accept: {
      "image/*": [".jpeg", ".gif", ".png", ".jpg"],
      "video/*": [".mp4", ".mov", ".m4v"],
    },
    maxFiles: 1,
    multiple: false,
  });

  return (
    <div
      className={classNames(
        "border-primary flex h-64 w-full items-center justify-center rounded-lg border border-dashed",
        {
          "text-secondary bg-secondary": !isDragActive,
          "text-messaging-info-blue border-blue-300 bg-blue-50": isDragActive,
        },
      )}
      {...getRootProps()}
      onPaste={e => {
        if (e.clipboardData?.files?.length > 0) {
          e.preventDefault();
          const files = Array.from(e.clipboardData.files);
          handleUpload(files);
        }
      }}
    >
      <div className="flex max-w-xs flex-col items-center">
        <div>
          <Typography.LabelLg weight="medium">
            {I18n.t("home_page.edit_form.welcome_banner.drop_image")}
          </Typography.LabelLg>
        </div>
        <input {...getInputProps()} />
        <div className="mt-3 px-4 text-center leading-none">
          <Typography.LabelSm weight="regular" color="text-light">
            {description}
          </Typography.LabelSm>
        </div>
        <label htmlFor={name} className="mt-6">
          <span
            className={classNames(uploadBtnClass, {
              "cursor-not-allowed": disabled,
            })}
          >
            {I18n.t("home_page.edit_form.welcome_banner.upload")}
          </span>
          <input
            accept="image/*, video/*"
            type="file"
            id={name}
            name={name}
            className="hidden"
            disabled={disabled}
            onChange={e => !disabled && handleUpload(e.target.files)}
          />
        </label>
      </div>
    </div>
  );
};

DropZone.propTypes = {
  isLoading: PropTypes.bool,
  progress: PropTypes.number,
  handleUpload: PropTypes.func,
  name: PropTypes.string,
  description: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
};
