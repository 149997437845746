import { MainAppProviders } from "@circle-react/providers";
import { CommunitySwitcher } from "./CommunitySwitcher";

export interface CommunitySwitcherWithProvidersProps {
  isLoadingAppData?: boolean;
}

export const CommunitySwitcherWithProviders = ({
  isLoadingAppData = false,
}: CommunitySwitcherWithProvidersProps) => {
  const config = {
    community_theme_settings: {
      default_appearance: "light",
    },
  };

  return (
    <MainAppProviders backendProps={config}>
      <CommunitySwitcher isLoadingAppData={isLoadingAppData} />
    </MainAppProviders>
  );
};
