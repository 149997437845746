import { t } from "i18n-js";
import { useFormContext } from "react-hook-form";
import { useSpaceAccessManagementContext } from "@circle-react/components/Spaces/SpaceAccessManagement/SpaceAccessManagementContext";
import { i18nRoot } from "../../constants";
import { defaultAction } from "../../constants";
import { ListHeaderColumn } from "./ListHeaderColumn";

interface ListHeaderColumnNoAccessProp {
  title?: string;
  shouldShowAddAllButton?: boolean;
  onAddAll?: (action: () => void) => void;
}

export const ListHeaderColumnNoAccess = ({
  title = t([i18nRoot, "no_access"]),
  shouldShowAddAllButton = true,
  onAddAll,
}: ListHeaderColumnNoAccessProp) => {
  const { options, parentFieldName, childFieldName } =
    useSpaceAccessManagementContext();
  const { setValue } = useFormContext();

  const spaceGroupIds: Array<number> = [];
  const spaceIds: Array<number> = [];

  options.forEach(spaceGroup => {
    spaceGroupIds.push(spaceGroup.id);
    spaceGroup.spaces.forEach(space => {
      spaceIds.push(space.id);
    });
  });

  const addAllSpacesAndSpaceGroups = () => {
    setValue(parentFieldName, spaceGroupIds);
    setValue(childFieldName, spaceIds);
  };
  const onClick = () => {
    const method = onAddAll || defaultAction;
    method(addAllSpacesAndSpaceGroups);
  };

  return (
    <ListHeaderColumn
      title={title}
      actionText={t([i18nRoot, "add_all"])}
      onClick={onClick}
      shouldShowButton={shouldShowAddAllButton}
    />
  );
};
