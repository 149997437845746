import { useMemo } from "react";
import Trix from "trix";
import { usePunditUserContext } from "@circle-react/contexts";
import {
  buildTiptapDocument,
  fileBlock,
  paragraphBlock,
  textParagraphBlock,
} from "@circle-react-uikit/TipTap/utilities/contentBuilder";

export const usePostBodyWithRecording = ({ room }: any) => {
  const { currentCommunitySettings } = usePunditUserContext();
  const tiptapPostEnabled = currentCommunitySettings?.tiptap_post_enabled;

  const trixBodyWithRecording = useMemo(() => {
    if (tiptapPostEnabled || !room.recording_url) {
      return "";
    }

    const blob = room.recording_blob;
    const attachment = new Trix.Attachment({
      ...blob,
      url: room.recording_url,
      sgid: blob.attachable_sgid,
      filesize: blob.byte_size,
      contentType: "video/mp4",
      content: room.video_container_tag,
    });
    const video = Trix.Text.textForAttachmentWithAttributes(attachment);
    const documentArray = [new Trix.Block(video)];

    if (room.description) {
      const desc = new Trix.StringPiece(room.description);
      const description = new Trix.Text([desc]);
      documentArray.unshift(new Trix.Block(description));
    }

    const doc = new Trix.Document(documentArray);
    const elements = new Trix.DocumentView(doc).render();

    let viewHTML = "";

    elements.forEach((element: any) => {
      const firstChild = element.pop();
      viewHTML += firstChild.outerHTML;
    });

    return viewHTML;
  }, [tiptapPostEnabled, room]);

  const tiptapBodyWithRecording =
    tiptapPostEnabled && room.recording_attachment
      ? buildTiptapDocument({
          bodyContent: [
            room.description
              ? textParagraphBlock({ text: room.description })
              : paragraphBlock(),
            fileBlock({ file: room.recording_attachment }),
          ],
          inlineAttachments: [room.recording_attachment],
        })
      : null;

  return {
    trixBodyWithRecording,
    tiptapBodyWithRecording,
  };
};
