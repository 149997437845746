import { t } from "i18n-js";
import { internalApi } from "@/react/helpers/urlHelpers";
import { fetchRequest } from "@circle-react/helpers/backendRequestHelpers";
import { SEND_MESSAGE_FORM_DEFAULT_VALUES } from "./constants";
import {
  buildChatPromptMessage,
  buildFeedbackPromptMessage,
  buildFeedbackSelectorMessage,
  buildQuestionPrompt,
  buildQuestionSelectorMessage,
} from "./messageFactory";
import { getLocalStorage, setLocalStorage } from "./utils";
import { autolink } from "./utils";

export const processHandler = ({
  history,
  addMessage,
  setIsUserInputEnabled,
  setConversationType,
  setProductArea,
  conversationType,
  setIsBotProcessing,
  createSupportWidgetFeedback,
  productArea,
  createChatMessage,
  sessionId,
  setSessionId,
  setIsConversationEnded,
  setCanRestart,
  displayLiveChat,
  createLiveChatMessage,
  setIsLiveChatEnded,
  hasPostedLiveMessage,
  setIsLiveChatWithDeflectionEnabled,
}) => {
  const processSelectConversationType = value => {
    if (value === "bug") {
      addMessage("bot", {
        content: t("support_widget.chatbot.templates.reach_out_to_support"),
        buttons: [
          {
            label: t("support_widget.send_a_message"),
            event: "contact-support",
            value: null,
          },
        ],
      });
    } else if (value == "feedback") {
      addMessage("bot", buildFeedbackSelectorMessage());
    } else if (value == "question") {
      addMessage("bot", buildQuestionSelectorMessage());
    } else if (value == "live-chat") {
      fetchRequest(internalApi.supportWidget.getLiveChatStatus({ flow: "any" }))
        .then(response => response.json())
        .then(response => {
          if (response.is_available) {
            addMessage("bot", {
              content: t("support_widget.chatbot.templates.live_chat_start"),
              buttons: [],
            });
            setIsUserInputEnabled(true);
          } else {
            const openingTime = response?.opening_time;
            const closingTime = response?.closing_time;

            setIsUserInputEnabled(false);
            setIsBotProcessing(false);

            addMessage("bot", {
              content: t("support_widget.chatbot.templates.live_chat_closed", {
                openingTime,
                closingTime,
              }),
              buttons: [
                {
                  label: t("support_widget.send_a_message"),
                  event: "contact-support",
                  value: null,
                },
              ],
            });
          }
        })
        .catch(() => {});
    }
    setConversationType(value);
    setCanRestart(true);
  };

  const processPostMessage = value => {
    addMessage("user", {
      content: autolink(value),
      buttons: [],
    });
    if (conversationType === "feedback") {
      setIsBotProcessing(true);
      setIsUserInputEnabled(false);
      createSupportWidgetFeedback({
        text: value,
        area: productArea,
        type: "feedback",
        is_important: false,
      });
    } else if (conversationType === "question") {
      setIsBotProcessing(true);
      setIsUserInputEnabled(false);
      createChatMessage({
        message: value,
        sessionId: sessionId,
        productArea: productArea,
      });
    } else if (conversationType === "live-chat") {
      if (!hasPostedLiveMessage) {
        setIsBotProcessing(true);
        setCanRestart(false);
      }

      createLiveChatMessage({
        message: value,
        sessionId: sessionId,
      });
    }
  };

  return ({ event, value }) => {
    if (event === "select-conversation-type") {
      processSelectConversationType(value);
    } else if (event === "contact-support") {
      setLocalStorage({
        ...getLocalStorage(),
        formValues: {
          ...SEND_MESSAGE_FORM_DEFAULT_VALUES,
          type: conversationType,
          area: productArea,
          text: value,
        },
      });
      history.push("/form", { source: "chat", chatBotSessionId: sessionId });
    } else if (event === "start-chat") {
      addMessage("bot", buildChatPromptMessage({ displayLiveChat }));
      setSessionId(null);
      setProductArea(null);
      setConversationType(null);
      setIsUserInputEnabled(false);
      setIsBotProcessing(false);
      setCanRestart(false);
      setIsConversationEnded(false);
      setIsLiveChatEnded(false);
    } else if (event === "restart-chat") {
      addMessage("bot", buildChatPromptMessage({ displayLiveChat }));
      setSessionId(null);
      setProductArea(null);
      setConversationType(null);
      setIsUserInputEnabled(false);
      setIsBotProcessing(false);
      setIsLiveChatWithDeflectionEnabled(false);
    } else if (event == "select-product-type") {
      if (conversationType === "feedback") {
        addMessage("bot", buildFeedbackPromptMessage());
      } else if (conversationType === "question") {
        addMessage("bot", buildQuestionPrompt());
      }
      setProductArea(value);
      setIsUserInputEnabled(true);
    } else if (event == "post-message") {
      processPostMessage(value);
    } else if (event == "complete-chat") {
      addMessage("bot", {
        content: ["Enjoy the rest of your day!"],
        buttons: [],
      });
      setIsUserInputEnabled(false);
      setIsConversationEnded(true);
    }
  };
};
