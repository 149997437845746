import {
  selectHLSState,
  selectRecordingState,
  useHMSStore,
} from "@100mslive/react-sdk";

export const useHMSHlsState = () => {
  const hlsState = useHMSStore(selectHLSState);
  const recordingState = useHMSStore(selectRecordingState);

  return {
    hlsUrl: hlsState.variants[0]?.url,
    isHlsRunning: !!hlsState?.running,
    isRecording: !!recordingState?.hls?.running,
  };
};
