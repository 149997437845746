import { useState } from "react";
import { t } from "i18n-js";
import { memberSettingsPaths } from "@circle-react/helpers/urlHelpers";
import { ThreeDotsDropdownV3 } from "@circle-react-shared/ThreeDotsDropdownV3";
import { Dropdown } from "@circle-react-uikit/Dropdown";
import { Settings } from "./Settings";

export const ManageMemberDirectoryActionsV3 = () => {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const onEditMemberDirectoryClick = () => {
    setIsSettingsOpen(true);
  };

  const onSettingsCloseClick = () => {
    setIsSettingsOpen(false);
  };

  return (
    <>
      <ThreeDotsDropdownV3
        label={t("space_settings")}
        dataTestId="space-settings-v3"
      >
        <Dropdown.ItemLink to={memberSettingsPaths.index()}>
          {t("members_directory.header.manage_audience")}
        </Dropdown.ItemLink>
        <Dropdown.ItemLink forceClose onClick={onEditMemberDirectoryClick}>
          {t("members_directory.header.edit_member_directory")}
        </Dropdown.ItemLink>
      </ThreeDotsDropdownV3>
      <Settings open={isSettingsOpen} onClose={onSettingsCloseClick} />
    </>
  );
};
