import { t } from "i18n-js";
import { useGamificationSettings } from "@circle-react/components/SettingsApp/Gamification/LevelsForm/gamificationApi";
import { useSupportUnreleasedWorkflowsFeaturesEnabled } from "@circle-react/components/shared/Workflows/hooks/useSupportUnreleasedWorkflowsFeaturesEnabled";
import { usePunditUserContext } from "@circle-react/contexts";
import { useContactsInWorkflowsEnabled } from "@circle-react/hooks/useContactsInWorkflowsEnabled";
import { availableEvents } from "../Events";
import { memberTagged, memberUntagged } from "./MemberTag";

const contactTagEventTranslations = {
  [memberTagged.value]: {
    labelKey: "settings.workflows.form.events.contact_tagged.title_new",
    descriptionKey:
      "settings.workflows.form.events.contact_tagged.description_new",
  },
  [memberUntagged.value]: {
    labelKey: "settings.workflows.form.events.contact_untagged.title_new",
    descriptionKey:
      "settings.workflows.form.events.contact_untagged.description_new",
  },
};
export const determineReleaseStatus = (
  released: boolean | (() => boolean) | undefined,
): boolean => {
  if (released === undefined) return true;
  return typeof released === "function" ? released() : released;
};

export const useReleasedEvents = () => {
  const shouldSupportUnreleasedWorkflowsFeaturesEnabled =
    useSupportUnreleasedWorkflowsFeaturesEnabled();
  const { currentCommunitySettings } = usePunditUserContext();
  const { data: gamificationSettings } = useGamificationSettings();
  const isGamificationEnabled = currentCommunitySettings?.gamification_enabled;
  const isGamificationCustomPointAwardsEnabled =
    currentCommunitySettings?.gamification_custom_point_awards_enabled;
  const shouldShowGamificationLeveledUpEvent =
    gamificationSettings?.enabled && isGamificationEnabled;
  const shouldShowGamificationLeveledDownEvent =
    gamificationSettings?.enabled &&
    isGamificationEnabled &&
    isGamificationCustomPointAwardsEnabled;

  const isContactsInWorkflowsEnabled = useContactsInWorkflowsEnabled();

  let allAvailableEvents = availableEvents;
  if (!shouldShowGamificationLeveledUpEvent) {
    allAvailableEvents = allAvailableEvents.filter(
      availableEvent =>
        availableEvent.value !== "gamification_member_leveled_up",
    );
  }

  if (!shouldShowGamificationLeveledDownEvent) {
    allAvailableEvents = allAvailableEvents.filter(
      availableEvent =>
        availableEvent.value !== "gamification_member_leveled_down",
    );
  }

  if (isContactsInWorkflowsEnabled) {
    allAvailableEvents = allAvailableEvents.map(availableEvent => {
      const translation = contactTagEventTranslations[availableEvent.value];
      if (translation) {
        return {
          ...availableEvent,
          label: t(translation.labelKey),
          description: t(translation.descriptionKey),
        };
      }
      return availableEvent;
    });
  }

  const releasedEvents = shouldSupportUnreleasedWorkflowsFeaturesEnabled
    ? allAvailableEvents
    : allAvailableEvents.filter(availableEvent =>
        determineReleaseStatus(availableEvent.released),
      );

  return { releasedEvents };
};
