import {
  reactQueryDelete,
  reactQueryGet,
  reactQueryPost,
  reactQueryPut,
} from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import type { CommunitySegmentRule } from "@circle-react/types/CommunitySegment";

interface ListSegmentsParams {
  params: {
    page?: number;
    per_page?: number;
  };
}

const list = ({ params = {} }: ListSegmentsParams) =>
  reactQueryGet(internalApi.segments.index({ params }));

const createSegment = ({
  title,
  rules,
}: {
  title: string;
  rules: CommunitySegmentRule;
}) => reactQueryPost(internalApi.segments.index(), { title, rules });

const deleteSegment = ({ id }: { id: number }) =>
  reactQueryDelete(internalApi.segments.detail({ id }));

const showSegment = ({ id }: { id: number }) =>
  reactQueryGet(internalApi.segments.detail({ id }));

const updateSegment = ({
  id,
  title,
  rules,
}: {
  id: number;
  title?: string;
  rules?: CommunitySegmentRule;
}) => reactQueryPut(internalApi.segments.detail({ id }), { title, rules });

export const segmentsApi = {
  list,
  delete: deleteSegment,
  create: createSegment,
  show: showSegment,
  update: updateSegment,
} as const;
