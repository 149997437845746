import { useEffect } from "react";
import { useVersionActivationModal } from "..";
import { useIsV3 } from "@circle-react/hooks/useIsV3";

export const useRenderVersionActivationModal = () => {
  const { isV3Enabled } = useIsV3();
  const modal = useVersionActivationModal();
  let shouldShowVersionActivationModal = false;

  try {
    shouldShowVersionActivationModal = !!sessionStorage.getItem(
      "showVersionActivationModal",
    );
  } catch (error) {
    console.warn("Session storage is disabled or unavailable.");
    shouldShowVersionActivationModal = true;
  }

  useEffect(() => {
    if (!isV3Enabled) return;

    if (shouldShowVersionActivationModal) {
      try {
        sessionStorage.removeItem("showVersionActivationModal");
      } catch (error) {
        console.warn("Unable to remove item from session storage.");
      }
      void modal.show();
    }
  }, [modal, shouldShowVersionActivationModal, isV3Enabled]);
};
