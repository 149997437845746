import { t } from "i18n-js";
import { Icon } from "@circle-react-shared/Icon";

const i18nRoot = "courses.lessons";

export interface LessonIconProps {
  isSpaceMember: boolean;
  isDripped: boolean;
  isCompleted: boolean;
}

export const LessonIcon = ({
  isSpaceMember,
  isDripped,
  isCompleted,
}: LessonIconProps) => {
  let iconType: any = "lock";
  let ariaLabel = "lesson_locked";

  if (isSpaceMember && isDripped) {
    iconType = isCompleted ? "16-checked-green" : "16-unchecked";
    ariaLabel = isCompleted ? "lesson_completed" : "lesson_incomplete";
  }

  return (
    <Icon
      className="text-default flex-none"
      size={16}
      type={iconType}
      aria-label={t([i18nRoot, ariaLabel])}
    />
  );
};
