import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Icon } from "@circle-react/components/shared/Icon";
import { supportWidgetPaths } from "@circle-react/helpers/urlHelpers";
import { Typography } from "@circle-react-uikit/Typography";

export const PostItem = ({ post }) => {
  const { id, name } = post;
  const history = useHistory();

  return (
    <button
      type="submit"
      className="hover:bg-tertiary focus:bg-secondary flex cursor-pointer items-center justify-between space-x-6 px-6 py-2 transition-all duration-150 ease-in-out"
      onClick={() => {
        history.push(supportWidgetPaths.showPost(id));
      }}
    >
      <span className="text-dark text-left">
        <Typography.LabelSm weight="medium">{name}</Typography.LabelSm>
      </span>
      <span className="-mr-1 ml-2.5">
        <Icon type="16-chevron-right" size={16} className="text-dark" />
      </span>
    </button>
  );
};

PostItem.propTypes = {
  post: PropTypes.object,
};
