import { Form } from "@circle-react-uikit/Form";

export const TrialRequiresCardToggle = () => (
  <Form.Item
    className="!py-0"
    labelClassName="!font-normal"
    name="trial_requires_card"
    translationRoot="settings.paywalls.form.free_trial"
    fullWidth
    hideBorder
    hideDescription
    hidePlaceholder
    inlineReverse
  >
    <Form.ToggleSwitch
      data-testid="trial_requires_card"
      name="trial_requires_card"
      variant="small"
    />
  </Form.Item>
);
