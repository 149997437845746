import { CardElement } from "@stripe/react-stripe-js";
import { t } from "i18n-js";
import { ChangePlanFormContextProvider } from "./ChangePlanFormContext";
import { ChangePlanFormLoader } from "./ChangePlanFormLoader";
import { ChangePlanFormStep } from "./ChangePlanFormStep";
import { ConfirmationStep } from "./ConfirmationStep";
import { PaywallChooserStep } from "./PaywallChooserStep";
import { PriceChooserStep } from "./PriceChooserStep";
import { CHANGE_PAYWALL_STEPS } from "./constants";
import { useChangePlan } from "./hooks/useChangePlan";

export interface ChangePlanFormProps {
  subscription: any;
  changePlan?: boolean;
  onSuccess: (result: any) => void;
  onDismiss: () => void;
}

export const ChangePlanForm = ({
  subscription,
  changePlan = false,
  onSuccess,
  onDismiss,
}: ChangePlanFormProps) => {
  const {
    isPlanChooserActive,
    isPriceChooserStepActive,
    isConfirmationStepActive,
    validateNewCouponHandler,
    previewMutation,
    changePriceMutation,
    canGoBack,
    goBack,
    getTitle,
    getButtonLabel,
    getFormDefaults,
    setActiveStep,
    setShouldKeepTrial,
    priceChangePreview,
    shouldKeepTrial,
    shouldAddPaymentMethod,
    isInTrial,
    isCardInfoComplete,
    isCreatingPaymentMethod,
    handleOnAddPaymentAndChangePrice,
    handleOnChangeCard,
  } = useChangePlan({
    subscription,
    changePlan,
    onSuccess,
    CardElement,
  });

  if (changePriceMutation.isLoading) {
    return (
      <ChangePlanFormLoader
        title={t(
          "member_profile_modal.billing.subscriptions.change_plan_form.hang_tight",
        )}
        subtitle={t(
          "member_profile_modal.billing.subscriptions.change_plan_form.wait_a_few_seconds",
        )}
      />
    );
  }

  const submit = (data: any) => {
    if (isPlanChooserActive) {
      setActiveStep(CHANGE_PAYWALL_STEPS.PRICES);
      setShouldKeepTrial(
        isInTrial && subscription.paywall_id == data.paywall_id,
      );
    } else if (isPriceChooserStepActive) {
      return previewMutation.mutate({
        new_paywall_price_id: data.new_paywall_price_id,
      });
    } else if (isConfirmationStepActive) {
      if (isInTrial && shouldAddPaymentMethod) {
        return handleOnAddPaymentAndChangePrice({
          new_paywall_price_id: data.new_paywall_price_id,
          new_coupon_code: priceChangePreview.new_coupon_code,
        });
      }

      return changePriceMutation.mutate({
        new_paywall_price_id: data.new_paywall_price_id,
        new_coupon_code: priceChangePreview.new_coupon_code,
      });
    }
  };

  return (
    <ChangePlanFormContextProvider
      dismiss={onDismiss}
      canGoBack={canGoBack}
      goBack={goBack}
      formDefaults={getFormDefaults()}
      submit={submit}
      submitLabel={getButtonLabel()}
      submitDisabled={
        (isPriceChooserStepActive && previewMutation.isLoading) ||
        (isConfirmationStepActive && previewMutation.isLoading) ||
        (isConfirmationStepActive &&
          shouldAddPaymentMethod &&
          !isCardInfoComplete) ||
        (isConfirmationStepActive &&
          shouldAddPaymentMethod &&
          isCreatingPaymentMethod)
      }
      priceChangePreview={priceChangePreview}
      subscription={subscription}
      shouldKeepTrial={shouldKeepTrial}
      CardElement={CardElement}
      isInTrial={isInTrial}
      shouldAddPaymentMethod={shouldAddPaymentMethod}
      handleOnChangeCard={handleOnChangeCard}
      previewMutation={previewMutation}
      validateNewCouponHandler={validateNewCouponHandler}
      changePlan={changePlan}
    >
      <ChangePlanFormStep title={getTitle()}>
        {isPlanChooserActive && <PaywallChooserStep />}
        {isPriceChooserStepActive && <PriceChooserStep />}
        {isConfirmationStepActive && <ConfirmationStep />}
      </ChangePlanFormStep>
    </ChangePlanFormContextProvider>
  );
};
