import { loadStripe } from "@stripe/stripe-js";
import { usePunditUserContext } from "@circle-react/contexts";

const localeToBcp47: Record<string, "en" | "pt-BR"> = {
  en: "en",
  pt: "pt-BR",
};

const getLocale = (locale?: typeof window.locale) =>
  localeToBcp47[locale ?? "en"];

export const loadStripeWrapper = ({
  stripeAccountId,
  stripePublicKey,
  locale,
}: any) =>
  loadStripe(stripePublicKey, {
    stripeAccount: stripeAccountId,
    locale: getLocale(locale),
  });

export const useStripeElement = () => {
  const { currentCommunity } = usePunditUserContext();

  const stripePromise = loadStripeWrapper({
    stripeAccountId: currentCommunity.payment_processor?.id,
    stripePublicKey:
      currentCommunity.payment_processor?.public_api_key ||
      window.STRIPE_PUBLIC_KEY,
    locale: window.locale || "en",
  });

  return { stripePromise };
};
