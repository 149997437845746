import { cloneElement } from "react";
import { useHideOnboardingConfirmationModal } from "@/react/components/Modals/HideOnboardingConfirmationModal";

export const Left = ({ children }) => {
  const hideOnboardingConfirmationModal = useHideOnboardingConfirmationModal();
  return (
    <div className="sidebar sidebar--left">
      <div className="sidebar__content" data-draggable="false">
        {cloneElement(children, {
          onClickRemoveOnboarding: hideOnboardingConfirmationModal.show,
        })}
      </div>
    </div>
  );
};
