import { useQuery } from "react-query";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";
import { workflows } from "@circle-react/helpers/urlHelpers/internalApi/workflows";

export const useFetchPost = id => {
  const {
    data: post,
    isLoading: isLoadingPost,
    isSuccess: isPostLoaded,
  } = useQuery(
    ["post", id],
    () =>
      reactQueryGet(
        workflows.posts.show({
          id: id,
        }),
      ),
    {
      enabled: !!id,
    },
  );

  return {
    post,
    isLoadingPost,
    isPostLoaded,
  };
};
