import { useQuery } from "react-query";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";

export const LEADERBOARD_QUERY_KEY = "leaderboard";

export const useLeaderboard = ({
  period = "7_days",
}: {
  period?: string | null;
}) =>
  useQuery([LEADERBOARD_QUERY_KEY, period], () =>
    reactQueryGet(
      internalApi.gamification.leaderboard({
        params: {
          period: period,
        },
      }),
    ),
  );
