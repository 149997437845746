import type { ComponentPropsWithoutRef } from "react";
import classNames from "classnames";
import { ThemeStyles } from "./ThemeStyles";

export type HightlightedCodeProps = ComponentPropsWithoutRef<"code">;

export const HightlightedCode = ({
  children,
  className,
  ...props
}: HightlightedCodeProps) => (
  <>
    <ThemeStyles />
    <pre className="bg-tertiary m-0 !rounded-md border-none px-4 py-3">
      <code
        className={classNames(
          "!bg-transparent !p-0 text-xs leading-4",
          className,
        )}
        {...props}
      >
        {children}
      </code>
    </pre>
  </>
);
