import classNames from "classnames";
import { ClearButton } from "@circle-react-shared/Filter";
import { DynamicFilterItems } from "@circle-react-shared/MemberFilters/DynamicFilters/DynamicFilterItems";
import { FiltersRow } from "@circle-react-shared/MemberFilters/DynamicFilters/FiltersRow";
import type { FilterObject } from "./types";
import { useDynamicFilters } from "./useDynamicFilters";

interface DynamicFiltersProp {
  defaultFilters: FilterObject[];
  customFilters?: FilterObject[];
  defaultFilterItems: string[];
  className?: string;
  showClearButton?: boolean;
  label: string;
}

export const DynamicFilters = ({
  defaultFilters = [],
  customFilters = [],
  defaultFilterItems = [],
  className,
  showClearButton = true,
  label,
}: DynamicFiltersProp) => {
  const { isClearFiltersShown, onClearFilters, ...dynamicFilterProps } =
    useDynamicFilters({
      filters: [...defaultFilters, ...customFilters],
      defaultFilterItems,
    });

  return (
    <FiltersRow className={classNames("w-full", className)}>
      <DynamicFilterItems
        defaultFilters={defaultFilters}
        customFilters={customFilters}
        label={label}
        {...dynamicFilterProps}
      />
      {showClearButton && (
        <ClearButton isVisible={isClearFiltersShown} onClick={onClearFilters} />
      )}
    </FiltersRow>
  );
};
