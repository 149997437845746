import PropTypes from "prop-types";
import { useThemeObject } from "@circle-react/providers";
import { LockscreenCanvas } from "@circle-react-shared/CanvasPreviews";
import { useFormBlocks } from "../Providers";

export const LockscreenPreview = ({ space = {} }) => {
  const theme = useThemeObject();
  const { blocks } = useFormBlocks();
  const { emoji = "", name = "" } = space;

  return (
    <LockscreenCanvas
      theme={theme}
      headerSpaceEmoji={emoji}
      headerSpaceTitle={name}
      blocks={blocks}
    />
  );
};

LockscreenPreview.propTypes = {
  space: PropTypes.shape({
    emoji: PropTypes.string,
    name: PropTypes.string,
  }),
};
