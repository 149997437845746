import PropTypes from "prop-types";
import { Typography } from "@circle-react-uikit/Typography";

export const Title = ({ shouldTruncate = true, children }) => {
  const style = shouldTruncate
    ? {
        display: "-webkit-box",
        WebkitLineClamp: "3",
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
      }
    : {};

  return (
    <div style={style}>
      <Typography.LabelLg weight="semibold">{children}</Typography.LabelLg>
    </div>
  );
};

Title.propTypes = {
  shouldTruncate: PropTypes.bool,
  children: PropTypes.node,
};
