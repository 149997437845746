import { t } from "i18n-js";
import { useGamificationSettings } from "@circle-react/components/SettingsApp/Gamification/LevelsForm/gamificationApi";
import { BaseResource } from "@circle-react/components/SettingsApp/Workflows/components/Events/BaseResource";

export interface LevelResourcePropType {
  resourceId: number;
}

export const LevelResource = ({ resourceId }: LevelResourcePropType) => {
  const { data: gamificationSettings, isLoading } = useGamificationSettings();

  const value = gamificationSettings?.levels?.find(
    level => level.id === resourceId,
  )?.name;
  return (
    <BaseResource
      label={t(
        "settings.workflows.form.events.gamification_member_leveled_up.level",
      )}
      value={value}
      isLoading={isLoading}
    />
  );
};
