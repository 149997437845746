import { useEffect, useState } from "react";
import { ParticipantEvent, RoomEvent } from "livekit-client";
import { useLivekitContext } from "../../VideoProvider/LivekitProvider";
import { ACTION_TYPES, parseMetadata } from "./helpers";
import { useLivekitPublishMetadata } from "./useLivekitPublishMetadata";

export const useLivekitPeerMetadata = peerId => {
  const { room, participants } = useLivekitContext();
  const participant = participants?.find(
    participant => participant.sid === peerId,
  );
  const [metadata, setMetadata] = useState(
    parseMetadata(participant?.metadata),
  );
  const publishMetadata = useLivekitPublishMetadata(participant);

  useEffect(() => {
    const onMetadataChanged = prev => {
      // The isHandRaised metadata param is only streamed but this is not persisted.
      // More info here:
      //  - https://docs.livekit.io/client/data-messages/#data-messages
      //  - https://github.com/livekit/client-sdk-js/issues/148
      // This block avoids override the isHandRaised param with the persisted metadata
      const prevMetadata = JSON.parse(prev || "{}");
      const currMetadata = JSON.parse(participant.metadata || "{}");
      if (!("isHandRaised" in currMetadata)) {
        return setParticipantMetadata({
          ...currMetadata,
          isHandRaised: prevMetadata.isHandRaised,
        });
      }

      if (participant.metadata) {
        setMetadata(parseMetadata(participant.metadata));
      }
    };

    if (participant) {
      participant.on(
        ParticipantEvent.ParticipantMetadataChanged,
        onMetadataChanged,
      );
    }

    return () => {
      if (participant) {
        participant.off(
          ParticipantEvent.ParticipantMetadataChanged,
          onMetadataChanged,
        );
      }
    };
  }, [participant]);
  const setParticipantMetadata = async newMetadata => {
    participant?.setMetadata(JSON.stringify({ ...metadata, ...newMetadata }));
    await publishMetadata(newMetadata);
  };

  useEffect(() => {
    const decoder = new TextDecoder();
    const onDataReceived = data => {
      try {
        const strData = decoder.decode(data);
        const action = JSON.parse(strData);
        if (action?.type === ACTION_TYPES.PUBLISH_METADATA && participant) {
          const participantId = action.payload?.participantId;
          const newMetadata = action.payload?.metadata;
          if (newMetadata && participant?.sid === participantId) {
            participant.setMetadata(
              JSON.stringify({ ...metadata, ...newMetadata }),
            );
          }
        }
      } catch (err) {
        console.error("Could not decode received data - ", err.message);
      }
    };

    if (room) {
      room.on(RoomEvent.DataReceived, onDataReceived);
    }

    return () => {
      if (room) {
        room.off(RoomEvent.DataReceived, onDataReceived);
      }
    };
  }, [metadata, participant, room]);

  return {
    metadata,
    setMetadata: setParticipantMetadata,
  };
};
