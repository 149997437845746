import { useMemo } from "react";
import { SanitizeHTMLString } from "@circle-react-shared/uikit/SanitizeContent";

interface HighlightedTextProps {
  originalText: string;
  highlightedText?: string | null;
}

export const HighlightedText = ({
  originalText,
  highlightedText,
}: HighlightedTextProps) => {
  const finalHighlightedHTML = useMemo(() => {
    if (!originalText) return "";

    const extractHighlights = (text: string | null | undefined): string[] => {
      if (!text) return [];
      const regex = /<mark>(.*?)<\/mark>/g;
      return Array.from(text.matchAll(regex), match => match[1]);
    };

    const highlightText = (text: string, terms: string[]): string => {
      const escapedTerms = terms.map(escapeRegExp);
      const regex = new RegExp(`(${escapedTerms.join("|")})`, "gi");
      return text.replace(regex, "<mark>$1</mark>");
    };

    const escapeRegExp = (string: string): string =>
      string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

    const highlights = extractHighlights(highlightedText);
    return highlightText(originalText, highlights);
  }, [originalText, highlightedText]);

  return <SanitizeHTMLString content={finalHighlightedHTML} />;
};
