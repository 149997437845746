import { Icon } from "@/react/components/shared/Icon";
import { Modal } from "@/react/components/shared/uikit/ModalV2";
import { LabelMd } from "@/react/components/shared/uikit/Typography/components/Label/LabelMd";
import { TitleLg } from "@/react/components/shared/uikit/Typography/components/Title/TitleLg";

export interface ChangePlanFormLoaderProps {
  title: string;
  subtitle: string;
}

export const ChangePlanFormLoader = ({
  title,
  subtitle,
}: ChangePlanFormLoaderProps) => (
  <Modal.Body className="flex flex-col items-center py-14">
    <div className="m-8 flex h-16 w-16 justify-center">
      <Icon
        className="!h-full !w-full"
        type="loader"
        useWithFillCurrentColor
        useWithStrokeCurrentColor
      />
    </div>
    <div className="space-y-4 text-center">
      <div>
        <TitleLg>{title}</TitleLg>
      </div>
      <div>
        <LabelMd>{subtitle}</LabelMd>
      </div>
    </div>
  </Modal.Body>
);
