import { useQuery } from "react-query";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers/internalApi";

export const useCommunityChat = (id: number) => {
  const fetchChat = () =>
    reactQueryGet(internalApi.communityInbox.show({ id }));

  const { data, isLoading } = useQuery(
    ["community-inbox", "chat-room", id],
    fetchChat,
  );

  return {
    chat: data,
    isChatLoading: isLoading,
  };
};
