// Room synchronization constants
export const SYSTEM_MESSAGES = Object.freeze({
  UPDATE_ROOM: "update-room",
});
export const MODERATOR_MESSAGES = Object.freeze({
  STOP_SCREENSHARE: "stop-screenshare",
});
export const SYSTEM_TYPE = "system";
export const MODERATOR_TYPE = "moderator";
export const METADATA_TYPE = "metadata";
