import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import { useHistory } from "react-router-dom";
import { Icon } from "@circle-react/components/shared/Icon";
import { supportWidgetPaths } from "@circle-react/helpers/urlHelpers";
import { EmojiRenderer } from "@circle-react-shared/EmojiRenderer";
import { Typography } from "@circle-react-uikit/Typography";
import { useSupportWidgetContext } from "../SupportWidgetContext";

export const SpaceGroupListItem = ({ spaceGroup }) => {
  const { setCurrentSpaceProperties } = useSupportWidgetContext();
  const history = useHistory();

  const handleCLick = (spaceProperties, spaceId) => {
    setCurrentSpaceProperties(spaceProperties);
    history.push(supportWidgetPaths.posts(spaceId));
  };

  if (isEmpty(spaceGroup.spaces)) return null;

  return (
    <>
      <div className="px-6">
        <Typography.LabelXxs color="text-light">
          {spaceGroup.name}
        </Typography.LabelXxs>
      </div>
      <div className="flex flex-col">
        {spaceGroup.spaces.map(
          ({ id, name, emoji, custom_emoji_url, custom_emoji_dark_url }) => (
            <button
              type="submit"
              className="hover:bg-tertiary focus:bg-secondary flex cursor-pointer items-center justify-between space-x-6 px-6 py-2 transition-all duration-150 ease-in-out last:mb-4"
              key={id}
              onClick={() =>
                handleCLick(
                  { name, emoji, custom_emoji_url, custom_emoji_dark_url },
                  id,
                )
              }
            >
              <Typography.LabelSm weight="medium">
                <div className="flex flex-row">
                  <EmojiRenderer
                    className="!h-5 !w-auto"
                    emoji={emoji}
                    customEmojiUrl={custom_emoji_url}
                    customEmojiDarkUrl={custom_emoji_dark_url}
                  />
                  <span className="ml-2 break-all">{name}</span>
                </div>
              </Typography.LabelSm>
              <Icon
                type="16-chevron-right"
                size={16}
                className="text-default"
              />
            </button>
          ),
        )}
      </div>
    </>
  );
};

SpaceGroupListItem.propTypes = {
  spaceGroup: PropTypes.object,
};
