import { useFormContext } from "react-hook-form";
import { useAdminPaywallCharge } from "@circle-react/hooks/paywalls/useAdminPaywallCharge";
import { TABS, TABS_LOCALE, statusByTab } from "../constants";

export const useTabWithFilters = (
  setActiveFilterTab,
  setSearchKey,
  resetAllChargesToFirstPage,
) => {
  const allChargesData = useAdminPaywallCharge({
    filters: { display_status: statusByTab[TABS.ALL] },
    perPage: 1,
  });
  const paidChargesData = useAdminPaywallCharge({
    filters: { display_status: statusByTab[TABS.PAID] },
    perPage: 1,
  });
  const refundedChargesData = useAdminPaywallCharge({
    filters: { display_status: statusByTab[TABS.REFUNDED] },
    perPage: 1,
  });
  const failedChargesData = useAdminPaywallCharge({
    filters: { display_status: statusByTab[TABS.FAILED] },
    perPage: 1,
  });

  const tabOptions = [
    [TABS.ALL, allChargesData],
    [TABS.PAID, paidChargesData],
    [TABS.REFUNDED, refundedChargesData],
    [TABS.FAILED, failedChargesData],
  ].map(([tab, chargesData]) => ({
    value: tab,
    label: TABS_LOCALE[tab],
    badge: { value: chargesData.totalCharges },
  }));

  const { reset } = useFormContext();
  const changeTabAndResetForm = tab => {
    reset({ searchKey: "" });
    setSearchKey("");
    setActiveFilterTab(tab);
    resetAllChargesToFirstPage();
  };

  return {
    tabOptions,
    changeTabAndResetForm,
  };
};
