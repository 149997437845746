import { Suspense } from "react";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { PageLoader } from "@circle-react/pages/PageLoader";

const GeneralSettingsComponent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "GeneralSettingsPage" */ "@circle-react/components/SettingsApp/General"
    ),
);

export const GeneralSettingsPage = () => (
  <Suspense fallback={<PageLoader />}>
    <GeneralSettingsComponent />
  </Suspense>
);
