import { useEffect } from "react";
import isFunction from "lodash/isFunction";
import { useBoolean } from "@circle-react/hooks/utils/useBoolean";

export const useDraftVisibilityConfirmModal = onSubmit => {
  const [shouldShowDraftAccessConfirmaModal, toggleDraftAccessConfirmaModal] =
    useBoolean(false);

  const [isDraftAccessConfirmed, toggleIsDraftAccessConfirmed] =
    useBoolean(false);

  useEffect(() => {
    if (isDraftAccessConfirmed) {
      isFunction(onSubmit) && onSubmit();
    }
  }, [isDraftAccessConfirmed, onSubmit]);

  return {
    shouldShowDraftAccessConfirmaModal,
    toggleDraftAccessConfirmaModal,
    isDraftAccessConfirmed,
    toggleIsDraftAccessConfirmed,
  };
};
