export const isResultPost = result =>
  result.type === "post" && result.slug && result.space_slug;
export const isResultComment = result =>
  result.type === "comment" && result.post_slug;
export const isResultSpace = result => result.type === "space";
export const isResultEvent = result => result.type === "event";
export const isResultCommunityMember = result =>
  result.type === "community_member" &&
  result.public_uid &&
  result.accepted_invitation;
