import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { Form } from "@circle-react-uikit/Form";
import { usePricingTabContext } from "../../hooks/usePricingTabContext";

export const TrialSelfCancelableToggle = () => {
  const { hasRecurringPrices } = usePricingTabContext();
  const { setValue } = useFormContext();

  useEffect(() => {
    if (!hasRecurringPrices()) {
      setValue("self_trial_cancelable", false);
    }
  }, [hasRecurringPrices()]);

  return (
    <Form.Item
      className="!py-0"
      labelClassName="!font-normal"
      name="self_trial_cancelable"
      translationRoot="settings.paywalls.modal.tabs.pricing"
      fullWidth
      hideBorder
      hideDescription
      hidePlaceholder
      inlineReverse
      isDisabled={!hasRecurringPrices()}
    >
      <Form.ToggleSwitch
        name="self_trial_cancelable"
        variant="small"
        disabled={!hasRecurringPrices()}
      />
    </Form.Item>
  );
};
