import { t } from "i18n-js";
import { Modal } from "@circle-react-uikit/ModalV2";
import { Typography } from "@circle-react-uikit/Typography";

export const ConfirmDynamicBody = () => (
  <Modal.Body className="text-center">
    <Typography.BodyMd>
      {t(
        "settings.workflows.confirm_modal.are_you_sure_you_want_to_publish_your_workflow",
      )}
    </Typography.BodyMd>
  </Modal.Body>
);
