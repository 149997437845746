import type {
  AddressElementProps,
  CardElementProps,
} from "@stripe/react-stripe-js";
import type { Appearance } from "@stripe/stripe-js";

export const stripeElementsOptions: { appearance: Appearance } = {
  appearance: {
    theme: "stripe",
    variables: {
      spacingGridRow: "20px",
    },
    rules: {
      [".Label"]: {
        color: "rgb(59, 59, 59)",
        fontFamily: `system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
        fontSize: "14px",
        fontWeight: "600",
        lineHeight: "20px",
        margin: "0",
        padding: "0",
      },
      [".Input"]: {
        backgroundColor: "rgb(255, 255, 255)",
        border: "1px solid rgb(229, 231, 235)",
        borderColor: "rgb(229, 231, 235)",
        borderRadius: "8px",
        borderStyle: "solid",
        borderWidth: "1px",
        boxShadow: "none",
        color: "rgb(59, 59, 59)",
        fontFamily: `system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "18px",
        margin: "0",
        marginTop: "4px",
        outline: "transparent solid 0px",
        outlineOffset: "0",
        padding: "12px 16px",
        transition: "none",
      },
      [".Input:focus"]: {
        border: "1px solid rgba(107, 114, 128)",
        borderColor: "rgba(107, 114, 128)",
        borderWidth: "1px",
        boxShadow: "none",
        outline: "transparent solid 0px",
        outlineOffset: "0",
        transition: "none",
      },
      ".TermsText": {
        fontSize: "0",
      },
    },
  },
};

export const stripeAddressElementOptions: AddressElementProps["options"] = {
  mode: "billing",
  fields: {
    phone: "never",
  },
  display: {
    name: "full",
  },
};

export const stripeCardElementOptions: CardElementProps["options"] = {
  hidePostalCode: true,
  classes: {
    base: "h-11 w-full grow rounded-lg border border-solid border-gray-200 bg-white px-4 py-3 shadow-none outline-0 ring-0",
    focus: "border border-gray-500 shadow-none outline-0 ring-0",
  },
};
