import { t } from "i18n-js";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-uikit/Typography";

interface AddMembersButtonProps {
  onAdd: () => void;
}

export const AddMembersButton = ({ onAdd }: AddMembersButtonProps) => (
  <button
    type="button"
    className="hover:bg-secondary flex h-12 w-full flex-row items-center rounded-lg"
    onClick={onAdd}
  >
    <div className="bg-secondary ml-4 mr-3 inline-flex h-8 w-8 items-center justify-center rounded-md text-gray-600">
      <Icon type="16-account" size={16} />
    </div>
    <Typography.LabelSm weight="semibold">
      {t("spaces.form.edit.members.add_members")}
    </Typography.LabelSm>
  </button>
);
