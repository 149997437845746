import { ModalManagerProvider } from "@circle-react/components/Layout/ModalManagerProvider";
import {
  ChatRoomsContextProvider,
  UnreadChatThreadsProvider,
} from "@circle-react/hooks/chatsV2";
import { MasqueradeContextProvider } from "../contexts/masqueradeContext";
import { SpaceNotificationsProvider } from "../contexts/sidebarNotificationsContext";

const Providers = [
  ChatRoomsContextProvider,
  SpaceNotificationsProvider,
  UnreadChatThreadsProvider,
  ModalManagerProvider,
  MasqueradeContextProvider,
];

export const MultiContextProvider = ({ children }: any) =>
  Providers.reduceRight(
    (acc, Provider) => <Provider>{acc}</Provider>,
    children,
  );
