import { useEffect } from "react";
import PropTypes from "prop-types";
import { t } from "i18n-js";
import { useFormContext } from "react-hook-form";
import { useWatch } from "react-hook-form";
import { useMobileWebFlags } from "@circle-react/hooks/useMobileWebFlags";
import { Form } from "@circle-react-uikit/Form";
import { OptionDescription } from "./OptionDescription";

const options = [
  {
    label: t("spaces.form.create.visibility_types.open"),
    value: "open",
    description: (
      <OptionDescription
        accessDesc={t("customize_space.access_form.open_access")}
        visibilityDesc={t("customize_space.access_form.open_visibility")}
        isAccessOpen
      />
    ),
  },
  {
    label: t("spaces.form.create.visibility_types.private"),
    value: "private",
    description: (
      <OptionDescription
        accessDesc={t("customize_space.access_form.private_access")}
        visibilityDesc={t("customize_space.access_form.private_visibility")}
      />
    ),
  },
  {
    label: t("spaces.form.create.visibility_types.secret"),
    value: "secret",
    description: (
      <OptionDescription
        accessDesc={t("customize_space.access_form.secret_access")}
        visibilityDesc={t("customize_space.access_form.secret_visibility")}
      />
    ),
  },
];

export const AccessForm = ({ onChange }) => {
  const {
    formState: { dirtyFields },
  } = useFormContext();

  const visibility = useWatch({ name: "visibility" });
  const isPrivateAccess = visibility === "private";
  const { isMobileWebNewSpaceCustomizationUIEnabled } = useMobileWebFlags();

  useEffect(() => {
    if ("visibility" in dirtyFields) {
      onChange(true);
    }
  }, [dirtyFields.visibility]);

  return (
    <>
      <Form.Item
        name="visibility"
        hideDescription
        rules={{
          required: t("spaces.form.create.choose_visibility"),
          maxLength: 255,
        }}
        hideBorder
        hideLabel
        fullWidth
        variant="extra-bottom-margin"
      >
        <Form.RadioGroup
          variant={isMobileWebNewSpaceCustomizationUIEnabled ? "box" : "radio"}
          name="visibility"
          options={options}
          optionAlignment="left"
        />
      </Form.Item>
      {isPrivateAccess && (
        <Form.Item
          inlineReverse
          hideBorder
          hideDescription
          label={t("spaces.form.edit.customize.show_lock_icon_to_non_members")}
          tooltipText={t(
            "spaces.form.edit.customize.show_lock_icon_to_non_members_description",
          )}
          name="show_lock_icon_for_non_members"
          shouldToggleValueOnLabelClick
        >
          <Form.ToggleSwitch variant="small" />
        </Form.Item>
      )}
    </>
  );
};

AccessForm.propTypes = {
  onChange: PropTypes.func,
};
