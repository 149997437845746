import PropTypes from "prop-types";
import { Typography } from "@circle-react-uikit/Typography";

export const Title = ({ children }) => (
  <div>
    <Typography.LabelSm weight="semibold">{children}</Typography.LabelSm>
  </div>
);

Title.propTypes = {
  children: PropTypes.node,
};
