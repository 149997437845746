import { t } from "i18n-js";
import { Icon } from "@circle-react-shared/Icon";
import { Form } from "@circle-react-uikit/Form";

const options = [
  {
    label: t("spaces.form.create.course_types.self_paced"),
    value: "self_paced",
    description: t("spaces.form.create.course_types.self_paced_info"),
  },
  {
    label: t("spaces.form.create.course_types.structured"),
    value: "structured",
    description: t("spaces.form.create.course_types.structured_info"),
  },
  {
    label: t("spaces.form.create.course_types.scheduled"),
    value: "scheduled",
    description: t("spaces.form.create.course_types.scheduled_info"),
  },
];

interface CourseTypePickerOptionsProps {
  hideLabel?: boolean;
}
export const CourseTypePickerOptions = ({
  hideLabel,
}: CourseTypePickerOptionsProps) => (
  <Form.Item
    name="course_setting[course_type]"
    hideDescription
    rules={{
      required: t("spaces.form.create.choose_course_type"),
      maxLength: 255,
    }}
    hideBorder
    fullWidth
    variant="extra-bottom-margin"
    label={t("spaces.form.create.course_types.label")}
    hideLabel={hideLabel}
  >
    <Form.RadioGroup
      variant="box"
      name="visibility"
      options={options}
      optionAlignment="left"
      checkedIcon={<Icon type="20-checkmark-circle" size={16} />}
    />
  </Form.Item>
);
