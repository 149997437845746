import { timeDelay } from ".";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-uikit/Typography";

export const ViewTimeDelay = ({ action }: { action: any }) => {
  const { api_params: apiParams } = action || {};

  return (
    <div className="flex items-center justify-center">
      <div className="border-dark flex h-[60px] w-[360px] justify-center gap-3 rounded-xl border px-5 py-[18px]">
        <Icon
          type="20-time-delay"
          size={20}
          useWithFillCurrentColor
          aria-hidden
        />
        <Typography.LabelMd weight="semibold">
          {timeDelay.getActionTitle?.(apiParams)}
        </Typography.LabelMd>
      </div>
    </div>
  );
};
