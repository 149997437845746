import { useMutation } from "react-query";
import { reactQueryPut } from "@/react/helpers/backendRequestHelpers";
import { internalApi } from "@/react/helpers/urlHelpers";

interface DripScheduleMutationProps {
  course_section: {
    dripped_at?: string | null;
    drip_delay?: number;
    notify_students_enabled?: boolean;
  };
}

export const useDripScheduleMutation = ({ section, options }: any) => {
  const changeDripScheduleMutation = useMutation(
    (data: DripScheduleMutationProps) =>
      reactQueryPut(
        internalApi.courses.updateSection({
          sectionId: section.id,
          courseId: section.space_id,
        }),
        data,
      ),
    options,
  );
  return { changeDripScheduleMutation };
};
