import { useState } from "react";

export const useLivekitRoleChangeNotification = () => {
  const [roleChangedToModerator, setRoleChangedToModerator] = useState(false);
  const hideModeratorNotification = () => setRoleChangedToModerator(false);
  return {
    roleChangedToModerator,
    hideModeratorNotification,
  };
};
