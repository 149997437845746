import { t } from "i18n-js";
import { InfoBox } from "@circle-react-shared/uikit/InfoBox";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { OfferingIdField } from "./Fields/OfferingIdField";
import { SubscriptionGroupIdField } from "./Fields/SubscriptionGroupIdField";
import { SubscriptionGroupNameField } from "./Fields/SubscriptionGroupNameField";

export const PricingSettings = () => (
  <InfoBox className="border-primary flex flex-col gap-y-4 border px-6 py-5">
    <Typography.LabelLg weight="bold">
      {t("settings.paywalls.modal.tabs.pricing.settings_title")}
    </Typography.LabelLg>
    <div className="flex flex-col gap-y-3.5">
      <OfferingIdField />
      <div className="flex flex-row gap-x-4">
        <div className="basis-1/2">
          <SubscriptionGroupNameField />
        </div>
        <div className="basis-1/2">
          <SubscriptionGroupIdField />
        </div>
      </div>
    </div>
  </InfoBox>
);
