import PropTypes from "prop-types";
import {
  CurrentSpaceContextProvider,
  CustomizeSpaceContextProvider,
} from "@circle-react/contexts";
import { CustomizeSpaceLayout } from "./CustomizeSpaceLayout";
import { Modal } from "./Modal";

export const CustomizeSpaceFullPage = ({
  isOpen,
  onExit,
  onClose,
  onSave,
  space,
  sections,
  initialSection,
  onSlugChange,
  scrollToId,
}) => {
  if (!space) return null;

  return (
    <CurrentSpaceContextProvider spaceId={space.id}>
      <CustomizeSpaceContextProvider onSlugChange={onSlugChange}>
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          onExit={onExit}
          onSave={onSave}
          sections={sections}
          initialSection={initialSection}
          title={space?.name}
        >
          <CustomizeSpaceLayout space={space} scrollToId={scrollToId} />
        </Modal>
      </CustomizeSpaceContextProvider>
    </CurrentSpaceContextProvider>
  );
};

CustomizeSpaceFullPage.propTypes = {
  isOpen: PropTypes.bool,
  onExit: PropTypes.func,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  sections: PropTypes.array,
  space: PropTypes.object,
  initialSection: PropTypes.string,
  scrollToId: PropTypes.string,
};
