import { createContext, useContext } from "react";
import PropTypes from "prop-types";
import { useTemplates } from "./useTemplates";

const WorkflowFormData = createContext({
  templates: [],
  isLoadingTemplates: false,
  selectedTemplateId: undefined,
});
WorkflowFormData.displayName = "WorkflowFormData";

export const CreateWorkflowProvider = ({ children, category }) => {
  const { templates, isLoading: isLoadingTemplates } = useTemplates(category);

  return (
    <WorkflowFormData.Provider
      value={{
        templates,
        isLoadingTemplates,
      }}
    >
      {children}
    </WorkflowFormData.Provider>
  );
};

CreateWorkflowProvider.propTypes = {
  children: PropTypes.node.isRequired,
  category: PropTypes.string,
};

CreateWorkflowProvider.defaultProps = {
  category: undefined,
};

export const useCreateWorkflowData = () => useContext(WorkflowFormData);
