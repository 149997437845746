import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { usePunditUserContext } from "@circle-react/contexts";
import { isMobileOs } from "@circle-react/helpers/browserHelpers";
import { isPublicStream } from "@circle-react/helpers/liveStreamRoomHelpers";
import { userPaths } from "@circle-react/helpers/urlHelpers";
import { liveStreamPaths } from "@circle-react/helpers/urlHelpers";
import { useLiveStreamsFeatureFlags } from "@circle-react/hooks/useLiveStreamsFeatureFlags";
import { useRoom } from "./useRoom";

export const useLiveStreamsChecksRedirect = () => {
  const {
    publicLiveStreamsEnabled,
    liveMobileWebEnabled,
    isLoading: isLoadingFlags,
  } = useLiveStreamsFeatureFlags();
  const {
    currentCommunityMember,
    currentUser,
    isLoading: isLoadingPundit,
  } = usePunditUserContext();
  const history = useHistory();
  const room = useRoom();
  const isLoading = isLoadingFlags || isLoadingPundit;

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (
      publicLiveStreamsEnabled &&
      isPublicStream(room) &&
      !currentCommunityMember
    ) {
      // Redirect to public room
      history.push(liveStreamPaths.publicRoom({ roomSlug: room.slug }));
    } else if (!currentUser) {
      // Redirect to login
      window.location.href = userPaths.signIn({
        params: {
          post_login_redirect: window.location.href,
        },
      });
    } else if (
      isMobileOs() &&
      room &&
      liveMobileWebEnabled &&
      window.location.pathname !==
        liveStreamPaths.mobileRoom({ roomSlug: room.slug })
    ) {
      history.push(liveStreamPaths.mobileRoom({ roomSlug: room.slug }));
    } else if (isMobileOs() && !liveMobileWebEnabled) {
      history.push(liveStreamPaths.notSupported({ roomSlug: room.slug }));
    }
  }, [
    currentCommunityMember,
    currentUser,
    history,
    isLoading,
    liveMobileWebEnabled,
    publicLiveStreamsEnabled,
    room,
  ]);
};
