import type {
  MarketingEmailAddressAttributes,
  MarketingEmailAddressSettingsAttributes,
} from "@circle-react/components/SettingsApp/Emails/components/BroadcastSettings/interfaces";
import {
  reactQueryGet,
  reactQueryPost,
} from "../helpers/backendRequestHelpers";
import { internalApi } from "../helpers/urlHelpers";

const show = () =>
  reactQueryGet(internalApi.marketingEmailAddressSettings.show());

const upsert = (payload: MarketingEmailAddressSettingsAttributes) =>
  reactQueryPost(internalApi.marketingEmailAddressSettings.upsert(), payload);

const footerHtml = (payload: MarketingEmailAddressAttributes) =>
  reactQueryGet(internalApi.marketingEmailAddressSettings.footerHtml(payload));

export const marketingEmailAddressSettingsApi = {
  show,
  upsert,
  footerHtml,
};
