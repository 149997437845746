import { t } from "i18n-js";
import { useHistory } from "react-router-dom";
import { serializeObjectToQueryParam } from "@circle-react/helpers/urlHelpers";
import { useRouterQueryParams } from "@circle-react/hooks/useRouterQueryParams";
import { DebouncedSearchBar } from "@circle-react-shared/DebouncedSearchBar";
import { IconButton } from "@circle-react-shared/uikit/HeaderV3/IconButton";

export const MembersSearch = ({ setShouldRenderMobileFiltersTransition }) => {
  const history = useHistory();
  const { query = "", ...rest } = useRouterQueryParams();

  const onSubmit = value =>
    history.push({
      search: serializeObjectToQueryParam({
        ...rest,
        query: value?.trim(),
      }),
    });

  return (
    <div className="bg-primary border-primary flex items-center justify-between border-b px-4 py-2 md:hidden">
      <DebouncedSearchBar
        defaultValue={query}
        onSearch={onSubmit}
        placeholder={t("members_directory.header.search.placeholder")}
      />
      <IconButton
        name="20-filters"
        iconSize={20}
        iconClassName="!text-dark"
        buttonSize={28}
        onClick={() =>
          setShouldRenderMobileFiltersTransition(prevState => !prevState)
        }
      />
    </div>
  );
};
