import PropTypes from "prop-types";
import { t } from "i18n-js";
import { Typography } from "@circle-react/components/shared/uikit/Typography";
import { usePlanStatus } from "@circle-react/hooks/usePlanStatus";
import { LimitPanel } from "../LimitPanel";

export const Attendees = ({ hosts, participants, onPurchase, isLoading }) => {
  const { isCommunityOnEnterprise } = usePlanStatus();

  return (
    <LimitPanel
      isLoading={isLoading}
      canPurchaseMore={!isCommunityOnEnterprise}
      onPurchase={onPurchase}
      title={t("live_streams.dashboard.limits.hosts")}
    >
      <div className="flex h-full flex-col justify-between">
        <div>
          <div className="text-dark text-base font-bold">{hosts}</div>
        </div>
        <div>
          <Typography.LabelSm>
            {t("live_streams.dashboard.limits.participants")}
          </Typography.LabelSm>
          <div className="text-dark text-base font-bold">{participants}</div>
        </div>
      </div>
    </LimitPanel>
  );
};

Attendees.propTypes = {
  hosts: PropTypes.number,
  participants: PropTypes.number,
  onPurchase: PropTypes.func,
  isLoading: PropTypes.bool,
};
