import PropTypes from "prop-types";
import { t } from "i18n-js";
import {
  MAXIMUM_FREE_TRIAL_DAYS,
  MINIMUM_FREE_TRIAL_DAYS_WHEN_ENABLED,
} from "@circle-react/components/Paywalls/Admin/PaywallsAdmin/FormModal/PricingTab/constants";
import { Form } from "@circle-react-uikit/Form";
import { Typography } from "@circle-react-uikit/Typography";

const i18nRoot = "settings.workflows.form";

export const TrialDaysInput = ({ disabled, name }) => {
  const trialDaysInputValidation = {
    pattern: {
      value: /^[0-9]+$/,
      message: t(
        "create_paywall_subscription_modal.warning.only_number_are_allowed",
      ),
    },
    required: t(
      "create_paywall_subscription_modal.warning.only_number_are_allowed",
    ),
    min: {
      value: MINIMUM_FREE_TRIAL_DAYS_WHEN_ENABLED,
      message: t(
        "settings.paywalls.modal.tabs.pricing.alerts.free_trial_should_be_greater_than",
      ),
    },
    max: {
      value: MAXIMUM_FREE_TRIAL_DAYS,
      message: t(
        "settings.paywalls.modal.tabs.pricing.alerts.free_trial_should_be_less_or_equal_than",
      ),
    },
  };

  return (
    <Form.Item
      name={name}
      hideDescription
      label={
        <Typography.LabelSm weight="semibold">
          {t([i18nRoot, "trial_days"])}
        </Typography.LabelSm>
      }
      hideBorder
      isFirstChild
      hiddenField
      className="flex flex-1 flex-col gap-1"
      errorClassName="text-red-500 text-xs"
      rules={trialDaysInputValidation}
      placeholder={t([i18nRoot, "trial_days_placeholder"])}
    >
      <Form.Input
        name="trial_days"
        inputMode="numeric"
        suffix={t("create_paywall_subscription_modal.form.trial_days_suffix")}
        disabled={disabled}
      />
    </Form.Item>
  );
};

TrialDaysInput.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
};
