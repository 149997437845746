import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { availableActions } from "../Actions";

export const useCompactViewElements = (actionType: string, title: any) => {
  const {
    formState: { errors },
  } = useFormContext();

  const compactViewActionMap = useMemo(
    () =>
      availableActions.reduce((acc, availableAction) => {
        const { value, isCompactView, compactViewTitlePrefix } =
          availableAction || {};
        acc.set(value, {
          isCompactView,
          compactViewTitlePrefix,
        });
        return acc;
      }, new Map<string, { isCompactView?: boolean; compactViewTitlePrefix?: React.ReactNode }>()),
    [],
  );

  const currentActionView = compactViewActionMap.get(actionType);

  const compactViewTitle =
    currentActionView?.isCompactView &&
    currentActionView?.compactViewTitlePrefix ? (
      <div className="flex gap-3">
        {currentActionView.compactViewTitlePrefix}
        <span className="text-base font-semibold">{title}</span>
      </div>
    ) : undefined;

  return {
    compactViewTitle,
    isCompactView: currentActionView?.isCompactView && !!errors,
  };
};
