import { useCallback, useEffect } from "react";
import {
  HMSNotificationTypes,
  useHMSNotifications,
} from "@100mslive/react-sdk";
import { useLiveSettingsActions } from "../../store";
import { METADATA_TYPE, SYSTEM_MESSAGES, SYSTEM_TYPE } from "./constants";
import { useHMSLocalMetadata } from "./useHMSLocalMetadata";

// 100ms notifications handling
export const useHMSNotificationsListener = ({ onRoomUpdate, onEndStream }) => {
  const { setMicrophoneEnabled, setCameraEnabled } = useLiveSettingsActions();
  const hmsNotification = useHMSNotifications();
  const { setMetadata } = useHMSLocalMetadata();

  const onChangeTrackStateRequest = useCallback(
    data => {
      const { track, enabled } = data;
      if (track?.type === "audio") {
        setMicrophoneEnabled(enabled);
      } else if (track?.type === "video") {
        setCameraEnabled(enabled);
      }
    },
    [setCameraEnabled, setMicrophoneEnabled],
  );

  useEffect(() => {
    if (!hmsNotification) {
      return;
    }

    const { type, data } = hmsNotification;
    const { type: messageType, message, reason } = data || {};
    switch (type) {
      case HMSNotificationTypes.NEW_MESSAGE:
        if (
          messageType === SYSTEM_TYPE &&
          message === SYSTEM_MESSAGES.UPDATE_ROOM
        ) {
          onRoomUpdate?.();
        } else if (messageType === METADATA_TYPE) {
          const newMetadata = JSON.parse(message);
          if (newMetadata && typeof newMetadata === "object") {
            setMetadata(newMetadata);
          }
        }
        return;
      case HMSNotificationTypes.ROOM_ENDED:
        onEndStream?.(reason);
        return;
      case HMSNotificationTypes.CHANGE_MULTI_TRACK_STATE_REQUEST:
      case HMSNotificationTypes.CHANGE_TRACK_STATE_REQUEST: {
        // Note: Contents of data:
        // const { requestedBy, track, enabled } = data;
        onChangeTrackStateRequest(data);
        break;
      }
      default:
        // Ignore other notifications for now
        return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We only want to run when hmsNotification changes
  }, [hmsNotification, onChangeTrackStateRequest]);
};
