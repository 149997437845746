import type { Form } from "@/react/types/Form";
import {
  reactQueryDelete,
  reactQueryGet,
  reactQueryPatch,
  reactQueryPost,
} from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers/internalApi";

interface CreateParams {
  name: string;
}

interface IndexParams {
  page?: number;
  per_page?: number;
}

const create = (payload: CreateParams) =>
  reactQueryPost(internalApi.forms.create(), payload);

const index = (params: IndexParams) =>
  reactQueryGet(internalApi.forms.index(params));

const show = (id: number) => reactQueryGet(internalApi.forms.show(id));

const update = (payload: Form) =>
  reactQueryPatch(internalApi.forms.update(payload.id), payload);

const destroy = (formId: number) =>
  reactQueryDelete(internalApi.forms.destroy(formId));

const duplicate = (formId: number) =>
  reactQueryPost(internalApi.forms.duplicate(formId));

export const formsApi = {
  create,
  index,
  show,
  update,
  destroy,
  duplicate,
};
