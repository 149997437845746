import PropTypes from "prop-types";
import { BannerBlock } from "./BannerBlock";
import { CallToActionBlock } from "./CallToActionBlock";
import { DescriptionBlock } from "./DescriptionBlock";
import { LegacyLockScreenBlock } from "./LegacyLockScreenBlock";
import { LessonsBlock } from "./LessonsBlock";
import { PaywallsBlock } from "./PaywallsBlock";

const blockTypeToComponentMap = {
  banner: BannerBlock,
  description: DescriptionBlock,
  call_to_action: CallToActionBlock,
  lessons: LessonsBlock,
  legacy_lock_screen: LegacyLockScreenBlock,
  paywalls: PaywallsBlock,
};

export const BlockSelector = ({ block = {} }) => {
  const { type, data } = block;

  const BlockComponent = blockTypeToComponentMap[type];

  return <BlockComponent {...data} />;
};

BlockSelector.propTypes = {
  block: PropTypes.shape({
    type: PropTypes.string,
    id: PropTypes.string,
    hidden: PropTypes.bool,
    data: PropTypes.object,
  }),
};
