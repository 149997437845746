import classNames from "classnames";
import { t } from "i18n-js";
import ReactPlayer from "react-player/lazy";
import { Icon } from "@circle-react/components/shared/Icon";
import { TippyV2 } from "@circle-react/components/shared/TippyV2";

interface PreviewProps {
  url: string;
  type?: string;
  onRemove: () => void;
  disabled: boolean;
  isSpaceWelcomeBanner: boolean;
}

export const Preview = ({
  url,
  type = "image",
  onRemove,
  disabled,
  isSpaceWelcomeBanner,
}: PreviewProps) => {
  const hasImage = type.includes("image");

  return (
    <>
      {hasImage ? (
        <img
          loading="lazy"
          src={url}
          alt={t("cover_image")}
          className={classNames("w-full rounded-md", {
            "h-auto": !isSpaceWelcomeBanner,
            "h-[256px] object-cover": isSpaceWelcomeBanner,
          })}
        />
      ) : (
        <div className="h-auto rounded-md">
          <ReactPlayer url={url} width="100%" height="100%" controls />
        </div>
      )}
      {!disabled && (
        <TippyV2 content={t("home_page.remove_banner")}>
          <button
            type="button"
            className="border-primary bg-tertiary group absolute right-0 top-0 -m-2 flex h-[22px] w-[22px] cursor-pointer items-center justify-center rounded-full border"
            onClick={onRemove}
            aria-label={t("home_page.remove_banner")}
          >
            <Icon type="20-close-sm" aria-hidden size={20} />
          </button>
        </TippyV2>
      )}
    </>
  );
};
