import { formatPaywallCurrencyAmount } from "@/react/helpers/paywalls/currencies";
import { mapCurrencyAmountStrToInt } from "@circle-react/components/Paywalls/Admin/utils";
import { paywallPriceTypes } from "@circle-react/types";
import type {
  NoticeScenario,
  NoticeScenarioMatcher,
  NoticeScenarioMessagePropsResolver,
} from "../NoticeScenario.types";
import { onetimeStateValidator } from "./StateValidators";

const matcher: NoticeScenarioMatcher = ({ priceType }) =>
  priceType === paywallPriceTypes.onetime;
const messagePropsResolver: NoticeScenarioMessagePropsResolver = ({
  currency,
  watch,
}) => [
  "onetime",
  {
    total_amount: formatPaywallCurrencyAmount(
      currency,
      mapCurrencyAmountStrToInt(watch("amount")),
    ),
  },
];

export const onetime: NoticeScenario = {
  matcher,
  stateValidator: onetimeStateValidator,
  messagePropsResolver,
};
