import { isEventsSpace } from "@/react/helpers/spaceHelpers";

export const shouldDisableRightSidebar = ({
  postSlug,
  space,
  disableRightSidebar,
  currentCommunityMember,
  pinnedPosts,
  isHomePage,
}: any) => {
  // FIXME: Rework this logic
  if (isHomePage) return false;
  if (disableRightSidebar) return true;

  const hasVisibleBlocks =
    space?.visible_tabs?.members ||
    space?.visible_tabs?.past ||
    space?.visible_tabs?.upcoming ||
    pinnedPosts?.length > 0;

  if (!hasVisibleBlocks) return true;

  const isLockedSpace =
    space.is_private &&
    !space.is_space_member &&
    !currentCommunityMember?.is_admin;

  return postSlug && postSlug !== "members" && isEventsSpace(space)
    ? false
    : isLockedSpace
      ? true
      : space.hide_right_sidebar;
};
