import { Suspense } from "react";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { PageLoader } from "@circle-react/pages/PageLoader";

const EmailsSettingsComponent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "EmailsSettingsPage" */ "@circle-react/components/SettingsApp/Emails"
    ),
);

export const EmailsSettingsPage = () => (
  <Suspense fallback={<PageLoader />}>
    <EmailsSettingsComponent />
  </Suspense>
);
