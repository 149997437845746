import { useState } from "react";

const DEFAULT_PAGE_SIZE = 6;

export const usePaginatedPeers = (
  peers,
  startingPage = 1,
  pageSize = DEFAULT_PAGE_SIZE,
) => {
  const [page, setPage] = useState(startingPage);
  const startingPeerIndex = (page - 1) * pageSize;
  const currentPagePeers = peers?.slice(
    startingPeerIndex,
    startingPeerIndex + pageSize,
  );
  const totalPages = Math.ceil(peers?.length / pageSize);
  const next = () => setPage(Math.min(page + 1, totalPages));
  const prev = () => setPage(Math.max(page - 1, 1));
  const isFirstPage = page === 1;
  const isLastPage = page === totalPages;

  return {
    currentPagePeers,
    page,
    totalPages,
    next,
    prev,
    isFirstPage,
    isLastPage,
  };
};
