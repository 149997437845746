import { forwardRef } from "react";
import type { ComponentPropsWithRef } from "react";

interface DatePickerInputProps extends ComponentPropsWithRef<"input"> {
  value?: any;
  onChange?: any;
}

export const DatePickerInput = forwardRef<
  HTMLInputElement,
  DatePickerInputProps
>((props, ref) => (
  <input
    {...props}
    className="placeholder:text-light min-w-[18rem] border-none bg-transparent p-0 text-center text-xs font-medium leading-4 placeholder:opacity-100 focus:outline-none focus:ring-0"
    ref={ref}
  />
));

DatePickerInput.displayName = "DatePickerInput";
