import { useLivekitContext } from "../../VideoProvider/LivekitProvider";

// TODO: Implement livekit logic
export const useLivekitScreenSharing = () => {
  const { localParticipant, isSomeoneScreenSharing } = useLivekitContext();
  const isScreenSharingEnabled = !!localParticipant?.isScreenShareEnabled;
  const toggleScreenSharing = async () => {
    if (localParticipant) {
      await localParticipant.setScreenShareEnabled(!isScreenSharingEnabled, {
        audio: true,
        systemAudio: "include",
      });
    }
  };

  return {
    isScreenSharingEnabled,
    isSomeoneScreenSharing,
    toggleScreenSharing,
  };
};
