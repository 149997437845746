import { t } from "i18n-js";
import isEmpty from "lodash/isEmpty";
import { Typography } from "@circle-react-uikit/Typography";
import { SkeletonLoader } from "../SkeletonLoader";
import { SpaceGroupListItem } from "../SpaceGroupListItem";
import { useListSpaceGroups } from "../queries";

export const SpaceGroupList = () => {
  const { isLoading, data } = useListSpaceGroups();

  const spaceGroups = data?.records || [];

  if (isLoading) {
    return (
      <div className="space-y-3">
        <SkeletonLoader width="50px" />
        <SkeletonLoader rows={5} />
      </div>
    );
  }

  if (isEmpty(spaceGroups)) {
    return (
      <div className="px-6">
        <Typography.BodyMd>
          {t("support_widget.no_spaces_found")}
        </Typography.BodyMd>
      </div>
    );
  }

  return (
    <div className="mb-4 overflow-auto">
      <div className="mt-1.5">
        {spaceGroups.map(spaceGroup => (
          <SpaceGroupListItem spaceGroup={spaceGroup} key={spaceGroup.id} />
        ))}
      </div>
    </div>
  );
};
