import BasicInfo from "../../NewV2/NewForm/BasicInfo";
import EventOptions from "../../NewV2/NewForm/EventOptions";
import { NotificationTypePicker } from "../../NewV2/NewForm/NotificationTypePicker";
import VisibilityPicker from "../../NewV2/NewForm/VisibilityPicker";

export const Details = () => (
  <div className="edit-space__container">
    <BasicInfo />
    <VisibilityPicker />
    <NotificationTypePicker />
    <EventOptions />
  </div>
);
