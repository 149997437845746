import { useCallback } from "react";

export const useMemberAddressAttributes = (
  watch: any,
  setValue: any,
  isMemberAddressRequired: any,
  previewCheckout: any,
) =>
  useCallback(
    event => {
      const {
        community_member_billing_info_attributes: {
          address_country: currentCountry = "",
        } = {},
      } = watch();

      const { value, complete } = event;
      const { name, address } = value;
      const { line1, line2, city, state, postal_code, country } = address;

      // We set the form's name field to the value of the address element's name field
      // because we do not show the name field when the address element is present.
      setValue("name", name);

      setValue(
        "community_member_billing_info_attributes[address_line1]",
        line1,
      );
      setValue(
        "community_member_billing_info_attributes[address_line2]",
        line2,
      );
      setValue("community_member_billing_info_attributes[address_city]", city);
      setValue(
        "community_member_billing_info_attributes[address_state]",
        state,
      );
      setValue(
        "community_member_billing_info_attributes[address_postal_code]",
        postal_code,
      );
      setValue(
        "community_member_billing_info_attributes[address_country]",
        country,
      );

      if (currentCountry !== country) {
        setValue("community_member_billing_info_attributes[tax_id_type]", "");
        setValue("community_member_billing_info_attributes[tax_id_value]", "");
      }

      const isMemberAddressComplete = !isMemberAddressRequired || complete;
      setValue(
        "community_member_billing_info_address_attributes_complete",
        isMemberAddressComplete,
      );

      if (isMemberAddressComplete) {
        previewCheckout();
      }
    },
    [isMemberAddressRequired, setValue, watch, previewCheckout],
  );
