import { Suspense } from "react";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { PageLoader } from "@circle-react/pages/PageLoader";

const MemberNewInvitationLinksComponent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "MemberNewInvitationLinksPage" */ "@circle-react/components/SettingsApp/NewInvitationLink"
    ),
);

export const MemberNewInvitationLinksPage = () => (
  <Suspense fallback={<PageLoader />}>
    <MemberNewInvitationLinksComponent />
  </Suspense>
);
