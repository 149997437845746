import { availableActions } from "../Actions";
import type { Log } from "./HistoryAction";

const actionHistoryViewMap = availableActions.reduce((acc, action) => {
  acc.set(action.value, action.historyDetailViewComponent);
  return acc;
}, new Map());

interface HistoryActionMessageProps {
  log: Log;
  status: string;
}

export const HistoryActionMessage = ({
  log,
  status,
}: HistoryActionMessageProps) => {
  const { action } = log.message;
  const { body = {}, url = "" } = log.message.request_details || {};

  const { api_params: apiParams = {} } = action || {};
  const HistoryDetailViewComponent =
    actionHistoryViewMap.get(action?.action_type) || (() => null);

  return (
    <HistoryDetailViewComponent
      body={body}
      url={url}
      apiParams={apiParams}
      log={log}
      status={status}
    />
  );
};
