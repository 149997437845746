import { t } from "i18n-js";
import { Form } from "@circle-react-uikit/Form";
import { usePricingTabContext } from "../../hooks/usePricingTabContext";
import { PricingTabAddPriceModalAmountField } from "../AmountField";
import { PricingTabAddPriceModalBillingFrequencyChooser } from "../BillingFrequencyChooser";
import { PricingTabAddPriceModalInputWrapper } from "../InputWrapper";
import { PricingTabAddPriceModalFieldsUpfrontPaymentFields } from "../UpfrontPaymentFields";

export const PricingTabAddPriceModalFieldsInstallments = () => {
  const { priceBuildOptions } = usePricingTabContext();

  const buildOptions = priceBuildOptions.installments;

  const installmentsCountErrorMessage = t(
    "settings.paywalls.modal.tabs.pricing.add_price_modal.field_errors.installments_count",
    {
      min: buildOptions.limits.installments_count.min,
      max: buildOptions.limits.installments_count.max,
    },
  );

  return (
    <>
      <div className="flex flex-row gap-4">
        <PricingTabAddPriceModalBillingFrequencyChooser />
        <PricingTabAddPriceModalInputWrapper
          name="installments_count"
          labelKey="rebills"
          placeholder={t(
            "settings.paywalls.modal.tabs.pricing.add_price_modal.placeholder",
          )}
          rules={{
            valueAsNumber: true,
            required: true,
            validate: {
              bounds: value => {
                const valueInt = parseInt(value);
                const isValid =
                  !isNaN(valueInt) &&
                  valueInt >= buildOptions.limits.installments_count.min &&
                  valueInt <= buildOptions.limits.installments_count.max;
                return isValid || installmentsCountErrorMessage;
              },
            },
          }}
        >
          <Form.Input variant="text" type="number" />
        </PricingTabAddPriceModalInputWrapper>
      </div>
      <div className="flex flex-row gap-4">
        <PricingTabAddPriceModalAmountField
          name="amount"
          labelKey="total_price"
        />
      </div>
      <PricingTabAddPriceModalFieldsUpfrontPaymentFields />
    </>
  );
};
