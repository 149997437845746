import React from "react";
import classNames from "classnames";
import { BlockWithConnector as Block } from "@circle-react-shared/BlockWithConnector";
import { useItemData } from "@circle-react-shared/BlockWithConnectorItem/ItemDataProvider";
import { Actions } from "./Actions";
import { ButtonContent } from "./ButtonContent";

export interface BlockContentProps {
  title: string;
  isApplicableToMembersOnly?: boolean;
  hasSuccess: boolean;
  hasError: boolean;
  statusMessage: string;
  description: string;
  actions?: any[];
  isCompactView?: boolean;
  compactViewTitle?: React.ReactNode;
  children?: React.ReactNode;
  actionComponents: React.ReactNode;
  collapsedViewMetaComponent?: React.ReactNode;
}

export const BlockContent = ({
  title,
  isApplicableToMembersOnly,
  actions,
  hasSuccess,
  hasError,
  statusMessage,
  children,
  description,
  // isCompactView used in combination with compactViewTitle
  // hiding the description and showing different title when collapsed
  isCompactView,
  compactViewTitle,
  actionComponents,
  collapsedViewMetaComponent,
}: BlockContentProps) => {
  const { isOpen } = useItemData();

  const shouldShowCompactView = !!isCompactView && !isOpen;
  return (
    <Block.Content
      className={classNames({
        "!w-fit": shouldShowCompactView,
      })}
    >
      <div className="flex justify-between">
        <Block.Button className="pr-0">
          <ButtonContent
            title={shouldShowCompactView ? compactViewTitle : title}
            description={shouldShowCompactView ? undefined : description}
            isApplicableToMembersOnly={isApplicableToMembersOnly}
          />
        </Block.Button>
        <Actions
          actions={actions}
          hasSuccess={hasSuccess}
          hasError={hasError}
          statusMessage={statusMessage}
        >
          {actionComponents}
        </Actions>
      </div>
      {!isOpen && collapsedViewMetaComponent && (
        <div className="mx-8 border-t py-5">{collapsedViewMetaComponent}</div>
      )}
      <Block.Panel
        panelClassName={classNames("max-h-fit ease-in-out", {
          "h-0": !isOpen,
          "h-auto": isOpen,
        })}
      >
        {children}
      </Block.Panel>
    </Block.Content>
  );
};
