import { Link } from "react-router-dom";
import { usePunditUserContext } from "@circle-react/contexts";
import { t } from "@circle-react/custom_i18n";
import { Button } from "@circle-react-shared/uikit/Button";
import { Typography } from "@circle-react-shared/uikit/Typography";

export const LandingHeader = () => {
  const { currentCommunitySettings } = usePunditUserContext();
  const isV3Preview = Boolean(currentCommunitySettings?.circle_v3_preview);
  return (
    <div className="flex w-full flex-col items-center text-center">
      <Typography.TitleXl weight="font-bold" color="text-dark">
        {t("leaderboard.landing_page_title")}
      </Typography.TitleXl>
      <div className="mt-2">
        <Typography.TitleSm color="text-dark">
          {t("leaderboard.description")}
        </Typography.TitleSm>
      </div>
      <Link to="/settings/gamification">
        <Button large className="mt-8" variant="circle">
          {isV3Preview ? t("leaderboard.preview") : t("leaderboard.activate")}
        </Button>
      </Link>
    </div>
  );
};
