import PropTypes from "prop-types";
import classNames from "classnames";
import { usePunditUserContext } from "@circle-react/contexts";
import { useIsV3 } from "@circle-react/hooks/useIsV3";

export const Body = ({
  hasPaddingTopMobile = true,
  isMinimalLayout,
  children,
  className,
  wrapperClassName,
}) => {
  const { currentCommunitySettings } = usePunditUserContext();
  const isTailwindPostsEnabled =
    currentCommunitySettings?.tailwind_posts_enabled;
  const { isV3Enabled } = useIsV3();

  return (
    <div
      className={classNames(
        "main h-full",
        {
          "bg-secondary": !isMinimalLayout,
        },
        className,
      )}
    >
      <div
        className={classNames(
          "main__wrapper h-full sm:mx-6 md:pt-6",
          {
            "pt-0": !hasPaddingTopMobile,
            "px-4 sm:px-0": isMinimalLayout,
            "post-v2": !isTailwindPostsEnabled,
            "lg:mx-6": !isV3Enabled,
            "lg:mx-9": isV3Enabled,
          },
          wrapperClassName,
        )}
      >
        {children}
      </div>
    </div>
  );
};

Body.propTypes = {
  children: PropTypes.node,
  hasPaddingTopMobile: PropTypes.bool,
  isMinimalLayout: PropTypes.bool,
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
};
