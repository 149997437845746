import type { UseQueryOptions } from "react-query";
import { useQuery } from "react-query";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";

interface MarketingHubStatusCircuitBreakers {
  broadcasts: boolean;
}

export interface MarketingHubStatusNonAdminData {
  enabled: boolean;
  circuit_breakers: MarketingHubStatusCircuitBreakers;
}

export interface MarketingHubStatusAdminData {
  enabled: boolean;
  trialing: boolean;
  lapsed: boolean;
  show_limits_notice: boolean;
  plan: {
    cancel_at_period_end: boolean;
    current_period_end: string;
    status: string;
  };
  limits: {
    marketing_email_limit_reached: boolean;
    marketing_email_limit: number;
    marketing_email_usage_in_period: number;
    marketing_email_limit_period_in_hours: number;
    allowance: number;
  };
  sender_assessments: {
    state: "normal" | "warning" | "suspend" | "blocked" | "flagged";
    allow_sending: boolean;
  };
  circuit_breakers: MarketingHubStatusCircuitBreakers;
}

export const MARKETING_HUB_STATUS_QUERY_KEY = "marketing-hub-status";

export const isAdminData = (
  data:
    | MarketingHubStatusAdminData
    | MarketingHubStatusNonAdminData
    | undefined,
): data is MarketingHubStatusAdminData => !!data && "limits" in data;

export const useGetMarketingHubStatus = (
  options?: UseQueryOptions<
    MarketingHubStatusAdminData | MarketingHubStatusNonAdminData,
    unknown
  >,
) =>
  useQuery<MarketingHubStatusAdminData | MarketingHubStatusNonAdminData>({
    queryKey: MARKETING_HUB_STATUS_QUERY_KEY,
    queryFn: () => reactQueryGet(internalApi.marketingHubStatus.show()),
    ...options,
    notifyOnChangeProps: "tracked",
  });
