import { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { t } from "i18n-js";
import { isFunction } from "lodash";
import { useFormContext } from "react-hook-form";
import { BrandedApp } from "@circle-react-shared/BrandedApp";
import { Layout, useCustomizeUIData } from "@circle-react-shared/CustomizeUI";
import { LockscreenPreview } from "./Preview";
import { usePaywallBlockContext } from "./Providers/PaywallBlockProvider";
import { Sidebar } from "./Sidebar";
import { parseSubmitData } from "./parseSubmitData";

export const LockscreenView = ({ space = {}, onSave }) => {
  const { formId, addBeforeExit, removeBeforeExit } = useCustomizeUIData();
  const { handleSubmit, formState, reset } = useFormContext();

  const { dirtyFields } = formState;
  const hasDirtyFields = Object.keys(dirtyFields).length > 0;
  const makeFormNotDirty = () => reset(null, { keepValues: true });

  const { shouldShowBrandedAppBanner } = usePaywallBlockContext();

  const beforeExit = useCallback(() => {
    if (hasDirtyFields) {
      return window.confirm(t("courses.lockscreen.modal.unsaved_changes"));
    }
    return true;
  }, [hasDirtyFields]);

  useEffect(() => {
    addBeforeExit(beforeExit);
    return removeBeforeExit;
  }, [removeBeforeExit, addBeforeExit, beforeExit]);

  const handleSave = data => {
    if (!isFunction(onSave)) return;
    const parsedData = parseSubmitData(data);
    onSave(parsedData);
    makeFormNotDirty();
  };

  return (
    <>
      <Layout.Sidebar>
        <form
          id={formId}
          className="react-form h-full w-full"
          onSubmit={handleSubmit(handleSave)}
        >
          <Sidebar />
        </form>
      </Layout.Sidebar>
      {shouldShowBrandedAppBanner ? (
        <BrandedApp.LandingPage />
      ) : (
        <Layout.Main>
          <LockscreenPreview space={space} />
        </Layout.Main>
      )}
    </>
  );
};

LockscreenView.propTypes = {
  space: PropTypes.object,
  courseSettings: PropTypes.object,
  onSave: PropTypes.func,
  debug: PropTypes.bool,
};
