import { useState } from "react";
import { t } from "i18n-js";
import { isEmpty } from "lodash";
import { useQuery } from "react-query";
import { internalApi } from "@/react/helpers/urlHelpers";
import { useAddSpaceModal } from "@circle-react/components/Modals/AddSpaceModal";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";
import { DataTable } from "@circle-react-shared/uikit/DataTableV2";
import { EmptyState } from "@circle-react-shared/uikit/EmptyState";
import { Loader } from "@circle-react-uikit/Loader";
import { Switch } from "@circle-react-uikit/Switch";
import { Typography } from "@circle-react-uikit/Typography";
import { SpaceNameCell } from "./SpaceNameCell";
import { SpacesList } from "./SpacesList";

interface SpaceProp {
  id: number;
  name: string;
  posts_count: number;
  created_at: string;
  updated_at: string;
}

interface SpaceResponse {
  records: Array<SpaceProp>;
  count: number;
}

const columns = [
  {
    header: t("settings.community_bot.knowledge.spaces.columns.name"),
    accessorKey: "name",
    cell: ({ row }: any) => <SpaceNameCell name={row.original?.name} />,
    canSort: false,
    cellClassName: "w-1/2",
  },
  {
    header: t("settings.community_bot.knowledge.spaces.columns.posts_included"),
    accessorKey: "posts_count",
    cell: ({ row }: any) => row.original?.posts_count,
    canSort: false,
  },
  {
    header: t(
      "settings.community_bot.knowledge.spaces.columns.used_in_replies",
    ),
    accessorKey: "used_in_replies",
    cell: () => 0,
    canSort: false,
  },
];

export const Spaces = () => {
  const [currentPage, setPage] = useState(1);
  const spaceModal = useAddSpaceModal();
  const spacesQuery = useQuery<SpaceResponse>({
    queryKey: ["internal_api", "community_bot", "spaces"],
    queryFn: () =>
      reactQueryGet(
        internalApi.communityBot.spaces.index({
          params: {
            page: currentPage,
            per_page: 10,
          },
        }),
      ),
  });

  const onChangePage = (page: number) => {
    setPage(page);
    spacesQuery.remove();
  };

  const spacesCount = spacesQuery?.data?.count || 0;
  const hasNoResults = isEmpty(spacesQuery?.data?.records || []);

  if (spacesQuery?.isLoading) {
    return <Loader />;
  }

  if (hasNoResults) {
    return (
      <div className="bg-primary flex h-full flex-col">
        <EmptyState
          header={t(
            "settings.community_bot.knowledge.community.empty_state.header",
          )}
          description={t(
            "settings.community_bot.knowledge.community.empty_state.description",
          )}
          primaryLabel={t(
            "settings.community_bot.knowledge.community.empty_state.primary_label",
          )}
          onPrimaryClick={() => spaceModal.show()}
        />
      </div>
    );
  }

  return (
    <>
      {spacesCount > 0 && (
        <div className="px-6 pb-1 pt-5">
          <div className="flex min-h-[1.75rem] items-center gap-4">
            <Typography.LabelMd weight="semibold">
              {t("settings.community_bot.knowledge.spaces.count", {
                count: spacesCount,
              })}
            </Typography.LabelMd>
          </div>
          <div className="mt-4 flex items-center gap-4 align-middle text-sm">
            <Switch as="span" checked={false} onChange={() => {}} />
            <span>
              {t("settings.community_bot.knowledge.spaces.use_all_spaces")}
            </span>
          </div>
        </div>
      )}
      <DataTable.TableWrapper>
        <div className="mt-0.5">
          <SpacesList
            columns={columns}
            spacesQuery={spacesQuery}
            onChangePage={onChangePage}
          />
        </div>
      </DataTable.TableWrapper>
    </>
  );
};
