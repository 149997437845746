import PropTypes from "prop-types";
import classNames from "classnames";

export const SpaceFeedWrapper = ({ children, className }) => (
  <div className={classNames("react-page-space-show h-full w-full", className)}>
    {children}
  </div>
);

SpaceFeedWrapper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
