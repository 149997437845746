import { Suspense } from "react";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { PageLoader } from "@circle-react/pages/PageLoader";

const MemberEditInvitationLinksComponent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "MemberEditInvitationLinksPage" */ "@circle-react/components/SettingsApp/EditInvitationLink"
    ),
);

export const MemberEditInvitationLinksPage = () => (
  <Suspense fallback={<PageLoader />}>
    <MemberEditInvitationLinksComponent />
  </Suspense>
);
