import { Suspense } from "react";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { PageLoader } from "@circle-react/pages/PageLoader";

const LiveMobileComponent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "LiveMobilePage" */ "@circle-react/components/LiveStreams/Room/MobileRoom"
    ),
);

export const LiveMobilePage = () => (
  <Suspense fallback={<PageLoader />}>
    <LiveMobileComponent />
  </Suspense>
);
