import { QueryClient, QueryClientProvider } from "react-query";
import { ModalManagerProvider } from "@circle-react/components/Layout/ModalManagerProvider";
import { reactQueryConfig } from "@circle-react/providers/QueryClient/reactQueryConfig";
import { ToastProvider } from "@circle-react-uikit/ToastV2";
import { List } from "./List";

const queryClient = new QueryClient(reactQueryConfig);

export const PaywallGroups = () => (
  <ToastProvider>
    <QueryClientProvider client={queryClient}>
      <ModalManagerProvider>
        <List />
      </ModalManagerProvider>
    </QueryClientProvider>
  </ToastProvider>
);

PaywallGroups.propTypes = {};
