import PropTypes from "prop-types";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastProvider } from "@circle-react-uikit/ToastV2";
import { reactQueryConfig } from "../../../../providers/QueryClient/reactQueryConfig";
import { Records } from "./Records";
import "./style.scss";

const queryClient = new QueryClient(reactQueryConfig);

export const List = ({ canCreate }) => (
  <ToastProvider>
    <QueryClientProvider client={queryClient}>
      <Records canCreate={canCreate} />
    </QueryClientProvider>
  </ToastProvider>
);

List.propTypes = {
  canCreate: PropTypes.bool.isRequired,
};
