import { useState } from "react";
import PropTypes from "prop-types";
import { truncate } from "lodash";
import { Icon } from "@circle-react/components/shared/Icon";
import { LabelSm } from "@circle-react/components/shared/uikit/Typography/components/Label/LabelSm";
import { LabelXs } from "@circle-react/components/shared/uikit/Typography/components/Label/LabelXs";

export const AttachmentItem = ({ attachment }) => {
  const thumbnailUrl = attachment.thumbnails?.[0]?.content_url;
  const [showThumbnail, setShowThumbnail] = useState(!!thumbnailUrl);

  return (
    <div>
      <a
        href={attachment.content_url}
        target="_blank"
        rel="noreferrer"
        className="flex gap-2 rounded-lg border border-gray-300 p-3 dark:border-gray-600"
        download
      >
        {showThumbnail ? (
          <img
            loading="lazy"
            alt=""
            className="h-10 w-10 rounded-lg object-cover"
            src={thumbnailUrl}
            onError={() => setShowThumbnail(false)}
          />
        ) : (
          <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-gray-100 object-cover text-gray-400 dark:bg-gray-700">
            <Icon type="tiptap-paperclip" size={20} />
          </div>
        )}
        <div className="flex flex-col">
          <LabelSm weight="semibold" className="break-all">
            {truncate(attachment.file_name, { length: 28 })}
          </LabelSm>
          <LabelXs>
            {truncate(attachment.content_type.split("/").pop().toUpperCase(), {
              length: 28,
            })}
          </LabelXs>
        </div>
      </a>
    </div>
  );
};

AttachmentItem.propTypes = {
  attachment: PropTypes.object,
};
