import { useEffect } from "react";
import { t } from "i18n-js";
import { useLiveStreamContext } from "@circle-react/contexts/LiveStreams";
import { useToast } from "@circle-react-uikit/ToastV2";

export const useRoomNotFound = () => {
  const {
    data: { room },
    status: { isRoomError, apiError },
  } = useLiveStreamContext();
  const { error } = useToast();

  useEffect(() => {
    if (apiError?.status === 401) {
      error(t("live_streams.room.authorization_error"));
      window.location.href = "/";
    }
    if (apiError?.status !== 401 && isRoomError && !room) {
      window.location.href = "/";
    }
  }, [room, isRoomError, apiError]);
};
