import { t } from "i18n-js";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { SupportedCurrencyChooser } from "@circle-react/components/SupportedCurrencyChooser";
import { Button } from "@circle-react-uikit/Button";
import { Form } from "@circle-react-uikit/Form";
import { Modal } from "@circle-react-uikit/Modal";
import { usePaywallGroup } from "../hooks/usePaywallGroup";
import "./styles.scss";

const PaywallGroupsFormModalComponent = ({
  paywallGroup = null,
  refetchPaywallGroups = null,
}: any) => {
  const modal = useModal();

  const { getFormDefaults, mutation, editionMode }: any = usePaywallGroup({
    modal: modal,
    refetchPaywallGroups: refetchPaywallGroups,
    paywallGroup: paywallGroup,
  });

  return (
    <Modal
      show={modal.visible}
      title={
        editionMode
          ? t("settings.paywall_groups.headings.edit")
          : t("settings.paywall_groups.headings.create")
      }
      onClose={modal.hide}
    >
      <Form
        onSubmit={mutation.mutate}
        defaultValues={getFormDefaults()}
        className="paywall-group-form"
      >
        {({ formState: { isSubmitting } }) => (
          <>
            <Form.Item
              name="name"
              translationRoot="settings.paywall_groups.fields"
              hideBorder
              hideDescription
              fullWidth={false}
              rules={{
                required: true,
                maxLength: 255,
              }}
              hidePlaceholder
            >
              <Form.Input />
            </Form.Item>
            {(!paywallGroup || paywallGroup?.policies?.requires_currency) && (
              <SupportedCurrencyChooser
                formItemName="currency_id"
                formItemTranslationRoot="settings.paywall_groups.fields"
                formItemRules={{
                  required: t(
                    "settings.paywall_groups.fields.currency_id_is_required",
                  ),
                }}
                formItemTooltipText={t(
                  "settings.paywall_groups.fields.currency_id_tooltip",
                )}
                disabled={
                  paywallGroup && !paywallGroup.policies.can_change_currency
                }
              />
            )}
            <Button
              type="submit"
              variant="circle"
              disabled={isSubmitting || mutation.isLoading}
              className="paywall-group-form__submit-button"
            >
              {editionMode
                ? t("settings.paywall_groups.actions.update")
                : t("settings.paywall_groups.actions.create")}
            </Button>
          </>
        )}
      </Form>
    </Modal>
  );
};

const PaywallsAdminFormModal = ModalManager.create(
  ({ paywallGroup, refetchPaywallGroups }: any) => (
    <PaywallGroupsFormModalComponent
      paywallGroup={paywallGroup}
      refetchPaywallGroups={refetchPaywallGroups}
    />
  ),
);

export const usePaywallGroupFormModal = () => useModal(PaywallsAdminFormModal);
