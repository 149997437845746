export const serializePaywallJson = (paywall: any) => {
  const filterPaywallPrices = (priceAttribute: any) => {
    if (priceAttribute.tmp_id) {
      return priceAttribute;
    }
    if (priceAttribute._destroy) {
      return { id: priceAttribute.id, _destroy: 1 };
    }
    if (priceAttribute._updated) {
      return { id: priceAttribute.id, preferred: priceAttribute.preferred };
    }

    return null;
  };

  const paywallGroupField = {
    paywall_group_id: paywall?.enable_subscription_group
      ? paywall.paywall_group_id
      : "",
  };

  return {
    publish: paywall.publish,
    paywall: {
      ...paywall,
      ...paywallGroupField,
      stripe_prices_attributes: paywall.stripe_prices_attributes
        .map(filterPaywallPrices)
        .filter((e: any) => e),
    },
  };
};
