import { DropdownMenu } from "./DropdownMenu";

const CHAT_SORT_VALUES = {
  OLDEST: "oldest",
  LATEST: "latest",
} as const;

const CHAT_TYPES = {
  ALL: "all",
  AI_PAUSED: "ai_paused",
} as const;

export const Header = () => (
  <div className="bg-primary sticky top-0 z-10 mb-2 mt-1 flex gap-2">
    <DropdownMenu
      className="w-[40%]"
      items={Object.values(CHAT_TYPES)}
      activeItem={CHAT_TYPES.ALL}
      localeScope="community_inbox.filters"
      onItemClick={() => {}}
    />
    <DropdownMenu
      className="grow"
      items={Object.values(CHAT_SORT_VALUES)}
      activeItem={CHAT_SORT_VALUES.LATEST}
      localeScope="community_inbox.filters"
      onItemClick={() => {}}
    />
  </div>
);
