import { t } from "i18n-js";
import { usePunditUserContext } from "@circle-react/contexts";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { useToast } from "@circle-react-uikit/ToastV2";

export const useDownloadCSV = roomId => {
  const { success, error } = useToast();
  const { currentCommunityMember } = usePunditUserContext();
  const email = currentCommunityMember?.email;

  const onDownloadCSV = () =>
    fetch(
      internalApi.liveStreams.downloadParticipantsCSV({
        roomId: roomId,
      }),
    )
      .then(res => {
        if (res.ok) {
          success(t("export_member_csv.message", { email }));
        } else {
          error(t("export_member_csv.error_message"));
        }
      })
      .catch(err => {
        error(t("export_member_csv.error_message"));
        console.error(err?.message);
      });

  return {
    onDownloadCSV,
  };
};
