import PropTypes from "prop-types";
import classnames from "classnames";
import { useRouteMatch } from "react-router-dom";
import { AppBar } from "@/react/components/Layout/AppBar";
import { BrowserNotificationPrompt } from "@/react/components/Layout/BrowserNotificationPrompt";
import { MobileBottomToolbar } from "@/react/components/Layout/MobileBottomToolbar";
import { Body } from "@/react/components/Layout/StandardLayout/Body";
import { Breadcrumb } from "@/react/components/Layout/StandardLayout/Breadcrumb";
import { Content } from "@/react/components/Layout/StandardLayout/Content";
import { Header } from "@/react/components/Layout/StandardLayout/Header";
import { Left } from "@/react/components/Layout/StandardLayout/Left";
import { Right } from "@/react/components/Layout/StandardLayout/Right";
import { SideBar } from "@/react/components/Spaces/Sidebar";
import { isEventsSpace } from "@/react/helpers/spaceHelpers";
import { useAppendBodyClasses } from "@/react/hooks/useAppendBodyClasses";
import { useLgScreenMediaQuery } from "@/react/hooks/useMediaQuery";
import { usePunditUserContext, useSpacesContext } from "@circle-react/contexts";
import { useLayoutPartialsContext } from "@circle-react/contexts/layoutPartialsContext";
import { MobileDrawer } from "../MobileDrawer";
import "./styles.scss";

const shouldDisableRightSidebar = ({
  postSlug,
  space,
  disableRightSidebar,
}) => {
  if (disableRightSidebar) return true;

  return postSlug && postSlug !== "members" && isEventsSpace(space)
    ? false
    : space.hide_right_sidebar;
};

export const StandardLayout = ({
  disableSidebar = false,
  disableRightSidebar = false,
  children,
  bodyClassNames = "",
  renderLeftSidebar = null,
  hideAppBar = false,
}) => {
  const { currentCommunityMember } = usePunditUserContext();
  const containerProps = {};
  const { data: { mobile_drawer_html } = {} } = useLayoutPartialsContext();
  if (!disableSidebar && mobile_drawer_html) {
    containerProps["data-controller"] = "mobile-sidebar";
  }
  useAppendBodyClasses(
    classnames("is-standard-layout react-standard-layout", bodyClassNames),
  );
  const isLgScreen = useLgScreenMediaQuery();
  const { params: { spaceSlug } = {} } = useRouteMatch("/c/:spaceSlug") || {};
  const { params: { postSlug } = {} } =
    useRouteMatch("/c/:spaceSlug/:postSlug") || {};
  const isHomePage = useRouteMatch("/home")?.isExact;
  const isFeedPage = useRouteMatch("/feed")?.isExact;
  const isSpaceShowPage = useRouteMatch("/c/:spaceSlug")?.isExact;

  const space = useSpacesContext().helpers.findBy({ slug: spaceSlug }) || {};
  // TODO: refactor so that we rely on child props to set this value.
  const disableLayoutRightSidebar = shouldDisableRightSidebar({
    disableRightSidebar,
    postSlug,
    space,
  });

  const showMobileBottomToolbar = Boolean(
    !!currentCommunityMember &&
      !isLgScreen &&
      !!(isHomePage || isFeedPage || isSpaceShowPage),
  );

  return (
    <div className="community__wrapper bg-secondary" {...containerProps}>
      <div id="mobile-dropdown-container" />
      {/*TODO Add support for mobile menu and also add condition based on screensize*/}
      {!disableSidebar && (
        <MobileDrawer renderLeftSidebar={renderLeftSidebar} />
      )}
      {/*TODO: REACTIFY revisit to take full advantage of react/router/context */}
      <div
        id="panel"
        className={classnames("community__content trix-v2", {
          "community__content--no-sidebar": disableSidebar,
        })}
        data-target="mobile-sidebar.panel"
      >
        {!disableSidebar && !hideAppBar && <AppBar />}
        <div
          className={classnames("standard-layout", {
            "has-sidebar": !disableSidebar,
            "no-right-sidebar": disableLayoutRightSidebar,
          })}
        >
          {/*TODO: Pass it from outside*/}
          {!disableSidebar && (
            <Left>{renderLeftSidebar ? renderLeftSidebar() : <SideBar />}</Left>
          )}
          {children}
          <BrowserNotificationPrompt />
          {showMobileBottomToolbar && <MobileBottomToolbar />}
        </div>
      </div>
    </div>
  );
};
StandardLayout.Header = Header;
StandardLayout.Breadcrumb = Breadcrumb;
StandardLayout.Content = Content;
StandardLayout.Body = Body;
StandardLayout.Left = Left;
StandardLayout.Right = Right;

StandardLayout.propTypes = {
  bodyClassNames: PropTypes.string,
  children: PropTypes.any.isRequired,
  disableRightSidebar: PropTypes.bool,
  disableSidebar: PropTypes.bool,
  renderLeftSidebar: PropTypes.func,
  hideAppBar: PropTypes.bool,
};
