import PropTypes from "prop-types";
import classNames from "classnames";
import { t } from "i18n-js";
import { useMobileWebFlags } from "@circle-react/hooks/useMobileWebFlags";
import { useCustomizeUIData } from "@circle-react-shared/CustomizeUI";
import { SaveButton } from "@circle-react-shared/CustomizeUI/Header/SaveButton";
import { Button } from "@circle-react-uikit/Button";
import { Typography } from "@circle-react-uikit/Typography";
import { useDirtyFields } from "./hooks/useDirtyFields";

export const Footer = () => {
  const { onExit, isSaving, formId, isSaveButtonDisabled } =
    useCustomizeUIData();
  const { isDirty, resetDirtyFields } = useDirtyFields();
  const { isMobileWebNewSpaceCustomizationUIEnabled } = useMobileWebFlags();

  const handleExit = () => {
    if (isDirty) {
      resetDirtyFields();
    }
    onExit();
  };

  return (
    <div className="flex w-full flex-col items-start justify-between gap-y-2 md:flex-row md:items-center md:gap-y-0">
      <Typography.LabelSm weight="semibold">
        {isDirty && t("unsaved_changes")}
      </Typography.LabelSm>
      <div
        className={classNames({
          "grid w-full grid-cols-2 gap-4":
            isMobileWebNewSpaceCustomizationUIEnabled,
          "flex gap-4": !isMobileWebNewSpaceCustomizationUIEnabled,
        })}
      >
        <Button
          variant="secondary"
          onClick={handleExit}
          full={isMobileWebNewSpaceCustomizationUIEnabled}
        >
          {t("cancel")}
        </Button>
        <SaveButton
          full={isMobileWebNewSpaceCustomizationUIEnabled}
          type="submit"
          formId={formId}
          disabled={isSaving || isSaveButtonDisabled}
          variant="circle"
        >
          {t("save_changes")}
        </SaveButton>
      </div>
    </div>
  );
};

Footer.propTypes = {
  onSave: PropTypes.func.isRequired,
};
