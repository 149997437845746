import { useMemo } from "react";
import { t } from "i18n-js";
import * as yup from "yup";
import { formattedDateTime } from "@circle-react/helpers/dateTimeHelpers/formattedDateTime";
import { Modal } from "@circle-react-shared/uikit/ModalV2";
import { Form } from "@circle-react-uikit/Form";
import { CreateSchedule } from "./CreateSchedule";

export interface ScheduleFormData {
  frequency: string;
  ends_on: "on" | "never";
  first_run_at: string;
  last_run_at: string;
  next_run_at?: string;
  days_of_week: string[];
  has_schedule_run_before: boolean;
}

interface ModalCreateScheduleProps {
  schedule: {
    isReschedule: boolean;
    first_run_at: string;
    last_run_at: string;
    next_run_at: string;
    frequency: string;
    days_of_week: string;
    do_not_skip_tasks: boolean;
    has_run_but_not_completed: boolean;
    id: string;
  };
  formState?: ScheduleFormData | null;
  onCancel: () => void;
  onSubmit: (data: any) => void;
}

const i18nBase = "settings.workflows.recurring_schedule_modal";

export const ModalCreateSchedule = ({
  schedule,
  formState,
  onSubmit,
  onCancel,
}: ModalCreateScheduleProps) => {
  const { isReschedule } = schedule;

  const getDefaultValue = (): any => {
    if (isReschedule) {
      return {
        frequency: "weekly",
        days_of_week: [],
        ends_on: "never",
      };
    }

    if (formState) {
      return formState;
    }

    return {
      frequency: schedule?.frequency || "weekly",
      days_of_week: schedule?.days_of_week || [],
      ends_on: schedule?.last_run_at ? "on" : "never",
      first_run_at: schedule?.first_run_at,
      last_run_at: schedule?.last_run_at,
      next_run_at: schedule?.next_run_at,
      has_schedule_run_before: schedule?.has_run_but_not_completed,
    };
  };

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        first_run_at: yup.lazy(() =>
          schedule?.has_run_but_not_completed
            ? yup.date().required()
            : yup
                .date()
                .required()
                .min(
                  new Date(),
                  t(
                    [
                      "settings.workflows.one_time_schedule_modal",
                      "past_error",
                    ],
                    {
                      currentDateTime: formattedDateTime({
                        dateTime: new Date(),
                        format: "date_time_with_short_timezone",
                      }),
                    },
                  ),
                ),
        ),
      }),
    [schedule?.has_run_but_not_completed],
  );

  return (
    <Modal.Content onClose={onCancel}>
      <Modal.Header>
        <Modal.Title className="text-center">
          {t(`${i18nBase}.title`)}
        </Modal.Title>
        <Modal.CloseButton onClick={onCancel} />
      </Modal.Header>
      <Modal.Body className="pb-6">
        <Form
          defaultValues={getDefaultValue()}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          <CreateSchedule />
        </Form>
      </Modal.Body>
    </Modal.Content>
  );
};
