import classnames from "classnames";
import { t } from "i18n-js";

const TH_CLASS_NAMES = "uppercase !px-0 !text-default !text-xxs !text-light";
const TD_CLASS_NAMES = "h-14 group px-0 text-default";

export const COLUMN_NAMES = [
  {
    dataIndex: "display_name",
    key: "display_name",
    title: t("customize_space.paywalls.table_columns.paywall"),
    shouldWrap: true,
    className: TH_CLASS_NAMES,
    tdClassName: classnames(TD_CLASS_NAMES, "!text-dark !font-medium"),
  },
  {
    dataIndex: "unlocked_by",
    key: "unlocked_by",
    title: t("customize_space.paywalls.table_columns.unlocked_by"),
    shouldWrap: true,
    className: TH_CLASS_NAMES,
    tdClassName: classnames(TD_CLASS_NAMES, "!font-medium"),
  },
  {
    dataIndex: "pricing",
    key: "pricing",
    title: t("customize_space.paywalls.table_columns.pricing"),
    className: TH_CLASS_NAMES,
    tdClassName: TD_CLASS_NAMES,
  },
  {
    dataIndex: "status",
    key: "status",
    title: t("customize_space.paywalls.table_columns.status"),
    className: TH_CLASS_NAMES,
    tdClassName: TD_CLASS_NAMES,
  },
  {
    dataIndex: "actions",
    key: "actions",
    title: " ",
    className: classnames(TD_CLASS_NAMES, "w-2 !text-right"),
    tdClassName: classnames(TD_CLASS_NAMES, "w-2 !text-right !py-0"),
  },
];
