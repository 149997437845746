import { format } from "date-fns";
import { useFormContext } from "react-hook-form";
import { DefaultOptionsContainer } from "./DefaultOptionsContainer";
import { useGetMessage } from "./useGetMessage";

export function EveryMonthOptions() {
  const { watch } = useFormContext();
  const firstRun = watch("first_run_at");
  const message = useGetMessage("every_month_on_until", "every_month_on");

  return (
    <DefaultOptionsContainer
      i18n={["settings.workflows.recurring_schedule_modal", message]}
      variables={{
        day_of_month: firstRun ? format(new Date(firstRun), "do") : null,
      }}
    />
  );
}
