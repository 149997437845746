import { useEffect, useState } from "react";
import { t } from "i18n-js";
import { isScheduledCourseSpace } from "@/react/helpers/courseHelpers";
import { useCourseSections } from "@/react/hooks/courses/useCoursesApi";
import type { CoursesSection } from "@/react/types/CoursesSection";
import type { Space } from "@/react/types/Space";
import { formattedDateTime } from "@circle-react/helpers/dateTimeHelpers/formattedDateTime";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { Switch } from "@circle-react-uikit/Switch";
import { useToast } from "@circle-react-uikit/ToastV2";
import { NotifyStudentsEmailModal } from "./NotifyStudentsEmailModal";
import { useNotifyStudentsEnabledMutation } from "./hooks";

interface NotifyStudentsToggleProps {
  section: CoursesSection;
  space: Space;
}

const TRANSLATION_ROOT = "courses.edit.sections.notify_students_modal.form";

export const NotifyStudentsToggle = ({
  section,
  space,
}: NotifyStudentsToggleProps) => {
  const [isNotifyStudentsEnabled, setIsNotifyStudentsEnabled] = useState(
    Boolean(section.notify_students_enabled),
  );
  const [isNotifyStudentsEmailModalOpen, setIsNotifyStudentsEmailModalOpen] =
    useState(false);
  const [
    isNotifyStudentsEmailModalOpenForEdit,
    setIsNotifyStudentsEmailModalOpenForEdit,
  ] = useState(false);
  const { success, error } = useToast();
  const { refetch: refetchCourseSections } = useCourseSections(
    section.space_id,
  );
  const { changeNotifyStudentsEnabledMutation } =
    useNotifyStudentsEnabledMutation({
      section,
      options: {
        onSuccess: async () => {
          await refetchCourseSections();
          success(t(`${TRANSLATION_ROOT}.save_success`), {
            duration: "short",
            shouldUseProgress: false,
          });
        },
        onError: () => {
          error(t(`${TRANSLATION_ROOT}.save_error`));
          setIsNotifyStudentsEnabled(true);
        },
      },
    });

  useEffect(() => {
    setIsNotifyStudentsEnabled(Boolean(section.notify_students_enabled));
  }, [section.notify_students_enabled]);

  const handleChange = (checked: any) => {
    if (checked) {
      setIsNotifyStudentsEmailModalOpen(true);
    } else {
      setIsNotifyStudentsEnabled(false);
      changeNotifyStudentsEnabledMutation.mutate({
        course_section: { notify_students_enabled: false },
      });
    }
  };

  const notificationsSentAt =
    section.drip_notifications_sent_at &&
    formattedDateTime({
      dateTime: new Date(section.drip_notifications_sent_at),
      format: "date_time_with_short_timezone",
    });

  let tooltipContent;
  if (isScheduledCourseSpace(space)) {
    if (notificationsSentAt) {
      tooltipContent = t(
        "courses.edit.sections.notify_students_modal.sent_tooltip",
        {
          date: notificationsSentAt,
        },
      );
    } else if (section.is_dripped) {
      tooltipContent = t(
        "courses.edit.sections.notify_students_modal.delayed_notification_tooltip",
      );
    }
  }

  return (
    <>
      <div className="group flex items-center">
        <input
          type="checkbox"
          name={`section[${section.id}].notify_students_enabled`}
          checked={isNotifyStudentsEnabled}
          className="hidden"
        />
        <TippyV2 disabled={!tooltipContent} content={tooltipContent}>
          <Switch
            checked={isNotifyStudentsEnabled}
            onChange={checked => handleChange(checked)}
            disabled={isScheduledCourseSpace(space) && !!notificationsSentAt}
          />
        </TippyV2>
        {!(isScheduledCourseSpace(space) && notificationsSentAt) && (
          <button
            type="submit"
            className="hover:bg-tertiary invisible ml-3 rounded-md px-2 py-1 group-hover:visible"
            onClick={() => setIsNotifyStudentsEmailModalOpenForEdit(true)}
          >
            {t("edit")}
          </button>
        )}
      </div>
      <NotifyStudentsEmailModal
        isOpen={isNotifyStudentsEmailModalOpen}
        isOpenForEdit={isNotifyStudentsEmailModalOpenForEdit}
        onClose={() => {
          setIsNotifyStudentsEmailModalOpen(false);
          setIsNotifyStudentsEmailModalOpenForEdit(false);
        }}
        section={section}
        shouldShowNotificationMessage={!!tooltipContent}
      />
    </>
  );
};
