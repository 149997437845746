import { useIsCustomizeCommunityAvailable } from "@circle-react/hooks/useIsCustomizeCommunity";
import { useThemeContext } from "@circle-react/providers";

export interface UseGetSidebarLinkClassProps {
  getActiveInactiveClasses: (
    isActive: boolean,
    isInvokedFromPostModalAndFromFeed?: boolean,
    isFeedPlatformMenuItem?: boolean,
  ) => string;
  getUnreadClasses: (hasUnreadContent: boolean) => string;
}

const sidebarClasses = {
  inactive:
    "bg-transparent text-c-sidebar focus:text-c-sidebar hover:text-c-sidebar cursor-pointer hover:bg-c-sidebar-hover focus:bg-c-sidebar-hover rounded-md",
  activeWithBrandColor:
    "bg-brand rounded-md text-brand-button focus:text-brand-button hover:text-brand-button cursor-default",
  activeWithThemeColor:
    "bg-c-active-item-color rounded-md text-c-active-item-text-color focus:text-c-active-item-text-color hover:text-c-active-item-text-color cursor-default",
  unreadContent: "rounded-md font-semibold",
} as const;

export const useGetSidebarLinkClass = (): UseGetSidebarLinkClassProps => {
  const isCustomizeCommunity = useIsCustomizeCommunityAvailable();
  const { currentTheme } = useThemeContext();

  const shouldUseActiveItemThemeColor =
    isCustomizeCommunity && currentTheme === "custom";

  const getActiveInactiveClasses = (
    isActive: boolean,
    isInvokedFromPostModalAndFromFeed: boolean = false,
    isFeedPlatformMenuItem: boolean = false,
  ) => {
    if (isFeedPlatformMenuItem && isInvokedFromPostModalAndFromFeed) {
      if (shouldUseActiveItemThemeColor) {
        return sidebarClasses.activeWithThemeColor;
      }
      return sidebarClasses.activeWithBrandColor;
    }
    if (isActive && !isInvokedFromPostModalAndFromFeed) {
      if (shouldUseActiveItemThemeColor) {
        return sidebarClasses.activeWithThemeColor;
      }
      return sidebarClasses.activeWithBrandColor;
    }
    return sidebarClasses.inactive;
  };

  const getUnreadClasses = (hasUnreadContent: boolean): string => {
    if (hasUnreadContent) {
      return sidebarClasses.unreadContent;
    }
    return "";
  };

  return {
    getActiveInactiveClasses,
    getUnreadClasses,
  };
};
