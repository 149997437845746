import { usePunditUserContext } from "@circle-react/contexts";
import { useMarketingHubStatus } from "@circle-react/contexts/MarketingHubStatusContext";

export const useMarketingHubAccessEndDate = () => {
  const { data, isLoading: isLoadingMarketingHubStatus } =
    useMarketingHubStatus();
  const { currentCommunity } = usePunditUserContext();

  if (!data || !("plan" in data) || isLoadingMarketingHubStatus) {
    return {
      isLoading: isLoadingMarketingHubStatus,
      accessEndDate: null,
    };
  }

  const {
    enabled: isMarketingHubStatusEnabled,
    trialing: isTrialing,
    plan,
  } = data;

  if (!isMarketingHubStatusEnabled)
    return {
      isLoading: isLoadingMarketingHubStatus,
      accessEndDate: null,
    };

  if (isTrialing)
    return {
      isLoading: isLoadingMarketingHubStatus,
      accessEndDate: currentCommunity.trial_ends_at,
    };

  if (plan.cancel_at_period_end && plan.current_period_end)
    return {
      isLoading: isLoadingMarketingHubStatus,
      accessEndDate: plan.current_period_end,
    };

  return {
    isLoading: isLoadingMarketingHubStatus,
    accessEndDate: null,
  };
};
