import { t } from "i18n-js";
import { RadioFilter } from "@circle-react/components/Modals/SearchResultsModal/FilterBar/RadioFilter";

export const EventStatusFilter = () => (
  <RadioFilter
    name="status"
    chip={t("search_v2.filters.event_status.title")}
    title={t("search_v2.filters.event_status.title")}
    options={[
      { value: "past", label: t("search_v2.filters.event_status.past") },
      {
        value: "upcoming",
        label: t("search_v2.filters.event_status.upcoming"),
      },
    ]}
  />
);
