import { useEffect, useState } from "react";

export const useAutoHideUI = (condition, autoHideDelay = 3000) => {
  const [uiHidden, setUiHidden] = useState();
  let timer;

  const onMouseMove = () => {
    setUiHidden(false);
    clearTimeout(timer);
    timer = setTimeout(() => setUiHidden(true), autoHideDelay);
  };

  const removeListeners = () => {
    document.removeEventListener("mousemove", onMouseMove);
    clearTimeout(timer);
  };

  useEffect(() => {
    if (condition) {
      document.addEventListener("mousemove", onMouseMove);
      setUiHidden(true);
    } else {
      removeListeners();
      setUiHidden(false);
    }

    return () => {
      removeListeners();
    };
  }, [condition]);

  return {
    uiHidden,
  };
};
