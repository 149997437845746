export const PaymentIntentStatus = {
  CANCELED: "canceled",
  PROCESSING: "processing",
  REQUIRES_ACTION: "requires_action",
  REQUIRES_CAPTURE: "requires_capture",
  REQUIRES_CONFIRMATION: "requires_confirmation",
  REQUIRES_PAYMENT_METHOD: "requires_payment_method",
  SUCCEEDED: "succeeded",
};

export const isRequiresAction = (paymentIntent: any) =>
  paymentIntent?.status === PaymentIntentStatus.REQUIRES_ACTION;

export const isSucceeded = (paymentIntent: any) =>
  paymentIntent?.status === PaymentIntentStatus.SUCCEEDED;
