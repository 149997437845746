import { useMutation } from "react-query";
import { reactQueryPut } from "@/react/helpers/backendRequestHelpers";
import { internalApi } from "@/react/helpers/urlHelpers";

interface ChangeNotifyStudentsEnabledMutationProps {
  course_section: { notify_students_enabled: boolean };
}

export const useNotifyStudentsEnabledMutation = ({ section, options }: any) => {
  const changeNotifyStudentsEnabledMutation = useMutation(
    (data: ChangeNotifyStudentsEnabledMutationProps) =>
      reactQueryPut(
        internalApi.courses.updateSection({
          sectionId: section.id,
          courseId: section.space_id,
        }),
        data,
      ),
    options,
  );
  return { changeNotifyStudentsEnabledMutation };
};
