import { useRef } from "react";
import classNames from "classnames";
import { uniqueId } from "lodash";
import DatePicker from "react-datepicker";
import { Controller } from "react-hook-form";
import { mergeRefs } from "@circle-react/helpers/refHelpers";
import { Icon } from "@circle-react-shared/Icon";

interface FormDatePickerProps {
  minDate?: Date;
  maxDate?: Date;
  name: string;
  disabled?: boolean;
  placeholder?: string;
}

/**
 * We have DatePicker UIKit Form component that uses Flatpickr.
 * We in the recurring modal, we already DateTimePicker which uses the react-datepicker component.
 * Since we cannot have two different libraries for datepicker in the same form, creating a sole datepicker using react-datepicker
 */
export function FormDatePicker({
  minDate,
  maxDate,
  name,
  disabled,
  placeholder,
  ...rest
}: FormDatePickerProps) {
  const refDate: any = useRef<DatePicker>(null);
  const datePickerPortalId: any = useRef<string>(
    uniqueId("date-picker-portal-"),
  );

  return (
    <Controller
      name={name}
      render={({ field: { value, ref, ...fieldRest } }) => {
        const selectedDate = value ? new Date(value) : null;
        const mergedDateRef = mergeRefs([ref, refDate]);

        return (
          <div className="relative mr-1">
            <DatePicker
              selected={selectedDate}
              dateFormat="MMM d, yyyy"
              minDate={minDate}
              maxDate={maxDate}
              disabled={disabled}
              placeholderText={placeholder}
              ref={mergedDateRef}
              portalId={datePickerPortalId}
              popperClassName="!z-[1080] !w-auto"
              className={classNames({
                "!bg-secondary !border-primary": disabled,
              })}
              {...fieldRest}
              {...rest}
            />
            <Icon
              type="cheveron-down"
              className="hover:text-darkest absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer"
              onClick={() => {
                refDate.current?.input?.focus();
              }}
            />
          </div>
        );
      }}
    />
  );
}
