import { useEffect } from "react";
import { selectHLSState, useHMSStore } from "@100mslive/react-sdk";
import { t } from "i18n-js";
import { useToast } from "@circle-react-uikit/ToastV2";
import { useHMSErrorCode } from "./useHMSErrorCode";

const I18N_ERROR_BASE = "live_streams.room.hms_errors.";
const errorTranslation = translationKey =>
  `${I18N_ERROR_BASE}${translationKey}`;

export const useHMSErrorHandler = () => {
  const { error } = useToast();
  const errorCode = useHMSErrorCode();
  const hlsState = useHMSStore(selectHLSState);
  const onHMSError = errorCode => {
    // Note: Map with translations defined as part of
    // component render, otherwise translations don't work
    const errorMap = {
      1000: t(errorTranslation("1000_websocket_blocked")),
      1003: t(errorTranslation("1003_websocket_disconnected")),
      2003: t(errorTranslation("2003_endpoint_not_reachable")),
      3001: t(errorTranslation("3001_cannot_access_device")),
      3002: t(errorTranslation("3002_capture_device_not_available")),
      3003: t(errorTranslation("3003_capture_device_is_in_use")),
      4005: t(errorTranslation("4005_ice_connection_failed")),
    };
    // Display specific error message if available
    const errorMessage = errorMap[errorCode];
    errorMessage && error(errorMessage);
  };
  useEffect(() => {
    if (errorCode) {
      onHMSError(errorCode);
    }
  }, [errorCode]);
  useEffect(() => {
    if (hlsState.error) {
      error(t("live_streams.room.hls_error"));
    }
  }, [hlsState.error]);
};
