import { t } from "i18n-js";
import { PaywallCurrencyAmountFormInput } from "@circle-react/components/Paywalls";
import {
  buildTransformInputValueToCurrencyAmountStrFunc,
  mapCurrencyAmountStrToInt,
} from "@circle-react/components/Paywalls/Admin/utils";
import { formatPaywallCurrencyAmount } from "@circle-react/helpers/paywalls/currencies";
import { usePricingTabContext } from "../hooks/usePricingTabContext";
import type { PricingTabAddPriceModalInputWrapperProps } from "./InputWrapper";
import { PricingTabAddPriceModalInputWrapper } from "./InputWrapper";

export type PricingTabAddPriceModalAmountFieldProps = Pick<
  PricingTabAddPriceModalInputWrapperProps,
  "name" | "labelKey" | "placeholder"
>;

export const PricingTabAddPriceModalAmountField = ({
  name,
  labelKey,
  placeholder,
}: PricingTabAddPriceModalAmountFieldProps) => {
  const { currency } = usePricingTabContext();

  const mapAmountIntToCurrencyAmountStr =
    buildTransformInputValueToCurrencyAmountStrFunc(currency, {
      amountInCents: true,
    });

  const amountErrorMessage = t(
    "settings.paywalls.modal.tabs.pricing.add_price_modal.field_errors.amount",
    {
      min: formatPaywallCurrencyAmount(currency, currency.min_charge_amount, {
        amountInCents: true,
        includeCurrencyCode: false,
      }),
      max: formatPaywallCurrencyAmount(currency, currency.max_charge_amount, {
        amountInCents: true,
        includeCurrencyCode: false,
      }),
    },
  );

  return (
    <PricingTabAddPriceModalInputWrapper
      name={name}
      labelKey={labelKey}
      placeholder={placeholder || mapAmountIntToCurrencyAmountStr(0)}
      rules={{
        required: true,
        validate: {
          bounds: value => {
            const valueInt = mapCurrencyAmountStrToInt(value);
            const isValid =
              !isNaN(valueInt) &&
              valueInt >= currency.min_charge_amount &&
              valueInt <= currency.max_charge_amount;
            return isValid || amountErrorMessage;
          },
        },
      }}
      transformValue={mapAmountIntToCurrencyAmountStr}
    >
      <PaywallCurrencyAmountFormInput currency={currency} />
    </PricingTabAddPriceModalInputWrapper>
  );
};
