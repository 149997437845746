import { isEmpty } from "lodash";
import { useQuery } from "react-query";
import { workflowsApi } from "@circle-react/api";
import { internalApi } from "@circle-react/helpers/urlHelpers";

interface UsePreviewDMProps {
  communityMemberId: number;
  body: object;
  resourceAttributes: object;
}

interface CommunityMemberResponse {
  name: string;
  avatar_url: string;
  isLoading: boolean;
}

export const usePreviewDM = ({
  communityMemberId,
  body,
  resourceAttributes,
}: UsePreviewDMProps) => {
  const {
    data: { interpolated_body: interpolatedBody = {} } = {},
    isLoading: isLoadingPreviewMessageBody,
  } = useQuery(
    [
      "workflows-fetch-interpolated-preview-message",
      communityMemberId,
      body,
      resourceAttributes,
    ],
    () =>
      workflowsApi.fetchInterpolatedPreviewMessage({
        body: {
          rich_text_body: body,
          mappings: resourceAttributes,
        },
      }),
    { enabled: !isEmpty(resourceAttributes) },
  );

  const { data: communityMember, isLoading: isLoadingCommunityMember } =
    useQuery<CommunityMemberResponse>(
      internalApi.communityMembers.show({ id: communityMemberId }),
      {
        enabled: !!communityMemberId,
      },
    );

  return {
    isLoadingPreviewMessageBody,
    isLoadingCommunityMember,
    interpolatedBody,
    communityMember,
  };
};
