import classNames from "classnames";

export const LoadingState = () => (
  <div className="border-primary bg-primary mt-4 flex flex-col rounded-2xl border">
    {Array(10)
      .fill(0)
      .map((level, index) => (
        <div
          className={classNames("flex items-center space-x-6 p-4 px-6", {
            "border-primary border-t": index !== 0,
          })}
          key={level}
        >
          <div className="bg-tertiary h-8 w-8 animate-pulse rounded-full" />
          <div className="flex flex-1 items-start space-x-3">
            <div className="bg-tertiary h-10 w-10 animate-pulse rounded-full" />
            <div className="flex flex-col space-y-2">
              <div className="bg-tertiary h-5 w-32 animate-pulse rounded" />
              <div className="bg-tertiary w-18 h-3 animate-pulse rounded" />
            </div>
          </div>
          <div className="bg-tertiary h-5 w-10 animate-pulse rounded" />
        </div>
      ))}
  </div>
);
