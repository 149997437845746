import { BannerForm } from "./BannerForm";

export const BannerFormFields = ({
  fieldName,
  displayAllowClose = true,
  community,
  community_setting,
  setMediaSettings,
  onBannerDescriptionChange,
  onCommunitySettingChange,
  onRemoveMedia,
}) => {
  const banner = community_setting[fieldName];
  const { enabled, title, allow_close } = banner;

  return (
    <BannerForm
      keyField={fieldName}
      title={title}
      enabled={enabled ? enabled : false}
      description={community[`${fieldName}_description`]}
      media={community[`${fieldName}_media`]}
      setMediaSettings={setMediaSettings}
      onBannerDescriptionChange={event =>
        onBannerDescriptionChange(event, `${fieldName}_description`)
      }
      allowClose={allow_close ? allow_close : false}
      onTitleChange={event =>
        onCommunitySettingChange(fieldName, "title", event.target.value)
      }
      onAllowCloseChange={value =>
        onCommunitySettingChange(fieldName, "allow_close", value)
      }
      onEnabledChange={value =>
        onCommunitySettingChange(fieldName, "enabled", value)
      }
      displayAllowClose={displayAllowClose}
      onRemoveMedia={event => onRemoveMedia(event, `${fieldName}_media`)}
    />
  );
};
