import PropTypes from "prop-types";
import { CustomizeUI } from "@circle-react-shared/CustomizeUI";
import { FullScreenModal } from "@circle-react-shared/ModalsV2/FullScreenModal";
import { Content } from "./Content";

export const Modal = ({
  isOpen,
  onSave,
  onClose,
  onExit,
  sections,
  initialSection,
  title,
  formId = "edit-space-form",
  contentPortalId = "customize-space-portal",
  children,
}) => (
  <FullScreenModal
    isOpen={isOpen}
    onClose={onClose}
    shouldShowCloseButton
    contentPortalId={contentPortalId}
  >
    <CustomizeUI
      formId={formId}
      sections={sections}
      initialSection={initialSection}
      title={title}
      onSave={onSave}
      onExit={onExit}
    >
      <Content>{children}</Content>
    </CustomizeUI>
  </FullScreenModal>
);

Modal.propTypes = {
  isOpen: PropTypes.bool,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  onExit: PropTypes.func,
  sections: PropTypes.array,
  initialSection: PropTypes.string,
  title: PropTypes.string,
  formId: PropTypes.string,
  contentPortalId: PropTypes.string,
  children: PropTypes.node,
};
