import { t } from "i18n-js";
import { useEventSpaces } from "@/react/components/Events/hooks/useEventSpaces";
import { useCreateEventModal } from "@/react/components/Modals/CreateEventModal";
import { Icon } from "@circle-react-shared/Icon";

export const OpenEventModalButton = () => {
  const { spaceId } = useEventSpaces();
  const createEventModal = useCreateEventModal();
  return (
    <button
      type="button"
      onClick={() => createEventModal.show({ spaceId })}
      className="text-dark flex w-full justify-center p-5"
    >
      <Icon
        aria-label={t("events.create.title")}
        type="bar-plus"
        size={24}
        useWithStrokeCurrentColor
      />
    </button>
  );
};
