import { useState } from "react";
import type { Dispatch, SetStateAction } from "react";
import type { LocationState } from "./useNavigationLogic";

interface NavigationState {
  direction: "next" | "previous";
  setDirection: Dispatch<SetStateAction<"next" | "previous">>;
  isPostQueryEnabled: boolean;
  setIsPostQueryEnabled: Dispatch<SetStateAction<boolean>>;
  hasNextPage: boolean;
  setHasNextPage: Dispatch<SetStateAction<boolean>>;
}

export const useNavigationState = (
  locationState?: LocationState,
): NavigationState => {
  const [direction, setDirection] = useState<"next" | "previous">("next");
  const [isPostQueryEnabled, setIsPostQueryEnabled] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(
    locationState?.hasNextPage ?? false,
  );

  return {
    direction,
    setDirection,
    isPostQueryEnabled,
    setIsPostQueryEnabled,
    hasNextPage,
    setHasNextPage,
  };
};
