import { CommunityMemberFilter } from "./CommunityMemberFilter";
import { NameFilter } from "./NameFilter";
import { RunIdFilter } from "./RunIdFilter";
import { StatusFilter } from "./StatusFilter";

export const SharedFilters = {
  NameFilter,
  StatusFilter,
  RunIdFilter,
  CommunityMemberFilter,
};

export { HistoryFilters } from "./HistoryFilters";
