export const STATUS = Object.freeze({
  NOT_STARTED: "not_started",
  ACTIVE: "active",
  COMPLETED: "completed",
  RELEASED: "released",
  CANCELED: "canceled",
});

export const isInstallmentCompleted = (installment: any) =>
  installment.status === STATUS.COMPLETED;
