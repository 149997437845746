interface ChatThreadsHeaderWrapperProps {
  isRenderedOnHeader: boolean;
  children: any;
}

export const ChatThreadsHeaderWrapper = ({
  isRenderedOnHeader,
  children,
}: ChatThreadsHeaderWrapperProps) => {
  if (!isRenderedOnHeader) {
    return children;
  }

  return (
    <div
      className="border-primary bg-primary rounded-lg border shadow-sm"
      data-testid="thread-item"
    >
      {children}
    </div>
  );
};
