import { useState } from "react";
import { useActivateAndRunWorkflow } from "@circle-workflows/hooks/useActivateAndRunWorkflow";
import { noop } from "lodash";
import {
  isMarketingHubRequired,
  isPlanCheckFailed,
} from "@/react/components/SettingsApp/Workflows/workflowApiErrorHelper";
import { nextPlanTierForWorkflowUpgrade } from "@/react/helpers/planHelpers";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { getWorkflowSource } from "@circle-react/components/SettingsApp/Workflows/hooks/useOpenedFromPage";
import { usePunditUserContext } from "@circle-react/contexts";
import { useUpgradePlanModal } from "@circle-react-shared/UpgradePlan";
import { Modal } from "@circle-react-uikit/ModalV2";
import { useToast } from "@circle-react-uikit/ToastV2";
import { SCHEDULE_TYPE } from "../../constants";
import { WorkflowFormProvider } from "../WorkflowForm/WorkflowFormProvider";
import { useWorkflow } from "../WorkflowForm/useWorkflow";
import { ConfirmContent } from "./ConfirmContent";
import { OneTimeSchedule } from "./OneTimeSchedule";
import { RecurringSchedule } from "./RecurringSchedule";
import { RerunContent } from "./RerunContent";
import { confirmBodyProps } from "./confirmBodyProps";

export const MODAL_RESULT = {
  DISMISSED: "dismissed",
  ERROR: "error",
  SUCCESS: "closed_after_success",
} as const;

export interface ConfirmSubmitWorkflowModalProps {
  isRerun: boolean;
}

export const ModalComponent = ({
  isRerun = false,
}: ConfirmSubmitWorkflowModalProps) => {
  const modal = useModal();
  const source: any = getWorkflowSource();
  const {
    saveWorkflowName,
    hasName,
    placeholderName,
    isBulkAction,
    isScheduled,
    schedule,
    hasRuns,
    id: workflowId,
  } = useWorkflow();

  const [isConfirmContent, setIsConfirmContent] = useState(
    !(isRerun || isScheduled),
  );

  const { mutateAsync: activateAndRunWorkflow, isLoading } =
    useActivateAndRunWorkflow({ onError: noop });

  const { currentCommunity } = usePunditUserContext();
  const { current_plan_tier: currentPlanTier } = currentCommunity;
  const upgradePlanModal = useUpgradePlanModal();
  const toast = useToast();
  const { successButtonLabel } = confirmBodyProps({
    isBulkAction,
    isRerun,
  });

  const onCancel = () => {
    modal.resolve(MODAL_RESULT.DISMISSED);
    void modal.hide();
  };

  const onPlanUpgradeSuccess = () => {
    void upgradePlanModal.hide();
    void modal.show();
  };

  const showPlanUpgradeModal = (error: any) =>
    upgradePlanModal.show({
      ...(isPlanCheckFailed(error) && {
        planTier: nextPlanTierForWorkflowUpgrade(currentPlanTier),
      }),
      subheading: error.body.message,
      isMarketingHubRequired: isMarketingHubRequired(error),
      onSuccess: onPlanUpgradeSuccess,
      successButtonLabel: successButtonLabel,
      usedForWorkflows: true,
      showPlanDetails: true,
      source,
    });

  const publishHandler = async (onPublish: any) => {
    try {
      await onPublish();
      modal.resolve(MODAL_RESULT.SUCCESS);
    } catch (error: any) {
      if (isPlanCheckFailed(error) || isMarketingHubRequired(error)) {
        void modal.hide();
        await showPlanUpgradeModal(error);
      } else {
        toast.error(error.message);
      }
      modal.resolve(MODAL_RESULT.ERROR);
    } finally {
      void modal.hide();
    }
  };

  const getScheduledBody = () => {
    switch (schedule?.schedule_type) {
      case SCHEDULE_TYPE.ONE_TIME:
        return (
          <OneTimeSchedule
            workflowId={workflowId}
            setIsConfirmContent={setIsConfirmContent}
            onCancel={onCancel}
            schedule={schedule}
            hasRuns={hasRuns}
          />
        );
      case SCHEDULE_TYPE.RECURRING:
        return (
          <RecurringSchedule
            workflowId={workflowId}
            onCancel={onCancel}
            schedule={schedule}
            isLoading={isLoading}
            onPublish={() =>
              publishHandler(async () => {
                if (!hasName) {
                  await saveWorkflowName(placeholderName);
                }
                await activateAndRunWorkflow();
              })
            }
          />
        );
      case "run_now":
        return (
          <RerunContent
            hasRuns={hasRuns}
            onCancel={onCancel}
            onPublish={publishHandler}
            additionalRerunParams={{ schedule_run_now: true }}
          />
        );
    }

    return null;
  };

  const getBody = () => {
    if (isConfirmContent) {
      return (
        <ConfirmContent
          isLoading={isLoading}
          onPublish={() =>
            publishHandler(async () => {
              if (!hasName) {
                await saveWorkflowName(placeholderName);
              }
              await activateAndRunWorkflow();
            })
          }
          onCancel={onCancel}
        />
      );
    }

    if (isScheduled) {
      return getScheduledBody();
    }

    if (isRerun) {
      return <RerunContent onPublish={publishHandler} onCancel={onCancel} />;
    }

    return null;
  };

  return (
    <Modal isOpen={modal.visible} onClose={onCancel}>
      <Modal.Overlay />
      {getBody()}
    </Modal>
  );
};

const ConfirmSubmitWorkflowModal = ({
  isRerun,
}: ConfirmSubmitWorkflowModalProps) =>
  ModalManager.create(({ workflow }: any) => (
    <WorkflowFormProvider workflow={workflow}>
      <ModalComponent isRerun={isRerun} />
    </WorkflowFormProvider>
  ));

export const useConfirmSubmitWorkflowModal = ({
  isRerun,
}: ConfirmSubmitWorkflowModalProps) =>
  useModal(ConfirmSubmitWorkflowModal({ isRerun }));
