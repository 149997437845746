import { t } from "i18n-js";
import { TextArea } from "@circle-react/components/shared/Form/TextArea";
import { UnlockPlanFeature } from "@circle-react/components/shared/UnlockPlanFeature";
import { usePunditUserContext } from "@circle-react/contexts";
import { Form } from "@circle-react-uikit/Form";
import { TabTitle } from "../TabTitle";

const i18nRoot = "settings.paywalls.tracking_js_code";

export const VARIABLES = [
  {
    group: t([i18nRoot, "group.member"]),
    label: t([i18nRoot, "variables.member_email"]),
    value: t([i18nRoot, "variables.member_email"]),
  },
  {
    group: t([i18nRoot, "group.charge"]),
    label: t([i18nRoot, "variables.amount_paid"]),
    value: t([i18nRoot, "variables.amount_paid"]),
  },
  {
    group: t([i18nRoot, "group.coupon"]),
    label: t([i18nRoot, "variables.coupon_code"]),
    value: t([i18nRoot, "variables.coupon_code"]),
  },
  {
    group: t([i18nRoot, "group.paywall"]),
    label: t([i18nRoot, "variables.paywall_internal_name"]),
    value: t([i18nRoot, "variables.paywall_internal_name"]),
  },
  {
    group: t([i18nRoot, "group.paywall"]),
    label: t([i18nRoot, "variables.paywall_display_name"]),
    value: t([i18nRoot, "variables.paywall_display_name"]),
  },
  {
    group: t([i18nRoot, "group.paywall"]),
    label: t([i18nRoot, "variables.paywall_trial_days"]),
    value: t([i18nRoot, "variables.paywall_trial_days"]),
  },
  {
    group: t([i18nRoot, "group.paywall_price"]),
    label: t([i18nRoot, "variables.paywall_price_amount"]),
    value: t([i18nRoot, "variables.paywall_price_amount"]),
  },
  {
    group: t([i18nRoot, "group.paywall_price"]),
    label: t([i18nRoot, "variables.paywall_price_interval"]),
    value: t([i18nRoot, "variables.paywall_price_interval"]),
  },
  {
    group: t([i18nRoot, "group.paywall_price"]),
    label: t([i18nRoot, "variables.paywall_price_type"]),
    value: t([i18nRoot, "variables.paywall_price_type"]),
  },
];

export const TrackingTab = () => {
  const { currentCommunity } = usePunditUserContext();
  const options = VARIABLES;

  return (
    <div>
      <div className="flex h-[60vh] flex-col px-4">
        <TabTitle title={t("settings.paywalls.modal.tabs.tracking.title")} />
        {!currentCommunity.paywall_tracking_code_feature_flag_enabled ? (
          <UnlockPlanFeature
            PlanFeatureName={t(
              "pricing_plans.feature.conversion_tracking_code",
            )}
          />
        ) : (
          <Form.Item
            name="conversion_tracking_js_code"
            translationRoot="settings.paywalls.modal.tabs.tracking"
            tooltipText={t(
              "settings.paywalls.modal.tabs.tracking.conversion_tracking_js_code_tooltip",
            )}
            hideBorder
            fullWidth={false}
            hideDescription
            className="!flex flex-auto flex-col"
          >
            <TextArea
              className="flex-auto"
              showMenuBar
              menuBarOptions={options}
            />
          </Form.Item>
        )}
      </div>
    </div>
  );
};

TrackingTab.propTypes = {};
