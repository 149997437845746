import { PaywallChargesList } from "@circle-react-shared/Paywalls/PaywallChargesList";
import { PaywallChargesListContextProvider } from "@circle-react-shared/Paywalls/PaywallChargesList/PaywallChargesListContextProvider";
import { EmptyPage } from "../../Filters/EmptyPage";
import { usePaywallChargesList } from "./hooks/usePaywallChargesList";

export interface ChargesListProps {
  chargesData: any;
  activeFilterTab: string;
  refetchAllCharges: () => void;
}

export const ChargesList = ({
  chargesData,
  activeFilterTab,
  refetchAllCharges,
}: ChargesListProps) => {
  const { onClickPagination, columnsToRender } = usePaywallChargesList({
    chargesData,
  });

  if (
    chargesData.hasFilters &&
    chargesData.totalCharges === 0 &&
    !chargesData.isLoadingCharges
  ) {
    return <EmptyPage />;
  }

  return (
    <PaywallChargesListContextProvider
      shouldOpenAdminMemberEdit
      refetchAllCharges={refetchAllCharges}
    >
      <PaywallChargesList
        key={activeFilterTab}
        columnsToRender={columnsToRender}
        chargesData={chargesData}
        onClickPagination={onClickPagination}
      />
    </PaywallChargesListContextProvider>
  );
};
