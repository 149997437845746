import { useEffect } from "react";
import { t } from "i18n-js";
import { useFormContext } from "react-hook-form";
import { FormItem } from "@circle-react/components/SettingsApp/General/FormItem";
import { useIsWorkflowInDraftMode } from "@circle-react/components/SettingsApp/Workflows/hooks/useIsWorkflowInDraftMode";
import { usePunditUserContext } from "@circle-react/contexts/punditUserContext";
import { Icon } from "@circle-react-shared/Icon";
import { Form } from "@circle-react-shared/uikit/Form";
import { InfoBox } from "@circle-react-shared/uikit/InfoBox";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { FormTextSelect } from "@circle-react-uikit/Form/FormTextSelect";
import { useActions, useCurrentAction } from "../../../ActionRule";

export const i18nRoot = "settings.workflows.form.time_delay";

const timeUnitOptions = [
  {
    value: "hours",
    label: t([i18nRoot, "time_unit_options.hours_label"]),
    rules: {
      minValue: 1,
      maxValue: 999,
    },
  },
  {
    value: "days",
    label: t([i18nRoot, "time_unit_options.days_label"]),
    rules: {
      minValue: 1,
      maxValue: 365,
    },
  },
  {
    value: "weeks",
    label: t([i18nRoot, "time_unit_options.weeks_label"]),
    rules: {
      minValue: 1,
      maxValue: 52,
    },
  },
];

export const FormTimeDelay = () => {
  const { paramsPath, action } = useCurrentAction();
  const { doesActionhasServerId } = useActions();
  const { watch, setValue, clearErrors, trigger } = useFormContext();
  const { currentCommunitySettings } = usePunditUserContext();
  const isWorkflowInDraftMode = useIsWorkflowInDraftMode();

  const timeUnit = watch(`${paramsPath}.time_unit`);
  const { minValue, maxValue } = timeUnitOptions.find(
    option => option.value === timeUnit,
  )?.rules ?? { minValue: 1, maxValue: 999 };

  // Set default values for the fields
  useEffect(() => {
    if (timeUnit) return;
    setValue(`${paramsPath}.time_unit_amount`, 1);
    setValue(`${paramsPath}.time_unit`, "days", { shouldValidate: true });
  }, [paramsPath, setValue, timeUnit]);

  // Clear the error state of time_unit_amount field when time_unit changes
  useEffect(() => {
    clearErrors(`${paramsPath}.time_unit_amount`);
    void trigger(`${paramsPath}.time_unit_amount`);
  }, [timeUnit, clearErrors, paramsPath, trigger]);
  const canNotEditTimeDelay =
    !currentCommunitySettings?.edit_time_delays_enabled &&
    doesActionhasServerId(action) &&
    !isWorkflowInDraftMode;

  if (canNotEditTimeDelay) {
    return (
      <InfoBox className="flex justify-between !py-3">
        <div className="flex items-center">
          <Icon className="mr-3" type="20-info" size={20} />
          <Typography.LabelSm>
            {t([i18nRoot, "cannot_edit_time_delay"])}
          </Typography.LabelSm>
        </div>
      </InfoBox>
    );
  }

  return (
    // eslint-disable-next-line tailwindcss/no-custom-classname -- We need react-forms error styles
    <div className="react-form">
      <Typography.LabelSm weight="semibold">
        {t([i18nRoot, "time_unit_amount"])}
      </Typography.LabelSm>
      <div className="mt-2 flex flex-row gap-5">
        <div className="flex flex-1">
          <FormItem
            className="w-full !p-0"
            name={`${paramsPath}.time_unit_amount`}
            hideLabel
            placeholder={t([i18nRoot, "time_unit_amount_placeholder"])}
            translationRoot={i18nRoot}
            hideDescription
            rules={{
              required: t([i18nRoot, "errors.time_unit_amount.required"]),
              min: {
                value: minValue,
                message: t(
                  [i18nRoot, "errors.time_unit_amount.enter_a_value_between"],
                  {
                    start: minValue,
                    end: maxValue,
                  },
                ),
              },
              max: {
                value: maxValue,
                message: t(
                  [i18nRoot, "errors.time_unit_amount.enter_a_value_between"],
                  {
                    start: minValue,
                    end: maxValue,
                  },
                ),
              },
            }}
          >
            <Form.Input
              type="number"
              name={`${paramsPath}.time_unit_amount`}
              data-testid="time_unit_amount"
            />
          </FormItem>
        </div>

        <div className="flex flex-1">
          <FormTextSelect
            wrapperClassName="w-full"
            name={`${paramsPath}.time_unit`}
            label=""
            options={timeUnitOptions}
            rules={{
              required: t([i18nRoot, "errors.time_unit.required"]),
            }}
          />
        </div>
      </div>
    </div>
  );
};
