import { t } from "i18n-js";
import { EntityResource } from "../EntityResource";
import { AnyEventSelector } from "../Event/shared/AnyEventSelector";
import type { EventOption } from "../types";

export const eventPublished: Readonly<EventOption> = {
  label: t("settings.workflows.form.events.event_published.title"),
  value: "event_published",
  group: t("settings.workflows.form.event_groups.events"),
  description: t("settings.workflows.form.events.event_published.description"),
  formComponent: AnyEventSelector,
  viewComponent: EntityResource,
  icon: "event",
  released: true,
};
