import PropTypes from "prop-types";
import { useFetchEvent } from "@circle-workflows/hooks/useFetchEvent";

export const EventFromId = ({ id }) => {
  const { data: event, isLoading } = useFetchEvent({ eventId: id });

  if (isLoading) {
    return (
      <span className="bg-tertiary block h-4 w-full animate-pulse rounded" />
    );
  }

  return <span>{event.name}</span>;
};

EventFromId.propTypes = {
  id: PropTypes.number.isRequired,
};
