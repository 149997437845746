import { usePunditUserContext } from "@circle-react/contexts";
import { isCommunityAdmin } from "@circle-react/helpers/communityMemberHelpers";
import { useUsageContext } from "@circle-react/providers";
import type {
  SortOptions,
  Views,
} from "@circle-react-shared/MembersList/constants";
import {
  SORT_OPTIONS,
  VIEWS,
} from "@circle-react-shared/MembersList/constants";

export interface MemberDirectorySettings {
  defaultSort: SortOptions;
  scope: Record<string, boolean>;
  view: Views;
  isLoading: boolean;
}

export const useMemberDirectorySettings = (): MemberDirectorySettings => {
  const { isAdminUsageContext } = useUsageContext();
  const { currentCommunitySettings, currentCommunityMember, isLoading } =
    usePunditUserContext();

  const memberDirectorySettings =
    currentCommunitySettings?.member_directory_settings || {
      sort: SORT_OPTIONS[0],
      exclude_empty_profiles: true,
    };

  const adminScope = {
    exclude_empty_name: isAdminUsageContext ? false : true,
    exclude_empty_profiles: isAdminUsageContext
      ? false
      : memberDirectorySettings.exclude_empty_profiles,
  };

  const memberScope = {
    visible_in_member_directory: true,
    ...adminScope,
  };

  const scope = isCommunityAdmin(currentCommunityMember)
    ? adminScope
    : memberScope;

  const getView = () => {
    let view = VIEWS.CARDS;
    if (currentCommunitySettings?.member_directory_settings?.view) {
      view = currentCommunitySettings.member_directory_settings.view;
    }
    return view;
  };

  return {
    defaultSort: memberDirectorySettings.sort,
    scope,
    view: getView(),
    isLoading,
  };
};
