import { t } from "i18n-js";
import { Typography } from "@circle-react-shared/uikit/Typography";

interface ViewEmailExcerptProps {
  inlinePreviewComponent: React.ReactNode;
  internalName?: string;
  subject?: string;
}

const localeNamespace = "settings.workflows.view";

const ViewEmailExcerpt = ({
  inlinePreviewComponent,
  internalName,
  subject,
}: ViewEmailExcerptProps) => (
  <div className="flex flex-1 items-center gap-x-4">
    {inlinePreviewComponent}
    <div className="flex flex-col gap-y-1">
      <Typography.LabelSm weight="semibold">
        {internalName || t([localeNamespace, "untitled_email"])}
      </Typography.LabelSm>
      <div className="flex gap-x-1">
        <Typography.LabelXs weight="medium">
          {t([localeNamespace, "subject"])}
        </Typography.LabelXs>
        <Typography.LabelXs>
          {subject || t([localeNamespace, "no_subject_line"])}
        </Typography.LabelXs>
      </div>
    </div>
  </div>
);

export default ViewEmailExcerpt;
