import classnames from "classnames";
import { t } from "i18n-js";
import { Typography } from "@circle-react-uikit/Typography";
import type { SpaceOption } from "../../SpaceAccessManagementContext";
import { i18nRoot } from "../../constants";
import { Item } from "./Item";
import { ItemButton } from "./ItemButton";
import { ItemTitleWithIcon } from "./ItemTitleWithIcon";
import { useChildItem } from "./hooks/useChildItem";

interface SpaceOptionWithSpaceGroupId extends SpaceOption {
  spaceGroupId: number;
  onRemoveChild: (action: () => void, object: SpaceOption) => void;
}

export const ChildRemovableItem = ({
  spaceGroupId,
  onRemoveChild = action => {
    action();
  },
  ...space
}: SpaceOptionWithSpaceGroupId) => {
  const { name, icon, tooltipText } = space;
  const { isChecked, onRemoveClick, isSpaceFound } = useChildItem({
    space,
    spaceGroupId,
    onRemoveChild,
  });

  return (
    <Item
      onClick={onRemoveClick}
      isDisabled={!isChecked}
      className={classnames({ hidden: !isChecked || !isSpaceFound })}
    >
      <ItemTitleWithIcon icon={icon} tooltipText={tooltipText}>
        <Typography.LabelSm>{name}</Typography.LabelSm>
      </ItemTitleWithIcon>
      {isChecked && <ItemButton actionText={t([i18nRoot, "remove"])} />}
    </Item>
  );
};
