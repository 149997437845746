import { noop } from "lodash";
import { Form } from "@circle-react-uikit/Form";
import { usePricingTabContext } from "../hooks/usePricingTabContext";
import { AddMobilePriceModal } from "./AddMobilePriceModal";

export const PricingTabAddPriceModal = () => {
  const { defaultPriceTypeOption } = usePricingTabContext();

  const defaultOption = defaultPriceTypeOption();

  return (
    <Form
      className="w-full"
      defaultValues={{ price_type: defaultOption.value }}
      onSubmit={noop}
    >
      <AddMobilePriceModal />
    </Form>
  );
};
