import type { PropsWithChildren } from "react";
import classnames from "classnames";

interface ListProp {
  className?: string;
  dataTestId?: string;
}

export const List = ({
  children,
  className,
  dataTestId,
}: PropsWithChildren<ListProp>) => (
  <ul
    className={classnames("list-none py-2 md:p-2", className)}
    data-testid={dataTestId}
  >
    {children}
  </ul>
);
