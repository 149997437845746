import { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Transition } from "@headlessui/react";
import {
  useCurrentCommunityContext,
  useSpaceGroupsContext,
} from "@circle-react/contexts";
import { useSpaceApi } from "@circle-react/hooks/useSpaceApi";
import { Form } from "@circle-react-uikit/Form";
import { useToast } from "@circle-react-uikit/ToastV2";
import { FormContainer } from "./FormContainer";
import { SpaceNavigationModal } from "./SpaceNavigationModal";
import "./styles.scss";

export const NewForm = ({ space_group_id, onModalClose }) => {
  const { createSpace } = useSpaceApi();
  const currentCommunityContext = useCurrentCommunityContext();
  const spaceGroupsContext = useSpaceGroupsContext();
  const spaceFormPropsLoaded =
    currentCommunityContext.helpers.isDataLoaded() &&
    spaceGroupsContext.helpers.isDataLoaded();
  const [completedPopupVisible] = useState(false);
  const [spaceRootUrl] = useState("");
  const toast = useToast();

  const formattedSpaceFormData = data => {
    const result = {};

    Object.entries(data).forEach(([attribute, value]) => {
      if (["visibility", "post_type"].includes(attribute)) {
        result[attribute] = value[0];
      } else if (attribute === "notification_preferences") {
        [
          "default_notification_setting",
          "default_in_app_notification_setting",
          "default_mobile_notification_setting",
        ].forEach(setting_name => {
          if (value.includes("none")) {
            result[setting_name] = "never";
          } else {
            result[setting_name] = value.includes(setting_name)
              ? "all"
              : "never";
          }
        });
      } else {
        result[attribute] = value;
      }
    });

    return result;
  };

  const handleFormSubmit = async formData => {
    try {
      const data = await createSpace(formattedSpaceFormData(formData));
      if (data && data.root_url) {
        window.location = data.root_url;
      }
    } catch (apiError) {
      console.error(apiError);
      const { firstErrorDetail, errorDetails } = apiError;
      const errorMessage = firstErrorDetail ? firstErrorDetail : errorDetails;
      toast.error(errorMessage);
    }
  };

  const onCompletedPopupClose = () => {
    window.location = "/";
  };

  return (
    <Transition.Root show={spaceFormPropsLoaded} as={Fragment}>
      <div className="new-space-form">
        <Form
          onSubmit={handleFormSubmit}
          defaultValues={{
            space_group_id: space_group_id,
            emoji: "#",
          }}
        >
          <FormContainer
            currentSpaceGroupId={space_group_id}
            onModalClose={() => {
              if (onModalClose.redirect_to) {
                window.location = onModalClose.redirect_to;
              } else {
                onModalClose();
              }
            }}
          />
        </Form>
        <SpaceNavigationModal
          show={completedPopupVisible}
          onModalClose={onCompletedPopupClose}
          spaceRootUrl={spaceRootUrl}
        />
      </div>
    </Transition.Root>
  );
};

NewForm.propTypes = {
  space_group_id: PropTypes.number,
  onModalClose: PropTypes.func,
};
