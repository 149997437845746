import { t } from "i18n-js";
import { isValidURL } from "@circle-react/helpers/urlHelpers/isValidUrl";

const i18nRoot = "settings.validation";

export interface GetInputFieldRulesProps {
  isRequired?: boolean;
  requiredText?: string;
  maxLength?: number;
  maxLengthText?: string;
  isURL?: boolean;
}

export const getInputFieldRules = (props?: GetInputFieldRulesProps) => {
  const {
    isRequired = true,
    maxLength = 255,
    requiredText = t([i18nRoot, "this_field_is_required"]),
    maxLengthText = t([i18nRoot, "maximum_number_of_characters_allowed"], {
      count: maxLength,
    }),
    isURL = false,
  } = props ?? {};

  return {
    required: {
      value: isRequired,
      message: requiredText,
    },
    maxLength: {
      value: maxLength,
      message: maxLengthText,
    },
    validate: {
      isURL: (value: string) => {
        if (isURL && value && !isValidURL(value)) {
          return t([i18nRoot, "invalid_url_format"]);
        }
        return true;
      },
    },
  };
};

export const isSlugFormatValid = (value: string) => {
  // Slug can only contain lower case letters, numbers, underscores and hyphens
  const slugRegex = /^[a-z0-9_-]+$/;
  return slugRegex.test(value);
};

export const convertTimestampToDate = (timestamp: number): Date =>
  new Date(timestamp * 1000);
