import { t } from "i18n-js";

interface CustomizeSpaceSectionsParams {
  isCourseSpace?: boolean;
  areWorkflowsEnabled: boolean;
}

export const getCustomizeSpaceSections = ({
  isCourseSpace = false,
  areWorkflowsEnabled,
}: CustomizeSpaceSectionsParams): string[] => {
  const sections: string[] = [];

  if (isCourseSpace) sections.push(t("customize_space.lessons"));

  sections.push(t("customize_space.customize"));
  sections.push(t("customize_space.paywalls.title"));
  sections.push(t("customize_space.lock_screen"));
  sections.push(t("customize_space.members"));
  sections.push(t("customize_space.options"));

  if (areWorkflowsEnabled) sections.push(t("customize_space.workflows"));

  return sections;
};
