import {
  selectRoleChangeRequest,
  useHMSActions,
  useHMSStore,
} from "@100mslive/react-sdk";

export const useHMSRoleChange = () => {
  const hmsActions = useHMSActions();
  const changeRoleRequest = useHMSStore(selectRoleChangeRequest);
  const requestedByPeer = changeRoleRequest?.requestedBy;
  const requestRoleChange = (peerId, role, force = false) =>
    hmsActions.changeRoleOfPeer(peerId, role, force);
  const acceptRoleChange = () => hmsActions.acceptChangeRole(changeRoleRequest);
  const rejectRoleChange = () => hmsActions.rejectChangeRole(changeRoleRequest);

  return {
    hasRoleChangeRequest: !!changeRoleRequest,
    requestedByPeer,
    requestRoleChange,
    acceptRoleChange,
    rejectRoleChange,
  };
};
