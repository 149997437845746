import classNames from "classnames";
import { t } from "i18n-js";
import { useAddLinkModal } from "@circle-react/components/Modals/AddLinkModal";
import { BlockInViewOnlyMode } from "@circle-react/components/ViewOnlyMasquerading/BlockInViewOnlyMode";
import { Icon } from "@circle-react/components/shared/Icon";
import { useIsV3 } from "@circle-react/hooks/useIsV3";

export const AddLinkButton = () => {
  const addLinkModal = useAddLinkModal();
  const { isV3Enabled } = useIsV3();

  return (
    <BlockInViewOnlyMode>
      <button
        type="button"
        className={classNames(
          "text-c-sidebar hover:text-c-sidebar hover:bg-c-sidebar-hover focus:bg-c-sidebar-hover flex w-full items-center px-2",
          {
            "rounded-md py-1.5 text-sm": !isV3Enabled,
            "text-xs-plus h-8.5 rounded-lg px-4 font-medium transition-colors duration-200":
              isV3Enabled,
          },
        )}
        onClick={() => addLinkModal.show()}
      >
        <Icon type="16-add-new" size={16} />
        <span className="ml-2">{t("add_link")}</span>
      </button>
    </BlockInViewOnlyMode>
  );
};
