import { usePunditUserContext } from "@circle-react/contexts";
import { isCommunityAdmin } from "@circle-react/helpers/communityMemberHelpers";
import { PreviewBarComponent } from "./PreviewBarComponent";
import { useShouldDisplayV3PreviewBar } from "./hooks/useShouldDisplayV3PreviewBar";

interface OptInBarProps {
  previewBarText: string;
  previewBarCtaContent: string;
}

export const PreviewBar = ({
  previewBarText,
  previewBarCtaContent,
}: OptInBarProps) => {
  const { currentCommunityMember } = usePunditUserContext();
  const isCurrentUserCommunityAdmin = isCommunityAdmin(currentCommunityMember);
  const shouldDisplayV3PreviewBar = useShouldDisplayV3PreviewBar();

  return (
    <PreviewBarComponent
      previewBarText={previewBarText}
      previewBarCtaContent={previewBarCtaContent}
      isAdmin={isCurrentUserCommunityAdmin}
      shouldDisplayV3PreviewBar={shouldDisplayV3PreviewBar}
    />
  );
};
