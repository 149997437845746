export const TYPE = Object.freeze({
  fixed: "PaywallCoupons::Fixed",
  percent: "PaywallCoupons::Percent",
});

export const isFixed = type => type === TYPE.fixed;
export const isPercent = type => type === TYPE.percent;
export const types = TYPE;

export const PAYWALL_COUPON_STATUS = Object.freeze({
  ACTIVE: "active",
  INACTIVE: "inactive",
});

export const PAYWALL_COUPON_STATUSES = [
  PAYWALL_COUPON_STATUS.ACTIVE,
  PAYWALL_COUPON_STATUS.INACTIVE,
];
