import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { canQueryPermissions, hasMediaAccess } from "../helpers";

const MEDIA_DEVICE_TYPE = Object.freeze({
  AUDIO_INPUT: "audioinput",
  AUDIO_OUTPUT: "audiooutput",
  VIDEO_INPUT: "videoinput",
});

const DEVICE_POLLING_INTERVAL_MS = 2000;

export const useMediaDevices = isHost => {
  const [devices, setDevices] = useState([]);
  const [permissionQueryAvailable, setPermissionQueryAvailable] =
    useState(true);
  const isPollingDevices = useRef(false);
  const pollDevicesTimeout = useRef(null);

  const enumerateMediaDevices = useCallback(() => {
    if (hasMediaAccess()) {
      return navigator.mediaDevices
        .enumerateDevices()
        .then(foundDevices => {
          setDevices(foundDevices);
          return foundDevices;
        })
        .catch(console.error);
    }
    return Promise.resolve();
  }, []);

  const pollMediaDevices = useCallback(() => {
    isPollingDevices.current = true;
    pollDevicesTimeout.current = null;
    const areDevicesAvailable = devices =>
      !!devices?.length && devices.some(device => !!device.label?.trim());
    if (areDevicesAvailable(devices)) {
      return;
    }
    enumerateMediaDevices()
      .then(foundDevices => {
        if (areDevicesAvailable(foundDevices)) {
          isPollingDevices.current = false;
        } else {
          pollDevicesTimeout.current = setTimeout(() => {
            pollMediaDevices();
          }, DEVICE_POLLING_INTERVAL_MS);
        }
      })
      .catch(error => {
        console.error(error);
      });
  }, [enumerateMediaDevices, devices]);

  useEffect(() => {
    if (isHost && hasMediaAccess() && !!enumerateMediaDevices) {
      enumerateMediaDevices();
      navigator.mediaDevices.addEventListener(
        "devicechange",
        enumerateMediaDevices,
      );
      return () =>
        navigator.mediaDevices.removeEventListener(
          "devicechange",
          enumerateMediaDevices,
        );
    }
  }, [isHost, enumerateMediaDevices]);

  useEffect(() => {
    if (!enumerateMediaDevices || !canQueryPermissions()) {
      return;
    }
    let micPermissionStatus, cameraPermissionStatus;
    // This is not supported on all browsers,
    // also not necessary for getting devices.
    // We are listening for permission change in order
    // to refresh devices when permission is granted.
    navigator.permissions
      .query({ name: "microphone" })
      .then(permissionStatus => {
        micPermissionStatus = permissionStatus;
        permissionStatus.onchange = () => {
          if (permissionStatus.state === "granted") {
            enumerateMediaDevices();
          }
        };
      })
      .catch(e => {
        console.error(
          "Could not listen for microphone permission status",
          e.message,
        );
        setPermissionQueryAvailable(false);
      });
    navigator.permissions
      .query({ name: "camera" })
      .then(permissionStatus => {
        cameraPermissionStatus = permissionStatus;
        permissionStatus.onchange = () => {
          if (permissionStatus.state === "granted") {
            enumerateMediaDevices();
          }
        };
      })
      .catch(e => {
        console.error(
          "Could not listen for camera permission status",
          e.message,
        );
        setPermissionQueryAvailable(false);
      });

    return () => {
      if (micPermissionStatus) {
        micPermissionStatus.onchange = null;
      }
      if (cameraPermissionStatus) {
        cameraPermissionStatus.onchange = null;
      }
    };
  }, [enumerateMediaDevices]);

  // Check if we need to poll for devices
  useEffect(() => {
    if (permissionQueryAvailable || isPollingDevices.current) {
      return;
    }
    pollMediaDevices();
  }, [permissionQueryAvailable, pollMediaDevices]);

  // Clear the timeout when unmounting
  useEffect(
    () => () => {
      if (pollDevicesTimeout.current) {
        clearTimeout(pollDevicesTimeout.current);
      }
    },
    [],
  );

  const audioInputDevices = useMemo(
    () =>
      devices.filter(device => device.kind === MEDIA_DEVICE_TYPE.AUDIO_INPUT),
    [devices],
  );
  const audioOutputDevices = useMemo(
    () =>
      devices.filter(device => device.kind === MEDIA_DEVICE_TYPE.AUDIO_OUTPUT),
    [devices],
  );
  const videoInputDevices = useMemo(
    () =>
      devices.filter(device => device.kind === MEDIA_DEVICE_TYPE.VIDEO_INPUT),
    [devices],
  );

  return {
    audioInputDevices,
    audioOutputDevices,
    videoInputDevices,
  };
};
