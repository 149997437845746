import { t } from "i18n-js";
import { useMutation } from "react-query";
import { liveStreamApi } from "@circle-react/api";
import { PARTICIPANT_ROLES } from "@circle-react/helpers/liveStreamRoomHelpers";
import { useToast } from "@circle-react-uikit/ToastV2";
import { useProviderRoleChange } from "./provider";

export const useInviteParticipantToModerate = (
  peerId,
  roomId,
  communityMemberId,
  participantId,
) => {
  const { success, error } = useToast();
  const { requestRoleChange } = useProviderRoleChange();
  const inviteModeratorMutation = useMutation(
    () =>
      liveStreamApi.addModerator({
        roomId: roomId,
        formData: {
          moderator_community_member_id: communityMemberId,
          participant_id: participantId,
        },
      }),
    {
      onSuccess: () => {
        requestRoleChange(peerId, PARTICIPANT_ROLES.MODERATOR, true);
        success(
          t("live_streams.room.participant_invited_to_moderator_message"),
        );
      },
      onError: () =>
        error(t("live_streams.room.participant_invited_to_moderator_error")),
    },
  );

  return inviteModeratorMutation.mutate;
};
