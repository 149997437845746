import { t } from "i18n-js";
import { useCreateLiveModal } from "@circle-react/components/Modals/CreateLiveModal";
import { usePunditUserContext } from "@circle-react/contexts";
import { isCommunityAdmin } from "@circle-react/helpers/communityMemberHelpers";
import type { ButtonVariant } from "@circle-react-uikit/Button";
import { Button } from "@circle-react-uikit/Button";

interface GoLiveButtonV2Props {
  variant?: ButtonVariant;
  small?: boolean;
}

export const GoLiveButtonV2 = ({
  variant = "primary",
  small = true,
}: GoLiveButtonV2Props) => {
  const { currentCommunityMember, isLoading } = usePunditUserContext();
  const createLiveModal = useCreateLiveModal();
  const toggleFormOpen = () => {
    void createLiveModal.show();
  };

  if (isLoading || !isCommunityAdmin(currentCommunityMember)) {
    return null;
  }

  return (
    <Button variant={variant} onClick={toggleFormOpen} small={small}>
      <span>{t("live_streams.now_live.go_live")}</span>
    </Button>
  );
};
