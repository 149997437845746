import { useFormContext } from "react-hook-form";
import { ACTIONS } from "./useActions";

const ENTRY_RULE_ID = { id: "0" };

export const useActionsIds = () => {
  const { watch } = useFormContext();
  const formActions = watch(ACTIONS, []);

  const actionsIds = formActions.map((field, index) => ({
    id: field?.id || index + 1,
  }));

  const itemsIds = [ENTRY_RULE_ID, ...actionsIds];

  return {
    itemsIds,
    actionsIds,
  };
};
