import { t } from "i18n-js";
import { Button } from "@circle-react-uikit/Button";
import { Typography } from "@circle-react-uikit/Typography";
import { Block } from "./Block";
import { BlockGroup } from "./BlockGroup";
import { Price } from "./Price";

export const EmptyPaywallBlock = () => (
  <BlockGroup>
    <Block isHighlighted={false} isError={false} isEmpty>
      <div className="border-primary flex flex-col gap-y-2 border-b pb-4">
        <Typography.LabelLg truncate>
          {t("paywalls.lock_screen.preview.empty_block.name")}
        </Typography.LabelLg>
        <Price price={null} currency={null} />
      </div>
      <div className="flex h-full flex-col justify-between gap-y-4">
        <Typography.BodySm color="text-lightest">
          {t("paywalls.lock_screen.preview.empty_block.description")}
        </Typography.BodySm>
        <Button variant="primary" type="button">
          {t("join")}
        </Button>
      </div>
    </Block>
  </BlockGroup>
);
