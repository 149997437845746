import {
  LOCATION_TYPES,
  LOCATION_TYPE_TAGS,
} from "@circle-react/components/Events/helpers/constants";
import { getLongLocalizedDateRange } from "@circle-react/helpers/dateTimeHelpers/getLocalizedRange";
import type { Event } from "@circle-react/types";
import type { IconType } from "@circle-react-shared/Icon";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-uikit/Typography";

export interface EventDetailsProps {
  event?: Event;
}
export const EventDetails = ({ event }: EventDetailsProps) => {
  if (!event?.event_setting_attributes) {
    return null;
  }

  const {
    event_setting_attributes: {
      starts_at: startsAtString,
      ends_at: endsAtString,
      time_zone: timeZone,
      time_zone_abbreviations: timeZoneAbbreviation,
      location_type: locationType,
    },
  } = event;

  const startsAtDate = new Date(startsAtString);
  const endsAtDate = new Date(endsAtString);
  const timeStampWithEndTime = getLongLocalizedDateRange(
    startsAtDate,
    endsAtDate,
    timeZone,
  );

  const entries: Array<{
    icon: IconType;
    text: string;
  }> = [
    {
      icon: "16-calendar",
      text: `${timeStampWithEndTime} (${timeZoneAbbreviation})`,
    },
    {
      icon:
        locationType === LOCATION_TYPES.IN_PERSON ||
        locationType === LOCATION_TYPES.TBD
          ? "16-location"
          : "16-video",
      text: LOCATION_TYPE_TAGS[locationType],
    },
  ];

  return (
    <div className="flex flex-col gap-y-2 pb-3">
      {entries.map(({ icon, text }) => (
        <div key={icon} className="flex items-center gap-x-2">
          <Icon type={icon} size={16} className="text-default !w-4" />
          <Typography.LabelXs>{text}</Typography.LabelXs>
        </div>
      ))}
    </div>
  );
};
