import { SpaceGroupSelect } from "@circle-react/components/shared/SpaceGroupSelect";
import { useSpaceGroupsContext } from "@circle-react/contexts";
import { useFocusOnLoad } from "@circle-react/hooks/useFocusOnLoad";
import { Form } from "@circle-react-uikit/Form";
import { useModalData } from "@circle-react-uikit/ModalV2";

const BasicInfo = () => {
  const inputRef = useFocusOnLoad();
  const spaceGroupsContext = useSpaceGroupsContext();
  const spaceGroups = spaceGroupsContext.records;
  const { contentPortalElement } = useModalData();

  return (
    <>
      <Form.Item
        name="name"
        hideDescription
        translationRoot="spaces.form.create.basic_info"
        rules={{ required: "Name is required!", maxLength: 255 }}
        hideBorder
        fullWidth
        variant="extra-bottom-margin"
      >
        <Form.Input ref={inputRef} prefix={<Form.EmojiPickerV3Wrapper />} />
      </Form.Item>
      <Form.Item
        name="space_group_id"
        translationRoot="spaces.form.create.basic_info"
        hideDescription
        rules={{ required: "Please choose a space group!", maxLength: 255 }}
        hideBorder
        fullWidth
        variant="extra-bottom-margin"
      >
        <SpaceGroupSelect
          variant="form-input"
          spaceGroups={spaceGroups}
          name="space_group_id"
          appendTo={contentPortalElement}
        />
      </Form.Item>
    </>
  );
};

export default BasicInfo;
