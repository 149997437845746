import { t } from "i18n-js";
import {
  dateFormats,
  formattedDateTime,
} from "@circle-react/helpers/dateTimeHelpers/formattedDateTime";
import { PRODUCT_AREAS } from "./constants";

const SELECT_CONVERSATION_TYPE = "select-conversation-type";
export const uuid = () => new Date().getTime().toString();
export const timestamp = () =>
  formattedDateTime({
    dateTime: new Date(),
    format: dateFormats.short_time,
  });

export const buildChatPromptMessage = ({ displayLiveChat }) => {
  const buttons = [
    {
      label: t(
        "support_widget.chatbot.templates.conversation_select_type_answers.question",
      ),
      event: SELECT_CONVERSATION_TYPE,
      value: "question",
    },
    {
      label: t(
        "support_widget.chatbot.templates.conversation_select_type_answers.feedback",
      ),
      event: SELECT_CONVERSATION_TYPE,
      value: "feedback",
    },
    {
      label: t(
        "support_widget.chatbot.templates.conversation_select_type_answers.bug",
      ),
      event: SELECT_CONVERSATION_TYPE,
      value: "bug",
    },
  ];

  if (displayLiveChat) {
    buttons.push({
      label: t(
        "support_widget.chatbot.templates.conversation_select_type_answers.live_chat",
      ),
      event: SELECT_CONVERSATION_TYPE,
      value: "live-chat",
    });
  }

  return {
    content: t("support_widget.chatbot.templates.conversation_select_type"),
    buttons,
  };
};

export const buildFeedbackSelectorMessage = () => ({
  content: t("support_widget.chatbot.templates.feedback_select_product_area"),
  buttons: PRODUCT_AREAS.map(({ text, value }) => ({
    label: text,
    event: "select-product-type",
    value,
  })),
});

export const buildQuestionSelectorMessage = () => ({
  content: t("support_widget.chatbot.templates.question_select_product_area"),
  buttons: PRODUCT_AREAS.map(({ text, value }) => ({
    label: text,
    event: "select-product-type",
    value,
  })),
});

export const buildFeedbackPromptMessage = () => ({
  content: t("support_widget.chatbot.templates.feedback_prompt"),
  buttons: [],
});

export const buildQuestionPrompt = () => ({
  content: t("support_widget.chatbot.templates.question_prompt"),
  buttons: [],
});

export const buildRestartChatPrompt = () => ({
  content: t("support_widget.chatbot.templates.restart_prompt"),
  buttons: [
    {
      label: t("support_widget.chatbot.answer_yes"),
      event: "restart-chat",
      value: null,
    },
    {
      label: t("support_widget.chatbot.answer_no"),
      event: "complete-chat",
      value: null,
    },
  ],
});

export const buildFeedbackReceivedMessage = () => ({
  content: t("support_widget.chatbot.templates.feedback_received"),
  buttons: [],
});

export const buildContactSupportMessage = ({
  displayLiveChat,
  emailWaitTime,
  liveChatWaitTime,
}) => {
  if (displayLiveChat) {
    return {
      content: t("support_widget.chatbot.templates.select_contact_method"),
      buttons: [
        {
          label: t("support_widget.chatbot.contact_methods.live_chat"),
          sublabel: t("support_widget.chatbot.estimated_wait_time", {
            time: liveChatWaitTime,
          }),
          event: SELECT_CONVERSATION_TYPE,
          value: "live-chat",
        },
        {
          label: t("support_widget.chatbot.contact_methods.email"),
          sublabel: t("support_widget.chatbot.estimated_wait_time", {
            time: emailWaitTime,
          }),
          event: "contact-support",
          value: null,
        },
      ],
    };
  }

  return {
    content: t("support_widget.chatbot.templates.reach_out_to_support"),
    buttons: [
      {
        label: t("support_widget.send_a_message"),
        event: "contact-support",
        value: null,
      },
    ],
  };
};

export const buildEnjoyMessage = () => ({
  content: t("support_widget.chatbot.templates.enjoy"),
  buttons: [],
});
