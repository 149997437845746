import { t } from "i18n-js";
import { PlanDetails } from "./PlanDetails";
import { PlanDetailsContainer } from "./PlanDetailsContainer";
import { useCurrentAndSelectedPlans } from "./hooks/useCurrentAndSelectedPlans";

export const SelectedPlanDetails = () => {
  const { selectedPaywall, selectedPaywallPrice } =
    useCurrentAndSelectedPlans();

  if (!selectedPaywall) {
    return null;
  }

  const planDetailsLabel = t(
    "member_profile_modal.billing.subscriptions.price_chooser_step.selected_plan",
  );
  const planName = selectedPaywall.display_name;
  const planPricing = selectedPaywallPrice?.short_description;

  return (
    <PlanDetailsContainer label={planDetailsLabel}>
      <PlanDetails planName={planName} planPricing={planPricing} />
    </PlanDetailsContainer>
  );
};
