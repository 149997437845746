import { useLiveRoom } from "@circle-react/components/LiveStreams/hooks";
import { usePunditUserContext } from "@circle-react/contexts";
import { canCreateLive } from "@circle-react/helpers/communityMemberHelpers";

export const useShouldDisplayGoLiveFooter = () => {
  const { isLoading, currentCommunityMember } = usePunditUserContext();
  const { room: doesRoomAlreadyExist } = useLiveRoom();

  return (
    !isLoading && canCreateLive(currentCommunityMember) && !doesRoomAlreadyExist
  );
};
