import { SortableContainer } from "react-sortable-hoc";
import type { SortableContainerProps } from "react-sortable-hoc";
import type { SpaceGroupWithSpaces } from "@circle-react/components/Spaces/Sidebar/index";
import { usePunditUserContext } from "@circle-react/contexts";
import { isCommunityAdmin } from "@circle-react/helpers/communityMemberHelpers";
import { useLgScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { BottomButton } from "./BottomButton";
import Space from "./Space";
import { SpaceListView } from "./SpaceListView";

export interface SpaceListProps extends SortableContainerProps {
  spaceGroup: SpaceGroupWithSpaces;
  listId: string;
}

export const SpaceList = SortableContainer(
  ({ spaceGroup, listId }: SpaceListProps) => {
    const { currentCommunityMember } = usePunditUserContext();
    const { spaces } = spaceGroup;
    const hasSpaces = spaces.length > 0;
    const isLgScreen = useLgScreenMediaQuery();
    const isAdmin = isCommunityAdmin(currentCommunityMember);
    const shouldDisableDragAndDropForSpaces = !isAdmin || !isLgScreen;

    return (
      <SpaceListView id={listId}>
        {hasSpaces ? (
          spaces.map((space, index) => (
            <Space
              key={space.id}
              index={index}
              space={space}
              disabled={shouldDisableDragAndDropForSpaces}
            />
          ))
        ) : (
          <BottomButton spaceGroup={spaceGroup} />
        )}
      </SpaceListView>
    );
  },
);

SpaceList.displayName = "SpaceList";
