import { useCallback } from "react";
import { t } from "i18n-js";
import { ToggleWithLoader } from "@circle-react-uikit/ToggleWithLoader";

export interface DynamicWorkflowToggleProps {
  isWorkflowActive: boolean;
  isDisabled: boolean;
  hasNoActions?: boolean;
  hasActionsUnsaved?: boolean;
  isDraft?: boolean;
  isLoading: boolean;
  onChange: (newStatus: boolean) => void;
}

const i18nRoot = "settings.workflows.view.header";

export const DynamicWorkflowToggle = ({
  isDisabled,
  isLoading,
  isWorkflowActive,
  hasNoActions,
  hasActionsUnsaved,
  isDraft = false,
  onChange,
}: DynamicWorkflowToggleProps) => {
  const tooltipUncheckedLabel = useCallback(() => {
    if (hasActionsUnsaved) {
      return t([i18nRoot, "incomplete_actions_message"]);
    }

    if (isDraft) {
      return t([i18nRoot, "disabled_tooltip"]);
    }

    if (hasNoActions) {
      return t([i18nRoot, "has_no_actions_message"]);
    }

    return t([i18nRoot, "off_tooltip"]);
  }, [hasActionsUnsaved, hasNoActions, isDraft]);

  const handleChange = useCallback(
    (newStatus: boolean) => {
      onChange?.(newStatus);
    },
    [onChange],
  );

  return (
    <ToggleWithLoader
      isChecked={isWorkflowActive}
      checkedLabel={t([i18nRoot, "on_value"])}
      uncheckedLabel={t([i18nRoot, "off_value"])}
      tooltipCheckedLabel={t([i18nRoot, "on_tooltip"])}
      tooltipUncheckedLabel={tooltipUncheckedLabel()}
      tooltipPlacement="bottom"
      isLoading={isLoading}
      isDisabled={isDisabled}
      onChange={handleChange}
      containerClassName="mx-auto"
      className=""
    />
  );
};
