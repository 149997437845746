import { NavLink, useLocation } from "react-router-dom";
import { useMobileWebFlags } from "@circle-react/hooks/useMobileWebFlags";
import { ItemLink } from "@circle-react-uikit/Dropdown/ItemLink";
import { useSpaceCustomizationModal } from "../../SpaceCustomizationModal";
import { useSortBy } from "./useSortBy";

export interface SortByItemLinksProps {
  defaultSort: string;
  include?: string[];
  exclude?: string[];
}

export const SortByItemLinks = ({
  defaultSort,
  include,
  exclude,
}: SortByItemLinksProps) => {
  const { sortingOptions } = useSortBy({ defaultSort, include, exclude });
  const { isMobileWebNewSpaceCustomizationUIEnabled } = useMobileWebFlags();
  const modal = useSpaceCustomizationModal();
  const location = useLocation();
  const currentSortSelectedValue =
    new URLSearchParams(location.search).get("sort") || defaultSort;

  if (isMobileWebNewSpaceCustomizationUIEnabled) {
    return (
      <div>
        {sortingOptions.map(({ value, label, pathTo }) => (
          <NavLink
            className="text-light block px-5 py-2 text-sm font-medium"
            key={value}
            to={pathTo}
            onClick={modal.hide}
            isActive={() => currentSortSelectedValue === value}
            activeClassName="!text-dark"
          >
            {label}
          </NavLink>
        ))}
      </div>
    );
  }

  return (
    <>
      {sortingOptions.map(({ value, label, pathTo }) => (
        <ItemLink forceClose key={value} to={pathTo}>
          {label}
        </ItemLink>
      ))}
    </>
  );
};
