import PropTypes from "prop-types";
import { t } from "i18n-js";
import { OpenQPModal } from "@/react/components/QuickPost/OpenQPModal";
import { useConfirmModal } from "@circle-react/components/LiveStreams/Room/hooks";
import {
  isEnded,
  roomTypeData,
} from "@circle-react/helpers/liveStreamRoomHelpers";
import { DataTable } from "@circle-react-uikit/DataTableV2";
import { useChatTranscript } from "./hooks/useChatTranscript";
import { useDeleteRecording } from "./hooks/useDeleteRecording";
import { useEndRoom } from "./hooks/useEndRoom";
import { usePostBodyWithRecording } from "./hooks/usePostBodyWithRecording";

export const Actions = ({ room, refetch, setCurrentRoom }) => {
  const { endRoom } = useEndRoom(room, refetch);
  const roomType = roomTypeData(room);
  const { deleteRecording } = useDeleteRecording(room, refetch);
  const { chatTranscript } = useChatTranscript(room, refetch);

  const { confirmModal, onConfirmButtonClick } = useConfirmModal({
    title: t(`live_streams.${roomType}_ended.confirm`),
    onConfirm: endRoom,
  });

  const {
    confirmModal: chatTranscriptModal,
    onConfirmButtonClick: onConfirmChatTranscriptButton,
  } = useConfirmModal({
    title: t("live_streams.chat_transcript.confirm"),
    onConfirm: chatTranscript,
    sentiment: "primary",
  });

  const {
    confirmModal: deleteRecordingModal,
    onConfirmButtonClick: onDeleteRecordingButtonClick,
  } = useConfirmModal({
    title: t("live_streams.delete_recording.confirm"),
    onConfirm: deleteRecording,
    description: t("live_streams.delete_recording.confirm_description"),
  });

  const { trixBodyWithRecording, tiptapBodyWithRecording } =
    usePostBodyWithRecording({ room });

  const menuItems = [
    {
      key: "view-details",
      label: t("live_streams.dashboard.table.actions.view_details"),
      onClick: () => setCurrentRoom(room),
    },
  ];

  if (!isEnded(room)) {
    menuItems.unshift({
      key: "end-stream",
      label: t(`live_streams.dashboard.table.actions.${roomType}.end`),
      onClick: onConfirmButtonClick,
    });
  }

  if (room.recording_url) {
    menuItems.push({
      key: "post-to-space",
      linkComponent: key => (
        <OpenQPModal
          key={key}
          hasIcon={false}
          isButton={false}
          render={({ onClick }) => (
            <DataTable.Actions.Item onClick={onClick}>
              {t("live_streams.dashboard.table.actions.post_to_space")}
            </DataTable.Actions.Item>
          )}
          bodyForEditor={trixBodyWithRecording}
          tiptapBodyForEditor={tiptapBodyWithRecording}
          title={room.name}
        />
      ),
      label: t("live_streams.dashboard.table.actions.post_to_space"),
    });
  }

  if (room.chat_transcript) {
    menuItems.push({
      key: "chat-transcript",
      label: t("live_streams.dashboard.table.actions.chat_transcript"),
      onClick: onConfirmChatTranscriptButton,
    });
  }

  if (room.recording_url) {
    const onDownloadRecordingButtonClick = () => {
      window.open(room.recording_url, "_blank");
    };
    menuItems.push({
      key: "download-recording",
      label: t("live_streams.dashboard.table.actions.download_recording"),
      onClick: onDownloadRecordingButtonClick,
    });
  }

  if (room.transcript_url) {
    const onDownloadTranscriptButtonClick = () => {
      window.open(room.transcript_url, "_blank");
    };
    menuItems.push({
      key: "download-transcript",
      label: t("live_streams.dashboard.table.actions.download_transcript"),
      onClick: onDownloadTranscriptButtonClick,
    });
  }

  if (room.recording_url) {
    menuItems.push({
      key: "delete-recording",
      label: t("live_streams.dashboard.table.actions.delete_recording"),
      hasDanger: true,
      onClick: onDeleteRecordingButtonClick,
    });
  }

  return (
    <>
      {confirmModal}
      {deleteRecordingModal}
      {chatTranscriptModal}
      <DataTable.Actions>
        {menuItems.map(({ key, label, hasDanger, onClick, linkComponent }) => {
          if (key === "post-to-space") {
            return linkComponent(key);
          }

          return (
            <DataTable.Actions.Item
              key={key}
              hasDanger={hasDanger}
              onClick={onClick}
            >
              {label}
            </DataTable.Actions.Item>
          );
        })}
      </DataTable.Actions>
    </>
  );
};

Actions.propTypes = {
  room: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string,
    description: PropTypes.string,
    recording_name: PropTypes.string,
    recording_url: PropTypes.string,
    recording_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    chat_transcript: PropTypes.bool,
  }),
  refetch: PropTypes.func.isRequired,
  setCurrentRoom: PropTypes.func.isRequired,
};
