import { useFormContext } from "react-hook-form";
import { EveryDayOptions } from "./EveryDayOptions";
import { EveryMonthOptions } from "./EveryMonthOptions";
import { EveryWeekOptions } from "./EveryWeekOptions";
import { EveryYearOptions } from "./EveryYearOptions";

const frequencyOptionComponents: any = {
  daily: EveryDayOptions,
  weekly: EveryWeekOptions,
  monthly: EveryMonthOptions,
  yearly: EveryYearOptions,
};

export function FrequencyOptions() {
  const { watch } = useFormContext();
  const frequency = watch("frequency");
  const Component = frequencyOptionComponents[frequency];

  return <Component />;
}
