import { get } from "lodash";
import { useFormContext } from "react-hook-form";
import { getActionHasValue } from "./getActionHasValue";
import { getAudienceHasValue } from "./getAudienceHasValue";
import { hasSomeTruthyValues } from "./hasSomeTruthyValues";
import { hasTruthyValues } from "./hasTruthyValues";

const getHasValue = (name, input) => {
  if (name.startsWith("actions")) {
    return getActionHasValue(input);
  }

  if (name.startsWith("audience")) {
    return getAudienceHasValue(input);
  }

  return hasTruthyValues(input);
};

export const useFieldStatus = name => {
  const { getFieldState, formState, watch } = useFormContext();

  const value = watch(name);
  const { error: inputError } = getFieldState(name, formState);
  const { dirtyFields, touchedFields } = formState;

  const dirtyInputValue = get(dirtyFields, name, null);
  const isDirty = hasSomeTruthyValues(dirtyInputValue);

  const touchedInputValue = get(touchedFields, name, null);
  const isTouched = hasSomeTruthyValues(touchedInputValue);

  const hasId = Boolean(value?.id);
  const hasError = Boolean(inputError);
  const hasValue = getHasValue(name, value);

  return {
    isDirty,
    isTouched,
    hasError,
    hasValue,
    hasId,
    value,
  };
};
