import PropTypes from "prop-types";
import { t } from "i18n-js";
import { Headline } from "@circle-react-shared/uikit/DataTableV2/components/Headline";
import { Button } from "@circle-react-uikit/Button";
import { DataTable } from "@circle-react-uikit/DataTable";
import { Loader } from "@circle-react-uikit/Loader";
import { usePaywallGroupFormModal } from "../FormModal";
import { usePaywallGroupApi } from "../hooks/usePaywallGroupApi";
import { Info } from "./Info";
import { TABLE_COLUMNS } from "./constants";
import "./styles.scss";

export const List = ({ shouldHideHeader = false }) => {
  const paywallGroupFormModal = usePaywallGroupFormModal();
  const { useListPaywallGroups } = usePaywallGroupApi({});
  const {
    data: paywallGroups,
    isLoading: isLoadingPaywallGroups,
    refetch: refetchPaywallGroups,
  } = useListPaywallGroups();

  const openFormModal = ({ paywallGroup = null }) =>
    paywallGroupFormModal.show({
      refetchPaywallGroups: refetchPaywallGroups,
      paywallGroup: paywallGroup,
    });

  const dataSource = paywallGroups?.map(paywallGroup => ({
    key: paywallGroup.id,
    name: <div className="whitespace-normal">{paywallGroup.name}</div>,
    paywalls: (
      <div className="whitespace-normal">
        {paywallGroup.paywalls_summary_text}
      </div>
    ),
    actions: (
      <button
        type="button"
        onClick={() => openFormModal({ paywallGroup: paywallGroup })}
        className="paywall-groups-list__link"
      >
        {t("settings.paywall_groups.actions.edit")}
      </button>
    ),
  }));

  return (
    <div className="paywall-groups-list">
      {!shouldHideHeader && (
        <div className="table-wrapper">
          <div className="flex justify-between">
            <h3>{t("settings.paywall_groups.title")}</h3>
            <Button variant="circle" onClick={openFormModal}>
              {t("settings.paywall_groups.actions.create_group")}
            </Button>
          </div>
        </div>
      )}

      <Info />

      <div>
        {isLoadingPaywallGroups ? (
          <Loader />
        ) : (
          paywallGroups?.length > 0 && (
            <>
              <Headline className="!px-0">
                <Headline.Title>
                  {t("settings.paywall_groups.count", {
                    count: paywallGroups.length,
                  })}
                </Headline.Title>
              </Headline>
              <DataTable
                columns={TABLE_COLUMNS}
                dataSource={dataSource || []}
                hasStickyHeader
                headerClassName="top-[63px]"
              />
            </>
          )
        )}
      </div>
    </div>
  );
};

List.propTypes = {
  shouldHideHeader: PropTypes.bool,
};
