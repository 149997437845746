import type { ReactNode } from "react";
import { createContext, useContext } from "react";
import invariant from "tiny-invariant";
import { useGetMarketingHubStatus } from "@circle-react/hooks/useGetMarketingHubStatus";

const MarketingHubStatusContext = createContext<ReturnType<
  typeof useGetMarketingHubStatus
> | null>(null);

MarketingHubStatusContext.displayName = "MarketingHubStatusContext";

export const useMarketingHubStatus = () => {
  const context = useContext(MarketingHubStatusContext);
  invariant(
    context,
    "useMarketingHubStatus must be used within a MarketingHubStatusProvider",
  );
  return context;
};

interface MarketingHubStatusProviderProps {
  children: ReactNode;
}

export const MarketingHubStatusProvider = ({
  children,
}: MarketingHubStatusProviderProps) => {
  const marketingHubStatus = useGetMarketingHubStatus();

  return (
    <MarketingHubStatusContext.Provider value={marketingHubStatus}>
      {children}
    </MarketingHubStatusContext.Provider>
  );
};
