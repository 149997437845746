import type { ComponentPropsWithoutRef } from "react";
import classNames from "classnames";
import { useMasqueradeEnabledAndActive } from "@circle-react/hooks/useMasqueradeEnabledAndActive";
import { CommunitySwitcherSkeleton as CommunitySwitcherLoader } from "@circle-react-uikit/SkeletonLoader/skeletons/CommunitySwitcher";
import { useTrialBanner } from "../Layout/TrialBanner/useTrialBanner";
import { NewCommunityButton } from "./NewCommunityButton";
import { OverlayScrollbars } from "./OverlayScrollbars";
import { useCommunitySwitcherStyles } from "./useCommunitySwitcherStyles";

export interface CommunitySwitcherPresentationProps
  extends ComponentPropsWithoutRef<"div"> {
  isLoading?: boolean;
}

export const CommunitySwitcherPresentation = ({
  children,
  isLoading = true,
  className,
  ...rest
}: CommunitySwitcherPresentationProps) => {
  const { presentationClasses, bodyClasses, bodyStyles } =
    useCommunitySwitcherStyles();

  const isMasqueradingEnabledAndActive = useMasqueradeEnabledAndActive();
  const { isTrialBannerExists } = useTrialBanner();

  const topStyles = {
    "!top-24": isTrialBannerExists || isMasqueradingEnabledAndActive,
    "!top-36": isMasqueradingEnabledAndActive && isTrialBannerExists,
  };

  return (
    <OverlayScrollbars>
      <div
        data-testid="community-switcher-presentation"
        className={classNames(className, presentationClasses)}
        {...rest}
      >
        {isLoading && <CommunitySwitcherLoader />}
        {!isLoading && (
          <div
            data-testid="community-switcher-presentation-body"
            className={classNames(bodyClasses, topStyles)}
            style={bodyStyles}
          >
            {children}
            <NewCommunityButton key="new-community-button" />
          </div>
        )}
      </div>
    </OverlayScrollbars>
  );
};
