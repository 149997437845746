import PropTypes from "prop-types";
import { noop } from "lodash";

export const CustomSelectInput = ({
  name,
  options,
  onChange = noop,
  disabled = false,
  value = "",
}) => (
  <select
    className="disabled:bg-secondary form-control w-full"
    name={name}
    onChange={onChange}
    disabled={disabled}
    value={value}
  >
    {options.map(({ label, value }) => (
      <option key={value} value={value}>
        {label}
      </option>
    ))}
  </select>
);

CustomSelectInput.propTypes = {
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  value: PropTypes.string,
};
