import { useMemo } from "react";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import type { IconType } from "@circle-react-shared/Icon";

const spaceIconsV2: Record<string, IconType> = {
  coverImageIcon: "16-file-image",
  customizeLessonsIcon: "16-document-text",
  welcomeBannerIcon: "16-welcome-banner",
  customizeIcon: "16-customize",
  creditCardIcon: "credit-card",
  customizeLockScreenIcon: "16-lock",
  customizeMembersIcon: "members-v2",
  inviteMemberIcon: "16-invite-member",
  customizeOptionsIcon: "16-info",
  leaveSpaceIcon: "16-exit",
  deleteSpaceIcon: "16-trash-bin",
};

const spaceIconsV3: Record<string, IconType> = {
  coverImageIcon: "20-image-v3",
  customizeLessonsIcon: "20-doc-v3",
  welcomeBannerIcon: "20-welcome-banner-v3",
  customizeIcon: "20-customize-v3",
  creditCardIcon: "20-card-v3",
  customizeLockScreenIcon: "20-locker-closed-v3",
  customizeMembersIcon: "20-users-v3",
  inviteMemberIcon: "20-user-plus-v3",
  customizeOptionsIcon: "20-info-v3",
  leaveSpaceIcon: "20-sign-out-v3",
  deleteSpaceIcon: "20-bin-v3",
  addSpaceLinksIcon: "20-book-v3",
};

export const useSpaceOptionsIcons = () => {
  const { isV3Enabled } = useIsV3();

  return useMemo(
    () => (isV3Enabled ? spaceIconsV3 : spaceIconsV2),
    [isV3Enabled],
  );
};
