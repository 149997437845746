import PropTypes from "prop-types";
import { t } from "i18n-js";

export const Status = ({ status }) => (
  <span className="flex min-h-[1.5rem] items-center gap-2">
    <span className="break-normal capitalize">
      {t(["settings.workflows.table.status", status], {
        defaultValue: status || "─",
      })}
    </span>
  </span>
);

Status.propTypes = {
  status: PropTypes.string,
};
