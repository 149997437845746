import { useSmScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { TableRow } from "@circle-react-uikit/Table";
import type { CellVariant } from "@circle-react-uikit/Table/TableCell";
import type { Member } from "../types";
import { DropdownColumn } from "./DropDownColumn";
import { JoinedAtColumn } from "./JoinedAtColumn";
import { LastActiveColumn } from "./LastActiveColumn";
import { MemberColumn } from "./MemberColumn";
import { MemberRoleColumn } from "./MemberRoleColumn";
import { PaywallColumn } from "./PaywallColumn";

interface MemberRowProps {
  member: Member;
  onRemove: () => void;
  onEdit: () => void;
  onRoleChange: () => void;
  cellVariant: CellVariant;
  columns: Record<string, string>;
  tableVariant?: "space-members" | "space-group-members";
}

export const MemberRow = ({
  member,
  onRemove,
  onEdit,
  onRoleChange,
  cellVariant = "no-padding",
  tableVariant,
  columns,
}: MemberRowProps) => {
  const isSmScreen = useSmScreenMediaQuery();

  return (
    <TableRow className="border-primary hover:bg-secondary group h-14 border-b">
      <MemberColumn member={member} cellVariant={cellVariant} />
      <MemberRoleColumn
        member={member}
        cellVariant={cellVariant}
        onRoleChange={onRoleChange}
      />
      <JoinedAtColumn
        member={member}
        cellVariant={cellVariant}
        hideColumn={!columns["joinedAt"] || isSmScreen}
      />
      <PaywallColumn
        member={member}
        cellVariant={cellVariant}
        hideColumn={!columns["paywall"]}
      />
      <LastActiveColumn
        member={member}
        cellVariant={cellVariant}
        hideColumn={!columns["lastActive"] || isSmScreen}
      />
      <DropdownColumn
        member={member}
        cellVariant={cellVariant}
        onEdit={onEdit}
        onRemove={onRemove}
        tableVariant={tableVariant}
      />
    </TableRow>
  );
};
