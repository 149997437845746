import classnames from "classnames";
import { t } from "i18n-js";
import { Typography } from "@circle-react-uikit/Typography";
import type { SpaceGroupOption } from "../../SpaceAccessManagementContext";
import { i18nRoot } from "../../constants";
import { Item } from "./Item";
import { ItemButton } from "./ItemButton";
import { ItemTitle } from "./ItemTitle";
import { useParentItem } from "./hooks/useParentItem";

interface ParentAddableItemProp extends SpaceGroupOption {
  isButtonEnabled?: boolean;
  onAddParent: (action: () => void, object: SpaceGroupOption) => void;
}

export const ParentAddableItem = ({
  isButtonEnabled = true,
  onAddParent = action => {
    action();
  },
  ...spaceGroup
}: ParentAddableItemProp) => {
  const { name } = spaceGroup;
  const { isChecked, onAddClick, isFromSearch, isAllSpacesSelected } =
    useParentItem({ spaceGroup, onAddParent });

  return (
    <Item
      isDisabled={isChecked || !isButtonEnabled}
      onClick={onAddClick}
      className={classnames({
        hidden: (isChecked && isAllSpacesSelected) || !isFromSearch,
      })}
    >
      <ItemTitle>
        <Typography.LabelSm weight="semibold">{name}</Typography.LabelSm>
      </ItemTitle>
      {!isChecked && isButtonEnabled && (
        <ItemButton actionText={t([i18nRoot, "add"])} />
      )}
    </Item>
  );
};
