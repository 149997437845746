import PropTypes from "prop-types";
import { colord } from "colord";
import { blobPath } from "@circle-react/helpers/urlHelpers";
import { CustomLinkButton } from "./CustomLinkButton";

export const BannerBlock = ({
  heading = "",
  description = "",
  button_label = "",
  button_link = "",
  background_image = "",
  overlay_color = "#191B1F",
  heading_color = "#FFFFFF",
  text_color = "#FFFFFF",
  button_background_color = "#2B2E33",
  button_text_color = "#FFFFFF",
}) => {
  const overlay = colord(overlay_color).alpha(0.75).toRgbString();

  let imageUrl = "";

  // background_image can be either an URL or an Active Storage ID
  if (background_image) {
    try {
      imageUrl = new URL(background_image).href;
    } catch {
      imageUrl = blobPath({ signed_id: background_image });
    }
  }

  return (
    <div
      className="flex w-full flex-col items-center bg-cover bg-center py-14 bg-blend-multiply"
      style={{
        backgroundImage: `url(${imageUrl})`,
        backgroundColor: overlay,
      }}
    >
      <div className="max-w-3xl px-5 text-center">
        {Boolean(heading) && (
          <h2
            className="mb-3 text-4xl font-bold leading-10"
            style={{ color: heading_color }}
          >
            {heading}
          </h2>
        )}
        {Boolean(description) && (
          <p
            className="mb-5 text-base leading-6 tracking-tighter"
            style={{ color: text_color }}
          >
            {description}
          </p>
        )}
        {Boolean(button_label) && (
          <CustomLinkButton
            href={button_link}
            style={{
              backgroundColor: button_background_color,
              color: button_text_color,
            }}
          >
            {button_label}
          </CustomLinkButton>
        )}
      </div>
    </div>
  );
};

BannerBlock.propTypes = {
  heading: PropTypes.string,
  description: PropTypes.string,
  button_label: PropTypes.string,
  button_link: PropTypes.string,
  background_image: PropTypes.string,
  overlay_color: PropTypes.string,
  heading_color: PropTypes.string,
  text_color: PropTypes.string,
  button_background_color: PropTypes.string,
  button_text_color: PropTypes.string,
};
