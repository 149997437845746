import PropTypes from "prop-types";
import classNames from "classnames";
import { useLocation } from "react-router-dom";
import { BottomMenu } from "../BottomMenu";
import { useSupportWidgetContext } from "../SupportWidgetContext";

export const SupportWidgetContainer = ({ children }) => {
  const location = useLocation();
  const { isExpanded } = useSupportWidgetContext();
  const isBottomMenuVisible =
    location.pathname !== "/ask" &&
    !location.pathname.match(/\/conversations\/\d+/);

  if (isBottomMenuVisible) {
    return (
      <>
        <div
          className={classNames(
            "xxl:h-full support-widget-container border-primary bg-primary hidden origin-bottom-right overflow-y-auto rounded-t-lg border-x border-t lg:flex lg:flex-col",
            {
              "h-[min(75vh,100vh-224px)] max-h-[75vh] min-h-[min(580px,100vh-224px)] w-[688px]":
                isExpanded,
              "h-[min(580px,100vh-224px)] max-h-[580px] min-h-[80px] w-[400px]":
                !isExpanded,
            },
          )}
          style={{
            transition:
              "width 200ms ease 0s, height 200ms ease 0s, max-height 200ms ease 0s, transform 300ms cubic-bezier(0, 1.2, 1, 1) 0s",
          }}
        >
          {children}
        </div>
        <div className="border-primary bg-primary hidden origin-bottom-right rounded-b-lg border-y border-l shadow-lg lg:flex">
          <BottomMenu />
        </div>
      </>
    );
  }
  return (
    <div
      className={classNames(
        "xxl:h-full support-widget-container border-primary bg-primary hidden origin-bottom-right overflow-y-auto rounded-t-lg border lg:flex lg:flex-col",
        {
          "h-[min(75vh,100vh-224px)] max-h-[75vh] min-h-[min(658px,100vh-224px)] w-[688px]":
            isExpanded,
          "h-[min(658px,100vh-224px)] max-h-[658px] min-h-[80px] w-[400px]":
            !isExpanded,
        },
      )}
      style={{
        transition:
          "width 200ms ease 0s, height 200ms ease 0s, max-height 200ms ease 0s, transform 300ms cubic-bezier(0, 1.2, 1, 1) 0s",
      }}
    >
      {children}
    </div>
  );
};

SupportWidgetContainer.propTypes = {
  children: PropTypes.node,
};
