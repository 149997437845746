import { t } from "i18n-js";
import { InfoBox } from "@circle-react-shared/uikit/InfoBox";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { RepurchaseForm } from "./Forms/RepurchaseForm";
import { SelfCancelableForm } from "./Forms/SelfCancelableForm";
import { SubscriptionGroupForm } from "./Forms/SubscriptionGroupForm";
import { TrialForm } from "./Forms/TrialForm";

export const PricingSettings = () => (
  <InfoBox className="border-primary flex flex-col gap-y-4 border px-6">
    <Typography.LabelLg weight="bold">
      {t("settings.paywalls.modal.tabs.pricing.settings_title")}
    </Typography.LabelLg>
    <div className="flex flex-col gap-y-3.5">
      <RepurchaseForm />
      <SubscriptionGroupForm />
      <TrialForm />
      <SelfCancelableForm />
    </div>
  </InfoBox>
);
