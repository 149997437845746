import { useQuery } from "react-query";
import { reactQueryGet } from "@/react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { usePaymentProcessorStatus } from "./usePaymentProcessorStatus";

export const usePaywallsAdmin = ({ paymentProcessor }) => {
  const fetchPaywalls = async () => reactQueryGet(internalApi.paywalls.index());
  const {
    data: paywalls,
    refetch: refetchPaywalls,
    isLoading: isPaywallsLoading,
  } = useQuery("paywalls", fetchPaywalls);

  const { paymentProcessorStatus } = usePaymentProcessorStatus({
    paymentProcessor,
  });

  return {
    paywalls,
    refetchPaywalls,
    isPaywallsLoading,
    paymentProcessorStatus,
  };
};
