import { t } from "i18n-js";
import { useRouteMatch } from "react-router-dom";
import { Button } from "@circle-react-shared/uikit/Button";
import { useSettingsHeader } from "@circle-react-shared/uikit/HeaderV3/useSettingsHeader";
import { HelloBar } from "@circle-react-shared/uikit/HelloBar";
import { useActivationConfirmationModal } from "../ActivationConfirmationModal";
import { useUpdateOptInSettings } from "../OptInLandingPage/hooks/useUpdateOptInSettings";
import { PREVIEW_BAR_DISABLED_ROUTES } from "./constants";

export interface PreviewBarProps {
  previewBarText: string;
  previewBarCtaContent: string;
  isAdmin?: boolean;
  shouldDisplayV3PreviewBar?: boolean;
}

export const PreviewBarComponent = ({
  previewBarText,
  previewBarCtaContent,
  isAdmin = false,
  shouldDisplayV3PreviewBar = false,
}: PreviewBarProps) => {
  const { isSettingsRoute } = useSettingsHeader();
  const isOptInBarDisabledOnRoute = useRouteMatch(PREVIEW_BAR_DISABLED_ROUTES);
  const mutation = useUpdateOptInSettings();
  const activationConfirmationModal = useActivationConfirmationModal();

  const shouldNotDisplayOptInBar =
    !shouldDisplayV3PreviewBar ||
    !isAdmin ||
    isOptInBarDisabledOnRoute ||
    isSettingsRoute;

  if (shouldNotDisplayOptInBar) return null;

  return (
    <HelloBar
      exitText={t("new_version_preview_bar.preview_bar_go_back")}
      onExit={() => mutation.mutate({ operation: "rollback_admin" })}
    >
      <div className="flex items-center space-x-6">
        <span>{previewBarText}</span>
        <Button
          variant="secondary"
          isFullRounded
          disabled={mutation.isLoading}
          onClick={() => activationConfirmationModal.show()}
        >
          {previewBarCtaContent}
        </Button>
      </div>
    </HelloBar>
  );
};
