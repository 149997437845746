import { parse } from "qs";
import { useLocation } from "react-router-dom";
import type { AnyObjectSchema, InferType } from "yup";

export const useRouterQueryParams = <T extends AnyObjectSchema>(
  yupSchema: T,
): InferType<T> => {
  const location = useLocation();
  const { search } = location;

  const parsedQueryParams = parse(search, {
    ignoreQueryPrefix: true,
  });

  try {
    return yupSchema.cast(parsedQueryParams);
  } catch (error) {
    console.error("Error parsing query params", error);
    return {};
  }
};
