import { t } from "i18n-js";
import { useModal } from "@circle-react/components/Layout/ModalManagerProvider";
import { ConfirmationModal } from "@circle-react-shared/ModalsV2/ConfirmationModal";
import { useDeleteSnippet } from "../queries";

export const DeleteSnippetModalComponent = ({
  id,
  title,
}: {
  id: number;
  title: string;
}) => {
  const modal = useModal();
  const deleteSnippetMutation = useDeleteSnippet({
    onSuccess: modal.remove,
  });

  return (
    <ConfirmationModal
      size="md"
      isOpen={modal.visible}
      onClose={modal.hide}
      title={t(
        "settings.community_bot.knowledge.custom.delete_snippet_modal.title",
      )}
      confirmVariant="danger"
      confirmText={
        deleteSnippetMutation.isLoading
          ? t(
              "settings.community_bot.knowledge.custom.delete_snippet_modal.confirming",
            )
          : t(
              "settings.community_bot.knowledge.custom.delete_snippet_modal.confirm",
            )
      }
      onConfirm={() => {
        deleteSnippetMutation.mutate({ id });
      }}
      disabled={deleteSnippetMutation.isLoading}
    >
      {t(
        "settings.community_bot.knowledge.custom.delete_snippet_modal.description",
        { title },
      )}
    </ConfirmationModal>
  );
};
