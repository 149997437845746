export const mapSettingsToDefaultFromValues = settings => {
  const { community, community_setting } = settings;
  const { meta_tags } = community_setting;
  return {
    logged_in_description:
      community.logged_in_user_home_page_banner_description,
    logged_in_media: community.logged_in_user_home_page_banner_media,
    logged_in_title: community_setting.logged_in_user_home_page_banner.title,
    allow_close: community_setting.logged_in_user_home_page_banner.allow_close,
    logged_in_banner_enabled:
      community_setting.logged_in_user_home_page_banner.enabled,
    logged_out_description:
      community.logged_out_user_home_page_banner_description,
    logged_out_media: community.logged_out_user_home_page_banner_media,
    logged_out_title: community_setting.logged_out_user_home_page_banner.title,
    logged_out_banner_enabled:
      community_setting.logged_out_user_home_page_banner.enabled,
    trending_posts_window_in_days:
      community_setting.trending_posts_window_in_days,
    default_post_sort_logged_in_user:
      community_setting.default_post_sort_logged_in_user,
    default_post_sort_logged_out_user:
      community_setting.default_post_sort_logged_out_user,
    include_non_member_space_posts:
      community_setting.include_non_member_space_posts,
    ...meta_tags,
    opengraph_image: meta_tags.opengraph_image,
  };
};

export const mapFormValuesToSettings = (formData, settings) => {
  const { community, community_setting } = settings;
  const { meta_tags } = community_setting;
  return {
    ...settings,
    community: {
      ...community,
      logged_in_user_home_page_banner_description:
        formData.logged_in_description,
      logged_out_user_home_page_banner_description:
        formData.logged_out_description,
      logged_in_user_home_page_banner_media: formData.logged_in_media,
      logged_out_user_home_page_banner_media: formData.logged_out_media,
    },
    community_setting: {
      ...community_setting,
      trending_posts_window_in_days: formData.trending_posts_window_in_days,
      default_post_sort_logged_in_user:
        formData.default_post_sort_logged_in_user,
      default_post_sort_logged_out_user:
        formData.default_post_sort_logged_out_user,
      include_non_member_space_posts: formData.include_non_member_space_posts,
      logged_in_user_home_page_banner: {
        ...community_setting.logged_in_user_home_page_banner,
        title: formData.logged_in_title,
        enabled: formData.logged_in_banner_enabled,
        allow_close: formData.allow_close,
      },
      logged_out_user_home_page_banner: {
        ...community_setting.logged_out_user_home_page_banner,
        title: formData.logged_out_title,
        enabled: formData.logged_out_banner_enabled,
      },
      meta_tags: {
        ...meta_tags,
        meta_title: formData.meta_title,
        meta_description: formData.meta_description,
        opengraph_title: formData.opengraph_title,
        opengraph_description: formData.opengraph_description,
        opengraph_image: formData.opengraph_image,
      },
    },
  };
};

export const BANNER_IMAGE_KEYS = {
  logged_in_media: "logged_in_user_home_page_banner_media",
  logged_out_media: "logged_out_user_home_page_banner_media",
};
