import { useMemo } from "react";
import {
  selectHandRaisedPeers,
  selectPeers,
  useHMSStore,
} from "@100mslive/react-sdk";
import { PARTICIPANT_ROLES } from "@circle-react/helpers/liveStreamRoomHelpers";

export const useHMSPeersList = () => {
  const peers = useHMSStore(selectPeers);
  const hosts = useMemo(
    () => peers.filter(peer => peer.roleName === PARTICIPANT_ROLES.HOST),
    [peers],
  );
  const peersWithHandRaised = useHMSStore(selectHandRaisedPeers);
  // We will keep supporting both "spectator" (webrtc participant) as well as "hlsspectator" (hls participant)
  // for now. This is because mobile may use "spectator" and also because we may want to rollback to using "spectator".
  const spectators = useMemo(
    () =>
      peers.filter(peer =>
        [PARTICIPANT_ROLES.SPECTATOR, PARTICIPANT_ROLES.HLS_SPECTATOR].includes(
          peer.roleName,
        ),
      ),
    [peers],
  );
  const moderators = useMemo(
    () => peers.filter(peer => peer.roleName === PARTICIPANT_ROLES.MODERATOR),
    [peers],
  );

  return {
    peers,
    peersWithHandRaised,
    hosts,
    spectators,
    moderators,
  };
};
