import { useLiveStreamContextData } from "@circle-react/contexts/LiveStreams";
import { ROOM_TYPES } from "@circle-react/helpers/liveStreamRoomHelpers";
import {
  isConference,
  isStream,
} from "@circle-react/helpers/liveStreamRoomHelpers";

export const useRoomType = () => {
  const { room } = useLiveStreamContextData();
  const roomType = room?.room_type || ROOM_TYPES.STREAM;
  const isLiveRoom = isConference(room);
  const isLiveStream = isStream(room);

  return {
    roomType,
    isLiveRoom,
    isLiveStream,
  };
};
