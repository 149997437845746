import { useEffect, useState } from "react";
import { t } from "i18n-js";
import uniqBy from "lodash/uniqBy";
import { internalApi } from "../../../helpers/urlHelpers";
import { LoaderRow } from "../../shared/LoaderRow";
import { Form } from "../Form";
import { Show } from "../Show";
import { mayBeJumpToCommentUsingLocationHash } from "../helpers";

export const ListComponent = props => {
  const [comments, setComments] = useState(props.comments || []);
  const [page, setPage] = useState(props.parent_comment_id ? 0 : 1);

  const hasMoreComments = props.parent_comment
    ? parseInt(props.parent_comment.replies_count, 10) > comments.length
    : true;

  const [hasNextPage, setHasNextPage] = useState(hasMoreComments);

  const [loading, setLoading] = useState(
    props.parent_comment_id ? false : hasNextPage,
  );

  const [scrolledIntoView, setScrolledIntoView] = useState(false);

  useEffect(() => {
    const fetchNextPage = async () => {
      if (!hasNextPage) {
        return;
      }

      if (page === 0 && comments.length) {
        return;
      }

      const perPage = 10;
      const nextPage = page || 1;
      const params = { page: nextPage, per_page: perPage };

      if (props.parent_comment_id) {
        params.parent_comment_id = props.parent_comment_id;
      }

      const response = await fetch(
        internalApi.comments.index({
          postId: props.post.id,
          params,
        }),
      );

      if (response.ok) {
        const data = await response.json();
        setComments(uniqBy([...comments, ...data.records], "id"));
        setHasNextPage(data.has_next_page);
        setLoading(false);
        props.onPageChange && props.onPageChange(page);
        setTimeout(window.initialiseTippy, 300);
      } else {
        setLoading(false);
      }
    };
    fetchNextPage();
  }, [page]);

  useEffect(() => {
    if (!loading && !scrolledIntoView && !props.parent_comment_id) {
      setScrolledIntoView(true);
      mayBeJumpToCommentUsingLocationHash();
    }
  }, [loading, scrolledIntoView, props.parent_comment_id]);

  const onNewComment = comment => {
    setComments([...comments, comment]);
  };

  const linkText = props.parent_comment_id
    ? t("comments.list.show_more_replies")
    : t("comments.list.show_more_comments");

  const linkClass = props.parent_comment_id
    ? "load-more__replies"
    : "btn btn-secondary load-more__comments";

  return (
    <>
      {comments.map(comment => (
        <Show key={comment.id} {...props} comment={comment} />
      ))}
      {loading && <LoaderRow />}
      {!loading && hasNextPage && (
        <div className="load-more">
          <a
            href="#load-more"
            onClick={event => {
              event.preventDefault();
              setPage(page + 1);
            }}
            className={linkClass}
          >
            {linkText}
          </a>
        </div>
      )}
      {Boolean(props?.showReplyForm) && (
        <Form
          post={props.post}
          space={props.space}
          communityMember={props.communityMember}
          parent_comment_id={props.parent_comment_id}
          onNewComment={onNewComment}
          isPost
          ref={props.formRef}
        />
      )}
    </>
  );
};
