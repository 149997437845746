import InfiniteScroll from "react-infinite-scroll-component";
import { useCommunityMembersInfiniteQuery } from "@circle-react/hooks/useCommunityMembersInfiniteQuery";
import { EMAIL_REGEX } from "@circle-react-uikit/CommunityMemberSelect";
import { Loader } from "@circle-react-uikit/Loader";
import { Table, TableBody } from "@circle-react-uikit/Table";
import { Row } from "./Row";

const stringIsEmail = (text: string) => EMAIL_REGEX.test("" + text);

export interface MembersToAddListProps {
  memberIds: string[];
  onRemove: (memberId: string) => void;
}

export const MembersToAddList = ({
  memberIds = [],
  onRemove,
}: MembersToAddListProps) => {
  const hasIds = memberIds.length > 0;

  const { hasNextPage, fetchNextPage, members } =
    useCommunityMembersInfiniteQuery({
      filters: { id: memberIds },
      options: { enabled: hasIds, keepPreviousData: hasIds },
    });

  if (!hasIds) {
    return null;
  }

  return (
    <div className="-mx-4 mt-4 h-full max-h-80 overflow-y-auto">
      <InfiniteScroll
        loader={<Loader />}
        dataLength={memberIds.length}
        style={{ overflow: "initial" }}
        scrollThreshold={0.5}
        next={fetchNextPage}
        hasMore={Boolean(hasNextPage)}
      >
        <Table
          variant="no-border"
          className="w-full max-w-full"
          tableClassName="w-full"
        >
          <TableBody>
            {memberIds.map(id => {
              if (stringIsEmail(id)) {
                return (
                  <Row
                    key={id}
                    id={id}
                    name={id}
                    onRemove={onRemove}
                    avatarUrl=""
                  />
                );
              }
              return null;
            })}
            {members.map(({ id, avatar_url, name }) => (
              <Row
                key={id}
                id={id}
                avatarUrl={avatar_url}
                name={name}
                onRemove={onRemove}
              />
            ))}
            {/* FIXME Bug: After adding and removing a member, the infinity scroll loader randomly does not disappear */}
            {/* {isFetching && (
              <TableRow>
                <TableCell variant="no-padding" colSpan="3">
                  <Loader />
                </TableCell>
              </TableRow>
            )} */}
          </TableBody>
        </Table>
      </InfiniteScroll>
    </div>
  );
};
