import { t } from "i18n-js";
import { PLATFORMS } from "@circle-react/helpers/communityHelpers";
import {
  CheckboxFilter,
  CheckboxSelectedText,
  Filter,
  useFilterUrl,
} from "@circle-react-shared/Filter";

const localeRoot = "settings.paywalls_admin_dashboard.filters.platform";
const queryParamsKey = "platform";

export const PlatformFilter = ({
  isInitialOpen,
  changeUrl = true,
  value,
}: any) => {
  const { queryParams, updateUrl } = useFilterUrl();
  const platforms = Object.values(PLATFORMS).map(platform => ({
    label: t([localeRoot, "options", platform]),
    value: platform,
  }));

  return (
    <Filter
      chip={t([localeRoot, "chip"])}
      title={t([localeRoot, "title"])}
      selectedText={value || queryParams[queryParamsKey]}
      renderSelectedText={props => (
        <CheckboxSelectedText items={platforms} {...props} />
      )}
      isInitialOpen={isInitialOpen}
      onApply={value => {
        updateUrl("page", 1);
        changeUrl && updateUrl(queryParamsKey, value);
      }}
      shouldShowRemoveButton
    >
      <CheckboxFilter
        options={platforms}
        legend={t([localeRoot, "legend"])}
        name="platform-filter"
        activeText={value ?? queryParams[queryParamsKey]}
      />
    </Filter>
  );
};

export const filterProperties = {
  name: "platform",
  filterKeys: [queryParamsKey],
  component: PlatformFilter,
  label: t([localeRoot, "chip"]),
};
