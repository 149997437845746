import { t } from "i18n-js";
import { useCreatePaywallSubscriptionFormContext } from "@circle-react-shared/Paywalls/Modals/CreatePaywallSubscriptionModal/Form/CreatePaywallSubscriptionFormContextProvider";
import { usePaywallPriceForm } from "@circle-react-shared/Paywalls/Modals/CreatePaywallSubscriptionModal/Form/hooks/usePaywallPriceForm";
import { useBulkImportTrialPaywallSubscriptionFormContext } from "./BulkImportTrialPaywallSubscriptionFormProvider";
import { CustomFormItem } from "./CustomFormItem";
import { CustomSelectInput } from "./CustomSelectInput";

const defaultPaywallPriceSelected = {
  label: t("bulk_import_page.paywall.choose_term"),
  value: "",
};

export const PaywallPriceForm = () => {
  const { paywallId, paywallPriceId, setPaywallPriceId } =
    useBulkImportTrialPaywallSubscriptionFormContext();
  const { paywallsIndex } = useCreatePaywallSubscriptionFormContext();
  const { paywallPriceOptions } = usePaywallPriceForm({
    paywall: paywallsIndex[paywallId],
  });

  const onPaywallIdChange = e => {
    setPaywallPriceId(e.target.value ?? paywallPriceId);
  };

  return (
    <CustomFormItem
      label={t("create_paywall_subscription_modal.form.paywall_price_id")}
    >
      <CustomSelectInput
        name="paywall[paywall_price_id]"
        options={[defaultPaywallPriceSelected, ...paywallPriceOptions]}
        disabled={paywallPriceOptions.length === 0}
        onChange={onPaywallIdChange}
        value={paywallPriceId}
      />
    </CustomFormItem>
  );
};
