import { useFormContext } from "react-hook-form";
import { formattedDateTime } from "@circle-react/helpers/dateTimeHelpers/formattedDateTime";
import { DefaultOptionsContainer } from "./DefaultOptionsContainer";
import { useGetMessage } from "./useGetMessage";

export function EveryYearOptions() {
  const { watch } = useFormContext();
  const firstRun = watch("first_run_at");
  const message = useGetMessage("every_year_on_until", "every_year_on");

  return (
    <DefaultOptionsContainer
      i18n={["settings.workflows.recurring_schedule_modal", message]}
      variables={{
        recurring_time: firstRun
          ? formattedDateTime({
              dateTime: new Date(firstRun),
              format: "short_month_and_date",
            })
          : null,
      }}
    />
  );
}
