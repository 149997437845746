import PropTypes from "prop-types";
import { Loader } from "@circle-react-uikit/Loader";
import { Typography } from "@circle-react-uikit/Typography";
import { Badge } from "../../Badge";
import { Resource } from "./Resource";

export const BaseResource = ({
  label,
  value,
  isLoading,
  shouldRepresentAsBadge = false,
}) => (
  <Resource>
    <Resource.Section>
      <Resource.Title>{label}</Resource.Title>
      <div>
        {shouldRepresentAsBadge ? (
          <ValueAsBadge value={value} isLoading={isLoading} />
        ) : (
          <ValueAsText value={value} isLoading={isLoading} />
        )}
      </div>
    </Resource.Section>
  </Resource>
);

const ValueAsBadge = ({ value, isLoading }) =>
  isLoading ? <Badge isLoading /> : <Badge text={value} />;

const ValueAsText = ({ value, isLoading }) =>
  isLoading ? <Loader /> : <Typography.LabelSm>{value}</Typography.LabelSm>;

BaseResource.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  isLoading: PropTypes.bool,
  shouldRepresentAsBadge: PropTypes.bool,
};

ValueAsBadge.propTypes = {
  value: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
};

ValueAsText.propTypes = {
  value: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
};
