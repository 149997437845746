import { truncate } from "lodash";
import { Link } from "react-router-dom";
import { BodySm } from "@circle-react/components/shared/uikit/Typography/components/Body/BodySm";
import { LabelMd } from "@circle-react/components/shared/uikit/Typography/components/Label/LabelMd";
import "./styles.css";

export const NewsPost = ({ post }) => (
  <Link
    className="border-primary bg-primary mb-4 block cursor-pointer rounded-lg border p-4"
    to={`/news/${post.id}`}
  >
    {post.cover_image_url && (
      <div>
        <img
          loading="lazy"
          alt=""
          className="mb-4 rounded"
          src={post.cover_image_url}
        />
      </div>
    )}
    <div className="text-dark mb-1">
      <div className="news-title pb-3">
        <LabelMd weight="semibold">{post.name}</LabelMd>
      </div>
      <div className="news-excerpt">
        <BodySm>{truncate(post.body_plain_text, { length: 200 })}</BodySm>
      </div>
    </div>
  </Link>
);
