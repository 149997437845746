import { Button } from "@circle-react-uikit/Button";

export const Footer = ({ label }: { label: string }) => (
  <footer
    className="border-primary flex items-center justify-end border-t px-5 py-4"
    data-testid="quick-post-footer"
  >
    <div className="flex items-center space-x-2">
      <Button disabled={false} type="submit" variant="circle">
        {label}
      </Button>
    </div>
  </footer>
);
