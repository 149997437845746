import type { ReactNode } from "react";
import { compact } from "lodash";
import { Helmet } from "react-helmet";
import { useAppendBodyClasses } from "@/react/hooks/useAppendBodyClasses";
import { usePunditUserContext } from "@circle-react/contexts";

interface Props {
  title?: string | null;
  bodyClassNames?: string;
  children?: ReactNode;
}

export const PageMetaData = ({
  title,
  bodyClassNames = "",
  children,
}: Props) => {
  const { currentCommunity } = usePunditUserContext();
  const fullTitle = compact([title, currentCommunity?.name]).join(" | ");
  useAppendBodyClasses(bodyClassNames);
  return (
    <>
      <Helmet>
        <title>{fullTitle}</title>
      </Helmet>

      {children}
    </>
  );
};
