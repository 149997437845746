import PropTypes from "prop-types";
import { t } from "i18n-js";
import { useMutation } from "react-query";
import { reactQueryDelete } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { useCourseSections } from "@circle-react/hooks/courses/useCoursesApi";
import { ConfirmationModal } from "@circle-react-shared/ModalsV2/ConfirmationModal";

export const ConfirmSectionDelete = ({
  show,
  name,
  onClose,
  courseId,
  sectionId,
}) => {
  const { refetch: refetchCourseSections } = useCourseSections(courseId);
  const deleteSectionMutation = useMutation(
    () =>
      reactQueryDelete(
        internalApi.courses.deleteSection({ courseId, sectionId }),
      ),
    {
      onSuccess: async () => {
        await refetchCourseSections();
        onClose();
      },
    },
  );

  return (
    <ConfirmationModal
      isOpen={show}
      title={t("courses.edit.sections.delete_section")}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={deleteSectionMutation.mutate}
      confirmVariant="danger"
      disabled={deleteSectionMutation.isLoading}
      confirmText={
        deleteSectionMutation.isLoading ? t("deleting") : t("confirm")
      }
    >
      {t("courses.edit.sections.delete_confirmation", { name })}
    </ConfirmationModal>
  );
};

ConfirmSectionDelete.propTypes = {
  show: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  courseId: PropTypes.string.isRequired,
  sectionId: PropTypes.string.isRequired,
};
