import {
  reactQueryDelete,
  reactQueryGet,
  reactQueryPost,
} from "@/react/helpers/backendRequestHelpers";
import { internalApi } from "@/react/helpers/urlHelpers";

const index = ({ filters, order, page, perPage, searchKey }) => {
  const searchKeyObject = searchKey ? { search_key: searchKey } : {};

  return () =>
    reactQueryGet(
      internalApi.admin.paywalls.subscriptions.index({
        params: {
          filters,
          order,
          page,
          per_page: perPage,
          ...searchKeyObject,
        },
      }),
    );
};

const refundOptionsSubscription = ({ subscriptionProcessorId }) =>
  reactQueryGet(
    internalApi.admin.paywalls.subscriptions.refundOptions({
      subscriptionProcessorId,
    }),
  );

const create = ({ params }) =>
  reactQueryPost(internalApi.admin.paywalls.subscriptions.create(), params);

const cancelAndRefundSubscription = ({ subscriptionProcessorId, params }) =>
  reactQueryPost(
    internalApi.admin.paywalls.subscriptions.cancellations({
      subscriptionProcessorId,
    }),
    params,
  );

const resumeSubscription = ({ subscriptionProcessorId }) =>
  reactQueryDelete(
    internalApi.admin.paywalls.subscriptions.cancellations({
      subscriptionProcessorId,
    }),
  );

export const paywallSubscriptionsApi = {
  index,
  create,
  cancelAndRefundSubscription,
  resumeSubscription,
  refundOptionsSubscription,
};
