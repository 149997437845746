import classNames from "classnames";
import { t } from "i18n-js";
import { useLocation } from "react-router-dom";
import { useProfileDrawer } from "@/react/components/Drawers/ProfileDrawer";
import { isCommunityAdmin } from "@/react/helpers/communityMemberHelpers";
import { authPathsWithRedirect } from "@/react/helpers/urlHelpers";
import { usePunditUserContext } from "@circle-react/contexts";
import { useIfSignupEnabled } from "@circle-react/contexts";
import { memberProfileModalPathsWithState } from "@circle-react/helpers/urlHelpers";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { IconButton } from "@circle-react-shared/uikit/HeaderV3/IconButton";
import { UserImage } from "@circle-react-shared/uikit/UserImage";
import { Dropdown } from "@circle-react-uikit/Dropdown";

export const RightActionContent = () => {
  const { currentUser, currentCommunityMember } = usePunditUserContext();
  const location = useLocation();
  const ifSignupEnabled = useIfSignupEnabled();
  const { show: showMemberProfileDrawer } = useProfileDrawer();
  const isAdmin = isCommunityAdmin(currentCommunityMember);
  const { isV3Enabled } = useIsV3();

  const {
    can_access_billing: shouldShowBilling,
    can_access_authentication_page: shouldShowAuthentication,
  } = currentCommunityMember?.policies || {};

  const LOGGED_IN_MENU_ITEM_LINKS = [
    {
      action: () =>
        showMemberProfileDrawer({
          memberPublicId: currentCommunityMember?.public_uid,
        }),
      label: t("my_profile"),
    },
    {
      to: memberProfileModalPathsWithState.profile(location),
      label: t("edit_profile"),
    },
    {
      to: memberProfileModalPathsWithState.notifications(location),
      label: t("notification"),
    },
    shouldShowAuthentication && {
      to: memberProfileModalPathsWithState.authentication(location),
      label: t("authentication"),
    },
    shouldShowBilling && {
      to: memberProfileModalPathsWithState.billing(location),
      label: t("billing"),
    },
    isAdmin && [
      {
        action: () => (window.location.href = "/analytics"),
        label: t("analytics_title"),
      },
      {
        to: "/audience/manage",
        label: t("audience"),
      },
      {
        to: "/settings",
        label: t("settings_title"),
      },
    ],
  ]
    .flat()
    .filter(Boolean);

  if (currentUser) {
    return (
      <Dropdown
        className="user-menu z-[9999]"
        button={
          <UserImage
            name={currentCommunityMember?.name}
            src={currentCommunityMember?.avatar_url}
            size="8"
          />
        }
        appendTo={document.getElementById("mainHeader") || document.body}
      >
        <div>
          <div
            className={classNames("-mt-2 mb-1.5 w-full py-1", {
              "bg-secondary": isV3Enabled,
            })}
          >
            <Dropdown.ItemWithLink
              to={`/u/${currentCommunityMember?.public_uid}?show_back_link=true`}
            >
              {currentCommunityMember?.name}
            </Dropdown.ItemWithLink>
          </div>

          {LOGGED_IN_MENU_ITEM_LINKS.map(item => (
            <Dropdown.ItemWithLink
              key={item.label}
              to={item.to}
              onClick={item.action}
            >
              {item.label}
            </Dropdown.ItemWithLink>
          ))}

          <Dropdown.Separator />
          <Dropdown.ItemLink
            onClick={() => (window.location.href = "/users/sign_out")}
          >
            {t("sign_out")}
          </Dropdown.ItemLink>
        </div>
      </Dropdown>
    );
  }
  return (
    <div className="user-join__mobile">
      <div className="user-join__menu">
        <Dropdown
          button={
            <IconButton
              name="20-user"
              iconSize={20}
              buttonSize={28}
              ariaLabel={t("log_in")}
              iconClassName="!w-5 !h-5 !text-dark"
            />
          }
          className="z-[9999]"
          appendTo={document.getElementById("mainHeader") || document.body}
        >
          <Dropdown.ItemLink
            onClick={() =>
              (window.location.href = authPathsWithRedirect.signIn())
            }
          >
            {t("log_in")}
          </Dropdown.ItemLink>
          {ifSignupEnabled(({ communitySignupUrl }) => (
            <Dropdown.ItemLink
              onClick={() => (window.location.href = communitySignupUrl)}
            >
              {t("sign_up")}
            </Dropdown.ItemLink>
          ))}
        </Dropdown>
      </div>
    </div>
  );
};
