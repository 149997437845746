import type { PaymentElementProps } from "@stripe/react-stripe-js";
import { isEmpty } from "lodash";
import { usePaywallCheckoutContext } from "@circle-react/contexts/Paywalls/paywallCheckoutContext";

export const usePaymentElementFieldsOption = () => {
  const { memberBillingInfo, isMemberAddressRequired } =
    usePaywallCheckoutContext();

  const fieldsOption: PaymentElementProps["options"] =
    !isEmpty(memberBillingInfo) || isMemberAddressRequired
      ? { fields: { billingDetails: { address: "never" } } }
      : {};

  return {
    fieldsOption,
  };
};
