import { Suspense } from "react";
import { VersionedContent } from "@circle-react/components/Layout/VersionedContent";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { PageLoader } from "@circle-react/pages/PageLoader";

const CoursesComponent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "CoursesPage" */ "@circle-react/components/Courses/Directory"
    ),
);

export const CoursesPage = () => (
  <VersionedContent fullWidth>
    <Suspense fallback={<PageLoader />}>
      <CoursesComponent />
    </Suspense>
  </VersionedContent>
);
