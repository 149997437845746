import type { TimeDelayApiParams } from ".";
import { TIME_UNIT_TO_SECONDS } from ".";
import { formattedDateTime } from "@circle-react/helpers/dateTimeHelpers/formattedDateTime";

export const calculateProjectedTime = (
  updatedAt: string,
  apiParams: TimeDelayApiParams,
): string => {
  const timeUnit = apiParams.time_unit;
  const timeUnitInSeconds = TIME_UNIT_TO_SECONDS[timeUnit] || 1;
  const timeDelayInSeconds = apiParams.time_unit_amount * timeUnitInSeconds;

  return formattedDateTime({
    dateTime: new Date(
      new Date(updatedAt).getTime() + timeDelayInSeconds * 1000,
    ),
    format: "short_date_at_short_time",
  });
};
