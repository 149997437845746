import { Suspense } from "react";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { PageLoader } from "@circle-react/pages/PageLoader";

const CodeSnippetsComponent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "CodeSnippetsPage" */ "@circle-react/components/SettingsApp/CodeSnippets"
    ),
);

export const CodeSnippetsPage = () => (
  <Suspense fallback={<PageLoader />}>
    <CodeSnippetsComponent />
  </Suspense>
);
