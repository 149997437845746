import { useMemo } from "react";
import PropTypes from "prop-types";
import { Form } from "@circle-react-uikit/Form";
import { parseSpaceResponse } from "./helpers";

export const EditSpaceForm = ({ onSubmit, space, children, ...rest }) => {
  const formDefaultValues = useMemo(
    () => space && parseSpaceResponse(space),
    [space],
  );

  return (
    <Form
      id="edit-space-form"
      onSubmit={onSubmit}
      defaultValues={formDefaultValues}
      className="flex h-full flex-col overflow-auto"
      formClassName="flex flex-col h-full overflow-auto"
      {...rest}
    >
      {children}
    </Form>
  );
};

EditSpaceForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  space: PropTypes.object,
  children: PropTypes.node,
  triggerFormSubmitRef: PropTypes.object,
};
