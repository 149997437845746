import { t } from "i18n-js";
import { Form } from "@circle-react-uikit/Form";

const tooltipLocaleRoot = "settings.paywalls.modal.tabs.pricing.tooltip_text";

export const OfferingIdField = ({ className = "" }: any) => (
  <Form.Item
    className={`!py-0 ${className}`}
    name="mobile_paywall_resource_attributes.processor_id"
    hideDescription
    hidePlaceholder
    label={t("settings.paywalls.form.offering_label")}
    hideBorder
    tooltipText={t([tooltipLocaleRoot, "offering_processor_id"])}
    rules={{ required: true }}
  >
    <Form.Input />
  </Form.Item>
);
