import { t } from "i18n-js";
import type { Space } from "@circle-react/types";

export interface UseCoverImageLabelProps {
  space: Space;
}

export const useCoverImageLabel = ({ space }: UseCoverImageLabelProps) => {
  const {
    cover_image_url: coverImageUrl,
    cover_image_visible: isCoverImageVisible,
  } = space ?? {};

  return coverImageUrl || isCoverImageVisible
    ? t("update_cover")
    : t("add_cover");
};
