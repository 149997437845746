import { CursorPagination } from "@/react/components/shared/Pagination/CursorPagination";
import { MESSAGE_CONTAINER_ID } from "../../hooks/useScroll";
import { MessageBox } from "../MessageBox";
import { Avatar } from "./Avatar";
import { ContentBlock } from "./ContentBlock";

interface ChatWindowProps {
  messages: any;
  createMessage: (data: any) => void;
  isDms: boolean;
  fetchNextPage: () => void;
  hasNextPage: boolean;
  isLoading: boolean;
}

export const ChatWindow = ({
  messages,
  createMessage,
  isDms,
  fetchNextPage,
  hasNextPage,
  isLoading,
}: ChatWindowProps) => (
  <div className="bg-primary flex h-[calc(100%-64px)] w-full flex-col justify-between">
    {hasNextPage && (
      <CursorPagination
        componentId={MESSAGE_CONTAINER_ID}
        fetchNewPage={fetchNextPage}
        onEvent="scrollUp"
        messageId={null}
        isLoading={isLoading}
      />
    )}
    <div id={MESSAGE_CONTAINER_ID} className="overflow-auto">
      {messages.map((message: any) => {
        const {
          created_at: createdAt,
          updated_at: updatedAt,
          id,
          body,
          author_type: authorType,
          community_bot_profile: communityBotProfile,
          community_member: communityMember,
        } = message;

        const isUserMessage = authorType === "community_member";
        const authorName = isUserMessage
          ? communityMember.name
          : communityBotProfile.name;
        const elementKey = `${id}-${updatedAt}`;

        return (
          <div className="px-4 py-3" id={id} key={elementKey}>
            <div className="flex w-full items-start justify-start gap-3">
              <div className="pt-1.5">
                <Avatar message={message} />
              </div>
              <ContentBlock
                authorName={authorName}
                timestamp={createdAt}
                body={body}
              />
            </div>
          </div>
        );
      })}
    </div>
    {isDms && (
      <div className="px-4 py-3">
        <MessageBox onSubmit={createMessage} />
      </div>
    )}
  </div>
);
