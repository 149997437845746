import { useThemeContext } from "@/react/providers";
import { EmojiRenderer } from "@circle-react-shared/EmojiRenderer";

export const useSpacesForFilters = ({ spaces, valueKey = "slug" }) => {
  const { currentAppearance } = useThemeContext();
  return spaces.map(record => {
    const label = record.name;
    const richLabel = (
      <>
        <EmojiRenderer
          className="mr-1 inline !h-5 !w-5 align-text-top"
          emoji={record.emoji}
          appearance={currentAppearance}
          customEmojiUrl={record.custom_emoji_url}
          customEmojiDarkUrl={record.custom_emoji_dark_url}
        />
        <span>{record.name}</span>
      </>
    );

    return {
      richLabel,
      label,
      value: record[valueKey],
    };
  });
};
