import {
  selectConnectionQualityByPeerID,
  selectLocalPeer,
  useHMSStore,
} from "@100mslive/react-sdk";

export const useHMSLocalSignal = () => {
  const localPeer = useHMSStore(selectLocalPeer);
  const localPeerDownlinkQuality = useHMSStore(
    selectConnectionQualityByPeerID(localPeer?.id),
  )?.downlinkQuality;

  return {
    localPeerDownlinkQuality,
  };
};
