import PropTypes from "prop-types";
import classNames from "classnames";
import { t } from "i18n-js";
import {
  durationToHrMin,
  sumDurations,
} from "@/react/helpers/dateTimeHelpers/formattedDuration";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-uikit/Typography";
import { LessonContent } from "./LessonContent";

export const SectionContent = ({
  section = {},
  isMember,
  hasBorder = false,
  open,
  onToggle,
}) => {
  const duration = sumDurations(
    section.lessons
      .map(lesson => lesson.featured_media?.duration)
      .filter(Boolean),
  );
  const durationString =
    (duration.hours !== 0 || duration.minutes !== 0) &&
    ` ${t("separator_dot")} ${durationToHrMin(duration)}`;
  const lessonCount = section.lessons.length;
  return (
    <div key={section.id}>
      <button
        type="button"
        onClick={onToggle}
        className={classNames(
          "bg-secondary flex w-full justify-between p-4 text-left",
          {
            "border-primary border-b": open || hasBorder,
          },
        )}
      >
        <div className="flex">
          <Icon
            type={open ? "16-chevron-down" : "16-chevron-right"}
            className="mr-2"
            size={16}
          />
          <Typography.LabelMd weight="semibold" color="text-darkest">
            {section.name}
          </Typography.LabelMd>
        </div>
        <Typography.LabelSm>
          {t("courses.summary.content.lessons_count", {
            count: lessonCount,
          })}
          {durationString}
        </Typography.LabelSm>
      </button>
      {open && (
        <div className="divide-primary divide-y">
          {section.lessons.map(lesson => (
            <LessonContent
              key={lesson.id}
              lesson={lesson}
              isMember={isMember}
              section={section}
            />
          ))}
        </div>
      )}
    </div>
  );
};

SectionContent.propTypes = {
  section: PropTypes.object,
  isMember: PropTypes.bool,
  hasBorder: PropTypes.bool,
  areSectionsOpened: PropTypes.bool,
};
