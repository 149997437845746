import { create } from "zustand";

interface ManageMemberQueryParams {
  queryParams?: any;
}
interface Store {
  manageMemberQueryParams: ManageMemberQueryParams;
  setManageMemberQueryParams: (
    manageMemberFilters: ManageMemberQueryParams,
  ) => void;
  resetManageMemberQueryParams: () => void;
}

const useManageMemberQueryParamsStore = create<Store>(set => ({
  manageMemberQueryParams: {},
  setManageMemberQueryParams: manageMemberQueryParams =>
    set({ manageMemberQueryParams }),
  resetManageMemberQueryParams: () =>
    set({ manageMemberQueryParams: undefined }),
}));

export default useManageMemberQueryParamsStore;
