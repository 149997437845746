import { forwardRef } from "react";
import PropTypes from "prop-types";
import { t } from "i18n-js";
import { useFormContext } from "react-hook-form";
import { ConfirmationModal } from "@circle-react-shared/ModalsV2/ConfirmationModal";
import { Form } from "@circle-react-uikit/Form";

export const NotificationsConfirmModal = forwardRef(
  ({ isOpen, onClose, setNotificationsChanged }, triggerFormSubmitRef) => {
    const { setValue } = useFormContext();
    const onUpdateAllMembersNotifications = () => {
      setNotificationsChanged(false);
      setValue("update_all_members_notifications", true);
      setTimeout(() => triggerFormSubmitRef.current());
    };
    const onUpdateNewMembersNotifications = () => {
      setNotificationsChanged(false);
      setValue("update_all_members_notifications", false);
      setTimeout(() => triggerFormSubmitRef.current());
    };
    return (
      <ConfirmationModal
        isOpen={isOpen}
        title={t("spaces.form.create.notifications_confirm_modal.title")}
        onClose={onClose}
        onCancel={onUpdateNewMembersNotifications}
        cancelText={t(
          "spaces.form.create.notifications_confirm_modal.new_members",
        )}
        onConfirm={onUpdateAllMembersNotifications}
        confirmVariant="primary"
        confirmText={t(
          "spaces.form.create.notifications_confirm_modal.all_members",
        )}
        shouldSubmitOnCancel
      >
        {t("spaces.form.create.notifications_confirm_modal.description")}
        <div className="hidden">
          <Form.Item
            hiddenField
            name="update_all_members_notifications"
            hideDescription
            hideBorder
            hideLabel
          >
            <Form.Input type="hidden" />
          </Form.Item>
        </div>
      </ConfirmationModal>
    );
  },
);
NotificationsConfirmModal.displayName = "NotificationsConfirmModal";

NotificationsConfirmModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  setNotificationsChanged: PropTypes.func,
};
