import { Suspense } from "react";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { PageLoader } from "@circle-react/pages/PageLoader";

const AnalyticsSettingsComponent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "AnalyticsSettingsPage" */ "@circle-react/components/SettingsApp/AnalyticsV2"
    ),
);

export const AnalyticsSettingsPage = () => (
  <Suspense fallback={<PageLoader />}>
    <AnalyticsSettingsComponent />
  </Suspense>
);
