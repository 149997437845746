import { t } from "i18n-js";
import { ConfirmationModal } from "@circle-react-shared/ModalsV2/ConfirmationModal";
import { PriceTypeSelectorField } from "./Fields/PriceTypeSelectorField";
import { PriceDescriptionBox } from "./PriceDescriptionBox";
import { PriceForms } from "./PriceForms/";
import { useAddPriceMobile } from "./hooks/useAddMobilePrice";

export const AddMobilePriceModal = () => {
  const { onConfirm, hideAddPriceModal } = useAddPriceMobile();

  return (
    <ConfirmationModal
      isOpen
      title={t(
        "settings.paywalls.modal.tabs.pricing.add_price_modal.title_mobile",
      )}
      confirmText={t(
        "settings.paywalls.modal.tabs.pricing.add_price_modal.actions.confirm",
      )}
      onConfirm={onConfirm}
      onClose={hideAddPriceModal}
      onCancel={hideAddPriceModal}
      size="lg"
      dataTestId="save-paywall-price-mobile"
    >
      <div className="flex flex-col gap-4">
        <PriceTypeSelectorField name="price_type" labelKey="payment_type" />
        <PriceForms />
        <PriceDescriptionBox />
      </div>
    </ConfirmationModal>
  );
};
