import PropTypes from "prop-types";
import { t } from "i18n-js";
import InfiniteScroll from "react-infinite-scroll-component";
import { LoaderRow } from "@circle-react-shared/LoaderRow";
import { Table, TableBody } from "@circle-react-uikit/Table";
import { Typography } from "@circle-react-uikit/Typography";
import { AddMembersButton } from "./AddMembersButton";
import { MemberRow } from "./MemberRow";
import { MembersHeaderTable } from "./MembersHeaderTable";
import { NoMembers } from "./NoMembers";
import { useMembersInfiniteTable } from "./hooks/useMembersInfiniteTable";

export const MembersInfiniteTable = ({
  dataLength,
  members,
  onAdd,
  onEdit,
  onRemove,
  onRoleChange,
  searchTerm = "",
  shouldHideAddMemberButton = false,
  shouldShowHeader = false,
  cellVariant = "no-border",
  variant = "space-members",
  ...rest
}) => {
  const {
    isSpaceMembersEmpty,
    isResultEmpty,
    isResultEmptyWithSearch,
    shouldShowAddMember,
    columnsToRender,
  } = useMembersInfiniteTable({
    dataLength,
    members,
    searchTerm,
    shouldHideAddMemberButton,
  });

  if (isSpaceMembersEmpty || isResultEmpty) {
    return <NoMembers onAddMember={onAdd} />;
  }

  if (isResultEmptyWithSearch) {
    return (
      <Typography.BodyMd>
        {t("spaces.form.edit.members.no_members_found_for_term", {
          member_search_term: searchTerm,
        })}
      </Typography.BodyMd>
    );
  }

  return (
    <InfiniteScroll
      loader={<LoaderRow />}
      dataLength={dataLength}
      style={{ overflow: "initial" }}
      scrollThreshold={0.5}
      {...rest}
    >
      {shouldShowAddMember && <AddMembersButton onAdd={onAdd} />}
      <Table
        variant="no-border"
        className="w-full min-w-[600px]"
        tableClassName="w-full table-fixed"
      >
        <MembersHeaderTable
          columnsToRender={columnsToRender}
          shouldShowHeader={shouldShowHeader}
        />
        <TableBody variant="no-divider">
          {members.map(member => (
            <MemberRow
              cellVariant={cellVariant}
              key={member.id}
              member={member}
              onEdit={onEdit}
              onRemove={onRemove}
              onRoleChange={onRoleChange}
              columns={columnsToRender}
              tableVariant={variant}
            />
          ))}
        </TableBody>
      </Table>
    </InfiniteScroll>
  );
};

MembersInfiniteTable.propTypes = {
  dataLength: PropTypes.number.isRequired,
  members: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      avatarUrl: PropTypes.string,
      name: PropTypes.string,
      email: PropTypes.string,
      role: PropTypes.string,
    }),
  ).isRequired,
  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
  onRoleChange: PropTypes.func,
  searchTerm: PropTypes.string,
  shouldHideAddMemberButton: PropTypes.bool,
  variant: PropTypes.oneOf(["space-members", "space-group-members"]),
};
