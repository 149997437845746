import type { ReactNode } from "react";
import classNames from "classnames";

export const Section = ({
  className,
  children,
}: {
  className?: string;
  children: ReactNode;
}) => (
  <div className={classNames("flex flex-col gap-2", className)}>{children}</div>
);
