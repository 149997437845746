import type { PropsWithChildren } from "react";
import classnames from "classnames";

interface ContainerProp {
  className?: string;
}

export const Container = ({
  children,
  className,
}: PropsWithChildren<ContainerProp>) => (
  <div
    className={classnames(
      "md:bg-primary md:divide-primary md:divide-y",
      className,
    )}
  >
    {children}
  </div>
);
