const commentWrapperPrefix = "comment_wrapper_";

export const jumpToComment = id => {
  window.setTimeout(() => {
    const element = document.querySelector(commentWrapperHash(id));
    if (element) {
      const highlightClassname = "just-added";
      const parentHighlightClassname = "parent-just-added";

      element.querySelector("input").setAttribute("checked", true);
      element.classList.add(highlightClassname);
      element.parentNode.parentNode.classList.add(parentHighlightClassname);

      element.scrollIntoView();
    }
  }, 500);
};

export const mayBeJumpToCommentUsingLocationHash = () => {
  const commentId = commentIdFromLocationHash();
  commentId && jumpToComment(commentId);
};

export const commentIdFromLocationHash = () =>
  (window.location.hash || "").split("#comment_wrapper_")[1];

const commentWrapperHash = id => `#${commentWrapperPrefix}${id}`;
