import { Suspense } from "react";
import { t } from "i18n-js";
import { PageMetaData } from "@/react/components/Layout/PageMetaData";
import { StandardLayoutV2 } from "@/react/components/Layout/StandardLayoutV2";
import { StandardLayoutHeaderWithPortal } from "@/react/components/Layout/StandardLayoutV2/StandardLayoutHeader/StandardLayoutHeaderWithPortal";
import { VersionedContent } from "@circle-react/components/Layout/VersionedContent";
import { SpaceHeaderTitleText } from "@circle-react/components/Spaces/Header/SpaceHeaderTitle/SpaceHeaderTitleText";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { PageLoader } from "@circle-react/pages/PageLoader";

const BookmarksComponent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "BookmarksPage" */ "@circle-react-shared/uikit/HeaderV3/RightActionBlock/Bookmarks/BookmarksList"
    ),
);

export const BookmarksPage = () => (
  <StandardLayoutV2 disableRightSidebar>
    <VersionedContent fullWidth fullHeight>
      <Suspense fallback={<PageLoader />}>
        <StandardLayoutV2.Body>
          <PageMetaData title={t("header_v3.bookmarks")} />
          <StandardLayoutHeaderWithPortal
            leftContent={
              <SpaceHeaderTitleText>
                {t("header_v3.bookmarks")}
              </SpaceHeaderTitleText>
            }
            hasBorder
          />
          <StandardLayoutV2.Content fullWidth>
            <div className="bg-primary m-4 mx-auto max-w-4xl overflow-auto lg:h-[calc(100vh-160px)]">
              <BookmarksComponent />
            </div>
          </StandardLayoutV2.Content>
        </StandardLayoutV2.Body>
      </Suspense>
    </VersionedContent>
  </StandardLayoutV2>
);
