import { useState } from "react";
import { useForm } from "react-hook-form";
import { useChangePlanFormContext } from "../ChangePlanFormContext";

export const useCoupon = () => {
  const { priceChangePreview, previewMutation, validateNewCouponHandler } =
    useChangePlanFormContext();

  // STATE
  const [isCouponEnabled, setIsCouponEnabled] = useState(false);
  const { register, watch, reset } = useForm();
  const { new_coupon_code } = watch();

  // HANDLERS
  const toggleAddCoupon = (event: any) => {
    event.preventDefault();
    setIsCouponEnabled(!isCouponEnabled);
  };

  const handleOnClickCouponValidation = (event: any) => {
    event.preventDefault();
    validateNewCouponHandler({
      new_paywall_price_id: priceChangePreview.paywall_price_id,
      new_coupon_code: new_coupon_code,
    });
  };

  const handleOnClickRemoveCoupon = (event: any) => {
    event.preventDefault();
    reset({ new_coupon_code: "" });
    setIsCouponEnabled(false);
    previewMutation.mutate({
      new_paywall_price_id: priceChangePreview.paywall_price_id,
    });
  };

  return {
    enableCoupon: isCouponEnabled,
    toggleAddCoupon,
    handleOnClickCouponValidation,
    handleOnClickRemoveCoupon,
    reactHook: { register },
  };
};
