import { NavLink } from "react-router-dom";
import { formatDate } from "@/react/components/SettingsApp/ManageMembers/TabContent/Cells";
import type { AdvancedSearchResultPost } from "@/react/components/search/types";
import { postsPath } from "@/react/helpers/urlHelpers";
import { Icon } from "@circle-react/components/shared/Icon";
import { staticAssetUrl } from "@circle-react/config/Settings";
import { useThemeContext } from "@circle-react/providers";
import { Typography } from "@circle-react-uikit/Typography";
import { HighlightedText } from "./HighlightedText";

const eventStartsAtFormat = "EEEE, MMM d, h:mm a (z)";

export const Event = ({ item }: { item: AdvancedSearchResultPost }) => {
  const path = postsPath({ spaceSlug: item.space_slug, slug: item.slug });
  const { isDarkAppearance } = useThemeContext();
  const coverImageFallbackUrl = isDarkAppearance
    ? staticAssetUrl("dark-mode-cover-thumbnail-2x.png")
    : staticAssetUrl("light-mode-cover-thumbnail-2x.png");

  return (
    <NavLink
      className="flex max-w-full flex-col items-start gap-4 pr-0 md:flex-row md:items-center md:gap-6 md:pr-6"
      data-testid="event-result"
      to={path}
    >
      <div className="w-full rounded md:h-24 md:w-44">
        <img
          loading="lazy"
          className="h-full w-full rounded-md object-cover"
          src={item.cover_image_url || coverImageFallbackUrl}
          alt=""
        />
      </div>
      <div className="[&_mark]:!bg-search-yellow flex flex-1 flex-col gap-4">
        <Typography.LabelMd weight="semibold">
          <HighlightedText
            originalText={item.name}
            highlightedText={item.highlighted_name}
          />
        </Typography.LabelMd>
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-2">
            <Icon
              type="16-calendar"
              size={16}
              className="text-default !h-4 !w-4"
            />
            <Typography.LabelXs>
              {formatDate(item.starts_at, eventStartsAtFormat)}
            </Typography.LabelXs>
          </div>
        </div>
      </div>
    </NavLink>
  );
};
