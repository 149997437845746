import { PropTypes } from "prop-types";
import classNames from "classnames";
import { formattedDateTime } from "@circle-react/helpers/dateTimeHelpers/formattedDateTime";
import { Typography } from "@circle-react-uikit/Typography";
import { Result } from "../Table/Result";
import { ExpandableCode } from "./ExpandableCode";

export const HistoryLog = ({ log, noMessage = false }) => {
  const { action_to_s = "", created_at: createdAt = "", message = {} } = log;
  const date = formattedDateTime({
    dateTime: new Date(createdAt),
    format: "short_date_at_short_time",
  });

  return (
    <div className="border-dark rounded-lg border">
      <div
        className={classNames(
          "bg-secondary border-dark flex flex-col gap-3.5 rounded-t-lg px-6 py-5",
          {
            "border-b": !noMessage,
            "rounded-b-lg": noMessage,
          },
        )}
      >
        <div className="flex flex-col gap-1">
          <div>
            <Typography.LabelMd weight="semibold">
              {action_to_s}
            </Typography.LabelMd>
          </div>
          <div className="flex flex-col gap-2 md:flex-row">
            <Result
              result={message?.status || "success"}
              isBadge={false}
              textSize="text-sm"
            />
            <Typography.LabelSm
              color="text-default"
              as="time"
              dateTime={createdAt}
            >
              {date}
            </Typography.LabelSm>
          </div>
        </div>
      </div>
      {!noMessage && (
        <div className="p-2">
          <ExpandableCode code={JSON.stringify(message, null, 2)} />
        </div>
      )}
    </div>
  );
};

HistoryLog.propTypes = {
  log: PropTypes.shape({
    action_to_s: PropTypes.string,
    created_at: PropTypes.string,
    message: PropTypes.object,
  }),
  noMessage: PropTypes.bool,
};
