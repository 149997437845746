import { useQuery } from "react-query";
import { eventApi } from "@circle-react/api/eventApi";

export const useFetchUpcomingEvents = ({
  per_page,
  location_type = "",
  ...options
}) =>
  useQuery(
    ["workflows", "list", "upcomingEvents"],
    () =>
      eventApi.fetchUpcomingEvents({
        params: { per_page, location_type },
      }),
    options,
  );
