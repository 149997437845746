import { isNil } from "lodash";
import { affiliateStatuses } from "@circle-react/types/PaywallsAffiliates";

export const isSaleAttributed = (attributable: any) =>
  !isNil(attributable.paywalls_affiliates_attribution_info);

export const attributeSaleModalType = (attributable: any) =>
  isSaleAttributed(attributable)
    ? "manage_attribute_sale"
    : "add_attribute_sale";

export const isAffiliateInvited = (affiliate: any) =>
  affiliate.status === affiliateStatuses.invited;
