import { useCallback, useEffect, useRef, useState } from "react";
import { getUserMedia, hasMediaAccess } from "../helpers";
import {
  selectCameraEnabled,
  selectVideoInputId,
  useLiveSettingsActions,
  useLiveStore,
} from "../store";

export const useDevicePreview = () => {
  const videoRef = useRef(null);
  const [hasVideo, setHasVideo] = useState(false);
  const cameraEnabled = useLiveStore(selectCameraEnabled);
  const videoInputId = useLiveStore(selectVideoInputId);
  const { setVideoInputId } = useLiveSettingsActions();

  const getMediaConstraints = useCallback(() => {
    const sharedVideoConstraints = {
      width: 1280,
      height: 720,
    };
    const video =
      !videoInputId || videoInputId === "default"
        ? sharedVideoConstraints
        : { deviceId: videoInputId, ...sharedVideoConstraints };

    // TODO: Get specific audio device for microphone meter
    return {
      video,
      audio: true,
    };
  }, [videoInputId]);

  const stopTracks = () => {
    if (videoRef.current?.srcObject) {
      videoRef.current?.srcObject.getTracks().forEach(track => track.stop());
    }
  };

  const handleMedia = useCallback(() => {
    if (
      videoRef.current &&
      cameraEnabled &&
      hasMediaAccess() &&
      !!getMediaConstraints
    ) {
      getUserMedia(getMediaConstraints())
        .then(stream => {
          videoRef.current.srcObject = stream;
          if (stream.getVideoTracks().length) {
            setVideoInputId(stream.getVideoTracks()[0].getSettings().deviceId);
            setHasVideo(true);
          } else {
            setHasVideo(false);
          }
        })
        .catch(err => {
          console.error("Could not get media devices", err.message);
          setHasVideo(false);
        });
    } else if (videoRef.current) {
      // Detach video
      stopTracks();
      videoRef.current.srcObject = null;
    }
  }, [videoRef, cameraEnabled, getMediaConstraints, setVideoInputId]);

  useEffect(() => {
    handleMedia?.();
  }, [handleMedia]);

  useEffect(() => {
    const currentVideoRef = videoRef.current;

    return () => {
      if (currentVideoRef) {
        stopTracks();
        currentVideoRef.srcObject = null;
      }
    };
  }, []);

  // Listen to device changes
  useEffect(() => {
    if (!handleMedia) {
      return;
    }
    if (hasMediaAccess()) {
      // Recapture on device change (default may have changed)
      navigator.mediaDevices.addEventListener("devicechange", handleMedia);
    }
    return () => {
      if (hasMediaAccess()) {
        navigator.mediaDevices.removeEventListener("devicechange", handleMedia);
      }
    };
  }, [handleMedia]);

  return {
    videoRef,
    hasVideo,
  };
};
