import { t } from "i18n-js";

export const NoMessagesContainer = () => (
  <div className="flex h-full flex-col items-center justify-center">
    <div className="text-darkest mb-2 text-xl font-semibold">
      {t("messaging.no_messages")}
    </div>
    <div className="text-default text-sm">{t("messaging.type_name")}</div>
  </div>
);
