import { useWatch } from "react-hook-form";
import { paywallPresenterOptions } from "../../constants";
import { useGetPaywall } from "../../hooks/useGetPaywall";
import { useChangePlanFormContext } from "../ChangePlanFormContext";

export const useCurrentAndSelectedPlans = () => {
  const { subscription, priceChangePreview } = useChangePlanFormContext();

  const { data: currentPaywall, isLoading: isLoadingCurrentPaywall } =
    useGetPaywall(subscription.paywall_id, paywallPresenterOptions);
  const currentPaywallPrice = currentPaywall?.active_subscription_prices?.find(
    (price: any) => price.id === subscription.paywall_price_id,
  );

  const selectedPaywallId =
    useWatch({ name: "paywall_id" }) ?? subscription.paywall_id;
  const { data: selectedPaywall, isLoading: isLoadingSelectedPaywall } =
    useGetPaywall(selectedPaywallId, paywallPresenterOptions);

  const selectedPaywallPricingOptions =
    selectedPaywall?.active_subscription_prices
      ?.filter((price: any) => price.id !== subscription.paywall_price_id)
      ?.map((price: any) => ({
        key: `${price.id}`,
        value: price.id,
        label: price.short_description,
        details: "",
        enableCursor: true,
      }));

  const selectedPaywallPriceId =
    priceChangePreview.paywall_price_id ?? subscription.paywall_price_id;
  const selectedPaywallPrice =
    selectedPaywall?.active_subscription_prices?.find(
      (price: any) => price.id === selectedPaywallPriceId,
    );

  return {
    isLoadingCurrentPaywall,
    currentPaywall,
    currentPaywallPrice,
    isLoadingSelectedPaywall,
    selectedPaywall,
    selectedPaywallPricingOptions,
    selectedPaywallPrice,
  };
};
