import type { CommunityMember } from "./CommunityMember";

export const affiliatesProgramPayoutProvider = {
  paypal: "paypal",
  wise: "wise",
} as const;
export type AffiliatesProgramPayoutProvider =
  (typeof affiliatesProgramPayoutProvider)[keyof typeof affiliatesProgramPayoutProvider];

export interface AffiliatesProgram {
  id: number;
  payout_type: AffiliatesProgramPayoutProvider;
}

export const affiliateStatuses = {
  inactive: "inactive",
  invited: "invited",
  enrolled: "enrolled",
  disabled: "disabled",
} as const;
export type AffiliateStatus =
  (typeof affiliateStatuses)[keyof typeof affiliateStatuses];

export type AttributableType =
  | "CommunityMemberCharge"
  | "CommunityMemberSubscription";

export interface AffiliatePromotionalLink {
  promotional_url: string;
  promotional_url_description: string;
}

export interface AffiliateReferralLink {
  affiliate_id: number;
  affiliate_code: string;
  campaign_id: number;
  campaign_name: string;
  paywall_id: number;
  paywall_name: string;
  url: string;
}

export interface AffiliatePayout {
  id: number;
  amount: number;
  amount_as_currency: string;
  status?: string;
}
export interface AffiliateAmountEntriesSummary {
  amount: number;
  amount_as_currency: string;
}

export interface PaywallPriceCommissionDetails {
  description: string;
  pricing_description: string;
  commission: string;
}

export interface PaywallCommissionDetails {
  description: string;
  pricing_description: string;
  commission: string;
  prices_commission_details: Array<PaywallPriceCommissionDetails>;
}

export interface Affiliate {
  id: number;
  community_id: number;
  program: AffiliatesProgram;
  community_member_id: number;
  community_member: CommunityMember;
  paypal_email: string;
  status: AffiliateStatus;
  manual_update_target_status?: AffiliateStatus;
  invited_at: string; // ISO8601 datetime string
  enrolled_at: string; // ISO8601 datetime string
  commissions_details: string;
  paywalls_commission_details: { [key: string]: PaywallCommissionDetails };
  promotional_link?: AffiliatePromotionalLink;
  referral_links: Array<AffiliateReferralLink>;
  visitors_count: number;
  leads_count: number;
  conversions_count: number;
  pending_commissions?: AffiliateAmountEntriesSummary;
  due_payout?: AffiliateAmountEntriesSummary;
  processing_payouts?: AffiliateAmountEntriesSummary;
  paid_payouts?: AffiliateAmountEntriesSummary;
}

export interface InviteNewAndExistingMembersParams {
  existing_member_ids?: (number | undefined)[];
  new_member_emails?: (string | undefined)[];
}

export interface InviteNewAndExistingMembersResponse {
  invites_sent: number;
  failures: string[];
}

export interface AffiliatesPayoutsControls {
  can_start_payouts: boolean;
  can_export_payouts: boolean;
  can_mark_payouts_as_paid: boolean;
}

export interface Attributable {
  id: number;
  can_be_attributed_for_affiliation: boolean;
  has_removable_attribution: boolean;
  paywalls_affiliates_campaign_id: number;
  paywalls_affiliates_attribution_info: {
    id: number;
    community_member: CommunityMember;
  };
  processor_id: string;
}
