import classnames from "classnames";
import { Switch } from "@circle-react-uikit/Switch";
import { Typography } from "@circle-react-uikit/Typography";
import type { SpaceGroupOption } from "../../SpaceAccessManagementContext";
import { Item } from "./Item";
import { ItemTitle } from "./ItemTitle";
import { useParentItem } from "./hooks/useParentItem";

interface ParentMobileItemProp {
  spaceGroup: SpaceGroupOption;
  isToggleEnabled?: boolean;
  onAddParent?: (action: () => void, object: SpaceGroupOption) => void;
  onRemoveParent?: (action: () => void, object: SpaceGroupOption) => void;
}

export const ParentMobileItem = ({
  isToggleEnabled = true,
  spaceGroup,
  onAddParent = action => {
    action();
  },
  onRemoveParent = action => {
    action();
  },
}: ParentMobileItemProp) => {
  const { name } = spaceGroup;
  const { isChecked, onAddClick, onRemoveClick, isFromSearch } = useParentItem({
    spaceGroup,
    onAddParent,
    onRemoveParent,
  });

  const onClick = isChecked ? onRemoveClick : onAddClick;

  return (
    <Item
      className={classnames({ hidden: !isFromSearch })}
      isDisabled={!isToggleEnabled}
      onClick={onClick}
    >
      <ItemTitle>
        <Typography.LabelSm weight="semibold">{name}</Typography.LabelSm>
      </ItemTitle>
      {isToggleEnabled && <Switch as="span" checked={isChecked} />}
    </Item>
  );
};
