import { t } from "i18n-js";
import { Button } from "@circle-react-uikit/Button";
import { Modal } from "@circle-react-uikit/ModalV2";
import { RerunBody } from "./RerunBody";

const i18nRoot = "settings.workflows.rerun_modal";

interface RerunFormProps {
  onCancel: () => void;
}

export const RerunForm = ({ onCancel }: RerunFormProps) => (
  <>
    <Modal.Header>
      <Modal.Title size="md" className="text-center">
        {t([i18nRoot, "title"])}
      </Modal.Title>
      <Modal.HeaderActions className="absolute right-0 top-0 mr-6 mt-6">
        <Modal.CloseButton onClick={onCancel} />
      </Modal.HeaderActions>
    </Modal.Header>
    <RerunBody />
    <Modal.Footer>
      <Button variant="circle" type="submit" full large>
        {t([i18nRoot, "continue"])}
      </Button>
    </Modal.Footer>
  </>
);
