import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { usePricingTabContext } from "../../hooks/usePricingTabContext";
import { SelfCancelableToggle } from "../Fields/SelfCancelableToggle";

export const SelfCancelableForm = () => {
  const { hasSubscriptionPrices } = usePricingTabContext();
  const isToggleDisabled = !hasSubscriptionPrices();
  const { watch, setValue } = useFormContext();

  const isEditingMode = !!watch("id");

  const selfCancelableValue = watch("self_cancelable");
  const [wasChangedByUser, setWasChangedByUser] = useState(
    hasSubscriptionPrices() && !selfCancelableValue,
  );

  useEffect(() => {
    if (isToggleDisabled) {
      setValue("self_cancelable", false);
    } else if (!isEditingMode && !wasChangedByUser) {
      // for new paywalls we default it on the first time, we don't re-enable it
      // if the user disabled it
      setValue("self_cancelable", true);
    }
  }, [isToggleDisabled, setValue, wasChangedByUser, isEditingMode]);

  const onChanging = () => {
    setWasChangedByUser(true);
  };

  const tooltipText = isToggleDisabled
    ? "self_cancelable_tooltip_disabled"
    : "self_cancelable_tooltip";

  return (
    <SelfCancelableToggle
      tooltipText={tooltipText}
      isToggleDisabled={isToggleDisabled}
      onChanging={onChanging}
    />
  );
};
