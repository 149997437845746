import PropTypes from "prop-types";
import { t } from "i18n-js";
import { SpaceListButton } from "@/react/components/Spaces/SidebarV2/CommunityMenu/SpaceList/SpaceListButton";
import { Icon } from "@circle-react-shared/Icon";

export const JoinSpacesButtonView = ({ ...rest }) => (
  <SpaceListButton {...rest}>
    <Icon size={16} type="16-add-new" />
    <span className="mx-2">{t("join_a_space")}</span>
  </SpaceListButton>
);

JoinSpacesButtonView.propTypes = {
  spaceGroupSlug: PropTypes.string,
};
