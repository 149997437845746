import { t } from "i18n-js";
import { SanitizeHTMLString } from "@circle-react-shared/uikit/SanitizeContent";
import { popperSameWidthModifier } from "@circle-react-uikit/Dropdown/utils";
import { Form } from "@circle-react-uikit/Form";
import { useModalData } from "@circle-react-uikit/ModalV2";
import { Typography } from "@circle-react-uikit/Typography";
import { countriesNotSupported } from "../constants";

const localeRoot = "stripe_onboarding_modal";

export const CountriesForm = ({ countries, isDisabled, defaultValue }: any) => {
  const { contentPortalElement } = useModalData();

  return (
    <div className="flex flex-col gap-y-4 py-4">
      <Typography.BodySm color="text-default">
        <SanitizeHTMLString content={t([localeRoot, "subtitles"])[0]} />
      </Typography.BodySm>
      <Typography.BodySm color="text-default">
        <SanitizeHTMLString content={t([localeRoot, "subtitles"])[1]} />
      </Typography.BodySm>
      <Form.TextSelect
        name="country"
        options={countries}
        label={null}
        initialValue={defaultValue}
        disabled={isDisabled}
        appendTo={contentPortalElement}
        rules={{
          validate: {
            countryIsSupported: value =>
              !countriesNotSupported.includes(value.toUpperCase()),
          },
        }}
        popperOptions={{
          modifiers: [{ ...popperSameWidthModifier, phase: "afterWrite" }],
        }}
      />
    </div>
  );
};
