import type { NotificationActionType } from "@circle-react/types/Notification";

const notificationAction = (action: NotificationActionType) =>
  action?.includes("flagged") ? "flagged" : action;

export const notificationActionType = ({
  action,
}: {
  action: NotificationActionType;
}) => `notifications__row--${notificationAction(action)}`;

export const notificationActionIcon = ({
  action,
}: {
  action: NotificationActionType;
}) => `${notificationAction(action)}`;
