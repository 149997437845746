import { useMemo } from "react";
import { t } from "i18n-js";
import { FormTextSelect } from "@circle-react-shared/uikit/Form/FormTextSelect";
import { I18N_ROOT } from "./constants";
import { countryDropdownOptions } from "./helpers";

export const FormCountrySelector = () => {
  const countries = useMemo(() => countryDropdownOptions(), []);

  return (
    <FormTextSelect
      wrapperClassName="form-group no-border grow basis-0 !pt-4"
      name="address_country"
      label={t([I18N_ROOT, "address_country"])}
      options={countries}
      placeholder={t([I18N_ROOT, "select_country"])}
    />
  );
};
