import { useRef } from "react";
import type { KeyboardEvent } from "react";
import noop from "lodash/noop";
import { useClickAway } from "react-use";
import { useSearchResultsModal } from "@/react/components/Modals/SearchResultsModal";
import { DefaultSearchQuickItem } from "@/react/components/search/DefaultSearchQuickItem";
import { Divider } from "@/react/components/search/Divider";
import { EmptySearch } from "@/react/components/search/EmptySearch";
import { SearchInput } from "@/react/components/search/SearchInput";
import { SearchItemContainer } from "@/react/components/search/SearchItemContainer";
import { SearchQuickItem } from "@/react/components/search/SearchQuickItem";
import type { AdvancedSearchResult } from "@/react/components/search/types";
import { useSearchV2 } from "@/react/components/search/useSearchV2";
import { communityMemberProfilePath } from "@/react/helpers/urlHelpers";
import { RecentSearches } from "../RecentSearches";
import { useRecentSearches } from "../useRecentSearches";

interface SpotlightSearchInputProps {
  onClose: () => void;
}

export const SpotlightSearchInput = ({
  onClose = noop,
}: SpotlightSearchInputProps) => {
  const { recentSearches, addRecentSearch, removeRecentSearch } =
    useRecentSearches();
  const { show: showSearchResultModal } = useSearchResultsModal();
  const modalBodyRef = useRef(null);

  const onSearchInCommunity = () => {
    addRecentSearch({ type: "general", query: searchValue });
    void showSearchResultModal({ searchValue }).catch(e => console.error(e));
    onClose();
  };

  const {
    searchValue,
    results,
    isLoading,
    hasNoResults,
    setSearchValue,
    resultsContainerRef,
    onKeydown,
    onMouseOver,
    onFocus,
    isLoadingSearch,
    selectedResultIndex,
    resetSearch,
  } = useSearchV2({ onClose, type: "general", onSearchInCommunity });

  useClickAway(modalBodyRef, onClose);

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (!searchValue && recentSearches.length) {
      return;
    }
    onKeydown(event);
  };

  const onClick = (result: AdvancedSearchResult | null) => {
    if (!result) {
      onSearchInCommunity();
    }
    if (result?.type === "community_member" || result?.type === "space") {
      onClose();
    }
  };

  const getResultLink = (result: AdvancedSearchResult) => {
    if (result.type === "community_member") {
      return communityMemberProfilePath({ public_uid: result.public_uid });
    }
    if (result.type === "space") {
      return `/c/${result.slug}`;
    }
    return null;
  };

  const onClickCancel = () => {
    onClose();
    resetSearch();
  };

  const renderSearchResultContent = () => {
    if (!searchValue && !recentSearches.length) {
      return <EmptySearch />;
    }

    if (!searchValue && recentSearches.length) {
      return (
        <RecentSearches
          removeRecentSearch={removeRecentSearch}
          recentSearches={recentSearches}
        />
      );
    }

    const shouldRenderResults = !hasNoResults && !isLoading;

    return (
      <div className="overflow-y-auto md:max-h-96" ref={resultsContainerRef}>
        <ul className="list-none p-2">
          <DefaultSearchQuickItem
            index={0}
            searchValue={searchValue}
            isSelected={selectedResultIndex === 0}
            onClick={() => onClick(null)}
            onMouseOver={onMouseOver}
            onFocus={onFocus}
          />

          {shouldRenderResults && (
            <>
              <div className="-mx-2 my-2">
                <Divider />
              </div>
              {results.map((result: AdvancedSearchResult, index) => {
                const indexResult = index + 1;
                if (
                  result.type === "space" ||
                  result.type === "community_member"
                ) {
                  return (
                    <SearchQuickItem
                      key={result.id}
                      index={indexResult}
                      result={result}
                      onMouseOver={onMouseOver}
                      onFocus={onFocus}
                      isSelected={selectedResultIndex === indexResult}
                      onClick={() => onClick(result)}
                      link={getResultLink(result)}
                    />
                  );
                }

                return null;
              })}
            </>
          )}
        </ul>
      </div>
    );
  };

  return (
    <div ref={modalBodyRef}>
      <SearchInput
        onChange={e => setSearchValue(e)}
        isFixed
        searchValue={searchValue}
        onKeydown={handleKeyDown}
        isLoading={isLoadingSearch}
        onClickCancel={onClickCancel}
      />
      <SearchItemContainer>{renderSearchResultContent()}</SearchItemContainer>
    </div>
  );
};
