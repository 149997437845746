import { Typography } from "@circle-react-shared/uikit/Typography";

export interface PlanDetailsContainerProps {
  label: string;
  children: React.ReactNode;
}

export const PlanDetailsContainer = ({
  label,
  children,
}: PlanDetailsContainerProps) => (
  <div className="flex flex-col gap-y-2 pb-6 pt-4">
    <Typography.LabelSm weight="medium">{label}</Typography.LabelSm>
    {children}
  </div>
);
