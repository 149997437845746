import { t } from "i18n-js";
import { DataTable } from "@circle-react-shared/uikit/DataTableV2";
import { Typography } from "@circle-react-uikit/Typography";
import { AddPriceButton } from "../AddPriceButton";
import { usePricingTabContext } from "../hooks/usePricingTabContext";
import { ActionsColumn } from "./Columns/ActionsColumn";
import { PaymentTypeColumn } from "./Columns/PaymentTypeColumn";
import { PricingColumn } from "./Columns/PricingColumn";
import { StatusColumn } from "./Columns/StatusColumn";
import { StatusHeader } from "./Headers/StatusHeader";

const columns = [
  {
    accessorKey: "paymentType",
    header: t(
      "settings.paywalls.modal.tabs.pricing.table.columns.payment_type",
    ),
    cell: (props: any) => <PaymentTypeColumn {...props} />,
  },
  {
    accessorKey: "pricing",
    header: t("settings.paywalls.modal.tabs.pricing.table.columns.pricing"),
    cell: (props: any) => <PricingColumn {...props} />,
  },
  {
    accessorKey: "status",
    header: <StatusHeader />,
    cell: (props: any) => <StatusColumn {...props} />,
  },
  {
    accessorKey: "actions",
    header: " ",
    cell: (props: any) => <ActionsColumn {...props} />,
  },
];

export const PricingTabPriceList = () => {
  const { getActivePrices } = usePricingTabContext();
  const prices = getActivePrices();

  return (
    <div className="flex flex-col gap-y-4">
      <div className="flex flex-row">
        <div className="w-4/5 flex-none">
          <Typography.LabelLg weight="semibold">
            {t("settings.paywalls.modal.tabs.pricing.pricing_title")}
          </Typography.LabelLg>
          <Typography.BodyMd>
            {t("settings.paywalls.modal.tabs.pricing.mobile_description")}
          </Typography.BodyMd>
        </div>
        <div className="w-1/5 flex-none text-right">
          <AddPriceButton variant="secondary" />
        </div>
      </div>

      <DataTable columns={columns} data={prices}>
        <DataTable.Table>
          <DataTable.Header />
          <DataTable.Body />
        </DataTable.Table>
      </DataTable>
    </div>
  );
};
