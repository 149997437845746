import { t } from "i18n-js";
import { Form } from "@circle-react-shared/uikit/Form";

export const SubscriptionGroupToggle = ({
  tooltipText,
  isToggleDisabled,
  enableSubscriptionGroup,
}: any) => (
  <Form.Item
    className="!py-0"
    labelClassName="!font-normal"
    name="enable_subscription_group"
    translationRoot="settings.paywalls.modal.tabs.pricing"
    tooltipText={t(`settings.paywalls.modal.tabs.pricing.${tooltipText}`)}
    fullWidth
    hideDescription
    hidePlaceholder
    hideBorder
    inlineReverse
    isDisabled={isToggleDisabled}
  >
    <Form.ToggleSwitch
      name="enable_subscription_group"
      variant="small"
      value={String(enableSubscriptionGroup)}
      disabled={isToggleDisabled}
    />
  </Form.Item>
);
