import type { StateCreator } from "zustand";
import { immer } from "zustand/middleware/immer";
import { QUESTION_STATUSES } from "@circle-react/types/Live/QA/Question";
import type {
  Question,
  QuestionStatus,
} from "@circle-react/types/Live/QA/Question";
import type { Reply } from "@circle-react/types/Live/QA/Reply";
import type { Session } from "@circle-react/types/Live/QA/Session";

export const QUESTION_SORT_VALUES = {
  POPULAR: "popular",
  LATEST: "latest",
  OLDEST: "oldest",
} as const;

export type QuestionSortValue =
  (typeof QUESTION_SORT_VALUES)[keyof typeof QUESTION_SORT_VALUES];

interface QAState {
  session: Session | null;
  questions: Question[];
  filterByStatus: QuestionStatus;
  sortBy: QuestionSortValue;
  replyingQuestionId: number | null;
  replies: Reply[];
  upvotedQuestionIds: number[];
  pendingQuestionIds: number[];
}

interface SetSessionOptions {
  setInitialFilter?: boolean;
}

interface QAActions {
  setSession: (session: Session | null, options?: SetSessionOptions) => void;
  setQuestions: (questions: Question[]) => void;
  addQuestion: (question: Question) => void;
  updateQuestion: (question: Question) => void;
  deleteQuestion: (questionId: number) => void;
  setReplyingQuestionId: (questionId: number | null) => void;
  setReplies: (replies: Reply[]) => void;
  addReply: (reply: Reply) => void;
  updateReply: (reply: Reply) => void;
  deleteReply: (replyId: number) => void;
  setFilterByStatus: (status: QuestionStatus) => void;
  setFilterToSee: (question: Question) => void;
  setSortBy: (sort: QuestionSortValue) => void;
  updateUpvotedQuestionIds: (question: Question) => void;
  setPendingQuestionIds: (questionIds: number[]) => void;
  updatePendingQuestionIds: (question: Question) => void;
}

export interface QASlice {
  qa: QAState;
  qaActions: QAActions;
}

const APPROVED_STATUSES: QuestionStatus[] = [
  QUESTION_STATUSES.APPROVED,
  QUESTION_STATUSES.ANSWERED,
  QUESTION_STATUSES.UNANSWERED,
];

const isQuestionVisible = (
  question: Question,
  filterByStatus: QuestionStatus,
) =>
  (filterByStatus === QUESTION_STATUSES.APPROVED &&
    APPROVED_STATUSES.includes(question.status)) ||
  question.status === filterByStatus;

const sortQuestions = (questions: Question[], sortBy: QuestionSortValue) => {
  switch (sortBy) {
    case "latest": {
      return questions.sort((a, b) => {
        const bDate = new Date(b.approved_at || b.created_at);
        const aDate = new Date(a.approved_at || a.created_at);
        return bDate.getTime() - aDate.getTime();
      });
    }
    case "oldest": {
      return questions.sort((a, b) => {
        const bDate = new Date(b.approved_at || b.created_at);
        const aDate = new Date(a.approved_at || a.created_at);
        return aDate.getTime() - bDate.getTime();
      });
    }
    case "popular":
      return questions.sort((a, b) => b.upvotes_count - a.upvotes_count);
    default:
      return questions;
  }
};

export const createQASlice: StateCreator<
  QASlice,
  [],
  [["zustand/immer", never]],
  QASlice
> = immer((set, get) => ({
  qa: {
    session: null,
    questions: [],
    filterByStatus: QUESTION_STATUSES.APPROVED,
    sortBy: QUESTION_SORT_VALUES.LATEST,
    replyingQuestionId: null,
    replies: [],
    upvotedQuestionIds: [],
    pendingQuestionIds: [],
  },
  qaActions: {
    setSession: (session, options = {}) => {
      const currentSession = get().qa.session;
      set(state => {
        if (options.setInitialFilter && !currentSession && session?.enabled) {
          if (session?.has_pending_questions) {
            state.qa.filterByStatus = QUESTION_STATUSES.PENDING;
          } else {
            state.qa.filterByStatus = QUESTION_STATUSES.APPROVED;
          }
        }
        state.qa.session = session;
      });
    },
    setQuestions: questions =>
      set(state => {
        state.qa.questions = questions;
        const newUpvotedQuestionIds = questions
          .filter(q => q.upvoted_by_current_community_member)
          .map(q => q.id);
        const upvotedQuestionIdsSet = new Set(state.qa.upvotedQuestionIds);
        newUpvotedQuestionIds.forEach(id => upvotedQuestionIdsSet.add(id));
        state.qa.upvotedQuestionIds = Array.from(upvotedQuestionIdsSet);
      }),
    setFilterToSee: question => {
      const { filterByStatus } = get().qa;
      if (!isQuestionVisible(question, filterByStatus)) {
        get().qaActions.setFilterByStatus(question.status);
      }
    },
    addQuestion: question => {
      const { questions, filterByStatus, sortBy } = get().qa;

      if (
        isQuestionVisible(question, filterByStatus) &&
        !questions.some(q => q.id === question.id)
      ) {
        set(state => {
          state.qa.questions.push(question);
          state.qa.questions = sortQuestions(state.qa.questions, sortBy);
        });
      }
    },
    updateQuestion: question => {
      const { questions, filterByStatus, sortBy } = get().qa;
      const index = questions.findIndex(q => q.id === question.id);
      if (index !== -1) {
        set(state => {
          if (isQuestionVisible(question, filterByStatus)) {
            state.qa.questions[index] = question;
          } else {
            state.qa.questions.splice(index, 1);
          }
          state.qa.questions = sortQuestions(state.qa.questions, sortBy);
        });
      } else if (isQuestionVisible(question, filterByStatus)) {
        get().qaActions.addQuestion(question);
      }
    },
    updateUpvotedQuestionIds: question => {
      const { upvotedQuestionIds } = get().qa;
      const upvotedQuestionIdsSet = new Set(upvotedQuestionIds);
      if (question.upvoted_by_current_community_member) {
        upvotedQuestionIdsSet.add(question.id);
      } else {
        upvotedQuestionIdsSet.delete(question.id);
      }
      set(state => {
        state.qa.upvotedQuestionIds = Array.from(upvotedQuestionIdsSet);
      });
    },
    setPendingQuestionIds: questionIds =>
      set(state => {
        state.qa.pendingQuestionIds = questionIds;
      }),
    updatePendingQuestionIds: question => {
      const { pendingQuestionIds } = get().qa;
      const pendingQuestionIdsSet = new Set(pendingQuestionIds);
      if (question.status === QUESTION_STATUSES.PENDING) {
        pendingQuestionIdsSet.add(question.id);
      } else {
        pendingQuestionIdsSet.delete(question.id);
      }
      set(state => {
        state.qa.pendingQuestionIds = Array.from(pendingQuestionIdsSet);
        if (pendingQuestionIdsSet.size === 0 && state.qa.session) {
          state.qa.session.has_pending_questions = false;
        }
      });
    },
    deleteQuestion: questionId =>
      set(state => {
        state.qa.questions = state.qa.questions.filter(
          q => q.id !== questionId,
        );
        state.qa.pendingQuestionIds = state.qa.pendingQuestionIds.filter(
          id => id !== questionId,
        );
      }),
    setReplyingQuestionId: questionId =>
      set(state => {
        state.qa.replyingQuestionId = questionId;
      }),
    setReplies: replies =>
      set(state => {
        state.qa.replies = replies;
      }),
    addReply: reply =>
      set(state => {
        const { replyingQuestionId, replies } = get().qa;
        if (
          replyingQuestionId === reply.question_id &&
          !replies.some(r => r.id === reply.id)
        ) {
          state.qa.replies.push(reply);
        }
      }),
    updateReply: reply => {
      const { replies } = get().qa;
      const index = replies.findIndex(r => r.id === reply.id);
      if (index !== -1) {
        set(state => {
          state.qa.replies[index] = reply;
        });
      }
    },
    deleteReply: replyId =>
      set(state => {
        state.qa.replies = state.qa.replies.filter(r => r.id !== replyId);
      }),
    setFilterByStatus: status =>
      set(state => {
        state.qa.filterByStatus = status;
      }),
    setSortBy: sort =>
      set(state => {
        state.qa.sortBy = sort;
      }),
  },
}));
