import { useFormContext } from "react-hook-form";
import { INTERNAL_NAME_MAX_LENGTH } from "@circle-react/components/SettingsApp/Emails/components/Builder/constants";
import { useGetBroadcast } from "@circle-react/components/SettingsApp/Emails/hooks/useGetBroadcast";
import { t } from "@circle-react/custom_i18n";
import { PreviewTextField } from "@circle-react-shared/Emails/PreviewTextField";
import { SubjectField } from "@circle-react-shared/Emails/SubjectField";
import { Form } from "@circle-react-shared/uikit/Form";
import { useCurrentAction } from "../../../ActionRule";
import { EmailContentField } from "./EmailContentField";

const localeNamespace = "settings.workflows.form";

export const FormSendEmail = () => {
  const { paramsPath } = useCurrentAction();
  const { watch, setValue } = useFormContext();
  const broadcastId = watch(`${paramsPath}.broadcast_id`);

  useGetBroadcast({
    id: broadcastId,
    enabled: !!broadcastId,
    onSuccess: data => {
      setValue(`${paramsPath}.internal_name`, data.internal_name);
      setValue(`${paramsPath}.subject`, data.broadcast_content?.subject);
      setValue(
        `${paramsPath}.preview_text`,
        data.broadcast_content?.preview_text,
      );
      setValue(
        `${paramsPath}.email_content`,
        data.broadcast_content?.email_content,
      );
    },
  });

  return (
    <div className="react-form flex flex-col gap-y-7">
      <Form.Item
        name={`${paramsPath}.internal_name`}
        label={t([localeNamespace, "internal_name"])}
        hideDescription
        hidePlaceholder
        hideBorder
        isFirstChild
        rules={{
          maxLength: {
            value: INTERNAL_NAME_MAX_LENGTH,
            message: t([
              "settings.emails.broadcasts",
              "internal_name_too_long",
            ]),
          },
        }}
      >
        <Form.Input />
      </Form.Item>
      <SubjectField fieldName={`${paramsPath}.subject`} />
      <PreviewTextField fieldName={`${paramsPath}.preview_text`} />
      <EmailContentField />
    </div>
  );
};
