import classNames from "classnames";
import { SpaceAccessManagement } from "@circle-react/components/Spaces/SpaceAccessManagement/";
import { useSpaceAccessManagementContext } from "@circle-react/components/Spaces/SpaceAccessManagement/SpaceAccessManagementContext";
import type {
  SpaceGroupOption,
  SpaceOption,
} from "@circle-react/components/Spaces/SpaceAccessManagement/SpaceAccessManagementContext";
import { defaultAction } from "../../constants";
import { ChildRemovableItem } from "../Item/ChildRemovableItem";
import { ParentRemovableItem } from "../Item/ParentRemovableItem";

interface ListAccessGrantedProp {
  onRemoveParent?: (action: () => void, object: SpaceGroupOption) => void;
  onRemoveChild?: (action: () => void, object: SpaceOption) => void;
  className?: string;
}

export const ListAccessGranted = ({
  onRemoveParent,
  onRemoveChild,
  className,
}: ListAccessGrantedProp) => {
  const { options } = useSpaceAccessManagementContext();

  return (
    <SpaceAccessManagement.List
      className={classNames("hidden md:block", className)}
      dataTestId="list-space-access-granted"
    >
      {options.map((spaceGroup: SpaceGroupOption) => (
        <>
          <ParentRemovableItem
            key={`list-access-granted_${spaceGroup.id}`}
            {...spaceGroup}
            onRemoveParent={onRemoveParent || defaultAction}
          />
          {spaceGroup.spaces?.map(space => (
            <ChildRemovableItem
              key={`list-access-granted_${spaceGroup.id}-${space.id}`}
              {...space}
              spaceGroupId={spaceGroup.id}
              onRemoveChild={onRemoveChild || defaultAction}
            />
          ))}
        </>
      ))}
    </SpaceAccessManagement.List>
  );
};
