import { useEffect } from "react";
import { t } from "i18n-js";
import { useProfileDrawer } from "@/react/components/Drawers/ProfileDrawer";
import { usePunditUserContext } from "@circle-react/contexts";
import { getProfileLinkProps } from "@circle-react/helpers/profileHelpers";
import { isProfileDrawerEnabled } from "@circle-react/helpers/punditUserHelpers";
import { UserImage } from "@circle-react-shared/uikit/UserImage";
import { useHomePageApi } from "../../../hooks/home_page/useHomePageApi";

export const PostsSection = () => {
  const { posts, fetchPosts } = useHomePageApi();
  const punditUser = usePunditUserContext();
  const { show: showMemberProfileDrawer } = useProfileDrawer();

  useEffect(() => {
    void fetchPosts();
  }, []);

  return posts.length > 0 ? (
    <div className="sidebar__block sidebar__right trending-posts block">
      <div className="block__title">
        <h4>{t("trending_posts")}</h4>
      </div>
      <div className="block__content">
        <ul className="trending-posts__post-list">
          {posts.map((post: any) => {
            const profileLinkProps = getProfileLinkProps({
              publicUid: post.author.public_uid ?? "",
              showMemberProfileDrawer: (e: any) =>
                void showMemberProfileDrawer(e),
              isProfileDrawerEnabled: isProfileDrawerEnabled(punditUser),
              params: {},
            });
            return (
              <li key={post.id}>
                <div className="trending-posts__post">
                  {!post.hide_meta_info && (
                    <div className="trending-posts__avatar">
                      <a {...profileLinkProps}>
                        <UserImage
                          src={post.author.avatar_url}
                          name={post.author.name}
                          size="10"
                        />
                      </a>
                    </div>
                  )}
                  <div className="trending-posts__content">
                    <a
                      href={post.show_path}
                      className="trending-posts__post-name"
                    >
                      {post.name}
                    </a>
                    {!post.hide_meta_info && (
                      <a
                        {...profileLinkProps}
                        className="trending-posts__author-name"
                      >
                        {post.author.name}
                      </a>
                    )}
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  ) : null;
};
