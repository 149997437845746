import { useSpaceSlugFromUrl } from "@/react/hooks/useSpaceSlugFromUrl";
import { useSpacesContext } from "@circle-react/contexts";

export const useActiveSpace = () => {
  const spaceSlug = useSpaceSlugFromUrl();
  const space = useSpacesContext().helpers.findBy({ slug: spaceSlug });

  return {
    space,
  };
};
