import { useParticipant } from "@livekit/react-core";
import { useLivekitContext } from "../../VideoProvider/LivekitProvider";
import { CONNECTION_QUALITY } from "./constants";

export const useLivekitLocalSignal = () => {
  const { localParticipant } = useLivekitContext();
  const { connectionQuality } = useParticipant(localParticipant);

  return {
    localPeerDownlinkQuality: CONNECTION_QUALITY[connectionQuality],
  };
};
