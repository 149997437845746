import PropTypes from "prop-types";
import I18n from "i18n-js";
import { useFormContext } from "react-hook-form";
import {
  browserTimeZone,
  getTimezoneShort,
} from "@circle-react/helpers/dateTimeHelpers";
import { DateInput } from "@circle-react-uikit/DateInput";
import { Form } from "@circle-react-uikit/Form";
import {
  getDurationOptions,
  getEndDateOptions,
  getMaxRedemptionOptions,
  getStartDateOptions,
} from "../constants";

export const Usage = ({ paywallCoupon }) => {
  const { watch } = useFormContext();
  const {
    duration,
    enable_start_date,
    enable_end_date,
    enable_max_redemptions,
  } = watch();

  // In flatpickr it is needed to escape the timezone characters
  // https://flatpickr.js.org/formatting/#escaping-formatting-tokens
  const timezone = getTimezoneShort(browserTimeZone);
  const dateFormat = `F d, Y G:i K (\\${timezone.split("").join("\\")})`;

  return (
    <>
      {!paywallCoupon && (
        <>
          <Form.Item
            name="duration"
            translationRoot="settings.paywall_coupons.form"
            hideBorder
            hideDescription
            fullWidth
            tooltipText={I18n.t(
              "settings.paywall_coupons.form.duration_description",
            )}
          >
            <Form.SelectNative
              options={getDurationOptions()}
              data-testid="coupon_duration"
            />
          </Form.Item>

          {duration == "repeating" && (
            <Form.Item
              name="duration_in_months"
              label={I18n.t("settings.paywall_coupons.form.duration_in_months")}
              hideDescription
              hideBorder
              fullWidth={false}
              variant="extra-bottom-margin"
              rules={{
                required: I18n.t(
                  "settings.paywall_coupons.alerts.error.duration_in_monts_not_zero",
                ),
              }}
            >
              <Form.Input
                type="number"
                min="1"
                max="120"
                data-testid="coupon_duration_in_months"
              />
            </Form.Item>
          )}
        </>
      )}

      <Form.Item
        name="enable_start_date"
        hideBorder
        fullWidth
        hideDescription
        label={I18n.t("settings.paywall_coupons.form.redeemable_date")}
      >
        <Form.SelectNative
          options={getStartDateOptions()}
          data-testid="coupon_start_date"
        />
      </Form.Item>

      {enable_start_date == "true" && (
        <DateInput
          dateFormat={dateFormat}
          name="starts_at"
          fullWidth
          minuteIncrement={5}
          dataTestId="redeemable_from_date"
        />
      )}

      <Form.Item
        name="enable_end_date"
        hideBorder
        fullWidth
        hideDescription
        label={I18n.t("settings.paywall_coupons.form.redeemable_until_date")}
      >
        <Form.SelectNative
          options={getEndDateOptions()}
          data-testid="coupon_end_date"
        />
      </Form.Item>

      {enable_end_date == "true" && (
        <DateInput
          dateFormat={dateFormat}
          name="ends_at"
          fullWidth
          minuteIncrement={5}
          dataTestId="redeemable_until_date"
        />
      )}

      <div className="!hidden">
        <Form.Item
          name="enable_max_redemptions"
          hideBorder
          hideDescription
          fullWidth
          label={I18n.t("settings.paywall_coupons.form.max_redemptions")}
          tooltipText={I18n.t(
            "settings.paywall_coupons.form.max_redemptions_description",
          )}
        >
          <Form.SelectNative options={getMaxRedemptionOptions()} />
        </Form.Item>
      </div>

      {enable_max_redemptions == "true" && (
        <div className="paywall-coupons-form__fields__no_margin">
          <Form.Item
            name="max_redemptions"
            hideDescription
            hideBorder
            fullWidth
            placeholder={I18n.t(
              "settings.paywall_coupons.form.max_redemptions_placeholder",
            )}
            variant="extra-bottom-margin"
            rules={{
              required: I18n.t(
                "settings.paywall_coupons.alerts.error.max_redemptions_not_zero",
              ),
              min: 1,
            }}
          >
            <Form.Input type="number" min="1" />
          </Form.Item>
        </div>
      )}
    </>
  );
};

Usage.propTypes = {
  paywallCoupon: PropTypes.object,
};
