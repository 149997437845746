import { SortableContainer } from "react-sortable-hoc";
import { usePunditUserContext } from "@circle-react/contexts";
import { isCommunityAdmin } from "@circle-react/helpers/communityMemberHelpers";
import { useLgScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { SpaceGroup } from "./SpaceGroup";
import { SpaceGroupListView } from "./SpaceGroupListView";

export const SpaceGroupList = SortableContainer(
  ({ spaceGroupWithSpaces, onSpaceSortEnd, spaceGroupDragProperties }) => {
    const { currentCommunityMember } = usePunditUserContext();
    const isAdmin = isCommunityAdmin(currentCommunityMember);
    const isLargeScreen = useLgScreenMediaQuery();
    const shouldDisableDragAndDropForSpaceGroups = !isAdmin || !isLargeScreen;

    return (
      <SpaceGroupListView>
        {spaceGroupWithSpaces.map((spaceGroup, index) => (
          <SpaceGroup
            index={index}
            disabled={shouldDisableDragAndDropForSpaceGroups}
            isAdmin={isAdmin}
            position={index}
            key={spaceGroup.id}
            spaceGroup={spaceGroup}
            spaceGroupDragProperties={spaceGroupDragProperties}
            onSpaceSortEnd={onSpaceSortEnd}
          />
        ))}
      </SpaceGroupListView>
    );
  },
);
