import PropTypes from "prop-types";
import classNames from "classnames";
import { noop } from "lodash";

export const Button = ({
  onClick = noop,
  className,
  label,
  type = "button",
  dataTestId,
  ...otherProps
}) => (
  <button
    className={classNames(
      "w-full rounded-lg bg-[#506CF0] px-6 py-2.5 font-bold leading-5 text-white hover:opacity-90 disabled:cursor-not-allowed disabled:opacity-50",
      className,
    )}
    onClick={onClick}
    // eslint-disable-next-line react/button-has-type -- False positive
    type={type}
    data-testid={dataTestId}
    {...otherProps}
  >
    {label}
  </button>
);

Button.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  dataTestId: PropTypes.string,
};
