import PropTypes from "prop-types";
import classnames from "classnames";
import { t } from "i18n-js";
import { useHistory } from "react-router-dom";
import { Icon } from "@circle-react/components/shared/Icon";
import { useSupportWidgetContext } from "../SupportWidgetContext";

export const HeaderIconButton = ({
  ariaLabel = "",
  onClick = null,
  href = "",
  icon = "",
  type = "",
  className = "",
  size = 20,
}) => {
  let Component, props;

  const history = useHistory();
  const { isExpanded, expandWidget, collapseWidget } =
    useSupportWidgetContext();

  const HEADER_ICON_BUTTON_MAPPER = {
    back: {
      ariaLabel: t("support_widget.search_container.back_button_label"),
      icon: "20-chevron-left",
      onClick: history.goBack,
    },
    expand: {
      ariaLabel: t("support_widget.search_container.expand_widget_label"),
      icon: isExpanded ? "shrink" : "20-expand",
      onClick: () => (isExpanded ? collapseWidget() : expandWidget()),
    },
  };

  if (href) {
    Component = "a";
    props = {
      href,
      target: "_blank",
      rel: "noopener noreferrer",
    };
  } else {
    Component = "button";
    props = { onClick: onClick || HEADER_ICON_BUTTON_MAPPER[type]?.onClick };
  }

  return (
    <Component
      className={classnames(
        "text-dark hover:bg-tertiary w-10 rounded-lg p-2",
        {
          "visited:text-dark hover:text-dark focus:bg-tertiary focus:text-dark inline-block text-center":
            href,
        },
        className,
      )}
      aria-label={HEADER_ICON_BUTTON_MAPPER[type]?.ariaLabel || ariaLabel}
      {...props}
    >
      <Icon
        useWithFillCurrentColor
        type={HEADER_ICON_BUTTON_MAPPER[type]?.icon || icon}
        size={size}
      />
    </Component>
  );
};

HeaderIconButton.propTypes = {
  ariaLabel: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  type: PropTypes.oneOf(["back", "expand"]),
  href: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.number,
};
