import { useEffect } from "react";
import { useLiveSettingsActions } from "../store";
import { useLocalParticipant } from "./useLocalParticipant";
import { useRoom } from "./useRoom";

export const useMuteOnJoin = () => {
  const { setMicrophoneEnabled } = useLiveSettingsActions();
  const room = useRoom();
  const { isCurrentParticipantRoomCreator } = useLocalParticipant();
  const shouldMute = !!room?.mute_on_join;

  useEffect(() => {
    if (!isCurrentParticipantRoomCreator && shouldMute) {
      setMicrophoneEnabled(false);
    }
  }, [isCurrentParticipantRoomCreator, setMicrophoneEnabled, shouldMute]);
};
