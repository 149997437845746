import { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useCourseSections } from "@circle-react/hooks/courses/useCoursesApi";
import { CustomizeLessonsContent } from "./Content";

export const LessonList = ({ space }) => {
  const {
    data: sectionsData,
    isLoading: sectionsAreLoading,
    dataUpdatedAt,
  } = useCourseSections(space.id);

  const sectionsDataWithSortIds = useMemo(
    () =>
      (sectionsData?.records || []).map(section => ({
        ...section,
        sortId: `section-${section.id}`,
        lessons: section?.lessons?.map(lesson => ({
          ...lesson,
          sortId: `lesson-${lesson.id}`,
        })),
      })),
    [sectionsData, dataUpdatedAt],
  );

  const [sections, setSections] = useState(sectionsDataWithSortIds);

  useEffect(() => {
    setSections(sectionsDataWithSortIds);
  }, [sectionsData, sectionsDataWithSortIds, dataUpdatedAt]);

  return (
    <CustomizeLessonsContent
      sectionsAreLoading={sectionsAreLoading}
      sections={sections}
      setSections={setSections}
      space={space}
    />
  );
};

LessonList.propTypes = {
  space: PropTypes.object.isRequired,
};
