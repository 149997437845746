import {
  reactQueryGet,
  reactQueryPost,
} from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import type {
  PaginationHelper,
  PaginationParams,
} from "@circle-react/types/InternalAPIHelper";
import type {
  Affiliate,
  InviteNewAndExistingMembersParams,
  InviteNewAndExistingMembersResponse,
} from "@circle-react/types/PaywallsAffiliates";
import { attributions } from "./attributions";

const index = (
  params: PaginationParams,
): Promise<PaginationHelper<Affiliate>> =>
  reactQueryGet(internalApi.paywalls.affiliates.index(params));

const invite = (
  params: InviteNewAndExistingMembersParams,
): Promise<InviteNewAndExistingMembersResponse> =>
  reactQueryPost(internalApi.paywalls.affiliates.invite(), params);

export const affiliatesApi = {
  index,
  invite,
  attributions,
};
