import { useFormContext } from "react-hook-form";
import { usePricingTabContext } from "../hooks/usePricingTabContext";
import { PricingTabPriceModalSelectField } from "./SelectField";

export const PricingTabAddPriceModalBillingFrequencyChooser = () => {
  const { priceBuildOptions } = usePricingTabContext();
  const { watch } = useFormContext();

  const priceType = watch("price_type");

  const options = Object.entries(priceBuildOptions[priceType].frequencies).map(
    ([value, frequency]) => ({
      value,
      label: frequency.as_adjective,
    }),
  );

  return (
    <PricingTabPriceModalSelectField
      name="billing_frequency"
      options={options}
      required
    />
  );
};
