import { useEffect } from "react";
import { t } from "i18n-js";
import { useFormContext } from "react-hook-form";
import { Form } from "@circle-react-uikit/Form";
import { usePricingTabContext } from "../../hooks/usePricingTabContext";

const tooltipLocaleRoot = "settings.paywalls.modal.tabs.pricing.tooltip_text";

export const SubscriptionGroupNameField = ({ className = "" }: any) => {
  const { hasRecurringPrices } = usePricingTabContext();
  const { setValue } = useFormContext();

  useEffect(() => {
    if (!hasRecurringPrices()) {
      setValue("apple_subscription_group_name", "");
    }
  }, [hasRecurringPrices]);

  return (
    <Form.Item
      className={`!py-0 ${className}`}
      name="apple_subscription_group_name"
      hideDescription
      hidePlaceholder
      label={t("settings.paywalls.form.subscription_group_name_label")}
      tooltipText={t([tooltipLocaleRoot, "subscription_group_name"])}
      hideBorder
      rules={{ required: hasRecurringPrices() }}
    >
      <Form.Input disabled={!hasRecurringPrices()} />
    </Form.Item>
  );
};
