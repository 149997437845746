import type { noop } from "lodash";
import type { CommunityMember } from "@circle-react/types";
import { Loader } from "@circle-react-uikit/Loader";
import { BillingInfoForm } from "./BillingInfoForm";
import {
  BillingInfoContext,
  initialBillingInfo,
} from "./useBillingInfoContext";
import { useBillingInfoQuery } from "./useBillingInfoQuery";

export interface BillingInfoFormWrapperProps {
  communityMember: CommunityMember;
  onSuccess: typeof noop;
  asPrerequisite?: boolean;
}

export const BillingInfoFormWrapper = ({
  communityMember,
  onSuccess,
  asPrerequisite,
}: BillingInfoFormWrapperProps) => {
  const { isLoadingBillingInfo, billingInfo } =
    useBillingInfoQuery(communityMember);

  if (isLoadingBillingInfo) {
    return <Loader variant="default" />;
  }

  return (
    <BillingInfoContext.Provider
      value={{
        communityMember,
        billingInfo: billingInfo || initialBillingInfo,
        onSuccess,
        asPrerequisite,
      }}
    >
      <BillingInfoForm />
    </BillingInfoContext.Provider>
  );
};
