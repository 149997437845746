import {
  useCurrentSpaceContext,
  useSpaceGroupsContext,
} from "@circle-react/contexts";
import { Loader } from "@circle-react-uikit/Loader";
import { useModalData } from "@circle-react-uikit/ModalV2";
import { useSpacePaywalls } from "../hooks/useSpacePaywalls";
import { Header } from "./Header";
import { PaywallList } from "./PaywallList";
import { PaywallsDropdown } from "./PaywallsDropdown";

export const StripeEnabled = () => {
  const { data: space } = useCurrentSpaceContext();
  const { helpers } = useSpaceGroupsContext();
  const currentSpaceGroup = helpers.findById(space.space_group_id);

  const {
    isLoading: isSpacePaywallsLoading,
    currentPaywalls,
    availablePaywalls,
    removePaywall,
    addPaywall,
    refetchPaywalls,
  } = useSpacePaywalls({ space });

  const { contentPortalElement } = useModalData();

  return (
    <>
      <Header space={space} spaceGroup={currentSpaceGroup} />
      <PaywallsDropdown
        paywalls={availablePaywalls}
        onClick={addPaywall}
        isLoading={isSpacePaywallsLoading}
        appendTo={contentPortalElement}
      />
      {isSpacePaywallsLoading ? (
        <Loader center />
      ) : (
        <PaywallList
          space={space}
          paywalls={currentPaywalls}
          isLoading={isSpacePaywallsLoading}
          onRemove={removePaywall}
          refetchPaywalls={refetchPaywalls}
        />
      )}
    </>
  );
};
