import { useState } from "react";
import { newNotificationsCountPath } from "../../helpers/urlHelpers";

export const useNotificationsApi = (perPage = 30) => {
  const [notifications, setNotifications] = useState([]);
  const [newNotificationCount, setNewNotificationCount] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  const defaultFetchOptions = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "same-origin",
  };

  const fetchNewNotificationsCount = communityId =>
    fetch(
      newNotificationsCountPath() + `?community_id=${communityId}`,
      defaultFetchOptions,
    )
      .then(r => r.json())
      .then(data => {
        setNewNotificationCount(data.new_notifications_count);
        if (data.new_notifications_count) {
          setDataLoaded(false);
        }
      });

  const fetchNotifications = (page, notificationGroup) =>
    fetch(
      `/notifications?page=${page}&per_page=${perPage}&notification_group=${notificationGroup}`,
      defaultFetchOptions,
    )
      .then(r => r.json())
      .then(data => {
        if (page === 1) {
          setNotifications([...data.records]);
        } else {
          setNotifications([...notifications, ...data.records]);
        }
        setDataLoaded(true);
        setHasNextPage(data.has_next_page);
      });

  const fetchFirstPage = async notificationGroup => {
    await fetchNotifications(1, notificationGroup);
  };

  const resetNewNotificationsCount = () => {
    if (!newNotificationCount) {
      return;
    }
    fetch("/notifications/reset_new_notifications_count", {
      ...defaultFetchOptions,
      method: "POST",
    })
      .then(r => r.json())
      .then(data => {
        setNewNotificationCount(data.new_notifications_count);
      })
      .catch(error => {
        console.error(error);
      });
  };

  const markAllAsRead = () => {
    fetch("/notifications/mark_all_as_read", {
      ...defaultFetchOptions,
      method: "POST",
    })
      .then(r => r.json())
      .then(() => {
        const read_at = new Date().toString();
        const updatedNotifications = notifications.map(n => ({
          ...n,
          read_at,
        }));
        setNotifications(updatedNotifications);
      })
      .catch(error => {
        console.error(error);
      });
  };

  const markAsRead = notification => {
    if (notification.read_at) {
      return;
    }
    fetch(`/notifications/${notification.id}/mark_as_read`, {
      method: "POST",
      ...defaultFetchOptions,
    })
      .then(r => r.json())
      .then(data => {
        const updatedNotifications = notifications.map(n => {
          if (n.id === notification.id) {
            return data.record;
          }
          return n;
        });
        setNotifications(updatedNotifications);
      })
      .catch(error => {
        console.error(error);
      });
  };

  const archiveNotification = notification => {
    fetch(`/notifications/${notification.id}/archive`, {
      ...defaultFetchOptions,
      method: "POST",
    })
      .then(r => r.json())
      .then(() => {
        const updatedNotifications = notifications.filter(
          n => n.id !== notification.id,
        );
        setNotifications(updatedNotifications);
      })
      .catch(error => {
        console.error(error);
      });
  };

  const unfollowNotification = notification => {
    fetch(
      `/posts/${notification.post_id}/post_followers/${notification.post_follower_id}`,
      {
        ...defaultFetchOptions,
        method: "DELETE",
      },
    )
      .then(r => r.json())
      .then(data => {
        const updatedNotifications = notifications.map(n => {
          if (n.id === notification.id && data.success) {
            n.post_follower_id = null;
            return n;
          }
          return n;
        });
        setNotifications(updatedNotifications);
      })
      .catch(error => {
        console.error(error);
      });
  };

  const followNotification = notification => {
    fetch(`/posts/${notification.post_id}/post_followers`, {
      ...defaultFetchOptions,
      method: "POST",
    })
      .then(r => r.json())
      .then(data => {
        const updatedNotifications = notifications.map(n => {
          if (n.id === notification.id && data.success) {
            n.post_follower_id = data.post_follower_id;
            return n;
          }
          return n;
        });
        setNotifications(updatedNotifications);
      })
      .catch(error => {
        console.error(error);
      });
  };

  const resizeNotificationWindow = () => {
    const vheightouter = document.getElementById(
      "bottomBarNotificationWrapperOuter",
    );
    const vheightinner = document.getElementById(
      "bottomBarNotificationWrapperInner",
    );

    if (vheightouter) {
      const height = window.innerHeight - 56;
      vheightouter.style.height = height + "px";
    }
    if (vheightinner) {
      const height = window.innerHeight - 215;
      vheightinner.style.height = height + "px";
    }
  };

  const onNotificationEventReceive = eventData => {
    switch (eventData.event) {
      case "resetNewNotificationCount":
        setNewNotificationCount(eventData.new_notifications_count);
        break;
      case "updateNewNotificationCount":
        setNewNotificationCount(eventData.new_notifications_count);
        break;
      case "newNotification":
        setNewNotificationCount(newNotificationCount + 1);
        break;
    }
  };

  return {
    dataLoaded,
    setDataLoaded,
    notifications,
    newNotificationCount,
    setNewNotificationCount,
    hasNextPage,
    setHasNextPage,
    fetchNotifications,
    fetchFirstPage,
    resetNewNotificationsCount,
    markAsRead,
    markAllAsRead,
    archiveNotification,
    fetchNewNotificationsCount,
    resizeNotificationWindow,
    onNotificationEventReceive,
    unfollowNotification,
    followNotification,
  };
};
