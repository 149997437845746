import classNames from "classnames";
import { useThemeContext } from "@/react/providers";
import { usePunditUserContext } from "@circle-react/contexts";
import { isCommunityAdmin } from "@circle-react/helpers/communityMemberHelpers";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import type { Space } from "@circle-react/types";
import { EmojiRenderer } from "@circle-react-shared/EmojiRenderer";
import { Icon } from "@circle-react-shared/Icon";
import { shouldRenderLockIcon } from "./utils";

export interface SpaceEmojiProps {
  space: Space;
  className?: string;
}

export const SpaceEmoji = ({ space, className }: SpaceEmojiProps) => {
  const { currentAppearance } = useThemeContext();
  const { currentCommunityMember } = usePunditUserContext();
  const isAdmin = isCommunityAdmin(currentCommunityMember);
  const shouldUseLockIcon = shouldRenderLockIcon({
    shouldShowLockIconForNonMembers:
      space.show_lock_icon_for_non_members && !space.is_space_member,
    isPrivate: space.is_private,
    isAdmin,
  });
  const { isV3Enabled } = useIsV3();

  return (
    <EmojiRenderer
      className={classNames("title-emoji", className, {
        "!w-6": !isV3Enabled,
        "w-5": isV3Enabled,
      })}
      emoji={space.emoji}
      appearance={currentAppearance}
      customEmojiUrl={space.custom_emoji_url}
      customEmojiDarkUrl={space.custom_emoji_dark_url}
      shouldUseLockIcon={shouldUseLockIcon}
      lockIcon={<Icon type="lock" className="text-dark" />}
      dataTestId="title-emoji"
    />
  );
};
