import { t } from "i18n-js";
import { Header, useCustomizeUIData } from "@circle-react-shared/CustomizeUI";

export const SaveAction = () => {
  const { formId, isSaving } = useCustomizeUIData();

  return (
    <Header.Actions className="hidden justify-end lg:flex">
      <Header.SaveButton
        type="submit"
        formId={formId}
        disabled={isSaving}
        variant="circle"
      >
        {t("save_changes")}
      </Header.SaveButton>
    </Header.Actions>
  );
};
