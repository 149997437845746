import { useParticipant } from "@livekit/react-core";
import { CONNECTION_QUALITY } from "./constants";

export const useLivekitPeerSignal = participant => {
  const { connectionQuality } = useParticipant(participant);

  return {
    peerDownlinkQuality: CONNECTION_QUALITY[connectionQuality],
  };
};
