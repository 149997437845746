import { useFormContext } from "react-hook-form";
import {
  ACTIVE_STATUS,
  STATUS,
} from "@circle-react/helpers/communityMemberSubscriptionHelpers";
import { useAdminPaywallSubscription } from "@circle-react/hooks/paywalls/useAdminPaywallSubscription";
import { TABS, TABS_LOCALE } from "../constants";

export const useTabWithFilters = (
  setActiveFilterTab,
  setSearchKey,
  resetAllSubscriptionToFirstPage,
) => {
  const allSubscriptions = useAdminPaywallSubscription({
    perPage: 1,
  });
  const activeSubscriptions = useAdminPaywallSubscription({
    filters: { status: ACTIVE_STATUS.join(",") },
    perPage: 1,
  });
  const canceledSubscriptions = useAdminPaywallSubscription({
    filters: { status: STATUS.CANCELED },
    perPage: 1,
  });

  const tabOptions = [
    [TABS.ALL, allSubscriptions],
    [TABS.ACTIVE, activeSubscriptions],
    [TABS.CANCELED, canceledSubscriptions],
  ].map(([tab, subscriptionsData]) => ({
    value: tab,
    label: TABS_LOCALE[tab],
    badge: { value: subscriptionsData.totalSubscriptions },
  }));

  const { reset } = useFormContext();
  const changeTabAndResetSearchForm = tab => {
    reset({ searchKey: "" });
    setSearchKey("");

    resetAllSubscriptionToFirstPage();

    setActiveFilterTab(tab);
  };

  return {
    tabOptions,
    changeTabAndResetSearchForm,
  };
};
