import { useHistory, useLocation } from "react-router-dom";
import { RangeTab } from "@circle-react/components/Leaderboard/Dashboard/Table/RangeTab";
import { t } from "@circle-react/custom_i18n";

const RANGES = [
  {
    label: t("leaderboard.date_ranges.7_days"),
    queryParams: "?period=7_days",
  },
  {
    label: t("leaderboard.date_ranges.30_days"),
    queryParams: "?period=30_days",
  },
  {
    label: t("leaderboard.date_ranges.all_time"),
    queryParams: "?period=all_time",
  },
];

export const RangePicker = () => {
  const location = useLocation();
  const history = useHistory();

  const isMatch = RANGES.some(
    ({ queryParams }) => location.search === queryParams,
  );

  if (!isMatch) {
    history.replace(`/leaderboard/${RANGES[0].queryParams}`);
  }
  return (
    <div className="flex flex-1 items-center space-x-2">
      {RANGES.map(({ label, queryParams }) => (
        <RangeTab key={queryParams} label={label} queryParams={queryParams} />
      ))}
    </div>
  );
};
