import PropTypes from "prop-types";
import { useFormBlocks } from "../Providers";
import { SidebarEditView } from "./SidebarEditView";
import { SidebarListView } from "./SidebarListView";

export const Sidebar = () => {
  const { isEditingBlock } = useFormBlocks();

  if (isEditingBlock) {
    return <SidebarEditView />;
  }

  return <SidebarListView />;
};

Sidebar.propTypes = {
  space: PropTypes.object.isRequired,
};
