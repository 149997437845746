import PropTypes from "prop-types";

export const FieldWrapper = ({ title, children }) => (
  <>
    <label htmlFor="cover-image" className="mb-2">
      {title}
    </label>
    <div className="relative mb-2 flex flex-row items-start justify-between whitespace-pre-wrap">
      {children}
    </div>
  </>
);

FieldWrapper.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};
