import PropTypes from "prop-types";
import { useFocusOnLoad } from "@circle-react/hooks/useFocusOnLoad";
import { Form } from "@circle-react-uikit/Form";
import { RichTextInput } from "@circle-react-uikit/RichTextInput";

export const LockScreenFields = () => {
  const inputRef = useFocusOnLoad();
  const lockedPageHeadingRules = {};
  const translationRoot = "spaces.form.create";

  return (
    <>
      <h2 className="heading-h2">
        {I18n.t(`${translationRoot}.private_space_lock_screen_step_title`)}
      </h2>
      <div className="new-space-form__locked-screen">
        <Form.Item
          name="locked_page_heading"
          rules={lockedPageHeadingRules}
          hideBorder
          hideDescription
          translationRoot={translationRoot}
          fullWidth
        >
          <Form.Input autoFocus />
        </Form.Item>
        <div className="form-group form-group--column no-border trix-v2">
          <label>{I18n.t("spaces.form.create.locked_page_description")}</label>
          <RichTextInput
            ref={inputRef}
            id="locked_page_description"
            name="locked_page_description"
            placeholder={I18n.t(
              `${translationRoot}.locked_page_content_placeholder`,
            )}
            preventAccidentalLeave={false}
            tabIndex={0}
            className="w-full"
          />
        </div>
        <Form.Item
          name="locked_button_label"
          hideDescription
          translationRoot={translationRoot}
          hideBorder
          fullWidth
        >
          <Form.Input />
        </Form.Item>
        <Form.Item
          name="locked_button_url"
          hideDescription
          translationRoot={translationRoot}
          hideBorder
          fullWidth
        >
          <Form.Input />
        </Form.Item>
      </div>
    </>
  );
};

LockScreenFields.propTypes = {
  isPrivateSpaceWithLockedScreen: PropTypes.func,
};
