import PropTypes from "prop-types";

export const PrettyPrintJson = ({ data }) => (
  <div>
    <pre>{JSON.stringify(data, null, 2)}</pre>
  </div>
);

PrettyPrintJson.propTypes = {
  data: PropTypes.string.isRequired,
};
