import { useState } from "react";
import { t } from "i18n-js";
import { DEFAULT_COURSE_TYPE } from "@/react/components/Spaces/CourseSpace/CourseTypePicker/constants";
import type { CourseSettingFormValues } from "@/react/components/Spaces/CourseSpace/hooks";
import { useCourseSettingsMutation } from "@/react/components/Spaces/CourseSpace/hooks";
import type { Space } from "@/react/types/Space";
import { useSpaceResource } from "@circle-react/contexts";
import { ConfirmationModal } from "@circle-react-shared/ModalsV2/ConfirmationModal";
import { Button } from "@circle-react-uikit/Button";
import { ButtonWithDisabled } from "@circle-react-uikit/ButtonWithDisabled";
import { Modal } from "@circle-react-uikit/ModalV2";
import { useToast } from "@circle-react-uikit/ToastV2";
import { CourseTypePickerForm, FORM_ID } from "../CourseTypePickerForm";

interface CourseTypePickerModalProps {
  space: Space;
  isOpen: boolean;
  onClose: () => void;
}

export const CourseTypePickerModal = ({
  space,
  isOpen,
  onClose,
}: CourseTypePickerModalProps) => {
  const courseType = space.course_setting?.course_type || DEFAULT_COURSE_TYPE;
  const defaultValues = { course_setting: { course_type: courseType } };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isFormVisible, setIsFormVisible] = useState(true);
  const [payload, setPayload] =
    useState<CourseSettingFormValues>(defaultValues);
  const { error, success } = useToast();

  const resetModalState = () => {
    onClose();
    setTimeout(() => {
      setIsSubmitting(false);
      setIsFormVisible(true);
      setIsConfirmationOpen(false);
    }, 100);
  };
  const { refetch: refetchSpaceResource } = useSpaceResource({
    spaceId: space.id,
  });
  const { mutate } = useCourseSettingsMutation({
    space,
    options: {
      onSuccess: async () => {
        await refetchSpaceResource();
        success(t("spaces.form.create.course_types.save_success"), {
          duration: "short",
          shouldUseProgress: false,
        });
        resetModalState();
      },
      onError: () => {
        error(t("spaces.form.create.course_types.save_error"));
        setIsSubmitting(false);
      },
    },
  });

  const newCourseType = payload.course_setting.course_type;

  const confirmationText = t(
    `spaces.form.create.course_types.${newCourseType}_confirmation`,
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      contentPortalId="course-type-picker-portal"
    >
      <Modal.Overlay />
      <Modal.Content size="md">
        <Modal.Header>
          <Modal.Title size="sm">
            {t("spaces.form.create.course_types.label")}
          </Modal.Title>
          <Modal.HeaderActions>
            <Modal.CloseButton onClick={onClose} />
          </Modal.HeaderActions>
        </Modal.Header>
        <Modal.Body className="px-2">
          {isFormVisible && (
            <CourseTypePickerForm
              defaultValues={defaultValues}
              onSubmit={payload => {
                if (courseType === payload.course_setting.course_type) {
                  success(t("spaces.form.create.course_types.save_success"), {
                    duration: "short",
                    shouldUseProgress: false,
                  });
                  resetModalState();
                } else {
                  setPayload(payload);
                  setIsConfirmationOpen(true);
                  setIsFormVisible(false);
                }
              }}
            />
          )}
          <ConfirmationModal
            title={t("spaces.form.create.course_types.are_you_sure")}
            confirmText={t("save")}
            cancelText={t("cancel")}
            confirmVariant="circle"
            disabled={isSubmitting}
            isOpen={isConfirmationOpen}
            onCancel={() => {
              setIsFormVisible(true);
              setIsConfirmationOpen(false);
            }}
            onClose={() => {
              setIsFormVisible(true);
              setIsConfirmationOpen(false);
            }}
            onConfirm={() => {
              setIsSubmitting(true);
              mutate(payload);
            }}
          >
            <p>{confirmationText}</p>
          </ConfirmationModal>
        </Modal.Body>
        <Modal.Footer className="flex justify-end">
          {!isSubmitting && (
            <Button
              type="button"
              variant="secondary"
              className="mr-2"
              onClick={onClose}
            >
              {t("cancel")}
            </Button>
          )}
          <ButtonWithDisabled
            type="submit"
            variant="circle"
            form={FORM_ID}
            submitting={isSubmitting}
          >
            {t("save")}
          </ButtonWithDisabled>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};
