import { t } from "i18n-js";
import { useEventRsvp } from "@circle-react/components/Events/Rsvp/hooks/useEventRsvp";
import type { Event } from "@circle-react/types/Event";
import { Icon } from "@circle-react-shared/Icon";
import { Button } from "@circle-react-uikit/Button";
import { Modal } from "@circle-react-uikit/ModalV2";
import { Typography } from "@circle-react-uikit/Typography";

interface AddToCalendarConfirmationModalProps {
  onClose: () => void;
  isOpen?: boolean;
  event: Event;
}

export const AddToCalendarConfirmationModal = ({
  isOpen,
  onClose,
  event,
}: AddToCalendarConfirmationModalProps) => {
  const { addToCalendar } = useEventRsvp(event);
  const onButtonClick = () => {
    void addToCalendar();
    onClose();
  };

  return (
    <Modal
      title={t("events.add_to_calendar.confirm_modal.title")}
      isOpen={!!isOpen}
      onClose={onClose}
    >
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header className="md:pb-4">
          <Modal.Title size="md" className="text-center">
            {t("events.add_to_calendar.confirm_modal.title")}
          </Modal.Title>
          <Modal.HeaderActions>
            <Modal.CloseButton onClick={onClose} />
          </Modal.HeaderActions>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-4 text-center">
            <Typography.BodyMd color="text-default">
              {t("events.add_to_calendar.confirm_modal.description_1")}
            </Typography.BodyMd>
          </div>
          <div className="text-center">
            <Typography.BodyMd color="text-default">
              {t("events.add_to_calendar.confirm_modal.description_2")}
            </Typography.BodyMd>
          </div>
        </Modal.Body>
        <Modal.Footer className="space-y-2">
          <Button
            variant="secondary"
            full
            large
            onClick={onButtonClick}
            className="flex justify-center gap-x-2"
          >
            <Icon
              type="16-google-calendar"
              size={16}
              className="h-4 !w-4"
              useWithFillCurrentColor
            />
            {t("events.add_to_calendar.confirm_modal.google")}
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};
