import type { ReactNode } from "react";
import { PageMetaData } from "@/react/components/Layout/PageMetaData";
import { StandardLayoutHeader } from "@/react/components/Layout/StandardLayoutV2/StandardLayoutHeader";
import { SetLayoutConfig } from "@/react/components/SettingsApp/Layout/SetLayoutConfig";
import { useHideDOMElement } from "@/react/hooks/useHideDOMElement";
import { StandardLayoutHeaderContainer } from "@circle-react/components/Layout/StandardLayoutV2/StandardLayoutHeaderContainer";
import { SpaceHeaderTitleText } from "@circle-react/components/Spaces/Header/SpaceHeaderTitle/SpaceHeaderTitleText";
import { usePunditUserContext } from "@circle-react/contexts";
import { isPromoCodeEnabled } from "@circle-react/helpers/communityHelpers";

export interface SettingsHeaderProps {
  pageTitle: string;
  layoutConfigProps?: any;
  pageMetaProps?: any;
  rightContent?: ReactNode;
  leftContent?: ReactNode;
  filters?: ReactNode;
  headline?: ReactNode;
  hasBorder?: boolean;
  children?: ReactNode;
}

export const SettingsHeader = ({
  pageTitle,
  layoutConfigProps,
  pageMetaProps,
  rightContent,
  leftContent,
  filters,
  headline,
  hasBorder,
  children,
}: SettingsHeaderProps) => {
  // small workaround to keep mobile check sync
  const isMobile = window.innerWidth <= 768;
  const classToHide = !isMobile ? ".header__inside" : ".no-header";
  const { currentCommunity } = usePunditUserContext();
  const isPromoCodeEnabledForCommunity = isPromoCodeEnabled(currentCommunity);
  const shouldRenderSettingsHeader = !isPromoCodeEnabledForCommunity;
  useHideDOMElement(classToHide);
  return (
    <>
      <PageMetaData title={pageTitle} {...pageMetaProps} />
      <SetLayoutConfig disableRightSidebar={false} {...layoutConfigProps} />
      {shouldRenderSettingsHeader && (
        <StandardLayoutHeaderContainer>
          <StandardLayoutHeader
            leftContent={
              leftContent ? (
                leftContent
              ) : (
                <SpaceHeaderTitleText>{pageTitle}</SpaceHeaderTitleText>
              )
            }
            rightContent={rightContent}
            filters={filters}
            headline={headline}
            hasBorder={hasBorder}
          />
          {children}
        </StandardLayoutHeaderContainer>
      )}
    </>
  );
};
