import { t } from "i18n-js";
import { Typography } from "@circle-react-uikit/Typography";
import { usePayment } from "../../hooks/usePayment";
import { CheckoutFormSection } from "../CheckoutFormSection";
import { CheckoutFormAccountDetails } from "./CheckoutFormAccountDetails";
import { CheckoutFormAccountFields } from "./CheckoutFormAccountFields";

export const CheckoutFormAccountInfo = () => {
  const { currentUser } = usePayment();

  return (
    <div className="flex flex-col gap-y-1 pt-6 md:pb-6 md:pt-12">
      {currentUser ? (
        <Typography.LabelLg weight="semibold" textAlign="mb-4">
          {t("paywall_checkout.your_account")}
        </Typography.LabelLg>
      ) : (
        <Typography.LabelLg weight="semibold">
          {t("paywall_checkout.create_your_account")}
        </Typography.LabelLg>
      )}
      <CheckoutFormSection className="gap-y-5 !py-0">
        {currentUser ? (
          <CheckoutFormAccountDetails />
        ) : (
          <CheckoutFormAccountFields />
        )}
      </CheckoutFormSection>
    </div>
  );
};
