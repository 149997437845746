import { useThemeContext } from "@circle-react/providers";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-shared/uikit/Typography";

const iconTypes = {
  1: { light: "leaderboard-rank-1", dark: "leaderboard-rank-1-dark" },
  2: { light: "leaderboard-rank-2", dark: "leaderboard-rank-2-dark" },
  3: { light: "leaderboard-rank-3", dark: "leaderboard-rank-3-dark" },
} as const;

type Rank = keyof typeof iconTypes;

const isTop3Rank = (rank: number): rank is Rank => rank >= 1 && rank <= 3;

const getIconType = (rank: number, isDark: boolean) => {
  if (isTop3Rank(rank)) {
    return isDark ? iconTypes[rank].dark : iconTypes[rank].light;
  }
  return null;
};

export const Rank = ({ rank }: { rank: number }) => {
  const { isDarkAppearance } = useThemeContext();

  const iconType = getIconType(rank, Boolean(isDarkAppearance));

  if (iconType) {
    return <Icon className="!w-8" size={32} type={iconType} />;
  }

  return (
    <div className="text-light text-center">
      <Typography.LabelSm weight="semibold">{rank}</Typography.LabelSm>
    </div>
  );
};
