import { PropTypes } from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { formattedMessageSentAt } from "@circle-react/helpers/dateTimeHelpers/formattedMessageSentAt";
import { Typography } from "@circle-react-uikit/Typography";

export const ConversationItem = ({ conversation }) => {
  const { id, subject, status, custom_fields } = conversation;
  const sourceObj = custom_fields.find(field => field.id === 12092056289943); // source custom field ID
  const source = sourceObj
    ? I18n.t(`support_widget.conversation_item.source.${sourceObj.value}`)
    : undefined;
  const { day } = formattedMessageSentAt(conversation.updated_at);
  const formatStatus = status =>
    ({
      closed: I18n.t("support_widget.conversation_item.status.closed"),
      solved: I18n.t("support_widget.conversation_item.status.solved"),
    })[status] || I18n.t("support_widget.conversation_item.status.open");

  return (
    <Link
      to={`/conversations/${id}`}
      className="text-dark hover:bg-tertiary hover:text-dark focus:bg-secondary focus:text-dark cursor-pointer px-6 py-2"
    >
      <div className="flex justify-between">
        <Typography.LabelSm
          truncate
          weight={conversation.unread ? "medium" : "normal"}
        >
          {subject}
        </Typography.LabelSm>
        <div className="ml-4 flex self-center">
          {conversation.unread && (
            <div className="mr-3 flex self-center">
              <span className="h-2 w-2 rounded-full bg-blue-600" />
            </div>
          )}
          <span
            className={classNames(
              "inline-flex w-14 items-center justify-center rounded-full px-2 py-1 text-xs font-medium ring-1 ring-inset",
              {
                "bg-gray-50 text-gray-500 ring-gray-300 dark:bg-gray-700 dark:text-gray-400 dark:ring-gray-600":
                  status === "closed" || status === "solved",
                "bg-green-50 text-green-600 ring-green-600/50 dark:bg-[#0D6E30] dark:text-white dark:ring-[#199543]":
                  status !== "closed" && status !== "solved",
              },
            )}
          >
            {formatStatus(status)}
          </span>
        </div>
      </div>
      <div>
        <Typography.BodyXs className="text-xs" color="text-gray-400 flex">
          {source}
          <div className="mx-2 flex self-center">
            <span className="h-1 w-1 rounded-full bg-gray-400" />
          </div>
          {day}
        </Typography.BodyXs>
      </div>
    </Link>
  );
};

ConversationItem.propTypes = {
  conversation: PropTypes.object,
};
