import PropTypes from "prop-types";
import { BlockWithConnector } from "@circle-react-shared/BlockWithConnector";

export const WorkflowBlockList = ({ items = [], children }) => (
  <BlockWithConnector items={items}>{children}</BlockWithConnector>
);

WorkflowBlockList.propTypes = {
  items: PropTypes.array,
  children: PropTypes.node,
};
