import PropTypes from "prop-types";
import { FullScreenContent } from "@circle-react/components/Layout/FullScreenLayout/FullScreenContent";
import { FullScreenHeader } from "@circle-react/components/Layout/FullScreenLayout/FullScreenHeader";
import { FullScreenTabs } from "@circle-react/components/Layout/FullScreenLayout/FullScreenTabs";
import { FullScreenTopbar } from "@circle-react/components/Layout/FullScreenLayout/FullScreenTopbar";
import { PageMetaData } from "@circle-react/components/Layout/PageMetaData";
import "./styles.scss";

export const FullScreenLayout = ({ title, children }) => (
  <PageMetaData title={title}>
    <div className="react-layout-fullscreen">{children}</div>
  </PageMetaData>
);

FullScreenLayout.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

FullScreenLayout.Content = FullScreenContent;
FullScreenLayout.Header = FullScreenHeader;
FullScreenLayout.Tabs = FullScreenTabs;
FullScreenLayout.Topbar = FullScreenTopbar;
