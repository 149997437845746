import type { Dispatch, SetStateAction } from "react";
import type { UseMutationOptions } from "react-query";
import { useMutation } from "react-query";
import { workflowsApi } from "@/react/api";
import { useToast } from "@circle-react-uikit/ToastV2";
import type { WebhookTest } from "../generated/WorkflowsApi.types";

interface TestWebhookProps {
  setIsTestingWebhook: Dispatch<SetStateAction<boolean>>;
  setIsWebhookTestSuccessful: Dispatch<SetStateAction<boolean>>;
  setIsWebhookTested: Dispatch<SetStateAction<boolean>>;
  setTestWebhookResult: Dispatch<SetStateAction<WebhookTest | null>>;
  setIsWebhookUrlChanged: Dispatch<SetStateAction<boolean>>;
  options?: UseMutationOptions<any, unknown, any, unknown>;
}

interface RequestBody {
  webhook_test: {
    url: string;
    trigger_type: string;
  };
}

export const useTestWebhook = ({
  setIsTestingWebhook,
  setIsWebhookTestSuccessful,
  setIsWebhookTested,
  setTestWebhookResult,
  setIsWebhookUrlChanged,
  options = {},
}: TestWebhookProps) => {
  const toast = useToast();

  return useMutation(
    (body: RequestBody) => workflowsApi.testWebhook({ body }),
    {
      onSuccess: (data: WebhookTest) => {
        setIsWebhookTestSuccessful(data?.success);
        setIsTestingWebhook(false);
        setIsWebhookTested(true);

        setTestWebhookResult(data);
        setIsWebhookUrlChanged(false);
      },
      onError: (error: any) => {
        console.error(error);
        toast.error(error.message);
      },
      ...options,
    },
  );
};
