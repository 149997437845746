import classNames from "classnames";
import { t } from "i18n-js";
import { SortableElement } from "react-sortable-hoc";
import { useAddLinkModal } from "@circle-react/components/Modals/AddLinkModal";
import { Icon } from "@circle-react/components/shared/Icon";
import { Dropdown } from "@circle-react/components/shared/uikit/Dropdown";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { useAnyHoverMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { useBoolean } from "@circle-react/hooks/utils/useBoolean";
import { DeleteLinkConfirmation } from "./DeleteLinkConfirmation";

interface LinkItemProps {
  link: any;
  isAdmin?: boolean;
}

export const LinkItem = SortableElement(({ link, isAdmin }: LinkItemProps) => {
  const [shouldShowDeleteConfirmation, toggleDeleteConfirmation] =
    useBoolean(false);
  const [isDropdownClosed, toggleIsDropdownClosed] = useBoolean(false);
  const canHover = useAnyHoverMediaQuery();
  const addLinkModal = useAddLinkModal();
  const { isV3Enabled } = useIsV3();

  return (
    <>
      <a
        key={link.url}
        href={link.url}
        target="_blank"
        rel="noreferrer"
        draggable="false"
        title={link.name}
        className={classNames(
          "text-c-sidebar hover:text-c-sidebar focus:text-c-sidebar active:text-c-sidebar hover:bg-c-sidebar-hover focus:bg-c-sidebar-hover group flex w-full items-center gap-2 px-2",
          {
            "my-px rounded-md py-1.5 text-sm font-normal": !isV3Enabled,
            "text-xs-plus h-8.5 !m-0 rounded-lg px-4 font-medium transition-colors duration-200":
              isV3Enabled,
          },
        )}
      >
        <span
          className={classNames({
            "flex !h-5 !w-5 items-center justify-center": isV3Enabled,
          })}
        >
          <Icon
            type="16-external-link"
            className={classNames({
              "!h-4 !w-4": isV3Enabled,
            })}
            size={16}
          />
        </span>
        <h4
          className={classNames("truncate text-current", {
            "w-full max-w-full text-sm font-normal": !isV3Enabled,
            "text-xs-plus flex-1 font-medium": isV3Enabled,
          })}
        >
          {link.name}
        </h4>

        <button
          type="button"
          className={classNames("flex gap-0.5", {
            "hidden group-hover:flex": canHover && isDropdownClosed,
          })}
          onClick={event => {
            event.preventDefault();
            event.stopPropagation();
          }}
          aria-label={t("community_sidebar.view_sidebar_link_options")}
        >
          {isAdmin && (
            <Dropdown
              buttonClassName="flex py-0.5 w-5 h-5 rounded hover:bg-black/10 transition-colors"
              button={<Icon type="16-menu-dots-horizontal" size={16} />}
              onToggle={toggleIsDropdownClosed}
              appendTo={document.body}
            >
              <Dropdown.ItemWithLink
                onClick={() => {
                  void addLinkModal.show({ isEdit: true, link });
                }}
              >
                {t("edit")}
              </Dropdown.ItemWithLink>
              <Dropdown.ItemWithLink onClick={toggleDeleteConfirmation}>
                {t("delete")}
              </Dropdown.ItemWithLink>
            </Dropdown>
          )}
        </button>
      </a>
      <DeleteLinkConfirmation
        link={link}
        isOpen={shouldShowDeleteConfirmation}
        onClose={toggleDeleteConfirmation}
      />
    </>
  );
});
