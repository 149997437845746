import { usePaywallCheckoutContext } from "@circle-react/contexts/Paywalls/paywallCheckoutContext";

export const CommunityInfo = () => {
  const { currentCommunity } = usePaywallCheckoutContext();

  return (
    <div className="community-info">
      {currentCommunity.logo_url && (
        <img
          loading="lazy"
          className="community-info__logo"
          src={currentCommunity.logo_url}
          alt=""
        />
      )}

      {!currentCommunity.logo_url && currentCommunity.icon_url && (
        <>
          <div className="community-info__icon">
            <img loading="lazy" src={currentCommunity.icon_url} alt="" />
          </div>
          <span className="community-info__name">{currentCommunity.name}</span>
        </>
      )}

      {!currentCommunity.logo_url && !currentCommunity.icon_url && (
        <span className="community-info__name">{currentCommunity.name}</span>
      )}
    </div>
  );
};
