import { useMemo } from "react";
import { t } from "i18n-js";
import { getActionIcon } from "../../Actions";

const i18nRoot = "settings.workflows";

export const useActionGroup = ({ actions: actionsProp, truncationLimit }) => {
  const titlePrefix = `${t([i18nRoot, "action"])}: `;

  const mappedActions = useMemo(
    () =>
      actionsProp
        .filter(actionType => actionType !== "")
        .map(actionType => {
          const icon = getActionIcon(actionType);
          return {
            title: `${titlePrefix}${t([i18nRoot, "action_types", actionType], {
              defaultValue: t([i18nRoot, "action_types", "unknown"]),
            })}`,
            icon,
          };
        }),
    [actionsProp, titlePrefix],
  );

  const remainingItemsTooltip = useMemo(
    () =>
      actionsProp
        .slice(truncationLimit)
        .map(
          actionType =>
            `${titlePrefix}${t([i18nRoot, "action_types", actionType], {
              defaultValue: t([i18nRoot, "action_types", "unknown"]),
            })}`,
        )
        .join(", "),
    [actionsProp, truncationLimit, titlePrefix],
  );

  const remainingActionsCount = actionsProp.length - truncationLimit;

  const actions =
    remainingActionsCount > 1
      ? mappedActions.slice(0, truncationLimit)
      : mappedActions;

  return {
    actions,
    remainingActionsCount,
    remainingItemsTooltip,
  };
};
