import type { Space } from "@/react/types/Space";
import {
  canInviteMembers,
  canManageSpace,
  isSpaceMember,
} from "@circle-react/helpers/spaceHelpers";
import { EventSpaceActions } from "../../SpaceHeaderActions/EventSpaceActions";
import { InviteMemberSpaceOptions } from "../../SpaceHeaderTitleDropdown/spaceOptions/InviteMemberSpaceOptions";
import { LeaveAndDeleteSpaceOptions } from "../../SpaceHeaderTitleDropdown/spaceOptions/LeaveAndDeleteSpaceOptions";
import { ManageSpaceOptions } from "../../SpaceHeaderTitleDropdown/spaceOptions/ManageSpaceOptions";
import { Separator } from "./Separator";

export const EventSpaceOptions = ({
  space,
  setIsAddMemberOpen,
  onClickAddCoverImage,
  isAdmin,
}: {
  space: Space;
  setIsAddMemberOpen: (isOpen: boolean) => void;
  onClickAddCoverImage: () => void;
  isAdmin: boolean;
}) => {
  const canMemberManageSpace = canManageSpace(space);
  const isMember = isSpaceMember(space);
  return (
    <>
      {isMember && (
        <div className="md:hidden">
          <EventSpaceActions space={space} />
        </div>
      )}
      <Separator />
      {canMemberManageSpace && (
        <ManageSpaceOptions
          space={space}
          onInviteMember={() => setIsAddMemberOpen(true)}
          onAddCoverImage={onClickAddCoverImage}
        />
      )}
      {!isAdmin && isMember && canInviteMembers(space) && (
        <InviteMemberSpaceOptions
          space={space}
          onInviteMember={() => setIsAddMemberOpen(true)}
        />
      )}
      <Separator />
      <LeaveAndDeleteSpaceOptions space={space} isAdmin={isAdmin} />
    </>
  );
};
