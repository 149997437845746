import { staticAssetUrl } from "@circle-react/config/Settings";
import { useThemeContext } from "@circle-react/providers";
import type { Event } from "@circle-react/types";

export interface EventCoverProps {
  event?: Event;
}

export const EventCover = ({ event }: EventCoverProps) => {
  const { isDarkAppearance } = useThemeContext();

  if (!event) {
    return null;
  }

  const {
    cover_image_url: coverImageUrl,
    cover_image: coverImage,
    cover_image_variants: coverImageVariants,
  } = event;

  if (!coverImageUrl || !coverImage) {
    return null;
  }

  const { thumbnail: coverImgThumbnail } = coverImageVariants || {};

  const coverImageFallbackUrl = isDarkAppearance
    ? staticAssetUrl("dark-mode-cover-thumbnail-2x.png")
    : staticAssetUrl("light-mode-cover-thumbnail-2x.png");

  const imgSrcUrl = coverImageUrl || coverImageFallbackUrl;
  const imgUrl = coverImgThumbnail || imgSrcUrl;

  return (
    <img
      loading="lazy"
      alt=""
      src={imgUrl}
      className="border-primary relative h-full max-h-[112px] w-full max-w-[314px] rounded-lg border object-cover"
    />
  );
};
