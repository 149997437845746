import { t } from "i18n-js";
import { Icon } from "@circle-react/components/shared/Icon";
import { LabelMd } from "@circle-react-shared/uikit/Typography/components/Label/LabelMd";
import { NewsPost } from "../../NewsPost";
import { SkeletonLoader } from "../../SkeletonLoader";
import { CIRCLE_COMMUNITY_WHATS_NEW_URL } from "../../constants";
import { useListNews } from "../../queries";
import "./styles.css";

export const NewsListView = () => {
  const { isLoading, data } = useListNews();

  if (isLoading) {
    return (
      <>
        <SkeletonLoader rows={1} height="52px" wrapperClassName="!px-0 mb-4" />
        <SkeletonLoader rows={2} height="170px" wrapperClassName="px-2 mb-4" />
      </>
    );
  }

  const posts = data?.records || [];

  return (
    <div className="flex flex-col">
      <div className="border-primary bg-primary sticky top-0 z-10 mb-4 flex h-[52px] shrink-0 items-center justify-center border-b px-3">
        <LabelMd weight="semibold">{t("support_widget.news.news")}</LabelMd>
      </div>
      <div className="mb-4 px-4">
        {posts.map(post => (
          <NewsPost key={post.id} post={post} />
        ))}
        <a
          href={CIRCLE_COMMUNITY_WHATS_NEW_URL}
          target="_blank"
          rel="noopener noreferrer"
          className="see-more-news border-circle my-4 flex w-full justify-center gap-2 rounded-md border p-1"
        >
          {t("support_widget.news.see_more")}
          <Icon type="share-support-widget" useWithFillCurrentColor size={16} />
        </a>
      </div>
    </div>
  );
};
