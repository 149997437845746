import type { ReactNode } from "react";
import { createContext, useContext, useState } from "react";

interface FilterContextType {
  filters: Record<string, any>;
  setFilters: (filters: Record<string, any>) => void;
  resetFilters: () => void;
}

const FilterContext = createContext<FilterContextType>({
  filters: {},
  setFilters: () => {},
  resetFilters: () => {},
});

export interface FilterContextProviderProps {
  children: ReactNode;
}

export const FilterContextProvider = ({
  children,
}: FilterContextProviderProps) => {
  const [filters, setFilters] = useState<Record<string, any>>({});

  const resetFilters = () => setFilters({});

  return (
    <FilterContext.Provider
      value={{
        filters,
        setFilters,
        resetFilters,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export const useFilterContext = () => useContext(FilterContext);

FilterContext.displayName = "FilterContext";
