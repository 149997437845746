import { useQuery } from "react-query";
import { billingInfoApi } from "@circle-react/api/paywalls/communityMembers/billingInfoApi";
import type { ApiError } from "@circle-react/config/CustomErrors";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import type {
  CommunityMember,
  CommunityMemberBillingInfo,
} from "@circle-react/types";

export const useBillingInfoQuery = (communityMember: CommunityMember) => {
  const {
    isLoading: isLoadingBillingInfo,
    data: billingInfo,
    refetch: refetchBillingInfo,
  } = useQuery<CommunityMemberBillingInfo, ApiError>(
    internalApi.paywalls.communityMembers.billingInfo.show(communityMember.id),
    () => billingInfoApi.show(communityMember.id),
    {
      // 404 is expected for members without a billing information
      retry: (_: number, error) => error.status !== 404,
      useErrorBoundary: error => error?.status !== 404,
    },
  );

  return {
    isLoadingBillingInfo,
    billingInfo,
    refetchBillingInfo,
  };
};
