import type { Dispatch, ReactNode, SetStateAction } from "react";
import { createContext, useContext, useState } from "react";

type StringRecord = Record<string, string>;

interface Context {
  queryParamsKey: StringRecord;
  setQueryParamsKey: Dispatch<SetStateAction<StringRecord>>;
}

const DateRangePickerContext = createContext<Context | null>(null);
DateRangePickerContext.displayName = "DateRangePickerContext";

interface Props {
  queryParamsKey?: StringRecord;
  children: ReactNode;
}

export const DateRangePickerContextProvider = ({
  queryParamsKey: queryParamsKeyParam,
  children,
}: Props) => {
  const [queryParamsKey, setQueryParamsKey] = useState<StringRecord>(
    queryParamsKeyParam ?? {},
  );

  const valueToStore: Context = {
    queryParamsKey,
    setQueryParamsKey,
  };

  return (
    <DateRangePickerContext.Provider value={valueToStore}>
      {children}
    </DateRangePickerContext.Provider>
  );
};

export const useDateRangePickerContext = () => {
  const context = useContext(DateRangePickerContext);

  if (!context) {
    throw new Error(
      "useDateRangePickerContext must be used within a DateRangePickerContext",
    );
  }

  return context;
};
