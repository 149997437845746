import { useEffect } from "react";
import { useChargeDetailsModal } from "@circle-react-shared/Paywalls/Modals/ChargeDetailsModal";
import { useDefaultColumnsToRender } from "@circle-react-shared/Paywalls/PaywallChargesList/Columns/hooks/useDefaultColumnsToRender";

export const usePaywallChargesList = ({ chargesData }) => {
  useEffect(() => {
    if (chargesData?.charges?.length === 0 && chargesData?.totalCharges > 0) {
      chargesData.setPage(1);
    }
  });

  const { defaultColumnsToRender } = useDefaultColumnsToRender();

  const onClickPagination = currentPage => {
    chargesData.setPage(currentPage);
  };

  const chargeDetailsModal = useChargeDetailsModal();
  const onClickColumn = cellContext => {
    const {
      cell: {
        column: { id: columnName },
        row: { original: charge },
      },
    } = cellContext;

    if (columnName !== "customer") {
      void chargeDetailsModal.show({
        charge,
        shouldOpenAdminMemberEdit: true,
      });
    }
  };

  const columns = defaultColumnsToRender.map(column => ({
    ...column,
    onClick: onClickColumn,
  }));

  return {
    onClickPagination,
    columnsToRender: columns,
  };
};
