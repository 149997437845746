import { t } from "i18n-js";
import { useQuery } from "react-query";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { BaseResource } from "../../BaseResource";

export const SectionResource = ({ resourceId }: { resourceId: number }) => {
  const { data: section, isLoading } = useQuery<{ name: string } | undefined>(
    internalApi.settings.courses.sections.show({ id: resourceId }),
  );

  return (
    <BaseResource
      label={t("settings.workflows.view.resources.section")}
      value={section?.name}
      isLoading={isLoading}
    />
  );
};
