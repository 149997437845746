import { useHideOnboardingConfirmationModal } from "@/react/components/Modals/HideOnboardingConfirmationModal";
import { isCommunityAdmin } from "@/react/helpers/communityMemberHelpers";
import { useOnboarding } from "@circle-react/components/CommunityOnboarding/useOnboarding";
import { usePunditUserContext } from "@circle-react/contexts";

export const usePlatformMenuV3 = () => {
  const { currentCommunityMember } = usePunditUserContext();
  const { isOnboardingAvailable } = useOnboarding();

  const isAdmin = Boolean(isCommunityAdmin(currentCommunityMember));
  const shouldShowOnboarding = Boolean(isAdmin && isOnboardingAvailable);
  const hideOnboardingModal = useHideOnboardingConfirmationModal();

  return {
    shouldShowOnboarding,
    hideOnboardingModal,
  };
};
