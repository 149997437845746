import { fetchRequest } from "../helpers/backendRequestHelpers";
import { internalApi } from "../helpers/urlHelpers";

const show = processorId =>
  fetchRequest(
    internalApi.communityMemberCharge.show({
      processorId,
    }),
    {
      method: "GET",
    },
  );

export const communityMemberChargeApi = {
  show,
};
