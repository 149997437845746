import { useState } from "react";

export const useSet = (initialValue = new Set()) => {
  const [set, setSet] = useState(initialValue);

  const add = value => {
    setSet(set => {
      if (set.has(value)) {
        return set;
      }
      const newSet = new Set(set);
      newSet.add(value);
      return newSet;
    });
  };

  const remove = value => {
    setSet(set => {
      const newSet = new Set(set);
      newSet.delete(value);
      return newSet;
    });
  };

  const clear = () => {
    setSet(() => new Set());
  };

  const reset = () => {
    setSet(() => initialValue);
  };

  return [set, { add, remove, reset, clear }];
};
