import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { SectionItem } from "./SectionItem";

export interface DraggableSectionItemProps {
  section: any;
  space: any;
  [key: string]: any;
}

export const DraggableSectionItem = ({
  section,
  space,
  ...rest
}: DraggableSectionItemProps) => {
  const {
    attributes,
    listeners,
    setActivatorNodeRef,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: section.sortId,
    data: {
      dragType: "section",
      item: section,
    },
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <SectionItem
      isDragging={isDragging}
      ref={setNodeRef}
      section={section}
      space={space}
      tableBodyProps={{
        style,
        ...attributes,
      }}
      dragHandleProps={{
        listeners,
        setActivatorNodeRef,
      }}
      {...rest}
    />
  );
};
