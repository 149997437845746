import { useHMSActions } from "@100mslive/react-sdk";
import { HMS_ERRORS, useHMSErrorCode } from "./useHMSErrorCode";

export const useHMSUnblockAudio = () => {
  const errorCode = useHMSErrorCode();
  const hmsActions = useHMSActions();

  const isAudioBlocked = errorCode === HMS_ERRORS.AUDIO_BLOCKED;

  const unblockAudio = async () => {
    await hmsActions.unblockAudio();
  };

  return {
    isAudioBlocked,
    unblockAudio,
  };
};
