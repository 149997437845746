import {
  reactQueryGet,
  reactQueryPost,
  reactQueryPut,
} from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import type { CommunityMemberBillingInfo } from "@circle-react/types";

const create = (
  communityMemberId: number,
  billingInfo: CommunityMemberBillingInfo,
): Promise<CommunityMemberBillingInfo> =>
  reactQueryPost(
    internalApi.paywalls.communityMembers.billingInfo.create(communityMemberId),
    {
      billing_info: billingInfo,
    },
  );

const show = (communityMemberId: number): Promise<CommunityMemberBillingInfo> =>
  reactQueryGet(
    internalApi.paywalls.communityMembers.billingInfo.show(communityMemberId),
  );

const update = (
  communityMemberId: number,
  billingInfo: CommunityMemberBillingInfo,
): Promise<CommunityMemberBillingInfo> =>
  reactQueryPut(
    internalApi.paywalls.communityMembers.billingInfo.update(communityMemberId),
    {
      billing_info: billingInfo,
    },
  );

export const billingInfoApi = {
  create,
  show,
  update,
};
