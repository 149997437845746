import { XCircleIcon } from "@heroicons/react/solid";
import "./styles.scss";

export const ServerErrors = ({ serverErrors, ...props }) => {
  const { title, errors, topExtraInfo, bottomExtraInfo } = serverErrors;

  return (
    <div className="server-errors" {...props}>
      <div className="server-errors__wrapper">
        <div className="server-errors__close-icon">
          <XCircleIcon className="server-errors__icon-svg" />
        </div>
        <div className="server-errors__container">
          {title && <h3 className="server-errors__title">{title}</h3>}
          {topExtraInfo && (
            <p className="server-errors__extra-info">{topExtraInfo}</p>
          )}
          {errors && Array.isArray(errors) && (
            <div className="server-errors__content">
              <ul className="server-errors__list">
                {errors.map(error => (
                  <li key={error.title}>
                    <span className="server-errors__error-title">
                      {error.title}
                    </span>
                    :{" "}
                    <span
                      dangerouslySetInnerHTML={{ __html: error.description }}
                    ></span>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {bottomExtraInfo && (
            <p className="server-errors__extra-info">{bottomExtraInfo}</p>
          )}
        </div>
      </div>
    </div>
  );
};
