import { useContext } from "react";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import { MultiStepPanelContext } from "@circle-react-uikit/MultiStepPanel";
import { Step } from "@circle-react-uikit/MultiStepPanel/Step";
import { BasicInformation } from "./BasicInformation";
import { LockScreenFields } from "./LockScreenFields";
import { NotificationPreferenceFields } from "./NotificationPreferenceFields";

export const StepPanelContainer = ({
  currentSpaceGroupId,
  isPrivateSpaceWithLockedScreen,
}) => {
  const { watch } = useFormContext();
  const { steps } = useContext(MultiStepPanelContext);

  const watchName = watch("name");
  const watchVisibility = watch("visibility");
  const watchNotificationPreferences = watch("notification_preferences");

  return (
    <>
      <Step
        sequence={steps.basicInformation.sequence}
        title={steps.basicInformation.title}
        status={steps.basicInformation.status}
        displayNext
        isNextStepDisabled={!watchName || !watchVisibility}
      >
        <BasicInformation spaceGroupId={currentSpaceGroupId} />
      </Step>
      <Step
        sequence={steps.notificationPreferences.sequence}
        title={steps.notificationPreferences.title}
        status={steps.notificationPreferences.status}
        displaySubmit
        isNextStepDisabled={!watchNotificationPreferences}
      >
        <NotificationPreferenceFields />
      </Step>
      <Step
        sequence={steps.lockScreen.sequence}
        title={steps.lockScreen.title}
        status={steps.lockScreen.status}
        displayNext
        displaySkip
      >
        <LockScreenFields
          isPrivateSpaceWithLockedScreen={isPrivateSpaceWithLockedScreen}
        />
      </Step>
    </>
  );
};

StepPanelContainer.propTypes = {
  currentSpaceGroupId: PropTypes.number,
  isPrivateSpaceWithLockedScreen: PropTypes.func,
};
