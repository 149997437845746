import { t } from "i18n-js";
import { EntityResource } from "../EntityResource";
import type { EventOption } from "../types";
import { AnyPostSelector } from "./shared/AnyPostSelector";

export const postPublished: Readonly<EventOption> = {
  label: t("settings.workflows.form.events.post_published.title"),
  value: "post_published",
  group: t("settings.workflows.form.event_groups.posts_and_comments"),
  description: t("settings.workflows.form.events.post_published.description"),
  formComponent: AnyPostSelector,
  viewComponent: EntityResource,
  icon: "post",
};
