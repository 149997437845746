import { useMutation } from "react-query";
import { reactQueryPut } from "@/react/helpers/backendRequestHelpers";
import { internalApi } from "@/react/helpers/urlHelpers";
import type { CoursesSection } from "@/react/types/CoursesSection";

interface EmailNotificationMutationProps {
  course_section: {
    email_notification_subject: string;
    email_notification_body: Record<string, unknown>;
    notify_students_enabled?: boolean;
  };
}

interface UseEmailNotificationMutationProps {
  section: CoursesSection;
  options?: Record<string, unknown>;
}

export const useEmailNotificationMutation = ({
  section,
  options,
}: UseEmailNotificationMutationProps) => {
  const emailNotificationMutation = useMutation(
    (data: EmailNotificationMutationProps) =>
      reactQueryPut(
        internalApi.courses.updateSection({
          sectionId: section.id,
          courseId: section.space_id,
        }),
        data,
      ),
    options,
  );
  return { emailNotificationMutation };
};
