import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { LiveFormV3 } from "@circle-react/components/LiveStreams/LiveFormV3";
import { AddonsProvider } from "@circle-react/components/LiveStreams/LiveFormV3/Modal/AddonsProvider";

const CreateLiveModalComponent = () => {
  const { visible: isVisible, hide } = useModal();

  return (
    <AddonsProvider>
      <LiveFormV3 isOpen={isVisible} onClose={hide} />
    </AddonsProvider>
  );
};

export const CreateLiveModal = ModalManager.create(() => (
  <CreateLiveModalComponent />
));

export const useCreateLiveModal = () => useModal(CreateLiveModal);
