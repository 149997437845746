import {
  selectVideoTrackByID,
  useHMSStore,
  useVideo,
} from "@100mslive/react-sdk";

export const useHMSPeerVideo = peer => {
  const trackId = peer?.videoTrack;
  const { videoRef } = useVideo({ trackId });
  const track = useHMSStore(selectVideoTrackByID(trackId));

  return {
    videoRef,
    hasVideo: Boolean(track?.enabled),
  };
};
