import PropTypes from "prop-types";
import { t } from "i18n-js";
import { useContactsInWorkflowsEnabled } from "@circle-react/hooks/useContactsInWorkflowsEnabled";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { BadgeV2 } from "@circle-react-uikit/BadgeV2";
import { Typography } from "@circle-react-uikit/Typography";

export const ButtonContent = ({
  title,
  description,
  isApplicableToMembersOnly,
}) => {
  const isContactsInWorkflowsEnabled = useContactsInWorkflowsEnabled();

  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-col gap-1">
        <div>
          <Typography.LabelSm color="text-default">
            {description}
          </Typography.LabelSm>
        </div>
        <div>
          <Typography.TitleSm weight="semibold">{title}</Typography.TitleSm>
        </div>
      </div>
      {isContactsInWorkflowsEnabled && isApplicableToMembersOnly && (
        <TippyV2
          content={t("settings.workflows.members_only_tooltip")}
          interactive={false}
          className="flex"
        >
          <BadgeV2
            textSizeClassName="text-xs"
            label={t("settings.workflows.members_only")}
          />
        </TippyV2>
      )}
    </div>
  );
};

ButtonContent.propTypes = {
  title: PropTypes.ReactNode,
  description: PropTypes.ReactNode,
  isApplicableToMembersOnly: PropTypes.bool,
};
