import PropTypes from "prop-types";
import { Button } from "@circle-react-uikit/Button";
import { Modal } from "@circle-react-uikit/Modal";

export const SpaceNavigationModal = ({ show, onModalClose, spaceRootUrl }) => (
  <Modal show={show} onClose={onModalClose} showIcon={false}>
    <p className="text-sm text-gray-500">
      {I18n.t("spaces.form.create.success_message")}
    </p>

    <Button
      style={{ width: 200, height: 100 }}
      name={name}
      onClick={() => {
        window.location = `${spaceRootUrl}/invite`;
      }}
      variant="secondary"
    >
      {I18n.t("spaces.invite_members")}
    </Button>
    <Button
      style={{ width: 200, height: 100 }}
      name={name}
      onClick={() => {
        window.location = `${spaceRootUrl}/new`;
      }}
      variant="secondary"
    >
      {I18n.t("spaces.create_post")}
    </Button>
    <Button
      style={{ width: 200, height: 100 }}
      name={name}
      onClick={() => {
        window.location = `${spaceRootUrl}/settings`;
      }}
      variant="secondary"
    >
      {I18n.t("spaces.customize")}
    </Button>
  </Modal>
);

SpaceNavigationModal.propTypes = {
  show: PropTypes.bool,
  onModalClose: PropTypes.func,
  spaceRootUrl: PropTypes.string,
};
