import { useFormContext } from "react-hook-form";
import { Tabs } from "@circle-react-uikit/Tabs";
import { TABS } from "./constants";

const Navigation = ({ activeTab, setActiveTab }) => {
  const { trigger } = useFormContext();

  const handleTabClick = async tab => {
    const result = await trigger();
    if (!result) {
      return;
    }
    if (activeTab == TABS.DETAILS && tab == TABS.USAGE) {
      return;
    }

    setActiveTab(tab);
  };
  return (
    <Tabs
      wrapperClassName="px-6"
      controlled
      activeTab={activeTab}
      onClick={handleTabClick}
      options={Object.values(TABS).map(tab => ({ label: tab }))}
      variant="underline"
    />
  );
};

export default Navigation;
