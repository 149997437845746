import { useEffect, useRef } from "react";
import { useParticipant } from "@livekit/react-core";

export const useLivekitPeerVideo = peer => {
  const videoRef = useRef();
  const videoElement = videoRef.current;
  const { cameraPublication } = useParticipant(peer);
  const track = cameraPublication?.track;
  const hasVideo =
    cameraPublication?.isSubscribed &&
    cameraPublication?.track &&
    !cameraPublication?.isMuted;

  useEffect(() => {
    if (videoElement && track) {
      track.attach(videoElement);
    }

    return () => {
      if (videoElement && track) {
        track.detach(videoElement);
      }
    };
  }, [videoElement, track]);

  return {
    videoRef,
    hasVideo,
  };
};
