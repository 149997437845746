import { t } from "i18n-js";
import {
  SPACE_POST_TYPES,
  TRIGGER_RESOURCE_TYPE,
} from "@circle-react/components/SettingsApp/Workflows/constants";
import { EntitySelector } from "../../EntitySelector";

const i18nRoot = "settings.workflows.form.entity_type.event";

export const entityTypeOptions = [
  {
    value: TRIGGER_RESOURCE_TYPE.COMMUNITY,
    label: t([i18nRoot, "community.title"]),
    description: t([i18nRoot, "community.description"]),
  },
  {
    value: TRIGGER_RESOURCE_TYPE.SPACE_GROUP,
    label: t([i18nRoot, "space_group.title"]),
    description: t([i18nRoot, "space_group.description"]),
  },
  {
    value: TRIGGER_RESOURCE_TYPE.SPACE,
    label: t([i18nRoot, "space.title"]),
    description: t([i18nRoot, "space.description"]),
  },
];

export const AnyEventSelector = () => (
  <EntitySelector
    entityTypeOptions={entityTypeOptions}
    resourceType={SPACE_POST_TYPES.EVENT}
  />
);

AnyEventSelector.propTypes = {};
