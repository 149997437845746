import { ChatItem } from "@circle-react/components/SettingsApp/CommunityInbox/Sidebar/ChatList/ChatItem";
import { useChatList } from "@circle-react/components/SettingsApp/CommunityInbox/hooks/useCommunityChatList";
import { chatBotPath } from "@circle-react/helpers/urlHelpers";
import { Loader } from "@circle-react-uikit/Loader";
import { NoMessages } from "../NoMessages";

export const BotChatList = ({ shouldShowLatestMessageOnly = false }) => {
  const { chats: botChats = [], isChatsLoading: isBotChatsLoading } =
    useChatList({
      filter: "mine",
      per_page: shouldShowLatestMessageOnly ? 1 : 20,
    });

  if (shouldShowLatestMessageOnly) {
    const chat = botChats[0];
    if (!chat) {
      return null;
    }
    return (
      <ChatItem
        key={chat.id}
        chat={chat}
        path={chatBotPath({ id: chat.id })}
        isBotType
        shouldHightlightChat
      />
    );
  }
  if (isBotChatsLoading) {
    return <Loader center />;
  }
  if (botChats.length === 0) {
    return <NoMessages />;
  }
  return (
    <>
      {botChats.map(chat => (
        <ChatItem
          key={chat.id}
          chat={chat}
          path={chatBotPath({ id: chat.id })}
          isBotType
        />
      ))}
    </>
  );
};
