import { Link } from "react-router-dom";

interface CommonProps {
  onClick: () => void;
  onKeyDown: () => void;
  children: React.ReactNode;
}

interface ButtonProps extends CommonProps {
  link?: never;
}

interface LinkProps extends CommonProps {
  link: string | null;
}

type LinkOrButtonProps = ButtonProps | LinkProps;

export const LinkOrButton = ({
  onClick,
  onKeyDown,
  children,
  link,
}: LinkOrButtonProps) => {
  const commonProps = {
    onClick,
    onKeyDown,
    className:
      "mr-3 flex flex-1 items-center gap-3 truncate focus-visible:!outline",
  };

  if (link) {
    return (
      <Link to={link} {...commonProps}>
        {children}
      </Link>
    );
  }

  return (
    <button type="button" {...commonProps}>
      {children}
    </button>
  );
};
