import { t } from "i18n-js";
import {
  useCommunitySettingShow,
  useUpdateCommunitySetting,
} from "@circle-react/hooks/UseCommunitySetting";
import { Button } from "@circle-react-uikit/Button";
import { Loader } from "@circle-react-uikit/Loader";
import { Popover } from "@circle-react-uikit/Popover";

export const PublicSettingButton = () => {
  const updateCommunitySettingMutation = useUpdateCommunitySetting();
  const { data } = useCommunitySettingShow();

  const communitySetting = data?.community_settings;
  if (updateCommunitySettingMutation.isLoading || !communitySetting) {
    return <Loader />;
  }
  const publicMemberDirectory = communitySetting.public_member_directory;
  const buttonType = publicMemberDirectory ? "public" : "private";
  const localeNamespace = `members_directory.header.actions.${buttonType}`;

  const handleOnClick = () => {
    updateCommunitySettingMutation.mutate({
      data,
      updates: {
        public_member_directory: !publicMemberDirectory,
      },
    });
  };

  return (
    <Popover buttonTitle={t(`${localeNamespace}.title`)}>
      <p>{t(`${localeNamespace}.description`)}</p>
      <Button variant="primary" onClick={handleOnClick}>
        {t(`${localeNamespace}.revert_button`)}
      </Button>
    </Popover>
  );
};
