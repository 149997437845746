import { t } from "i18n-js";
import Communities from "@circle-react/components/Communities";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { Drawer } from "@circle-react-uikit/Drawer";

const BrandingDrawerComponent = () => {
  const drawer = useModal();

  return (
    <Drawer
      noWrapperPadding
      fixedHeader
      maskClosable={false}
      title={t("settings.branding_form.title")}
      open={drawer.visible}
      onClose={drawer.hide}
    >
      <Communities.BrandingEditForm />
    </Drawer>
  );
};

const BrandingDrawer = ModalManager.create(() => <BrandingDrawerComponent />);

export const useEditBrandingDrawer = () => useModal(BrandingDrawer);
