import PropTypes from "prop-types";
import I18n from "i18n-js";
import { useFormContext } from "react-hook-form";
import { isFixed, isPercent } from "@circle-react/helpers/paywallCouponHelpers";
import { Form } from "@circle-react-uikit/Form";
import { AMOUNT_REGEX, getTypeOptions } from "../constants";
import { FixedAmountFields } from "./FixedAmountFields";

export const Details = ({ paywallCoupon }) => {
  const { watch } = useFormContext();
  const type = watch("type");

  return (
    <>
      {!paywallCoupon && (
        <Form.Item
          name="code"
          translationRoot="settings.paywall_coupons.form"
          hideBorder
          fullWidth={false}
          variant="extra-bottom-margin"
          tooltipText={I18n.t("settings.paywall_coupons.form.code_description")}
          hideDescription
          hidePlaceholder
        >
          <Form.Input data-testid="coupon_code" />
        </Form.Item>
      )}

      <Form.Item
        name="name"
        translationRoot="settings.paywall_coupons.form"
        label={I18n.t("settings.paywall_coupons.form.name_label")}
        rules={{
          required: I18n.t(
            "settings.paywall_coupons.alerts.error.name_is_required",
          ),
          maxLength: 40,
        }}
        hideBorder
        fullWidth={false}
        variant="extra-bottom-margin"
        hideDescription
        hidePlaceholder
      >
        <Form.Input maxLength="40" data-testid="coupon_name" />
      </Form.Item>

      {paywallCoupon && (
        <>
          <Form.Item
            name="active"
            inline
            hideBorder
            hideDescription
            label={I18n.t("settings.paywall_coupons.form.active")}
          >
            <Form.ToggleSwitch variant="small" />
          </Form.Item>

          <div className="paywall-coupons-form__fields__coupon-info">
            <label>{I18n.t("settings.paywall_coupons.form.code_info")}</label>
            <p>{paywallCoupon.code}</p>
          </div>

          <div className="paywall-coupons-form__fields__coupon-info">
            <label>{I18n.t("settings.paywall_coupons.form.terms")}</label>
            <p>{paywallCoupon.terms}</p>
          </div>

          {paywallCoupon.max_redemptions && (
            <div className="paywall-coupons-form__fields__coupon-info">
              <label>
                {I18n.t("settings.paywall_coupons.form.max_redemptions")}
              </label>
              <p>{paywallCoupon.max_redemptions}</p>
            </div>
          )}

          <div className="paywall-coupons-form__fields__coupon-info">
            <label>{I18n.t("settings.paywall_coupons.form.redemptions")}</label>
            <p>{paywallCoupon.redemptions_count}</p>
          </div>
        </>
      )}

      {!paywallCoupon && (
        <>
          <Form.Item
            name="type"
            hideDescription
            translationRoot="settings.paywall_coupons.form"
            rules={{
              required: I18n.t(
                "settings.paywall_coupons.alerts.error.type_is_required",
              ),
            }}
            hideBorder
            fullWidth
            variant="extra-bottom-margin"
          >
            <Form.SelectNative
              name="type"
              options={getTypeOptions()}
              data-testid="coupon_type"
            />
          </Form.Item>

          {isFixed(type) && <FixedAmountFields />}
          {isPercent(type) && (
            <>
              <span className="paywall-coupons-form__input_percent_symbol">
                %
              </span>
              <Form.Item
                name="amount"
                translationRoot="settings.paywall_coupons.form"
                hideBorder
                fullWidth={false}
                hidePlaceholder
                rules={{
                  required: I18n.t(
                    "settings.paywall_coupons.alerts.error.amount_not_zero",
                  ),
                  pattern: {
                    value: AMOUNT_REGEX,
                    message: I18n.t(
                      "settings.paywall_coupons.alerts.error.amount_not_zero",
                    ),
                  },
                }}
                variant="extra-bottom-margin"
                hideDescription
              >
                <Form.Input data-testid="coupon_amount" />
              </Form.Item>
            </>
          )}
        </>
      )}
    </>
  );
};

Details.propTypes = {
  paywallCoupon: PropTypes.object,
};
