import type { CSSProperties } from "react";
import { colord } from "colord";
import { useIsCustomColorTheme } from "@circle-react/hooks/useIsCustomColorTheme";
import { THEME_MAP, useThemeContext } from "@circle-react/providers";

const sidebarBackgroundCssVar = "--color-community-sidebar-background";

export const useSidebarColor = () => {
  const { customColors, isCustomTheme, currentTheme, currentAppearance } =
    useThemeContext();
  const customSidebarColor =
    customColors[currentAppearance]?.sidebar_background_color || "#fff";

  const themeSidebarColor =
    THEME_MAP?.[currentTheme]?.[currentAppearance]?.[sidebarBackgroundCssVar] ||
    "#fff";

  return isCustomTheme ? colord(customSidebarColor) : colord(themeSidebarColor);
};

export const useCustomSidebarBackgroundColor = () => {
  const sidebarColor = useSidebarColor();
  return sidebarColor.toHex();
};

export const useCustomSidebarBorderColor = () => {
  const sidebarColor = useSidebarColor();

  if (sidebarColor.isDark()) {
    return sidebarColor.lighten(0.1).toHex();
  }
  return sidebarColor.darken(0.1).toHex();
};

export const useSidebarBackgroundStyles = (): CSSProperties => {
  const backgroundColor = useCustomSidebarBackgroundColor();
  const isCustomColorTheme = useIsCustomColorTheme();

  if (isCustomColorTheme) {
    return {
      backgroundColor,
    };
  }
  return {};
};

export const useSidebarBorderStyles = (): CSSProperties => {
  const borderColor = useCustomSidebarBorderColor();
  const isCustomColorTheme = useIsCustomColorTheme();

  if (isCustomColorTheme) {
    return {
      borderColor,
    };
  }
  return {};
};
