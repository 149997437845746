import { t } from "i18n-js";
import { FeatureLandingPage } from "@circle-react-shared/FeatureLandingPage";
import { Icon } from "@circle-react-shared/Icon";
import featureOne2x from "../../../../../../../assets/images/features/workflows-landing/feature-1-2x.png";
import featureOne4x from "../../../../../../../assets/images/features/workflows-landing/feature-1-4x.png";
import featureOneJpg from "../../../../../../../assets/images/features/workflows-landing/feature-1.jpg";
import featureTwo2x from "../../../../../../../assets/images/features/workflows-landing/feature-2-2x.png";
import featureTwo4x from "../../../../../../../assets/images/features/workflows-landing/feature-2-4x.png";
import featureTwoJpg from "../../../../../../../assets/images/features/workflows-landing/feature-2.jpg";
import featureThree2x from "../../../../../../../assets/images/features/workflows-landing/feature-3-2x.png";
import featureThree4x from "../../../../../../../assets/images/features/workflows-landing/feature-3-4x.png";
import featureThreeJpg from "../../../../../../../assets/images/features/workflows-landing/feature-3.jpg";

const localeNamespace = "settings.workflows.landing_page";

export const FeatureCards = () => (
  <FeatureLandingPage.FeatureCards.Container>
    <FeatureLandingPage.FeatureCards.Card
      srcSet={`${featureOneJpg} 663w, ${featureOne2x} 812w, ${featureOne4x} 2000w`}
      src={featureOneJpg}
      bgColor="#CFD8FE"
      imgClassName="max-w-full max-h-full xl:max-w-[406px]"
    >
      <FeatureLandingPage.FeatureCards.CardDescription
        mainIconEl={<Icon type="20-lightning" size={20} />}
        title={t([localeNamespace, "card_1.title"])}
        subtitle={t([localeNamespace, "card_1.subtitle"])}
        checkedList={[
          t([localeNamespace, "card_1.bullet_1"]),
          t([localeNamespace, "card_1.bullet_2"]),
          t([localeNamespace, "card_1.bullet_3"]),
        ]}
        bgColor="#F4F6FF"
        accentColor="#CFD8FE"
      />
    </FeatureLandingPage.FeatureCards.Card>
    <FeatureLandingPage.FeatureCards.Card
      srcSet={`${featureTwoJpg} 663w, ${featureTwo2x} 812w, ${featureTwo4x} 2000w`}
      src={featureTwoJpg}
      bgColor="#B0EBD9"
      imgClassName="max-w-full max-h-full xl:max-w-[423px]"
    >
      <FeatureLandingPage.FeatureCards.CardDescription
        mainIconEl={<Icon type="20-rocket" size={20} />}
        title={t([localeNamespace, "card_2.title"])}
        subtitle={t([localeNamespace, "card_2.subtitle"])}
        checkedList={[
          t([localeNamespace, "card_2.bullet_1"]),
          t([localeNamespace, "card_2.bullet_2"]),
          t([localeNamespace, "card_2.bullet_3"]),
        ]}
        bgColor="#EBFAF5"
        accentColor="#B0EBD9"
      />
    </FeatureLandingPage.FeatureCards.Card>
    <FeatureLandingPage.FeatureCards.Card
      srcSet={`${featureThreeJpg} 663w, ${featureThree2x} 812w, ${featureThree4x} 2000w`}
      src={featureThreeJpg}
      bgColor="#FFC9D3"
      imgClassName="max-w-full max-h-full xl:max-w-[360px] self-end mb-[-7px]"
    >
      <FeatureLandingPage.FeatureCards.CardDescription
        mainIconEl={<Icon type="20-clock" size={20} />}
        title={t([localeNamespace, "card_3.title"])}
        subtitle={t([localeNamespace, "card_3.subtitle"])}
        checkedList={[
          t([localeNamespace, "card_3.bullet_1"]),
          t([localeNamespace, "card_3.bullet_2"]),
          t([localeNamespace, "card_3.bullet_3"]),
        ]}
        bgColor="#FFF1F3"
        accentColor="#FFC9D3"
      />
    </FeatureLandingPage.FeatureCards.Card>
  </FeatureLandingPage.FeatureCards.Container>
);
