import { isEmpty } from "lodash";
import { usePaywallCheckoutContext } from "@circle-react/contexts/Paywalls/paywallCheckoutContext";

export const usePaymentMethodDataParams = () => {
  const { memberBillingInfo } = usePaywallCheckoutContext();

  const addressParams = () => {
    const {
      address_line1,
      address_line2,
      address_city,
      address_state,
      address_postal_code,
      address_country,
    } = memberBillingInfo;

    return {
      line1: address_line1,
      line2: address_line2,
      city: address_city,
      state: address_state,
      country: address_country,
      postal_code: address_postal_code,
    };
  };

  const paymentMethodDataParams = isEmpty(memberBillingInfo)
    ? {}
    : {
        payment_method_data: { billing_details: { address: addressParams() } },
      };

  return {
    paymentMethodDataParams,
  };
};
