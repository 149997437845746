import { useQuery } from "react-query";
import { paywallCouponApi } from "@circle-react/api/paywallCouponApi";
import { PAYWALL_COUPON_STATUSES } from "@circle-react/helpers/paywallCouponHelpers";

export const paywallCouponListQueryKey = "paywall-coupons-list";
export const defaultParams = {
  paywallId: null,
  queryParams: {},
};

const couponStatusesDefault = PAYWALL_COUPON_STATUSES;

export const usePaywallCouponList = ({
  paywallId,
  couponStatuses = couponStatusesDefault,
  skipDateValidations = true,
  queryParams = {},
  enabled: enabledOverride = false,
}) => {
  const isEnabled = enabledOverride || Boolean(paywallId);

  const paywallCouponsListQuery = useQuery(
    [
      paywallCouponListQueryKey,
      paywallId,
      {
        couponStatuses,
        skipDateValidations,
      },
    ],
    paywallCouponApi.index({ paywallId, skipDateValidations, couponStatuses }),
    { enabled: isEnabled, ...queryParams },
  );

  return {
    paywallCouponsListQuery,
  };
};
