import PropTypes from "prop-types";
import { usePunditUserContext } from "@circle-react/contexts";
import { PaywallFormContent } from "./PaywallFormContent";
import { usePaywallFormContentCreateFlow } from "./hooks/usePaywallFormContentCreateFlow";
import { usePaywallFormContentEditFlow } from "./hooks/usePaywallFormContentEditFlow";

export const PaywallFormContentOrchestrator = ({
  activeTab,
  setActiveTab,
  mutation,
  isSubmitting,
  paywallGroupsEnabled,
  currentPaywallStatus,
  requiresCurrency,
  canChangeCurrency,
}) => {
  const { currentCommunitySettings } = usePunditUserContext();

  const hook = !currentPaywallStatus
    ? usePaywallFormContentCreateFlow
    : usePaywallFormContentEditFlow;

  return (
    <PaywallFormContent
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      usePaywallFormContentHook={hook}
      mutation={mutation}
      isSubmitting={isSubmitting}
      paywallGroupsEnabled={paywallGroupsEnabled}
      currentPaywallStatus={currentPaywallStatus}
      requiresCurrency={requiresCurrency}
      canChangeCurrency={canChangeCurrency}
      workflowsEnabled={
        currentCommunitySettings?.upgrade_flows_for_workflows_paywalls_enabled
      }
    />
  );
};

PaywallFormContentOrchestrator.propTypes = {
  activeTab: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  mutation: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  currentPaywallStatus: PropTypes.string,
  paywallGroupsEnabled: PropTypes.bool.isRequired,
  requiresCurrency: PropTypes.bool.isRequired,
  canChangeCurrency: PropTypes.bool.isRequired,
};
