import { useCallback, useMemo } from "react";
import { useLiveStreamContextData } from "@circle-react/contexts/LiveStreams";
import { useProviderPeersList } from "./provider";

// Note: This hook returns "peers" as it's the simplest
// way to get active participants.

export const useActiveParticipantList = () => {
  const { hosts, spectators, moderators } = useProviderPeersList();
  const { room, participants } = useLiveStreamContextData();

  const sortAlphabetically = useCallback(
    (participantA, participantB) => {
      const nameA = participants?.[participantA.customerUserId]?.name || "";
      const nameB = participants?.[participantB.customerUserId]?.name || "";
      if (nameA > nameB) {
        return 1;
      }
      if (nameA === nameB) {
        return 0;
      }
      return -1;
    },
    [participants],
  );

  const sortByRole = useCallback(
    (hostA, hostB) => {
      if (
        participants?.[hostA.customerUserId]?.community_member_id ===
        room?.creator?.id
      ) {
        return -1;
      }
      if (
        participants?.[hostB.customerUserId]?.community_member_id ===
        room?.creator?.id
      ) {
        return 1;
      }
      return 0;
    },
    [participants, room?.creator?.id],
  );

  const orderedHosts = useMemo(
    () =>
      // Need to return room creator / main host first
      (hosts && [...hosts].sort(sortAlphabetically).sort(sortByRole)) || [],
    [hosts, sortByRole, sortAlphabetically],
  );

  const orderedModerators = useMemo(
    () => (moderators && [...moderators].sort(sortAlphabetically)) || [],
    [moderators, sortAlphabetically],
  );

  const orderedSpectators = useMemo(
    () => (spectators && spectators.sort(sortAlphabetically)) || [],
    [spectators, sortAlphabetically],
  );

  const peers = [...orderedHosts, ...orderedModerators, ...orderedSpectators];
  const [host, ...cohosts] = orderedHosts;

  return {
    peers,
    hosts: orderedHosts,
    cohosts,
    moderators: [host, ...orderedModerators],
    spectators: orderedSpectators,
    count: peers?.length || 0,
  };
};
