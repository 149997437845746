import { useCallback, useMemo, useRef } from "react";
import { useLivekitPeersList } from "./useLivekitPeersList";

const assignSelectedSpeaker = (dominantSpeaker, hosts, selectedSpeakerRef) => {
  if (dominantSpeaker) {
    // New dominant speaker, set as selected
    selectedSpeakerRef.current = dominantSpeaker;
  } else if (selectedSpeakerRef.current) {
    // No dominant speaker, update previous selected speaker or assign the first host,
    // This will take care of any video track changes that may occur.
    const selectedSpeaker = selectedSpeakerRef.current;
    selectedSpeakerRef.current =
      hosts?.find(host => host.id === selectedSpeaker.id) || hosts?.[0] || null;
  } else {
    // No selected speaker assigned, use the first host
    selectedSpeakerRef.current = hosts?.[0] || null;
  }
};

export const useLivekitPeers = () => {
  const { peers, hosts, spectators } = useLivekitPeersList();
  // TODO: Review logic for dominant speaker
  const dominantSpeaker = useMemo(
    () => hosts?.find(host => host.isSpeaking),
    [hosts],
  );
  const selectedSpeakerRef = useRef(null);
  assignSelectedSpeaker(dominantSpeaker, hosts, selectedSpeakerRef);
  const isSpeaking = useCallback(
    peerId => !!hosts?.some(host => host.id === peerId && host.isSpeaking),
    [hosts],
  );

  return {
    peers,
    hosts,
    spectators,
    dominantSpeaker,
    selectedSpeaker: selectedSpeakerRef.current,
    isSpeaking,
  };
};
