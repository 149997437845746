import { t } from "i18n-js";
import { FormTextAreaAutosize } from "@circle-react/components/shared/uikit/Form/FormTextAreaAutosize";
import { FormTipTapBlockEditor } from "@circle-react/components/shared/uikit/Form/FormTipTapBlockEditor/FormTipTapBlockEditor";

export const Body = () => (
  <div className="flex-1 overflow-auto px-5 py-4" data-ai-scrollable>
    <div className="trix-v2 mx-auto grid h-full max-w-3xl grid-rows-[auto,auto,1fr]">
      <FormTextAreaAutosize
        maxLength={255}
        draggable={false}
        name="title"
        autoFocus
        // ref={focusRef}
        placeholder={t(
          "settings.community_bot.knowledge.custom.placeholders.title",
        )}
        className="bg-primary text-dark mb-2 block w-full resize-none border-none p-0 text-2xl font-bold focus:ring-0"
        rows={1}
        onKeyPress={e => {
          if (e.key === "Enter") e.preventDefault();
        }}
        tabIndex={0}
      />
      <FormTipTapBlockEditor
        placeholder={t(
          "settings.community_bot.knowledge.custom.placeholders.body",
        )}
        name="body.body"
        className="h-full"
        editorClassName="h-full quick-post-modal--v2"
      />
    </div>
  </div>
);
