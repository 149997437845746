import PropTypes from "prop-types";
import { t } from "i18n-js";
import { Icon } from "@circle-react/components/shared/Icon";
import { useSmScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { Typography } from "@circle-react-uikit/Typography";

export const ChatThreadsBanner = ({
  shouldShowChatThreadsBanner,
  hideBanner,
}) => {
  const isSmallScreen = useSmScreenMediaQuery();
  if (!shouldShowChatThreadsBanner) return null;

  return (
    <div className="border-primary bg-primary mx-3 mt-6 h-24 rounded-lg border px-6 py-5 shadow-sm md:mx-6">
      <div className="flex flex-row items-center">
        <div className="mr-3 h-10 w-12 rounded-lg bg-gray-100 md:mr-6 md:h-14 md:w-14">
          <div className="flex h-full w-full items-center justify-between">
            <Icon
              type={isSmallScreen ? "16-chat-threads" : "20-chat-threads"}
              size={isSmallScreen ? 16 : 20}
              className="!w-full"
            />
          </div>
        </div>
        <div className="w-full">
          <div className="flex w-full flex-row items-start justify-between">
            <Typography.LabelLg weight="semibold">
              {t("threads.banner.title")}
            </Typography.LabelLg>
            <button type="submit" onClick={hideBanner}>
              <Icon type="20-close-sm" size={20} />
            </button>
          </div>
          {!isSmallScreen && (
            <Typography.LabelMd>
              {t("threads.banner.description")}
            </Typography.LabelMd>
          )}
        </div>
      </div>
    </div>
  );
};

ChatThreadsBanner.propTypes = {
  shouldShowChatThreadsBanner: PropTypes.bool,
  hideBanner: PropTypes.func.isRequired,
};
