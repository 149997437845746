import { Fragment, useMemo } from "react";
import classNames from "classnames";
import { t } from "i18n-js";
import { truncate } from "lodash";
import { InView } from "react-intersection-observer";
import { useHistory } from "react-router-dom";
import { getRouteWithPrevLocation } from "@/react/helpers/urlHelpers";
import { useUpdateMissingParticipants } from "@circle-react/hooks/chatsV2";
import { useChatRoomContext } from "@circle-react/hooks/chatsV2/useChatRoomContext";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { IconButton } from "@circle-react-shared/uikit/HeaderV3/IconButton";
import { Typography } from "@circle-react-uikit/Typography";
import { MessageItem } from "../../ChatWindowV2/MessageItem/ThreadItem";
import { findMissingParticipantIds } from "../../ChatWindowV2/helpers";
import { RichTextMessageBox } from "../../sharedV2/RichTextMessageBox";
import { ChatRoomTitle } from "../ChatRoomTitle";
import { getParticipantNames } from "../helpers";
import { getChatRoomUrl } from "../helpers";
import { ChatThreadsHeaderWrapper } from "./ChatThreadsHeaderWrapper";

export const ThreadItem = ({
  chatRoomData,
  newBadgeIds,
  handleWhenThreadInViewPort,
  thread,
  space,
  setSelectedReply,
  isRenderedOnHeader,
}: any) => {
  const history = useHistory();
  const { isMessaging } = useChatRoomContext();
  const { roomName, uuid, embedded_space, lesson } = chatRoomData;

  const { slug } = space || {};

  const {
    parent_message: parentMessage,
    replies,
    current_participant,
  } = thread;

  const {
    chat_room_uuid: chatRoomUuid,
    thread_participants_preview: threadParticipants,
    total_thread_participants_count: threadParticipantsCount,
    replies_count: totalRepliesCount,
    chat_thread_id: chatThreadId,
    embedded: isEmbedded,
    id,
  } = parentMessage;

  const chatRoomUrl = getChatRoomUrl({
    isEmbedded,
    isMessaging,
    lesson,
    slug,
    chatRoomUuid,
  });

  const threadParticipantNames = lesson
    ? lesson.name
    : getParticipantNames(threadParticipants, threadParticipantsCount);

  const repliesCount = totalRepliesCount - replies.length;
  const shouldShowMoreRepliesButton = repliesCount > 0;

  const shouldShowNewBadge = newBadgeIds.has(chatThreadId);

  const missingParticipantIds = useMemo(
    () => findMissingParticipantIds([parentMessage, ...replies], []),
    [parentMessage, replies],
  );

  const { isLoading, participants } = useUpdateMissingParticipants({
    uuid,
    ids: missingParticipantIds,
    enabled: missingParticipantIds.length > 0,
    shouldUpdateCache: false,
  });

  if (isLoading) return null;

  return (
    <Fragment key={parentMessage.id}>
      <ChatThreadsHeaderWrapper isRenderedOnHeader={isRenderedOnHeader}>
        <div
          className={classNames({
            "flex w-full flex-row items-start justify-between":
              isRenderedOnHeader,
            "mt-7 flex w-full flex-row justify-between first:mt-0":
              !isRenderedOnHeader,
          })}
        >
          <div className="w-full">
            <div
              className={classNames({
                "mt-7 flex w-full flex-row justify-between first:mt-0":
                  !isRenderedOnHeader,
                "flex flex-row items-center justify-between px-5 pt-5":
                  isRenderedOnHeader,
              })}
            >
              <ChatRoomTitle
                space={space}
                chatRoomName={roomName}
                parentMessage={parentMessage}
                chatRoomUrl={chatRoomUrl}
              />
              {shouldShowNewBadge && (
                <div className="bg-v2-danger flex h-4 items-center rounded px-1">
                  <Typography.LabelXxs color="text-white">
                    {t("threads.new")}
                  </Typography.LabelXxs>
                </div>
              )}
            </div>
            <div
              className={classNames({
                "mb-2": !isRenderedOnHeader,
                "px-5": isRenderedOnHeader,
              })}
            >
              <Typography.LabelXs color="text-light">
                {truncate(threadParticipantNames, { length: 60 })}
              </Typography.LabelXs>
            </div>
          </div>
          {isRenderedOnHeader && (
            <div className="pr-5 pt-5">
              <TippyV2
                interactive={false}
                content={t("header_v3.reply_to_thread")}
              >
                <IconButton
                  iconClassName="text-default"
                  className="hover:!bg-tertiary focus-visible:!bg-tertiary"
                  name="20-reply-to-thread"
                  ariaLabel={t("header_v3.reply_to_thread")}
                  onClick={() => {
                    history.push(
                      getRouteWithPrevLocation({
                        url: chatRoomUrl,
                        hash: `message_${id}`,
                        search: `message_id=${id}`,
                      }),
                    );
                  }}
                />
              </TippyV2>
            </div>
          )}
        </div>
        <div
          className={classNames({
            "border-primary bg-primary rounded-lg border shadow-sm":
              !isRenderedOnHeader,
          })}
        >
          <div
            className={classNames({
              "py-1 first:rounded-t-lg": !isRenderedOnHeader,
              "mt-2": isRenderedOnHeader,
            })}
          >
            <MessageItem
              message={parentMessage}
              chatRoom={chatRoomData}
              currentParticipant={current_participant}
              participants={participants}
              isParentMessage
              chatThreadsEnabled
              isRenderedOnHeader={isRenderedOnHeader}
              chatRoomUrl={chatRoomUrl}
            />
            {shouldShowMoreRepliesButton && (
              <button
                type="submit"
                className="ml-5 hover:underline"
                onClick={() =>
                  setSelectedReply({
                    parentMessage,
                    threadMessageId: replies[0].id,
                  })
                }
              >
                <Typography.LabelXs
                  color="text-selector-active"
                  weight="medium"
                >
                  {repliesCount > 1
                    ? t("threads.show_more_replies", { repliesCount })
                    : t("threads.show_one_more_reply")}
                </Typography.LabelXs>
              </button>
            )}
          </div>
          {replies.map((reply: any) => (
            <div
              key={reply.id}
              className="border-primary py-1 first:rounded-t-lg last:rounded-b-lg"
            >
              <MessageItem
                message={reply}
                chatRoom={chatRoomData}
                currentParticipant={current_participant}
                participants={participants}
                chatThreadsEnabled
                isRenderedOnHeader={isRenderedOnHeader}
                chatRoomUrl={chatRoomUrl}
              />
            </div>
          ))}
          <InView
            as="div"
            onChange={inView => handleWhenThreadInViewPort(inView, thread)}
          >
            <div id={chatThreadId} className="invisible" />
          </InView>
          <RichTextMessageBox
            chatRoom={chatRoomData}
            parentMessageId={parentMessage.id}
            spaceId={embedded_space?.id}
            userList={[]}
            participants={participants}
            messageBoxId={"threadsMessageTextBox" + String(parentMessage.id)}
          />
        </div>
      </ChatThreadsHeaderWrapper>
    </Fragment>
  );
};
