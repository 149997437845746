import { internalApi } from "@circle-react/helpers/urlHelpers";
import { useUnPaginateQuery } from "@circle-react/hooks/reactQuery/useUnPaginateQuery";

export const useMemberTags = () => {
  const { records: memberTags = [], isLoading } = useUnPaginateQuery({
    api: internalApi.memberTags.index,
  });

  return { memberTags, isLoading };
};
