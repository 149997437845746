import { useCohostsList, useRoom } from ".";
import { t } from "i18n-js";
import { useMutation } from "react-query";
import { liveStreamApi } from "@circle-react/api";
import { useRemoveCommunityMemberAsCohost } from "@circle-react/components/LiveStreams/Room/hooks";
import {
  useProviderPeersList,
  useProviderRoleChange,
} from "@circle-react/components/LiveStreams/Room/hooks/provider";
import { useLiveStreamContextData } from "@circle-react/contexts/LiveStreams";
import { PARTICIPANT_ROLES } from "@circle-react/helpers/liveStreamRoomHelpers";
import { useToast } from "@circle-react-uikit/ToastV2";

export const useCohosts = onAddCohosts => {
  const { success, error } = useToast();
  const room = useRoom();
  const { requestRoleChange } = useProviderRoleChange();
  const { spectators } = useProviderPeersList();
  const { participants } = useLiveStreamContextData();
  const { cohosts, isLoadingCohosts, refetchCohosts } = useCohostsList();

  const findActivePeerIdByCommunityMemberId = communityMemberId => {
    if (!spectators?.length) {
      return null;
    }
    const activePeer = spectators.find(
      peer =>
        participants?.[peer.customerUserId]?.community_member_id ===
        communityMemberId,
    );
    return activePeer?.id;
  };

  const inviteActivePeersToCohost = communityMembers => {
    communityMembers?.forEach(({ id: communityMemberId }) => {
      const peerId = findActivePeerIdByCommunityMemberId(communityMemberId);
      if (peerId) {
        // Invite through 100ms so that they can then accept
        // without reloading or leaving the session
        requestRoleChange(peerId, PARTICIPANT_ROLES.HOST);
      }
    });
  };

  const addCohostsMutation = useMutation(
    formData =>
      liveStreamApi.addCohosts({
        roomId: room?.id,
        formData,
        forReactQuery: true,
      }),
    {
      onSuccess: communityMembers => {
        inviteActivePeersToCohost(communityMembers);
        refetchCohosts();
        success(t("live_streams.room.cohosts_added_successfully"));
        onAddCohosts && onAddCohosts();
      },
      onError: () => error(t("live_streams.room.could_not_add_cohosts_error")),
    },
  );
  const removeCohost = useRemoveCommunityMemberAsCohost(refetchCohosts);
  const canInviteMoreHosts = room?.host_capacity > cohosts.length + 1;

  return {
    cohosts,
    isLoadingCohosts,
    addCohosts: addCohostsMutation.mutate,
    isAddingCohosts: addCohostsMutation.isLoading,
    removeCohost,
    canInviteMoreHosts,
  };
};
