import PropTypes from "prop-types";
import { t } from "i18n-js";
import { usePunditUserContext } from "@circle-react/contexts";
import { isPrivateCommunity } from "@circle-react/helpers/communityHelpers";
import {
  isBasicPostsSpace,
  isChatSpace,
  isCourseSpace,
  isEventsSpace,
  isImagesSpace,
  isMembersSpace,
} from "@circle-react/helpers/spaceHelpers";
import { Form } from "@circle-react-uikit/Form";
import { Typography } from "@circle-react-uikit/Typography";

const Permissions = ({ space }) => {
  const { currentCommunity } = usePunditUserContext();

  let fields = [];

  if (isEventsSpace(space)) {
    fields = [
      "allow_members_to_add_others",
      "hide_from_featured_areas",
      "allow_members_to_create_posts",
    ];
  }

  if (isChatSpace(space)) {
    fields = ["chat_room_show_history"];
  }

  if (isImagesSpace(space)) {
    fields = [
      "allow_members_to_add_others",
      "allow_members_to_create_posts",
      "hide_from_featured_areas",
    ];
  }

  if (isBasicPostsSpace(space)) {
    fields = [
      "allow_members_to_add_others",
      "allow_members_to_create_posts",
      "allow_post_title",
      "allow_cover_images",
      "hide_from_featured_areas",
    ];
  }

  if (isMembersSpace(space)) {
    fields = ["allow_members_to_add_others"];
  }

  if (
    !isPrivateCommunity(currentCommunity) &&
    !isChatSpace(space) &&
    !isCourseSpace(space)
  ) {
    fields = [...fields, "show_to_logged_out_visitors"];
  }

  fields = [...fields, "hide_members_count"];

  const nameTranslationKey = name => {
    if (name == "allow_members_to_create_posts") {
      return isEventsSpace(space)
        ? "allow_members_to_create_events"
        : "allow_members_to_create_posts";
    }
    return name;
  };

  return (
    <div className="mt-5 flex-1 md:mt-8">
      <Typography.TitleSm weight="bold">
        {t("spaces.form.edit.options.permissions.label")}
      </Typography.TitleSm>
      {fields.map(name => (
        <Form.Item
          hiddenField
          hideDescription
          label={t(
            `spaces.form.edit.options.permissions.${nameTranslationKey(name)}`,
          )}
          name={name}
          key={name}
          className="my-5 flex flex-row-reverse items-center justify-end py-0"
          labelClassName="ml-4 mb-0"
          shouldToggleValueOnLabelClick
        >
          <Form.ToggleSwitch variant="small" />
        </Form.Item>
      ))}
    </div>
  );
};

export default Permissions;

Permissions.propTypes = {
  space: PropTypes.object,
};
