import { NavLink } from "react-router-dom";
import { coursePaths } from "@/react/helpers/urlHelpers";
import { useThemeContext } from "@/react/providers";
import { SanitizeHTMLString } from "@circle-react/components/shared/uikit/SanitizeContent";
import { EmojiRenderer } from "@circle-react-shared/EmojiRenderer";
import { Typography } from "@circle-react-uikit/Typography";
import type { AdvancedSearchResultLesson } from "../types";
import { HighlightedText } from "./HighlightedText";

export const Lesson = ({ item }: { item: AdvancedSearchResultLesson }) => {
  const {
    emoji,
    custom_emoji_url: customEmojiUrl,
    custom_emoji_dark_url: customEmojiDarkUrl,
  } = item.space_emoji || {};
  const { currentAppearance } = useThemeContext();

  const path = coursePaths.showLesson({
    spaceSlug: item.space_slug,
    sectionId: String(item.section_id),
    lessonId: item.id,
  });

  const body = item.highlighted_body || item.body;

  return (
    <NavLink
      to={path}
      className="flex max-w-full items-center gap-8 pr-6"
      data-testid="course-lesson-result"
    >
      <div className="flex max-w-full flex-col gap-3">
        <div className="flex items-center gap-2">
          <EmojiRenderer
            wrapperClassName="flex items-center !w-5 !h-5"
            emoji={emoji}
            customEmojiUrl={customEmojiUrl}
            customEmojiDarkUrl={customEmojiDarkUrl}
            appearance={currentAppearance}
          />
          <Typography.LabelXs color="text-selector-active">
            {item.space_name}
          </Typography.LabelXs>
        </div>
        <div className="[&_mark]:!bg-search-yellow flex flex-col gap-2">
          <Typography.LabelMd weight="semibold">
            <HighlightedText
              originalText={item.name}
              highlightedText={item.highlighted_name}
            />
          </Typography.LabelMd>
          {item.body && (
            <div className="line-clamp-4">
              <Typography.BodySm color="text-default">
                <SanitizeHTMLString content={body} />
              </Typography.BodySm>
            </div>
          )}
        </div>
      </div>
    </NavLink>
  );
};
