import { Suspense } from "react";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { PageLoader } from "@circle-react/pages/PageLoader";

const PlanSettingsComponent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "PlanSettingsPage" */ "@circle-react/components/SettingsApp/Plan"
    ),
);

export const PlanSettingsPage = () => (
  <Suspense fallback={<PageLoader />}>
    <PlanSettingsComponent />
  </Suspense>
);
