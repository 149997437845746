import { t } from "i18n-js";
import { useFormContext } from "react-hook-form";
import { formattedDateTime } from "@circle-react/helpers/dateTimeHelpers/formattedDateTime";
import { Typography } from "@circle-react-uikit/Typography";
import { FirstLastRunPicker } from "./FirstLastRunPicker";
import { useMessageParts } from "./useMessageParts";

export function DefaultOptionsContainer({
  i18n,
  isMessageVisible: isMessageVisibleProp,
  variables = {},
}: {
  i18n: string | string[];
  isMessageVisible?: boolean;
  variables?: Record<string, string | number | null>;
}) {
  const { watch } = useFormContext();
  const firstRun = watch("first_run_at");
  const lastRun = watch("last_run_at");
  const nextRun = watch("next_run_at");
  const endsOn = watch("ends_on");
  const hasScheduleRunBefore = watch("has_schedule_run_before");
  const recurringHourMinutes = hasScheduleRunBefore
    ? new Date(nextRun)
    : new Date(firstRun);

  const getIsMessageVisible = () => {
    if (!firstRun) {
      return false;
    }

    return !(endsOn === "on" && !lastRun);
  };

  const isMessageVisible = isMessageVisibleProp ?? getIsMessageVisible();
  const { prefix, midSection } = useMessageParts(hasScheduleRunBefore);

  return (
    <div className="flex flex-col gap-y-4">
      <FirstLastRunPicker />
      {isMessageVisible && (
        <Typography.LabelSm weight="medium">
          {t(i18n, {
            start_time_at: formattedDateTime({
              dateTime: new Date(firstRun),
              format: "date_time_with_short_timezone",
            }),
            end_time_at: formattedDateTime({
              dateTime: new Date(lastRun),
              format: "long_date",
            }),
            prefix,
            mid_section: midSection,
            recurring_hour_minutes: formattedDateTime({
              dateTime: recurringHourMinutes,
              format: "short_time",
            }),
            ...variables,
          })}
        </Typography.LabelSm>
      )}
    </div>
  );
}
