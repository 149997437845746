import { t } from "i18n-js";
import { TRIGGER_RESOURCE_TYPE } from "../../../constants";
import type { EventOption } from "../types";
import { PaywallResource } from "./shared/PaywallResource";
import { WhichPaywallSelector } from "./shared/WhichPaywallSelector";

export const memberSubscribed: Readonly<EventOption> = {
  label: t(
    "settings.workflows.form.events.community_member_subscribed_to_paywall.title",
  ),
  value: "community_member_subscribed_to_paywall",
  group: t("settings.workflows.form.event_groups.paywalls"),
  description: t(
    "settings.workflows.form.events.community_member_subscribed_to_paywall.description",
  ),
  resource: TRIGGER_RESOURCE_TYPE.PAYWALL,
  formComponent: WhichPaywallSelector,
  viewComponent: PaywallResource,
  icon: "paywall",
};
