import { useMemo } from "react";
import { PARTICIPANT_ROLES } from "@circle-react/helpers/liveStreamRoomHelpers";
import { useLivekitContext } from "../../VideoProvider/LivekitProvider";

export const useLivekitPeersList = () => {
  const { participants } = useLivekitContext();
  const peers = participants;

  const peersWithHandRaised = useMemo(
    () =>
      peers?.filter(peer => {
        try {
          return !!JSON.parse(peer?.metadata || "{}")?.isHandRaised;
        } catch (err) {
          console.error(err.message);
          return false;
        }
      }) || [],
    [peers],
  );

  const hosts = useMemo(
    () => peers.filter(peer => peer.roleName === PARTICIPANT_ROLES.HOST),
    [peers],
  );

  const spectators = useMemo(
    () =>
      peers.filter(peer =>
        [PARTICIPANT_ROLES.SPECTATOR, PARTICIPANT_ROLES.HLS_SPECTATOR].includes(
          peer.roleName,
        ),
      ),
    [peers],
  );

  return {
    peers,
    peersWithHandRaised,
    hosts,
    spectators,
  };
};
