import { t } from "i18n-js";
import { DataTable } from "@circle-react-shared/uikit/DataTableV2";
import { numberFormatter } from "@circle-react-uikit/TabV2/Counter";

export const Headline = ({ totalChargesCount }: any) => {
  if (totalChargesCount <= 0) {
    return null;
  }

  return (
    <DataTable.Headline className="flex flex-row">
      <DataTable.Headline.Title>
        {t("settings.paywalls_admin_dashboard.charges.count", {
          count: numberFormatter(totalChargesCount),
        })}
      </DataTable.Headline.Title>
    </DataTable.Headline>
  );
};
