import { usePunditUserContext } from "@circle-react/contexts";
import { isCommunityAdmin } from "@circle-react/helpers/communityMemberHelpers";
import { OptInBarComponent } from "./OptInBarComponent";
import { useShouldDisplayV3OptInBar } from "./hooks/useShouldDisplayV3OptInBar";

interface OptInBarProps {
  optInText: string;
  optInCTAContent: string;
}

export const OptInBar = ({ optInText, optInCTAContent }: OptInBarProps) => {
  const { currentCommunityMember } = usePunditUserContext();
  const isCurrentUserCommunityAdmin = isCommunityAdmin(currentCommunityMember);
  const shouldDisplayV3OptInBar = useShouldDisplayV3OptInBar();

  return (
    <OptInBarComponent
      optInText={optInText}
      optInCTAContent={optInCTAContent}
      isAdmin={isCurrentUserCommunityAdmin}
      shouldDisplayV3OptInBar={shouldDisplayV3OptInBar}
    />
  );
};
