import { Children } from "react";
import classNames from "classnames";

export const BlockGroup = ({ children }: any) => {
  const childrenCount = Children.count(children);

  return (
    <div
      className={classNames(
        "grid w-full max-w-[960px] grid-cols-1 gap-y-4 px-6 md:mx-4",
        {
          "md:w-1/3 md:w-[332px]": childrenCount === 1,
          "md:w-[680px] md:grid-cols-2 md:gap-x-4": childrenCount === 2,
          "md:w-[960px] md:grid-cols-3 md:gap-x-4": childrenCount > 2,
        },
      )}
    >
      {children}
    </div>
  );
};
