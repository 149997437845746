import { t } from "i18n-js";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { ActionModal } from "@circle-react-shared/ModalsV2/ActionModal";

export const CancelConfirmationModalComponent = ({
  onConfirm,
  disabled,
}: any) => {
  const i18nRoot = "settings.workflows.table.actions.cancel";
  const modal = useModal();
  return (
    <ActionModal
      isOpen={modal.visible}
      onClose={modal.hide}
      actionVariant="danger"
      actionText={t(`${i18nRoot}.title`)}
      title={t(`${i18nRoot}.confirm_title`)}
      onAction={onConfirm}
      disabled={disabled}
    >
      {t(`${i18nRoot}.description`)}
    </ActionModal>
  );
};

export const CancelConfirmationModal = ModalManager.create(
  ({ onConfirm, disabled }: any) => (
    <CancelConfirmationModalComponent
      onConfirm={onConfirm}
      disabled={disabled}
    />
  ),
);

export const useCancelConfirmationModal = () =>
  useModal(CancelConfirmationModal);
