import { useLiveStreamContextActions } from "@circle-react/contexts/LiveStreams";
import { useProviderLeaveRoom } from "./provider";

// Leaving a room is a combined action that requires us to leave on circle
// As well as 100ms
export const useLeaveRoom = () => {
  const { leaveAsync } = useLiveStreamContextActions();
  const leaveProviderRoom = useProviderLeaveRoom();

  return async () => {
    // Circle leave
    await leaveAsync();
    // Provider leave
    await leaveProviderRoom();
  };
};
