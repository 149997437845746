import { QuickPostV2Form } from "@circle-react/components/QuickPostV2/Form";
import { POST_FORM_CACHE } from "@circle-react/components/constants";
import {
  CurrentPostContextProvider,
  useCurrentSpaceContext,
} from "@circle-react/contexts";
import { usePersistedFormFieldValues } from "@circle-react/hooks/utils/usePersistFormFields";

interface NewPostWrapperProps {
  spaceId?: string | number;
  bodyForEditor?: string;
  tiptapBodyForEditor?: object;
  shouldSkipRedirectAfterSubmit?: boolean;
  onSubmitSuccess?: () => void;
}

interface CurrentSpaceContext {
  data: {
    id: string;
  };
  [key: string]: any;
}

const hasCurrentSpaceContextData = (
  context: any,
): context is CurrentSpaceContext => typeof context?.data?.id !== "undefined";

export const NewPostWrapper = ({
  spaceId,
  bodyForEditor,
  tiptapBodyForEditor,
  shouldSkipRedirectAfterSubmit,
  onSubmitSuccess,
}: NewPostWrapperProps) => {
  const currentSpaceContext = useCurrentSpaceContext();
  const currentSpaceId =
    spaceId ||
    (hasCurrentSpaceContextData(currentSpaceContext)
      ? currentSpaceContext.data.id
      : undefined);

  const { getPersistedFormFieldValues } = usePersistedFormFieldValues({
    cacheKey: POST_FORM_CACHE,
  });

  const persistedFormValues = getPersistedFormFieldValues();

  const post = {
    id: null,
    space_id: currentSpaceId || persistedFormValues?.space_id,
    name: persistedFormValues?.name,
    body_for_editor: bodyForEditor || persistedFormValues?.body,
    tiptap_body: tiptapBodyForEditor || persistedFormValues?.tiptap_body,
    cover_image: persistedFormValues?.cover_image,
  };

  return (
    <CurrentPostContextProvider post={post}>
      <QuickPostV2Form
        shouldSkipRedirectAfterSubmit={shouldSkipRedirectAfterSubmit}
        onSubmitSuccess={onSubmitSuccess}
      />
    </CurrentPostContextProvider>
  );
};
