import { LeaveSpaceConfirmationModal as LeaveSpaceConfirmationModalContent } from "@/react/components/Spaces/LeaveSpaceConfirmationModal";
import { useActiveSpace } from "@/react/hooks/useActiveSpace";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { CurrentSpaceContextProvider } from "@circle-react/contexts";

const LeaveSpaceConfirmationModalComponent = () => {
  const { space } = useActiveSpace();
  const drawer = useModal();

  return (
    <CurrentSpaceContextProvider spaceId={space?.id}>
      <LeaveSpaceConfirmationModalContent
        show={drawer.visible}
        space={space}
        onClose={drawer.hide}
      />
    </CurrentSpaceContextProvider>
  );
};

const LeaveSpaceConfirmationModal = ModalManager.create(() => (
  <LeaveSpaceConfirmationModalComponent />
));

export const useLeaveSpaceConfirmationModal = () =>
  useModal(LeaveSpaceConfirmationModal);
