export const ToggleableField = ({
  toggleField,
  fieldToggleable,
  hideToggleableField,
}: any) => (
  <div className="flex flex-col gap-y-2">
    {!!toggleField && toggleField}
    {!!fieldToggleable && !hideToggleableField && (
      <div className="flex flex-col gap-y-2 pl-11">{fieldToggleable}</div>
    )}
  </div>
);
