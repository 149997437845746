import { t } from "i18n-js";
import { useIsModifyScheduleEnabled } from "@circle-react/components/SettingsApp/Workflows/hooks/useIsModifyScheduleEnabled";

const i18nRoot = "settings.workflows.recurring_schedule_modal";

export const useMessageParts = (hasScheduleRunBefore: boolean) => {
  const isModifyScheduleEnabled = useIsModifyScheduleEnabled();

  const prefix =
    hasScheduleRunBefore && isModifyScheduleEnabled
      ? t(`${i18nRoot}.workflow_already_started`)
      : t(`${i18nRoot}.workflow_will_start_running`);

  const midSection =
    hasScheduleRunBefore && isModifyScheduleEnabled
      ? t(`${i18nRoot}.workflow_will_now_run`)
      : t(`${i18nRoot}.once_started_workflow_will_run`);

  return { prefix, midSection };
};
