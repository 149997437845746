import type { ComponentType, ReactNode } from "react";
import classNames from "classnames";
import I18n from "i18n-js";
import { Typography } from "@circle-react-uikit/Typography";

export interface CheckoutFormFieldWrapperProps {
  name: string;
  addon?: ReactNode;
  className?: string;
  childClassName?: string;
  children: ReactNode;
  optional?: boolean;
  LabelComponent?: ComponentType<{ weight?: string }>;
}

export const CheckoutFormFieldWrapper = ({
  name,
  addon,
  className,
  childClassName,
  children,
  optional,
  LabelComponent = Typography.LabelSm,
}: CheckoutFormFieldWrapperProps) => (
  <div className={classNames(className, "flex flex-col")}>
    <div
      className={classNames(childClassName, "mb-1 flex flex-row items-center")}
    >
      <div className="flex flex-row items-center gap-x-1">
        <LabelComponent weight="semibold">
          {I18n.t(`paywall_checkout.form.fields.${name}`)}
        </LabelComponent>
        {optional && (
          <>
            {" "}
            <Typography.LabelSm weight="normal" color="text-light">
              {`(${I18n.t("paywall_checkout.form.fields.optional")})`}
            </Typography.LabelSm>
          </>
        )}
      </div>
      {addon}
    </div>
    {children}
  </div>
);
