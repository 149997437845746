import { useFreeTrial } from "../../hooks/useFreeTrial";
import { TrialDaysInput } from "../Fields/TrialDaysInput";
import { TrialRequiresCardToggle } from "../Fields/TrialRequiresCardToggle";
import { TrialSelfCancelableToggle } from "../Fields/TrialSelfCancelableToggle";
import { TrialToggle } from "../Fields/TrialToggle";
import { ToggleableField } from "../ToggleableField";

export const TrialForm = () => {
  const { hasPricesEligibleForTrial, hasTrial } = useFreeTrial();

  return (
    <ToggleableField
      toggleField={<TrialToggle />}
      fieldToggleable={
        <>
          <TrialDaysInput className="w-7/12" />
          <TrialRequiresCardToggle />
          <TrialSelfCancelableToggle />
        </>
      }
      hideToggleableField={!hasPricesEligibleForTrial() || !hasTrial}
    />
  );
};
