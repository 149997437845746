import {
  useCurrentSpaceContext,
  usePunditUserContext,
} from "@circle-react/contexts";

export const useIsLockedSpace = () => {
  const { currentCommunityMember } = usePunditUserContext();
  const { data: space = {} } = useCurrentSpaceContext();
  const { is_private: isPrivate, is_space_member: isSpaceMember } = space;
  const isAdmin = currentCommunityMember?.is_admin;

  return isPrivate && !isSpaceMember && !isAdmin;
};
