import { usePunditUserContext } from "@circle-react/contexts";
import { useRouteHelpers } from "@circle-react/routes/Main/useRouteHelpers";

export const useShouldShowMobileBottomBar = () => {
  const { currentCommunityMember } = usePunditUserContext();
  const { isHomePage, isSpaceShowPage, isMemberDirectoryPage, isMessagesPage } =
    useRouteHelpers();

  return (
    !!currentCommunityMember &&
    (isHomePage || isSpaceShowPage || isMemberDirectoryPage || isMessagesPage)
  );
};
