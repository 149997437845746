import type { Dispatch, Reducer } from "react";
import { useReducer } from "react";

type BooleanListState = Map<string, boolean>;
type BooleanListReducer = Reducer<BooleanListState, BooleanListAction>;

export interface BooleanListAction {
  id: string;
  value: boolean;
  resetOthers?: boolean;
}

const reducer: BooleanListReducer = (
  state: BooleanListState,
  action: BooleanListAction,
) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention -- honor legacy naming convention
  const { id, value, resetOthers } = action;
  if (resetOthers) {
    state.clear();
  }
  state.set(id, value);
  return new Map(state);
};

/**
 * A boolean list to set state for a list of items
 */
export const useBooleanList = (
  initialValue: BooleanListState = new Map(),
): [BooleanListState, Dispatch<BooleanListAction>] =>
  useReducer<BooleanListReducer>(reducer, initialValue);
