import { EventsV3 } from "@circle-react/components/EventsV3";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { AddonsProvider } from "@circle-react/components/LiveStreams/LiveFormV3/Modal/AddonsProvider";

interface CreateEventModalProps {
  spaceId: number;
}

const CreateEventModalComponent = ({ spaceId }: CreateEventModalProps) => {
  const { visible: isVisible, hide } = useModal();

  return (
    <AddonsProvider>
      <EventsV3
        isOpen={isVisible}
        onClose={hide}
        spaceId={spaceId}
        shouldClearEventStateOnHide
      />
    </AddonsProvider>
  );
};

export const CreateEventModal = ModalManager.create(
  ({ spaceId }: CreateEventModalProps) => (
    <CreateEventModalComponent spaceId={spaceId} />
  ),
);

export const useCreateEventModal = () => useModal(CreateEventModal);
