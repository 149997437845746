import { isEmpty, isObject } from "lodash";

export const hasTruthyValues = value => {
  if (isObject(value)) {
    if (isEmpty(value)) return false;
    return Object.values(value).every(value => hasTruthyValues(value));
  }

  return Boolean(value);
};
