import type { ReactNode } from "react";
import classNames from "classnames";

export interface CheckoutFormSectionProps {
  className?: string;
  children: ReactNode;
}

export const CheckoutFormSection = ({
  className,
  children,
}: CheckoutFormSectionProps) => (
  <div
    className={classNames(
      "border-secondary-200 flex flex-col py-6 empty:hidden",
      className,
    )}
  >
    {children}
  </div>
);
