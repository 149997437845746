import { t } from "i18n-js";
import noop from "lodash/noop";
import { useQueryClient } from "react-query";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { useSpaceGroupResource } from "@circle-react/contexts";
import { useAddMembersToSpaceGroup } from "@circle-react/hooks/useAddMembersToSpaceGroup";
import { Form } from "@circle-react-uikit/Form";
import { Modal } from "@circle-react-uikit/ModalV2";
import { useToast } from "@circle-react-uikit/ToastV2";
import { AddMemberContent } from "./AddMemberContent";

export interface AddSpaceGroupMemberProps {
  spaceGroupId: number;
}

const formId = "add-space-group-members-form";

const defaultValues = {
  add_all_members: false,
  community_member_ids: null,
};

export const AddSpaceGroupMember = ({
  spaceGroupId,
}: AddSpaceGroupMemberProps) => {
  const toastr = useToast();
  const queryClient = useQueryClient();
  const modal = useModal();
  const { refetch } = useSpaceGroupResource(spaceGroupId);
  const { mutate: addMembers, isLoading } = useAddMembersToSpaceGroup({
    onError: (error: any) => {
      toastr.error(error.message);
    },
    onSuccess: ({ message }: any) => {
      void queryClient.invalidateQueries(["space-group-members"]);
      void refetch?.();
      toastr.success(message, {
        duration: "short",
        shouldUseProgress: false,
      });
      void modal.hide();
    },
  });

  const onSubmit = (formContent: any) =>
    addMembers({
      spaceGroupId,
      ...(formContent.community_member_ids && {
        communityMembersId: formContent.community_member_ids,
      }),
      shouldAddAllMembers: formContent.add_all_members,
    });

  return (
    <Modal
      isOpen={modal.visible}
      onClose={modal.visible ? modal.hide : noop}
      contentPortalId="add-space-group-member-portal-content"
      title={t("space_groups.form.edit.members.add_member")}
    >
      <Modal.Overlay />
      <Modal.Content size="md">
        <Form
          id={formId}
          mode="onChange"
          onSubmit={onSubmit}
          defaultValues={defaultValues}
          className="flex flex-col overflow-y-auto"
          formClassName="flex overflow-y-auto flex-col"
        >
          <AddMemberContent
            onClose={modal.hide}
            isLoading={isLoading}
            formId={formId}
          />
        </Form>
      </Modal.Content>
    </Modal>
  );
};

const AddSpaceGroupMemberModal = ModalManager.create(
  ({ spaceGroupId }: AddSpaceGroupMemberProps) => (
    <AddSpaceGroupMember spaceGroupId={spaceGroupId} />
  ),
);

export const useAddSpaceGroupMemberModal = () =>
  useModal(AddSpaceGroupMemberModal);
