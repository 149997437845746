import type { ReactNode } from "react";
import { useEffect } from "react";
import { StandardLayoutV2 } from "@/react/components/Layout/StandardLayoutV2";
import { useLayoutContext } from "@/react/components/SettingsApp/Layout/LayoutContext";
import { useHideTrialBanner } from "@/react/hooks/useHideTrialBanner";
import { useSettingsHeader } from "@circle-react-shared/uikit/HeaderV3/useSettingsHeader";

interface LayoutComponentProps {
  children: ReactNode;
  hideAppBar?: boolean;
}

export const LayoutComponent = ({
  children,
  hideAppBar,
  ...rest
}: LayoutComponentProps) => {
  const { config, setConfig } = useLayoutContext();
  const { isReactFlowWorkflowsRoute } = useSettingsHeader();
  useEffect(() => {
    setConfig((currentConfig: any) => ({
      ...currentConfig,
      hideAppBar: hideAppBar,
    }));
  }, [hideAppBar, setConfig]);

  useHideTrialBanner();

  return (
    <StandardLayoutV2
      {...rest}
      disableRightSidebar={config.disableRightSidebar}
      renderLeftSidebar={config.renderLeftSidebar}
      disableSidebar={config.disableSidebar || isReactFlowWorkflowsRoute}
      hideAppBar={config.hideAppBar}
    >
      {children}
    </StandardLayoutV2>
  );
};
