import { Suspense } from "react";
import { usePunditUserContext } from "@circle-react/contexts";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { PageLoader } from "@circle-react/pages/PageLoader";

const OptInLandingPageComponent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "OptInLandingPageComponent" */ "@circle-react/components/NewVersionOptIn/OptInLandingPage/OptInLandingPageComponent"
    ),
);

export const OptInLandingPage = () => {
  const { currentCommunitySettings } = usePunditUserContext();
  const isCircleV3Eligible = currentCommunitySettings?.circle_v3_eligible;
  const isCircleV3Enabled = currentCommunitySettings?.circle_v3_enabled;

  return (
    <Suspense fallback={<PageLoader />}>
      <OptInLandingPageComponent
        isCircleV3Eligible={isCircleV3Eligible}
        isCircleV3Enabled={isCircleV3Enabled}
      />
    </Suspense>
  );
};
