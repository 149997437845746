import { Suspense } from "react";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { PageLoader } from "@circle-react/pages/PageLoader";

const PostsSettingsComponent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "PostsSettingsPage" */ "@circle-react/components/SettingsApp/Posts"
    ),
);

export const PostsSettingsPage = () => (
  <Suspense fallback={<PageLoader />}>
    <PostsSettingsComponent />
  </Suspense>
);
