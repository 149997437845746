import { t } from "i18n-js";
import { Icon } from "@circle-react-shared/Icon";
import { Dropdown } from "@circle-react-shared/uikit/Dropdown";
import { Typography } from "@circle-react-shared/uikit/Typography";

interface DropdownMenuProps<T> {
  className?: string;
  items: T[];
  activeItem: T;
  localeScope: string;
  onItemClick: (item: T) => void;
}

export const DropdownMenu = <T extends string>({
  className,
  items,
  activeItem,
  localeScope,
  onItemClick,
}: DropdownMenuProps<T>) => (
  <Dropdown
    direction="bottom-end"
    className={className}
    buttonClassName="w-full"
    button={
      <Dropdown.MenuButton className="hover:bg-tertiary border-primary flex justify-between rounded-md border px-2 py-1 transition-colors">
        <Typography.LabelSm>{t([localeScope, activeItem])}</Typography.LabelSm>
        <Icon size={16} type="caret-down-bold" className="text-dark" />
      </Dropdown.MenuButton>
    }
  >
    {items.map(item => (
      <Dropdown.Item key={item} onClick={() => onItemClick(item)}>
        <Dropdown.ItemLink type="button" active={activeItem === item}>
          {t([localeScope, item])}
        </Dropdown.ItemLink>
      </Dropdown.Item>
    ))}
  </Dropdown>
);
