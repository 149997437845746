import PropTypes from "prop-types";
import { t } from "i18n-js";
import { TippyV2 } from "@circle-react/components/shared/TippyV2";
import { Badge } from "@circle-react-uikit/Badge";
import { Typography } from "@circle-react-uikit/Typography";

const Information = ({ tooltipText, text, hide }) =>
  !hide && (
    <TippyV2 content={tooltipText} className="cursor-pointer pr-3">
      <Typography.LabelSm color="text-default">{text}</Typography.LabelSm>
    </TippyV2>
  );

Information.propTypes = {
  tooltipText: PropTypes.string,
  text: PropTypes.string,
  hide: PropTypes.bool,
};

export const InvitationLinkInformation = ({
  invitationLinkName,
  invitationLinkActive,
  membersCount,
  spaces,
  spaceGroups,
  shouldShowPaywall,
  paywallString,
  paywallCouponString,
}) => {
  const badgeVariant = invitationLinkActive ? "success" : "default";
  return (
    <>
      <div className="flex gap-3">
        <Typography.LabelLg>{invitationLinkName}</Typography.LabelLg>
        <Badge variant={badgeVariant}>
          <div className="px-2 py-1">
            {invitationLinkActive
              ? t("invitation_links.summary.badge.active")
              : t("invitation_links.summary.badge.revoked")}
          </div>
        </Badge>
      </div>
      <div className="pt-1">
        <Typography.LabelSm color="text-dark">
          {t("invitation_links.members_joined", { count: membersCount })}
        </Typography.LabelSm>
      </div>

      <div className="flex pt-2">
        <Information
          tooltipText={spaces.join(", ")}
          text={t("invitation_links.summary.spaces", { count: spaces.length })}
          hide={spaces.length <= 0}
        />
        <Information
          tooltipText={spaceGroups.join(", ")}
          text={t("invitation_links.summary.space_groups", {
            count: spaceGroups.length,
          })}
          hide={spaceGroups.length <= 0}
        />
        <Information
          tooltipText={paywallString}
          text={t("invitation_links.summary.paywall")}
          hide={!shouldShowPaywall}
        />
        <Information
          tooltipText={paywallCouponString}
          text={t("invitation_links.summary.paywall_coupon")}
          hide={!shouldShowPaywall || !paywallCouponString}
        />
      </div>
    </>
  );
};

InvitationLinkInformation.propTypes = {
  invitationLinkName: PropTypes.string,
  invitationLinkActive: PropTypes.bool,
  membersCount: PropTypes.number,
  spaces: PropTypes.array,
  spaceGroups: PropTypes.array,
  shouldShowPaywall: PropTypes.bool,
  paywallString: PropTypes.string,
  paywallCouponString: PropTypes.string,
};
