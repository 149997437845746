import { useMemo } from "react";
import { useRouterQueryParams } from "@circle-react/hooks/useRouterQueryParams";
import { allFilterTranslations } from "./useFilters";

const useIsFilterBeingUsed = (type: "charges" | "subscriptions") => {
  const queryParams = useRouterQueryParams();
  return useMemo(
    () =>
      Object.keys(allFilterTranslations[type]).some(
        filterParam => !!queryParams[filterParam],
      ),
    [queryParams, type],
  );
};

export const useIsFilterBeingUsedOnTransactions = () =>
  useIsFilterBeingUsed("charges");
export const useIsFilterBeingUsedOnSubscriptions = () =>
  useIsFilterBeingUsed("subscriptions");
