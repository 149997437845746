import { t } from "i18n-js";
import { LabelMd } from "@circle-react/components/shared/uikit/Typography/components/Label/LabelMd";
import { BotAvatar } from "../BotAvatar";

export const Thinking = () => (
  <div className="ml-6 flex flex-row items-start gap-3 pt-6">
    <BotAvatar />
    <div className="flex flex-col gap-1">
      <LabelMd weight="semibold" color="text-dark">
        {t("support_widget.chatbot.name")}
      </LabelMd>
      <div className="typing flex h-5 items-center gap-1">
        <span className="inline-block h-2 w-2 rounded-full bg-gray-500"></span>
        <span className="inline-block h-2 w-2 rounded-full bg-gray-500"></span>
        <span className="inline-block h-2 w-2 rounded-full bg-gray-500"></span>
      </div>
    </div>
  </div>
);
